import React, { useState, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/core/styles';
import { grey, red, green } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import InputSearch from '../../components/InputSearch';
import Alert from '../../components/Alert';
import Popup from '../../components/Popup';
import DateTimePicker from '../../components/DateTimePicker';

import api from '../../services/api';

import date from '../../utils/date';
import { PermissionsContext } from '../../providers/Permissions';

export default function ExportPeopleToDeviceList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [exportLogs, setExportLogs] = useState(null);
    const [total, setTotal] = useState(0);
    // const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [refresh, setRefresh] = useState(true)
    const [limit, setLimit] = useState(10);

    const [openDeletePopup, setOpenDeletePopup] = useState(false);

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingExportLog, setDeletingExportLog] = useState();

    const handleDelete = (id) => {
        setDeletingExportLog(id);
        setOpenDeletePopup(true);
    };


    const deleteExportLog = async () => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/export_people/delete/${deletingExportLog}`);

            setIsDeleting(false);

            setDeletingExportLog(null);
            setOpenDeletePopup(false);

            if (result.status === 204) {
                setRefresh(!refresh);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Registro deletado com sucesso!`,
                });
            }
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    function updateRequest({ search }) {
        const loadData = async () => {
            try {
                const result = await api.get('/export_people/list', {
                    params: {
                        search,
                        start_time: startTime,
                        end_time: endTime,
                        limit,
                        page,
                    },
                });

                const { count, ExportedLog } = result.data;

                setExportLogs(ExportedLog);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();

        // const load = setInterval(loadData, 10000);

        // return () => {
        //     clearInterval(load);
        // };
    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Exportações
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item>
                                <InputSearch
                                    textFieldProps={{
                                        placeholder: 'Buscar…',
                                    }}
                                    request={updateRequest}
                                    pagination={[page, limit, refresh, startTime, endTime]}
                                />
                            </Grid>
                            <Grid item>
                                <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    value={startTime}
                                    placeholder="Inicio das exportações"
                                    onChange={value => setStartTime(value && value.format())}
                                    cancelLabel="cancelar"
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item>
                                <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    value={endTime}
                                    placeholder="Fim das exportações"
                                    onChange={value => setEndTime(value && value.format())}
                                    cancelLabel="cancelar"
                                    disabled={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Horário</TableCell>
                                        <TableCell className={classes.tableHead}>Completados/Total</TableCell>
                                        <PermissionOneOfWrapper
                                            routes={[
                                                "/exportar_pessoas/:id",
                                                "/exportar_pessoas/deletar/:id",
                                            ]}
                                        >
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!exportLogs && exportLogs.map(log => (
                                        <TableRow key={log.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {log.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {log.event_time ? date(log.event_time).toLocaleFormat() : '-'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {`${log.completed}/${log.total}`}
                                            </TableCell>
                                            <PermissionOneOfWrapper
                                                routes={[
                                                    "/exportar_pessoas/:id",
                                                    "/exportar_pessoas/deletar/:id",
                                                ]}
                                            >
                                                <TableCell className={classes.tableBody}>
                                                    <PermissionWrapper route="/exportar_pessoas/:id">
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            style={{ background: '#ECB365' }}
                                                            className={classes.actionButton}
                                                            href={`/exportar_pessoas/${log.id}`}
                                                        >
                                                            <VisibilityIcon style={{ fontSize: 20 }} />
                                                        </Button>
                                                    </PermissionWrapper>
                                                    <PermissionWrapper route="/exportar_pessoas/deletar/:id">
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            color="secondary"
                                                            className={classes.actionButton}
                                                            onClick={() => handleDelete(log.id)}
                                                        >
                                                            <DeleteIcon style={{ fontSize: 20 }} />
                                                        </Button>
                                                    </PermissionWrapper>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                    <Popup
                        openPopup={openDeletePopup}
                        setOpenPopup={setOpenDeletePopup}

                    >
                        <Grid container xs={12} style={{
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0 3% 2% 3%',

                        }}>
                            <Typography variant='h5'
                                style={{
                                    fontWeight: 'bold',
                                    color: '#9e9e9e'
                                }}
                            >
                                Deseja mesmo deletar esse registro de exportação?
                            </Typography>
                            <Grid item style={{
                                marginTop: '50px'
                            }}>
                                <Button
                                    disabled={isDeleting}
                                    variant="contained"
                                    onClick={() => deleteExportLog()}
                                    color="secondary"
                                    endIcon={<DeleteIcon />}
                                >
                                    {isDeleting ? 'Deletando...' : 'Sim, delete o registro'}
                                </Button>
                            </Grid>

                        </Grid>
                    </Popup>
                </Grid>
                <PermissionWrapper route="/exportar_pessoas/exportar">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href="/exportar_pessoas/exportar"
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}

            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    enabledNotification: {
        backgroundColor: green[700],
        color: '#FFF',
    },
    disabledNotification: {
        backgroundColor: red[700],
        color: '#FFF',
    },
}));
