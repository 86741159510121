import { useState, useEffect } from 'react';

import api from '../services/api';

const useCameras = ({
    search = '',
    page = 0,
    limit = 10,
} = {}) => {

    const [cameras, setCameras] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const findCameras = async () => {
            try {
                const result = await api.get('/camera/list', {
                    params: {
                        search,
                        page,
                        limit,
                    }
                });

                const { cameras, count } = result.data;

                setCameras(cameras);
                setTotal(count)
                console.log({ cameras })
            }
            catch (err) {
                console.log(err);
            }
        };

        findCameras();
    }, [search, page, limit]);

    return { cameras, total };
};

export default useCameras;