
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import DaysPicker from './DaysPicker';
import { grey, indigo } from '@material-ui/core/colors';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react';
import date from '../utils/date';
import Select from './Select';

const validationInitialState = {
    start_time: new Date().setHours(0, 0, 0, 0),
    end_time: new Date().setHours(23, 59, 59, 0),
};

const handleValidationDate = (state, action) => {
    switch (action?.type) {
        case 'change':
            return {
                ...state,
                [action?.time]: action?.value && action?.value?.format(),
            };
        case 'reset':
            return validationInitialState;
        case 'load':
            if (!action?.value) return state;
            let visitingTime = new Date();
            visitingTime.setHours(action?.value?.split(':')[0]);
            visitingTime.setMinutes(parseInt(action?.value?.split(':')[1]), 0, 0);
            // console.log(visitingTime)
            return {
                ...state,
                [action?.time]: date(visitingTime).format(),
            }
        default: return state;
    };
};

const ResendValidation = forwardRef(({ user }, ref) => {

    const classes = useStyles();

    const [visitingDay, setVisitingDay] = useState(user?.validation_date ?? Date.now())
    const [validationDate, dispatchValidationDate] = useReducer(handleValidationDate, validationInitialState);
    const [days, setDays] = useState(null)
    const [isConstantVisiting, setConstantVisiting] = useState(user?.constant_visiting ?? false)
    const daysRef = useRef(null);

    useEffect(() => {

        setDays(user?.visiting_days)
        dispatchValidationDate({ type: 'load', time: 'start_time', value: user?.visiting_start });
        dispatchValidationDate({ type: 'load', time: 'end_time', value: user?.visiting_end });

    }, [user]);

    useImperativeHandle(ref, () => ({
        getValues() {
            return {
                visiting_days: days,
                validation_date: visitingDay,
                visiting_start: date(validationDate?.start_time).format("HH:mm"),
                visiting_end: date(validationDate?.end_time).format("HH:mm"),
                constant_visiting: isConstantVisiting
            };
        }
    }));

    if (user?.person_type_id !== 4) return <></>

    return (
        <>
            <Grid container className={classes.formSection}>
                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                    VALIDADE DO ACESSO
                </Typography>

                <Grid container spacing={2}>
                    {
                        !isConstantVisiting && (
                            <Grid item xs={6} md={2}>
                                <DatePicker
                                    label="Dia da Visita"
                                    format="DD/MM/YYYY"
                                    id="date"
                                    value={visitingDay}
                                    placeholder="Dia da visita"
                                    onChange={date => setVisitingDay(date && date.format())}
                                    cancelLabel="cancelar"
                                    fullWidth={true}
                                    required={!user?.constant_visiting}
                                />
                            </Grid>
                        )
                    }
                    <Grid item xs={6} md={2}>
                        <TimePicker
                            label="Inicio"
                            format="HH:mm"
                            placeholder="Inicio da visita"
                            required={true}
                            fullWidth={true}
                            value={validationDate?.start_time}
                            onChange={(value) =>
                                dispatchValidationDate({ type: 'change', time: 'start_time', value: value })
                            }
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <TimePicker
                            label="Fim"
                            format="HH:mm"
                            placeholder="Fim da visita"
                            required={true}
                            fullWidth={true}
                            value={validationDate?.end_time}
                            onChange={(value) =>
                                dispatchValidationDate({ type: 'change', time: 'end_time', value: value })
                            }
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Select
                            label="Visita Frequente"
                            options={[
                                {
                                    label: "Sim",
                                    value: true,
                                },
                                {
                                    label: "Não",
                                    value: false,
                                },
                            ]}
                            textFieldProps={{
                                name: "constant_visiting",
                                value: isConstantVisiting,
                                onChange:
                                    () => setConstantVisiting(!isConstantVisiting),
                                fullWidth: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.formSection}
                style={{ display: (isConstantVisiting ? 'flex' : 'none') }}
            >
                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                    DIAS DE ACESSO
                </Typography>
                <Grid container spacing={2}>
                    <DaysPicker setDays={setDays} ref={daysRef} loading={days} />
                </Grid>
            </Grid>
        </>
    )
});

export default ResendValidation;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    faceImageContainer: {
        width: '100%',
        height: '303px',
        border: `solid 1px ${indigo[200]}`,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    faceImageContent: {
        width: '100%',
        flex: 7,
        backgroundColor: grey[50],
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(.5),
    },
    faceImageButton: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        flex: 1,
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    noImage: {
        textAlign: 'center',
        color: grey[400],
    },
    cameraContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& h6 > span': {
            marginLeft: theme.spacing(.5),
        },
        '&:hover': {
            boxShadow: '0 2px 10px 1px rgba(150,150,150,.3)',
        },
    },
    tableContainer: {
        maxHeight: '95%',
        width: '100%',
    },
    table: {
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
}));