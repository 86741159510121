import React, { useState, useContext, useReducer, useRef, useEffect } from 'react';
import {
    Toolbar,
    Paper,
    Grid,
    Typography,
    Button,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AppBar,
    Tabs,
    Tab,
    Box,
    IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';
import Select from '../../components/Select';
import Input from '../../components/Input';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

import AccessKeywords from './components/AccessKeywords';

import PropTypes from 'prop-types';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            style={{ marginTop: 10, }}
            {...other}
        >
            <Paper elevation={3}>
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </Paper>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const getWebhookType = (webhook) => {
    switch (webhook) {
        case 'user_access':
            return 'Acesso Facial';
        case 'face_detection':
            return 'Detecção Facial';
        case 'face_recognition':
            return 'Reconhecimento Facial';
        default:
            return '';
    }
};

const webhookInitialValues = {
    name: "",
    active: false,
    method: "get",
    url: "",
    body: {
        selection: "no_body",
        value: null,
    },
    auth: {
        selection: "no_auth",
        username: null,
        password: null
    },
    query: [],
    header: [],
};

const reduceWebook = (state, action) => {
    switch (action.type) {
        case "change":
            if (action.index >= 0 && action.innerKey) {
                const changedIndex = { ...state };
                changedIndex[action.name][action.index][action.innerKey] = action.value;
                return changedIndex;
            }

            if (action.innerKey)
                return {
                    ...state,
                    [action.name]: {
                        ...state[action.name],
                        [action.innerKey]: action.value,
                    }
                };

            return {
                ...state,
                [action.name]: action.value,
            };
        case "append_to":
            const appendingTo = { ...state };
            appendingTo[action.name].push({
                active: true,
                name: "",
                value: "",
            });
            return appendingTo;
        case "remove_from":
            const removingFrom = { ...state };
            if (!removingFrom[action.name][action.index])
                return state;

            removingFrom[action.name].splice(action.index, 1);
            return removingFrom;
        case "reset":
            return webhookInitialValues;
        default:
            return state;
    }
};

const QueryHeaderItem = ({
    index,
    origin,
    value,
    onChange,
    onRemove,
}) => {
    const [remove, setRemove] = useState(false);
    const timeoutRef = useRef(null);

    const removeItem = () => {
        if (!remove) {
            setRemove(true);
            timeoutRef.current = setTimeout(() => {
                setRemove(false);
            }, 3000)
        } else {
            setRemove(false);
            clearTimeout(timeoutRef.current);
            onRemove({
                origin: origin,
                index: index,
            });
        }
    }

    function changeItemName(e) {
        onChange({
            index: index,
            origin: origin,
            prop: "name",
            value: e.target.value,
        });
    }

    function changeItemValue(e) {
        onChange({
            index: index,
            origin: origin,
            prop: "value",
            value: e.target.value,
        });
    }

    function toggleItem(e) {
        onChange({
            index: index,
            origin: origin,
            prop: "active",
            value: e.target.checked,
        });
    }

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    return (
        <Grid item xs={12}>
            <Grid container spacing={3} alignItems='center'>
                <Grid item style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Checkbox
                        color='primary'
                        checked={value.active}
                        onChange={toggleItem}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Input
                        textFieldProps={{
                            placeholder: 'Nome',
                            value: value.name,
                            onChange: changeItemName,
                            fullWidth: true,
                            required: true,
                            disabled: !value.active,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Input
                        textFieldProps={{
                            placeholder: 'Valor',
                            value: value.value,
                            onChange: changeItemValue,
                            fullWidth: true,
                            required: true,
                            disabled: !value.active,
                        }}
                    />
                </Grid>
                <Grid item style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <IconButton
                        onClick={removeItem}
                    >
                        <DeleteIcon
                            color={
                                remove ? "secondary" : "#696969"
                            }
                        />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

const Webhook = ({
    data,
    setMessage,
}) => {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const [webhook, dispatchWebhook] = useReducer(reduceWebook, data);

    const [savingHook, setSavingHook] = useState(false);

    const [currentTab, setCurrentTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const [openKeywords, setOpenKeywords] = useState(false);

    function changeWebhookActive(e) {
        dispatchWebhook({
            type: "change",
            name: "active",
            value: e.target.value,
        });
    }

    function changeMethod(e) {
        dispatchWebhook({
            type: "change",
            name: "method",
            value: e.target.value,
        });
    }

    function changeUrl(e) {
        dispatchWebhook({
            type: "change",
            name: "url",
            value: e.target.value,
        });
    }

    function changeBodySelection(e) {
        dispatchWebhook({
            type: "change",
            name: "body",
            innerKey: "selection",
            value: e.target.value,
        });
    }

    function changeAuthSelection(e) {
        dispatchWebhook({
            type: "change",
            name: "auth",
            innerKey: "selection",
            value: e.target.value,
        });
    }

    function changeAuthUsername(e) {
        dispatchWebhook({
            type: "change",
            name: "auth",
            innerKey: "username",
            value: e.target.value,
        });
    }

    function changeAuthPassword(e) {
        dispatchWebhook({
            type: "change",
            name: "auth",
            innerKey: "password",
            value: e.target.value,
        });
    }

    function appendToQuery() {
        dispatchWebhook({
            type: "append_to",
            name: "query",
        });
    }

    function appendToHeader() {
        dispatchWebhook({
            type: "append_to",
            name: "header",
        });
    }

    function removeFromQueryOrHeader({ index, origin }) {
        dispatchWebhook({
            type: "remove_from",
            name: origin,
            index: index,
        });
    }

    function changeQueryHeader({ index, origin, prop, value }) {
        dispatchWebhook({
            type: "change",
            name: origin,
            index: index,
            innerKey: prop,
            value: value,
        });
    }

    function changeOpenKeywords({ target: { value } }) {
        setOpenKeywords(value);
    }

    async function saveWebhook() {
        setSavingHook(true);
        try {
            console.log(webhook);

            const response = await api.post('/webhook/modify', webhook);

            console.log(response.data)

            setMessage({
                open: true,
                variant: 'success',
                text: 'Webhook atualizado com sucesso',
            });
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error ?? "Não foi possível atualizar esse webhook",
            });
        }
        setSavingHook(false);
    }

    return (
        <Accordion
            style={{
                background: "#e6e9eb"
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>
                    {
                        getWebhookType(webhook.name)
                    }
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                <Select
                                    options={[
                                        { label: 'GET', value: 'get' },
                                        { label: 'POST', value: 'post' },
                                        { label: 'PUT', value: 'put' },
                                        { label: 'PATCH', value: 'patch' },
                                        { label: 'DELETE', value: 'delete' },
                                    ]}
                                    textFieldProps={{
                                        value: webhook.method,
                                        onChange: changeMethod,
                                        fullWidth: true,
                                        required: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <Input
                                    textFieldProps={{
                                        placeholder: 'URL',
                                        value: webhook.url,
                                        onChange: changeUrl,
                                        fullWidth: true,
                                        required: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={currentTab}
                                onChange={handleTabChange}
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="settings"
                            >
                                <Tab label="Body" {...a11yProps(0)} />
                                <Tab label="Auth" {...a11yProps(1)} />
                                <Tab label="Query" {...a11yProps(2)} />
                                <Tab label="Header" {...a11yProps(3)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={currentTab} index={0}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Select
                                        options={[
                                            { label: 'No Body', value: 'no_body' },
                                            { label: 'JSON', value: 'json' },
                                        ]}
                                        textFieldProps={{
                                            value: webhook.body.selection,
                                            onChange: changeBodySelection,
                                            fullWidth: true,
                                            required: true,
                                        }}
                                    />
                                </Grid>
                                {
                                    webhook.body.selection !== 'no_body' && (
                                        <Grid item xs={12}>
                                            <Editor
                                                value={webhook.body.value}
                                                onChange={(event) => {
                                                    dispatchWebhook({
                                                        type: "change",
                                                        name: "body",
                                                        innerKey: "value",
                                                        value: event
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </TabPanel>
                        <TabPanel value={currentTab} index={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Select
                                        options={[
                                            { label: "No Auth", value: "no_auth" },
                                            { label: 'Basic Auth', value: 'basic' },
                                            { label: 'Digest Auth', value: 'digest' },
                                        ]}
                                        textFieldProps={{
                                            value: webhook.auth.selection,
                                            onChange: changeAuthSelection,
                                            fullWidth: true,
                                            required: true,
                                        }}
                                    />
                                </Grid>
                                {
                                    webhook.auth.selection !== 'no_auth' && (
                                        <>
                                            <Grid item xs={12}>
                                                <Input
                                                    label="Username"
                                                    textFieldProps={{
                                                        placeholder: 'Username',
                                                        value: webhook.auth.username,
                                                        onChange: changeAuthUsername,
                                                        fullWidth: true,
                                                        required: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Input
                                                    label="Password"
                                                    textFieldProps={{
                                                        value: webhook.auth.password,
                                                        onChange: changeAuthPassword,
                                                        placeholder: 'password',
                                                        fullWidth: true,
                                                        required: true
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )
                                }
                            </Grid>
                        </TabPanel>
                        <TabPanel value={currentTab} index={2}>
                            <Grid container spacing={3}>
                                {
                                    webhook.query.map((queryItem, queryIndex) => (
                                        <QueryHeaderItem
                                            key={queryIndex}
                                            index={queryIndex}
                                            origin="query"
                                            value={queryItem}
                                            onChange={changeQueryHeader}
                                            onRemove={removeFromQueryOrHeader}
                                        />
                                    ))
                                }
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={appendToQuery}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={currentTab} index={3}>
                            <Grid container spacing={3}>
                                {
                                    webhook.header.map((headerItem, headerIndex) => (
                                        <QueryHeaderItem
                                            key={headerIndex}
                                            index={headerIndex}
                                            origin="header"
                                            value={headerItem}
                                            onChange={changeQueryHeader}
                                            onRemove={removeFromQueryOrHeader}
                                        />
                                    ))
                                }
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={appendToHeader}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Grid>
                    <Grid item>
                        <Select
                            label="Status"
                            options={[
                                { label: 'ATIVADO', value: true },
                                { label: 'DESATIVADO', value: false },
                            ]}
                            textFieldProps={{
                                value: webhook.active,
                                onChange: changeWebhookActive,
                                fullWidth: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Select
                            label="Mostrar palavras-chave"
                            options={[
                                { label: 'Sim', value: true },
                                { label: 'Não', value: false },
                            ]}
                            textFieldProps={{
                                value: openKeywords,
                                onChange: changeOpenKeywords,
                                fullWidth: true
                            }}
                        />
                    </Grid>
                    {
                        openKeywords && (
                            <AccessKeywords />
                        )
                    }
                    <PermissionWrapper route="/webhooks/modificar">
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={saveWebhook}
                                disabled={savingHook}
                            >
                                {
                                    savingHook
                                        ? "Salvando..."
                                        : "Salvar"
                                }
                            </Button>
                        </Grid>
                    </PermissionWrapper>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default function WebhookList() {
    const classes = useStyles();

    const [webhooks, setWebhooks] = useState([]);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    useEffect(() => {
        (async () => {
            try {
                const result = await api.get('/webhook/list');

                const { webhooks: fetchedWebhooks } = result.data;

                console.log({ fetchedWebhooks });

                setWebhooks(fetchedWebhooks);
            } catch (err) {
                console.log(err);
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error ?? "Não foi possível listar os webhooks",
                });
            }
        })();
    }, []);

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3} component={Paper}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Webhooks
                        </Typography>
                    </Grid>
                    {
                        !!webhooks.length && webhooks.map((webhook) => (
                            <Grid item xs={12}>
                                <Webhook
                                    key={webhook.id}
                                    data={webhook}
                                    setMessage={setMessage}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div >
    );


}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        color: grey[600],
        fontWeight: "bold"
    },
}));
