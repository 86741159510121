import React, {
    useReducer,
    forwardRef,
    useEffect,
    useImperativeHandle
} from 'react';
import {
    Button,
    Grid
} from "@material-ui/core";

const initialState = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
}

const handleDays = (state, action) => {
    let newState = {};

    switch (action.type) {
        case 'change':
            newState = {
                ...state,
                [action.day]: action.value
            };
            action.setDays(newState);
            console.log(newState);
            return newState;
        case 'reset':
            newState = initialState;
            action.setDays(newState);
            return newState;
        case 'load':
            console.log({ loading: action.loading })
            return action.loading;
        default: return state;
    }
}

const getDay = (day) => {
    switch (day) {
        case 'monday':
            return 'Segunda';
        case 'tuesday':
            return 'Terça';
        case 'wednesday':
            return 'Quarta';
        case 'thursday':
            return 'Quinta';
        case 'friday':
            return 'Sexta';
        case 'saturday':
            return 'Sabado';
        case 'sunday':
            return 'Domingo';
        default: //
    }
};

const DaysPicker = forwardRef(({ setDays, loading }, ref) => {

    // const classes = useStyles();

    const [days, dispatchDays] = useReducer(handleDays, initialState);

    useEffect(() => {
        console.log('days init')
        setDays(initialState);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (loading) {
            console.log('loading days')
            dispatchDays({ type: 'load', loading });
        }
    }, [loading]);

    useImperativeHandle(ref, () => ({
        resetValues() {
            dispatchDays({ type: 'reset', setDays });
        }
    }));

    const getVariant = (value) => {
        if (value === true) return 'contained'
        else return 'text'
    }

    return (
        <Grid container spacing={1}>
            {
                Object.keys(initialState).map((day) => (
                    <Grid item>
                        <Button
                            variant={getVariant(days[day])}
                            color='primary'
                            onClick={() => dispatchDays({ type: 'change', day: day, value: !days[day], setDays })}
                        >
                            { getDay(day) }
                        </Button>
                    </Grid>
                ))
            }
        </Grid>
    );
});

// const useStyles = makeStyles((theme) => ({

// }));

export default DaysPicker;