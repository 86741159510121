import React, { useState, useReducer, useEffect } from 'react';
import { useDevices, useCameras } from '../../hooks';

import {
    Toolbar,
    Paper,
    Button,
    Grid,
    Typography,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    FormControlLabel,
} from '@material-ui/core';

import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';

import { Formik } from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Popup from '../../components/Popup';
import Select from '../../components/Select';

import api from '../../services/api';
import { Stack } from '@mui/material';
import useExternalDevice from '../../hooks/useExternalDevices';


const reduceDevices = (state, action) => {
    let newValues = [];
    switch (action.type) {
        case 'single':
            if (action.checked && !state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                newValues.push(action.id);
            } else if (!action.checked && state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                let index = state[action.deviceArray].indexOf(action.id);
                newValues.splice(index, 1);
            } else {
                newValues = state[action.deviceArray];
            }
            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'all':
            if (action.checked) {
                newValues = state[action.deviceArray];
                for (let current of action.allDevices) {
                    if (!newValues.includes(current.id)) {
                        newValues.push(current.id);
                    }
                }
            } else {
                const allIds = action.allDevices.map(el => el.id)
                newValues = state[action.deviceArray].filter(el => !allIds.includes(el));
            }

            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'load':
            newValues = action.loading;
            return {
                ...state,
                [action.deviceArray]: newValues,
            };
        case 'reset':
            return { device_id: [], camera_id: [], external_id: [] };
        case 'reset_one':
            return {
                ...state,
                [action.deviceArray]: [],
            };
        default: return state;
    }
};

export default function SecurityPanelCreate() {
    const classes = useStyles();

    let initialValues = {
        name: '',
        type: '',
    };

    const [devicesSearch, setDevicesSearch] = useState('');
    const [devicesPage, setDevicesPage] = useState(0);
    const [devicesLimit, setDevicesLimit] = useState(10);
    const { devices, total: totalDevices } = useDevices({ search: devicesSearch, page: devicesPage, limit: devicesLimit, url: "list_dhi" });

    const [camerasSearch, setCamerasSearch] = useState('');
    const [camerasPage, setCamerasPage] = useState(0);
    const [camerasLimit, setCamerasLimit] = useState(10);
    const { cameras, total: totalCameras } = useCameras({ search: camerasSearch, page: camerasPage, limit: camerasLimit });

    const [externalDeviceSearch, setExternalDeviceSearch] = useState('');
    const [externalDevicePage, setExternalDevicePage] = useState(0);
    const [externalDeviceLimit, setExternalDeviceLimit] = useState(10);
    const { devices:externalDevices, total: totalExternalDevices } = useExternalDevice({ search: externalDeviceSearch, page: externalDevicePage, limit: externalDeviceLimit });


    const [openDevicesPopup, setOpenDevicesPopup] = useState(false);
    const [openCamerasPopup, setOpenCamerasPopup] = useState(false);
    const [openExternalCamerasPopup, setOpenExternalCamerasPopup] = useState(false);

    const [deviceIds, dispatchDeviceIds] = useReducer(reduceDevices, { device_id: [], camera_id: [], external_id: [] });


    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const [users, setUsers] = useState([]);
    const [usersSearch, setUsersSearch] = useState('');
    const [usersLimit, setUsersLimit] = useState(10);
    const [usersPage, setUsersPage] = useState(0);
    const [usersTotal, setUsersTotal] = useState(0);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [onlySelectedUsers, setOnlySelectedUsers] = useState(false);
    const [openUsersModal, setOpenUsersModal] = useState(false);

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const result = await api.get('/user/list_by_credentials', {
                    params: {
                        search: usersSearch,
                        limit: usersLimit,
                        page: usersPage,
                        only_selected: onlySelectedUsers,
                        selected_users: selectedUsers,
                    },
                });

                const { count, users } = result.data;

                console.log({ users })

                setUsers(users);
                setUsersTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadUsers();

    }, [usersLimit, usersPage, usersSearch, onlySelectedUsers, selectedUsers]);

    const checkUser = (type, isSelected, id) => {
        if (type === "single") {
            let newUsers = Object.assign([], selectedUsers);
            if (isSelected) {
                newUsers.push(id);
            } else {
                let index = selectedUsers.indexOf(id);
                newUsers.splice(index, 1);
            }
            setSelectedUsers(newUsers);
        } else if (type === 'all') {
            let allUsers = [];
            if (isSelected) {
                allUsers = Object.assign([], selectedUsers);
                users.forEach(user => {
                    if (!allUsers.includes(user.id)) {
                        allUsers.push(user.id)
                    }
                });
            } else {
                let userIds = users.map(el => el.id);
                allUsers = selectedUsers.filter(user => !userIds.includes(user));
            }
            setSelectedUsers(allUsers);
        }
    };

    const handleOnlySelectedUsers = (checked) => {
        setUsersPage(0);
        setOnlySelectedUsers(checked);
    };

    const savePanel = async (values, formik) => {
        try {
            const data = {
                ...values,
                cameras_ids: deviceIds.camera_id,
                devices_ids: deviceIds.device_id,
                external_devices_ids: deviceIds.external_id,
                users_ids: selectedUsers,
            };

            if (data.type === "access") {
                data.cameras_ids = [];
                data.external_devices_ids = [];
            } else if (data.type === "monitoring") {
                data.devices_ids = [];
            } else if (data.type === "") {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: "Selecione um tipo de painel válido",
                });
                formik.setSubmitting(false);
                return;
            }

            if (data.type === "access" && data.devices_ids.length === 0) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: "Selecione ao menos um dispositivo para o painel",
                });
                formik.setSubmitting(false);
                return;
            } else if (data.type === "monitoring" && data.cameras_ids.length === 0 && data.external_devices_ids.length === 0) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: "Selecione ao menos uma camera ou dispositivo externo para o painel.",
                });
                formik.setSubmitting(false);
                return;
            }

            const result = await api.post('/security_panel/create', data);

            const securityPanel = result.data;

            console.log({securityPanel});
            resetForm(formik);
            dispatchDeviceIds({ type: 'reset' });
            setSelectedUsers([]);

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `O painel ${securityPanel.name} foi criado com sucesso`,
            });
        } catch (err) {
            console.log(err)
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const resetForm = formik => {
        formik.resetForm();
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'device':
                setDevicesPage(newPage);
                break;
            case 'camera':
                setCamerasPage(newPage);
                break;
            case 'external_device':
                setExternalDevicePage(newPage);
                break;
            case 'user':
                setUsersPage(newPage);
                break;
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        switch (quantityOf) {
            case 'device':
                setDevicesLimit(parseInt(target.value, 10));
                setDevicesPage(0);
                break;
            case 'camera':
                setCamerasLimit(parseInt(target.value, 10));
                setCamerasPage(0);
                break;
            case 'external_device':
                setExternalDeviceLimit(parseInt(target.value, 10));
                setExternalDevicePage(0);
                break;
            case 'user':
                setUsersLimit(parseInt(target.value, 10));
                setUsersPage(0);
                break;
            default:
                console.log('Quantidade inválida');
        }
    };

    const checkSelection = (deviceArray, fetchedArray) => {
        if (!deviceIds[deviceArray].length) return false;

        let checkingArray = fetchedArray.map(el => el.id);
        let checked = checkingArray.every(el => {
            return deviceIds[deviceArray].includes(el)
        });
        return checked;
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid container xs={12}>
                        <Typography xs={6} variant="h5" className={classes.title}>
                            Criar Painel de Segurança
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={savePanel}
                    >
                        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="security-panel-create" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container className={classes.formSection}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                        Informações básicas
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Input
                                                                label="Nome"
                                                                id="name"
                                                                textFieldProps={{
                                                                    name: "name",
                                                                    value: values.name,
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                    required: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Select
                                                                label="Tipo"
                                                                id="type"
                                                                options={[
                                                                    {
                                                                        label: "Selecione um tipo",
                                                                        value: "",
                                                                    },
                                                                    {
                                                                        label: "Acesso",
                                                                        value: "access",
                                                                    },
                                                                    {
                                                                        label: "Monitoramento",
                                                                        value: "monitoring",
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    name: "type",
                                                                    onChange: (e) => {
                                                                        if (e.target.value === "access") {
                                                                            dispatchDeviceIds({
                                                                                type: 'reset_one',
                                                                                deviceArray: 'device_id',
                                                                            });
                                                                        } else if (e.target.value === "monitoring") {
                                                                            dispatchDeviceIds({
                                                                                type: 'reset_one',
                                                                                deviceArray: 'camera_id',
                                                                            });
                                                                        }
                                                                        handleChange(e);
                                                                    },
                                                                    fullWidth: true,
                                                                    value: values.type,
                                                                    required: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Grid item xs={12}>
                                                    <Grid container justify="center" spacing={3}>
                                                        {
                                                            values.type === "access" && (
                                                                <Grid item xs={6}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        fullWidth={true}
                                                                        onClick={() => setOpenDevicesPopup(true)}
                                                                    >
                                                                        Adcionar dispositivos {`(${deviceIds.device_id.length})`}
                                                                    </Button>
                                                                </Grid>
                                                            )
                                                        }
                                                        {
                                                            values.type === "monitoring" && (
                                                                <Grid item xs={6}>
                                                                    <Stack gap={2} direction={'row'}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            fullWidth={true}
                                                                            onClick={() => setOpenCamerasPopup(true)}
                                                                            >
                                                                            Adcionar câmeras embarcadas {`(${deviceIds.camera_id.length})`}
                                                                        </Button>

                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            fullWidth={true}
                                                                            onClick={() => setOpenExternalCamerasPopup(true)}
                                                                        >
                                                                            Adcionar câmeras externas {`(${deviceIds.external_id.length})`}
                                                                        </Button>
                                                                    </Stack>
                                                                </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Grid item xs={12}>
                                                    <Grid container justify="center" spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenUsersModal(true)}
                                                            >
                                                                Compartilhar com outros usuários {`(${selectedUsers.length})`}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container justify="flex-end">
                                                <Button
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    form="security-panel-create"
                                                    color="primary"
                                                    endIcon={<SaveIcon />}
                                                >
                                                    {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                </Button>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
            <Popup
                openPopup={openDevicesPopup}
                setOpenPopup={setOpenDevicesPopup}
                title="Dispositivos"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setDevicesSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="devices list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Descrição</TableCell>
                                        <TableCell className={classes.tableHead}>Local</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllDevices"
                                                color="primary"
                                                // checked={selectedDevices.length === devices.length}
                                                checked={checkSelection('device_id', devices)}
                                                onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                    type: 'all',
                                                    deviceArray: 'device_id',
                                                    allDevices: devices,
                                                    checked,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!devices && devices.map(device => (
                                        <TableRow key={device.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {device.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.description}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.place_devices?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addDevice"
                                                    color="primary"
                                                    checked={deviceIds.device_id.includes(device.id)}
                                                    onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                        type: 'single',
                                                        id: device.id,
                                                        deviceArray: 'device_id',
                                                        checked,
                                                    })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={totalDevices}
                            rowsPerPage={devicesLimit}
                            page={devicesPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'device')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'device')}
                        />
                    </Grid>
                </Grid>
            </Popup>


            <Popup
                openPopup={openExternalCamerasPopup}
                setOpenPopup={setOpenExternalCamerasPopup}
                title="Câmeras"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setExternalDeviceSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="camera list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Local</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivo</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllCameras"
                                                color="primary"
                                                // checked={selectedCameras.length === cameras.length}
                                                checked={checkSelection('external_id', externalDevices)}
                                                onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                    type: 'all',
                                                    deviceArray: 'external_id',
                                                    allDevices: externalDevices,
                                                    checked,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* {!!externalDevices && externalDevices.map(camera => {
                                        console.log(camera)
                                    } )} */}

                                    {!!externalDevices && externalDevices.map(camera => (
                                        <TableRow key={camera.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {camera.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.name}
                                            </TableCell>
                                             <TableCell className={classes.tableBody}>
                                                {camera.device_type}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.org.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addCamera"
                                                    color="primary"
                                                    checked={deviceIds.external_id.includes(camera.id)}
                                                    onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                        type: 'single',
                                                        id: camera.id,
                                                        deviceArray: 'external_id',
                                                        checked,
                                                    })}
                                                />
                                            </TableCell> 
                                        </TableRow>
                                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={totalExternalDevices}
                            rowsPerPage={externalDeviceLimit}
                            page={externalDevicePage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'external_device')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'external_device')}
                        />
                    </Grid>
                </Grid>
            </Popup>

            <Popup
                openPopup={openCamerasPopup}
                setOpenPopup={setOpenCamerasPopup}
                title="Câmeras"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setCamerasSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="camera list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Local</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivo</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllCameras"
                                                color="primary"
                                                // checked={selectedCameras.length === cameras.length}
                                                checked={checkSelection('camera_id', cameras)}
                                                onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                    type: 'all',
                                                    deviceArray: 'camera_id',
                                                    allDevices: cameras,
                                                    checked,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!cameras && cameras.map(camera => (
                                        <TableRow key={camera.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {camera.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {(camera.type === 'entry') ? 'Entrada' : (camera.type === 'internal') ? 'Interna' : (camera.type === 'exit') ? 'Saída' : 'Desconhecido'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.place_cameras?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.device?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addCamera"
                                                    color="primary"
                                                    checked={deviceIds.camera_id.includes(camera.id)}
                                                    onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                        type: 'single',
                                                        id: camera.id,
                                                        deviceArray: 'camera_id',
                                                        checked,
                                                    })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={totalCameras}
                            rowsPerPage={camerasLimit}
                            page={camerasPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'camera')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'camera')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openUsersModal}
                setOpenPopup={setOpenUsersModal}
                title="Adcione usuários que também teram acesso"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Grid item xs={4}>
                            <Input
                                icon={<SearchIcon style={{ color: grey[400] }} />}
                                iconPosition="start"
                                textFieldProps={{
                                    placeholder: 'Buscar…',
                                    onChange: ({ target }) => setUsersSearch(target.value),
                                    value: usersSearch,
                                    fullWidth: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                label="Mostrar apenas selecionados"
                                style={{
                                    userSelect: "none",
                                }}
                                checked={onlySelectedUsers}
                                onChange={({ target: { checked } }) => handleOnlySelectedUsers(checked)}
                                control={
                                    <Checkbox
                                        color='primary'

                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="users list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Pessoa</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllUsers"
                                                color="primary"
                                                checked={users.every(el => {
                                                    return selectedUsers.includes(el.id);
                                                })}
                                                onChange={({ target }) => checkUser('all', target.checked)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!users && users.map(person => (
                                        <TableRow key={person.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {person.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.doc_type && person?.doc_type === 'ic' ? 'RG' :
                                                    person?.doc_type === 'passport' ? 'Passaporte' :
                                                        person?.doc_type === 'other' ? 'Outro' :
                                                            person?.doc_type === 'unknown' ? 'CPF' :
                                                                'Não identificado'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.doc_number ? person.doc_number : "Não identificado"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.PersonType?.name ? person.PersonType?.name : "Não identificado"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addUser"
                                                    color="primary"
                                                    checked={selectedUsers.includes(person.id)}
                                                    onChange={({ target }) => checkUser('single', target.checked, person.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={usersTotal}
                            rowsPerPage={usersLimit}
                            page={usersPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'user')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'user')}
                        />
                    </Grid>
                </Grid>
            </Popup>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
        fontSize: '1.5rem',
    },
}));
