import React, { useState, useEffect, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import InputSearch from '../../components/InputSearch';
import Alert from '../../components/Alert';
import PopUpDetailsVehicleTable from '../../components/PopUpDetailsVehicleTable'
import DeletePopUp from '../../components/DeletePopUp'

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';

export default function VehicleList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const [vehicle, setVehicle] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([{ name: 'test', id: 0 }]);
    const [userDetails, setUserDetails] = useState([])
    const [refresh, setRefresh] = useState(true)
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openViewPopup, setOpenViewPopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingVehicleId, setDeletingVehicleId] = useState();

    const handleView = (id) => {
        setOpenViewPopup(true);
        viewVehicle(id);
    }

    const handleDelete = (id) => {
        setDeletingVehicleId(id);
        setOpenDeletePopup(true);
    }

    const viewVehicle = async (id) => {
        try {

            const result = await api.get(`/vehicle/list/${id}`);
            const { vehicles: requestedVehicle } = result.data;
            setUserDetails(requestedVehicle);

        } catch (err) {
            console.log(err)
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }
    useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/vehicle_type/list', {
                    params: {
                        show_exceptions: true,
                        limit: 30,
                    }
                });

                const { vehicleTypes } = result.data;

                setVehicleTypes(vehicleTypes);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, []);

    const deleteVehicle = async () => {
        try {
            setIsDeleting(true);

            const result = await api.post(`/vehicle/delete/${deletingVehicleId}`);
            const deletedVehicle = result.data;

            setIsDeleting(false);

            if (deletedVehicle) {
                setDeletingVehicleId(null);
                setOpenDeletePopup(false);
                setRefresh(!refresh);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `deletado com sucesso!`,
                });
            }
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    function updateRequest({ search }) {
        const loadData = async () => {
            try {
                const result = await api.get('/vehicle/list', {
                    params: {
                        search,
                        page,
                        limit,
                        users: [],
                    }
                });
                const { count, vehicles: requestedVehicle } = result.data;
                setVehicle(requestedVehicle);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const vehicleTypeName = (id) => {
        let result = ''
        if (!id)
            return "Não informado"

        vehicleTypes.forEach(current => {
            if (current.id === id) {
                result = current.name;
            }
        });

        return result;
    };
    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Veículos
                        </Typography>
                    </Grid>
                    <Grid item spacing={5} xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={updateRequest}
                            pagination={[refresh, page, limit]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="vehicle type list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Marca</TableCell>
                                        <TableCell className={classes.tableHead}>Modelo</TableCell>
                                        <TableCell className={classes.tableHead}>Cor</TableCell>
                                        <TableCell className={classes.tableHead}>Placa</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!vehicle && vehicle.map(current => (
                                        <TableRow key={current.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {current.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.car_brand}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.model}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.color}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.license_plate}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {console.log(current)}
                                                {vehicleTypeName(current?.vehicle_type_id)}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                    <>
                                                        <PermissionWrapper route="/veiculo/modificar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                className={classes.actionButton}
                                                                href={`/veiculo/modificar/${current.id}`}
                                                                id={`edit`}
                                                            >
                                                                <EditIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            style={{ background: '#ECB365' }}
                                                            className={classes.actionButton}
                                                            onClick={() => handleView(current.id)}
                                                            id={`view`}
                                                        >
                                                            <PeopleIcon style={{ fontSize: 20 }} />
                                                        </Button>
                                                        <PermissionWrapper route="/veiculo/deletar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="secondary"
                                                                className={classes.actionButton}
                                                                onClick={() => handleDelete(current.id)}
                                                                id={`delete`}
                                                            >
                                                                <DeleteIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                    </>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                <PopUpDetailsVehicleTable
                    user={userDetails}
                    open={openViewPopup}
                    setOpen={setOpenViewPopup}
                    title={'Veículo'}
                />
                <DeletePopUp
                    open={openDeletePopup}
                    setOpen={setOpenDeletePopup}
                    isDelete={isDeleting}
                    title={'Veículo'}
                    deleteFunction={() => deleteVehicle()}
                />
                    <PermissionWrapper route="/veiculo/criar">
                        <Fab
                            color="primary"
                            aria-label="add"
                            className={classes.fabButton}
                            href="/veiculo/criar"
                        >
                            <AddIcon />
                        </Fab>
                    </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    test: {
        '& > tr': {
            backgroundColor: '#CDCDCD',
        }
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
}));
