import React, {useState, useEffect} from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {useParams} from 'react-router-dom';

import SideMenu from '../../components/SideMenu';

import api from '../../services/api';

export default function OrganizationalList() {
    const classes = useStyles();

    const [org, setOrg] = useState([]);
    const [users, setUsers] = useState([]);
    

    const {id} = useParams();

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get(`/organizational_structure/view/${id}`);

                const {org} = result.data;
                
                setOrg(org);
                setUsers(org.org_users);
            }
            catch (err) {
                console.log(err);
            }
        };
        
        loadData();
    }, [id]);
    console.log(org);
    
    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h6" className={classes.title}>
                            {org.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.subtitle}>
                            Usuários:
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!users && users.map(user => (
                                        <TableRow key={user.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {user.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.doc_type && user?.doc_type === 'ic' ? 'RG' :
                                                        user?.doc_type === 'passport' ? 'Passaporte' :
                                                            user?.doc_type === 'other' ? 'Outro' :
                                                                user?.doc_type === 'unknown' ? 'CPF' :
                                                                    'Não identificado'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.doc_number ? user.doc_number : "Não identificado"}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {/* <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href={`/organizational/membros/${org.id}`}
                    >
                        <AddIcon />
                    </Fab> */}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    subtitle: {
        color: grey[700],
        fontSize: 24,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
}));
