import { useState, useEffect } from "react";

import api from "../services/api";

const useVehicleTypes = (props = {}) => {
    let { search = null, show_exceptions = false } = props;

    const [vehicleTypes, setVehicleTypes] = useState([]);

    useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/vehicle_type/list', {
                    params: {
                        search,
                        show_exceptions,
                        limit: 20,
                    }
                });

                const { vehicleTypes } = result.data;

                setVehicleTypes(vehicleTypes);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, [search, show_exceptions]);

    return vehicleTypes;
};

export default useVehicleTypes;
