import { createContext, useEffect, useState } from "react";
import api from "../services/api";
import storage from "../utils/storage";

export const PermissionsContext = createContext({
    allowedRoutes: [],
    hasPermission: (permission = "") => permission ? true : false,
    hasOneOfThePermissions: (permissions = []) => permissions ? true : false,
    hasAllOfThePermissions: (permissions = []) => permissions ? true : false,
    insertPermissions: (permissions) => { },
    PermissionWrapper: ({ route, otherwise = null, children }) => <></>,
    PermissionOneOfWrapper: ({ routes, otherwise = null, children }) => <></>,
});

export const PermissionsContextProvider = ({ children }) => {
    const [allowedRoutes, setAllowedRoutes] = useState([]);

    useEffect(() => {
        let interval = setInterval(async () => {
            try {
                const user = storage.getItem('@Identifik:user');

                if (!user)
                    return;

                const result = await api.get('/profile/get_user_profiles');

                const profiles = result.data;

                const routesAllowedToAccess = [];

                if (profiles?.length) for (const profile of profiles) {
                    if (profile.functionalities) for (const functionality of profile.functionalities) {
                        if (!routesAllowedToAccess.includes(functionality.router)) {
                            routesAllowedToAccess.push(functionality.router);
                        }
                    }
                }

                console.log({ routesAllowedToAccess });

                setAllowedRoutes(routesAllowedToAccess);

                storage.setItem("@Identifik:allowed_routes", routesAllowedToAccess);

            } catch (err) {

            }
        }, [10000]);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        try {
            const routes = storage.getItem("@Identifik:allowed_routes");

            if (!routes)
                return;

            setAllowedRoutes(routes);
        } catch (error) {

        }
    }, []);

    function hasPermission(permission = "") {
        return !!permission ? allowedRoutes.includes(permission) : false;
    }

    function hasOneOfThePermissions(permissions = []) {
        return permissions.some((permission) => allowedRoutes.includes(permission));
    }

    function hasAllOfThePermissions(permissions = []) {
        return permissions.every((permission) => allowedRoutes.includes(permission));
    }

    function insertPermissions(permissions) {
        setAllowedRoutes(permissions);
    }

    const PermissionWrapper = ({ route, otherwise = null, children }) => {
        if (hasPermission(route)) {
            return (
                <>
                    {children}
                </>
            );
        } else if (otherwise !== null && otherwise !== undefined) {
            return (
                <>
                    {otherwise}
                </>
            )
        }

        return (<></>);
    };

    const PermissionOneOfWrapper = ({ routes = [], otherwise = null, children }) => {
        if (routes.some((route) => allowedRoutes.includes(route))) {
            return (
                <>
                    {children}
                </>
            );
        } else if (otherwise !== null && otherwise !== undefined) {
            return (
                <>
                    {otherwise}
                </>
            )
        }

        return (<></>);
    };

    return (
        <PermissionsContext.Provider value={{
            allowedRoutes,
            hasPermission,
            hasOneOfThePermissions,
            hasAllOfThePermissions,
            insertPermissions,
            PermissionWrapper,
            PermissionOneOfWrapper,
        }}>
            {children}
        </PermissionsContext.Provider>
    );
};
