import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Typography,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function MultiPopup(props) {
    const { title, children, openPopup, setOpenPopup, popupIndex, width, height } = props;
    const classes = useStyles({ width: width ? width : '90%', height: height ? height : 'auto'});

    return (
        <Dialog open={openPopup} maxWidth="xl" classes={{ paper: classes.dialogWrapper }}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={() => setOpenPopup({ name: popupIndex, value: false })}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: ({ width }) => width,
        height: ({ height }) => height,
    },
    dialogTitle: {
        paddingRight: '0px',
    },
}))
