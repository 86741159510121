import React, { useState, useEffect, useReducer, useRef } from 'react';

import {
    Toolbar,
    Paper,
    Button,
    Grid,
    Typography,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';

import { grey, indigo } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import SaveIcon from '@material-ui/icons/Save';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

import { Formik } from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Autocomplete from '../../components/Autocomplete';

import api from '../../services/api';

const reduceDevices = (state, action) => {
    let newValues = [];
    console.log({ state })
    switch (action.type) {
        case 'single':
            if (action.checked && !state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                newValues.push(action.id);
            } else if (!action.checked && state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                let index = state[action.deviceArray].indexOf(action.id);
                newValues.splice(index, 1);
            } else {
                newValues = state[action.deviceArray];
            }
            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'all':
            if (action.checked) {
                newValues = state[action.deviceArray];
                for (let current of action.allDevices) {
                    if (!newValues.includes(current.id)) {
                        newValues.push(current.id);
                    }
                }
            } else {
                newValues = [];
            }
            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'reset':
            return { device_id: [], camera_id: [] };
        default: return state;
    }
};

export default function WantedCreate() {
    const classes = useStyles();

    const imageRef = useRef(null);

    let initialValues = {
        name: '',
        sex: 'unknown',
        role: 'visitor',
        face_image: null,
        notes: '',
        custom_inputs: [],
    };

    const [search, setSearch] = useState('');
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const [deviceIds, dispatchDeviceIds] = useReducer(reduceDevices, { device_id: [], camera_id: [] });

    const [image, setImage] = useState(null)

    const imageHandler = (e, setFieldValue) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setFieldValue('face_image', e.target.files[0]);
                console.log(e.target.files[0])
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        // console.log("imagem: " + image)
    }

    const ImagePreview = ({ image }) => {
        const src = image;

        return (
            <img
                src={src}
                alt="Imagem de identificação"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
        );
    };

    const [cameras, setCameras] = useState([]);

    useEffect(() => {
        const findCameras = async () => {
            try {
                const result = await api.get('/camera/list', {
                    params: {
                        search,
                        limit: 30,
                    }
                });

                const { cameras } = result.data;

                setCameras(cameras);
                console.log({ cameras })
            }
            catch (err) {
                console.log(err);
            }
        };

        findCameras();
    }, [search]);

    const saveUser = async (values, formik) => {
        try {

            const data = new FormData();

            data.append('face_image', values.face_image);

            if (!values.org_id) values.org_id = undefined;

            const userData = {
                ...values,
                camera_id: deviceIds.camera_id,
            };

            userData.face_image = undefined;

            data.append('user_data', JSON.stringify(userData));

            const result = await api.post('/wanted/create', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const { user } = result.data;
            dispatchDeviceIds({ type: 'reset' });
            setImage(null);
            resetForm(formik);

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Procurado ${user.name} cadastrado(a) com sucesso!`,
            });
        }
        catch (err) {
            console.log(err)
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const resetForm = formik => {
        formik.resetForm();
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };


    const [customInputs, setCustomInputs] = useState([]);

    useEffect(() => {
        const findInputs = async () => {
            try {
                const result = await api.get('/custom_input/list', {
                    params: {
                        target: ['wanted'],
                        page: 0,
                        limit: 30,
                    }
                });

                const { custom_inputs } = result.data;

                setCustomInputs(custom_inputs);
            }
            catch (err) {
                console.log(err);
            }
        };

        findInputs();
    }, []);

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid container xs={12}>
                        <Typography xs={6} variant="h5" className={classes.title}>
                            Cadastrar Pessoa Procurada
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={saveUser}
                    >
                        {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="guest-create" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3} className={classes.formSection} >
                                                    <div className={classes.faceImageContainer}
                                                        style={{
                                                                height: '300px',
                                                                width: '350px',
                                                            }}
                                                        >
                                                        <div
                                                            className={classes.faceImageContent}
                                                            style={{
                                                                width: '100%',
                                                                height: '200px',
                                                            }}
                                                        >
                                                            {image ? (
                                                                <ImagePreview image={image} />
                                                            ) : (
                                                                <div className={classes.noImage}>
                                                                    <PhotoCameraIcon />
                                                                    <Typography variant="subtitle2">
                                                                        SEM IMAGEM
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {image && (
                                                            <Button
                                                                className={classes.removeImageButton}
                                                                size="small"
                                                                type="button"
                                                                variant="outlined"
                                                                color="secondary"
                                                                endIcon={<DeleteIcon />}
                                                                fullWidth
                                                                onClick={() => {
                                                                    setFieldValue('face_image', null)
                                                                    setImage(null)
                                                                    imageRef.current.value = null;
                                                                }}
                                                            >
                                                                Remover
                                                            </Button>
                                                        )}
                                                        <Button
                                                            className={classes.faceImageButton}
                                                            type="button"
                                                            variant="outlined"
                                                            color="primary"
                                                            endIcon={<PhotoCameraIcon />}
                                                            fullWidth
                                                            id={'take_pic'}
                                                            component="label"
                                                        >
                                                            Adicionar Foto
                                                            <input
                                                                name='face_image'
                                                                type="file"
                                                                accept="image/*"
                                                                ref={imageRef}
                                                                onChange={(e) => imageHandler(e, setFieldValue)}
                                                                hidden
                                                                required={false}
                                                            />
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={9} className={classes.formSection}>
                                                    <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                        DADOS PESSOAIS
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={9}>
                                                            <Input
                                                                label="Nome/Apelido"
                                                                textFieldProps={{
                                                                    name: 'name',
                                                                    placeholder: 'Nome/Apelido',
                                                                    onChange: handleChange,
                                                                    value: values.name,
                                                                    fullWidth: true,
                                                                    required: false,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Sexo"
                                                                options={[
                                                                    { label: '-- Selecione --', value: 'unknown', id: 'unknown' },
                                                                    { label: 'Masculino', value: 'male', id: 'male' },
                                                                    { label: 'Feminino', value: 'female', id: 'female' },
                                                                ]}
                                                                textFieldProps={{
                                                                    name: 'sex',
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                    value: values.sex,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.formSection} style={{ marginTop: '10px' }}>
                                                        <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                            OBSERVAÇÕES
                                                        </Typography>
                                                        <Grid item xs={12} style={{ height: '100%' }}>
                                                            <textarea
                                                                placeholder="Escreva o texto aqui"
                                                                multiline
                                                                minRows={25}
                                                                style={{ width: '100%', border: '1px solid #c6c6c6', minHeight: '200px', resize: 'none', padding: '10px', fontSize: '1.2rem', color: grey[600], fontFamily: 'Arial, sans-serif' }}
                                                                value={values.notes}
                                                                onChange={({ target }) => setFieldValue('notes', target.value)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitleTwo}>
                                                    INFORMAÇÕES PERSONALIZADAS
                                                </Typography>
                                                <Grid container spacing={3}>
                                                    {customInputs.map((input, index) => {
                                                        if (index === 0 && values.custom_inputs.length === 0) {
                                                            values.custom_inputs = customInputs
                                                        }

                                                        return (
                                                            <Grid key={index} item xs={4}>
                                                                {
                                                                input.type === "normal" ? (

                                                                    <Input
                                                                        label={input.name}
                                                                        textFieldProps={{
                                                                            id: `custom_inputs${index}.information`,
                                                                            name: `custom_inputs[${index}].information`,
                                                                            onChange: handleChange,
                                                                            value: values.custom_inputs[index].hasOwnProperty('information') ? values.custom_inputs[index]['information'] : '',
                                                                            fullWidth: true,
                                                                            required: input.is_required,
                                                                        }}

                                                                        />
                                                                ) : (

                                                                        <Autocomplete
                                                                            label={input.name}
                                                                            id={`custom_inputs${index}.information`}
                                                                            options={input.options?.length && input.options.map((option) => ({ name: option, value: option, id: option }))}
                                                                            getOptionLabel={({ name }) => name || ''}
                                                                            onChange={(_, value) => setFieldValue(`custom_inputs[${index}].information`, value)}
                                                                            getOptionSelected={(option, value) => (option.id === value.id)}
                                                                            /* onInputChange={(_, value) => setPersonTypeSearch(value)} */
                                                                            value={values.custom_inputs[index].hasOwnProperty('information') ? values.custom_inputs[index]['information'] : ''}
                                                                            required={input.is_required}
                                                                            fullWidth
                                                                            />
                                                                    )
                                                                }
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    CÃMERAS
                                                </Typography>
                                                <Grid item xs={12}>
                                                    <Grid container style={{ padding: '0 0 3% 0', }}>
                                                        <Input
                                                            icon={<SearchIcon style={{ color: grey[400] }} />}
                                                            iconPosition="start"
                                                            textFieldProps={{
                                                                placeholder: 'Buscar…',
                                                                onChange: ({ target }) => setSearch(target.value),
                                                            }}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            style={{ marginLeft: '12px' }}
                                                            onClick={() => {
                                                                dispatchDeviceIds({
                                                                    type: 'all',
                                                                    checked: true,
                                                                    deviceArray: 'camera_id',
                                                                    allDevices: cameras,
                                                                });
                                                            }}
                                                        >
                                                            Selecionar todos
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            style={{ marginLeft: '12px' }}
                                                            onClick={() => {
                                                                dispatchDeviceIds({
                                                                    type: 'all',
                                                                    checked: false,
                                                                    deviceArray: 'camera_id',
                                                                });
                                                            }}
                                                        >
                                                            Desmarcar todos
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    {!!cameras && cameras.map((camera, index) => {
                                                        return (
                                                            <Grid item xs={4} key={camera.id}>
                                                                <FormControlLabel
                                                                    label={
                                                                        <Typography variant="subtitle2" style={{
                                                                            userSelect: 'none'
                                                                        }}>
                                                                            {camera.name}
                                                                            <Typography variant="caption">
                                                                                ({camera?.place_cameras?.name})
                                                                            </Typography>
                                                                        </Typography>
                                                                    }
                                                                    control={
                                                                        <Checkbox
                                                                            name={`camera_[${camera.id}]`}
                                                                            checked={deviceIds.camera_id.includes(camera.id)}
                                                                            color="primary"
                                                                            onChange={(e) => {
                                                                                dispatchDeviceIds({
                                                                                    type: 'single',
                                                                                    checked: e.target.checked,
                                                                                    deviceArray: 'camera_id',
                                                                                    id: camera.id
                                                                                });
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container justify="flex-end">
                                                <Button
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    form="guest-create"
                                                    color="primary"
                                                    endIcon={<SaveIcon />}
                                                >
                                                    {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                </Button>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    faceImageContainer: {
        width: '100%',
        height: '303px',
        border: `solid 1px ${indigo[200]}`,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    faceImageContent: {
        width: '100%',
        flex: 7,
        backgroundColor: grey[50],
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(.5),
    },
    faceImageButton: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        flex: 1,
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    noImage: {
        textAlign: 'center',
        color: grey[400],
    },
}));
