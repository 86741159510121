import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import {
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Checkbox,
    Paper,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    TextField,
    Button
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from "@material-ui/icons/Save";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import api from "../services/api";

import Alert from "./Alert";
import Input from "./Input";
import Popup from "./Popup";
import PopUpDetails from "./PopUpDetails";


export default function PopUpCreateVehicle({ access, open, setOpen, title }) {
    const classes = useStyles();

    const [vehicleTypeSearch, setVehicleTypeSearch] = useState();
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [vehicleType, setVehicleType] = useState({});
    const [openUsersPopup, setOpenUsersPopup] = useState(false);
    const [usersSearch, setUsersSearch] = useState('');
    const [usersLimit, setUsersLimit] = useState(10);
    const [usersPage, setUsersPage] = useState(0);
    const [usersTotal, setUsersTotal] = useState(0);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [message, setMessage] = useState({
        open: false,
        variant: "",
        text: "",
    });

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const result = await api.get('/user/list', {
                    params: {
                        search: usersSearch,
                        limit: usersLimit,
                        page: usersPage,
                    },
                });

                const { count, users } = result.data;

                setUsers(users);
                setUsersTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadUsers();
    }, [usersPage, usersLimit, usersSearch]);

    useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/vehicle_type/list', {
                    params: {
                        search: vehicleTypeSearch,
                        show_exceptions: false,
                        limit: 20,
                    }
                });

                const { vehicleTypes } = result.data;

                setVehicleTypes(vehicleTypes);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, [vehicleTypeSearch]);

    const saveVehicle = async (values, formik) => {
        try {
            const vehicleData = {
                ...values,
                users: selectedUsers,
            };
            console.log({values})
            const result = await api.post(
                "/vehicle/create",
                vehicleData
            );
            const { vehicle } = result.data;
            console.log(vehicle)

            formik.setSubmitting(false);

            setSelectedUsers([]);
            setMessage({
                open: true,
                variant: "success",
                text: `Veículo cadastrado com sucesso!`,
            });
            resetForm(formik);


        } catch (err) {
            console.log(err)
            setMessage({
                open: true,
                variant: "error",
                text: err.error,
            });
        }
    };

    const getColor = (color) => {
        let returning;
        switch (color) {
        case 'Unknown':
            returning = 'Desconhecida';
            break;
        case 'Pink':
            returning = 'Rosa';
            break;
        case 'Silver':
            returning = 'Prata';
            break;
        case 'Black':
            returning = 'Preto';
            break;
        case 'White':
            returning = 'Branco';
            break;
        case 'Orange':
            returning = 'Laranja';
            break;
        case 'Brown':
            returning = 'Marrom';
            break;
        case 'Red':
            returning = 'Vermelho';
            break;
        case 'Yellow':
            returning = 'Amarelo';
            break;
        case 'Gray':
            returning = 'Cinza';
            break;
        case 'Blue':
            returning = 'Azul';
            break;
        case 'Green':
            returning = 'Verde';
            break;
        default:
            returning = 'Desconhecida';

         };
        return returning
    };

    const optionsColor = [
        { label: 'Amarelo', value: 'Amarelo' },
        { label: 'Azul', value: 'Azul' },
        { label: 'Branco', value: 'Branco' },
        { label: 'Cinza', value:  'Cinza' },
        { label: 'Dourado', value: 'Dourado' },
        { label: 'Laranja', value: 'Laranja' },
        { label: 'Laranja escuro', value: 'Laranja escuro' },
        { label: 'Marrom', value:  'Marrom' },
        { label: 'Prata', value:  'Prata' },
        { label: 'Preto', value: 'Preto' },
        { label: 'Rosa', value: 'Rosa' },
        { label: 'Roxo', value: 'Roxo' },
        { label: 'Roxo escuro', value: 'Roxo escuro' },
        { label: 'Tomate', value:  'Tomate' },
        { label: 'Verde', value: 'Verde' },
        { label: 'Verde-oliva', value: 'Verde-oliva' },
        { label: 'Verde-oliva escuro', value: 'Verde-oliva' },
        { label: 'Vermelho', value: 'Vermelho' },
        { label: 'Outra', value: 'Outra' },
        { label: 'Desconhecida', value: 'Desconhecida' },
        // { label: 'Amarelo', value: ['Mistyrose', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Whitesmoke', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Dimgray', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Maroon', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Chartreuse', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Greenyellow', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Forestgreen', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Seagreen', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Deepskyblue', 'Amarelo'] },
    ];

    const resetForm = (formik) => {
        formik.resetForm();
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: "",
            text: "",
        });
    };

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'user':
                setUsersPage(newPage);
                break;
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        switch (quantityOf) {
            case 'user':
                setUsersLimit(parseInt(target.value, 10));
                setUsersPage(0);
                break;
            default:
                console.log('Quantidade inválida');
        }
    };

    const manipulateCheckBox = (checkbox, isSelected, id) => {
        switch (checkbox) {
            case 'user':
                let newSelectedUsers = Object.assign([], selectedUsers);
                if (isSelected) {
                    newSelectedUsers.push(id);
                } else {
                    let index = selectedUsers.indexOf(id);
                    newSelectedUsers.splice(index, 1);
                }
                setSelectedUsers(newSelectedUsers);
                break;
            case 'all_users':
                let allUsers = [];
                if (isSelected) {
                    allUsers = Object.assign([], selectedUsers);
                    users.forEach(user => {
                        if (!allUsers.includes(user.id)) {
                            allUsers.push(user.id)
                        }
                    });
                } else {
                    let userIds = users.map(el => el.id);
                    allUsers = selectedUsers.filter(user => !userIds.includes(user));
                }
                setSelectedUsers(allUsers);
                break;
            default:
                console.log('Tipo inválido');
        }
    };

    const initialValues = {
        car_brand: `${access?.vehicle_sign ? access?.vehicle_sign : 'Desconhecido'}`,
        model: `${access?.vehicle_sign ? access?.vehicle_sign : 'Desconhecido'}`,
        color: `${access?.vehicle_color ? access?.vehicle_color : 'Desconhecido'}`,
        license_plate: `${access?.plate_number ? access?.plate_number : 'Desconhecido'}`,
        users: [],
    }

    return (
        <>
            <PopUpDetails
                openPopup={open}
                setOpenPopup={setOpen}
                title={title}
                >
                <Formik
                    initialValues={initialValues}
                    onSubmit={saveVehicle}
                    enableReinitialize={true}
                >
                    {({
                    values,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}>
                            <Grid item xs={4} >
                                <TextField
                                    required
                                    variant='outlined'
                                    id="outlined-required"
                                    name="car_brand"
                                    label="Marca"
                                    value={values?.car_brand}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <TextField
                                    required
                                    variant='outlined'
                                    id="outlined-required"
                                    label="Modelo"
                                    name="model"
                                    value={values?.model}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Autocomplete
                                    id="type_of_vehicle"
                                    getOptionLabel={({ name }) => name || ''}
                                    options={vehicleTypes}
                                    onChange={(_, value) => setVehicleType(value)}
                                    getOptionSelected={(option, value) => (option.id === value.id)}
                                    onInputChange={(_, value) => setVehicleTypeSearch(value)}
                                    value={vehicleType}
                                    renderInput={(params) =>
                                        <TextField
                                         {...params}
                                          variant='outlined'
                                          label="Tipo"
                                          required
                                           />
                                        }

                                    />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete

                                    id="tipo_de_veiculo"
                                    options={optionsColor}
                                    value={getColor(values?.color)}
                                    renderInput={(params) =>
                                    <TextField
                                         {...params}
                                          variant='outlined'
                                          label="Cor"
                                          required
                                           />
                                        }

                                />
                            </Grid>
                            <Grid item xs={4} >
                                <TextField
                                    variant='outlined'
                                    required
                                    id="outlined-required"
                                    label="Placa"
                                    name="license_plate"
                                    value={values?.license_plate}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    onClick={() => setOpenUsersPopup(true)}
                                >
                                    Adicionar Pessoas {`(${selectedUsers.length})`}
                                </Button>
                            </Grid>
                            <Grid
                              container
                              justify="center"
                              spacing={3}>
                                <Button
                                    color="primary"
                                    className={classes.buttonSave}
                                    endIcon={<SaveIcon />}
                                    type="submit"
                                    variant="contained"
                                    >
                                    Salvar
                                </Button>
                             </Grid>
                            </Grid>
                    </form>
                    )}
                </Formik>
            </PopUpDetails >
            <Popup
                openPopup={openUsersPopup}
                setOpenPopup={setOpenUsersPopup}
                title="Adicione pessoas ao veículo"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={4}>
                            <Input
                                icon={<SearchIcon style={{ color: grey[400] }} />}
                                iconPosition="start"
                                textFieldProps={{
                                    placeholder: 'Buscar…',
                                    onChange: ({ target }) => setUsersSearch(target.value),
                                    fullWidth: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="users list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllUsers"
                                                color="primary"
                                                checked={users.every(el => {
                                                    return selectedUsers.includes(el.id);
                                                })}
                                                onChange={({ target }) => manipulateCheckBox('all_users', target.checked)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!users && users.map(user => (
                                        <TableRow key={user.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {user.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.doc_type && user?.doc_type === 'ic' ? 'RG' :
                                                    user?.doc_type === 'passport' ? 'Passaporte' :
                                                        user?.doc_type === 'other' ? 'Outro' :
                                                            user?.doc_type === 'unknown' ? 'CPF' :
                                                                'Não identificado'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.doc_number ? user.doc_number : "Não identificado"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addUser"
                                                    color="primary"
                                                    checked={selectedUsers.includes(user.id)}
                                                    onChange={({ target }) => manipulateCheckBox('user', target.checked, user.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={usersTotal}
                            rowsPerPage={usersLimit}
                            page={usersPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'user')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'user')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            {!!message.open && (
                <Alert
                    open={message.open}
                    autoHideDuration={6000}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    severity={message.variant}
                    onClose={closeMessage}
                >
                    {message.text}
                </Alert>
            )}
        </>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: "bold",
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    buttonSave: {
        marginTop: theme.spacing(1),
    },
    autocompletePadding: {
       padding: theme.spacing(1)
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    tableRow: {
        transition: '0.2s',
        '&:hover': {
            backgroundColor: grey[300],
        }
    },
}));
