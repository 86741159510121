import { Button, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import AccessMedia from "./AccessMedia";

import date from '../../../utils/date';
import { useState } from "react";

const DetailField = ({ label, value }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontWeight: 700,
                        color: grey[600],
                    }}
                >
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="subtitle1"
                    style={{
                        fontWeight: 700,
                        color: grey[500],
                        marginLeft: 8
                    }}
                >
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
};

const FullLogCard = ({ accessLog }) => {
    const classes = useStyles();

    const [showVideo, setShowVideo] = useState(false);
    const toggleShowVideo = () => setShowVideo((curr) => !curr);

    const getRejectionStatus = (rjStatus) => {
        let returning = '';
        switch (rjStatus) {
            case 'unauthorized_day':
                returning = 'DIA NÃO AUTORIZADO';
                break;
            case 'unauthorized_time':
                returning = 'HORA NÃO AUTORIZADA';
                break;
            case 'without_mask':
                returning = 'SEM MÁSCARA';
                break;
            case 'over_temperature':
                returning = 'ACIMA DA TEMPERATURA';
                break;
            case 'unauthorized_location':
                returning = 'LOCAL NÃO AUTORIZADO';
                break;
            case 'unknown_person':
                returning = 'PESSOA DESCONHECIDA';
                break;
            case 'wanted_person':
                returning = 'PESSOA PROCURADA';
                break;
            case 're_entry':
                returning = 'ENTRADA REPETIDA';
                break;
            case 'unknown_card':
                returning = 'CREDENCIAL DESCONHECIDA';
                break;
            case 'invalid_card':
                returning = 'CREDENCIAL INVÁLIDA';
                break;
            case 'related_already_with_a_dependant':
                returning = 'A PESSOA RELACIONADA JA ESTÁ SENDO ACOMPANHADA';
                break;
            case 'related_did_not_access':
                returning = 'A PESSOA RELACIONADA AINDA NÃO ACESSOU';
                break;
            case 'companion_out_of_time':
                returning = 'FORA DO HORÁRIO DE ACOMPANHAMENTO';
                break;
            default:
                returning = '-';
        }

        return returning;
    };

    const getDocumentType = (docType) => {
        let returning = '';

        switch (docType) {
            case 'ic':
                returning = 'RG';
                break;
            case 'passport':
                returning = 'Passaporte';
                break;
            case 'other':
                returning = 'Outro';
                break;
            case 'unknown':
                returning = 'CPF';
                break;
            default:
                returning = "-";
        }

        return returning;
    };

    return (
        <Grid
            container
            style={{
                height: "100%",
            }}
        >
            <Grid
                item
                xs={6}
                style={{
                    background: grey[300],
                    height: "100%",
                    padding: 12
                }}
            >
                <Grid
                    container
                    alignItems='center'
                    justify='center'
                    style={{
                        gap: 6,
                        height: '100%',
                    }}
                >
                    <Grid container alignItems='center' justify='center'>
                        <Typography
                            variant="subtitle1"
                            className={classes.accessImageAlt}
                        >
                            NO CADASTRO
                        </Typography>
                        <Grid
                            item
                            xs={12}
                            style={{
                                background: grey[500],
                                height: "300px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 15
                            }}
                        >
                            {
                                accessLog?.userFace !== null
                                    ? <img src={accessLog?.userFace} className={classes.video} alt="IMAGEM" />
                                    : (
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.accessImageAlt}
                                        >
                                            SEM IMAGEM CADASTRADA
                                        </Typography>
                                    )
                            }
                        </Grid>
                    </Grid>
                    <Grid container alignItems='center' justify='center'>
                        <Typography
                            variant="subtitle1"
                            className={classes.accessImageAlt}
                        >
                            NO ACESSO
                        </Typography>
                        <Grid
                            item
                            xs={12}
                            style={{
                                background: grey[500],
                                height: "300px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 15
                            }}
                        >
                            {
                                (accessLog.accessMedia) ? (
                                    <AccessMedia log={accessLog} showVideo={showVideo} />
                                ) : (
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.accessImageAlt}
                                    >
                                        NÃO POSSÍVEL RECUPERAR A IMAGEM/VIDEO DO ACESSO
                                    </Typography>
                                )
                            }
                        </Grid>
                        {
                            accessLog?.videoMedia && (
                                <>
                                    {
                                        showVideo ? (
                                            <Button
                                                onClick={toggleShowVideo}
                                                style={{
                                                    marginTop: 8,
                                                    background: "#9BA4B5",
                                                    color: grey[900],
                                                }}
                                            >
                                                Mostrar Imagem
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={toggleShowVideo}
                                                style={{
                                                    marginTop: 8,
                                                    background: "#9BA4B5",
                                                    color: grey[900],
                                                }}
                                            >
                                                Mostrar Video
                                            </Button>
                                        )
                                    }
                                </>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={6}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: grey[100],
                }}
            >
                <Grid container style={{ padding: 12, width: '100%' }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            className={classes.detailsSideTitle}

                        >
                            DADOS PESSOAIS
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="ID (Pessoa)"
                            value={accessLog?.user ? accessLog.user.id : "Desconhecido"}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Nome"
                            value={accessLog?.user ? accessLog.user.name : "Desconhecido"}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Documento"
                            value={
                                accessLog?.user?.doc_type
                                    ? `[${getDocumentType(accessLog?.user?.doc_type)}] ${accessLog?.user?.doc_number}`
                                    : 'Desconhecido'
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Tipo de Pessoa"
                            value={
                                accessLog?.user?.PersonType
                                    ? accessLog?.user?.PersonType.name
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Estrutura Organizacional"
                            value={
                                accessLog?.user?.OrganizationalStructure
                                    ? accessLog?.user?.OrganizationalStructure.name
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.divider}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            className={classes.detailsSideTitle}
                        >
                            DISPOSIVO E LOCALIZAÇÃO
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Tipo"
                            value={
                                accessLog?.device_id
                                    ? "Leitor Facial"
                                    : accessLog?.camera_id
                                        ? "Câmera"
                                        : "Desconhecido"
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Nome"
                            value={
                                accessLog?.Device
                                    ? accessLog?.Device?.name
                                    : accessLog?.camera
                                        ? accessLog?.camera?.name :
                                        "Desconhecido"
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Localização"
                            value={
                                accessLog?.Device
                                    ? accessLog?.Device?.place_devices?.name
                                    : accessLog?.camera
                                        ? accessLog?.camera?.place_cameras?.name :
                                        "Desconhecida"
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Ação"
                            value={
                                accessLog?.action_type && accessLog?.action_type === "entry"
                                    ? "Entrada"
                                    : accessLog?.action_type === "internal"
                                        ? "Acesso Interno"
                                        : accessLog?.action_type === "exit"
                                            ? "Saída"
                                            : "Desconhecida"
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Método"
                            value={
                                accessLog?.method && accessLog?.method === "facial"
                                    ? "Reconhecimento Facial"
                                    : accessLog?.method === "card"
                                        ? "Cartão"
                                        : "Desconhecido"
                            }
                        />
                    </Grid>
                    {
                        accessLog?.method === "card" && (
                            <Grid item xs={6}>
                                <DetailField
                                    label="Credencial"
                                    value={
                                        accessLog?.credential
                                            ? accessLog.credential
                                            : "Desconhecida"
                                    }
                                />
                            </Grid>
                        )
                    }
                    <Grid item xs={12} className={classes.divider}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            className={classes.detailsSideTitle}
                        >
                            SOBRE O ACESSO
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Horário"
                            value={
                                accessLog?.event_time
                                    ? date(accessLog.event_time).toLocaleFormat()
                                    : "-"
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DetailField
                            label="Status"
                            value={
                                accessLog?.allowed_access === true
                                    ? "Acesso Permitido"
                                    : "Acesso Negado"
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DetailField
                            label="Detalhes"
                            value={
                                accessLog?.rejection_status
                                    ? getRejectionStatus(accessLog.rejection_status)
                                    : "Nenhuma Irregularidade"
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FullLogCard;

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: 6,
        marginBottom: 6,
    },
    accessImageAlt: {
        fontWeight: 700,
        color: grey[900],
    },
    detailsSideTitle: {
        fontWeight: 700,
        color: grey[700],
    },
    video: {
        maxHeight: '100%',
        height: '100%',
        maxWidth: '100%',
        display: 'block',
        margin: 'auto',
    },
}));
