import React, { useEffect, useState, useReducer, useMemo } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
    ButtonGroup,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { makeStyles } from '@material-ui/core/styles';
import { grey, red, green } from '@material-ui/core/colors';

import ReactJson from 'react-json-view'

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Popup from '../../components/Popup';
import DateTimePicker from '../../components/DateTimePicker';

import api from '../../services/api';

import date from '../../utils/date';
import Select from '../../components/Select';
import Autocomplete from '../../components/Autocomplete';

const modelAndOperationType = {
    user: [
        { label: "Login", value: "login" },
        { label: "Criar", value: "create" },
        { label: "Vizualizar", value: "view" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Exportar", value: "export" },
        { label: "Reenviar", value: "resend" },
        { label: "Mapa de Calor", value: "heatmap" },
        { label: "Converter visitante para regular", value: "guest_to_regular" },
        { label: "Alterar privilégios", value: "toggle_privileges" },
    ],
    security_panel: [
        { label: "Visualizando Painel", value: "on_monitor" },
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
    ],
    person_type: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
    ],
    custom_input: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
    ],
    unified_report: [
        { label: "Exportar", value: "export" },
        { label: "Ver Imagem", value: "see_image" },
        { label: "Ver Video", value: "watch_video" },
    ],
    vehicle_report: [
        { label: "Exportar", value: "export" },
        { label: "Ver Video", value: "watch_video" },
    ],
    vehicle: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
        { label: "Ativar", value: "activate" },
    ],
    vehicle_type: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    case: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Vizualizar", value: "view" },
    ],
    case_file: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    case_type: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    device_group: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    export_people: [
        { label: "Exportar", value: "export" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    organizational_structure: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    profile: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    functionality: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    place: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    map: [
        { label: "Criar", value: "create" },
        { label: "Apagar", value: "delete" },
    ],
    notification: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    absence_notification: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    shortcut: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
    ],
    help_feed: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    camera: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
    ],
    device: [
        { label: "Criar", value: "create" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
        { label: "Vizualizar", value: "view" },
        { label: "Ver Capacidade", value: "get_capacity" },
        { label: "Limpar Dados", value: "remove_device_data" },
    ],
    school_settings: [
        { label: "Editar", value: "edit" },
        { label: "Vizualizar", value: "view" },
        { label: "Presença", value: "presence" },
    ],
    webhook: [
        { label: "Editar", value: "edit" },
        { label: "Processar", value: "request" },
    ],
    external_cam: [
        { label: "Criar", value: "create" },
        { label: "Vizualizar", value: "view" },
        { label: "Editar", value: "edit" },
        { label: "Apagar", value: "delete" },
    ],
    timeline: [
        { label: "Vizualizar", value: "view" },
        { label: "Ver Video", value: "watch_video" },
        { label: "Baixar Video", value: "download" },
    ],
};

const modelTranslation = Object.entries({
    user: "Pessoas",
    security_panel: "Painel de Segurança",
    person_type: "Tipo de Pessoa",
    custom_input: "Campo Personalizado",
    unified_report: "Relatório Unificado",
    vehicle_report: "Relatório de Veículos",
    vehicle: "Veículos",
    vehicle_type: "Tipo Veículo",
    case: "Casos",
    case_file: "Evidências",
    case_type: "Tipo de Caso",
    device_group: "Grupo de Dispositivos",
    export_people: "Exportar Pessoas",
    organizational_structure: "Estrutura Organizacional",
    profile: "Perfis de Acesso",
    functionality: "Funcionalidades",
    place: "Locais",
    map: "Mapas",
    notification: "Notificações de Acesso",
    absence_notification: "Notificações de Ausência",
    shortcut: "Atalhos",
    help_feed: "Artigos de Ajuda",
    device: "Dispositivos",
    camera: "Câmeras",
    school_settings: "Gerir",
    webhook: "Webhook",
    external_cam: "Câmeras Externas",
    timeline: "Timeline",
}).sort(([aKey, aValue], [bKey, bValue]) => {
    const nameA = aValue.toUpperCase(); // ignore upper and lowercase
    const nameB = bValue.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
}).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

const operationTranslation = Object.values(modelAndOperationType)
    .reduce((acc, model) => {
        for (const operation of model) {
            acc[operation.value] = operation.label;
        }

        return acc;
    }, {});

const filtersInititalValues = {
    model_reference: {
        active: false,
        value: null,
    },
    operation_type: {
        active: false,
        value: null,
    },
    operator_id: {
        active: false,
        value: null,
    },
    time_range: {
        active: false,
        value: {
            start: null,
            end: null,
        },
    },
};


const reduceFilters = (state, action) => {
    switch (action.type) {
        case "change":
            if (!!action.inner)
                return {
                    ...state,
                    [action.filter]: {
                        ...state[action.filter],
                        value: {
                            ...state[action.filter].value,
                            [action.inner]: action.value,
                        },
                    },
                };

            return {
                ...state,
                [action.filter]: {
                    ...state[action.filter],
                    value: action.value,
                },
            };
        case "toggle":
            return {
                ...state,
                [action.filter]: {
                    ...state[action.filter],
                    active: !state[action.filter].active,
                },
            };
        case "reset":
            return filtersInititalValues;
        default:
            return state;
    }
}

export default function OperationLogList() {
    const classes = useStyles();

    const [operationLogs, setOperationLogs] = useState(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const [openFilters, setOpenFilters] = useState(false);
    const [openControlDetails, setOpenControlDetails] = useState(false);

    const [selectedLog, setSelectedLog] = useState();

    const [filters, dispatchFilters] = useReducer(reduceFilters, filtersInititalValues);
    const [appliedFilters, setAppliedFilters] = useState(filtersInititalValues);

    const [operatorSearch, setOperatorSearch] = useState("");
    const [possibleOperators, setPossibleOperators] = useState([]);

    const [requestingInfo, setRequestingInfo] = useState(false);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get('/operation_log/list', {
                    params: {
                        filters: {
                            ...appliedFilters,
                            operator_id: {
                                active: appliedFilters.operator_id.active,
                                value: appliedFilters.operator_id.value ? appliedFilters.operator_id.value.id : null,
                            },
                        },
                        limit,
                        page,
                    },
                });

                const { count, operationLogs } = result.data;

                setOperationLogs(operationLogs);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            }
        };

        loadData();

        const load = setInterval(loadData, 10000);

        return () => {
            clearInterval(load);
        };
    }, [limit, page, appliedFilters]);

    useEffect(() => {
        const findPOssibleOperatos = async () => {
            try {
                const result = await api.get('/user/list', {
                    params: {
                        search: operatorSearch,
                    },
                });

                const { users } = result.data;

                setPossibleOperators(users);
            }
            catch (err) {
                // setMessage({
                //     open: true,
                //     variant: 'error',
                //     text: err.error,
                // });
                console.log(err);
            }
        };

        findPOssibleOperatos();
    }, [operatorSearch]);

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const toggleField = (field) => {
        dispatchFilters({
            type: "toggle",
            filter: field,
        });
    };

    const operationTypes = useMemo(() => {
        const value = filters.model_reference.value;
        if (!value)
            return [];

        return modelAndOperationType[value]
            .sort((a, b) => {
                const nameA = a.label.toUpperCase(); // ignore upper and lowercase
                const nameB = b.label.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

    }, [filters.model_reference.value]);

    const getOperationInfo = async (operationId) => {
        setRequestingInfo(true);
        try {
            const response = await api.get(`/operation_log/view/${operationId}`);

            const { log } = response.data;

            setSelectedLog(log);
            setOpenControlDetails(true);
        } catch (error) {
            setMessage({
                open: true,
                variant: 'error',
                text: error.error,
            });
        }
        setRequestingInfo(false);
    }

    const applyFilters = () => {
        setAppliedFilters(filters);
        setOpenFilters(false);
    };

    const resetFilters = () => {
        setAppliedFilters(filtersInititalValues);
        dispatchFilters({
            type: "reset",
        });
        setOpenFilters(false);
    };

    const setTimeRange = (time) => {
        let start = null;
        let end = date().toISOString();
        switch (time) {
            case "12h":
                start = date().subtract(12, "hours").toISOString();
                break;
            case "24h":
                start = date().subtract(24, "hours").toISOString();
                break;
            case "today":
                start = date().startOf("day").toISOString();
                end = date().endOf("day").toISOString();
                break;
            case "7d":
                start = date().subtract(7, "days").toISOString();
                break;
            case "this-week":
                start = date().startOf("week").toISOString();
                end = date().endOf("week").toISOString();
                break;
            case "this-month":
                start = date().startOf("month").toISOString()
                end = date().endOf("month").toISOString();
                break;
            case "all":
                start = null;
                end = null;
                dispatchFilters({
                    type: "toggle",
                    filter: "time_range"
                });
                break;
            default:
                end = null;
        }
        dispatchFilters({
            type: "change",
            filter: "time_range",
            inner: "start",
            value: start,
        });
        dispatchFilters({
            type: "change",
            filter: "time_range",
            inner: "end",
            value: end,
        });
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Registros de Operação
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item style={{ marginRight: '1%' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpenFilters(true)}
                                >
                                    Adcionar/Editar Filtros
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="operation logs">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Modelo</TableCell>
                                        <TableCell className={classes.tableHead}>Operação</TableCell>
                                        <TableCell className={classes.tableHead}>Operador</TableCell>
                                        <TableCell className={classes.tableHead}>Horário</TableCell>
                                        <TableCell className={classes.tableHead}>Informações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!operationLogs && operationLogs.map(log => (
                                        <TableRow key={log.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {log.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {modelTranslation[log.model_reference] ?? "Desonhecido"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {operationTranslation[log.operation_type] ?? "Desconhecida"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    !!log.operator_id
                                                        ? log.operator.name
                                                        : "-"
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {date(log.created_at).toLocaleFormat()}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    className={classes.actionButton}
                                                    onClick={() => getOperationInfo(log.id)}
                                                >
                                                    <TextSnippetIcon style={{ fontSize: 20 }} />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                    <Popup
                        openPopup={openControlDetails}
                        setOpenPopup={setOpenControlDetails}
                        title="Detalhes da Operação"
                        width='80%'
                    >
                        <Grid container spacing={2}>
                            {
                                !!selectedLog?.status_message && (
                                    <Grid item xs={12} style={{ display: 'flex', gap: '8px', flexDirection: "column" }}>
                                        <Typography
                                            variant='subtitle1'
                                            style={{
                                                fontSize: '1.3rem',
                                                fontWeight: "bolder",
                                                color: grey[800],
                                                width: "100%"
                                            }}
                                        >
                                            Mensagem de Status
                                        </Typography>
                                        <Typography
                                            variant='subtitle1'
                                            style={{
                                                fontSize: '1.3rem',
                                                marginLeft: "1rem"
                                            }}
                                        >
                                            {selectedLog?.status_message}
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                !!selectedLog?.incoming_state && (
                                    <Grid item xs={12} style={{ display: 'flex', gap: '8px', alignItems: 'center', flexDirection: "column" }}>
                                        <Typography
                                            variant='subtitle1'
                                            style={{
                                                fontSize: '1.3rem',
                                                fontWeight: "bolder",
                                                color: grey[800],
                                                width: "100%"
                                            }}
                                        >
                                            Dados Recebidos
                                        </Typography>
                                        <ReactJson
                                            src={selectedLog.incoming_state}
                                            theme="monokai"
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Popup>
                    <Popup
                        openPopup={openFilters}
                        setOpenPopup={setOpenFilters}
                        title="Filtros"
                        width='60%'
                    >
                        <Grid container spacing={2}>
                            <Grid xs={12} item className={classes.filterItem}>
                                <Grid item xs={3} className={classes.checkboxContainer}>
                                    <FormControlLabel
                                        label={
                                            <Typography variant="subtitle1" className={classes.label}>
                                                Modelo
                                            </Typography>
                                        }
                                        control={
                                            <Checkbox
                                                checked={filters.model_reference.active}
                                                onChange={() => toggleField('model_reference')}
                                                color="primary"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <Select
                                        options={[
                                            { label: '|---- Selecione um valor ----|', value: null },
                                            ...Object.entries(modelTranslation)
                                                .map(([value, label]) => ({
                                                    label,
                                                    value,
                                                })),
                                        ]}
                                        textFieldProps={{
                                            onChange: ({ target }) => {
                                                dispatchFilters({
                                                    type: "change",
                                                    filter: "model_reference",
                                                    value: target.value,
                                                });
                                                dispatchFilters({
                                                    type: "change",
                                                    filter: "operation_type",
                                                    value: null,
                                                });
                                            },
                                            value: filters.model_reference.value,
                                            fullWidth: true,
                                            disabled: !filters.model_reference.active
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12} item className={classes.filterItem}>
                                <Grid item xs={3} className={classes.checkboxContainer}>
                                    <FormControlLabel
                                        label={
                                            <Typography variant="subtitle1" className={classes.label}>
                                                Operação
                                            </Typography>
                                        }
                                        control={
                                            <Checkbox
                                                checked={filters.operation_type.active}
                                                onChange={() => toggleField('operation_type')}
                                                color="primary"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <Select
                                        options={[
                                            { label: '|---- Selecione um valor ----|', value: null },
                                            ...operationTypes,
                                        ]}
                                        textFieldProps={{
                                            onChange: ({ target }) => dispatchFilters({
                                                type: "change",
                                                filter: "operation_type",
                                                value: target.value,
                                            }),
                                            value: filters.operation_type.value,
                                            fullWidth: true,
                                            disabled: !filters.operation_type.active
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12} item className={classes.filterItem}>
                                <Grid item xs={3} className={classes.checkboxContainer}>
                                    <FormControlLabel
                                        label={
                                            <Typography variant="subtitle1" className={classes.label}>
                                                Operador
                                            </Typography>
                                        }
                                        control={
                                            <Checkbox
                                                checked={filters.operator_id.active}
                                                onChange={() => toggleField('operator_id')}
                                                color="primary"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <Autocomplete
                                        placeholder="Nome"
                                        options={possibleOperators}
                                        getOptionLabel={({ name }) => name || ''}
                                        onChange={(_, value) => dispatchFilters({
                                            type: "change",
                                            filter: "operator_id",
                                            value: value,
                                        })}
                                        getOptionSelected={(option, value) => (option.name === value.name)}
                                        onInputChange={(_, value) => setOperatorSearch(value)}
                                        value={filters.operator_id.value}
                                        fullWidth
                                        disabled={!filters.operator_id.active}
                                    // textFieldProps={{
                                    //     disabled: !checkedFilters.checkPerson
                                    // }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12} item className={classes.filterItem}>
                                <Grid item xs={3} className={classes.checkboxContainer}>
                                    <FormControlLabel
                                        label={
                                            <Typography variant="subtitle1" className={classes.label}>
                                                Horário de Ocorrência
                                            </Typography>
                                        }
                                        control={
                                            <Checkbox
                                                checked={filters.time_range.active}
                                                onChange={() => toggleField("time_range")}
                                                color="primary"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={9} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Grid item>
                                        <DateTimePicker
                                            className={classes.dateTimeInput}
                                            format="DD/MM/YYYY HH:mm"
                                            value={filters.time_range.value.start}
                                            placeholder="Início"
                                            onChange={date => dispatchFilters({
                                                type: "change",
                                                filter: "time_range",
                                                inner: "start",
                                                value: date && date.format(),
                                            })}
                                            cancelLabel="cancelar"
                                            disabled={!filters.time_range.active}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DateTimePicker
                                            className={classes.dateTimeInput}
                                            format="DD/MM/YYYY HH:mm"
                                            value={filters.time_range.value.end}
                                            placeholder="Fim"
                                            onChange={date => dispatchFilters({
                                                type: 'change',
                                                filter: "time_range",
                                                inner: 'end',
                                                value: date && date.format(),
                                            })}
                                            cancelLabel="cancelar"
                                            disabled={!filters.time_range.active}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <ButtonGroup variant='outlined'>
                                    <Button onClick={() => setTimeRange('today')} disabled={!filters.time_range.active}>
                                        Hoje
                                    </Button>
                                    <Button onClick={() => setTimeRange('12h')} disabled={!filters.time_range.active}>
                                        Últimas 12 horas
                                    </Button>
                                    <Button onClick={() => setTimeRange('24h')} disabled={!filters.time_range.active}>
                                        Últimas 24 horas
                                    </Button>
                                    <Button onClick={() => setTimeRange('7d')} disabled={!filters.time_range.active}>
                                        Últimos 7 dias
                                    </Button>
                                    <Button onClick={() => setTimeRange('this-week')} disabled={!filters.time_range.active}>
                                        Essa semana
                                    </Button>
                                    <Button onClick={() => setTimeRange('this-month')} disabled={!filters.time_range.active}>
                                        Esse mês
                                    </Button>
                                    <Button onClick={() => setTimeRange('all')} disabled={!filters.time_range.active}>
                                        Todo o período
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid container alignItems='center' justify='center' spacing={3}>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={applyFilters}
                                        fullWidth
                                    >
                                        Aplicar
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={resetFilters}
                                        fullWidth
                                    >
                                        Resetar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Popup>
                    <Backdrop className={classes.backdrop} open={requestingInfo}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}

            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    enabledNotification: {
        backgroundColor: green[700],
        color: '#FFF',
    },
    disabledNotification: {
        backgroundColor: red[700],
        color: '#FFF',
    },
    label: {
        color: '#9e9e9e',
        fontWeight: 'bold',
        userSelect: 'none',
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    filterItem: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
