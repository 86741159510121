import React, { useState, useEffect, useContext, useRef, useImperativeHandle } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Popover, Avatar, Divider, Grid, Button } from '@material-ui/core';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import { blue, grey, red } from '@material-ui/core/colors';
import IconButton from '@mui/material/IconButton';
import CommuteIcon from '@mui/icons-material/Commute';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
// import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
// import VideocamIcon from '@material-ui/icons/Videocam';
import DeviceIcon from '@material-ui/icons/DeviceHub';
// import LockIcon from '@material-ui/icons/Lock';
import HelpIcon from '@material-ui/icons/HelpOutline';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ListAltIcon from '@material-ui/icons/ListAlt';
// import LocationIcon from '@material-ui/icons/Room';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Extension from '@material-ui/icons/Extension';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import HomeIcon from '@material-ui/icons/Home';
import PersonTypeIcon from '@material-ui/icons/Accessibility';
import ReportIcon from '@material-ui/icons/Assessment';
import CasesIcon from '@material-ui/icons/Policy';
import CaseIcon from '@material-ui/icons/Gavel';
import CaseFileIcon from '@material-ui/icons/PermMedia';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@material-ui/icons/Map';
import InputIcon from '@material-ui/icons/Input';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo-horizontal.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExportIcon from '@mui/icons-material/IosShare';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import ApiIcon from '@mui/icons-material/Api';
import SettingsIcon from '@material-ui/icons/Settings';
import MonitorIcon from '@mui/icons-material/Monitor';
import RouteIcon from '@mui/icons-material/Route';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import WebhookIcon from '@mui/icons-material/Webhook';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

import Popup from "./Popup.js";
import SettingsModal from "./SettingsModal.js";

import { AuthContext } from '../providers/Auth';
import { ShortcutsContext } from '../providers/Shortcuts';

import storage from '../utils/storage';
import { PermissionsContext } from '../providers/Permissions';

import config from "../configs/config"
import api from "../services/api"

const drawerWidth = 300;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 5px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 0px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const audioUrl = config.useSsl ? (
    `https://${config.host}:${config.port}/midias/audios/danger_alert_2.mp3`
) : (
    `http://${config.host}:${config.port}/midias/audios/danger_alert_2.mp3`
)

const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggleAudio = () => setPlaying(!playing);

    const playAudio = () => setPlaying(true);

    const stopAudio = () => setPlaying(false);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing, audio]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => {
            console.log("ended")
            if (!playing) {
                console.log("play again")
                audio.play()
            } else {
                console.log("stop play")
                setPlaying(false)
            }
        });
        return () => {
            audio.addEventListener('ended', () => {
                console.log("ended")
                if (!playing) {
                    console.log("play again")
                    audio.play()
                } else {
                    console.log("stop play")
                    setPlaying(false)
                }
            });
        };
    }, []);

    return [playing, toggleAudio, playAudio, stopAudio];
};


const SideMenu = React.forwardRef(() => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = React.useState(false);

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [openSettings, setOpenSettings] = useState(false);
    const toggleSettings = () => setOpenSettings(!openSettings);

    const classes = useStyles();
    const menuRef = useRef(null);

    const { storeSystemShortcut, invalidateSystemShortcut } = useContext(ShortcutsContext);

    useEffect(() => {
        storeSystemShortcut({
            referrer: 'MENU',
            callback: () => {
                menuRef.current?.toggle();
            }
        });

        return () => invalidateSystemShortcut('MENU')
    }, [invalidateSystemShortcut, storeSystemShortcut]);

    useImperativeHandle(menuRef, () => ({
        toggle() {
            setIsOpen(!isOpen)
        }
    }));

    const [display, setDisplay] = useState(false);
    const [reportDisplay, setReportDisplay] = useState(false);
    const [caseDisplay, setCaseDisplay] = useState(false);
    const [vehicleDisplay, setVehicleDisplay] = useState(false);
    const [deviceDisplay, setDeviceDisplay] = useState(false);
    const [notificationDisplay, setNotificationDisplay] = useState(false);
    const [displayArrow, setDisplayArrow] = useState(false);
    const [authenticated, setAuthenticated] = useState(null);

    useEffect(() => {
        const user = storage.getItem('@Identifik:user');

        setAuthenticated(user);
    }, []);

    const handleDrawerOpen = () => {
        setIsOpen(true);
    };

    const handleDrawerClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            setDisplayArrow(true)
        } else {
            setDisplayArrow(false)
            setDisplay(false)
        }
    }, [isOpen])

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const auth = useContext(AuthContext);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const signOut = () => auth.signOut();

    const firstLetterName = () => {
        const { name } = storage.getItem('@Identifik:user');

        return name.charAt(0);
    };

    const openUserTree = () => {
        handleDrawerOpen();
        setDisplay(!display);
    }

    const toggleFocus = () => setDisplay(true);
    const toggleBlur = () => setDisplay(false);

    const openVehicleTree = () => {
        handleDrawerOpen();
        setVehicleDisplay(!vehicleDisplay);
    }

    const vehicleToggleFocus = () => setVehicleDisplay(true);
    const vehicleToggleBlur = () => setVehicleDisplay(false);

    const openDevicesTree = () => {
        handleDrawerOpen();
        setDeviceDisplay(!deviceDisplay);
    }

    const devicesToggleFocus = () => setDeviceDisplay(true);
    const devicesToggleBlur = () => setDeviceDisplay(false);


    const openReportTree = () => {
        handleDrawerOpen();
        setReportDisplay(!reportDisplay);
    }

    const reportToggleFocus = () => setReportDisplay(true);
    const reportToggleBlur = () => setReportDisplay(false);

    const openCaseTree = () => {
        handleDrawerOpen();
        setCaseDisplay(!caseDisplay);
    }

    const caseToggleFocus = () => setCaseDisplay(true);
    const caseToggleBlur = () => setCaseDisplay(false);

    const openNotificationTree = () => {
        handleDrawerOpen();
        setNotificationDisplay(!notificationDisplay);
    }

    const notificationToggleFocus = () => setNotificationDisplay(true);
    const notificationToggleBlur = () => setNotificationDisplay(false);

    const [openDangerAlert, setOpenDangerAlert] = useState(false);
    const [playing, toggle, playAudio, stopAudio] = useAudio(audioUrl);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const createLog = async (starting) => {
        try {
            const data = {
                name: starting ? "Botão Pânico Iniciado - " + Date.now() : "Botão Pânico Parado - " + Date.now(),
                description: starting ? "Botão Pânico Iniciado" : "Botão Pânico Parado",
                case_type_id: null,
                files: [],
            };


            await api.post('/case/create', data);
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={isOpen}>
                <Toolbar style={{ background: '#333' }} >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        style={{ color: '#C5CAE9' }}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(isOpen && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.logoContainer}>
                        <img src={logo} alt="logo" className={classes.logo} />
                    </div>
                    <div>
                        <PermissionOneOfWrapper routes={[
                            "/security_alert/start",
                            "/security_alert/stop",
                        ]}>
                            <IconButton
                                edge="end"
                                aria-label="user options"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                // color="secondary"
                                onClick={() => {
                                    setOpenDangerAlert(true)
                                }}
                                // className={classes.danger_alert}
                                style={{
                                    color: "#fff",
                                    backgroundColor: red[700],
                                    marginRight: 4,
                                }}
                            >
                                <GppMaybeIcon />
                            </IconButton>
                            <Popup
                                openPopup={openDangerAlert}
                                setOpenPopup={setOpenDangerAlert}
                                title="Alerta de Segurança"
                            >
                                <Grid container spacing={3} justify='center' alignItems='center'>
                                    <PermissionWrapper route="/security_alert/start">
                                        <Grid item xs={12}>
                                            <Button
                                                variant='contained'
                                                color="secondary"
                                                fullWidth={true}
                                                onClick={async () => {
                                                    playAudio();
                                                    await createLog(true);
                                                }}
                                            >
                                                Disparar
                                            </Button>
                                        </Grid>
                                    </PermissionWrapper>
                                    <PermissionWrapper route="/security_alert/stop">
                                        <Grid item xs={12}>
                                            <Button
                                                variant='contained'
                                                color="primary"
                                                fullWidth={true}
                                                onClick={async () => {
                                                    stopAudio();
                                                    await createLog(false);
                                                }}
                                            >
                                                Parar
                                            </Button>
                                        </Grid>
                                    </PermissionWrapper>
                                </Grid>
                            </Popup>
                        </PermissionOneOfWrapper>
                        <IconButton
                            edge="end"
                            aria-label="user options"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleMenu}
                        >
                            <Avatar className={classes.avatar}>
                                {firstLetterName()}
                            </Avatar>
                        </IconButton>
                        <Popover
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            open={open}
                            onClose={handleClose}
                            keepMounted
                        >
                            {
                                (authenticated && authenticated?.id === 1 && authenticated?.role === "admin") && (
                                    <Link onClick={toggleSettings} className={classes.popoverItem}>
                                        Configurações <SettingsIcon />
                                    </Link>
                                )
                            }
                            <Divider />
                            <Link onClick={signOut} className={classes.popoverItem}>
                                Sair <ExitToAppIcon />
                            </Link>
                        </Popover>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={isOpen} className={classes.drawer} style={{ background: '#333', color: '#C5CAE9', border: 'none' }}>
                <DrawerHeader style={{ background: '#333', border: 'none', display: 'flex', justifyContent: 'space-between', minHeight: '80px' }} >
                    <h3 style={{ color: '#C5CAE9', marginLeft: '20px', fontWeight: 400 }}>Menu</h3>
                    <IconButton onClick={handleDrawerClose} style={{ color: '#C5CAE9' }}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <div className={classes.drawerContainer} style={{ border: 'none' }}>
                    <List>
                        <PermissionWrapper route="/dashboard">
                            <ListItem onFocus={handleDrawerOpen} className={classes.linkFocus} button component={Link} to="/dashboard" style={{ background: '#333' }} id="dashboard" name="dashboard">
                                <ListItemIcon ><DashboardIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Dashboard" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionOneOfWrapper routes={[
                            "/pessoa",
                            "/tipo_de_pessoa",
                            "/custom_input",
                        ]}>
                            <ListItem
                                className={classes.linkFocus}
                                button
                                component={Link}
                                onClick={() => {
                                    openUserTree();
                                }}
                                onFocus={toggleBlur}
                                style={{ background: '#333' }}
                                id="pessoas"
                            >
                                <ListItemIcon ><PeopleIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText style={{ color: '#C5CAE9' }} primary="Pessoas" />
                                <ListItemIcon style={{ color: '#C5CAE9', display: displayArrow ? 'block' : 'none', paddingLeft: '35px' }} id="pessoas"><ExpandMoreIcon /></ListItemIcon>
                            </ListItem>
                            <ListItem className={classes.linkFocus} style={{ background: '#333', paddingTop: display ? '8px' : '0px', paddingBottom: display ? '8px' : '0px', maxWidth: display ? '300px' : '0px', maxHeight: display ? '300px' : '0px', transition: 'max-height .5s ease-in-out, max-width .5s, padding .5s', overflow: 'hidden' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <PermissionWrapper route="/pessoa">
                                        <ListItem on onFocus={toggleFocus} className={classes.linkFocus} button component={Link} to="/pessoa" id="criar_pessoa">
                                            <ListItemIcon><PeopleIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                            <ListItemText primary="Listar pessoas" style={{ color: '#C5CAE9' }} />
                                        </ListItem>
                                    </PermissionWrapper>
                                    <PermissionWrapper route="/tipo_de_pessoa">
                                        <ListItem onFocus={toggleFocus} className={classes.linkFocus} button component={Link} to="/tipo_de_pessoa" style={{ background: '#333' }} id="tipo_de_pessoa">
                                            <ListItemIcon ><PersonTypeIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                            <ListItemText primary="Tipo de Pessoas" style={{ color: '#C5CAE9' }} />
                                        </ListItem>
                                    </PermissionWrapper>
                                    <PermissionWrapper route="/custom_input">
                                        <ListItem onFocus={toggleFocus} className={classes.linkFocus} button component={Link} to="/custom_input" id="campo_personalizado">
                                            <ListItemIcon ><InputIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                            <ListItemText primary="Campos Personalizados" style={{ color: '#C5CAE9' }} />
                                        </ListItem>
                                    </PermissionWrapper>
                                </div>
                            </ListItem>
                        </PermissionOneOfWrapper>

                        <PermissionOneOfWrapper routes={[
                            "/dispositivo",
                        ]}>
                            <ListItem
                                className={classes.linkFocus}
                                button
                                component={Link}
                                onClick={() => {
                                    openDevicesTree();
                                }}
                                onFocus={devicesToggleBlur}
                                style={{ background: '#333' }}
                                id="devices"
                            >
                                <ListItemIcon ><DeviceIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText style={{ color: '#C5CAE9' }} primary="Dispositivos" />
                                <ListItemIcon style={{ color: '#C5CAE9', display: displayArrow ? 'block' : 'none', paddingLeft: '35px' }} id="devices"><ExpandMoreIcon /></ListItemIcon>
                            </ListItem>
                            <ListItem className={classes.linkFocus} style={{ background: '#333', paddingTop: deviceDisplay ? '8px' : '0px', paddingBottom: deviceDisplay ? '8px' : '0px', maxWidth: deviceDisplay ? '300px' : '0px', maxHeight: deviceDisplay ? '300px' : '0px', transition: 'max-height .5s ease-in-out, max-width .5s, padding .5s', overflow: 'hidden' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <PermissionWrapper route="/dispositivo">
                                        <ListItem on onFocus={devicesToggleBlur} className={classes.linkFocus} button component={Link} to="/dispositivo" style={{ background: '#333' }} id="dispositivo">
                                            <ListItemIcon ><AccountTreeOutlinedIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                            <ListItemText primary="Listar Dispositivos" style={{ color: '#C5CAE9' }} />
                                        </ListItem>
                                    </PermissionWrapper>
                                </div>
                            </ListItem>
                        </PermissionOneOfWrapper>

                        <PermissionWrapper route="/controle_remoto">
                            <ListItem className={classes.linkFocus} button component={Link} to="/controle_remoto" style={{ background: '#333' }} id="tipo_de_pessoa">
                                <ListItemIcon ><DoorBackIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Registros de Controle" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/operações">
                            <ListItem className={classes.linkFocus} button component={Link} to="/operações" style={{ background: '#333' }} id="tipo_de_pessoa">
                                <ListItemIcon ><AssignmentIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Registros de Operação" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/grupos_de_dispositivos">
                            <ListItem className={classes.linkFocus} button component={Link} to="/grupos_de_dispositivos" style={{ background: '#333' }} id="tipo_de_pessoa">
                                <ListItemIcon ><ApiIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Grupos de Dispositivos" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionOneOfWrapper routes={[
                            "/relatorio_unificado",
                        ]}>
                            <ListItem
                                className={classes.linkFocus}
                                button
                                component={Link}
                                onClick={() => {
                                    openReportTree();
                                }}
                                onFocus={reportToggleBlur}
                                style={{ background: '#333' }}
                                id="relatorios"
                            >
                                <ListItemIcon ><ReportIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText style={{ color: '#C5CAE9' }} primary="Relatorios" />
                                <ListItemIcon style={{ color: '#C5CAE9', display: displayArrow ? 'block' : 'none', paddingLeft: '35px' }} id="relatorios"><ExpandMoreIcon /></ListItemIcon>
                            </ListItem>
                            <ListItem className={classes.linkFocus} style={{ background: '#333', paddingTop: reportDisplay ? '8px' : '0px', paddingBottom: reportDisplay ? '8px' : '0px', maxWidth: reportDisplay ? '300px' : '0px', maxHeight: reportDisplay ? '300px' : '0px', transition: 'max-height .5s ease-in-out, max-width .5s, padding .5s', overflow: 'hidden' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <PermissionWrapper route="/relatorio_unificado">
                                        <ListItem on onFocus={reportToggleFocus} className={classes.linkFocus} button component={Link} to="/relatorio_unificado" id="pessoas">
                                            <ListItemIcon ><PeopleIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                            <ListItemText primary="Pessoas" style={{ color: '#C5CAE9' }} />
                                        </ListItem>
                                    </PermissionWrapper>
                                </div>
                            </ListItem>
                        </PermissionOneOfWrapper>

                        <PermissionWrapper route="/exportar_pessoas">
                            <ListItem className={classes.linkFocus} button component={Link} to="/exportar_pessoas" style={{ background: '#333' }} id="exportar_pessoas">
                                <ListItemIcon ><ExportIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Exportar Pessoas" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/organizational">
                            <ListItem className={classes.linkFocus} button component={Link} to="/organizational" style={{ background: '#333' }} id="organizational">
                                <ListItemIcon ><AccountTreeIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Organização" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/credenciais">
                            <ListItem className={classes.linkFocus} button component={Link} to="/credenciais" style={{ background: '#333' }} id="credenciais">
                                <ListItemIcon ><CreditCardIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Credenciais" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/perfil_de_acesso">
                            <ListItem className={classes.linkFocus} button component={Link} to="/perfil_de_acesso" style={{ background: '#333' }} id="perfil_de_acesso">
                                <ListItemIcon ><AssignmentIndIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Perfis de acesso" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/funcionalidades">
                            <ListItem className={classes.linkFocus} button component={Link} to="/funcionalidades" style={{ background: '#333' }} id="lista_funcionalidade">
                                <ListItemIcon ><Extension style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Funcionalidades" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/rotas_de_api">
                            <ListItem className={classes.linkFocus} button component={Link} to="/rotas_de_api" style={{ background: '#333' }} id="lista_funcionalidade">
                                <ListItemIcon ><RouteIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Rotas de API" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/local">
                            <ListItem className={classes.linkFocus} button component={Link} to="/local" style={{ background: '#333' }} id="local">
                                <ListItemIcon ><HomeIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Locais" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/mapa">
                            <ListItem className={classes.linkFocus} button component={Link} to="/mapa" style={{ background: '#333' }} id="mapa">
                                <ListItemIcon ><MapIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Mapas" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionOneOfWrapper routes={[
                            "/notificação",
                        ]}>
                            <ListItem
                                className={classes.linkFocus}
                                button
                                component={Link}
                                onClick={() => {
                                    openNotificationTree();
                                }}
                                onFocus={notificationToggleBlur}
                                style={{ background: '#333' }}
                                id="notificacoes"
                            >
                                <ListItemIcon ><NotificationsIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText style={{ color: '#C5CAE9' }} primary="Notificações" />
                                <ListItemIcon style={{ color: '#C5CAE9', display: displayArrow ? 'block' : 'none', paddingLeft: '35px' }}><ExpandMoreIcon /></ListItemIcon>
                            </ListItem>
                            <ListItem className={classes.linkFocus} style={{ background: '#333', paddingTop: notificationDisplay ? '8px' : '0px', paddingBottom: notificationDisplay ? '8px' : '0px', maxWidth: notificationDisplay ? '300px' : '0px', maxHeight: notificationDisplay ? '300px' : '0px', transition: 'max-height .5s ease-in-out, max-width .5s, padding .5s', overflow: 'hidden' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <PermissionWrapper route="/notificação">
                                        <ListItem on onFocus={notificationToggleFocus} className={classes.linkFocus} button component={Link} to="/notificação">
                                            <ListItemIcon ><NotificationImportantIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                            <ListItemText primary="Notificações de Acesso" style={{ color: '#C5CAE9' }} />
                                        </ListItem>
                                    </PermissionWrapper>
                                </div>
                            </ListItem>
                        </PermissionOneOfWrapper>

                        <PermissionWrapper route="/webhooks">
                            <ListItem className={classes.linkFocus} button component={Link} to="/webhooks" style={{ background: '#333' }} id="ajuda">
                                <ListItemIcon ><WebhookIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Webhooks" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/atalhos">
                            <ListItem className={classes.linkFocus} button component={Link} to="/atalhos" style={{ background: '#333' }} id="ajuda">
                                <ListItemIcon ><KeyboardIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Atalhos" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>

                        <PermissionWrapper route="/ajuda">
                            <ListItem className={classes.linkFocus} button component={Link} to="/ajuda" style={{ background: '#333' }} id="ajuda">
                                <ListItemIcon ><HelpIcon style={{ color: '#C5CAE9' }} /></ListItemIcon>
                                <ListItemText primary="Ajuda" style={{ color: '#C5CAE9' }} />
                            </ListItem>
                        </PermissionWrapper>
                    </List>
                </div>
            </Drawer>
            <Popup
                openPopup={openSettings}
                setOpenPopup={setOpenSettings}
                title="Configurações"
                width="70%"
            >
                <SettingsModal />
            </Popup>
        </Box>
    );
})

const useStyles = makeStyles(theme => ({
    drawer: {
        // width: metrics.drawerWidth,
        background: 'transparent',
        flexShrink: 0,
        color: '#333',
        '& > div': {
            border: 'none',
            background: '#333'
        }
    },
    drawerPaper: {
        // width: metrics.drawerWidth,
        minWidth: '1rem',
        background: '#333',
        padding: '0',
        color: '#C5CAE9',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: theme.transitions.create(
            ['width', 'display'],
            { duration: theme.transitions.easing.easeInOut }
        ),
    },
    drawerContainer: {
        overflow: 'auto',
        overflowX: 'hidden'
    },
    list: {

    },
    listItem: {
        '&:hover': {
            background: '#303F9F',
            '& $drawerPaper': {
                color: '#E8EAF6',
            },
            '& $icon': {
                color: '#E8EAF6',
            }
        }
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        color: '#C5CAE9'
    },
    itemText: {
        display: 'none',
        fontWeight: 'bold',

    },
    logoContainer: {
        flexGrow: 1,
    },
    logo: {
        width: 155,
        verticalAlign: 'middle',
    },
    avatar: {
        color: theme.palette.getContrastText(blue[700]),
        backgroundColor: blue[700],
    },
    danger_alert: {
        color: theme.palette.getContrastText(red[700]),
        backgroundColor: red[700],
    },
    popoverItem: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontWeight: 500,
        color: grey[700],
        padding: theme.spacing(1, 2),
        '& > *': {
            marginLeft: theme.spacing(1),
        },
        '&:hover': {
            backgroundColor: grey[100],
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
    linkFocus: {
        '&:focus': {
            border: '1px solid #5f679c',
            borderRadius: '4px',
        },
        '&:active': {
            border: "none"
        },
        '&:hover': {
            border: "none"
        },
    }
}));

export default SideMenu;
