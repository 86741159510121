import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import draftToHtml from "draftjs-to-html";

import { Toolbar, Paper, Grid, Typography, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';

export default function HelpView() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const { id } = useParams();
    const history = useHistory();

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });
    const [title, setTitle] = useState('');
    const contentRef = useRef(null);

    useEffect(() => {
        const loadPost = async () => {
            try {
                const result = await api.get(`/help_feed/view/${id}`);
                const { helpFeed } = result.data;

                setTitle(helpFeed.title);
                contentRef.current.innerHTML = draftToHtml(helpFeed.content);
            } catch (err) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
                setInterval(() => history.goBack(), 2000);
            }
        };

        loadPost();
    }, [id, history]);

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper alevation={3}>
                            <Grid item className={classes.form}>
                                <Grid item spacing={3}>
                                    <div ref={contentRef} className={classes.contentContainer} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <PermissionWrapper route="/ajuda/modificar/:id">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href={`/ajuda/modificar/${id}`}
                    >
                        <EditIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    contentContainer: {
        minHeight: '80vh',
        width: '100%',
        'div & img': {
            margin: '0 auto',
            display: 'flex'
        }
    },
}));

