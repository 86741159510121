import React, { useState, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { makeStyles, styled } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import InputSearch from '../../components/InputSearch';
import Alert from '../../components/Alert';
import Popup from '../../components/Popup';

import api from '../../services/api';
import date from '../../utils/date';
import config from '../../configs/config';
import { PermissionsContext } from '../../providers/Permissions';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function CaseFileList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [casesFiles, setCasesFiles] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [refresh, setRefresh] = useState(true)
    const [limit, setLimit] = useState(10);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [file, setFile] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingCaseFileId, setDeletingCaseFileId] = useState(null);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const handleOpenUpload = () => {
        setOpenUploadFile(true);
    };

    const handleCloseUpload = () => {
        setOpenUploadFile(false);
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const handleDelete = (id) => {
        setDeletingCaseFileId(id);
        setOpenDeletePopup(true);
    }

    async function fetchCasesFiles({ search }) {
        const loadData = async () => {
            try {
                const result = await api.get('/case_file/list', {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });

                const { count, cases_files } = result.data;

                setCasesFiles(cases_files);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const checkType = (file, type) => file.type.includes(type);

    const deleteCaseFile = async () => {
        try {
            setIsDeleting(true);

            const result = await api.post(`/case_file/delete/${deletingCaseFileId}`);

            const { success } = result.data;

            setIsDeleting(false);

            if (success) {
                setDeletingCaseFileId(null);
                setOpenDeletePopup(false);
                setRefresh(!refresh);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Evidência deletada com sucesso!`,
                });
            }
        }
        catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    const saveCaseFile = async () => {
        try {
            const formData = new FormData();

            formData.append('case_file', file);

            const result = await api.post('/case_file/create', formData);

            const { name } = result.data;

            await fetchCasesFiles({ search: '' });
            setFile(null);

            setMessage({
                open: true,
                variant: 'success',
                text: `Evidência: ${name} cadastrado com sucesso!`,
            });
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Evidências
                        </Typography>
                    </Grid>
                    <Grid item spacing={5} xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={fetchCasesFiles}
                            pagination={[page, limit, refresh]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Arquivo</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Caso</TableCell>
                                        <TableCell className={classes.tableHead}>Responsável</TableCell>
                                        <TableCell className={classes.tableHead}>Data de criação</TableCell>
                                        <PermissionOneOfWrapper
                                            routes={[
                                                "/evidencias/deletar/:id",
                                            ]}
                                        >
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!casesFiles && casesFiles.map(caseFile => (
                                        <TableRow key={caseFile.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {caseFile.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {!caseFile.removed ? (
                                                    <>
                                                        {checkType(caseFile, 'image') && (
                                                            <img
                                                                src={`http://${config.host}:${config.port}/midias/evidencia/${caseFile.name}`}
                                                                alt='something'
                                                                width="320"
                                                            />
                                                        )}
                                                        {checkType(caseFile, 'video') && (
                                                            <video width="320" height="240" controls>
                                                                <source
                                                                    src={`http://${config.host}:${config.port}/midias/evidencia/${caseFile.name}`}
                                                                    type={caseFile.type}
                                                                />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        )}
                                                        {checkType(caseFile, 'audio') && (
                                                            <audio controls>
                                                                <source
                                                                    src={`http://${config.host}:${config.port}/midias/evidencia/${caseFile.name}`}
                                                                    type={caseFile.type}
                                                                />
                                                                Your browser does not support the audio element.
                                                            </audio>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Typography variant="subtitle2">REMOVIDO</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {caseFile.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {caseFile?.case?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {caseFile.user.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {date(caseFile.created_at).format('DD/MM/YYYY HH:mm')}
                                            </TableCell>
                                            <PermissionOneOfWrapper
                                                routes={[
                                                    "/evidencias/deletar/:id",
                                                ]}
                                            >
                                                <TableCell className={classes.tableBody}>
                                                    <PermissionWrapper route="/evidencias/deletar/:id">
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            color="secondary"
                                                            className={classes.actionButton}
                                                            onClick={() => handleDelete(caseFile.id)}
                                                        >
                                                            <DeleteIcon style={{ fontSize: 20 }} />
                                                        </Button>
                                                    </PermissionWrapper>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                    <Popup
                        openPopup={openDeletePopup}
                        setOpenPopup={setOpenDeletePopup}

                    >
                        <Grid container xs={12} style={{
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0 3% 2% 3%',

                        }}>
                            <Typography variant='h5'
                                style={{
                                    fontWeight: 'bold',
                                    color: '#9e9e9e'
                                }}
                            >
                                Deseja mesmo deletar esta evidência?
                            </Typography>
                            <Grid item style={{
                                marginTop: '50px'
                            }}>
                                <Button
                                    disabled={isDeleting}
                                    variant="contained"
                                    onClick={() => deleteCaseFile()}
                                    color="secondary"
                                    endIcon={<DeleteIcon />}
                                >
                                    {isDeleting ? 'Deletando...' : 'Sim, delete esta evidência'}
                                </Button>
                            </Grid>

                        </Grid>
                    </Popup>
                </Grid>
                <Dialog open={openUploadFile} onClose={handleCloseUpload} maxWidth="sm" fullWidth>
                    <DialogTitle>Nova Evidência</DialogTitle>
                    <DialogContent>
                        <Button
                            component="label"
                            variant="contained"
                            color="primary"
                            startIcon={<CloudUploadIcon />}
                            fullWidth
                            style={{ height: 60 }}
                        >
                            Upload de evidência
                            <VisuallyHiddenInput type="file" onChange={event => {
                                const file = event.currentTarget.files[0];

                                setFile(file);
                            }} />
                        </Button>
                        {console.log(file)}
                        <Typography variant="subtitle2" style={{ marginTop: 20 }}>
                            {!!file && `ARQUIVO: ${file.name}`}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={handleCloseUpload}>Cancelar</Button>
                        <Button color="primary" onClick={saveCaseFile}>Salvar</Button>
                    </DialogActions>
                </Dialog>
                <PermissionWrapper route="/evidencias/criar">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        onClick={handleOpenUpload}
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}

            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
}));
