import React, { useState, useReducer, useEffect, useMemo, useRef, useContext } from 'react';
import { usePersonTypes } from '../../hooks';
import { useLocation } from 'react-router-dom';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Chip,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
    Tooltip,
    IconButton,
    Divider,
    ButtonGroup,
    FormControlLabel,
} from '@material-ui/core';
import { Skeleton } from '@mui/material';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PersonIcon from '@material-ui/icons/Face';
import CardIcon from '@material-ui/icons/CreditCard';
import { makeStyles } from '@material-ui/core/styles';
import { grey, green, orange, red } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import DateTimePicker from '../../components/DateTimePicker';
import FullScreenDialog from '../../components/FullScreenDialog';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Autocomplete from '../../components/Autocomplete';
import fileDownloader from 'js-file-download';
import Popup from '../../components/Popup';
import ExportModal from '../../components/ExportModal';
import { useTableColumnsToShow, ColumnsManagementModal } from './components/UnifiedColumns'

import api from '../../services/api';
import config from '../../configs/config';

import date from '../../utils/date';
import number from '../../utils/number';

import { metrics } from '../../assets/styles';
import { PermissionsContext } from '../../providers/Permissions';

const { host, port } = config;

const dhiAccess = ['UserAccessRecord'];
const cameraAccess = ['FaceDetectionRecord', 'FaceRecognitionRecord'];

const AccessMedia = ({ log, url }) => {
    const classes = useStyles();

    if (log && dhiAccess.includes(log.type)) {
        return (
            <img src={url} className={classes.video} alt="IMAGEM" />
        );
    } else if (log && cameraAccess.includes(log.type)) {

        return (
            <video controls className={classes.video}>
                <source src={url} />
            </video>
        );
    } else {
        return <></>
    }
};

const DetailField = ({ label, value }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontWeight: 700,
                        color: grey[600],
                    }}
                >
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="subtitle1"
                    style={{
                        fontWeight: 700,
                        color: grey[500],
                        marginLeft: 8
                    }}
                >
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
}

const reduceEventTime = (state, action) => {
    switch (action.type) {
        case 'change':
            console.log("changind time")
            console.log({ action })
            return {
                ...state,
                [action.time]: action.value,
            };
        default:
            return state;
    }
}

export default function UnifiedReport() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
        hasPermission,
    } = useContext(PermissionsContext);

    const location = useLocation();
    const { routeStartTime, routeEndTime, routeCheckTime, routeMask, routeCheckMask } = (location && location.state) || {};

    const [accessLogs, setAccessLogs] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [loadingMedia, setLoadingMedia] = useState(false);
    const [accessLog, setAccessLog] = useState(null);
    const [mediaUrl, setMediaUrl] = useState('');
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });


    const [openFilterPopup, setOpenFilterPopup] = useState(false);

    const [openTableEditPopup, setOpenTableEditPopup] = useState(false);
    const { tableColumns, dispatchTableColumns } = useTableColumnsToShow();

    const [openViewMedia, setOpenViewMedia] = useState(false);
    const [openAccessDetails, setOpenAccessDetails] = useState(false);

    const [openExport, setOpenExport] = useState(false);

    const handleOpenExport = () => setOpenExport(true);
    const handleCloseExport = () => setOpenExport(false);

    const [usingMask, setUsingMask] = useState(routeMask ?? null);
    const [temperatureType, setTemperatureType] = useState(null);
    const [temperatureValue, setTemperatureValue] = useState(null);
    const [type, setType] = useState(null);

    const [person, setPerson] = useState(null);
    const [people, setPeople] = useState([]);
    const [personSearch, setPersonSearch] = useState('');

    const [device, setDevice] = useState(null);
    const [devices, setDevices] = useState([]);
    const [deviceSearch, setDeviceSearch] = useState('');
    const [refreshDevices, setRefreshDevices] = useState(false);

    const [place, setPlace] = useState(null);
    const [places, setPlaces] = useState([]);
    const [placeSearch, setPlaceSearch] = useState('');

    const [emotion, setEmotion] = useState(null);

    const [eventTime, dispatchEventTime] = useReducer(reduceEventTime, {
        start_time: routeStartTime ?? date().startOf("day").toISOString(),
        end_time: routeEndTime ?? date().endOf("day").toISOString(),
    });

    const [selection, setSelection] = useState(null);

    const [rejectionStatus, setRejectionStatus] = useState(null);

    const [deviceType, setDeviceType] = useState(null);

    const [searchPersonTypes, setSearchPersonTypes] = useState('');
    const [selectedPersonType, setSelectedPersonType] = useState(null);
    const { personTypes } = usePersonTypes({ search: searchPersonTypes, show_exceptions: true, limit: 20 });

    const [accessMethod, setAccessMethod] = useState(null);
    const [allowedAccess, setAllowedAccess] = useState(null);

    const [externalAPICalled, setExternalAPICalled] = useState(null);


    const [checkedFilters, dispatchCheckedFilters] = useReducer((state, action) => {
        switch (action.type) {
            case 'toggle':
                return {
                    ...state,
                    [action.filter]: !state[action.filter],
                }
            default: return state;
        }
    }, {
        checkMask: (routeCheckMask) ? true : false,
        checkTemperature: false,
        checkType: false,
        checkPerson: false,
        checkPlace: false,
        checkTime: (routeCheckTime) ? true : true,
        checkSelection: false,
        checkDeviceType: false,
        checkRejectionStatus: false,
        checkEmotion: false,
        checkPersonType: false,
        checkAllowedAccess: false,
        checkAccessMethod: false,
        checkExternalAPICalled: false,
    });

    const handleCheck = (prop) => {
        dispatchCheckedFilters({
            type: 'toggle',
            filter: prop
        });
    };

    const [exportOngoing, setExportOngoing] = useState(false);

    const timeSet = (time) => {
        let start = null;
        let end = date().toISOString();
        switch (time) {
            case '12h':
                start = date().subtract(12, "hours").toISOString();
                break;
            case '24h':
                start = date().subtract(24, "hours").toISOString();
                break;
            case 'today':
                start = date().startOf("day").toISOString();
                end = date().endOf("day").toISOString();
                break;
            case '7d':
                start = date().subtract(7, "days").toISOString();
                break;
            case 'this-week':
                start = date().startOf("week").toISOString();
                end = date().endOf('week').toISOString();
                break;
            case 'this-month':
                start = date().startOf('month').toISOString()
                end = date().endOf('month').toISOString();
                break;
            case 'all':
                start = null;
                end = null;
                dispatchCheckedFilters({
                    type: 'toggle',
                    filter: 'checkTime'
                });
                break;
            default:
                end = null;
        }
        dispatchEventTime({
            type: 'change',
            time: 'start_time',
            value: start,
        });
        dispatchEventTime({
            type: 'change',
            time: 'end_time',
            value: end,
        });
    };

    const [showSkeleton, setShowSkeleton] = useState(false);
    const fetchingData = useRef(false);

    const loadData = async (autoRefreshing = false) => {
        fetchingData.current = true;
        console.log("setting to true")

        if (autoRefreshing === true) setShowSkeleton(true);

        try {
            console.log("requesting")
            const result = await api.post('/unified_report/list', {
                filters: checkedFilters,
                params: {
                    mask: usingMask,
                    temperature_type: temperatureType,
                    temperature_value: temperatureValue,
                    type: type,
                    person: person?.id ?? null,
                    place: place?.name ?? null,
                    selection: selection,
                    device_type: deviceType,
                    device: device?.id ?? null,
                    rejection_status: rejectionStatus,
                    emotion: emotion,
                    person_type: selectedPersonType?.id ?? null,
                    method: accessMethod,
                    allowed_access: allowedAccess,
                    limit: limit,
                    page: page,
                    externalAPICalled: externalAPICalled,
                    ...eventTime,
                },
            });

            const { count, access_log } = result.data;

            if (total > count) setPage(0);

            setAccessLogs(access_log);
            setTotal(count);

        }
        catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }

        if (autoRefreshing === true) setShowSkeleton(false);

        console.log("setting to false")
        fetchingData.current = false;
    };

    useEffect(() => {
        const fetchData = (autoRefreshing) => {
            if (!openFilterPopup && !exportOngoing && !fetchingData.current) loadData(autoRefreshing);
            else console.log({ canceled: true, openFilterPopup, exportOngoing, fetchingData })
        }

        if (!fetchingData.current) {
            fetchData(true);
            console.log("outside")
        };

        const load = setInterval(() => {
            fetchData(false);
            console.log("interval")
        }, 10000);

        return () => {
            clearInterval(load);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit, total, openFilterPopup, exportOngoing]);

    useEffect(() => {
        const findUser = async () => {
            try {
                const result = await api.get('/unified_report/filter', {
                    params: {
                        search_type: 'person',
                        person_name: personSearch
                    },
                });

                const { results } = result.data;

                setPeople(results);
            }
            catch (err) {
                // setMessage({
                //     open: true,
                //     variant: 'error',
                //     text: err.error,
                // });´
                console.log('person filter error: ', err)
            }
        };

        findUser();
    }, [personSearch]);

    useEffect(() => {
        const findDevice = async () => {
            try {
                const result = await api.get('/unified_report/filter', {
                    params: {
                        search_type: 'device',
                        device_type: deviceType,
                        device_name: deviceSearch
                    },
                });

                const { results } = result.data;

                setDevices(results);
            }
            catch (err) {
                console.log(err)
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            }
        };

        findDevice();
    }, [deviceSearch, deviceType, refreshDevices]);

    useEffect(() => {
        const findPlace = async () => {
            try {
                const result = await api.get('/unified_report/filter', {
                    params: {
                        search_type: 'place',
                        place_name: placeSearch,
                    },
                });

                const { results } = result.data;

                setPlaces(results);
            }
            catch (err) {
                console.log(err)
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            }
        };

        findPlace();
    }, [placeSearch]);

    const exportUnifiedReport = async (fileType, exportDestination, destinationEmail) => {
        setExportOngoing(true);
        try {
            if (accessLogs.length === 0) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: 'Sem dados para exportação',
                });
                return;
            }

            let data = {
                filters: checkedFilters,
                params: {
                    mask: usingMask,
                    temperature_type: temperatureType,
                    temperature_value: temperatureValue,
                    type: type,
                    person: person?.id ?? null,
                    place: place?.name ?? null,
                    selection: selection,
                    device_type: deviceType,
                    device: device?.id ?? null,
                    rejection_status: rejectionStatus,
                    emotion: emotion,
                    person_type: selectedPersonType?.id ?? null,
                    method: accessMethod,
                    allowed_access: allowedAccess,
                    exportationType: fileType,
                    exportDestination: exportDestination,
                    destinationEmail: destinationEmail,
                    externalAPICalled: externalAPICalled,
                    ...eventTime,
                },
                columnsToShow: tableColumns,
            };

            await api.post('/unified_report/export', data, {
                responseType: exportDestination === "website" ? 'blob' : undefined,
            }).then((res) => {
                const suggestedFileName = res.headers['x-suggested-filename'];
                if (exportDestination === "website")
                    fileDownloader(res.data, suggestedFileName);
                else setMessage({
                    open: true,
                    variant: 'success',
                    text: `Em breve o arquivo gerado deve chegar em seu email "${destinationEmail}"`,
                });
            }).catch(async err => {
                try {
                    let resText = await new Promise((resolve, reject) => {
                        let reader = new FileReader()
                        reader.addEventListener('abort', reject)
                        reader.addEventListener('error', reject)
                        reader.addEventListener('loadend', () => {
                            resolve(reader.result)
                        })
                        reader.readAsText(err)
                    })
                    let resData = JSON.parse(resText)

                    setMessage({
                        open: true,
                        variant: 'error',
                        text: resData.error,
                    });
                } catch (err) {
                    setMessage({
                        open: true,
                        variant: 'error',
                        text: 'Ocorreu um erro ao exportar, favor tente novamente mais tarde ou contate um administrador.',
                    });
                }
            });

        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
        setExportOngoing(false);
    };

    const clearMedia = () => {
        setOpenViewMedia(false);
        setAccessLog(null);
        setMediaUrl('');
        setLoadingMedia(false);
    };

    // const getMedia = async access => {
    //     if (dhiAccess.includes(access.type)) {
    //         const fetchedImage = await seeImage(access);
    //         if (fetchedImage) setOpenViewMedia(true);
    //     } else if (cameraAccess.includes(access.type)) {
    //         const fetchedVideo = await watchVideo(access);
    //         if (fetchedVideo) setOpenViewMedia(true);
    //     }
    // };

    const getImage = async (access) => {
        const fetchedImage = await seeImage(access);
        if (fetchedImage) setOpenViewMedia(true);
    };

    const getVideo = async (access) => {
        const fetchedVideo = await watchVideo(access);
        if (fetchedVideo) setOpenViewMedia(true);
    };

    const [userFaceUrl, setUserFaceUrl] = useState('');

    const handleOpenAccessDetails = async (access) => {
        if (openAccessDetails) {
            setAccessLog(null);
            setMediaUrl('');
            setLoadingMedia(false);
            setOpenAccessDetails(false);
            setUserFaceUrl('');
        } else if (!openAccessDetails && access) {
            if (dhiAccess.includes(access.type) && hasPermission("/relatorio_unificado/imagem/:id")) {
                await seeImage(access, false, false);
                if (access?.user?.face_image) setUserFaceUrl(`http://${host}:${port}/midias/imagem/face/${access.user.face_image}`);
                setOpenAccessDetails(true);
            } else if (cameraAccess.includes(access.type) && hasPermission("/relatorio_unificado/video/:id")) {
                await watchVideo(access, false, false);
                if (access?.user?.face_image) setUserFaceUrl(`http://${host}:${port}/midias/imagem/face/${access.user.face_image}`);
                setOpenAccessDetails(true);
            }
        }
    }

    const watchVideo = async (accessLog, useLoading = true, clearOnError = true) => {
        if (useLoading) setLoadingMedia(true);
        setAccessLog(accessLog);
        let loadedWithSuccess = false;

        try {
            const result = await api.get(`/access_log/video/${accessLog.id}`);

            const { video } = result.data;

            const videoPath = `http://${host}:${port}/midias/video/acesso/${video}`;

            setMediaUrl(videoPath);
            loadedWithSuccess = true;
        }
        catch (err) {
            console.log(err)
            if (clearOnError) clearMedia();

            setMessage({
                open: true,
                variant: 'error',
                text: 'Não foi possível baixar o vídeo do acesso',
            });
        }
        if (useLoading) setLoadingMedia(false);
        return loadedWithSuccess;
    };

    const seeImage = async (accessLog, useLoading = true, clearOnError = true) => {
        if (useLoading) setLoadingMedia(true);
        setAccessLog(accessLog);
        let loadedWithSuccess = false;

        try {
            const result = await api.get(`/access_log/image/${accessLog.id}`);

            const { image } = result.data;

            const imagePath = `http://${host}:${port}/midias/imagem/acesso/${image}`

            setMediaUrl(imagePath);
            loadedWithSuccess = true;
        } catch (err) {
            console.log(err);
            if (clearOnError) clearMedia();

            setMessage({
                open: true,
                variant: 'error',
                text: 'Não foi possível baixar a imagem do acesso',
            });
        }
        if (useLoading) setLoadingMedia(false);
        return loadedWithSuccess;
    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const accessStatus = ({ type, allowed }) => {
        switch (type) {
            case 'entry':
                return `Entrada ${allowed ? 'permitida' : 'negada'}`;
            case 'exit':
                return `Saída ${allowed ? 'permitida' : 'negada'}`;
            default:
                return `Acesso interno ${allowed ? 'permitido' : 'negado'}`;
        }
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const getDocumentType = (docType) => {
        let returning = '';

        switch (docType) {
            case 'ic':
                returning = 'RG';
                break;
            case 'passport':
                returning = 'Passaporte';
                break;
            case 'other':
                returning = 'Outro';
                break;
            case 'unknown':
                returning = 'CPF';
                break;
            default:
                returning = "-";
        }

        return returning;
    }

    const getEmotion = (emotion) => {
        let returning;
        switch (emotion) {
            case 'Unknown':
                returning = 'Desconhecida';
                break;
            case 'Smile':
                returning = 'Sorridente';
                break;
            case 'Anger':
                returning = 'Raiva';
                break;
            case 'Sadness':
                returning = 'Tristeza';
                break;
            case 'Disgust':
                returning = 'Desgosto';
                break;
            case 'Fear':
                returning = 'Medo';
                break;
            case 'Surprise':
                returning = 'Surpresa';
                break;
            case 'Neutral':
                returning = 'Neutro';
                break;
            case 'Laugh':
                returning = 'Riso';
                break;
            case 'Happy':
                returning = 'Felicidade';
                break;
            case 'Confused':
                returning = 'Confusão';
                break;
            case 'Scream':
                returning = 'Grito';
                break;
            case 'Lookaside':
                returning = 'Pensativo(a)';
                break;
            default:
                returning = 'Desconhecida';
        }

        return returning;
    }

    const getRejectionStatus = (rjStatus) => {
        let returning = '';
        switch (rjStatus) {
            case 'unauthorized_day':
                returning = 'DIA NÃO AUTORIZADO';
                break;
            case 'unauthorized_time':
                returning = 'HORA NÃO AUTORIZADA';
                break;
            case 'without_mask':
                returning = 'SEM MÁSCARA';
                break;
            case 'over_temperature':
                returning = 'ACIMA DA TEMPERATURA';
                break;
            case 'unauthorized_location':
                returning = 'LOCAL NÃO AUTORIZADO';
                break;
            case 'unknown_person':
                returning = 'PESSOA DESCONHECIDA';
                break;
            case 'wanted_person':
                returning = 'PESSOA PROCURADA';
                break;
            case 're_entry':
                returning = 'ENTRADA REPETIDA';
                break;
            case 'unknown_card':
                returning = 'CREDENCIAL DESCONHECIDA';
                break;
            case 'invalid_card':
                returning = 'CREDENCIAL INVÁLIDA';
                break;
            case 'related_already_with_a_dependant':
                returning = 'A PESSOA RELACIONADA JA ESTÁ SENDO ACOMPANHADA';
                break;
            case 'related_did_not_access':
                returning = 'A PESSOA RELACIONADA AINDA NÃO ACESSOU';
                break;
            case 'companion_out_of_time':
                returning = 'FORA DO HORÁRIO DE ACOMPANHAMENTO';
                break;
            default:
                returning = '-';
        }

        return returning;
    };

    const mediaDialogTitle = useMemo(() => {
        // console.log("called")
        if (accessLog && dhiAccess.includes(accessLog.type)) {
            return (
                `${accessLog.Device.name}`
            )
        } else if (accessLog && cameraAccess.includes(accessLog.type)) {
            return (
                `${accessLog.camera.name}
                 (${accessLog.camera.device.name})`
            )
        } else return '';
    }, [accessLog]);

    const TableCellSkeleton = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="rectangular" animation='wave' width='100%' height='2rem' />
            </div>
        )
    }

    const TableRowSkeleton = (key) => {
        // console.log(Object.values(tableColumns))
        return (
            <TableRow key={key}>
                {
                    true && Object.values(tableColumns)
                        .filter(value => value === true)
                        .map((_, skeletonIndex) => (
                            <TableCell key={`sc${skeletonIndex}`} className={classes.tableBody}>
                                <TableCellSkeleton />
                            </TableCell>
                        ))
                }
            </TableRow>
        );
    };

    const TableBodySkeleton = ({ total, limit }) => {
        console.log({ total, limit })


        const rowsNumber = useMemo(() => {
            let totalRows = total > 0 ? total : 10;
            const loadRows = () => {
                const rows = [];
                for (let i = 0; i < (totalRows < limit ? totalRows : limit); i++) {
                    rows.push('');
                }
                return rows;
            }
            return loadRows()
        }, [total, limit]);

        console.log({ rowsNumber })

        return (
            <TableBody>
                {
                    !!rowsNumber.length && rowsNumber.map((_, rowIndex) => (
                        <TableRowSkeleton key={rowIndex} />
                    ))
                }
            </TableBody>
        );
    }

    const getGlassesType = (glasses) => {
        switch (glasses) {
            case 0:
                return 'Indetectável';
            case 1:
                return 'Nenhum';
            case 2:
                return 'Óculos normal';
            case 3:
                return 'Óculos de sol';
            case 4:
                return 'Óculos normal';
            default:
                return 'Não identificado';
        }
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title} onClick={() => console.log(fetchingData)}>
                            Relatório Unificado (Acesso e Monitoramento)
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} className={classes.filter}>
                        <Grid item style={{ marginRight: '1%' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenFilterPopup(true)}
                            >
                                Adcionar/Editar Filtros
                            </Button>
                        </Grid>
                        <PermissionWrapper route="/relatorio_unificado/exportar">
                            <Grid item style={{ marginRight: '1%' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenExport}
                                >
                                    EXPORTAR
                                </Button>
                            </Grid>
                        </PermissionWrapper>
                        <Grid item style={{ marginRight: '1%' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenTableEditPopup(true)}
                            >
                                Editar campos da tabela
                            </Button>
                        </Grid>
                        <Dialog open={openFilterPopup} onClose={() => setOpenFilterPopup(false)} maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
                            <DialogTitle className={classes.dialogTitle}>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }} className={classes.label}>
                                        Escolha o que deseja filtrar
                                    </Typography>
                                    <Button
                                        color="secondary"
                                        onClick={() => setOpenFilterPopup(false)}>
                                        <CloseIcon />
                                    </Button>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={1} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Mascara
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkMask}
                                                        onChange={() => handleCheck('checkMask')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                    { label: 'Sim', value: 2 },
                                                    { label: 'Não', value: 1 },

                                                ]}
                                                textFieldProps={{
                                                    placeholder: 'Mascara',
                                                    onChange: ({ target }) => setUsingMask(target.value),
                                                    value: usingMask,
                                                    fullWidth: true,
                                                    disabled: !checkedFilters.checkMask
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Sentimento
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.emotion}
                                                        onChange={() => handleCheck('emotion')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um sentimento ----|', value: null },
                                                    {
                                                        label: "Sorriso",
                                                        value: "Smile",
                                                    },
                                                    {
                                                        label: "Raiva",
                                                        value: "Anger",
                                                    },
                                                    {
                                                        label: "Tristeza",
                                                        value: "Sadness",
                                                    },
                                                    {
                                                        label: "Desgosto",
                                                        value: "Disgust",
                                                    },
                                                    {
                                                        label: "Medo",
                                                        value: "Fear",
                                                    },
                                                    {
                                                        label: "Surpresa",
                                                        value: "Surprise",
                                                    },
                                                    {
                                                        label: "Neutra",
                                                        value: "Neutral",
                                                    },
                                                    {
                                                        label: "Rindo",
                                                        value: "Laugh",
                                                    },
                                                    {
                                                        label: "Feliz",
                                                        value: "Happy",
                                                    },
                                                    {
                                                        label: "Confusa",
                                                        value: "Confused",
                                                    },
                                                    {
                                                        label: "Gritando",
                                                        value: "Scream",
                                                    },
                                                    {
                                                        label: "De lado",
                                                        value: "Lookaside",
                                                    },
                                                    {
                                                        label: "Desconhecida",
                                                        value: "Unknown",
                                                    },
                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target }) => setEmotion(target.value),
                                                    value: emotion,
                                                    fullWidth: true,
                                                    disabled: !checkedFilters.emotion
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Temperatura
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkTemperature}
                                                        onChange={() => handleCheck('checkTemperature')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid xs={9} style={{ display: 'flex' }}>
                                            <Grid xs={4} item>
                                                <Select
                                                    options={[
                                                        { label: '|---- Selecione a variação ----|', value: null },
                                                        { label: 'Acima', value: 'gt' },
                                                        { label: 'Abaixo', value: 'lt' },
                                                        { label: 'Acima e igual', value: 'gte' },
                                                        { label: 'Abaixo e igual', value: 'lte' },
                                                        { label: 'Igual', value: 'eq' },
                                                    ]}
                                                    textFieldProps={{
                                                        onChange: ({ target }) => setTemperatureType(target.value),
                                                        value: temperatureType,
                                                        fullWidth: true,
                                                        disabled: !checkedFilters.checkTemperature
                                                    }}
                                                />
                                            </Grid>
                                            <Grid xs={8} item>
                                                <Input
                                                    textFieldProps={{
                                                        placeholder: 'Valor',
                                                        value: temperatureValue,
                                                        type: 'number',
                                                        onChange: ({ target }) => setTemperatureValue(target.value),
                                                        fullWidth: true,
                                                        disabled: !checkedFilters.checkTemperature
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Localização
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkPlace}
                                                        onChange={() => handleCheck('checkPlace')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Autocomplete
                                                placeholder="Nome"
                                                options={places}
                                                getOptionLabel={({ name }) => name || ''}
                                                onChange={(_, value) => setPlace(value)}
                                                getOptionSelected={(option, value) => (option.name === value.name)}
                                                onInputChange={(_, value) => setPlaceSearch(value)}
                                                value={place}
                                                fullWidth
                                                disabled={!checkedFilters.checkPlace}
                                                textFieldProps={{
                                                    disabled: !checkedFilters.checkPlace
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Marcação de Acesso
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkExternalAPICalled}
                                                        onChange={() => handleCheck('checkExternalAPICalled')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                    {
                                                        label: "MARCADO",
                                                        value: "SENT",
                                                    },
                                                    {
                                                        label: "INDIFERENTE",
                                                        value: "NOT_NEEDED",
                                                    },
                                                    {
                                                        label: "DESABILITADO",
                                                        value: "DISABLED",
                                                    },
                                                    {
                                                        label: "ERRO AO MARCAR",
                                                        value: "ERROR",
                                                    },
                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target }) => setExternalAPICalled(target.value),
                                                    value: externalAPICalled,
                                                    fullWidth: true,
                                                    disabled: !checkedFilters.checkExternalAPICalled
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Ação
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkType}
                                                        onChange={() => handleCheck('checkType')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                    { label: 'Entrou', value: 'entry' },
                                                    { label: 'Presente', value: 'internal' },
                                                    { label: 'Saiu', value: 'exit' },
                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target }) => setType(target.value),
                                                    value: type,
                                                    fullWidth: true,
                                                    disabled: !checkedFilters.checkType
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Tipo de Dispositivo
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkDeviceType}
                                                        onChange={() => handleCheck('checkDeviceType')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item style={{ display: 'flex' }} xs={9}>
                                            <Grid item xs={3}>
                                                <Select
                                                    options={[
                                                        { label: '|---- Selecione um valor ----|', value: null },
                                                        { label: 'Camera', value: 'camera' },
                                                        { label: 'DHI', value: 'dhi' },

                                                    ]}
                                                    textFieldProps={{
                                                        onChange: ({ target }) => {
                                                            setDeviceType(target.value);
                                                            setRefreshDevices(!refreshDevices);
                                                        },
                                                        value: deviceType,
                                                        fullWidth: true,
                                                        disabled: !checkedFilters.checkDeviceType
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Autocomplete
                                                    placeholder="Nome do dispositivo"
                                                    options={devices}
                                                    getOptionLabel={({ name }) => name || ''}
                                                    onChange={(_, value) => setDevice(value)}
                                                    getOptionSelected={(option, value) => (option.name === value.name)}
                                                    onInputChange={(_, value) => setDeviceSearch(value)}
                                                    value={device}
                                                    fullWidth
                                                    disabled={!checkedFilters.checkDeviceType}
                                                    textFieldProps={{
                                                        disabled: !checkedFilters.checkDeviceType
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Pessoa
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkPerson}
                                                        onChange={() => handleCheck('checkPerson')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Autocomplete
                                                placeholder="Nome"
                                                options={people}
                                                getOptionLabel={({ name }) => name || ''}
                                                onChange={(_, value) => setPerson(value)}
                                                getOptionSelected={(option, value) => (option.name === value.name)}
                                                onInputChange={(_, value) => setPersonSearch(value)}
                                                value={person}
                                                fullWidth
                                                disabled={!checkedFilters.checkPerson}
                                                textFieldProps={{
                                                    disabled: !checkedFilters.checkPerson
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Tipo de Pessoa
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkPersonType}
                                                        onChange={() => handleCheck('checkPersonType')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Autocomplete
                                                placeholder="Tipo de pessoa"
                                                options={personTypes}
                                                getOptionLabel={({ name }) => name || ''}
                                                onChange={(_, value) => setSelectedPersonType(value)}
                                                getOptionSelected={(option, value) => (option.name === value.name)}
                                                onInputChange={(_, value) => setSearchPersonTypes(value)}
                                                value={selectedPersonType}
                                                fullWidth
                                                disabled={!checkedFilters.checkPersonType}
                                                textFieldProps={{
                                                    disabled: !checkedFilters.checkPersonType
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Registros
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkSelection}
                                                        onChange={() => handleCheck('checkSelection')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                    { label: 'Todos', value: 'all' },
                                                    { label: 'Primeiro e Último', value: 'first_last' },

                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target }) => setSelection(target.value),
                                                    value: selection,
                                                    fullWidth: true,
                                                    disabled: !checkedFilters.checkSelection
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Detalhes
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkRejectionStatus}
                                                        onChange={() => handleCheck('checkRejectionStatus')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                    { label: 'Dia não autorizado', value: 'unauthorized_day' },
                                                    { label: 'Horário não autorizado', value: 'unauthorized_time' },
                                                    { label: 'Local não autorizado', value: 'unauthorized_location' },
                                                    { label: 'Acima da temperatura', value: 'over_temperature' },
                                                    { label: 'Sem máscara', value: 'without_mask' },
                                                    { label: 'Pessoa desconhecida', value: 'unknown_person' },
                                                    { label: 'Entrada Repetida', value: 're_entry' },
                                                    { label: 'Credencial Desconhecida', value: 'unknown_card' },
                                                    { label: 'Credencial Inválida', value: 'invalid_card' },
                                                    { label: 'A pessoa relacionada ja está sendo acompanhada', value: 'related_already_with_a_dependant' },
                                                    { label: 'A pessoa relacionada ainda não acessou', value: 'related_did_not_access' },
                                                    { label: 'Fora do horário de acompanhamento', value: 'companion_out_of_time' },
                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target }) => setRejectionStatus(target.value),
                                                    value: rejectionStatus,
                                                    fullWidth: true,
                                                    disabled: !checkedFilters.checkRejectionStatus
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Método
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkAccessMethod}
                                                        onChange={() => handleCheck('checkAccessMethod')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                    { label: 'Facial', value: 'facial' },
                                                    { label: 'Cartão', value: 'card' },
                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target }) => setAccessMethod(target.value),
                                                    value: accessMethod,
                                                    fullWidth: true,
                                                    disabled: !checkedFilters.checkAccessMethod
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Acesso
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkAllowedAccess}
                                                        onChange={() => handleCheck('checkAllowedAccess')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                    { label: 'Permitido', value: true },
                                                    { label: 'Negado', value: false },
                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target }) => setAllowedAccess(target.value),
                                                    value: allowedAccess,
                                                    fullWidth: true,
                                                    disabled: !checkedFilters.checkAllowedAccess
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <FormControlLabel
                                                label={
                                                    <Typography variant="subtitle1" className={classes.label}>
                                                        Período
                                                    </Typography>
                                                }
                                                control={
                                                    <Checkbox
                                                        checked={checkedFilters.checkTime}
                                                        onChange={() => handleCheck('checkTime')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={9} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Grid item>
                                                <DateTimePicker
                                                    className={classes.dateTimeInput}
                                                    format="DD/MM/YYYY HH:mm"
                                                    value={eventTime.start_time}
                                                    placeholder="Início"
                                                    onChange={date => dispatchEventTime({
                                                        type: 'change',
                                                        time: 'start_time',
                                                        value: date && date.format(),
                                                    })}
                                                    cancelLabel="cancelar"
                                                    disabled={!checkedFilters.checkTime}
                                                    fullWidth={true}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <DateTimePicker
                                                    className={classes.dateTimeInput}
                                                    format="DD/MM/YYYY HH:mm"
                                                    value={eventTime.end_time}
                                                    placeholder="Fim"
                                                    onChange={date => dispatchEventTime({
                                                        type: 'change',
                                                        time: 'end_time',
                                                        value: date && date.format(),
                                                    })}
                                                    cancelLabel="cancelar"
                                                    disabled={!checkedFilters.checkTime}
                                                    fullWidth={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <ButtonGroup variant='outlined'>
                                            <Button onClick={() => timeSet('today')} disabled={!checkedFilters.checkTime}>
                                                Hoje
                                            </Button>
                                            <Button onClick={() => timeSet('12h')} disabled={!checkedFilters.checkTime}>
                                                Últimas 12 horas
                                            </Button>
                                            <Button onClick={() => timeSet('24h')} disabled={!checkedFilters.checkTime}>
                                                Últimas 24 horas
                                            </Button>
                                            <Button onClick={() => timeSet('7d')} disabled={!checkedFilters.checkTime}>
                                                Últimos 7 dias
                                            </Button>
                                            <Button onClick={() => timeSet('this-week')} disabled={!checkedFilters.checkTime}>
                                                Essa semana
                                            </Button>
                                            <Button onClick={() => timeSet('this-month')} disabled={!checkedFilters.checkTime}>
                                                Esse mês
                                            </Button>
                                            <Button onClick={() => timeSet('all')} disabled={!checkedFilters.checkTime}>
                                                Todo o período
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='p' className={classes.label}>
                                            <i>
                                                Habilite os filtros que deseja usar. <br />
                                                Desabilite os que não mais desejar.
                                            </i>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setOpenFilterPopup(false);
                                            }}
                                        >
                                            Aplicar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table className={classes.table} stickyHeader size="small" aria-label="access history">
                            <TableHead>
                                <TableRow>
                                    {(tableColumns.showCode) && (
                                        <TableCell className={classes.tableHead}>Código</TableCell>
                                    )}
                                    {(tableColumns.showUserId) && (
                                        <TableCell className={classes.tableHead}>ID (Pessoa)</TableCell>
                                    )}
                                    {(tableColumns.showAccessMethod) && (
                                        <TableCell className={classes.tableHead}>Método de Acesso</TableCell>
                                    )}
                                    {(tableColumns.showUserName) && (
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                    )}
                                    {(tableColumns.showPersonType) && (
                                        <TableCell className={classes.tableHead}>Tipo de Pessoa</TableCell>
                                    )}
                                    {(tableColumns.showDocumentType) && (
                                        <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                    )}
                                    {(tableColumns.showDocumentNumber) && (
                                        <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                    )}
                                    {(tableColumns.showUserOrg) && (
                                        <TableCell className={classes.tableHead}>Organização (Pessoa)</TableCell>
                                    )}
                                    {(tableColumns.showSex) && (
                                        <TableCell className={classes.tableHead}>Sexo</TableCell>
                                    )}
                                    {(tableColumns.showIdentifiedAge) && (
                                        <TableCell className={classes.tableHead}>Idade Identificada</TableCell>
                                    )}
                                    {(tableColumns.showInformedAge) && (
                                        <TableCell className={classes.tableHead}>Idade Informada</TableCell>
                                    )}
                                    {(tableColumns.showMask) && (
                                        <TableCell className={classes.tableHead}>Máscara</TableCell>
                                    )}
                                    {(tableColumns.showEmotion) && (
                                        <TableCell className={classes.tableHead}>Sentimento</TableCell>
                                    )}
                                    {(tableColumns.showGlasses) && (
                                        <TableCell className={classes.tableHead}>Óculos</TableCell>
                                    )}
                                    {(tableColumns.showTemperature) && (
                                        <TableCell className={classes.tableHead}>Temperatura</TableCell>
                                    )}
                                    {(tableColumns.showDeviceType) && (
                                        <TableCell className={classes.tableHead}>Tipo de dispositivo</TableCell>
                                    )}
                                    {(tableColumns.showDeviceName) && (
                                        <TableCell className={classes.tableHead}>Nome do dispositivo</TableCell>
                                    )}
                                    {(tableColumns.showLocation) && (
                                        <TableCell className={classes.tableHead}>Localização</TableCell>
                                    )}
                                    {(tableColumns.showAction) && (
                                        <TableCell className={classes.tableHead}>Ação</TableCell>
                                    )}
                                    {(tableColumns.showSimilarity) && (
                                        <TableCell className={classes.tableHead}>Percentual de Semelhança</TableCell>
                                    )}
                                    {(tableColumns.showDateTime) && (
                                        <TableCell className={classes.tableHead}>Horário</TableCell>
                                    )}
                                    <PermissionOneOfWrapper
                                        routes={[
                                            "/relatorio_unificado/imagem/:id",
                                            "/relatorio_unificado/video/:id",
                                        ]}
                                    >
                                        {(tableColumns.showMedia) && (
                                            <TableCell className={classes.tableHead}>Mídia</TableCell>
                                        )}
                                    </PermissionOneOfWrapper>
                                    {(tableColumns.showExternalAPICalled) && (
                                        <TableCell className={classes.tableHead}>Marcação de Acesso</TableCell>
                                    )}
                                    {(tableColumns.showAccess) && (
                                        <TableCell className={classes.tableHead}>Acesso</TableCell>
                                    )}
                                    {(tableColumns.showRejectionStatus) && (
                                        <TableCell className={classes.tableHead}>Detalhes</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            {
                                !!showSkeleton ? (
                                    <TableBodySkeleton total={total} limit={limit} />
                                ) : (
                                    <TableBody>
                                        {!!accessLogs && accessLogs.map(access => (
                                            <TableRow key={access.id}>
                                                {(tableColumns.showCode) && (
                                                    <TableCell className={classes.tableBody} component="th" scope="row">
                                                        {access ? access.id : '-'}
                                                    </TableCell>
                                                )}
                                                {(tableColumns.showUserId) && (
                                                    <TableCell className={classes.tableBody} component="th" scope="row">
                                                        {access?.user_id ? access.user_id : '-'}
                                                    </TableCell>
                                                )}
                                                {(tableColumns.showAccessMethod) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access?.method === 'card'
                                                            ? (
                                                                <Tooltip
                                                                    title={
                                                                        <Typography variant="subtitle1">
                                                                            <b>Credencial:</b> {access.credential}
                                                                        </Typography>
                                                                    }
                                                                    arrow
                                                                >
                                                                    <IconButton
                                                                        style={{ padding: 0 }}
                                                                        onClick={() => handleOpenAccessDetails(access)}
                                                                    >
                                                                        <CardIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )
                                                            : (
                                                                <Tooltip
                                                                    title={
                                                                        <Typography variant="subtitle1">
                                                                            <b>Reconhecimento Facial</b>
                                                                        </Typography>
                                                                    }
                                                                    arrow
                                                                >
                                                                    <IconButton
                                                                        style={{ padding: 0 }}
                                                                        onClick={() => handleOpenAccessDetails(access)}
                                                                    >
                                                                        <PersonIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )
                                                        }
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showUserName) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access?.user
                                                            ? access.user.name
                                                            : <b>Não identificado</b>
                                                        }
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showPersonType) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access?.user?.PersonType
                                                            ? access.user.PersonType?.name
                                                            : <b>-</b>
                                                        }
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showDocumentType) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {
                                                            getDocumentType(access?.user?.doc_type)
                                                        }
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showDocumentNumber) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access?.user && access?.user?.doc_number !== '' && access?.user?.doc_number !== null
                                                            ? access.user?.doc_number
                                                            : <b>-</b>
                                                        }
                                                    </TableCell>
                                                )}
                                                {(tableColumns.showUserOrg) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access?.user
                                                            ? ((access.user.OrganizationalStructure) ? access.user.OrganizationalStructure.name : '-')
                                                            : <b>Não identificado</b>
                                                        }
                                                    </TableCell>
                                                )}
                                                {(tableColumns.showSex) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access?.user
                                                            ? ((access.user.sex === 'male') ? 'Masculino' : (access.user.sex === 'female') ? 'Feminino' : 'Desconhecido')
                                                            : <b>Não identificado</b>
                                                        }
                                                    </TableCell>
                                                )}
                                                {(tableColumns.showIdentifiedAge) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {
                                                            access?.attribute_age
                                                                ? access.attribute_age
                                                                : '-'
                                                        }
                                                    </TableCell>)}
                                                {(tableColumns.showInformedAge) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {
                                                            access?.user?.birthday
                                                                ? date().diff(access.user.birthday, 'years')
                                                                : '-'
                                                        }
                                                    </TableCell>)}
                                                {(tableColumns.showMask) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access?.attribute_mask === 2 ? 'Sim' : (access?.attribute_mask === 1) ? 'Não' : 'Não detectado'}
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showEmotion) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {(access.attribute_emotion) ? (getEmotion(access.attribute_emotion)) : ("Não identificado")}
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showGlasses) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {(access.attribute_glasses) ? (getGlassesType(access.attribute_glasses)) : ("Não identificado")}
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showTemperature) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access.current_temperature ? (`${number.toFloat(access.current_temperature, 1)}°C`) : '-'}
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showDeviceType) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {(access.camera) ? ('Câmera') : (access.Device) ? ("DHI") : ("-")}
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showDeviceName) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {(access.camera) ? (access.camera.name) : (access.Device) ? (access.Device.name) : ("-")}
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showLocation) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {(access.camera) ? (access.camera.place_cameras?.name) : (access.Device) ? (access.Device.place_devices?.name) : ("-")}
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showAction) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {
                                                            access.action_type ? ((access.action_type === 'entry') ? ('Entrou') :
                                                                (access.action_type === 'internal') ? ('Presente') :
                                                                    (access.action_type === 'exit') ? ('Saiu') :
                                                                        ("-")) : '-'
                                                        }
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showSimilarity) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access.similarity ? `${access.similarity}%` : '-'}
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showDateTime) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access.event_time ? date(access.event_time).toLocaleFormat() : '-'}
                                                    </TableCell>
                                                )}

                                                <PermissionOneOfWrapper
                                                    routes={[
                                                        "/relatorio_unificado/imagem/:id",
                                                        "/relatorio_unificado/video/:id",
                                                    ]}
                                                >
                                                    {(tableColumns.showMedia) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {
                                                                dhiAccess.includes(access.type) ? (
                                                                    <PermissionWrapper route="/relatorio_unificado/imagem/:id" otherwise="-">
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size="small"
                                                                            className={classes.videoButton}
                                                                            startIcon={<PlayArrowIcon fontSize="small" />}
                                                                            onClick={() => getImage(access)}
                                                                            disabled={loadingMedia}
                                                                        >
                                                                            {
                                                                                (accessLog && accessLog.id === access.id && loadingMedia) ? (
                                                                                    "Carregando"
                                                                                ) : (
                                                                                    "Visualizar"
                                                                                )
                                                                            }
                                                                        </Button>
                                                                    </PermissionWrapper>
                                                                ) : cameraAccess.includes(access.type) ? (
                                                                    <PermissionWrapper route="/relatorio_unificado/video/:id" otherwise="-">
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size="small"
                                                                            className={classes.videoButton}
                                                                            startIcon={<PlayArrowIcon fontSize="small" />}
                                                                            onClick={() => getVideo(access)}
                                                                        >
                                                                            {
                                                                                (accessLog && accessLog.id === access.id && loadingMedia) ? (
                                                                                    "Carregando"
                                                                                ) : (
                                                                                    "Visualizar"
                                                                                )
                                                                            }
                                                                        </Button>
                                                                    </PermissionWrapper>
                                                                ) : <></>
                                                            }
                                                        </TableCell>
                                                    )}
                                                </PermissionOneOfWrapper>

                                                {(tableColumns.showExternalAPICalled) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {
                                                            access.external_api_called === "SENT" ? "MARCADO" :
                                                                access.external_api_called === "NOT_NEEDED" ? "INDIFERENTE" :
                                                                    access.external_api_called === "DISABLED" ? "DESABILITADO" :
                                                                        access.external_api_called === "ERROR" ? "ERRO AO MARCAR" :
                                                                            "-"
                                                        }
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showAccess) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access.allowed_access ? (
                                                            <Chip
                                                                icon={<CheckIcon className={classes.authorizationIcon} />}
                                                                label={accessStatus({
                                                                    type: access.action_type,
                                                                    allowed: access.allowed_access,
                                                                })}
                                                                size="small"
                                                                className={classes.authorizedAccess}
                                                            />
                                                        ) : (
                                                            <Chip
                                                                icon={<CloseIcon className={classes.authorizationIcon} />}
                                                                label={accessStatus({
                                                                    type: access.action_type,
                                                                    allowed: access.allowed_access,
                                                                })}
                                                                size="small"
                                                                className={classes.unauthorizedAccess}
                                                            />
                                                        )}
                                                    </TableCell>
                                                )}

                                                {(tableColumns.showRejectionStatus) && (
                                                    <TableCell className={classes.tableBody}>
                                                        {access?.alcolizer_test_result ? (
                                                            access?.alcolizer_test_result === 'negative' ? 'ÁLCOOL NÃO DETECTADO' : 'ÁLCOOL DETECTADO'
                                                        ) : (
                                                            getRejectionStatus(access.rejection_status)
                                                        )}
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )
                            }
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={total}
                        rowsPerPage={limit}
                        page={page}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                        labelRowsPerPage="Registros por página"
                        onChangePage={changePage}
                        onChangeRowsPerPage={quantityPerPage}
                    />
                </Grid>
                <FullScreenDialog
                    open={openViewMedia}
                    title={mediaDialogTitle}
                    onClose={clearMedia}
                >
                    <AccessMedia log={accessLog} url={mediaUrl} />
                </FullScreenDialog>
                <Popup
                    title="Detalhes do Acesso"
                    openPopup={openAccessDetails}
                    onClose={handleOpenAccessDetails}
                    width="100%"
                >
                    <Grid container>
                        <Grid
                            item
                            xs={6}
                            style={{
                                background: grey[300],
                                height: "900px",
                                padding: 6
                            }}
                            onClick={() => console.log(accessLog)}
                        >
                            <Grid container alignItems='center' justify='center' style={{ gap: 6, height: '100%' }}>
                                <Grid container alignItems='center' justify='center'>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.accessImageAlt}
                                    >
                                        NO CADASTRO
                                    </Typography>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            background: grey[500],
                                            height: "300px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 15
                                        }}
                                    >
                                        {
                                            userFaceUrl !== ''
                                                ? <img src={userFaceUrl} className={classes.video} alt="IMAGEM" />
                                                : (
                                                    <Typography
                                                        variant="subtitle1"
                                                        className={classes.accessImageAlt}
                                                    >
                                                        SEM IMAGEM CADASTRADA
                                                    </Typography>
                                                )
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container alignItems='center' justify='center'>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.accessImageAlt}
                                    >
                                        NO ACESSO
                                    </Typography>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            background: grey[500],
                                            height: "300px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 15
                                        }}
                                    >
                                        {
                                            (accessLog && mediaUrl) ? (
                                                <AccessMedia log={accessLog} url={mediaUrl} />
                                            ) : (
                                                <Typography
                                                    variant="subtitle1"
                                                    className={classes.accessImageAlt}
                                                >
                                                    NÃO POSSÍVEL RECUPERAR A IMAGEM/VIDEO DO ACESSO
                                                </Typography>
                                            )

                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container style={{ padding: 6, width: '100%' }}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.detailsSideTitle}

                                    >
                                        DADOS PESSOAIS
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="ID (Pessoa)"
                                        value={accessLog?.user ? accessLog.user.id : "Desconhecido"}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Nome"
                                        value={accessLog?.user ? accessLog.user.name : "Desconhecido"}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Documento"
                                        value={
                                            accessLog?.user?.doc_type
                                                ? `[${getDocumentType(accessLog?.user?.doc_type)}] ${accessLog?.user?.doc_number}`
                                                : 'Desconhecido'
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Tipo de Pessoa"
                                        value={
                                            accessLog?.user?.PersonType
                                                ? accessLog?.user?.PersonType.name
                                                : '-'
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Estrutura Organizacional"
                                        value={
                                            accessLog?.user?.OrganizationalStructure
                                                ? accessLog?.user?.OrganizationalStructure.name
                                                : '-'
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.divider}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.detailsSideTitle}
                                    >
                                        DISPOSIVO E LOCALIZAÇÃO
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Tipo"
                                        value={
                                            accessLog?.device_id
                                                ? "Leitor Facial"
                                                : accessLog?.camera_id
                                                    ? "Câmera"
                                                    : "Desconhecido"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Nome"
                                        value={
                                            accessLog?.Device
                                                ? accessLog?.Device?.name
                                                : accessLog?.camera
                                                    ? accessLog?.camera?.name :
                                                    "Desconhecido"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Localização"
                                        value={
                                            accessLog?.Device
                                                ? accessLog?.Device?.place_devices?.name
                                                : accessLog?.camera
                                                    ? accessLog?.camera?.place_cameras?.name :
                                                    "Desconhecida"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Ação"
                                        value={
                                            accessLog?.action_type && accessLog?.action_type === "entry"
                                                ? "Entrada"
                                                : accessLog?.action_type === "internal"
                                                    ? "Acesso Interno"
                                                    : accessLog?.action_type === "exit"
                                                        ? "Saída"
                                                        : "Desconhecida"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Método"
                                        value={
                                            accessLog?.method && accessLog?.method === "facial"
                                                ? "Reconhecimento Facial"
                                                : accessLog?.method === "card"
                                                    ? "Cartão"
                                                    : "Desconhecido"
                                        }
                                    />
                                </Grid>
                                {
                                    accessLog?.method === "card" && (
                                        <Grid item xs={6}>
                                            <DetailField
                                                label="Credencial"
                                                value={
                                                    accessLog?.credential
                                                        ? accessLog.credential
                                                        : "Desconhecida"
                                                }
                                            />
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} className={classes.divider}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.detailsSideTitle}
                                    >
                                        SOBRE O ACESSO
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Horário"
                                        value={
                                            accessLog?.event_time
                                                ? date(accessLog.event_time).toLocaleFormat()
                                                : "-"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailField
                                        label="Status"
                                        value={
                                            accessLog?.allowed_access === true
                                                ? "Acesso Permitido"
                                                : "Acesso Negado"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DetailField
                                        label="Detalhes"
                                        value={
                                            accessLog?.rejection_status
                                                ? getRejectionStatus(accessLog.rejection_status)
                                                : "Nenhuma Irregularidade"
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Popup>
                <ExportModal
                    open={openExport}
                    onClose={handleCloseExport}
                    handleExport={exportUnifiedReport}
                />
                <ColumnsManagementModal
                    open={openTableEditPopup}
                    setOpen={setOpenTableEditPopup}
                    tableColumns={tableColumns}
                    dispatchTableColumns={dispatchTableColumns}
                />
            </main>
            {!!message.open && (
                <Alert
                    open={message.open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    severity={message.variant}
                    onClose={closeMessage}
                >
                    {message.text}
                </Alert>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: `calc(100% - ${metrics.drawerWidth}px)`,
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    smallTitle: {
        color: grey[600],
        marginLeft: theme.spacing(1),
        fontSize: 16,
    },
    filter: {
        margin: theme.spacing(0, 0, 3, 0),
    },
    dateTimeInput: {
        marginRight: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: '95%',
        width: '100%',
    },
    table: {
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    registeredInSector: {
        color: green[700],
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: 11,
    },
    unregisteredInSector: {
        color: orange[700],
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: 11,
    },
    notRegistered: {
        color: red[700],
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: 11,
    },
    authorizedAccess: {
        backgroundColor: green[700],
        color: '#FFF',
    },
    unauthorizedAccess: {
        backgroundColor: red[700],
        color: '#FFF',
    },
    authorizationIcon: {
        color: '#FFF',
    },
    videoButton: {
        lineHeight: 1,
        '& span > svg': {
            marginRight: 2,
        },
    },
    video: {
        maxHeight: '100%',
        height: '100%',
        maxWidth: '100%',
        display: 'block',
        margin: 'auto',
    },
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: '60%',
    },
    dialogTitle: {
        paddingRight: '0px',
    },
    label: {
        color: '#9e9e9e',
        fontWeight: 'bold',
        userSelect: 'none',
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    filterItem: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    divider: {
        marginTop: 6,
        marginBottom: 6,
    },
    detailsSideTitle: {
        fontWeight: 700,
        color: grey[700],
    },
    accessImageAlt: {
        fontWeight: 700,
        color: grey[900],
    }
}));
