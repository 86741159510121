import React, { useRef } from 'react'
import config from '../configs/config';


const {host, port} = config;

const Canvas = props => {
  
  const canvas = useRef(null);

 /*  const {} = props; */
  
  return <canvas 
            className="canvas-pin"
            ref={canvas}
            style={{ marginTop: "25px", backgroundImage: `url(http://${host}:${port}/midias/imagem/map/${props.image})`  }}
            onMouseDown={(e)=> props.getCursorPos(e, props.index, canvas)} 
            >
              {props.children}
            </canvas>
}

export default Canvas