import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
    Tabs, Tab, Box
} from '@material-ui/core';
import BarChartIcon from '@mui/icons-material/BarChart';
import ClearDevice from '@mui/icons-material/CleaningServices';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DoorBackIcon from '@mui/icons-material/DoorBack';
//import ConfigIcon from '@material-ui/icons/Settings';
import CamIcon from '@material-ui/icons/Videocam';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { loadPlayer } from 'rtsp-relay/browser'

import SideMenu from '../../components/SideMenu';
import Input from '../../components/Input';
import Alert from '../../components/Alert';
import Popup from '../../components/Popup';
import PopUpDeviceCapacity from '../../components/PopUpDeviceCapacity';
import FullScreenDialog from '../../components/FullScreenDialog';
import InputSearch from '../../components/InputSearch';

import api from '../../services/api';
import config from '../../configs/config';
import storage from '../../utils/storage';
import { PermissionsContext } from '../../providers/Permissions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function DeviceList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [devices, setDevices] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [refresh, setRefresh] = useState(true)
    const [authenticated, setAuthenticated] = useState(null);
    const [limit, setLimit] = useState(10);
    // eslint-disable-next-line
    const [deviceSettings, setDeviceSettings] = useState({
        input_01: {
            description: '',
            input_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        input_02: {
            description: '',
            input_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        input_03: {
            description: '',
            input_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        input_04: {
            description: '',
            input_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        output_01: {
            description: '',
            output_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        output_02: {
            description: '',
            output_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        output_03: {
            description: '',
            output_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        output_04: {
            description: '',
            output_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
    })
    const [openSettingsPopup, setOpenSettingsPopup] = useState(false);
    const [openCameraPopup, setOpenCameraPopup] = useState(false);
    const [deviceCameras, setDeviceCameras] = useState([]);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);

    const [loadingCapacity, setLoadingCapacity] = useState(false);
    const [capacity, setCapacity] = useState({});
    const [openCapacityPopup, setOpenCapacityPopup] = useState(false);


    const [openDoorPopup, setOpenDoorPopup] = useState(false);

    const [openClearDeviceData, setOpenClearDeviceData] = useState(false);
    const [deviceUsername, setDeviceUsername] = useState('');
    const [devicePassword, setDevicePassword] = useState('');
    const [clearingDevice, setClearingDevice] = useState('');

    const [openRTSP, setOpenRTSP] = useState(false);
    const [loadingRSTP, setLoadingRSTP] = useState(false);
    const [rtspRunning, setRTSPRunning] = useState(false);
    const rtspCanvas = useRef(null);

    const handleCloseRSTP = () => {
        setOpenRTSP(false);
        setLoadingRSTP(false);
        setRTSPRunning(false);
    }

    const connectionEstabilished = () => {
        setLoadingRSTP(true);
    };

    const playStarted = () => {
        setRTSPRunning(true);
        setLoadingRSTP(false);
    }

    useEffect(() => {
        if (openRTSP) {
            setTimeout(() => {
                loadPlayer({
                    url: `${config.relayUrl}/api/stream/?nvr=${selectedDevice.host}&cam=${1}&username=${selectedDevice.username}&password=${selectedDevice.password}&subtype=1`,
                    canvas: rtspCanvas.current,
                    onSourceEstablished: connectionEstabilished,
                    onPlay: playStarted,
                    onDisconnect: () => setRTSPRunning(false),
                });
            }, 2000);
        }
        //eslint-disable-next-line
    }, [openRTSP]);

    const [value, setValue] = useState(0);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState();

    const [submitting, setSubmitting] = useState({ type: '', onGoing: false });
    const [doorControlNotes, setDoorControlNotes] = useState('');

    const handleDelete = (device) => {
        setSelectedDevice(device);
        setOpenDeletePopup(true);
    };

    const resetControlPopup = () => {
        setDoorControlNotes('');
        setSelectedDevice(null);
    };

    const handleControlDevice = (device) => {
        setSelectedDevice(device);
        setOpenDoorPopup(true);
        console.log(device)
    };

    const handleGetCapacity = (device) => {
        getCapacity(device);
        setOpenCapacityPopup(true);
    };

    const handleClearDeviceData = (device) => {
        setSelectedDevice(device);
        setOpenClearDeviceData(true);
    }

    const handleCloseRemoveDeviceData = () => {
        setDeviceUsername('');
        setDevicePassword('');
        setSelectedDevice(null);
    };

    const removeDeviceData = async () => {
        setClearingDevice(true);
        try {
            const result = await api.post('/device/clear_data', {
                device_id: selectedDevice?.id,
                username: deviceUsername,
                password: devicePassword,
            });

            if (result.status === 204) {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: 'Dados limpos com sucesso'
                });
            }

            handleCloseRemoveDeviceData();
            setOpenClearDeviceData(false);
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error
            });
        }
        setClearingDevice(false);
    }

    const handlePingDoor = async () => {
        try {
            setSubmitting({ type: 'ping', onGoing: true });
            const result = await api.get('/door_control/ping', {
                params: {
                    user_id: authenticated?.id,
                    device_id: selectedDevice?.id,
                }
            });

            if (result.status === '200') {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: 'Alerta sônoro enviado'
                });
            }
            setSubmitting({ type: '', onGoing: false });
        } catch (err) {
            setSubmitting({ type: '', onGoing: false });
            setMessage({
                open: true,
                variant: 'error',
                text: err.error
            });
        }
    };


    const handleOpenDoor = async () => {
        try {
            setSubmitting({ type: 'open', onGoing: true });
            const result = await api.get('/door_control/open', {
                params: {
                    user_id: authenticated?.id,
                    device_id: selectedDevice?.id,
                    notes: doorControlNotes,
                }
            });

            if (result.status === '200') {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: 'Porta liberada'
                });
            }
            setSubmitting({ type: '', onGoing: false });
            setDoorControlNotes('');
        } catch (err) {
            setSubmitting({ type: '', onGoing: false });
            setMessage({
                open: true,
                variant: 'error',
                text: err.error
            });
        }
    };

    // const handleSettings = (id) => {
    //     let selectedDevice = devices.filter(device => device.id === id);
    //     let settings = {
    //         input_01: selectedDevice[0].input_01,
    //         input_02: selectedDevice[0].input_02,
    //         input_03: selectedDevice[0].input_03,
    //         input_04: selectedDevice[0].input_04,
    //         output_01: selectedDevice[0].output_01,
    //         output_02: selectedDevice[0].output_02,
    //         output_03: selectedDevice[0].output_03,
    //         output_04: selectedDevice[0].output_04
    //     };
    //     setDeviceSettings(settings);
    //     setOpenSettingsPopup(true);
    // }

    const handleCameras = (device) => {
        let deviceCameras = Object.assign([], device?.cameras);
        setDeviceCameras(deviceCameras);
        setOpenCameraPopup(true);
    };

    const deleteDevice = async (id) => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/device/delete/${selectedDevice.id}`);

            const { success } = result.data;

            setIsDeleting(false);

            if (success) {
                setSelectedDevice(null);
                setOpenDeletePopup(false);
                setRefresh(!refresh);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Dispositivo deletado com sucesso!`,
                });
            }
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const getCapacity = async device => {
        setLoadingCapacity(true);
        try {
            const result = await api.get(`/device/capacity/${device.id}`);

            setCapacity(result.data);
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: 'Não foi possível calcular a capacidade',
            });
        };
        setLoadingCapacity(false);
    };

    useEffect(() => {
        const user = storage.getItem('@Identifik:user');

        setAuthenticated(user);
    }, []);

    function updateRequest({ search }) {

        const loadData = async () => {
            try {
                const result = await api.get('/device/list', {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });

                //console.log(search, limit, page)

                const { count, devices } = result.data;

                //console.log(devices);

                setDevices(devices);
                setTotal(count);

                console.log('teste')
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();

        // const load = setInterval(loadData, 10000);

        // return () => {
        //     clearInterval(load);
        // };

    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Dispositivos
                        </Typography>
                    </Grid>
                    <Grid item spacing={5} xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={updateRequest}
                            pagination={[page, limit, refresh]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Ativo</TableCell>
                                        <TableCell className={classes.tableHead}>Online</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Host</TableCell>
                                        <TableCell className={classes.tableHead}>Porta</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Localidade</TableCell>
                                        <TableCell className={classes.tableHead}>Descrição</TableCell>
                                        <TableCell className={classes.tableHead}>Organização</TableCell>
                                        <PermissionOneOfWrapper routes={[
                                            "/dispositivo/verificar_porta",
                                            "/dispositivo/abrir_porta",
                                            "/camera/:id",
                                        ]}>
                                            <TableCell className={classes.tableHead}>Monitoramento e Controle</TableCell>
                                        </PermissionOneOfWrapper>
                                        <PermissionOneOfWrapper routes={[
                                            "/dispositivo/modificar/:id",
                                            "/dispositivo/deletar/:id",
                                            "/dispositivo/capacidade/:id",
                                            "/dispositivo/limpar_dados",
                                        ]}>
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!devices && devices.map(device => (
                                        <TableRow key={device.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {device.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {device.enabled ? (
                                                    <div className={classes.online} />
                                                ) : (
                                                    <div className={classes.offline} />
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {device.status && device.enabled ? (
                                                    <div className={classes.online} />
                                                ) : (
                                                    <div className={classes.offline} />
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.host}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.port}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.type.toUpperCase()}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device?.place_devices?.name ?? ""}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.description}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    !!device.OrganizationalStructure
                                                        ? device.OrganizationalStructure.name
                                                        : '-'
                                                }
                                            </TableCell>
                                            <PermissionOneOfWrapper routes={[
                                                "/dispositivo/verificar_porta",
                                                "/dispositivo/abrir_porta",
                                                "/camera/:id",
                                            ]}>
                                                <TableCell className={classes.tableBody}>
                                                    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                                                        {/* <Grid item style={{margin: '0 1%'}}>
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            className={classes.actionButton}
                                                            //onClick={() => handleSettings(device.id)}
                                                        >
                                                            <ConfigIcon />
                                                        </Button>
                                                    </Grid> */}
                                                        {(device.type !== 'dhi') && (
                                                            <PermissionWrapper route="/camera/:id">
                                                                <Grid item style={{ margin: '0 1%' }}>
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        size="small"
                                                                        color="primary"
                                                                        className={classes.actionButton}
                                                                        onClick={() => handleCameras(device)}
                                                                    >
                                                                        <CamIcon />
                                                                    </Button>
                                                                </Grid>
                                                            </PermissionWrapper>
                                                        )}
                                                        {(device.type === 'dhi') && (
                                                            <PermissionOneOfWrapper routes={[
                                                                "/dispositivo/verificar_porta",
                                                                "/dispositivo/abrir_porta",
                                                                "/camera/:id",
                                                            ]}>
                                                                <Grid item style={{ margin: '0 1%' }}>
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        size="small"
                                                                        color="primary"
                                                                        className={classes.actionButton}
                                                                        onClick={() => handleControlDevice(device)}
                                                                    >
                                                                        <DoorBackIcon />
                                                                    </Button>
                                                                </Grid>
                                                            </PermissionOneOfWrapper>
                                                        )}
                                                    </Grid>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                            <PermissionOneOfWrapper routes={[
                                                "/dispositivo/modificar/:id",
                                                "/dispositivo/deletar/:id",
                                                "/dispositivo/capacidade/:id",
                                                "/dispositivo/limpar_dados",
                                            ]}>
                                                <TableCell className={classes.tableBody}>
                                                    <>
                                                        <PermissionWrapper route="/dispositivo/modificar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                className={classes.actionButton}
                                                                href={`/dispositivo/modificar/${device.id}`}
                                                            >
                                                                <EditIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <PermissionWrapper route="/dispositivo/deletar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="secondary"
                                                                className={classes.actionButton}
                                                                onClick={() => handleDelete(device)}
                                                            >
                                                                <DeleteIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <PermissionWrapper route="/dispositivo/capacidade/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                style={{ background: '#ECB365' }}
                                                                className={classes.actionButton}
                                                                onClick={() => handleGetCapacity(device)}
                                                            >
                                                                <BarChartIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <PermissionWrapper route="/dispositivo/limpar_dados">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="secondary"
                                                                style={{ background: '#d10057' }}
                                                                className={classes.actionButton}
                                                                onClick={() => handleClearDeviceData(device)}
                                                            >
                                                                <ClearDevice style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                    </>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                    <Popup
                        openPopup={openSettingsPopup}
                        setOpenPopup={setOpenSettingsPopup}
                        title='Configurações do Dispositivo'
                    >
                        <Grid container direction='column' spacing={1} xs={12}>
                            <Grid item xs>
                                <Grid justify='center' container xs={12}>
                                    <Tabs
                                        xs={12}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        centered
                                        onChange={handleChangeTabs}
                                        value={value}
                                        aria-label="tabs"
                                    >
                                        <Tab label="Entrada" index={0} />
                                        <Tab label="Saída" index={1} />
                                    </Tabs>
                                </Grid>
                            </Grid>

                            <Grid item xs>
                                <Grid container xs>
                                    <TabPanel value={value} index={0}>
                                        <Grid container justify='space-between' styles={{ margin: '0.5%' }}>
                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                <Grid container>
                                                    <Typography variant='h5'>
                                                        Entrada 01
                                                    </Typography>
                                                </Grid>
                                                <Grid container style={{
                                                    margin: '2% 0'
                                                }}>
                                                    <Input
                                                        label="Descrição"
                                                        textFieldProps={{
                                                            name: 'input_01.description',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_01.description,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Comportamento de entrada"
                                                        textFieldProps={{
                                                            name: 'input_01.input_behaviour',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_01.input_behaviour,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Tipo de pulso"
                                                        textFieldProps={{
                                                            name: 'input_01.wave_type',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_01.wave_type,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Transição que inicia o pulso"
                                                        textFieldProps={{
                                                            name: 'input_01.wave_ignition',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_01.wave_ignition,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Reset da duração do pulso"
                                                        textFieldProps={{
                                                            name: 'input_01.wave_reset',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_01.wave_reset,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Duração do pulso"
                                                        textFieldProps={{
                                                            name: 'input_01.wave_duration',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_01.wave_duration,
                                                            required: false,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                <Grid container>
                                                    <Typography variant='h5'>
                                                        Entrada 02
                                                    </Typography>
                                                </Grid>
                                                <Grid container style={{
                                                    margin: '2% 0'
                                                }}>
                                                    <Input
                                                        label="Descrição"
                                                        textFieldProps={{
                                                            name: 'input_02.description',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_02.description,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Comportamento de entrada"
                                                        textFieldProps={{
                                                            name: 'input_02.input_behaviour',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_02.input_behaviour,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Tipo de pulso"
                                                        textFieldProps={{
                                                            name: 'input_02.wave_type',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_02.wave_type,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Transição que inicia o pulso"
                                                        textFieldProps={{
                                                            name: 'input_02.wave_ignition',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_02.wave_ignition,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Reset da duração do pulso"
                                                        textFieldProps={{
                                                            name: 'input_02.wave_reset',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_02.wave_reset,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Duração do pulso"
                                                        textFieldProps={{
                                                            name: 'input_02.wave_duration',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_02.wave_duration,
                                                            required: false,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                <Grid container>
                                                    <Typography variant='h5'>
                                                        Entrada 03
                                                    </Typography>
                                                </Grid>
                                                <Grid container style={{
                                                    margin: '2% 0'
                                                }}>
                                                    <Input
                                                        label="Descrição"
                                                        textFieldProps={{
                                                            name: 'input_03.description',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_03.description,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Comportamento de entrada"
                                                        textFieldProps={{
                                                            name: 'input_03.input_behaviour',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_03.input_behaviour,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Tipo de pulso"
                                                        textFieldProps={{
                                                            name: 'input_03.wave_type',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_03.wave_type,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Transição que inicia o pulso"
                                                        textFieldProps={{
                                                            name: 'input_03.wave_ignition',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_03.wave_ignition,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Reset da duração do pulso"
                                                        textFieldProps={{
                                                            name: 'input_03.wave_reset',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_03.wave_reset,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Duração do pulso"
                                                        textFieldProps={{
                                                            name: 'input_03.wave_duration',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_03.wave_duration,
                                                            required: false,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                <Grid container>
                                                    <Typography variant='h5'>
                                                        Entrada 04
                                                    </Typography>
                                                </Grid>
                                                <Grid container style={{
                                                    margin: '2% 0'
                                                }}>
                                                    <Input
                                                        label="Descrição"
                                                        textFieldProps={{
                                                            name: 'input_04.description',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_04.description,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Comportamento de entrada"
                                                        textFieldProps={{
                                                            name: 'input_04.input_behaviour',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_04.input_behaviour,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Tipo de pulso"
                                                        textFieldProps={{
                                                            name: 'input_04.wave_type',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_04.wave_type,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Transição que inicia o pulso"
                                                        textFieldProps={{
                                                            name: 'input_04.wave_ignition',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_04.wave_ignition,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Reset da duração do pulso"
                                                        textFieldProps={{
                                                            name: 'input_04.wave_reset',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_04.wave_reset,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Duração do pulso"
                                                        textFieldProps={{
                                                            name: 'input_04.wave_duration',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.input_04.wave_duration,
                                                            required: false,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Grid container justify='space-between' styles={{ margin: '0.5%' }}>
                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                <Grid container>
                                                    <Typography variant='h5'>
                                                        Saída 01
                                                    </Typography>
                                                </Grid>
                                                <Grid container style={{
                                                    margin: '2% 0'
                                                }}>
                                                    <Input
                                                        label="Descrição"
                                                        textFieldProps={{
                                                            name: 'output_01.description',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_01.description,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Comportamento de saída"
                                                        textFieldProps={{
                                                            name: 'output_01.output_behaviour',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_01.output_behaviour,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Tipo de pulso"
                                                        textFieldProps={{
                                                            name: 'output_01.wave_type',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_01.wave_type,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Transição que inicia o pulso"
                                                        textFieldProps={{
                                                            name: 'output_01.wave_ignition',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_01.wave_ignition,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Reset da duração do pulso"
                                                        textFieldProps={{
                                                            name: 'output_01.wave_reset',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_01.wave_reset,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Duração do pulso"
                                                        textFieldProps={{
                                                            name: 'output_01.wave_duration',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_01.wave_duration,
                                                            required: false,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                <Grid container>
                                                    <Typography variant='h5'>
                                                        Saída 02
                                                    </Typography>
                                                </Grid>
                                                <Grid container style={{
                                                    margin: '2% 0'
                                                }}>
                                                    <Input
                                                        label="Descrição"
                                                        textFieldProps={{
                                                            name: 'output_02.description',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_02.description,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Comportamento de saída"
                                                        textFieldProps={{
                                                            name: 'output_02.output_behaviour',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_02.output_behaviour,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Tipo de pulso"
                                                        textFieldProps={{
                                                            name: 'output_02.wave_type',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_02.wave_type,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Transição que inicia o pulso"
                                                        textFieldProps={{
                                                            name: 'output_02.wave_ignition',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_02.wave_ignition,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Reset da duração do pulso"
                                                        textFieldProps={{
                                                            name: 'output_02.wave_reset',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_02.wave_reset,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Duração do pulso"
                                                        textFieldProps={{
                                                            name: 'output_02.wave_duration',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_02.wave_duration,
                                                            required: false,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                <Grid container>
                                                    <Typography variant='h5'>
                                                        Saída 03
                                                    </Typography>
                                                </Grid>
                                                <Grid container style={{
                                                    margin: '2% 0'
                                                }}>
                                                    <Input
                                                        label="Descrição"
                                                        textFieldProps={{
                                                            name: 'output_03.description',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_03.description,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Comportamento de saída"
                                                        textFieldProps={{
                                                            name: 'output_03.output_behaviour',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_03.output_behaviour,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Tipo de pulso"
                                                        textFieldProps={{
                                                            name: 'output_03.wave_type',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_03.wave_type,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Transição que inicia o pulso"
                                                        textFieldProps={{
                                                            name: 'output_03.wave_ignition',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_03.wave_ignition,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Reset da duração do pulso"
                                                        textFieldProps={{
                                                            name: 'output_03.wave_reset',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_03.wave_reset,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Duração do pulso"
                                                        textFieldProps={{
                                                            name: 'output_03.wave_duration',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_03.wave_duration,
                                                            required: false,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                <Grid container>
                                                    <Typography variant='h5'>
                                                        Saída 04
                                                    </Typography>
                                                </Grid>
                                                <Grid container style={{
                                                    margin: '2% 0'
                                                }}>
                                                    <Input
                                                        label="Descrição"
                                                        textFieldProps={{
                                                            name: 'output_04.description',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_04.description,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Comportamento de saída"
                                                        textFieldProps={{
                                                            name: 'output_04.output_behaviour',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_04.output_behaviour,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Tipo de pulso"
                                                        textFieldProps={{
                                                            name: 'output_04.wave_type',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_04.wave_type,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Transição que inicia o pulso"
                                                        textFieldProps={{
                                                            name: 'output_04.wave_ignition',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_04.wave_ignition,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Reset da duração do pulso"
                                                        textFieldProps={{
                                                            name: 'output_04.wave_reset',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_04.wave_reset,
                                                            required: false,
                                                        }}
                                                    />
                                                    <Input
                                                        label="Duração do pulso"
                                                        textFieldProps={{
                                                            name: 'output_04.wave_duration',
                                                            disabled: true,
                                                            fullWidth: true,
                                                            value: deviceSettings.output_04.wave_duration,
                                                            required: false,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Popup>
                    <Popup
                        openPopup={openCameraPopup}
                        setOpenPopup={setOpenCameraPopup}
                        title='Câmeras do dispositivo'
                    >
                        <Grid xs={12} container justify='center'>
                            {(deviceCameras.length > 0) ? deviceCameras.map((current, index) => (
                                <Grid key={index} item style={{ margin: '0.6%' }}>
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        endIcon={<CamIcon />}
                                        fullWidth={true}
                                        href={`/camera/${current.id}`}
                                        target='_blank'
                                    >
                                        {current.name}
                                    </Button>
                                </Grid>
                            )) : (
                                <Grid container justify='center'>
                                    <Typography>
                                        Nenhuma câmera cadastrada
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Popup>
                    <Popup
                        openPopup={openDeletePopup}
                        setOpenPopup={setOpenDeletePopup}

                    >
                        <Grid container xs={12} style={{
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0 3% 2% 3%',

                        }}>
                            <Typography variant='h5'
                                style={{
                                    fontWeight: 'bold',
                                    color: '#9e9e9e'
                                }}
                            >
                                Deseja mesmo deletar esse dispositivo ?
                            </Typography>
                            <Grid item style={{
                                marginTop: '50px'
                            }}>
                                <Button
                                    disabled={isDeleting}
                                    variant="contained"
                                    onClick={() => deleteDevice()}
                                    color="secondary"
                                    endIcon={<DeleteIcon />}
                                >
                                    {isDeleting ? 'Deletando...' : 'Sim, delete o dispositivo'}
                                </Button>
                            </Grid>

                        </Grid>
                    </Popup>
                    <PopUpDeviceCapacity
                        capacity={capacity}
                        open={openCapacityPopup}
                        setOpen={setOpenCapacityPopup}
                        loading={loadingCapacity}
                    />
                    <Popup
                        openPopup={openDoorPopup}
                        setOpenPopup={setOpenDoorPopup}
                        title="Controle de Dispositivo"
                        width='auto'
                        onClose={resetControlPopup}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        fontSize: '1.3rem',
                                    }}
                                >
                                    Dispositivo:
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        fontSize: '1.3rem',
                                    }}
                                >
                                    {selectedDevice?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <textarea
                                    placeholder="Detalhes sobre a liberação"
                                    multiline
                                    minRows={20}
                                    style={{
                                        width: '100%',
                                        border: '1px solid #c6c6c6',
                                        borderRadius: '4px',
                                        minHeight: '200px',
                                        resize: 'none',
                                        padding: '10px',
                                        fontSize: '1.2rem',
                                        color: grey[600],
                                        fontFamily: 'Arial, sans-serif'
                                    }}
                                    value={doorControlNotes}
                                    onChange={({ target }) => setDoorControlNotes(target.value)}
                                />
                            </Grid>
                            <PermissionWrapper route="/dispositivo/verificar_porta">
                                <Grid item xs={12} md={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth={true}
                                        onClick={handlePingDoor}
                                        disabled={submitting.onGoing}
                                    >
                                        {submitting.onGoing && submitting.type === 'ping' ? 'Emitindo alerta...' : 'Emitir alerta sônoro'}
                                    </Button>
                                </Grid>
                            </PermissionWrapper>
                            <PermissionWrapper route="/dispositivo/abrir_porta">
                                <Grid item xs={12} md={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth={true}
                                        onClick={handleOpenDoor}
                                        disabled={submitting.onGoing}
                                    >
                                        {
                                            submitting.onGoing && submitting.type === 'open' ? (
                                                `Liberando ${selectedDevice?.dhi_type === 'entry' ? 'entrada...' :
                                                    selectedDevice?.dhi_type === 'exit' ? 'saída...' :
                                                        'dispositivo...'
                                                }`
                                            ) : (
                                                `Liberar ${selectedDevice?.dhi_type === 'entry' ? 'entrada' :
                                                    selectedDevice?.dhi_type === 'exit' ? 'saída' :
                                                        'dispositivo'
                                                }`
                                            )
                                        }
                                    </Button>
                                </Grid>
                            </PermissionWrapper>
                            <PermissionWrapper route="/camera/:id">
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth={true}
                                        disabled={submitting.onGoing}
                                        onClick={() => setOpenRTSP(true)}
                                    >
                                        Visualizar video
                                    </Button>
                                </Grid>
                            </PermissionWrapper>
                        </Grid>
                    </Popup>
                    <FullScreenDialog
                        open={openRTSP}
                        title={`${selectedDevice?.name} - ${loadingRSTP ? 'CARREGANDO' : (rtspRunning ? 'CONECTADO' : 'DESCONECTADO')}`}
                        onClose={handleCloseRSTP}
                    >
                        <div style={{
                            backgroundmaxHeight: '100%',
                            height: '100%',
                            maxWidth: '100%',
                            margin: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <canvas ref={rtspCanvas} style={{ height: '100%', width: '40%', borderRadius: '8px' }}></canvas>
                        </div>
                    </FullScreenDialog>
                    <Popup
                        openPopup={openClearDeviceData}
                        setOpenPopup={setOpenClearDeviceData}
                        width='35%'
                        title="Confirmação de Limpeza do Dispositivo"
                        onClose={handleCloseRemoveDeviceData}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography
                                    variant='h6'
                                    style={{
                                        color: grey[700],
                                        fontWeight: 700,
                                    }}
                                >
                                    Atenção: Uma vez que a limpeza dos dados do dispositivo é executada, não é possível voltar atrás.
                                    <br />
                                    Para confirmar essa ação, insira as credenciais do dispositivo abaixo:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    label="Usuário"
                                    textFieldProps={{
                                        fullWidth: true,
                                        value: deviceUsername,
                                        onChange: ({ target: { value } }) => setDeviceUsername(value),
                                        required: false,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    label="Senha"
                                    textFieldProps={{
                                        type: 'password',
                                        fullWidth: true,
                                        value: devicePassword,
                                        onChange: ({ target: { value } }) => setDevicePassword(value),
                                        required: false,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth={true}
                                    onClick={removeDeviceData}
                                    disabled={clearingDevice}
                                >
                                    {
                                        clearingDevice
                                            ? 'LIMPANDO...'
                                            : 'LIMPAR DADOS'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Popup>
                </Grid>
                <PermissionWrapper route="/dispositivo/criar">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href="/dispositivo/criar"
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}

            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    online: {
        height: '15px',
        width: '15px',
        borderRadius: '9999px',
        margin: '0 auto',
        background: 'hsl(120, 100%, 50%)',
    },
    offline: {
        height: '15px',
        width: '15px',
        margin: '0 auto',
        borderRadius: '9999px',
        background: 'hsl(0, 100%, 50%)',
    }
}));
