import React, { useState, useEffect, useRef, useContext } from "react";
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
    Tabs,
    Tab,
    Box,
    TextField,
} from "@material-ui/core";
import Backdrop from '@mui/material/Backdrop';

import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

import CircularProgress from '@mui/material/CircularProgress';

import SideMenu from "../../components/SideMenu";
import Timeline from "../../components/Timeline";
import Alert from '../../components/Alert';
import useScript from "../../hooks/useExternalScripts";
import api from "../../services/api";
import VideoJS from "../../components/VideoComponent";
import Autocomplete from '@material-ui/lab/Autocomplete';
import pt from "date-fns/locale/pt-BR";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDebounce } from "../../hooks/useDebounce";
import { PermissionsContext } from "../../providers/Permissions";

export default function TimelineViewer() {
    const classes = useStyles();
    registerLocale("pt-br", pt);

    const [search, setSearch] = useState("");
    const [options, setOptions] = useState([{}]);
    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [optionsSelected, setOptionsSelected] = useState([]);
    const [filter, setFilter] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    const [urlVideoSelected, setUrlVideoSelected] = useState(null);
    const [refreshing, setRefreshing] = useState(false);
    const [handleId, setHandleId] = useState("");
    const [handleDownloadId, setHandleDownloadId] = useState("");
    const [initialDate, setInitialDate] = useState(new Date());
    const [finallyDate, setFinallyDate] = useState(null);
    const [videoJsOptions, setVideoJsOptions] = useState({
        autoplay: true,
        controls: true,
        responsive: true,
        editable: true,
        fluid: true,
        sources: [
            {
                src: urlVideoSelected,
                type: "application/x-mpegURL",
            },
        ],
    });

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [loadingVideo, setLoadingVideo] = useState(false);

    const valueDebounced = useDebounce(search, 1000);

    useEffect(() => {
        const handleSearch = async () => {
            try {

                const { data: options } = await api.get('/recording/options?filter=' + valueDebounced);
                setOptions(options);

            } catch (err) {
                console.log(err);

            }

        }
        handleSearch();
    }, [valueDebounced]);

    const handleDownload = async (id) => {
        try {
            const response = await api.get(`/recording/recover/${id}`);
            console.log(response)
            window.location.href = response.data.url;
        } catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error ?? "Não foi possível baixar o video",
            });
        }
    };

    useEffect(() => {
        if (!filter) return;
        setVideoJsOptions({
            autoplay: true,
            controls: true,
            responsive: true,
            editable: true,
            fluid: true,
            sources: [
                {
                    src: urlVideoSelected,
                    type: "application/x-mpegURL",
                },
            ],
        });
    }, [urlVideoSelected, refreshing]);

    useEffect(() => {
        if (!filter) return;
        fetchItems();
    }, [refreshing]);


    const playerRef = React.useRef(null);

    const fetchItems = async () => {
        var modifiedInitialDate = new Date(initialDate);
        var modifiedFinallyDate = new Date(finallyDate);

        modifiedInitialDate.setHours(modifiedInitialDate.getHours() - 3);
        modifiedFinallyDate.setHours(modifiedFinallyDate.getHours() - 3);

        const response = await api.get(`/recording/list?initial_date=${modifiedInitialDate.toISOString()}&finally_date=${modifiedFinallyDate.toISOString()}&filter=${optionsSelected.map((option) => option.value)}`);
        if (!response.data[0]) {
            alert("Não há gravações nesse período");
            setFilter(false);
            return;
        }
        console.log(response.data)
        setFilteredItems(response.data);
        setOptionsSelected([]);
        setFilter(true);


    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on("waiting", () => {
            console.log("player is waiting");
        });

        player.on("dispose", () => {
            console.log("player will dispose");
        });
    };

    return (
        <div className={classes.root}>
            <SideMenu />

            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Timeline de Gravação
                        </Typography>
                    </Grid>
                    <Grid
                        component={Paper}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            minHeight: "85vh",
                            padding: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        elevation={16}
                        item
                        spacing={5}
                        xs={12}
                    >
                        {!filter ? (
                            <>
                                <Autocomplete
                                    multiple
                                    style={{
                                        maxWidth: "600px",
                                        minWidth: "600px",
                                    }}
                                    id="tags-standard"
                                    options={options}
                                    onChange={(event, value) => {
                                        setOptionsSelected(value)
                                    }}
                                    getOptionLabel={(option) => option?.label ?? ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            value={search}
                                            variant="standard"
                                            label="Câmeras"
                                            placeholder=""
                                            onChange={(event) => {
                                                setSearch(event.target.value);
                                            }}
                                        />
                                    )}
                                />

                                <Box
                                    style={{
                                        width: "60%",
                                        display: "flex",
                                        flexDirectio: "row",
                                        justifyContent: "space-around",
                                        padding: "10px",
                                    }}
                                >
                                    <DatePicker
                                        selected={initialDate}
                                        onFocus={() => {
                                            setFinallyDate(null);
                                        }}
                                        customInput={
                                            <TextField
                                                style={{
                                                    borderRadius: 2,
                                                    color: "#000",
                                                    width: "100%",
                                                }}
                                            />
                                        }
                                        onChange={(date) => {
                                            if (finallyDate === null) {
                                                setFinallyDate(date);
                                            }
                                            setInitialDate(date);
                                        }}
                                        showTimeSelect
                                        locale={"pt-br"}
                                        dateFormat="d MMMM, yyyy h:mm aa"
                                    />

                                    <DatePicker
                                        selected={finallyDate}
                                        disabled={!finallyDate}
                                        minDate={initialDate}
                                        maxDate={
                                            new Date(
                                                initialDate.getFullYear(),
                                                initialDate.getMonth(),
                                                initialDate.getDate() + 6
                                            )
                                        }
                                        customInput={
                                            <TextField
                                                style={{
                                                    borderRadius: 2,
                                                    color: "#000",
                                                    width: "100%",
                                                }}
                                            />
                                        }
                                        onChange={(date) => {
                                            setFinallyDate(date);
                                        }}
                                        locale={"pt-br"}
                                        showTimeSelect
                                        dateFormat="d MMMM, yyyy h:mm aa"
                                    />
                                </Box>


                                <Button
                                    disabled={!finallyDate}
                                    onClick={() => {
                                        fetchItems();
                                    }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Pesquisar
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    fullWidth
                                    style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        marginBottom: "20px",
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                        setFilteredItems([]);
                                        setUrlVideoSelected(null);
                                        setInitialDate(new Date());
                                        setFinallyDate(null);
                                        setVideoJsOptions({
                                            autoplay: true,
                                            controls: true,
                                            responsive: true,
                                            editable: true,
                                            fluid: true,
                                            sources: [
                                                {
                                                    src: null,
                                                    type: "application/x-mpegURL",
                                                },
                                            ],
                                        });
                                        setFilter(false);
                                    }}
                                >
                                    Voltar
                                </Button>

                                <VideoJS
                                    options={videoJsOptions}
                                    onReady={handlePlayerReady}
                                />
                                <Box>
                                    <PermissionOneOfWrapper routes={['/timeline_recorder/video/:id']} >
                                        <PermissionWrapper
                                            route={'/timeline_recorder/video/:id'}
                                        >
                                            <Button
                                                disabled={!handleDownloadId}
                                                variant="contained"
                                                color="primary"
                                                style={{ marginRight: "10px" }}
                                                onClick={() => {
                                                    handleDownload(handleDownloadId)
                                                }}
                                            >
                                                DOWNLOAD
                                            </Button>
                                        </PermissionWrapper>
                                    </PermissionOneOfWrapper>
                                </Box>

                                {filteredItems[0] && (
                                    <Timeline
                                        initialDate={initialDate}
                                        finallyDate={finallyDate}
                                        // pullZone={pullZone}
                                        setUrlVideoSelected={
                                            setUrlVideoSelected
                                        }
                                        urlVideoSelected={urlVideoSelected}
                                        setHandled={setHandleDownloadId}
                                        setHandledDelete={setHandleId}
                                        filter={filter}
                                        filteredItems={filteredItems}
                                        setMessage={setMessage}
                                        setLoadingVideo={setLoadingVideo}
                                    />
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </main>
            <Backdrop
                open={loadingVideo}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Typography
                    variant='h6'
                    style={{
                        textAlign: 'center',
                        userSelect: 'none',
                    }}
                >
                    <CircularProgress color='inherit' />
                    <br />
                    Solicitando video...
                </Typography>
            </Backdrop>
            {!!message.open && (
                <Alert
                    open={message.open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    severity={message.variant}
                    onClose={closeMessage}
                >
                    {message.text}
                </Alert>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: "100%",
    },
    tableHead: {
        textAlign: "center",
        textTransform: "uppercase",
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: "center",
    },
    actionButton: {
        margin: theme.spacing(0, 0.5),
        padding: theme.spacing(0.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: "fixed",
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    online: {
        height: "15px",
        width: "15px",
        borderRadius: "9999px",
        margin: "0 auto",
        background: "hsl(120, 100%, 50%)",
    },
    offline: {
        height: "15px",
        width: "15px",
        margin: "0 auto",
        borderRadius: "9999px",
        background: "hsl(0, 100%, 50%)",
    },
}));
