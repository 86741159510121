import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useDevices, useCameras } from '../../hooks';
import { useParams, useHistory } from 'react-router-dom';

import {
    Toolbar,
    Paper,
    Button,
    Grid,
    Typography,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    ButtonGroup,
} from '@material-ui/core';

import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';

import { Formik } from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Popup from '../../components/Popup';
import Select from '../../components/Select';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';

const reduceDevices = (state, action) => {
    let newValues = [];
    switch (action.type) {
        case 'single':
            if (action.checked && !state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                newValues.push(action.id);
            } else if (!action.checked && state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                let index = state[action.deviceArray].indexOf(action.id);
                newValues.splice(index, 1);
            } else {
                newValues = state[action.deviceArray];
            }
            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'all':
            if (action.checked) {
                newValues = state[action.deviceArray];
                for (let current of action.allDevices) {
                    if (!newValues.includes(current.id)) {
                        newValues.push(current.id);
                    }
                }
            } else {
                const allIds = action.allDevices.map(el => el.id)
                newValues = state[action.deviceArray].filter(el => !allIds.includes(el));
            }

            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'load':
            newValues = action.loading;
            return {
                ...state,
                [action.deviceArray]: newValues,
            };
        case 'reset':
            return { device_id: [], camera_id: [] };
        default: return state;
    }
};

export default function DeviceGroupModify() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    let [deviceGroup, setDeviceGroup] = useState({
        name: '',
        map_id: null,
        description: '',
        allow_reentry: true,
        reentry_time: 0,
        remote_validation: false,
    });

    const { id } = useParams();
    const history = useHistory();

    const [openColateralDelete, setOpenColateralDelete] = useState(false);
    const [originalDevices, setOriginalDevices] = useState([]);
    const [originalCameras, setOriginalCameras] = useState([]);
    const [storedValues, setStoredValues] = useState({});
    const [storedFormik, setStoredFormik] = useState({});

    const interceptSubmit = async (values, formik) => {
        const sameDevices = originalDevices.every((value) => deviceIds.device_id?.includes(value));
        const sameCameras = originalCameras.every((value) => deviceIds.camera_id?.includes(value));

        if (deviceIds.device_id.length === 0 && deviceIds.camera_id.length === 0) {
            setMessage({
                open: true,
                variant: 'error',
                text: "Selecione ao menos um dispositivo ou câmera",
            });

            return;
        }

        if (!sameDevices || !sameCameras) {
            setStoredValues(values);
            setStoredFormik(formik);
            setOpenColateralDelete(true);
        } else {
            await saveGroup(values, formik);
        }
    }

    const handleSubmit = async (deleting) => {
        setOpenColateralDelete(false);
        await saveGroup(storedValues, storedFormik, deleting);
    }

    const [devicesSearch, setDevicesSearch] = useState('');
    const [devicesPage, setDevicesPage] = useState(0);
    const [devicesLimit, setDevicesLimit] = useState(10);
    const { devices, total: totalDevices } = useDevices({ search: devicesSearch, page: devicesPage, limit: devicesLimit, url: "list_dhi" });

    const [camerasSearch, setCamerasSearch] = useState('');
    const [camerasPage, setCamerasPage] = useState(0);
    const [camerasLimit, setCamerasLimit] = useState(10);
    const { cameras, total: totalCameras } = useCameras({ search: camerasSearch, page: camerasPage, limit: camerasLimit });

    const [openDevicesPopup, setOpenDevicesPopup] = useState(false);
    const [openCamerasPopup, setOpenCamerasPopup] = useState(false);

    const [deviceIds, dispatchDeviceIds] = useReducer(reduceDevices, { device_id: [], camera_id: [] });

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = () => {
        setOpenDeletePopup(true);
    };

    const deleteGroup = async (colateralRemove) => {
        setIsDeleting(true);
        try {
            const result = await api.delete(`/device_group/delete/${id}`, {
                params: {
                    colateral: colateralRemove,
                }
            });

            const { status } = result;

            if (status === 200) {
                setOpenDeletePopup(false);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Grupo de dispositivos "${deviceGroup.name}" deletado com sucesso!`,
                });
                setTimeout(() => history.goBack(), 1500);
            }
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
        setIsDeleting(false);
    };

    useEffect(() => {
        const loadDeviceGroup = async () => {
            try {
                const result = await api.get(`/device_group/view/${id}`);

                const { DeviceGroup, cameras: fetchedCameras, devices: fetchedDevices } = result.data;

                setDeviceGroup(DeviceGroup);
                setOriginalDevices(Object.assign([], fetchedDevices));
                setOriginalCameras(Object.assign([], fetchedCameras));
                dispatchDeviceIds({ type: 'load', deviceArray: 'camera_id', loading: fetchedCameras });
                dispatchDeviceIds({ type: 'load', deviceArray: 'device_id', loading: fetchedDevices });
            } catch (err) {
                console.log(err)
                setMessage({
                    open: true,
                    variant: "error",
                    text: err.error,
                });
                setTimeout(() => history.goBack(), 1500);
            }
        };
        loadDeviceGroup();
    }, [id, history]);

    const saveGroup = async (values, formik, deleting) => {
        formik.setSubmitting(true);
        try {
            const data = {
                ...values,
                cameras: deviceIds.camera_id,
                devices: deviceIds.device_id,
                colateral_delete: deleting,
            };

            const result = await api.post(`/device_group/modify/${id}`, data);

            const { DeviceGroup: editedGroup } = result.data;

            setMessage({
                open: true,
                variant: 'success',
                text: `O grupo ${editedGroup.name} foi editado com sucesso`,
            });
            setOriginalDevices(Object.assign([], deviceIds.device_id));
            setOriginalCameras(Object.assign([], deviceIds.camera_id));
            setStoredValues({});
            setStoredFormik({});
        }
        catch (err) {
            console.log(err)
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
        formik.setSubmitting(false);
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'device':
                setDevicesPage(newPage);
                break;
            case 'camera':
                setCamerasPage(newPage);
                break
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        switch (quantityOf) {
            case 'device':
                setDevicesLimit(parseInt(target.value, 10));
                setDevicesPage(0);
                break;
            case 'camera':
                setCamerasLimit(parseInt(target.value, 10));
                setCamerasPage(0);
                break;
            default:
                console.log('Quantidade inválida');
        }
    };

    const checkSelection = (deviceArray, fetchedArray) => {
        if (!deviceIds[deviceArray].length) return false;

        let checkingArray = fetchedArray?.map(el => el.id);
        let checked = checkingArray?.every(el => {
            return deviceIds[deviceArray].includes(el)
        });
        return checked;
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid container xs={12}>
                        <Typography xs={6} variant="h5" className={classes.title}>
                            Editar Grupo de Dispositivos
                        </Typography>
                    </Grid>
                    <Formik
                        enableReinitialize={true}
                        initialValues={deviceGroup}
                        onSubmit={interceptSubmit}
                    >
                        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="device-group-modify" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container className={classes.formSection}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                        Informações básicas
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Input
                                                                label="Nome"
                                                                id="name"
                                                                textFieldProps={{
                                                                    name: "name",
                                                                    value: values.name,
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                    required: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Input
                                                                label="Descrição"
                                                                id="description"
                                                                textFieldProps={{
                                                                    name: "description",
                                                                    value: values.description,
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                    required: false,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                        Dispositivos e Câmeras
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container justify="space-around" spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenDevicesPopup(true)}
                                                            >
                                                                Adcionar dispositivos {`(${deviceIds.device_id.length})`}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenCamerasPopup(true)}
                                                            >
                                                                Adcionar câmeras {`(${deviceIds.camera_id.length})`}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {
                                                deviceIds.device_id.length > 0 && (
                                                    <Grid container className={classes.formSection} spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                                Configurações para Leitores Faciais
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={6} item>
                                                            <Select
                                                                label="Permitir entradas repetidas (PASSBACK)"
                                                                id="allow-re-entry"
                                                                tip={
                                                                    <>
                                                                        <Typography variant='subtitle1'>
                                                                            Ao habilitar essa função, uma pessoa poderá por exemplo, passar pelo dispositivo e
                                                                            depois usar seu rosto/cartão para habilitar a passagem de outra pessoa
                                                                        </Typography>
                                                                        <Typography variant="subtitle2"><i>OBS: Apenas para o Leitor Facial (DHI).</i></Typography>
                                                                    </>
                                                                }
                                                                options={[
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    name: "allow_reentry",
                                                                    onChange: (e) => {
                                                                        setFieldValue('reentry_time', 0);
                                                                        handleChange(e);
                                                                    },
                                                                    fullWidth: true,
                                                                    value: values.allow_reentry,
                                                                }}
                                                            />
                                                        </Grid>
                                                        {
                                                            !values.allow_reentry && (
                                                                <Grid xs={6} item>
                                                                    <Input
                                                                        label="Tempo entre entradas repetidas (segundos)"
                                                                        id="re-entry-time"
                                                                        textFieldProps={{
                                                                            name: "reentry_time",
                                                                            type: "number",
                                                                            onChange: handleChange,
                                                                            fullWidth: true,
                                                                            value: values.reentry_time,
                                                                        }}
                                                                        numberProps={{
                                                                            min: 0,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            )
                                                        }
                                                        <Grid xs={6} item>
                                                            <Select
                                                                label="Validação remota (DHI)"
                                                                id="remote-validation"
                                                                options={[
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    name: "remote_validation",
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                    value: values.remote_validation,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            <Grid item xs={12} container justify="space-evenly">
                                                <PermissionWrapper route="/grupos_de_dispositivos/deletar/:id">
                                                    <Button
                                                        disable={isDeleting || isSubmitting}
                                                        type="button"
                                                        variant="contained"
                                                        color="secondary"
                                                        endIcon={<DeleteIcon />}
                                                        onClick={handleDelete}
                                                    >
                                                        {isDeleting ? 'Apagando...' : 'Apagar grupo'}
                                                    </Button>
                                                </PermissionWrapper>
                                                <Button
                                                    disabled={isSubmitting || isDeleting}
                                                    type="submit"
                                                    variant="contained"
                                                    form="device-group-modify"
                                                    color="primary"
                                                    endIcon={<SaveIcon />}

                                                >
                                                    {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                </Button>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
            <Popup
                openPopup={openDevicesPopup}
                setOpenPopup={setOpenDevicesPopup}
                title="Dispositivos"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setDevicesSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="devices list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Descrição</TableCell>
                                        <TableCell className={classes.tableHead}>Local</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllDevices"
                                                color="primary"
                                                // checked={selectedDevices.length === devices.length}
                                                checked={checkSelection('device_id', devices)}
                                                onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                    type: 'all',
                                                    deviceArray: 'device_id',
                                                    allDevices: devices,
                                                    checked,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!devices && devices.map(device => (
                                        <TableRow key={device.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {device.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.description}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.place_devices?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addDevice"
                                                    color="primary"
                                                    checked={deviceIds.device_id.includes(device.id)}
                                                    onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                        type: 'single',
                                                        id: device.id,
                                                        deviceArray: 'device_id',
                                                        checked,
                                                    })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={totalDevices}
                            rowsPerPage={devicesLimit}
                            page={devicesPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'device')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'device')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openCamerasPopup}
                setOpenPopup={setOpenCamerasPopup}
                title="Câmeras"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setCamerasSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="camera list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Local</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivo</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllCameras"
                                                color="primary"
                                                // checked={selectedCameras.length === cameras.length}
                                                checked={checkSelection('camera_id', cameras)}
                                                onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                    type: 'all',
                                                    deviceArray: 'camera_id',
                                                    allDevices: cameras,
                                                    checked,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!cameras && cameras.map(camera => (
                                        <TableRow key={camera.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {camera.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {(camera.type === 'entry') ? 'Entrada' : (camera.type === 'internal') ? 'Interna' : (camera.type === 'exit') ? 'Saída' : 'Desconhecido'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.place_cameras?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.device?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addCamera"
                                                    color="primary"
                                                    checked={deviceIds.camera_id.includes(camera.id)}
                                                    onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                        type: 'single',
                                                        id: camera.id,
                                                        deviceArray: 'camera_id',
                                                        checked,
                                                    })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={totalCameras}
                            rowsPerPage={camerasLimit}
                            page={camerasPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'camera')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'camera')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openColateralDelete}
                setOpenPopup={setOpenColateralDelete}
                title="Aviso"
                width="40%"
            >
                <Grid container alignItems="center" justifyContent="center" direction="column">
                    <Typography
                        variant="h6"
                    >
                        Deseja apagar as pessoas dos dispositivos/câmeras que removeu?
                    </Typography>
                    <ButtonGroup
                        style={{
                            width: "50%",
                            marginTop: 4,
                        }}
                        fullWidth
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleSubmit(false)}
                        >
                            Não
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmit(true)}
                        >
                            Sim
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Popup>
            <Popup
                openPopup={openDeletePopup}
                setOpenPopup={setOpenDeletePopup}
                title="Atenção"
            >
                <Grid container xs={12} style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 3% 2% 3%',

                }}>
                    <Typography variant='h5'
                        style={{
                            fontWeight: 'bold',
                            color: '#696969',
                            textAlign: 'justify',
                            textIndent: '20px',
                        }}

                    >
                        Deseja também remover os registros de pessoas nos respectivos dispositivos
                        que foram vinculados através desse grupo? Essa operação pode demorar um pouco
                        a ser completada e não pode ser cancelada.
                        <br /><br />
                        <i>Para não realizar nenhuma das operações, apenas feche essa janela.</i>
                    </Typography>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} md={9} style={{
                            marginTop: '50px'
                        }}>
                            <Button
                                disabled={isDeleting}
                                variant="contained"
                                onClick={() => deleteGroup(true)}
                                color="primary"
                                id="confirm"
                                fullWidth
                            >
                                {isDeleting ? 'Deletando...' : 'Sim, remova o grupo e os registros nos dispositivos'}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={9} style={{
                            marginTop: '10px'
                        }}>
                            <Button
                                disabled={isDeleting}
                                variant="contained"
                                onClick={() => deleteGroup(false)}
                                color="secondary"
                                id="confirm"
                                fullWidth
                            >
                                {isDeleting ? 'Deletando...' : 'Não, remova apenas o grupo'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Popup>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
        fontSize: '1.5rem',
    },
}));
