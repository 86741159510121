import React, { useReducer, useEffect } from "react";
import {
    Button,
    Grid,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import Input from "../../../components/Input";

const initialValues = [""];

const reduceMacAddresses = (state, action) => {
    switch (action.type) {
        case 'change':
            const toChangeState = [...state];
            toChangeState[action.index] = action.value

            return toChangeState;
        case 'append':
            const toAppendState = [...(state ?? [])];
            toAppendState.push("");

            return toAppendState;
        case 'remove':
            if (state.length === 1)
                return state;
            const toSpliceState = [...state];
            toSpliceState.splice(action.index, 1);

            return toSpliceState;
        default: return state;
    }
};

export default function MacAddresses({
    value = initialValues,
    onChange = () => {},
}) {
    const [macAddresses, dispatchMacAddresses] = useReducer(reduceMacAddresses, value);

    const appendNewMac = () => dispatchMacAddresses({ type: "append" });

    if (!macAddresses?.length)
        appendNewMac();

    useEffect(() => {
        const timeout = setTimeout(() => {
            console.log("changing mac addresses to:", macAddresses);
            onChange(macAddresses);
        }, [500]);

        return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [macAddresses]);

    return (
        <Grid container justify="center" spacing={2}>
            {
                (macAddresses ?? []).map((macAddress, index) => (
                    <Grid item xs={12} key={index}>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Input
                                    textFieldProps={{
                                        onChange: ({ target: { value } }) => dispatchMacAddresses({
                                            type: "change",
                                            index: index,
                                            value: value,
                                        }),
                                        value: macAddress,
                                        fullWidth: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth={true}
                                    disabled={(macAddresses.length === 1)}
                                    onClick={() => dispatchMacAddresses({
                                        type: "remove",
                                        index: index,
                                    })}
                                >
                                    <CloseIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            }
            <Grid item xs={4}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={appendNewMac}
                >
                    ADCIONAR NOVO
                </Button>
            </Grid>
        </Grid>
    );
}