import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import fileDownloader from 'js-file-download';
import { useLocation } from 'react-router-dom';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    Paper,
    TablePagination,
    Grid,
    Typography,
    Button,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReloadIcon from '@material-ui/icons/Loop';
import MapPopup from '../../components/Popup';
import FullScreenDialog from '../../components/FullScreenDialog';
import Alert from '../../components/Alert';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Select from '../../components/Select';
import Canvas from '../../components/Canvas';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import CachedIcon from '@material-ui/icons/Cached';
import PeopleIcon from '@material-ui/icons/People';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import ReportIcon from '@mui/icons-material/Report';
import { makeStyles } from '@material-ui/core/styles';
import { grey, green, red } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Popup from '../../components/Popup';
import DateTimePicker from '../../components/DateTimePicker';
import InputSearch from '../../components/InputSearch';
import ExportModal from '../../components/ExportModal';
import Autocomplete from '../../components/Autocomplete';
import Input from '../../components/Input';
import { useTableColumnsToShow, ColumnsManagementModal } from './components/UserListColumns';

import api from '../../services/api';
import BluePin from '../../assets/img/pin-blue.svg';
import CyanPin from '../../assets/img/pin-cyan.svg';
import GreenPin from '../../assets/img/pin-green.svg';
import OrangePin from '../../assets/img/pin-orange.svg';
import RedPin from '../../assets/img/pin-red.svg';

import mask from '../../utils/mask';
import date from '../../utils/date';

import Loader from 'react-loader-spinner';

import config from '../../configs/config';
import ResendValidation from '../../components/ResendValidation';
import { useDebounce } from '../../hooks/useDebounce';
import { PermissionsContext } from '../../providers/Permissions';

const { host, port } = config;

const filterInitialValues = {
    sex: {
        active: false,
        value: null,
    },
    person_type: {
        active: false,
        value: {},
    },
    org: {
        active: false,
        value: {},
    },
    created_at: {
        active: false,
        value: {
            start: null,
            end: null,
        },
    },
    updated_at: {
        active: false,
        value: {
            start: null,
            end: null,
        },
    },
    face_image: {
        active: false,
        value: null,
    },
}

const reduceFilters = (state, action) => {
    switch (action.type) {
        case 'activate':
            return {
                ...state,
                [action.filter]: {
                    ...state[action.filter],
                    active: action.value,
                }
            };
        case 'change':
            let newValues = state;

            if (!action.datatype || action.datatype === 'normal') {
                newValues = {
                    ...state,
                    [action.filter]: {
                        ...state[action.filter],
                        value: action.value,
                    }
                };
            } else if (action.datatype === 'object') { // only for 1 layered objects
                newValues = {
                    ...state,
                    [action.filter]: {
                        ...state[action.filter],
                        value: {
                            ...state[action.filter].value,
                            [action.key]: action.value
                        },
                    }
                };
            }
            return newValues;
        case 'reset':
            return filterInitialValues;
        default: return state;
    }
};

const CustomFilterItem = ({
    label,
    index,
    onActivate,
    onChooseInput,
    onChooseValue,
    onRemove,
    inputs,
    values,
    allowedToRemove,
}) => {

    const classes = useStyles();
    const [valueSearch, setValueSearch] = useState(values?.value ?? "");

    const handleFilter = (index, value) => {
        onChooseInput(index, value);
    }

    const handleValue = (index, value) => {
        onChooseValue(index, value);
    }

    const handleSearchValue = ({ target: { value } }) => setValueSearch(value);

    const handleRemove = () => onRemove(index);

    useEffect(() => {
        const handler = setTimeout(() => {
            handleValue(index, valueSearch);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueSearch]);

    return (
        <Grid item xs={12}>
            <Grid container justify='space-between'>
                <Grid item xs={4}>
                    <FormControlLabel
                        label={label}
                        className={classes.formControl}
                        control={
                            <Checkbox
                                checked={values?.active}
                                color='primary'
                                onChange={({ target: { checked } }) => onActivate(index, checked)}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={!!values?.filter?.id ? 5 : 11}>
                            <Autocomplete
                                options={inputs}
                                getOptionLabel={({ name }) => name || ''}
                                onChange={(_, value) => handleFilter(index, value)}
                                getOptionSelected={(option, value) => (option.id === value.id)}
                                value={values?.filter}
                                disabled={!values?.active}
                                required={true}
                                fullWidth />
                        </Grid>
                        {
                            (!!values?.filter?.id) && (values.filter.type === "select" ? (
                                <Grid item xs={5}>
                                    <Autocomplete
                                        options={(values.filter?.options ?? []).map((option) => (
                                            { id: option, name: option }
                                        ))}
                                        getOptionLabel={({ name }) => name || ''}
                                        onChange={(_, value) => onChooseValue(index, value)}
                                        getOptionSelected={(option, value) => (option.id === value.id)}
                                        value={values?.value}
                                        required={true}
                                        disabled={!values?.active}
                                        fullWidth />
                                </Grid>
                            ) : (
                                <Grid item xs={5}>
                                    <Input
                                        textFieldProps={{
                                            onChange: handleSearchValue,
                                            value: valueSearch,
                                            disabled: !values?.active,
                                            fullWidth: true,
                                            required: true
                                        }}
                                    />
                                </Grid>
                            ))
                        }
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={true}
                                onClick={handleRemove}
                                disabled={!allowedToRemove}
                            >
                                <CloseIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const StatusTip = ({ success, type, content }) => {

    function getTipIcon() {
        switch (type) {
            case 'check':
                return <CheckIcon style={{ color: getTipColor() }} />;
            case 'error':
                return <CloseIcon style={{ color: getTipColor() }} />;
            case 'image':
                return <AccountCircleIcon style={{ color: getTipColor() }} />;
            default:
                throw new Error("Invalid tip type");
        }
    }

    function getTipColor() {
        if (success) {
            return green[700];
        } else {
            return red[700];
        }
    }

    return (
        <Tooltip title={
            <Typography variant='subtitle1' style={{
                fontSize: '1rem',
            }}>
                {content}
            </Typography>
        } arrow>
            <IconButton style={{ padding: 0 }}>
                {getTipIcon()}
            </IconButton>
        </Tooltip>
    );
};

const Pin = ({ pinAccesses, maxAccesses, style, name }) => {


    const [pinImage, setPinImage] = useState(null);
    const [pinColor, setPinColor] = useState('');

    useEffect(() => {
        try {
            const pin = parseInt(pinAccesses);
            // const total = parseInt(maxAccesses);
            const total = maxAccesses;

            // let blue = Math.round(total * 0.2);
            // let cyan = Math.round(total * 0.4);
            // let green = Math.round(total * 0.6);
            // let yellow = Math.round(total * 0.8);
            // let red = total;

            let blue = 10 * total;
            let cyan = 20 * total;
            let green = 30 * total;
            let yellow = 40 * total;
            //let red = 50 * total;

            let color = null;
            let image = null

            // console.log({ pin, total })
            // console.log({ blue, cyan, green, yellow, red });


            if (pin > yellow) {
                color = 1;
            } else if (pin > green && pin <= yellow) {
                color = 0.75;
            } else if (pin > cyan && pin <= green) {
                color = 0.5;
            } else if (pin > blue && pin <= cyan) {
                color = 0.25;
            } else if (pin > 0 && pin <= blue) {
                color = 0;
            }

            switch (color) {
                case 0:
                    image = BluePin;
                    setPinColor('blue');
                    break;
                case 0.25:
                    image = CyanPin;
                    setPinColor('cyan');
                    break;
                case 0.5:
                    image = GreenPin;
                    setPinColor('green');
                    break;
                case 0.75:
                    image = OrangePin;
                    setPinColor('yellow');
                    break;
                case 1:
                    image = RedPin;
                    setPinColor('red');
                    break;
                default:
                    console.log('error')
            }
            // console.log({ image })

            setPinImage(image);
        } catch (err) {
            console.log(err)
        }
    }, [pinAccesses, maxAccesses]);

    return (
        <Tooltip
            title={
                <div>
                    <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
                        Câmera: &nbsp;
                        <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
                            {name}:
                        </Typography>
                    </Typography>
                    <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
                        Total de Acessos:
                        <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
                            {pinAccesses}
                        </Typography>
                    </Typography>
                </div>
            }
            arrow
        >
            <img src={pinImage} alt={`${pinColor} location pin`} style={style} />
        </Tooltip>
    )
}

const FilterItem = ({ label, filter, children, filters, dispatchFilters }) => {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Grid container justify='space-between'>
                <Grid item xs={4}>
                    <FormControlLabel
                        label={label}
                        className={classes.formControl}
                        control={
                            <Checkbox
                                checked={filters[filter].active}
                                color='primary'
                                onChange={({ target: { checked } }) => dispatchFilters({
                                    type: 'activate',
                                    filter: filter,
                                    value: checked
                                })}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={8}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
};

function getTime(time) {
    let currentISO = date().toISOString();
    let currentDate = new Date(currentISO);

    switch (time) {
        case 'start':
            currentDate.setHours(0, 0, 0, 0);
            return currentDate.toISOString();
        case 'end':
            return currentISO;
        default:
            return null
    }
}

export default function UserList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
        hasPermission,
    } = useContext(PermissionsContext);

    const location = useLocation();
    const { paramStartTime, paramEndTime } = (location && location.state) || {};

    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleBackdrop = () => {
        setOpenBackdrop(!openBackdrop);
    }

    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [openMapPopup, setOpenMapPopup] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [accessLog, setAccessLog] = useState(null);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [logCameras, setLogCameras] = useState([]);
    // const [maxAccesses, setMaxAccesses] = useState(0);
    const [logTotal, setLogTotal] = useState(0);
    const [logPage, setLogPage] = useState(0);
    const [logLimit, setLogLimit] = useState(10);

    const [selectedUser, setSelectedUser] = useState({});

    const [openResendPopup, setOpenResendPopup] = useState(false);
    const [submittingResend, setSubmittingResend] = useState(false);

    const [openReportPopup, setOpenReportPopup] = useState(false);

    const [filters, dispatchFilters] = useReducer(reduceFilters, filterInitialValues);

    const [openFiltersPopup, setOpenFiltersPopup] = useState(false);

    const [openExport, setOpenExport] = useState(false);

    const handleOpenExport = () => setOpenExport(true);
    const handleCloseExport = () => setOpenExport(false);

    const [loadingUsers, setLoadingUsers] = useState(false);

    const resendRef = useRef(null)

    const [userCustomInputsFilters, setUserCustomInputsFilters] = useState([{
        active: false,
        filter: null,
        value: null,
    }]);

    const [openColumnsModal, setOpenColumnsModal] = useState(false);
    const toggleColumnsModal = () => setOpenColumnsModal(!openColumnsModal);
    const { tableColumns, dispatchTableColumns } = useTableColumnsToShow();

    useEffect(() => {
        if (paramStartTime && paramEndTime) {
            dispatchFilters({
                type: 'activate',
                filter: 'created_at',
                value: true,
            });
            dispatchFilters({
                type: 'change',
                filter: 'created_at',
                value: {
                    start: paramStartTime,
                    end: paramEndTime,
                }
            });
            setRefresh(!refresh)
        }
        //eslint-disable-next-line
    }, [paramStartTime, paramEndTime]);

    const timeSet = (time) => {
        let currentISO = date().toISOString();
        let currentDate = new Date(currentISO);
        let start = null;
        let end = currentISO;
        switch (time) {
            case '24h':
                currentDate.setHours(currentDate.getHours() - 24);
                start = currentDate.toISOString();
                break;
            case 'today':
                currentDate.setHours(0, 0, 0, 0);
                start = currentDate.toISOString();
                break;
            case '7d':
                currentDate.setDate(currentDate.getDate() - 7);
                start = currentDate.toISOString();
                break;
            default:
                end = null;
        }
        setStartTime(start);
        setEndTime(end);
    }

    const watchVideo = async accessLog => {
        setLoadingVideo(true);
        setAccessLog(accessLog);

        try {
            const result = await api.get(`/access_log/video/${accessLog.id}`);

            const { video } = result.data;

            const videoPath = `http://${host}:${port}/midias/video/acesso/${video}`;

            setVideoUrl(videoPath);
            setLoadingVideo(false);
        }
        catch (err) {
            clearVideo();

            setMessage({
                open: true,
                variant: 'error',
                text: 'Não foi possível baixar o vídeo',
            });
        }
    };

    const clearVideo = () => {
        setAccessLog(null);
        setVideoUrl('');
        setLoadingVideo(false);
    };


    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const accessStatus = ({ type, allowed }) => {
        switch (type) {
            case 'entry':
                return `Entrada ${allowed ? 'permitida' : 'negada'}`;
            case 'exit':
                return `Saída ${allowed ? 'permitida' : 'negada'}`;
            default:
                return `Acesso interno ${allowed ? 'permitido' : 'negado'}`;
        }
    };

    const [mapValue, setMapValue] = useState({ id: 0, map_image: "", name: "Mapa" });

    const [savedMaps, setSavedMaps] = useState([]);
    useEffect(() => {
        const findMap = async () => {
            try {
                const result = await api.get('/map/list');

                const { maps } = result.data;
                // console.log("Maps", maps)

                setSavedMaps(maps);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findMap();
    }, []);

    const [refresh, setRefresh] = useState(false);

    function updateRequest({ search }) {

        const loadData = async () => {
            setLoadingUsers(true);
            try {
                const result = await api.get('/user/list', {
                    params: {
                        start_time: paramStartTime ? paramStartTime : null,
                        end_time: paramEndTime ? paramEndTime : null,
                        search,
                        limit,
                        page,
                        filters: {
                            ...filters,
                            person_type: {
                                ...filters.person_type,
                                value: filters?.person_type?.value?.id ?? null,
                            },
                            org: {
                                ...filters.org,
                                value: filters?.org?.value?.id ?? null,
                            },
                            search_by_custom_inputs: userCustomInputsFilters,
                        },
                    },
                });

                const { count, users } = result.data;

                setUsers(users);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
                setMessage({
                    variant: 'error',
                    open: true,
                    text: err.error,
                })
            }
            setLoadingUsers(false);
        };

        loadData();

    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const changeLogPage = (_, newPage) => {
        setLogPage(newPage);
    };

    const logQuantityPerPage = ({ target }) => {
        setLogLimit(parseInt(target.value, 10));
        setLogPage(0);
    };

    const [personTypes, setPersonTypes] = useState([]);
    const [personTypeSeach, setPersonTypeSearch] = useState("");
    const deboncedPersonTypeSearch = useDebounce(personTypeSeach, 500);

    useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/person_type/list', {
                    params: {
                        show_exceptions: true,
                        limit: 30,
                        page: 0,
                        search: deboncedPersonTypeSearch,
                    }
                });

                const { personTypes } = result.data;

                setPersonTypes(personTypes);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, [deboncedPersonTypeSearch]);

    const [orgsSelect, setOrgsSelect] = useState([]);
    const [orgSearch, setOrgSearch] = useState("");
    const debouncedOrgsSearch = useDebounce(orgSearch, 500);

    useEffect(() => {
        const findOrg = async () => {
            try {
                const result = await api.get('/organizational_structure/index', {
                    params: {
                        limit: 10,
                        page: 0,
                        search: debouncedOrgsSearch,
                    }
                });

                const { orgs } = result.data;

                setOrgsSelect(orgs);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findOrg();
    }, [debouncedOrgsSearch]);

    const [customInputs, setCustomInputs] = useState([]);
    useEffect(() => {
        const findInputs = async () => {
            try {
                const result = await api.get('/custom_input/list', {
                    params: {
                        page: 0,
                        limit: 30,
                    }
                });

                const { custom_inputs } = result.data;

                setCustomInputs(custom_inputs);
            }
            catch (err) {
                console.log(err);
            }
        };

        findInputs();
    }, []);

    useEffect(() => {
        // console.log("opening popup")
        if (!openMapPopup) {
            // console.log('false value')
            setMapValue({ id: 0, map_image: "", name: "Mapa" })
            setStartTime(getTime('start'))
            setEndTime(getTime('end'))
            setCurrentUser(null)
            setLogCameras([]);
        }
    }, [openMapPopup]);

    const personTypeName = (id) => {
        let result = ''
        if (!id)
            return "|Não informado|"

        personTypes.forEach(current => {
            if (current.id === id) {
                result = current.name;
            }
        });

        return result;
    };

    const lastEventType = event => {
        switch (event) {
            case 'entry':
                return 'Entrada';
            case 'exit':
                return 'Saída';
            default:
                return 'Acesso interno';
        }
    };

    const [logs, setLogs] = useState([]);


    function setCurrentMap(e) {
        let currentMap = null;
        setLogCameras([]);
        savedMaps.forEach(map => {
            if (map.id === e.target.value) {
                currentMap = map;
            }
        })

        // console.log(currentMap)
        setMapValue(currentMap)
    }

    const [currentUser, setCurrentUser] = useState(null);

    function openModal(id) {
        setOpenMapPopup(true);
        let newUser = null;
        users.forEach(user => {
            if (user.id === id) {
                newUser = user;
            }
        })
        // console.log(newUser)
        setCurrentUser(newUser)
    }

    const [max, setMax] = useState(0);

    const getDeviceStatusTip = (relation) => {

        if (relation?.uid && relation?.error_type === null) {
            return <StatusTip success={true} type='check' content='Cadastrado(a) com sucesso' />;
        } else if (relation?.uid && relation?.error_type === "invalid_face_image") {
            return <StatusTip success={false} type='image' content='Cadastrado(a) com imagem inválida' />;
        } else if (!relation?.uid) {
            return <StatusTip success={false} type='error' content='Não cadastrado(a)' />
        } else {
            return;
        }

    }

    useEffect(() => {
        const loadData = async () => {
            try {
                if (mapValue?.id !== 0 && currentUser?.id) {
                    const result = await api.get('/access_log/list_by_map', {
                        params: {
                            user: currentUser?.id,
                            map: mapValue?.id,
                            startTime: startTime,
                            endTime: endTime,
                            limit: logLimit,
                            page: logPage,
                        },
                    });

                    const { log, count, cameras } = result.data;

                    setLogs(log);
                    setLogTotal(count);
                    setLogCameras(cameras);
                    // setMaxAccesses(cameras[0]?.max);
                    // setMax(parseInt(maxNumber))
                    const diffs = date(endTime).diff(startTime, 'days') !== 0 ? date(endTime).diff(startTime, 'days') + 1 : 1;
                    setMax(diffs)
                    // console.log(maxAccesses)
                }
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
        // eslint-disable-next-line
    }, [startTime, endTime, logLimit, logPage, mapValue])

    const [hover, setHover] = useState('40px');
    // const [opacity, setOpacity] = useState(100);
    const [indexLog, setIndexLog] = useState(null);

    function handleHover(index) {
        setHover('60px');
        // setOpacity(0);
        setIndexLog(index)
    }

    function handleLeaveHover() {
        setIndexLog(null)
        setHover('40px');
        // setOpacity(100);
    }

    function manageResending(user) {
        setSelectedUser(user);
        setOpenResendPopup(true);
    }

    async function resendUser() {

        try {
            setSubmittingResend(true);
            const data = resendRef.current.getValues();
            console.log(data)
            const result = await api.post(`/user/resend/${selectedUser.id}`, data);

            if (result.status === 204) {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: "Pessoa reenviada com sucesso"
                });
            }

            setSelectedUser(null);
            setOpenResendPopup(false);
            setSubmittingResend(false);
            setRefresh(!refresh)
        } catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error
            });
            setSelectedUser(null);
            setOpenResendPopup(false);
            setSubmittingResend(false);
        }
    }

    function manageReport(user) {
        setSelectedUser(user);
        setOpenReportPopup(true);
    }

    const appendFilter = () => {
        setUserCustomInputsFilters([...userCustomInputsFilters, {
            active: false,
            filter: null,
            value: null,
        }])
    };

    const toggleUserCustomInputFilter = (index, checked) => {
        const newValues = Object.assign([], userCustomInputsFilters);

        newValues[index].active = checked;

        setUserCustomInputsFilters(newValues);
    }

    const changeUserCustomInputFilter = (index, value) => {
        const newValues = Object.assign([], userCustomInputsFilters);

        newValues[index].filter = value;

        setUserCustomInputsFilters(newValues);
    }

    const changeUserCustomInputFilterValue = (index, value) => {
        console.log("chainging value")
        const newValues = Object.assign([], userCustomInputsFilters);

        newValues[index].value = value;

        setUserCustomInputsFilters(newValues);
    }

    const handleRemoveFilter = (index) => {
        if (userCustomInputsFilters.length === 1) return;

        const newCustomInputs = Object.assign([], userCustomInputsFilters);

        newCustomInputs.splice(index, 1);

        setUserCustomInputsFilters(newCustomInputs);
    }

    const getSingleCustomInputs = () => {
        let alreadyFoundIDS = [];

        const activeCustomInputs = userCustomInputsFilters.filter(element => element?.filter?.id && element.active);

        if (!activeCustomInputs.length) return [];

        const uniqueCustomInputs = activeCustomInputs.filter(element => {
            if (alreadyFoundIDS.includes(element.filter.id))
                return false;

            alreadyFoundIDS.push(element.filter.id);
            return true;
        });

        return uniqueCustomInputs;
    }

    const resetFilters = () => {
        dispatchFilters({
            type: 'reset'
        });
        setUserCustomInputsFilters([{
            active: false,
            filter: null,
            value: null,
        }]);
    }

    const TableCellSkeleton = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="rectangular" animation='wave' width='100%' height='2rem' />
            </div>
        )
    }

    const TableRowSkeleton = (key) => {
        return (
            <TableRow key={key}>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                {
                    (getSingleCustomInputs()).map((_, index) => (
                        <TableCell className={classes.tableBody} key={index}>
                            <TableCellSkeleton />
                        </TableCell>
                    ))
                }
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
                <TableCell className={classes.tableBody}>
                    <TableCellSkeleton />
                </TableCell>
            </TableRow>
        );
    };

    const TableBodySkeleton = () => {

        const loadRows = () => {
            const rows = [];
            for (let i = 0; i < (total < limit ? total : limit); i++) {
                rows.push('');
            }
            return rows;
        }

        const rowsNumber = loadRows();

        return (
            <TableBody>
                {
                    !!rowsNumber.length && rowsNumber.map((_, rowIndex) => (
                        <TableRowSkeleton key={rowIndex} />
                    ))
                }
            </TableBody>
        );
    }

    const handleExportUsers = async (fileType, exportDestination, destinationEmail) => {
        try {
            await api.get('/user/export', {
                params: {
                    start_time: paramStartTime ? paramStartTime : null,
                    end_time: paramEndTime ? paramEndTime : null,
                    search,
                    limit,
                    page,
                    filters: {
                        ...filters,
                        person_type: {
                            ...filters.person_type,
                            value: filters?.person_type?.value?.id ?? null,
                        },
                        search_by_custom_inputs: userCustomInputsFilters,
                    },
                    exportationType: fileType,
                    exportDestination: exportDestination,
                    destinationEmail: destinationEmail
                },
                responseType: exportDestination === "website" ? 'blob' : undefined,
            }).then((res) => {
                const suggestedFileName = res.headers['x-suggested-filename'];
                if (exportDestination === "website")
                    fileDownloader(res.data, suggestedFileName);
                else setMessage({
                    open: true,
                    variant: 'success',
                    text: `Em breve o arquivo gerado deve chegar em seu email "${destinationEmail}"`,
                });
            });
        } catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: "error",
                text: "Não foi possível realizar a exportação",
            });
        }
    }

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Pessoas
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <InputSearch
                                    textFieldProps={{
                                        placeholder: 'Buscar…',
                                    }}
                                    request={updateRequest}
                                    pagination={[page, limit, refresh]}
                                    onChangeSearch={(value) => setSearch(value)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setOpenFiltersPopup(true)}
                                >
                                    Filtros
                                </Button>
                            </Grid>
                            <PermissionWrapper route="/pessoa/exportar">
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={handleOpenExport}
                                    >
                                        Exportar
                                    </Button>
                                </Grid>
                            </PermissionWrapper>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={toggleColumnsModal}
                                >
                                    Campos
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        {(tableColumns.showID) && (
                                            <TableCell className={classes.tableHead}>ID</TableCell>
                                        )}
                                        {(tableColumns.showName) && (
                                            <TableCell className={classes.tableHead}>Nome</TableCell>
                                        )}
                                        {
                                            (getSingleCustomInputs()).map((customInput, index) => (
                                                <TableCell className={classes.tableHead} key={index}>{customInput.filter.name}</TableCell>
                                            ))
                                        }
                                        {(tableColumns.showActive) && (
                                            <TableCell className={classes.tableHead}>Ativo</TableCell>
                                        )}
                                        {(tableColumns.showFace) && (
                                            <TableCell className={classes.tableHead}>Face</TableCell>
                                        )}
                                        {(tableColumns.showOnDevices) && (
                                            <TableCell className={classes.tableHead}>Dispositivos</TableCell>
                                        )}
                                        {(tableColumns.showSex) && (
                                            <TableCell className={classes.tableHead}>Sexo</TableCell>
                                        )}
                                        {(tableColumns.showDocType) && (
                                            <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                        )}
                                        {(tableColumns.showDocNumber) && (
                                            <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                        )}
                                        {(tableColumns.showPhone) && (
                                            <TableCell className={classes.tableHead}>Telefone</TableCell>
                                        )}
                                        {(tableColumns.showEmail) && (
                                            <TableCell className={classes.tableHead}>E-mail</TableCell>
                                        )}
                                        {(tableColumns.showPersonType) && (

                                            <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        )}
                                        {(tableColumns.showOrganization) && (

                                            <TableCell className={classes.tableHead}>Organização</TableCell>
                                        )}
                                        {(tableColumns.showLastEvent) && (
                                            <TableCell className={classes.tableHead}>Último evento</TableCell>
                                        )}
                                        <PermissionOneOfWrapper
                                            routes={[
                                                "/visitante/modificar/:id",
                                                "/procurado/modificar/:id",
                                                "/pessoa/modificar/:id",
                                                "/pessoa/mapa_de_calor",
                                                "/pessoa/equipamentos_com_acesso",
                                                "/pessoa/reenviar",
                                            ]}
                                        >
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                {
                                    !!loadingUsers ? (
                                        <TableBodySkeleton />
                                    ) : (
                                        <TableBody>
                                            {!!users && users.map(user => (
                                                <TableRow key={user.id}>
                                                    {(tableColumns.showID) && (
                                                        <TableCell className={classes.tableBody} component="th" scope="row">
                                                            {user.id}
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showName) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {user.name}
                                                        </TableCell>
                                                    )}
                                                    {
                                                        (getSingleCustomInputs()).map((customInput, index) => (
                                                            <TableCell className={classes.tableBody} key={index}>
                                                                {
                                                                    (user.user_custom_inputs ?? []).find(userCI => userCI.custom_input_id === customInput.filter.id)?.information ?? "-"
                                                                }
                                                            </TableCell>
                                                        ))
                                                    }
                                                    {(tableColumns.showActive) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {user.active_register ? (
                                                                <Chip
                                                                    label={<CheckIcon className={classes.authorizationIcon} />}
                                                                    size="medium"
                                                                    className={classes.authorizedAccess}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    label={<CloseIcon className={classes.authorizationIcon} />}
                                                                    size="medium"
                                                                    className={classes.unauthorizedAccess}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showFace) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {user.face_image ? (
                                                                <Chip
                                                                    label={<CheckIcon className={classes.authorizationIcon} />}
                                                                    size="medium"
                                                                    className={classes.authorizedAccess}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    label={<CloseIcon className={classes.authorizationIcon} />}
                                                                    size="medium"
                                                                    className={classes.unauthorizedAccess}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showOnDevices) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {user.devices?.length ? (
                                                                user.devices.every(device => {
                                                                    if (device.relation[0]?.uid) return true;
                                                                    else return false;
                                                                }) ? (
                                                                    <Chip
                                                                        label={<CheckIcon className={classes.authorizationIcon} />}
                                                                        size="medium"
                                                                        className={classes.authorizedAccess}
                                                                    />
                                                                ) : (
                                                                    <Chip
                                                                        label={<CloseIcon className={classes.authorizationIcon} />}
                                                                        size="medium"
                                                                        className={classes.unauthorizedAccess}
                                                                    />
                                                                )
                                                            ) : '-'}
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showSex) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {
                                                                (user.sex === 'male') ? 'Masculino'
                                                                    : (user.sex === 'female') ? 'Feminino'
                                                                        : (user.sex === 'unknown') ? 'Outro'
                                                                            : 'Desconhecido'
                                                            }
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showDocType) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {user.doc_type && user?.doc_type === 'ic' ? 'RG' :
                                                                user?.doc_type === 'passport' ? 'Passaporte' :
                                                                    user?.doc_type === 'other' ? 'Outro' :
                                                                        user?.doc_type === 'unknown' ? 'CPF' :
                                                                            '-'}
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showDocNumber) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {user.doc_number ? user.doc_number : "-"}
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showPhone) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {user.phone !== null ? ((user.phone.length === 10) ? (
                                                                mask('(##) ####-####', user.phone)
                                                            ) : (
                                                                mask('(##) #####-####', user.phone)
                                                            )) : '-'}
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showEmail) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {user.email !== null ? (user.email) : '-'}
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showPersonType) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {personTypeName(user.person_type_id)}
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showOrganization) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {
                                                                !!user.OrganizationalStructure
                                                                    ? user.OrganizationalStructure.name
                                                                    : '-'
                                                            }
                                                        </TableCell>
                                                    )}
                                                    {(tableColumns.showLastEvent) && (
                                                        <TableCell className={classes.tableBody}>
                                                            {(user.access_logs.length > 0) && (
                                                                lastEventType(user.access_logs[0].action_type)
                                                            )}
                                                        </TableCell>
                                                    )}
                                                    <PermissionOneOfWrapper
                                                        routes={[
                                                            "/visitante/modificar/:id",
                                                            "/procurado/modificar/:id",
                                                            "/pessoa/modificar/:id",
                                                            "/pessoa/mapa_de_calor",
                                                            "/pessoa/equipamentos_com_acesso",
                                                            "/pessoa/reenviar",
                                                        ]}
                                                    >
                                                        <TableCell className={classes.tableBody}>
                                                            <Grid justify='center' container spacing={1}>
                                                                {
                                                                    user.person_type_id && user.person_type_id === 4 ? (
                                                                        <PermissionWrapper route="/visitante/modificar/:id">
                                                                            <Grid item>
                                                                                <Button
                                                                                    type="button"
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    className={classes.actionButton}
                                                                                    href={`/visitante/modificar/${user.id}`}
                                                                                    fullWidth={true}
                                                                                >
                                                                                    <EditIcon style={{ fontSize: 20 }} />
                                                                                </Button>
                                                                            </Grid>
                                                                        </PermissionWrapper>
                                                                    ) : user.person_type_id && user.person_type_id === 2 ? (
                                                                        <PermissionWrapper route="/procurado/modificar/:id">
                                                                            <Grid item>
                                                                                <Button
                                                                                    type="button"
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    className={classes.actionButton}
                                                                                    href={`/procurado/modificar/${user.id}`}
                                                                                    fullWidth={true}
                                                                                >
                                                                                    <EditIcon style={{ fontSize: 20 }} />
                                                                                </Button>
                                                                            </Grid>
                                                                        </PermissionWrapper>
                                                                    ) : (
                                                                        <PermissionWrapper route="/pessoa/modificar/:id">
                                                                            <Grid item>
                                                                                <Button
                                                                                    type="button"
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    className={classes.actionButton}
                                                                                    href={`/pessoa/modificar/${user.id}`}
                                                                                    fullWidth={true}
                                                                                >
                                                                                    <EditIcon style={{ fontSize: 20 }} />
                                                                                </Button>
                                                                            </Grid>
                                                                        </PermissionWrapper>
                                                                    )
                                                                }
                                                                <PermissionWrapper route="/pessoa/mapa_de_calor">
                                                                    <Grid item>
                                                                        <Button
                                                                            type="button"
                                                                            variant="contained"
                                                                            size="small"
                                                                            color="primary"
                                                                            style={{ background: '#ECB365' }}
                                                                            className={classes.actionButton}
                                                                            onClick={() => openModal(user.id)}
                                                                            fullWidth={true}
                                                                        >
                                                                            <VisibilityIcon style={{ fontSize: 20 }} />
                                                                        </Button>
                                                                    </Grid>
                                                                </PermissionWrapper>
                                                                <PermissionWrapper route="/pessoa/equipamentos_com_acesso">
                                                                    <Grid item>
                                                                        <Button
                                                                            type="button"
                                                                            variant="contained"
                                                                            size="small"
                                                                            color="primary"
                                                                            style={{ background: '#5b8262' }}
                                                                            className={classes.actionButton}
                                                                            onClick={() => manageReport(user)}
                                                                            fullWidth={true}
                                                                        >
                                                                            <AssignmentIcon style={{ fontSize: 20 }} />
                                                                        </Button>
                                                                    </Grid>
                                                                </PermissionWrapper>
                                                                <PermissionWrapper route="/pessoa/reenviar">
                                                                    {
                                                                        user.face_image && (
                                                                            <Grid item>
                                                                                <Button
                                                                                    type="button"
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    style={{ background: grey[400] }}
                                                                                    className={classes.actionButton}
                                                                                    onClick={() => manageResending(user)}
                                                                                    fullWidth={true}
                                                                                >
                                                                                    <ReloadIcon style={{ fontSize: 20 }} />
                                                                                </Button>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                </PermissionWrapper>
                                                            </Grid>
                                                        </TableCell>
                                                    </PermissionOneOfWrapper>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    )
                                }
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                <PermissionOneOfWrapper
                    routes={[
                        "/procurado/criar",
                        "/visitante/criar",
                        "/pessoa/criar",
                    ]}
                >
                    <Box className={classes.fabButton}>
                        <Backdrop open={openBackdrop} />
                        <SpeedDial
                            ariaLabel="SpeedDial tooltip example"
                            sx={{
                                position: 'absolute',
                                bottom: 16,
                                right: 16,
                                '& button:first-of-type': {
                                    backgroundColor: '#3f51b5',

                                }
                            }}
                            icon={<SpeedDialIcon />}
                            onClose={handleBackdrop}
                            onOpen={handleBackdrop}
                            open={openBackdrop}
                        >
                            {/* for some reason the SpeedDial doesnt accept anything wrapping the SpeedDialAction, even Fragments, so either return it plain or dont :/ */}
                            {
                                [
                                    {
                                        route: "/procurado/criar",
                                        component: (
                                            <SpeedDialAction
                                                icon={<ReportIcon />}
                                                tooltipTitle={'Procurado'}
                                                tooltipOpen
                                                FabProps={{
                                                    href: '/procurado/criar'
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        route: "/visitante/criar",
                                        component: (
                                            <SpeedDialAction
                                                icon={<CardTravelIcon />}
                                                tooltipTitle={'Visitante'}
                                                tooltipOpen
                                                FabProps={{
                                                    href: '/visitante/criar'
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        route: "/pessoa/criar",
                                        component: (
                                            <SpeedDialAction
                                                icon={<PeopleIcon />}
                                                tooltipTitle={'Regular'}
                                                tooltipOpen
                                                FabProps={{
                                                    href: '/pessoa/criar'
                                                }}
                                            />
                                        ),
                                    },
                                ].filter((element) => hasPermission(element.route)).map((element) => element.component)
                            }
                        </SpeedDial>
                    </Box>
                </PermissionOneOfWrapper>
            </main>
            <MapPopup
                openPopup={openMapPopup}
                setOpenPopup={setOpenMapPopup}
                height='90%'
                width='90%'
                maxWidth='xl'
                title={`Histórico de ${currentUser?.name}`}
            >
                <Grid container style={{ height: '100%' }}>
                    <Grid xs={6} item style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={4}>
                                <DateTimePicker
                                    label='Data de Início'
                                    className={classes.dateTimeInput}
                                    format="DD/MM/YYYY HH:mm"
                                    value={startTime}
                                    placeholder="Início"
                                    onChange={(date) => setStartTime(date && date.format())}
                                    cancelLabel="cancelar"
                                    disabled={false}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DateTimePicker
                                    label='Data de Fim'
                                    className={classes.dateTimeInput}
                                    format="DD/MM/YYYY HH:mm"
                                    value={endTime}
                                    placeholder="Fim"
                                    onChange={(date) => setEndTime(date && date.format())}
                                    cancelLabel="cancelar"
                                    disabled={false}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    label="Mapa de localização"
                                    options={
                                        savedMaps.map(map => ({ label: map.name, value: map.id }))
                                    }
                                    textFieldProps={{
                                        id: 'c_loc',
                                        onChange: (e) => setCurrentMap(e),
                                        value: mapValue.id,
                                        fullWidth: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify='center' spacing={2} style={{ marginTop: '10px' }}>
                            {/* <Grid item xs={12}>
                                <Typography align='center' variant='subtitle1'>
                                    Filtros de Rápidos
                                </Typography>
                            </Grid> */}
                            <Grid item xs={4}>
                                <Button
                                    variant='outlined'
                                    onClick={() => timeSet('today')}
                                    disabled={false}
                                    fullWidth={true}
                                >
                                    Hoje
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant='outlined'
                                    style={{ marginRight: '10px' }}
                                    onClick={() => timeSet('24h')}
                                    disabled={false}
                                    fullWidth={true}
                                >
                                    Últimas 24 horas
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant='outlined'
                                    onClick={() => timeSet('7d')}
                                    disabled={false}
                                    fullWidth={true}
                                >
                                    Últimos 7 dias
                                </Button>
                            </Grid>
                        </Grid>

                        <TableContainer component={Paper} style={{ maxHeight: '560px', marginTop: '25px' }}>
                            <Table className={classes.table} size="small" aria-label="access history" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Nome da Câmera</TableCell>
                                        <TableCell className={classes.tableHead}>Localização</TableCell>
                                        <TableCell className={classes.tableHead}>Horário</TableCell>
                                        <TableCell className={classes.tableHead}>Acesso</TableCell>
                                        <TableCell className={classes.tableHead}>Video</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {(mapValue.id !== 0) && (
                                        logs.map((log, index) => {
                                            if (log.camera.map_id === mapValue.id) {
                                                return (

                                                    <TableRow
                                                        key={index}
                                                        onMouseEnter={() => handleHover(log.camera_id)}
                                                        onMouseLeave={() => handleLeaveHover()}
                                                        className={classes.tableRow}
                                                    >
                                                        <TableCell className={classes.tableBody} component="th" scope="row">
                                                            {log.camera.name}
                                                        </TableCell>
                                                        <TableCell className={classes.tableBody}>
                                                            {(log.camera) ? (log.camera.place_cameras.name) : ("-")}
                                                        </TableCell>
                                                        <TableCell className={classes.tableBody}>
                                                            {date(log.event_time).toLocaleFormat()}
                                                        </TableCell>
                                                        <TableCell className={classes.tableBody}>
                                                            {log.allowed_access ? (
                                                                <Chip
                                                                    icon={<CheckIcon className={classes.authorizationIcon} />}
                                                                    label={accessStatus({
                                                                        type: log.action_type,
                                                                        allowed: log.allowed_access,
                                                                    })}
                                                                    size="small"
                                                                    className={classes.authorizedAccess}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    icon={<CloseIcon className={classes.authorizationIcon} />}
                                                                    label={accessStatus({
                                                                        type: log.action_type,
                                                                        allowed: log.allowed_access,
                                                                    })}
                                                                    size="small"
                                                                    className={classes.unauthorizedAccess}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell className={classes.tableBody}>
                                                            {(log.camera !== null) ? ((accessLog && accessLog.id === log.id && loadingVideo) ? (
                                                                <Button
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    disabled
                                                                    size="small"
                                                                    className={classes.actionButton}
                                                                >
                                                                    <CachedIcon fontSize="small" />
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="small"
                                                                    className={classes.actionButton}
                                                                    onClick={async () => await watchVideo(log)}
                                                                >
                                                                    <PlayArrowIcon fontSize="small" />
                                                                </Button>
                                                            )) : ('-')}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                            return null;
                                        })
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={logTotal}
                            rowsPerPage={logLimit}
                            page={logPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changeLogPage}
                            onChangeRowsPerPage={logQuantityPerPage}
                        />
                    </Grid>
                    <FullScreenDialog
                        open={!!(videoUrl && !loadingVideo)}
                        title={accessLog && (
                            `${accessLog.camera.name}
                            (${accessLog.camera.device.name})`
                        )}
                        onClose={clearVideo}
                    >
                        <video controls className={classes.video}>
                            <source src={videoUrl} />
                        </video>
                    </FullScreenDialog>
                    <Grid xs={6} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                        <Grid item style={{ display: (mapValue.id !== 0 && logCameras.length !== 0) ? 'block' : 'none' }}>
                            <Typography variant='h6' align='center' gutterBottom>
                                Média de acessos
                            </Typography>
                            <Grid container style={{ width: '100%' }}>
                                <Grid item className={classes.averagePins}>
                                    <Typography variant='subtitle1' className={classes.pinText}>
                                        {`1 - ${10 * max}`}
                                    </Typography>
                                    <div>
                                        <img src={BluePin} className={classes.pinImage} alt='blue pin' />
                                    </div>
                                </Grid>
                                <Grid item className={classes.averagePins}>
                                    <Typography variant='subtitle1' className={classes.pinText}>
                                        {`${(10 * max) + 1} - ${(20 * max)}`}
                                    </Typography>
                                    <div>
                                        <img src={CyanPin} className={classes.pinImage} alt='blue pin' />
                                    </div>
                                </Grid>
                                <Grid item className={classes.averagePins}>
                                    <Typography variant='subtitle1' className={classes.pinText}>
                                        {`${(20 * max) + 1} - ${(30 * max)}`}
                                    </Typography>
                                    <div>
                                        <img src={GreenPin} className={classes.pinImage} alt='blue pin' />
                                    </div>
                                </Grid>
                                <Grid item className={classes.averagePins}>
                                    <Typography variant='subtitle1' className={classes.pinText}>
                                        {`${(30 * max) + 1} - ${(40 * max)}`}
                                    </Typography>
                                    <div>
                                        <img src={OrangePin} className={classes.pinImage} alt='blue pin' />
                                    </div>
                                </Grid>
                                <Grid item className={classes.averagePins}>
                                    <Typography variant='subtitle1' className={classes.pinText}>
                                        {`+ ${(40 * max) + 1}`}
                                    </Typography>
                                    <div>
                                        <img src={RedPin} className={classes.pinImage} alt='blue pin' />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{ position: 'relative' }}>
                            <Canvas image={`${mapValue.map_image}`} getCursorPos={() => null} />
                            {
                                (mapValue.id !== 0 && logCameras.length !== 0) && logCameras.map((element, index) => {
                                    // console.log({ id: element.id, max: element.max, count: element.count })
                                    if (mapValue.id === element.camera.map_id) {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    top: `${element.camera.y_axis}px`,
                                                    left: `${element.camera.x_axis}px`,
                                                    position: 'absolute',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <Pin
                                                    pinAccesses={element?.count}
                                                    maxAccesses={max}
                                                    name={element?.camera.name}
                                                    style={{
                                                        width: `${element.camera_id === indexLog ? hover : 40}px`,
                                                        transition: 'width .2s, opacity .2s',
                                                        opacity: `${indexLog === null ? 100 : (element.camera_id === indexLog ? 100 : 0)}`
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                    return null;
                                })
                            }
                        </div>
                    </Grid>
                </Grid>
            </MapPopup>
            <Popup
                openPopup={openResendPopup}
                setOpenPopup={setOpenResendPopup}
                width='85%'
                // height='45%'
                title={`Deseja reenviar ${selectedUser?.name} a seus respectivos dispositivos novamente?`}
            >
                <Grid container spacing={2} justify='center'>
                    <ResendValidation user={selectedUser} ref={resendRef} />
                    {
                        !!selectedUser?.devices?.filter(device => device.type === 'dhi').length && (
                            <Grid item xs={12}>
                                <Typography variant='h5'>
                                    Dispositivos
                                </Typography>
                                <Grid container spacing={1}>
                                    {
                                        selectedUser?.devices?.filter(device => device.type === 'dhi')?.map(device => (
                                            <Grid item xs={4} key={`${device.id}`}>
                                                <Typography variant='subtitle1'>
                                                    - {device.name}
                                                </Typography>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        !!selectedUser?.cameras?.length && (
                            <Grid item xs={12}>
                                <Typography variant='h5'>
                                    Câmeras
                                </Typography>
                                <Grid container spacing={1}>
                                    {
                                        selectedUser?.cameras?.map(camera => (
                                            <Grid item xs={6} key={`${camera.id}`}>
                                                <Typography variant='subtitle1'>
                                                    - {camera.name} | ({camera.device?.name})
                                                </Typography>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                    <Grid item xs={12} md={4}>
                        <Button
                            type="button"
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.actionButton}
                            onClick={() => resendUser()}
                            fullWidth={true}
                            disabled={submittingResend}
                        >
                            {submittingResend ? 'ENVIANDO...' : 'SIM'}
                        </Button>
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openReportPopup}
                setOpenPopup={setOpenReportPopup}
                title={`Dispositivos e Câmeras de acesso para ${selectedUser?.name}`}
                width='50%'
            >
                <Grid container spacing={4}>
                    {
                        (
                            !selectedUser?.devices?.filter(device => device.type === 'dhi').length &&
                            !selectedUser?.devices?.filter(device => device.type !== 'dhi').length
                        ) && (
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        fontWeight: 900
                                    }}
                                >
                                    Essa pessoa não tem vínculo com nenhum dispositivo
                                </Typography>
                            </Grid>
                        )
                    }
                    {
                        !!selectedUser?.devices?.filter(device => device.type === 'dhi').length && (
                            <Grid item xs={12}>
                                <Typography variant='h5'>
                                    Leitores Faciais
                                </Typography>
                                <Grid container spacing={1}>
                                    {
                                        selectedUser?.devices?.filter(device => device.type === 'dhi')?.map(device => (
                                            <Grid item xs={4} key={`${device.id}`}>
                                                <Typography variant='subtitle1'>
                                                    - {device.name} {getDeviceStatusTip(device?.relation[0])}
                                                </Typography>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        !!selectedUser?.devices?.filter(device => device.type !== 'dhi').length && (
                            <Grid item xs={12}>
                                <Typography variant='h5'>
                                    NVRs & Câmeras
                                </Typography>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        paddingTop: '10px'
                                    }}
                                >
                                    {
                                        selectedUser?.devices?.filter(device => device.type !== 'dhi')?.map((device, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Accordion style={{ boxShaddow: 'none' }}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`${device.name}-content`}
                                                        id={`${device.name}-header`}
                                                    >
                                                        <Typography className={classes.heading}>
                                                            {device.name}  {getDeviceStatusTip(device?.relation[0])}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container>
                                                            {
                                                                selectedUser?.cameras?.filter(camera => camera.device_id === device.id).map(camera => (
                                                                    <Grid key={`${camera.id}`} item xs={12} md={4}>
                                                                        <Typography>
                                                                            - {camera.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <Grid container>
                            <Typography variant='h5' style={{ color: grey[700] }}>
                                Referência
                            </Typography>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <StatusTip success={true} type='check' content='Cadastrado(a) com sucesso' />
                                <Typography variant='subtitle1' style={{ color: grey[700] }}>
                                    Cadastrado(a) com sucesso
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <StatusTip success={false} type='image' content='Cadastrado(a) com imagem inválida' />
                                <Typography variant='subtitle1' style={{ color: grey[700] }}>
                                    Cadastrado(a) com imagem inválida
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <StatusTip success={false} type='error' content='Não cadastrado(a)' />
                                <Typography variant='subtitle1' style={{ color: grey[700] }}>
                                    Não cadastrado(a)
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                title='Filtros para pessoas'
                openPopup={openFiltersPopup}
                setOpenPopup={setOpenFiltersPopup}
                width='60%'
            >
                <Grid container spacing={2}>
                    {
                        (userCustomInputsFilters ?? []).map((filter, index) => (
                            <CustomFilterItem
                                key={index}
                                label="Campo personalizado"
                                index={index}
                                onActivate={toggleUserCustomInputFilter}
                                onChooseInput={changeUserCustomInputFilter}
                                onChooseValue={changeUserCustomInputFilterValue}
                                onRemove={handleRemoveFilter}
                                inputs={customInputs}
                                values={userCustomInputsFilters[index]}
                                allowedToRemove={userCustomInputsFilters.length > 1}
                            />
                        ))
                    }

                    <Grid item xs={12} style={{ display: "flex", gap: 8, justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={appendFilter}
                        >
                            Adicionar Campo
                        </Button>
                    </Grid>
                    <FilterItem label="Sexo" filter='sex' filters={filters} dispatchFilters={dispatchFilters}>
                        <Select
                            options={[
                                { label: 'Selecione um valor', value: null },
                                { label: 'Masculino', value: 'male' },
                                { label: 'Feminino', value: 'female' },
                                { label: 'Desconhecido', value: 'unknown' }
                            ]}
                            textFieldProps={{
                                onChange: ({ target: { value } }) => dispatchFilters({
                                    type: 'change',
                                    filter: 'sex',
                                    value,
                                }),
                                value: filters.sex.value,
                                disabled: !filters.sex.active,
                                fullWidth: true
                            }}
                        />
                    </FilterItem>
                    <FilterItem label="Tipo de Pessoa" filter='person_type' filters={filters} dispatchFilters={dispatchFilters}>
                        <Autocomplete
                            id="type_of_person"
                            options={personTypes}
                            getOptionLabel={({ name }) => name || ''}
                            onChange={(_, value) => dispatchFilters({
                                type: 'change',
                                filter: 'person_type',
                                value,
                            })}
                            getOptionSelected={(option, value) => (option.id === value.id)}
                            onInputChange={(_, value) => setPersonTypeSearch(value)}
                            value={filters.person_type?.value}
                            disabled={!filters.person_type.active}
                            fullWidth />
                    </FilterItem>
                    <FilterItem label="Organização" filter='org' filters={filters} dispatchFilters={dispatchFilters}>
                        <Autocomplete
                            id="org"
                            options={orgsSelect}
                            getOptionLabel={({ name }) => name || ''}
                            onChange={(_, value) => dispatchFilters({
                                type: 'change',
                                filter: 'org',
                                value,
                            })}
                            getOptionSelected={(option, value) => (option.id === value.id)}
                            onInputChange={(_, value) => setOrgSearch(value)}
                            value={filters.org.value}
                            required={false}
                            disabled={!filters.org.active}
                            fullWidth
                        />
                    </FilterItem>
                    <FilterItem label="Imagem Facial" filter='face_image' filters={filters} dispatchFilters={dispatchFilters}>
                        <Select
                            options={
                                [
                                    { label: 'Selecione um valor', value: null },
                                    { label: 'Cadastrada', value: true },
                                    { label: 'Não Cadastrada', value: false },
                                ]
                            }
                            textFieldProps={{
                                onChange: ({ target: { value } }) => dispatchFilters({
                                    type: 'change',
                                    filter: 'face_image',
                                    value,
                                }),
                                value: filters.face_image.value,
                                disabled: !filters.face_image.active,
                                fullWidth: true
                            }}
                        />
                    </FilterItem>
                    <FilterItem label="Intervalo de Criação" filter='created_at' filters={filters} dispatchFilters={dispatchFilters}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    value={filters.created_at.value.start}
                                    placeholder="Inicio"
                                    onChange={value => dispatchFilters({
                                        type: 'change',
                                        datatype: 'object',
                                        filter: 'created_at',
                                        key: 'start',
                                        value: (value && value.format())
                                    })}
                                    cancelLabel="cancelar"
                                    disabled={!filters.created_at.active}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    value={filters.created_at.value.end}
                                    placeholder="Fim"
                                    onChange={value => dispatchFilters({
                                        type: 'change',
                                        datatype: 'object',
                                        filter: 'created_at',
                                        key: 'end',
                                        value: (value && value.format())
                                    })}
                                    cancelLabel="cancelar"
                                    disabled={!filters.created_at.active}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    </FilterItem>
                    <FilterItem label="Intervalo de Alteração" filter='updated_at' filters={filters} dispatchFilters={dispatchFilters}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    value={filters.updated_at.value.start}
                                    placeholder="Inicio"
                                    onChange={value => dispatchFilters({
                                        type: 'change',
                                        datatype: 'object',
                                        filter: 'updated_at',
                                        key: 'start',
                                        value: (value && value.format())
                                    })}
                                    cancelLabel="cancelar"
                                    disabled={!filters.updated_at.active}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    value={filters.updated_at.value.end}
                                    placeholder="Fim"
                                    onChange={value => dispatchFilters({
                                        type: 'change',
                                        datatype: 'object',
                                        filter: 'updated_at',
                                        key: 'end',
                                        value: (value && value.format())
                                    })}
                                    cancelLabel="cancelar"
                                    disabled={!filters.updated_at.active}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    </FilterItem>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' justify='center' spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    fullWidth={true}
                                    onClick={resetFilters}
                                >
                                    Limpar
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    fullWidth={true}
                                    onClick={() => setRefresh(!refresh)}
                                    disabled={loadingUsers}
                                >
                                    {
                                        loadingUsers ? (
                                            <div style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                '& > div': {
                                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                }
                                            }}>
                                                <Loader type="ThreeDots" color="#99a8bd" height='auto' width='2rem' />
                                            </div>
                                        ) : 'Aplicar'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popup>
            <ExportModal
                open={openExport}
                onClose={handleCloseExport}
                handleExport={handleExportUsers}
            />
            <ColumnsManagementModal
                open={openColumnsModal}
                setOpen={setOpenColumnsModal}
                tableColumns={tableColumns}
                dispatchTableColumns={dispatchTableColumns}
            />
            {
                !!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )
            }
        </div >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    authorizedAccess: {
        backgroundColor: green[700],
        color: '#FFF',
    },
    unauthorizedAccess: {
        backgroundColor: red[700],
        color: '#FFF',
    },
    authorizationIcon: {
        color: '#FFF',
    },
    videoButton: {
        lineHeight: 1,
        '& span > svg': {
            marginRight: 2,
        },
    },
    video: {
        maxHeight: '100%',
        height: '100%',
        maxWidth: '100%',
        display: 'block',
        margin: 'auto',
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    tableRow: {
        transition: '0.2s',
        '&:hover': {
            backgroundColor: grey[300],
        }
    },
    actionButton: {
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    averagePins: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 10px'
    },
    pinText: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
    },
    pinImage: {
        width: '30px'
    },
    formControl: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        '& .MuiTypography-body1': {
            fontSize: '1.2rem',
            userSelect: 'none'
        },
    }
}));
