import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
    Tabs, Tab, Box
} from '@material-ui/core';
import BarChartIcon from '@mui/icons-material/BarChart';
import ClearDevice from '@mui/icons-material/CleaningServices';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DoorBackIcon from '@mui/icons-material/DoorBack';
//import ConfigIcon from '@material-ui/icons/Settings';
import CamIcon from '@material-ui/icons/Videocam';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { loadPlayer } from 'rtsp-relay/browser'

import SideMenu from '../../components/SideMenu';
import Input from '../../components/Input';
import Alert from '../../components/Alert';
import Popup from '../../components/Popup';
import PopUpDeviceCapacity from '../../components/PopUpDeviceCapacity';
import FullScreenDialog from '../../components/FullScreenDialog';
import InputSearch from '../../components/InputSearch';

import api from '../../services/api';
import config from '../../configs/config';
import storage from '../../utils/storage';
import { useDisclosure } from '../../hooks/useDiscloure';
import DeviceCreateModal from './Modal/DeviceCreate';
import DeviceEditModal from './Modal/DeviceEdit';
import { PermissionsContext } from '../../providers/Permissions';
import OnvifExtraData from './Modal/OnvifExtraData';
import { ViewHeadline } from '@material-ui/icons';

export default function DeviceList() {
    const classes = useStyles();


    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [devices, setDevices] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [refresh, setRefresh] = useState(true)
    const [authenticated, setAuthenticated] = useState(null);
    const [limit, setLimit] = useState(10);
    // eslint-disable-next-line

    const [openModalCreate, setModalCreate] = useState(false);
    const [openModalEdit, setModalEdit] = useState(false);
    const [deviceCameras, setDeviceCameras] = useState([]);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);

    const [loadingCapacity, setLoadingCapacity] = useState(false);
    const [capacity, setCapacity] = useState({});

    const [openRTSP, setOpenRTSP] = useState(false);
    const [loadingRSTP, setLoadingRSTP] = useState(false);
    const [rtspRunning, setRTSPRunning] = useState(false);
    const rtspCanvas = useRef(null);



    const handleCreateCamera = () => {
        setModalCreate(true);
    };

    const handleCloseRSTP = () => {
        setOpenRTSP(false);
        setLoadingRSTP(false);
        setRTSPRunning(false);
    }

    const connectionEstabilished = () => {
        setLoadingRSTP(true);
    };

    const playStarted = () => {
        setRTSPRunning(true);
        setLoadingRSTP(false);
    }



    const [value, setValue] = useState(0);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);

    const handleDetails = (device) => {
        setSelectedDevice(device);
        setOpenDetails(true);
    };

    const handleCloseDetails = () => {
        setSelectedDevice(null);
        setOpenDetails(false);
    }

    const handleEdit = async (device) => {
        try {
            const response = await api.get(`/external_cam/view/${device.id}`);

            setSelectedDevice(response.data);
            setModalEdit(true);
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error ?? "Não foi possível visualizar a câmera",
            });
        }
    }

    const handleDelete = (device) => {
        setSelectedDevice(device);
        setOpenDeletePopup(true);
    };

    const deleteDevice = async (id) => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/external_cam/delete/${selectedDevice.id}`);

            const { success } = result.data;

            setIsDeleting(false);

            if (success) {
                setSelectedDevice(null);
                setOpenDeletePopup(false);
                setRefresh(!refresh);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Dispositivo deletado com sucesso!`,
                });
            }
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    useEffect(() => {
        const user = storage.getItem('@Identifik:user');
        setAuthenticated(user);
    }, []);

    function updateRequest({ search }) {

        const loadData = async () => {
            try {
                const result = await api.get('/external_cam/list', {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });

                console.log(search, limit, page)

                const { count, devices } = result.data;

                console.log(devices);

                setDevices(devices);
                setTotal(count);

                console.log('teste')
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <Popup
                openPopup={openDeletePopup}
                setOpenPopup={setOpenDeletePopup}

            >
                <Grid container xs={12} style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 3% 2% 3%',

                }}>
                    <Typography variant='h5'
                        style={{
                            fontWeight: 'bold',
                            color: '#9e9e9e'
                        }}
                    >
                        Deseja mesmo deletar esse dispositivo?
                    </Typography>
                    <Grid item style={{
                        marginTop: '50px'
                    }}>
                        <Button
                            disabled={isDeleting}
                            variant="contained"
                            onClick={() => deleteDevice()}
                            color="secondary"
                            endIcon={<DeleteIcon />}
                        >
                            {isDeleting ? 'Deletando...' : 'Sim, delete o dispositivo'}
                        </Button>
                    </Grid>

                </Grid>
            </Popup>

            {openModalCreate && (
                <DeviceCreateModal
                    openModalCreate={openModalCreate}
                    setModalCreate={setModalCreate}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    setMessage={setMessage}
                />)}

            {openModalEdit && (
                <DeviceEditModal
                    selectedDevice={selectedDevice}
                    openModalEdit={openModalEdit}
                    setModalEdit={setModalEdit}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    setMessage={setMessage}
                />)}

            { }
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Cameras
                        </Typography>
                    </Grid>
                    <Grid item spacing={5} xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={updateRequest}
                            pagination={[page, limit, refresh]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Ativo</TableCell>
                                        <TableCell className={classes.tableHead}>Online</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Dispositivo</TableCell>
                                        <TableCell className={classes.tableHead}>Organização</TableCell>
                                        <PermissionOneOfWrapper routes={['/listar_cameras/deletar/:id', '/listar_cameras/modificar/:id']}>
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!devices && devices.map(device => (
                                        <TableRow key={device.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {device.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    device.enabled ? (
                                                        <div className={classes.online} />
                                                    ) : (
                                                        <div className={classes.offline} />
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    device.flag_status && device.enabled ? (
                                                        <div className={classes.online} />
                                                    ) : (
                                                        <div className={classes.offline} />
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.device_type}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.org ? device.org.name : '-'}
                                            </TableCell>
                                            <PermissionOneOfWrapper routes={['/listar_cameras/deletar/:id', '/listar_cameras/modificar/:id']}>
                                                <TableCell className={classes.tableBody}>
                                                    {
                                                        device.device_type === "Onvif" && (
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="secondary"
                                                                className={classes.actionButton}
                                                                style={{ background: '#ECB365' }}
                                                                onClick={() => handleDetails(device)}
                                                            >
                                                                <ViewHeadline style={{ fontSize: 20 }} />
                                                            </Button>
                                                        )
                                                    }
                                                    <PermissionWrapper route={'/listar_cameras/modificar/:id'}>
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            className={classes.actionButton}
                                                            onClick={() => { handleEdit(device) }}
                                                        >
                                                            <EditIcon style={{ fontSize: 20 }} />
                                                        </Button>
                                                    </PermissionWrapper>
                                                    <PermissionWrapper route={'/listar_cameras/deletar/:id'}>
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            color="secondary"
                                                            className={classes.actionButton}
                                                            onClick={() => handleDelete(device)}
                                                        >
                                                            <DeleteIcon style={{ fontSize: 20 }} />
                                                        </Button>
                                                    </PermissionWrapper>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>

                    <FullScreenDialog
                        open={openRTSP}
                        title={`${selectedDevice?.name} - ${loadingRSTP ? 'CARREGANDO' : (rtspRunning ? 'CONECTADO' : 'DESCONECTADO')}`}
                        onClose={handleCloseRSTP}
                    >
                        <div style={{
                            backgroundmaxHeight: '100%',
                            height: '100%',
                            maxWidth: '100%',
                            margin: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <canvas ref={rtspCanvas} style={{ height: '100%', width: '40%', borderRadius: '8px' }}></canvas>
                        </div>
                    </FullScreenDialog>

                </Grid>
                <OnvifExtraData
                    cameraId={selectedDevice?.id ?? null}
                    openModal={openDetails}
                    onCloseModal={handleCloseDetails}
                    setMessage={setMessage}
                />
                <PermissionWrapper route={'/listar_cameras/criar'}>
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        onClick={handleCreateCamera}
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}

            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    online: {
        height: '15px',
        width: '15px',
        borderRadius: '9999px',
        margin: '0 auto',
        background: 'hsl(120, 100%, 50%)',
    },
    offline: {
        height: '15px',
        width: '15px',
        margin: '0 auto',
        borderRadius: '9999px',
        background: 'hsl(0, 100%, 50%)',
    }
}));
