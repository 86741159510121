import React, { useState, useContext } from 'react';
import { Paper, Button, Grid, Typography } from '@material-ui/core';
// import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos'
import Loader from 'react-loader-spinner';

import Input from '../../components/Input';
import Alert from '../../components/Alert';

import { AuthContext } from '../../providers/Auth';

// import logo from '../../assets/img/logo.png';
import logoHori from '../../assets/img/logo-horizontal.png';
import loginBg from '../../assets/img/login-bg.jpg';
// import formBg from '../../assets/img/form-bg.png';

import "./styles.css";

export default function Login() {
    const classes = useStyles();

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const auth = useContext(AuthContext);
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        onSubmit: async ({ login, password }, formik) => {
            await auth.signIn({ login, password }, (_, err) => {
                if (!err) {
                    formik.setSubmitting(false);

                    history.push('/dashboard');

                    return;
                }

                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            });
        },
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    return (
        <>
            <Grid container className="fullScreen">
                <Grid container className='mainDiv'>
                    <Grid item md={8} xs={12} className={classes.leftSide}>
                        <Grid item xs={12} style={{
                            marginTop: '20px',
                        }}>
                            <img src={logoHori} alt="logo" className={classes.logo} />
                        </Grid>
                        <Grid item style={{ padding: '20px', textAlign: 'right' }}>
                            <Typography variant='h5' style={{
                                color: '#fff'
                            }}>
                                <i>Powered by MC2 Performance Organizacional</i>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12} style={{
                        '@media (min-width: 720px)': {
                            position: 'absolute',
                            zIndex: 1000
                        }
                    }}>
                        <Paper alevation={3} className={classes.formContainer} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '15px'
                        }}>
                            <form onSubmit={formik.handleSubmit} className={classes.form}>
                                <Typography variant="h5" style={{
                                    color: '#fff',
                                    textAlign: 'center',
                                    fontWeight: '700',
                                    fontSize: '2rem'
                                }}>
                                    LOGIN
                                </Typography>
                                <Input
                                    className={classes.input}
                                    style={{
                                        ...{
                                            padding: 20,
                                            backgroundColor: '#FFF',
                                            borderRadius: 5,
                                            fontSize: '1.2rem',
                                            fontWeight: '700'
                                        },
                                    }}
                                    // label="Login"
                                    textFieldProps={{
                                        placeholder: 'Username',
                                        name: 'login',
                                        onChange: formik.handleChange,
                                        value: formik.values.login,
                                        autoComplete: 'username',
                                        fullWidth: true,
                                        required: true,
                                    }}
                                />
                                <Input
                                    className={classes.input}
                                    style={{
                                        ...{
                                            padding: 20,
                                            backgroundColor: '#FFF',
                                            borderRadius: 5,
                                            fontSize: '1.2rem',
                                            fontWeight: '700'
                                        },
                                    }}
                                    // label="Password"
                                    textFieldProps={{
                                        placeholder: 'Password',
                                        name: 'password',
                                        onChange: formik.handleChange,
                                        value: formik.values.password,
                                        autoComplete: 'current-password',
                                        type: 'password',
                                        fullWidth: true,
                                        required: true,
                                    }}
                                />
                                <Button
                                    disabled={formik.isSubmitting}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    className={classes.whiteButton}
                                >
                                    {
                                        formik.isSubmitting ? (
                                            <Loader type="Oval" color="#0078D7" height={25} width={25} />
                                        ) : (
                                            <Typography variant="subtitle" style={{ fontSize: '1rem', display: 'flex', fontWeight: '700' }}>
                                                ENTRAR <ForwardIcon />
                                            </Typography>
                                        )}
                                </Button>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            {!!message.open && (
                <Alert
                    open={message.open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    severity={message.variant}
                    onClose={closeMessage}
                >
                    {message.text}
                </Alert>
            )}
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formContainer: {
        boxShadow: '0 0 5px #fff',
        // backgroundColor: 'rgba(12, 11, 45, .4)',
        backgroundColor: '#333',
        position: 'absolute',
        top: '25%',
        width: '100%',
        '@media (min-width: 900px)': {
            height: '100%',
            position: 'relative',
            top: 0
        }
    },
    logoContainer: {
        textAlign: 'center',
    },
    logo: {
        maxWidth: '65%',
        margin: 'auto',
    },
    form: {
        padding: theme.spacing(0, 3, 3, 3),
        width: '100%',
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    whiteButton: {
        borderColor: '#FFF',
        background: '#0d234d',
        padding: '15px',
        textAlign: 'center',
        color: '#FFF',
        '&:active': {
            borderColor: '#FFF',
            color: '#FFF',
        },
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        userSelect: 'none',
        backgroundSize: 'cover',
        opacity: '0.5',
        height: '100vh',
    }
}));
