const metrics = {
    drawerWidth: 260,

    margin: 15,
    marginSmall: 10,
    marginSmaller: 5,

    padding: 15,
    paddingSmall: 10,
    paddingSmaller: 5,
};

export default metrics;
