import React, { useState, useEffect, forwardRef, useImperativeHandle, useReducer } from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Checkbox,
    Button,
    Typography,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { grey } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';

import Input from './Input';
import Popup from './Popup';


import api from '../services/api';

const reduceDevices = (state, action) => {
    let newValues = [];
    switch (action.type) {
        case 'single':
            if (action.checked && !state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                newValues.push(action.id);
            } else if (!action.checked && state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                let index = state[action.deviceArray].indexOf(action.id);
                newValues.splice(index, 1);
            } else {
                newValues = state[action.deviceArray];
            }
            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'all':
            if (action.checked) {
                newValues = state[action.deviceArray];
                for (let current of action.allDevices) {
                    if (!newValues.includes(current.id)) {
                        newValues.push(current.id);
                    }
                }
            } else {
                const allIds = action.allDevices.map(el => el.id)
                newValues = state[action.deviceArray].filter(el => !allIds.includes(el));
            }

            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'load':
            console.log("loading")
            newValues = action.loading;
            console.log({ loading: newValues, array: action.deviceArray })
            return {
                ...state,
                [action.deviceArray]: newValues,
            };
        case 'reset':
            return { device_id: [], camera_id: [] };
        default: return state;
    }
};

const AccessPicker = forwardRef(({ xs = 12, md = 4, children }, ref) => {
    const classes = useStyles();

    const [cameras, setCameras] = useState([]);
    const [camerasSearch, setCamerasSearch] = useState('');
    const [camerasPage, setCamerasPage] = useState(0);
    const [camerasLimit, setCamerasLimit] = useState(10);
    const [camerasTotal, setCamerasTotal] = useState(0);
    const [openCamerasPopup, setOpenCamerasPopup] = useState(false);


    const [openCamerasInfoModal, setOpenCamerasInfoModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});


    const handleCloseCamerasInfo = () => {
        setOpenCamerasInfoModal(false);
        setSelectedGroup({});
    }

    const [deviceIds, dispatchDeviceIds] = useReducer(reduceDevices, { camera_id: [] });

    useImperativeHandle(ref, () => ({
        resetValues() {
            dispatchDeviceIds({ action: 'reset' });

            setCamerasSearch('');
            setCamerasPage(0);
            setCamerasLimit(10);
        },
        getValues() {
            return {
                camera_id: deviceIds.camera_id,
            };
        },
        load({  loadingCameras }) {
            dispatchDeviceIds({ type: 'load', deviceArray: 'camera_id', loading: loadingCameras });

        }
    }));


    useEffect(() => {
        const findCameras = async () => {
            const result = await api.get('/device_group/get_unrelated_cameras', {
                params: {
                    search: camerasSearch,
                    page: camerasPage,
                    limit: camerasLimit,

                }
            });

            const { cameras, count } = result.data;

            setCameras(cameras);
            setCamerasTotal(count);

            // console.log({ filteredCameras: cameras });
        };

        findCameras();
    }, [camerasLimit, camerasPage, camerasSearch]);

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'camera':
                setCamerasPage(newPage);
                break;
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        switch (quantityOf) {
            case 'camera':
                setCamerasLimit(parseInt(target.value, 10));
                setCamerasPage(0);
                break;

            default:
                console.log('Quantidade inválida');
        }
    };

    const checkSelection = (arr, fetchedArray) => {
        let checked = false;
        if (arr === 'group') {
            // let groupIds = groups.map(el => el.id);
            // checked = groupIds.every(el => {
            //     return selectedGroups.includes(el)
            // });
        } else {
            if (!deviceIds[arr].length) return checked;

            let checkingArray = fetchedArray.map(el => el.id);
            checked = checkingArray.every(el => {
                return deviceIds[arr].includes(el)
            });
        }

        return checked;
    }

    return (
        <>
            <Grid container justify="left" spacing={3}>
                <Grid item xs={xs} md={md}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        onClick={() => setOpenCamerasPopup(true)}
                    >
                        Câmeras {`(${deviceIds.camera_id.length})`}
                    </Button>
                </Grid>
                {
                    children
                }
            </Grid>
            <Popup
                openPopup={openCamerasPopup}
                setOpenPopup={setOpenCamerasPopup}
                title="Câmeras"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setCamerasSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="camera list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Local</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivo</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllCameras"
                                                color="primary"
                                                // checked={selectedCameras.length === cameras.length}
                                                checked={checkSelection('camera_id', cameras)}
                                                onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                    type: 'all',
                                                    deviceArray: 'camera_id',
                                                    allDevices: cameras,
                                                    checked,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!cameras && cameras.map(camera => (
                                        <TableRow key={camera.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {camera.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {(camera.type === 'entry') ? 'Entrada' : (camera.type === 'internal') ? 'Interna' : (camera.type === 'exit') ? 'Saída' : 'Desconhecido'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.place_cameras?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.device?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addCamera"
                                                    color="primary"
                                                    checked={deviceIds.camera_id.includes(camera.id)}
                                                    onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                        type: 'single',
                                                        id: camera.id,
                                                        deviceArray: 'camera_id',
                                                        checked,
                                                    })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={camerasTotal}
                            rowsPerPage={camerasLimit}
                            page={camerasPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'camera')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'camera')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openCamerasInfoModal}
                setOpenPopup={setOpenCamerasInfoModal}
                title="Câmeras"
                onClose={handleCloseCamerasInfo}
                width="45%"
            >
                <Grid container>
                    {
                        selectedGroup?.cameras?.map(camera => (
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        fontWeight: 700,
                                        color: "#696969"
                                    }}
                                >
                                    &bull; {camera.name} ({camera.device?.name})
                                </Typography>
                            </Grid>
                        ))
                    }
                </Grid>
            </Popup>
        </>
    );
});

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    tableRow: {
        transition: '0.2s',
        '&:hover': {
            backgroundColor: grey[300],
        }
    },
}));

export default AccessPicker;
