import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const keywords = [
    {
        keyword: '#access.id#',
        type: 'Camera/DHI',
        description: 'ID do log de acesso (Ex.: 1)',
    },
    {
        keyword: '#access.user.id#',
        type: 'Camera/DHI',
        description: 'ID da pessoa (Ex: 12)',
    },
    {
        keyword: '#access.user.name#',
        type: 'Camera/DHI',
        description: 'Nome da pessoa (Ex.: José Ferreira)',
    },
    {
        keyword: '#access.place.id#',
        type: 'Camera/DHI',
        description: 'ID do local em que a pessoa foi vista (Ex.: 1)',
    },
    {
        keyword: '#access.place.name#',
        type: 'Camera/DHI',
        description: 'Nome do local em que a pessoa foi vista (Ex.: Corredor A)',
    },
    {
        keyword: '#access.date#',
        type: 'Camera/DHI',
        description: 'Dia em que pessoa foi vista (Ex.: 12/01/2022)',
    },
    {
        keyword: '#access.time#',
        type: 'Camera/DHI',
        description: 'Hora em que pessoa foi vista (Ex.: 12:30)',
    },
    {
        keyword: '#access.datetime#',
        type: 'Camera/DHI',
        description: 'Dia e hora em que a pessoa foi vista (Ex.: 12/01/2022 12:30)',
    },
    {
        keyword: '#access.device_camera.id#',
        type: 'Camera/DHI',
        description: 'ID do dispositivo ou camera em que a pessoa foi vista (Ex.: 1)',
    },
    {
        keyword: '#access.device_camera.name#',
        type: 'Camera/DHI',
        description: 'Nome do dispositivo ou camera em que a pessoa foi vista (Ex.: Camera 01)',
    },
    {
        keyword: '#access.status#',
        type: 'Camera/DHI',
        description: 'Status do acesso (Permitido ou Negado)',
    },
    {
        keyword: '#access.user.document#',
        type: 'Camera/DHI',
        description: 'Tipo e número do documento da pessoa, se cadastrada',
    },
    {
        keyword: '#access.user.org.id#',
        type: 'Camera/DHI',
        description: 'ID da organização da pessoa, se cadastrada',
    },
    {
        keyword: '#access.user.org.name#',
        type: 'Camera/DHI',
        description: 'Nome da organização da pessoa, se cadastrada',
    },
    {
        keyword: '#access.user.occupation#',
        type: 'Camera/DHI',
        description: 'Ocupação do cadastro da pessoa',
    },
    {
        keyword: '#access.user.notes#',
        type: 'Camera/DHI',
        description: 'Observações no cadastro da pessoa',
    },
    {
        keyword: '#access.user.issuing_agency#',
        type: 'Camera/DHI',
        description: 'Orgão emissor do documento da pessoa',
    },
    {
        keyword: '#access.user.email#',
        type: 'Camera/DHI',
        description: 'Email da pessoa cadastrada',
    },
    {
        keyword: '#access.user.phone#',
        type: 'Camera/DHI',
        description: 'Número da pessoa cadastrada',
    },
    {
        keyword: '#access.user.active_register#',
        type: 'Camera/DHI',
        description: 'Se o cadastro da pessoa está ativo ou não (Ex.: Cadastro Ativo)',
    },
    {
        keyword: '#access.user.person_type.id#',
        type: 'Camera/DHI',
        description: 'Nome do Tipo de Pessoa, se cadastrada (Ex.: 1)',
    },
    {
        keyword: '#access.user.person_type.name#',
        type: 'Camera/DHI',
        description: 'Nome do Tipo de Pessoa, se cadastrada (Ex.: Colaborador)',
    },
    {
        keyword: '#access.age#',
        type: 'Camera/DHI',
        description: 'Idade identicada no acesso (Ex.: 21)',
    },
    {
        keyword: '#access.user.age#',
        type: 'Camera/DHI',
        description: 'Idade da pessoa em seu cadastro (Ex.: 21)',
    },
    {
        keyword: '#access.user_or_access.age#',
        type: 'Camera/DHI',
        description: 'Idade da pessoa em seu cadastro, caso contrário, a do acesso (Ex.: 21)',
    },
    {
        keyword: '#access.sex#',
        type: 'Camera/DHI',
        description: 'Sexo da pessoa identificado no acesso (Feminino / Masculino / Desconhecido)',
    },
    {
        keyword: '#access.user.sex#',
        type: 'Camera/DHI',
        description: 'Sexo da pessoa em seu cadastro (Feminino / Masculino / Desconhecido)',
    },
    {
        keyword: '#access.user_or_access.sex#',
        type: 'Camera/DHI',
        description: 'Sexo da pessoa em seu cadastro, caso contrário, o do acesso (Feminino / Masculino / Desconhecido)',
    },
    {
        keyword: '#access.emotion#',
        type: 'Camera',
        description: 'Emoção apresentada na detecção (Ex.: Surpesa)',
    },
    {
        keyword: '#access.glasses#',
        type: 'Camera',
        description: 'Se a pessoa está usando óculos e o tipo (Ex.: Óculos de sol)',
    },
    {
        keyword: '#access.beard#',
        type: 'Camera',
        description: 'Se a pessoa tem barba (Ex.: Sem barba)',
    },
    {
        keyword: '#access.mouth#',
        type: 'Camera',
        description: 'Se a pessoa está com a boca aberta ou não (Ex.: Boca aberta)',
    },
    {
        keyword: '#access.eye#',
        type: 'Camera',
        description: 'Se a pessoa está com os olhos abertos ou não (Ex.: Olhos fechados)',
    },
    {
        keyword: '#access.temperature#',
        type: 'DHI',
        description: 'Temperatura detectada (Ex.: 37°C / Não definida)',
    },
    {
        keyword: '#access.user.schedule#',
        type: 'Camera/DHI',
        description: 'Rotina do dia da pessoa (Ex.: 1º : 08:00 - 09:20 A)',
    },
    {
        keyword: '#access.device_camera.gen_data#',
        type: 'Camera/DHI',
        description: 'Dados do dispositivo (Validação remota, autorizações e etc)',
    },
    {
        keyword: '#access.action#',
        type: 'Camera/DHI',
        description: 'Tipo de ação (Ex.: Entrada, Saída, Interna)',
    },
    {
        keyword: '#access.detail#',
        type: 'Camera/DHI',
        description: 'Detalhes do acesso (Dia/Local Não Autorizado, Entrada Repetida e etc)',
    },
    {
        keyword: '#access.method#',
        type: 'Camera/DHI',
        description: 'Método de Acesso (Facial ou Cartão)',
    },
    {
        keyword: '#access.credential#',
        type: 'DHI',
        description: 'Número da credencial usada no acesso',
    },
    {
        keyword: '#access.similarity#',
        type: 'Camera/DHI',
        description: 'Em caso de um reconhecimento, o percentual de similaridade com uma pessoa cadastrada (Ex.: 76%)',
    },
]

export default function AccessKeywords() {
    const classes = useStyles();

    return (
        <Grid item xs={12} style={{ borderRadius: '3px', padding: '5px' }}>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="notify list">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>Campo</TableCell>
                            <TableCell className={classes.tableHead}>Tipo</TableCell>
                            <TableCell className={classes.tableHead}>Descrição</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            keywords.map((keyword, i) => (
                                <TableRow>
                                    <TableCell className={classes.tableBody} component="th" scope="row">
                                        {
                                            keyword.keyword
                                        }
                                    </TableCell>
                                    <TableCell className={classes.tableBody}>
                                        {
                                            keyword.type
                                        }
                                    </TableCell>
                                    <TableCell className={classes.tableBody}>
                                        {
                                            keyword.description
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    tableRow: {
        transition: '0.2s',
        '&:hover': {
            backgroundColor: grey[300],
        }
    },
}));
