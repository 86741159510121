import React, { useRef, useEffect, useState } from "react";
import {
  Timeline as Vis,
  TimelineEventPropertiesResult,
} from "vis-timeline/standalone"

import moment from "moment";
import api from "../../services/api";

function Timeline({
  filter,
  filteredItems,
  setUrlVideoSelected,
  urlVideoSelected,
  pullZone,
  initialDate,
  finallyDate,
  setHandled,
  setHandledDelete,
  setMessage,
  setLoadingVideo,
}) {
  const containerRef = useRef(null);
  const timelineRef = useRef(null);

  console.log({ initialDate, finallyDate })

  var modifiedInitialDate = new Date(initialDate);
  var modifiedFinallyDate = new Date(finallyDate);


  var options = {
    stack: false,
    multiselect: true,
    min: modifiedInitialDate,
    max: modifiedFinallyDate,
    multiselectPerGroup: true,
    zoomMin: 1000 * 2,
    zoomMax: 1000 * 60 * 60 * 24 * 7,
  };

  async function handleItemClick(properties) {
    console.log({ properties });

    if (!properties.item) return;

    setLoadingVideo(true);
    try {
      const data = JSON.parse(properties.item);

      console.log({
        url: data.url,
        id: data.id,
      });

      await api.get(`/recording/view/${data.id}`);

      setUrlVideoSelected(data.url);
      setHandled(data.id);
      setHandledDelete(data.id);
    } catch (err) {
      setMessage({
        open: true,
        variant: 'error',
        text: err.error ?? "Não foi possível visualizar o video",
      });
    }
    setLoadingVideo(false);
  }


  const initTimeline = () => {
    // if (!containerRef.current) return;


    const groupsName = [];
    const items = filteredItems?.map((item) => {
      const groupId = item?.camera?.name ?? "unknown";
      if (!groupsName.find((group) => group.id === groupId)) {
        groupsName.push({ id: groupId, content: groupId });
      }

      return {
        id: JSON.stringify({
          url: item.view_url,
          id: item.id,
        }),
        group: groupId,
        content: `${item.filename} - ${item.name}`,
        start: moment(item.initial_date).format("YYYY-MM-DD HH:mm:ss:SS"),
        end: moment(item.finally_date).format("YYYY-MM-DD HH:mm:ss:SS"),
      };
    });
    if (!!items[0]?.id && urlVideoSelected === null) {
      const firstVideo = JSON.parse(items[0].id);
      setUrlVideoSelected(firstVideo.url);
    }

    timelineRef.current.setGroups(groupsName);
    timelineRef.current.setItems(items);
    const timeline = timelineRef.current;

    timeline.setOptions(options);
    timeline.on("rangechange", function (properties) {
      console.log({ properties })
      window.scrollTo({
        top: window.pageYOffset + window.innerHeight,
      });
    });

    timeline.on("click", handleItemClick);

  };


  useEffect(() => {
    if (!filter) return;
    if (!timelineRef.current) {
      timelineRef.current = new Vis(containerRef.current);

    }
    initTimeline()

  }, [containerRef, filter, filteredItems]);

  return (
    <>
      <div style={{
        width: '100%',
        padding: '10px',
      }} ref={containerRef} />
    </>
  );
}

export default Timeline;