import React,
    {
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SchoolIcon from '@material-ui/icons/School';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
    Grid,
    Paper,
    Button,
} from "@material-ui/core";
import { Formik } from 'formik';

import Autocomplete from "./Autocomplete";
import Select from "./Select.js";
import Input from './Input';
import Alert from './Alert';

import api from "../services/api";
import { grey } from '@material-ui/core/colors';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            style={{ marginTop: 10, }}
            {...other}
        >
            <Paper elevation={3}>
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </Paper>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const SchoolSettings = () => {

    const [schoolSettings, setSchoolSettings] = useState({
        active: false,
        student_person_type_id: null,
        student_register_custom_input_id: null,
        student_unit_custom_input_id: null,
        system_presence_route: "",
    });

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    useEffect(() => {
        const loadSettings = async () => {
            try {
                const result = await api.get("/school_settings/view");

                const { data } = result;

                console.log({data});

                setSchoolSettings(data);
            } catch (err) {
                console.log(err)
                setMessage({
                    open: true,
                    variant: "error",
                    text: err.error,
                });
            }
        };

        loadSettings();
    }, []);

    const [customInputs, setCustomInputs] = useState([]);
    const [customInputsSearch, setCustomInputsSearch] = useState("");

    useEffect(() => {
        const findInputs = async () => {
            try {
                const result = await api.get('/custom_input/list', {
                    params: {
                        search: customInputsSearch,
                        page: 0,
                        limit: 30,
                    },
                });

                const { custom_inputs } = result.data;

                setCustomInputs(custom_inputs);
            }
            catch (err) {
                console.log(err);
            }
        };

        findInputs();
    }, [customInputsSearch]);


    const [personTypeSearch, setPersonTypeSearch] = useState();
    const [personTypes, setPersonTypes] = useState([]);

    useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/person_type/list', {
                    params: {
                        search: personTypeSearch,
                        show_exceptions: false,
                        limit: 30,
                    }
                });

                const { personTypes } = result.data;

                setPersonTypes(personTypes);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, [personTypeSearch]);

    const handleCIChange = (key, value, setFieldValue) => {
        setFieldValue(key, value);
        setCustomInputsSearch("");
    }

    const saveSettings = async (values) => {
        console.log(values)
        try {
            const data = {
                ...values,
                student_person_type_id: values.student_person_type_id.id,
                student_register_custom_input_id: values.student_register_custom_input_id.id,
                student_unit_custom_input_id: values.student_unit_custom_input_id.id,
            };

            await api.post("/school_settings/modify", data);

            setMessage({
                open: true,
                variant: "success",
                text: "Configurações salvas com sucesso",
            });
        } catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: "error",
                text: err.error,
            });
        }
    };

    return (
        <>
            <Formik
                initialValues={schoolSettings}
                onSubmit={saveSettings}
                enableReinitialize={true}
            >
                {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                    <>
                        <form id="school-settings" onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            color: grey[600],
                                            fontWeight: 700,
                                        }}
                                    >
                                        Configurações Gerir
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        label="Ativo"
                                        options={[
                                            { label: 'SIM', value: true },
                                            { label: 'NÃO', value: false },
                                        ]}
                                        textFieldProps={{
                                            name: 'active',
                                            onChange: handleChange,
                                            fullWidth: true,
                                            value: values.active,
                                            required: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        label="Tipo de Pessoa do Estudante"
                                        options={personTypes}
                                        getOptionLabel={({name}) => name || ''}
                                        onChange={(_, value) => setFieldValue("student_person_type_id", value)}
                                        getOptionSelected={(option, value) => (option.name === value.name)}
                                        onInputChange={(_, value) => setPersonTypeSearch(value)}
                                        value={values.student_person_type_id}
                                        fullWidth={true}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        label="Campo de Matrícula"
                                        options={customInputs}
                                        getOptionLabel={({name}) => name || ''}
                                        onChange={(_, value) => handleCIChange("student_register_custom_input_id", value, setFieldValue)}
                                        getOptionSelected={(option, value) => (option.name === value.name)}
                                        onInputChange={(_, value) => setCustomInputsSearch(value)}
                                        value={values.student_register_custom_input_id}
                                        fullWidth={true}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        label="Campo de Unidade"
                                        options={customInputs}
                                        getOptionLabel={({name}) => name || ''}
                                        onChange={(_, value) => handleCIChange("student_unit_custom_input_id", value, setFieldValue)}
                                        getOptionSelected={(option, value) => (option.name === value.name)}
                                        onInputChange={(_, value) => setCustomInputsSearch(value)}
                                        value={values.student_unit_custom_input_id}
                                        fullWidth={true}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        label="Rota de Presença"
                                        textFieldProps={{
                                            name: 'system_presence_route',
                                            placeholder: 'Rota de Presença',
                                            onChange: handleChange,
                                            value: values.system_presence_route,
                                            fullWidth: true,
                                            required: true,
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        form="school-settings"
                                        color="primary"
                                        style={{ width: 250 }}
                                    >
                                        {isSubmitting ? 'Salvando...' : 'Salvar'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </>
                )}
            </Formik>
            {!!message.open && (
                <Alert
                    open={message.open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    severity={message.variant}
                    onClose={closeMessage}
                >
                    {message.text}
                </Alert>
            )}
        </>
    );
}

const UserInfoItem = ({ label, value, xs }) => (
    <Grid item xs={xs}>
        <Typography
            variant="subtitle1"
            style={{
                color: grey[600],
                fontWeight: 700,
                fontSize: "1.1rem"
            }}
        >
            {label}
        </Typography>
        <Typography
            variant="subtitle2"
            style={{
                fontSize: "1rem"
            }}
        >
            {value}
        </Typography>
    </Grid>
);

const AdminSettings = () => {
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [search, setSearch] = useState("");
    const [users, setUsers] = useState([]);
    const [searchingRole, setSeachingRole] = useState("employee");
    const [selectedUser, setSelectedUser] = useState(null);

    const resetData = () => {
        setSearch("");
        setSelectedUser(null);
    };

    const queryUsers = useCallback(async () => {
        try {
            const result = await api.get("/user/list_by_role", {
                params: {
                    search: search,
                    role: searchingRole,
                },
            });

            const { users: usersByRole } = result.data;

            setUsers(usersByRole);
        } catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: "error",
                text: err.error,
            });
        }
    }, [search, searchingRole]);

    useEffect(() => {
        queryUsers();
    }, [queryUsers]);

    const changeCurrentPermissionFilter = ({ target: { value } }) => {
        setSeachingRole(value);
    };

    const togglePrivileges = async ({ id, privilege }) => {
        try {
            await api.post("/user/toggle_privileges", {
                user_id: id,
                privileges: privilege,
            });

            setMessage({
                open: true,
                variant: "success",
                text: "Permissões modificadas com sucesso",
            });

            await queryUsers();
            resetData();
        } catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: "error",
                text: err.error,
            });
        }
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Autocomplete
                        label="Pessoas"
                        options={users}
                        getOptionLabel={({name}) => name || ''}
                        onChange={(_, value) => setSelectedUser(value)}
                        getOptionSelected={(option, value) => (option.name === value.name)}
                        onInputChange={(_, value) => setSearch(value)}
                        value={selectedUser}
                        fullWidth={true}
                        required={true}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        label="Permissão Atual"
                        options={[
                            { label: 'Admin', value: 'admin' },
                            { label: 'Pessoa Normal', value: 'employee' },
                        ]}
                        textFieldProps={{
                            onChange: changeCurrentPermissionFilter,
                            fullWidth: true,
                            value: searchingRole,
                            required: true,
                        }}
                    />
                </Grid>
                {
                    (!!selectedUser) && (
                        <>
                            <UserInfoItem
                                xs={6}
                                label="ID"
                                value={selectedUser?.id}
                            />
                            <UserInfoItem
                                xs={6}
                                label="Nome"
                                value={selectedUser?.name}
                            />
                            <UserInfoItem
                                xs={6}
                                label="Permissões"
                                value={selectedUser?.role === "admin" ? "Administrador" : "Comum"}
                            />
                            <UserInfoItem
                                xs={6}
                                label="Login"
                                value={selectedUser?.login ?? "-"}
                            />
                            <UserInfoItem
                                xs={12}
                                label="Observações"
                                value={selectedUser?.notes ?? "-"}
                            />
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems="center" justify="center">
                                    <Grid item xs={7}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth={true}
                                            disabled={selectedUser?.role === "admin"}
                                            onClick={() => togglePrivileges({
                                                id: selectedUser.id,
                                                privilege: "admin"
                                            })}
                                        >
                                            Tornar em Administrador
                                        </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth={true}
                                            disabled={selectedUser?.role === "employee"}
                                            onClick={() => togglePrivileges({
                                                id: selectedUser.id,
                                                privilege: "employee"
                                            })}
                                        >
                                            Tornar em Comum
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )
                }
            </Grid>
            {!!message.open && (
                <Alert
                    open={message.open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    severity={message.variant}
                    onClose={closeMessage}
                >
                    {message.text}
                </Alert>
            )}
        </>
    );
};

export default function SettingsModal() {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="settings"
                >
                    <Tab label="Gerir" icon={<SchoolIcon />} {...a11yProps(0)} />
                    <Tab label="Administradores" icon={<SupervisorAccountIcon />} {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <SchoolSettings />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AdminSettings />
            </TabPanel>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));
