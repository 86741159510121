import React, { useState, useEffect } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Chip,
    Grid,
    Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { grey, green, red } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import DateTimePicker from '../../components/DateTimePicker';

import api from '../../services/api';

import date from '../../utils/date';
import number from '../../utils/number';

export default function AccessLogList() {
    const classes = useStyles();

    const [accessLog, setAccessLog] = useState([]);
    const [total, setTotal] = useState(0);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get('/access_log/list', {
                    params: {
                        type: 'user_access',
                        startTime,
                        endTime,
                        limit,
                        page,
                    },
                });

                const { count, access_log } = result.data;

                if (total > count) setPage(0);

                setAccessLog(access_log);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();

        const load = setInterval(loadData, 10000);

        return () => {
            clearInterval(load);
        };
    }, [page, limit, startTime, endTime, total]);

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const accessStatus = ({ type, allowed }) => {
        switch (type) {
            case 'entry':
                return `Entrada ${allowed ? 'permitida' : 'negada'}`;
            default:
                return `Saída ${allowed ? 'permitida' : 'negada'}`;
        }
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Acessos
                            <Typography variant="overline" className={classes.smallTitle}>
                                (facial)
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} className={classes.filter}>
                        <DateTimePicker
                            className={classes.dateTimeInput}
                            format="DD/MM/YYYY HH:mm"
                            value={startTime}
                            placeholder="Período inicial"
                            onChange={date => setStartTime(date && date.format())}
                            cancelLabel="cancelar"
                        />
                        <DateTimePicker
                            className={classes.dateTimeInput}
                            format="DD/MM/YYYY HH:mm"
                            value={endTime}
                            placeholder="Período final"
                            onChange={date => setEndTime(date && date.format())}
                            cancelLabel="cancelar"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="access history">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Código</TableCell>
                                    <TableCell className={classes.tableHead}>Nome</TableCell>
                                    <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                    <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                    <TableCell className={classes.tableHead}>Máscara</TableCell>
                                    <TableCell className={classes.tableHead}>Temperatura</TableCell>
                                    <TableCell className={classes.tableHead}>Horário</TableCell>
                                    <TableCell className={classes.tableHead}>Acesso</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!accessLog && accessLog.map(access => (
                                    <TableRow key={access.id}>
                                        <TableCell className={classes.tableBody} component="th" scope="row">
                                            {access.id}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access?.user
                                                ? access.user.name
                                                : <b>Não identificado</b>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.user.doc_type && access.user?.doc_type === 'ic' ? 'RG' :
                                                access.user?.doc_type === 'passport' ? 'Passaporte' :
                                                    access.user?.doc_type === 'other' ? 'Outro' :
                                                        access.user?.doc_type === 'unknown' ? 'CPF' :
                                                            'Não identificado'}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.user.doc_number ? access.user.doc_number : "Não identificado"}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.attribute_mask === 2 ? 'Sim' : 'Não'}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {number.toFloat(access.current_temperature, 1)}°C
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {date(access.event_time).toLocaleFormat()}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.allowed_access ? (
                                                <Chip
                                                    icon={<CheckIcon className={classes.authorizationIcon} />}
                                                    label={accessStatus({
                                                        type: access.action_type,
                                                        allowed: access.allowed_access,
                                                    })}
                                                    size="small"
                                                    className={classes.authorizedAccess}
                                                />
                                            ) : (
                                                <Chip
                                                    icon={<CloseIcon className={classes.authorizationIcon} />}
                                                    label={accessStatus({
                                                        type: access.action_type,
                                                        allowed: access.allowed_access,
                                                    })}
                                                    size="small"
                                                    className={classes.unauthorizedAccess}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={total}
                        rowsPerPage={limit}
                        page={page}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                        labelRowsPerPage="Registros por página"
                        onChangePage={changePage}
                        onChangeRowsPerPage={quantityPerPage}
                    />
                </Grid>
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    smallTitle: {
        color: grey[600],
        marginLeft: theme.spacing(1),
        fontSize: 16,
    },
    filter: {
        margin: theme.spacing(0, 0, 3, 0),
    },
    dateTimeInput: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    authorizedAccess: {
        backgroundColor: green[700],
        color: '#FFF',
    },
    unauthorizedAccess: {
        backgroundColor: red[700],
        color: '#FFF',
    },
    authorizationIcon: {
        color: '#FFF',
    },
}));
