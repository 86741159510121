import React, {useState, useEffect} from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Input from '../../components/Input';
import DateTimePicker from '../../components/DateTimePicker';
import Alert from '../../components/Alert';

import api from '../../services/api';

import date from '../../utils/date';

export default function Location() {
    const classes = useStyles();

    const [place, setPlace] = useState([]);
    const [search, setSearch] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [customMessage, setCustomMessage] = useState([{message: ""}]);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });



    useEffect(() => {
        const loadData = async () => {
            try {
                let formated = search;
                formated = formated.replace(/\./g, '');
                formated = formated.replace(/-/g, '');
                let sTime = (date(startTime).isValidDate()) ? startTime : null;
                let eTime = (date(endTime).isValidDate()) ? endTime : null;
                const result = await api.get('/location/of', {
                    params: {
                        search: formated,
                        startTime: sTime,
                        endTime: eTime
                    },
                });

                const { location } = result.data;

                if (Array.isArray(location)) {
                    setPlace(location);
                    setCustomMessage(null)
                } else {
                    setPlace([location]);
                    setCustomMessage(null)
                }
            }
            catch (err) {
                setPlace([])
                // setMessage({
                //     open: true,
                //     variant: 'error',
                //     text: err.error,
                //});
                setCustomMessage([{message: err.error}])
            }
        };

        loadData();
    }, [search, startTime, endTime]);

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const getGateAccessStatus = (status) => {
        let new_status;

        // https://demo.mdacesso.com.br/itk/swagger/ui/index#!/Access32Log/AccessLog_Get
        switch (status) {
            case null:
                new_status = null;
                break;
            case 1:
                new_status = 'Permitido'; // AccessGranted
                break;
            case 8:
                new_status = 'Acesso não completado'; // AccessNotCompleted
                break;
            case 9:
                new_status = '[Negado] A credencial do usuário não existe'; // CredentialNotExists
            break;
            case 10:
                new_status = "Acesso completado"; // AccessCompleted
                break;
            case 13:
                new_status = 'Credencial expirada'; // CredentialExpired
                break;
            case 22:
                new_status = '[Negado] Horário não registrado'; // ShiftNotRegistered
                break;
            case 23:
                new_status = 'Autorizado por administrador'; // AccessCompletedByAuthorization
                break;
            case 29:
                new_status = '[Negado] A credencial não era associada com o usuário'; // CredentialNotAssociatedToPerson
                break;
            case 39:
                new_status = '[Negado] Digital não registrada'; // FingerprintNotRegistered
                break;
            case 40:
                new_status = '[Negado] Digital não reconhecida'; // FingerprintDoesntMatch
                break;
            case 60:
                new_status = '[Negado] Senha Invalida'; // InvalidPassword
                break;
            default:
                new_status = 'Código ' + status;
        }

        return new_status;
    };

    const getFacialAccessStatus = (action, allowed) => {
        let new_status;

        if (allowed) {
            new_status = (action === 'entry') ? "[Permitido] Entrou" : "[Permitido] Saiu";
        } else {
            new_status = "[Acesso Negado]";
        }
        
        return new_status;
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Localização
                        </Typography>
                    </Grid>
                    <Grid container item className={classes.filter} xs={12}>
                        <Input
                            className={classes.dateTimeInput}
                            icon={<SearchIcon style={{color: grey[400]}} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({target}) => setSearch(target.value),
                            }}
                        />
                        <DateTimePicker
                            className={classes.dateTimeInput}
                            format="DD/MM/YYYY HH:mm"
                            value={startTime}
                            placeholder="Período inicial"
                            onChange={date => setStartTime(date && date.format())}
                            cancelLabel="cancelar"
                        />
                        <DateTimePicker
                            className={classes.dateTimeInput}
                            format="DD/MM/YYYY HH:mm"
                            value={endTime}
                            placeholder="Período final"
                            onChange={date => setEndTime(date && date.format())}
                            cancelLabel="cancelar"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivo</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Localização</TableCell>
                                        <TableCell className={classes.tableHead}>Ação</TableCell>
                                        <TableCell className={classes.tableHead}>Dia/Hora</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!place && place.map(current => (
                                        <TableRow key={current.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {current.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.device_name}
                                            </TableCell>

                                            <TableCell className={classes.tableBody}>
                                                {
                                                    (current.type)
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.device_description}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    (current.type === 'gate') ? getGateAccessStatus(current.action) : getFacialAccessStatus(current.action, current.allowed)
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {date(current.time).toLocaleFormat()}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {!!customMessage && customMessage.map(ms => (
                                        <TableRow>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {ms.message}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    filter: {
        margin: theme.spacing(0, 0, 3, 0),
    },
    dateTimeInput: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
}));

