import React from 'react';
import {
    Slide,
    Dialog,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
 } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {grey} from '@material-ui/core/colors';

import {colors} from '../assets/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({title, open, onClose, children}) {
    const classes = useStyles();

    return (
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.container}>
                {children}
            </div>
        </Dialog>
    );
};

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        backgroundColor: colors.primary,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        height: '100vh',
        backgroundColor: grey[900],
        padding: theme.spacing(5),
    },
}));
