import React, { useState} from 'react';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Toolbar, Paper, Button, Grid, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Popup from '../../components/Popup';

import api from '../../services/api';

export default function HelpCreate() {
    const classes = useStyles();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });
    const [title, setTitle] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [keywords, setKeywords] = useState([]);
    const [newKeyword, setNewKeyWord] = useState('');
    const [openKeywordPopup, setOpenKeywordPopup] = useState(false);

    const onEditorStateChange = (newState) => {
        setEditorState(newState);
    };

    const savePost = async () => {
        try {
            setIsSubmitting(true);
            if (!title) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: 'O nome da postagem é obrigatório',
                });
                setIsSubmitting(false)
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
                return;
            }
            const data = {
                title: title,
                content: convertToRaw(editorState.getCurrentContent()),
                keywords: keywords
            };
            const result = await api.post('/help_feed/create', data);
            const { helpFeed } = result.data;

            setTitle('');
            setEditorState(EditorState.createEmpty());
            setKeywords([]);
            setIsSubmitting(false);
            setMessage({
                open: true,
                variant: 'success',
                text: `Postagem "${helpFeed.title}" criada com sucesso!`,
            });
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const handleInsertKeywords = (word) => {
        const currentKeywords = Object.assign([], keywords);
        currentKeywords.push(word);
        setKeywords(currentKeywords);
        setNewKeyWord('');
    };

    const removeKeyword = (index) => {
        const currentKeywords = Object.assign([], keywords);
        currentKeywords.splice(index, 1);
        setKeywords(currentKeywords);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Criar Postagem
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper alevation={3}>
                            <Grid item className={classes.form}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography
                                            style={{
                                                fontWeight: 'bold',
                                                color: '#696969'
                                            }}
                                        >
                                            Nome da Postagem
                                        </Typography>
                                        <Grid item
                                            style={{
                                                marginBottom: '0.5%',
                                                width: '50%'
                                            }}
                                        >
                                            <Input
                                                textFieldProps={{
                                                    name: 'title',
                                                    onChange: ({ target }) => setTitle(target.value),
                                                    value: title,
                                                    fullWidth: true,
                                                    required: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item
                                            style={{
                                                marginBottom: '0.5%',
                                                width: '50%',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Button
                                                onClick={() => setOpenKeywordPopup(true)}
                                                variant='contained'
                                                color='primary'
                                            >
                                                Adcionar palavras-chave
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ minHeight: '400px', paddingBottom: '50px' }}>
                                        <Editor 
                                          editorState={editorState}
                                          config={{
                                            placeholder: 'EDITAR AQUIII AAAAAAAAAAA',
                                            id: "body_editor"
                                          }}
                                          
                                          editorClassName={classes.editor}
                                          toolbarClassName={classes.editorToolbar}
                                          wrapperClassName={classes.editorWrapper}
                                          onEditorStateChange={onEditorStateChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
                                        <Button
                                            disabled={isSubmitting}
                                            variant="contained"
                                            color="primary"
                                            endIcon={<SaveIcon />}
                                            onClick={() => savePost()}
                                        >
                                            {isSubmitting ? 'Salvando...' : 'Salvar'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Popup 
                    title='Inserir palavras chave'
                    openPopup={openKeywordPopup}
                    setOpenPopup={setOpenKeywordPopup}
                    width='35%'
                >
                    <Grid container direction='row' style={{ width: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <Grid item xs={6} style={{ marginRight: '5px' }}>
                                <Input 
                                    textFieldProps={{
                                        placeholder: 'Escreva aqui a nova palavra chave',
                                        onChange: ({ target }) => setNewKeyWord(target.value),
                                        value: newKeyword,
                                        fullWidth: true,
                                        required: true,
                                    }}
                                />
                            </Grid>
                            <Button
                                onClick={() => handleInsertKeywords(newKeyword)}
                                variant='contained'
                                color='primary'
                            >
                                <AddIcon />
                            </Button>
                        </Grid>
                        <Grid container xs={12} className={classes.keywordsContainer}>
                            {
                                keywords.map((word, index) => {
                                    return (
                                        <Grid key={index} item className={classes.keyword}>
                                            <Typography className={classes.keywordTitle}>{word}</Typography>
                                            <button className={classes.keywordButton} onClick={() => removeKeyword(index)}>
                                                <CloseIcon />
                                            </button>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                </Popup>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    editor: {
        height: '100vh',
        padding: '10px',
    },
    editorToolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid #cdcdcd'
    },
    editorWrapper: {
        border: '1px solid #cdcdcd',
        borderRadius: '5px',
        height: '100%',
    },
    keywordsContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '15px 0',
    },
    keyword: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#cdcdcd',
        borderRadius: '5px',
        padding: '0 5px',
        boxSizing: 'border-box',
        margin: '0 0 5px 5px'
    },
    keywordTitle: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        color: grey[700],
        textTransform: 'uppercase'
    },
    keywordButton: {
        border: 'none',
        background: 'transparent',
        padding: '0',
        margin: '0',
        color: '#fff',
        cursor: 'pointer'
    }
}));

