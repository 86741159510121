import React, { useState } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Typography,
    Button,
} from '@material-ui/core';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { makeStyles } from '@material-ui/core/styles';
import { grey, red, green } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Popup from '../../components/Popup';
import DateTimePicker from '../../components/DateTimePicker';
import InputSearch from '../../components/InputSearch';

import api from '../../services/api';

import date from '../../utils/date';

export default function DeviceRemoteControl() {
    const classes = useStyles();

    const [controlLogs, setControlLogs] = useState(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const [openControlDetails, setOpenControlDetails] = useState(false);

    const [selectedLog, setSelectedLog] = useState();

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const handleViewDetails = (log) => {
        setOpenControlDetails(true);
        setSelectedLog(log);
    };

    function updateRequest({ search }) {
        const loadData = async () => {
            try {
                const result = await api.get('/door_control/list', {
                    params: {
                        search,
                        start_time: startTime,
                        end_time: endTime,
                        limit,
                        page,
                    },
                });

                const { count, logs } = result.data;

                setControlLogs(logs);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();

        // const load = setInterval(loadData, 10000);

        // return () => {
        //     clearInterval(load);
        // };
    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Registros de Controle Remoto
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item>
                                <InputSearch
                                    textFieldProps={{
                                        placeholder: 'Buscar…',
                                    }}
                                    request={updateRequest}
                                    pagination={[page, limit, startTime, endTime]}
                                />
                            </Grid>
                            <Grid item>
                                <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    value={startTime}
                                    placeholder="Inicio"
                                    onChange={value => setStartTime(value && value.format())}
                                    cancelLabel="cancelar"
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item>
                                <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    value={endTime}
                                    placeholder="Fim"
                                    onChange={value => setEndTime(value && value.format())}
                                    cancelLabel="cancelar"
                                    disabled={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Operador(a)</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivo</TableCell>
                                        <TableCell className={classes.tableHead}>Horário</TableCell>
                                        <TableCell className={classes.tableHead}>Informações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!controlLogs && controlLogs.map(log => (
                                        <TableRow key={log.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {log.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {log.admin?.name ? log.admin.name : '<Operador(a) Removido>'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {log.device?.name ? log.device.name : '<Dispositivo Removido>'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {log.event_time ? date(log.event_time).toLocaleFormat() : '-'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    className={classes.actionButton}
                                                    onClick={() => handleViewDetails(log)}
                                                >
                                                    <TextSnippetIcon style={{ fontSize: 20 }} />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                    <Popup
                        openPopup={openControlDetails}
                        setOpenPopup={setOpenControlDetails}
                        title="Detalhes do Registro"
                        width='auto'
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        fontSize: '1.3rem',
                                    }}
                                >
                                    Dispositivo:
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        fontSize: '1.3rem',
                                    }}
                                >
                                    {selectedLog?.device?.name ? selectedLog.device.name : '<Dispositivo Removido>'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        fontSize: '1.3rem',
                                    }}
                                >
                                    Operador(a):
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        fontSize: '1.3rem',
                                    }}
                                >
                                    {selectedLog?.admin?.name ? selectedLog.admin.name : '<Operador(a) Removido>'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        fontSize: '1.3rem',
                                    }}
                                >
                                    Horário:
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    style={{
                                        fontSize: '1.3rem',
                                    }}
                                >
                                    {selectedLog?.event_time ? date(selectedLog.event_time).toLocaleFormat() : '-'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <textarea
                                    placeholder="Nenhum detalhe fornecido"
                                    multiline
                                    minRows={20}
                                    style={{
                                        width: '100%',
                                        border: '1px solid #c6c6c6',
                                        borderRadius: '4px',
                                        minHeight: '200px',
                                        resize: 'none',
                                        padding: '10px',
                                        fontSize: '1.2rem',
                                        color: grey[600],
                                        fontFamily: 'Arial, sans-serif'
                                    }}
                                    value={selectedLog?.notes}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                    </Popup>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}

            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    enabledNotification: {
        backgroundColor: green[700],
        color: '#FFF',
    },
    disabledNotification: {
        backgroundColor: red[700],
        color: '#FFF',
    },
}));
