import React from 'react';
import MaskedInput from 'react-text-mask';
import { grey } from '@material-ui/core/colors';
import Input from './Input';
import { Typography } from '@material-ui/core';

const isInvalidTime = (times) => {

    if (!times || times.length === 0) return true;

    const splitedStart = times[0].split(':');
    const splitedEnd = times[1].split(':');
    const currentTime = new Date();

    let startTime = new Date();
    startTime.setFullYear(currentTime.getFullYear());
    startTime.setMonth(currentTime.getMonth())
    startTime.setDate(currentTime.getDate());
    startTime.setHours(splitedStart[0]);
    startTime.setMinutes(splitedStart[1]);
    startTime.setSeconds('0');

    let endTime = new Date();
    endTime.setFullYear(currentTime.getFullYear());
    endTime.setMonth(currentTime.getMonth());
    endTime.setDate(currentTime.getDate());
    endTime.setHours(splitedEnd[0]);
    endTime.setMinutes(splitedEnd[1]);
    endTime.setSeconds('0');

    // console.log({ startTime, endTime, currentTime });

    if (startTime >= endTime) return true;

    return false;
};

export default function InputStartEndTime({
    label,
    className,
    icon,
    iconPosition,
    textFieldProps,
    onValidFormat,
    onInvalidFormat,
}) {
    const [showText, setShowText] = React.useState(true)
    return (
        <>
            {
                showText ? (
                    <Typography
                        variant='subtitle2'
                        onClick={() => setShowText(false)}
                        style={{
                            textAlign: 'center',
                            fontWeight: '700',
                            color: grey[500],
                            fontSize: '1rem',
                            cursor: 'pointer',
                        }}
                    >
                        {textFieldProps.value || '____ - ____'}
                    </Typography>
                ) : (
                    <Input
                        label={label}
                        className={className}
                        icon={icon}
                        iconPosition={iconPosition}
                        inputComponent={TimeMask}
                        style={{ textAlign: 'center', fontWeight: '700', color: grey[500], }}
                        textFieldProps={{
                            ...textFieldProps,
                            onChange: ({ target }) => {
                                const pattern = /^(?:[0-1][0-9]|2[0-3])|[_]:([0-5][0-9]) - (?:[0-1][0-9]|2[0-3]):([0-5][0-9])$/;
                                const value = target?.value;
                                // const times = target?.value?.split('-');
                                console.log({ value })
                                if (value === '') {
                                    onValidFormat(value + value)
                                } else if (value.match(pattern)) {
                                    //if (!isInvalidTime(times)) onValidFormat(value)
                                    onValidFormat(value)
                                } else {
                                    // to fix when a number wasn't added if it was the first character
                                    let nums = ['0', '1', '2' , '3', '4', '5', '6', '7', '8', '9'];
                                    if (nums.includes(value[0]) && value[1] === '_') {
                                        onValidFormat(value)
                                    }
                                }
                            },
                            onBlur: ({ target }) => {
                                const pattern = /^(?:[0-1][0-9]|2[0-3]):([0-5][0-9]) - (?:[0-1][0-9]|2[0-3]):([0-5][0-9])$/;


                                if (!target.value.match(pattern)) {
                                    onInvalidFormat('');
                                } else {
                                    let times = target.value.split('-');

                                    const invalidTime = isInvalidTime(times);
                                    if (invalidTime) onInvalidFormat('');
                                }

                                setShowText(true);
                            },
                        }}
                    />
                )
            }
        </>
    );
};

const TimeMask = props => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', '-', ' ', /\d/, /\d/, ':', /\d/, /\d/]}
        />
    );
};
