import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Typography,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { metrics } from '../../assets/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Popup from '../../components/Popup';
import Alert from '../../components/Alert';

import api from '../../services/api';

import date from '../../utils/date';

export default function ExportPeopleToDeviceView() {
    const classes = useStyles();

    const { id } = useParams();
    const history = useHistory();

    const [openUsersPopup, setOpenUsersPopup] = useState(false);
    const [openDevicesPopup, setOpenDevicesPopup] = useState(false);
    const [openCamerasPopup, setOpenCamerasPopup] = useState(false);
    const [openGroupsPopup, setOpenGroupsPopup] = useState(false);

    const [usersLimit, setUsersLimit] = useState(10);
    const [devicesLimit, setDevicesLimit] = useState(10);
    const [camerasLimit, setCamerasLimit] = useState(10);
    const [groupsLimit, setGroupsLimit] = useState(10);

    const [usersPage, setUsersPage] = useState(0);
    const [devicesPage, setDevicesPage] = useState(0);
    const [camerasPage, setCamerasPage] = useState(0);
    const [groupsPage, setGroupsPage] = useState(0);

    const [usersTotal, setUsersTotal] = useState(0);
    const [devicesTotal, setDevicesTotal] = useState(0);
    const [camerasTotal, setCamerasTotal] = useState(0);
    const [groupsTotal, setGroupsTotal] = useState(0);

    const [users, setUsers] = useState([]);
    const [viewingUsers, setViewingUsers] = useState([]);

    const [devices, setDevices] = useState([]);
    const [viewingDevices, setViewingDevices] = useState([]);

    const [cameras, setCameras] = useState([]);
    const [viewingCameras, setViewingCameras] = useState([]);

    const [groups, setGroups] = useState([]);
    const [viewingGroups, setViewingGroups] = useState([]);

    const [exportedLog, setExportedLog] = useState({});

    const [message, setMessage] = useState({
        open: false,
        variant: "",
        text: "",
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: "",
            text: "",
        });
    };

    const paginate = (array, page_number, page_size) => {
        return array.slice(page_number * page_size, page_number * page_size + page_size)
    }

    useEffect(() => {
        const loadLog = async () => {
            try {
                const result = await api.get(`/export_people/view/${id}`);
                const log = result.data;

                setUsers(log.users);
                setUsersTotal(log.users.length);
                setDevices(log.devices);
                setDevicesTotal(log.devices?.length);
                setCameras(log.cameras);
                setCamerasTotal(log.cameras?.length)
                setGroups(log.groups);
                setGroupsTotal(log.groups?.total);

                delete log.users;
                delete log.devices;
                delete log.cameras;
                delete log.groups;

                setExportedLog(log);
            } catch (err) {
                history.goBack();
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            }
        };

        loadLog();
    }, [id, history]);

    useEffect(() => {

        const currentUsers = Object.assign([], users);
        const paginatedUsers = paginate(currentUsers, usersPage, usersLimit);
        setViewingUsers(paginatedUsers);

    }, [usersPage, usersLimit, users]);

    useEffect(() => {

        const currentDevices = Object.assign([], devices);
        const paginatedDevices = paginate(currentDevices, devicesPage, devicesLimit);
        setViewingDevices(paginatedDevices);

    }, [devicesPage, devicesLimit, devices]);

    useEffect(() => {

        const currentCameras = Object.assign([], cameras);
        const paginatedCameras = paginate(currentCameras, camerasPage, camerasLimit);
        setViewingCameras(paginatedCameras);

    }, [camerasPage, camerasLimit, cameras]);

    useEffect(() => {

        const currentGroups = Object.assign([], groups);
        const paginatedGroups = paginate(currentGroups, groupsPage, groupsLimit);
        setViewingGroups(paginatedGroups);

    }, [groupsPage, groupsLimit, groups]);

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'user':
                setUsersPage(newPage);

                break;
            case 'device':
                setDevicesPage(newPage);
                break;
            case 'camera':
                setCamerasPage(newPage);
                break;
            case 'group':
                setGroupsPage(newPage);
                break;
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        switch (quantityOf) {
            case 'user':
                setUsersLimit(parseInt(target.value, 10));
                setUsersPage(0);
                break;
            case 'device':
                setDevicesLimit(parseInt(target.value, 10));
                setDevicesPage(0);
                break;
            case 'camera':
                setCamerasLimit(parseInt(target.value, 10));
                setCamerasPage(0);
                break;
            case 'group':
                setGroupsLimit(parseInt(target.value, 10));
                setGroupsPage(0);
                break;
            default:
                console.log('Quantidade inválida');
        }
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Registro de Exportação Nº {id}
                        </Typography>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid spacing={5} item xs={12}>
                            <Paper alevation={3} style={{ padding: '1.5%' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.viewTitle}>
                                                    Horário
                                                </Typography>
                                                <Typography variant="subtitle2" className={classes.viewSubtitle}>
                                                    {date(exportedLog.event_time).toLocaleFormat()}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.viewTitle}>
                                                    Progresso (Completados/Total)
                                                </Typography>
                                                <Typography variant="subtitle2" className={classes.viewSubtitle}>
                                                    {`${exportedLog.completed}/${exportedLog.total}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} justify="space-evenly">
                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth={true}
                                                    onClick={() => setOpenUsersPopup(true)}
                                                >
                                                    Pessoas Exportadas ({`${users.length}`})
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth={true}
                                                    onClick={() => setOpenGroupsPopup(true)}
                                                >
                                                    Grupos de Dispositivos ({`${groups.length}`})
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth={true}
                                                    onClick={() => setOpenDevicesPopup(true)}
                                                >
                                                    Dispositivos ({`${devices.length}`})
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth={true}
                                                    onClick={() => setOpenCamerasPopup(true)}
                                                >
                                                    Câmeras ({`${cameras.length}`})
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
            <Popup
                openPopup={openUsersPopup}
                setOpenPopup={setOpenUsersPopup}
                title="Pessoas exportadas"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="users list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!viewingUsers && viewingUsers.map(user => (
                                        <TableRow key={user.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {user.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.doc_type && user?.doc_type === 'ic' ? 'RG' :
                                                    user?.doc_type === 'passport' ? 'Passaporte' :
                                                        user?.doc_type === 'other' ? 'Outro' :
                                                            user?.doc_type === 'unknown' ? 'CPF' :
                                                                '-'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.doc_number ? user.doc_number : "-"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.PersonType ? user.PersonType.name : "-"}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={usersTotal}
                            rowsPerPage={usersLimit}
                            page={usersPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'user')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'user')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openDevicesPopup}
                setOpenPopup={setOpenDevicesPopup}
                title="Dispositivos de destino"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="devices list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Descrição</TableCell>
                                        <TableCell className={classes.tableHead}>Grupo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!viewingDevices && viewingDevices.map(device => (
                                        <TableRow key={device.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {device.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.description}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.groups[0] ? device.groups[0]?.name : "-"}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={devicesTotal}
                            rowsPerPage={devicesLimit}
                            page={devicesPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'device')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'device')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openCamerasPopup}
                setOpenPopup={setOpenCamerasPopup}
                title="Câmeras de destino"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="camera list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Grupo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!viewingCameras && viewingCameras.map(camera => (
                                        <TableRow key={camera.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {camera.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {(camera.type === 'entry') ? 'Entrada' : (camera.type === 'internal') ? 'Interna' : (camera.type === 'exit') ? 'Saída' : 'Desconhecido'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.groups[0] ? camera.groups[0]?.name : "-"}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={camerasTotal}
                            rowsPerPage={camerasLimit}
                            page={camerasPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'camera')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'camera')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openGroupsPopup}
                setOpenPopup={setOpenGroupsPopup}
                title="Grupos de Dispositivos"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="group list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Descrição</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!viewingGroups && viewingGroups.map(group => (
                                        <TableRow key={group.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {group.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {group.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {group.description}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={groupsTotal}
                            rowsPerPage={groupsLimit}
                            page={groupsPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'group')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'group')}
                        />
                    </Grid>
                </Grid>
            </Popup>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: `calc(100% - ${metrics.drawerWidth}px)`,
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    smallTitle: {
        color: grey[600],
        marginLeft: theme.spacing(1),
        fontSize: 16,
    },
    filter: {
        margin: theme.spacing(0, 0, 3, 0),
    },
    dateTimeInput: {
        marginRight: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: '95%',
        width: '100%',
    },
    table: {
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    viewTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: grey[600]
    },
    viewSubtitle: {
        fontSize: '1.1rem',
        color: grey[600]
    },
}));
