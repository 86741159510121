import React from 'react';
import { Bar } from 'react-chartjs-2';

export default function BarChart(props) {
	const { data = {
		labels: ['Null'],
		datasets: [
			{
				label: 'Null',
				data: [0],
				backgroundColor: [
					'#000D6B',
					'#FF5151',
					'#FF9300',
					'#125C13'
				]
			}
		]
	}, options } = props;
	return (
		<Bar 
			data={data}
			options={options}
		/>
	);	
};