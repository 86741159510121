import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    Toolbar,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import TopMenu from '../../components/TopMenu';
import SideMenu from '../../components/SideMenu';
import { loadPlayer } from 'rtsp-relay/browser'

import api from '../../services/api';

import config from '../../configs/config';

import { metrics } from '../../assets/styles';

export default function CameraView() {
    const { id } = useParams();

    const canvas = useRef(null);

    const classes = useStyles();
    const [cameraData, setCameraData] = useState({
        name: '',
        channel: '',
        recognition_similarity: '',
        type: '',
        usage: '',
        place: ''
    });
    const [deviceData, setDeviceData] = useState({
        name: '',
        org_id: '',
        location: '',
        host: '',
        port: ''
    });

    useEffect(() => {
        const loadCamera = async () => {

            const result = await api.get(`/camera/view/${id}`);
            const { camera } = result.data;
            setCameraData({
                name: camera.name,
                channel: camera.channel,
                recognition_similarity: camera.recognition_similarity,
                type: camera.type,
                usage: camera.usage,
                place: camera.place_cameras.name
            });

            setDeviceData({
                name: camera.device.name,
                org_id: camera.device.OrganizationalStructure.name,
                location: 'Somewhere',
                host: camera.device.host,
                port: camera.device.port
            });
            
            loadPlayer({
                url: `${config.relayUrl}/api/stream/?nvr=${camera.device.host}&cam=${camera.channel}&username=${camera.device.username}&password=${camera.device.password}&subtype=1`,
                canvas: canvas.current,
            });
        };
        loadCamera();
        // eslint-disable-next-line
    }, [id])

    return (
        <div className={classes.root}>
            <TopMenu />
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid spacing={2} xs={12} container direction='column'>
                    <Grid item>
                        <Typography variant='h5' className={classes.title}>
                            Câmera
                        </Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <Paper alevation={3}>
                            <Grid xs={12} container style={{
                                padding: '2%'
                            }}>
                                <Grid xs={4} item className={classes.cameraContainer}>
                                    <canvas ref={canvas} style={{ height: '100%', width: '100%', borderRadius: '8px' }}></canvas>
                                </Grid>
                                <Grid xs={8} container className={classes.dataContainer}>
                                    <Grid xs={12} item>
                                        <Grid container justify='space-between' style={{ height: '100%', width: '100%', boxSizing: 'border-box' }}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle1' className={classes.containerTitle}>
                                                    Informações da Câmera
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3} className={classes.dataItem}>
                                                <Typography variant='subtitle1' className={classes.label}>
                                                    Nome:
                                                </Typography>
                                                <Typography variant='subtitle2' className={classes.text}>
                                                    {cameraData.name}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3} className={classes.dataItem}>
                                                <Typography variant='subtitle1' className={classes.label}>
                                                    Canal:
                                                </Typography>
                                                <Typography variant='subtitle2' className={classes.text}>
                                                    {cameraData.channel}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3} className={classes.dataItem}>
                                                <Typography variant='subtitle1' className={classes.label}>
                                                    Grau de reconhecimento:
                                                </Typography>
                                                <Typography variant='subtitle2' className={classes.text}>
                                                    {cameraData.recognition_similarity}
                                                </Typography>
                                            </Grid>


                                            <Grid item xs={3} className={classes.dataItem}>
                                                <Typography variant='subtitle1' className={classes.label}>
                                                    Tipo:
                                                </Typography>
                                                <Typography variant='subtitle2' className={classes.text}>
                                                    {
                                                        cameraData.type === 'entry' ? 'Entrada' :
                                                        cameraData.type === 'internal' ? 'Interna' :
                                                        cameraData.type === 'exit' ? 'Saída' :
                                                        ''
                                                    }
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3} className={classes.dataItem}>
                                                <Typography variant='subtitle1' className={classes.label}>
                                                    Uso:
                                                </Typography>
                                                <Typography variant='subtitle2' className={classes.text}>
                                                    {(cameraData.usage === 'access_controll') ? 'Controle de Acesso' : 
                                                        (cameraData.usage === 'monitoring') ? 'Monitoramento' :
                                                        (cameraData.usage === 'security') ? 'Segurança Orgânica' :
                                                        'Desconhecido'
                                                    }
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3} className={classes.dataItem}>
                                                <Typography variant='subtitle1' className={classes.label}>
                                                    Local:
                                                </Typography>
                                                <Typography variant='subtitle2' className={classes.text}>
                                                    {cameraData.place}
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Grid container justify='space-between' style={{ height: '100%', width: '100%', boxSizing: 'border-box' }}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle1' className={classes.containerTitle}>
                                                    Informações do Dispositivo
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3} className={classes.dataItem}>
                                                <Typography variant='subtitle1' className={classes.label}>
                                                    Nome:
                                                </Typography>
                                                <Typography variant='subtitle2' className={classes.text}>
                                                    {deviceData.name}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3} className={classes.dataItem}>
                                                <Typography variant='subtitle1' className={classes.label}>
                                                    Organização:
                                                </Typography>
                                                <Typography variant='subtitle2' className={classes.text}>
                                                    {deviceData.org_id}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3} className={classes.dataItem}>
                                                <Typography variant='subtitle1' className={classes.label}>
                                                    Localização:
                                                </Typography>
                                                <Typography variant='subtitle2' className={classes.text}>
                                                    {deviceData.location}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: `calc(100% - ${metrics.drawerWidth}px)`,
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    cameraContainer: {
        width: '500px',
        height: '500px',
        padding: '8px',
        background: '#cdcdcd',
        borderRadius: '10px',
        boxShaddow: '0 0 0 0 5px #cdcdcd'
    },
    dataContainer: {
        display: 'flex',
        boxSizing: 'border-box',
        padding: '1%'
    },
    containerTitle: {
        fontWeight: 'bold',
        color: '#757575',
        fontSize: '1.5rem',
    },
    dataItem: {
        background: '#F0F0F0',
        padding: '0.5% 1.5%',
        borderRadius: '15px',
        boxSizing: 'border-box',
        margin: '0.5%'
    },
    label: {
        fontWeight: 'bold',
        color: '#757575',
        fontSize: '1rem'
    },
    text: {
        fontWeight: 'bold',
        color: '#696969',
        marginLeft: '8px',
        fontSize: '1rem',
        textAlign: 'justify'
    }
}));
