import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Popup from './Popup';

export default function PopUpDeviceCapacity({capacity, open, setOpen, close, loading}) {
 const getType = (type) =>{
        switch(type){
        case 'card':
            return 'Cartão de Acesso';
        case 'user':
            return 'Usuarios'
        case 'face':
            return 'Rostos'
        case 'finger':
            return 'Digitais'
         default:
            return '';
        }
    }
 const getTotal = (total) =>{
   switch(total){
   case 'card':
       return capacity.capacity?.table?.MaxDevCapsCount?.maxCardCount;
   case 'user':
       return capacity.capacity?.table?.MaxDevCapsCount?.maxUserCount;
   case 'face':
       return capacity.capacity?.table?.MaxDevCapsCount?.maxFaceCount
   case 'finger':
       return capacity.capacity?.table?.MaxDevCapsCount?.maxFingerCount
    default:
       return '';
   }
    }

  return (
      <Popup
             openPopup={open}
             setOpenPopup={setOpen}
             title="Capacidade do dispositivo"
             width='auto'
             onClose={close}
         >
            {!!loading ?

            <>
                    <Skeleton width={700} />
                    <Skeleton width={700} />
                    <Skeleton width={700} />
                    <Skeleton width={700} />
                    <Skeleton width={700} />
                    <Skeleton width={700} />
                    <Skeleton width={700} />
                    <Skeleton width={700} />
                    <Skeleton width={700} />
            </>
               :
              <TableContainer component={Paper}>
               <Table sx={{ minWidth: 700 }} aria-label="customized table">
                 <TableHead>
                   <TableRow>
                     <TableCell>Tipo</TableCell>
                     <TableCell align="right">Usado</TableCell>
                     <TableCell align="right">Total</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                    {
                     !!Object.entries(capacity)?.length &&
                     Object.entries(capacity)
                         .filter((element) => element[0] !== 'capacity')
                         .map(([key, value]) => (
                             <TableRow key={key}>
                             <TableCell align="left">
                                 {getType(key)}
                                 </TableCell>
                             <TableCell align="right">
                                 {(!!value.Size && !getTotal(key))? <b>Não identificado</b> : value.Size}
                                 </TableCell>
                             <TableCell align="right">
                                 {(!!value.Size && !getTotal(key))? <b>Não identificado</b> : getTotal(key)}</TableCell>
                             </TableRow>
                         ))
                     }
                 </TableBody>
               </Table>
             </TableContainer>
            }
        </Popup>
  )
}
