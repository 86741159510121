import { makeStyles } from "@material-ui/core";

const dhiAccess = ['UserAccessRecord'];
const cameraAccess = ['FaceDetectionRecord', 'FaceRecognitionRecord'];

const AccessMedia = ({ log, showVideo = false }) => {
    const classes = useStyles();

    console.log({ log })

    if (log && dhiAccess.includes(log.type)) {
        return (
            <img src={log.accessMedia} className={classes.video} alt="IMAGEM" />
        );
    } else if (log && cameraAccess.includes(log.type)) {
        return (
            <div
                className={classes.video}
                style={{
                    position: "relative"
                }}
            >
                {
                    showVideo ? (
                        <video controls className={classes.video} style={{ borderRadius: 12 }}>
                            <source src={log.videoMedia} />
                        </video>
                    ) : (
                        <img src={log.accessMedia} className={classes.video} alt="IMAGEM" />
                    )
                }
            </div>
        );
    } else {
        return <></>
    }
};

export default AccessMedia;

const useStyles = makeStyles((theme) => ({
    video: {
        maxHeight: '100%',
        height: '100%',
        maxWidth: '100%',
        display: 'block',
        margin: 'auto',
        borderRadius: 4,
    },
}));
