import React from 'react';
import {TextField, InputAdornment} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';

const Input = React.forwardRef(({
    label,
    className,
    icon,
    iconPosition,
    inputComponent,
    textFieldProps,
    numberProps,
    style,
    helperText,
    error,
    id
}, ref) => {
    const classes = useStyles();

    return (
        <div className={className}>
            {!!label && (
                <label className={classes.label}>
                    {label} {!!textFieldProps?.required && '*'}
                </label>
            )}
            <TextField
                {...textFieldProps}
                error={error}
                helperText={helperText}
                variant="outlined"
                size="small"
                id={id}
                inputRef={ref}
                inputProps={{
                    ...numberProps,
                    style: style ? style : {
                        paddingTop: 8,
                        paddingBottom: 8,
                        backgroundColor: '#FFF',
                        borderRadius: 5,
                    },
                }}
                InputProps={{
                    inputComponent,
                    ...(icon && {
                        style: {backgroundColor: '#FFF'},
                        startAdornment: (
                            <InputAdornment position={iconPosition}>
                                {icon}
                            </InputAdornment>
                        )
                    })
                }}
            />
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    label: {
        display: 'block',
        fontWeight: 'bold',
        color: grey[500],
        marginBottom: theme.spacing(1),
    },
}));

export default Input;
