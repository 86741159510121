import React, { useState } from 'react';
import { Toolbar, Paper, Button, Grid, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';

import api from '../../services/api';

export default function PersonTypeCreate() {
    const classes = useStyles();

    const initialValues = {
        name: '',
    };

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const saveFunc = async (values, formik) => {
        try {

            const personTypeData = {
                ...values,
            };
            const result = await api.post('/person_type/create', personTypeData);
            const { personType } = result.data;

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Tipo de pessoa "${personType.name}" cadastrado com sucesso!`,
            });
            resetForm(formik);
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const resetForm = formik => {
        formik.resetForm();
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Criar Novo Tipo de Pessoa
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={saveFunc}
                    >
                        {({ values, handleChange, handleSubmit, isSubmitting }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="person-type-create" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Grid container xs={12} style={{
                                                            boxSizing: 'borde-box',
                                                            display: 'flex',
                                                            justifyContent: 'space-evenly',
                                                            alignItems: 'center',
                                                            flexDirection: 'column'
                                                        }}>
                                                            <Grid item
                                                                style={{
                                                                    marginBottom: '0.5%',
                                                                    width: '50%'
                                                                }}
                                                            >
                                                                <Input
                                                                    label="Nome"
                                                                    textFieldProps={{
                                                                        name: 'name',
                                                                        placeholder: 'Nome do tipo de pessoa',
                                                                        onChange: handleChange,
                                                                        value: values.name,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Button
                                                                    disabled={isSubmitting}
                                                                    type="submit"
                                                                    variant="contained"
                                                                    form="person-type-create"
                                                                    color="primary"
                                                                    endIcon={<SaveIcon />}
                                                                    id="salvar"
                                                                >
                                                                    {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
}));

