import React, { useReducer } from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    Checkbox,
    Button,
    Typography,
    DialogContent,
    FormControlLabel
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from "@material-ui/core/styles";

const reduceTableColumns = (state, action) => {
    switch (action.type) {
        case 'change':
            return {
                ...state,
                [action.column]: !state[action.column]
            }
        default:
            return state;
    }
}

export const useTableColumnsToShow = () => {
    const [tableColumns, dispatchTableColumns] = useReducer(reduceTableColumns, {
        showID: false,
        showName: true,
        showActive: true,
        showFace: true,
        showOnDevices: true,
        showSex: true,
        showDocType: true,
        showDocNumber: true,
        showPhone: true,
        showEmail: true,
        showPersonType: true,
        showLastEvent: false,
        showOrganization: true,
    });

    return { tableColumns, dispatchTableColumns };
}

export const ColumnsManagementModal = ({ open, setOpen, tableColumns, dispatchTableColumns }) => {
    const classes = useStyles();

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
                <DialogTitle className={classes.dialogTitle}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }} className={classes.label}>
                            Campos da tabela
                        </Typography>
                        <Button
                            color="secondary"
                            onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid xs={12} container spacing={1}>
                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        ID
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showID}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showID' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Name
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showName}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showName' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Ativo
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showActive}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showActive' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Face
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showFace}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showFace' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Dispositivos
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showOnDevices}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showOnDevices' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Sexo
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showSex}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showSex' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Tipo de Documento
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showDocType}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showDocType' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        N° do Documento
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showDocNumber}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showDocNumber' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Telefone
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showPhone}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showPhone' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Email
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showEmail}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showEmail' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Tipo
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showPersonType}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showPersonType' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Organização
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showOrganization}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showOrganization' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.checkboxContainer}>
                            <FormControlLabel
                                label={
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Último Evento
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={tableColumns.showLastEvent}
                                        onChange={() => dispatchTableColumns({ type: 'change', column: 'showLastEvent' })}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: '60%',
    },
    dialogTitle: {
        paddingRight: '0px',
    },
    label: {
        color: '#9e9e9e',
        fontWeight: 'bold',
        userSelect: 'none',
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center'
    },
}));