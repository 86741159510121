import { Box, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import AccessMedia from "./AccessMedia";

import date from '../../../utils/date';

const PastLog = ({ accessLog, onClick }) => {
    const classes = useStyles();

    return (
        <Grid
            item
            xs={12}
            style={{
                height: "15rem",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                borderRadius: 12,
                cursor: "pointer",
            }}
            onClick={() => onClick(accessLog)}
        >
            <Box
                style={{
                    height: "10rem",
                    width: "100%",
                    background: "#222831",
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                    padding: "0 6px",

                }}
            >
                <Box
                    item
                    xs={12}
                    style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "transparent"
                    }}
                >
                    {
                        (accessLog.accessMedia) ? (
                            <AccessMedia log={accessLog} showVideo={false} />
                        ) : (
                            <Typography
                                variant="subtitle1"
                                className={classes.accessImageAlt}
                            >
                                NÃO POSSÍVEL RECUPERAR A IMAGEM DO ACESSO
                            </Typography>
                        )

                    }
                </Box>
            </Box>
            <Box
                style={{
                    height: "5rem",
                    width: "100%",
                    background: "#212A3E",
                    padding: "2px 6px",
                    borderBottomLeftRadius: 12,
                    borderBottomRightRadius: 12,
                }}
                component={Paper}
            >
                <Typography
                    style={{
                        fontWeight: 700,
                        color: grey[400],
                    }}
                >
                    Name: {accessLog?.user ? accessLog.user.name : "Desconhecido"}
                </Typography>
                <Typography
                    style={{
                        fontWeight: 700,
                        color: grey[400],
                    }}
                >
                    Location: {
                        accessLog?.Device
                            ? accessLog?.Device?.place_devices?.name
                            : accessLog?.camera
                                ? accessLog?.camera?.place_cameras?.name :
                                "Desconhecida"
                    }
                </Typography>
                <Typography
                    style={{
                        fontWeight: 700,
                        color: grey[400],
                    }}
                >
                    Time: {
                        accessLog?.event_time
                            ? date(accessLog.event_time).toLocaleFormat()
                            : "-"
                    }
                </Typography>
            </Box>
        </Grid>
    );
};

export default PastLog;

const useStyles = makeStyles((theme) => ({
    accessImageAlt: {
        fontWeight: 700,
        color: grey[900],
    },
}));
