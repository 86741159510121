export default function mask(mask, value) {
    const splittedMask = mask.split('');
    const splittedValue = value.split('');

    const result = splittedMask.map(char => {
        if (char === '#' && splittedValue.length > 0) {
            return splittedValue.shift();
        }
        return char;
    });

    return result.join('');
};
