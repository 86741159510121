import React, { useState, useReducer } from 'react';
import { useDevices, useCameras } from '../../hooks';

import {
    Toolbar,
    Paper,
    Button,
    Grid,
    Typography,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from '@material-ui/core';

import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';

import { Formik } from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Popup from '../../components/Popup';
import Select from '../../components/Select';

import api from '../../services/api';

const reduceDevices = (state, action) => {
    let newValues = [];
    switch (action.type) {
        case 'single':
            if (action.checked && !state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                newValues.push(action.id);
            } else if (!action.checked && state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                let index = state[action.deviceArray].indexOf(action.id);
                newValues.splice(index, 1);
            } else {
                newValues = state[action.deviceArray];
            }
            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'all':
            if (action.checked) {
                newValues = state[action.deviceArray];
                for (let current of action.allDevices) {
                    if (!newValues.includes(current.id)) {
                        newValues.push(current.id);
                    }
                }
            } else {
                const allIds = action.allDevices.map(el => el.id)
                newValues = state[action.deviceArray].filter(el => !allIds.includes(el));
            }

            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'load':
            newValues = action.loading;
            return {
                ...state,
                [action.deviceArray]: newValues,
            };
        case 'reset':
            return { device_id: [], camera_id: [] };
        default: return state;
    }
};

export default function DeviceGroupCreate() {
    const classes = useStyles();

    let initialValues = {
        name: '',
        description: '',
        allow_reentry: true,
        reentry_time: 0,
        remote_validation: false,
    };

    const [devicesSearch, setDevicesSearch] = useState('');
    const [devicesPage, setDevicesPage] = useState(0);
    const [devicesLimit, setDevicesLimit] = useState(10);
    const { devices, total: totalDevices } = useDevices({ search: devicesSearch, page: devicesPage, limit: devicesLimit, url: "list_dhi" });

    const [camerasSearch, setCamerasSearch] = useState('');
    const [camerasPage, setCamerasPage] = useState(0);
    const [camerasLimit, setCamerasLimit] = useState(10);
    const { cameras, total: totalCameras } = useCameras({ search: camerasSearch, page: camerasPage, limit: camerasLimit });

    const [openDevicesPopup, setOpenDevicesPopup] = useState(false);
    const [openCamerasPopup, setOpenCamerasPopup] = useState(false);

    const [deviceIds, dispatchDeviceIds] = useReducer(reduceDevices, { device_id: [], camera_id: [] });

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const saveGroup = async (values, formik) => {
        try {

            const data = {
                ...values,
                cameras: deviceIds.camera_id,
                devices: deviceIds.device_id,
            }

            const result = await api.post('/device_group/create', data);

            const { DeviceGroup } = result.data;

            resetForm(formik);
            dispatchDeviceIds({ type: 'reset' });

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `O grupo ${DeviceGroup.name} foi criado com sucesso`,
            });
        }
        catch (err) {
            console.log(err)
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const resetForm = formik => {
        formik.resetForm();
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'device':
                setDevicesPage(newPage);
                break;
            case 'camera':
                setCamerasPage(newPage);
                break
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        switch (quantityOf) {
            case 'device':
                setDevicesLimit(parseInt(target.value, 10));
                setDevicesPage(0);
                break;
            case 'camera':
                setCamerasLimit(parseInt(target.value, 10));
                setCamerasPage(0);
                break;
            default:
                console.log('Quantidade inválida');
        }
    };

    const checkSelection = (deviceArray, fetchedArray) => {
        if (!deviceIds[deviceArray].length) return false;

        let checkingArray = fetchedArray.map(el => el.id);
        let checked = checkingArray.every(el => {
            return deviceIds[deviceArray].includes(el)
        });
        return checked;
    }

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid container xs={12}>
                        <Typography xs={6} variant="h5" className={classes.title}>
                            Cadastrar Grupo de Dispositivos
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={saveGroup}
                    >
                        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="device-group-create" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container className={classes.formSection}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                        Informações básicas
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Input
                                                                label="Nome"
                                                                id="name"
                                                                textFieldProps={{
                                                                    name: "name",
                                                                    value: values.name,
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                    required: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Input
                                                                label="Descrição"
                                                                id="description"
                                                                textFieldProps={{
                                                                    name: "description",
                                                                    value: values.description,
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                    required: false,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                        Dispositivos e Câmeras
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container justify="space-around" spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenDevicesPopup(true)}
                                                            >
                                                                Adcionar dispositivos {`(${deviceIds.device_id.length})`}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenCamerasPopup(true)}
                                                            >
                                                                Adcionar câmeras {`(${deviceIds.camera_id.length})`}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {
                                                deviceIds.device_id.length > 0 && (
                                                    <Grid container className={classes.formSection} spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                                Configurações para Leitores Faciais
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={6} item>
                                                            <Select
                                                                label="Permitir entradas repetidas (DHI)"
                                                                id="allow-re-entry"
                                                                tip={
                                                                    <>
                                                                        <Typography variant='subtitle1'>
                                                                            Ao habilitar essa função, uma pessoa poderá por exemplo, passar pelo dispositivo e
                                                                            depois usar seu rosto/cartão para habilitar a passagem de outra pessoa
                                                                        </Typography>
                                                                        <Typography variant="subtitle2"><i>OBS: Apenas para o Leitor Facial (DHI).</i></Typography>
                                                                    </>
                                                                }
                                                                options={[
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    name: "allow_reentry",
                                                                    onChange: (e) => {
                                                                        setFieldValue('reentry_time', 0);
                                                                        handleChange(e);
                                                                    },
                                                                    fullWidth: true,
                                                                    value: values.allow_reentry,
                                                                }}
                                                            />
                                                        </Grid>
                                                        {
                                                            !values.allow_reentry && (
                                                                <Grid xs={6} item>
                                                                    <Input
                                                                        label="Tempo entre entradas repetidas (segundos)"
                                                                        id="re-entry-time"
                                                                        textFieldProps={{
                                                                            name: "reentry_time",
                                                                            type: "number",
                                                                            onChange: handleChange,
                                                                            fullWidth: true,
                                                                            value: values.reentry_time,
                                                                        }}
                                                                        numberProps={{
                                                                            min: 0,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            )
                                                        }
                                                        <Grid xs={6} item>
                                                            <Select
                                                                label="Validação remota (DHI)"
                                                                id="remote-validation"
                                                                options={[
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    name: "remote_validation",
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                    value: values.remote_validation,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            <Grid item xs={12} container justify="flex-end">
                                                <Button
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    form="device-group-create"
                                                    color="primary"
                                                    endIcon={<SaveIcon />}
                                                >
                                                    {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                </Button>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
            <Popup
                openPopup={openDevicesPopup}
                setOpenPopup={setOpenDevicesPopup}
                title="Dispositivos"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setDevicesSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="devices list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Descrição</TableCell>
                                        <TableCell className={classes.tableHead}>Local</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllDevices"
                                                color="primary"
                                                // checked={selectedDevices.length === devices.length}
                                                checked={checkSelection('device_id', devices)}
                                                onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                    type: 'all',
                                                    deviceArray: 'device_id',
                                                    allDevices: devices,
                                                    checked,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!devices && devices.map(device => (
                                        <TableRow key={device.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {device.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.description}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.place_devices?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addDevice"
                                                    color="primary"
                                                    checked={deviceIds.device_id.includes(device.id)}
                                                    onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                        type: 'single',
                                                        id: device.id,
                                                        deviceArray: 'device_id',
                                                        checked,
                                                    })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={totalDevices}
                            rowsPerPage={devicesLimit}
                            page={devicesPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'device')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'device')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openCamerasPopup}
                setOpenPopup={setOpenCamerasPopup}
                title="Câmeras"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setCamerasSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="camera list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Local</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivo</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllCameras"
                                                color="primary"
                                                // checked={selectedCameras.length === cameras.length}
                                                checked={checkSelection('camera_id', cameras)}
                                                onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                    type: 'all',
                                                    deviceArray: 'camera_id',
                                                    allDevices: cameras,
                                                    checked,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!cameras && cameras.map(camera => (
                                        <TableRow key={camera.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {camera.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {(camera.type === 'entry') ? 'Entrada' : (camera.type === 'internal') ? 'Interna' : (camera.type === 'exit') ? 'Saída' : 'Desconhecido'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.place_cameras?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.device?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addCamera"
                                                    color="primary"
                                                    checked={deviceIds.camera_id.includes(camera.id)}
                                                    onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                        type: 'single',
                                                        id: camera.id,
                                                        deviceArray: 'camera_id',
                                                        checked,
                                                    })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={totalCameras}
                            rowsPerPage={camerasLimit}
                            page={camerasPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'camera')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'camera')}
                        />
                    </Grid>
                </Grid>
            </Popup>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
        fontSize: '1.5rem',
    },
}));
