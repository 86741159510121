import React, { useState, useEffect, useRef } from "react";
import {
    Toolbar,
    Paper,
    Button,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Checkbox,
    Switch
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import PercentageIcon from '../../assets/img/percentage.svg';
import SearchIcon from '@material-ui/icons/Search';
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import SideMenu from "../../components/SideMenu";
import Alert from "../../components/Alert";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Popup from "../../components/Popup";
import HelpTip from "../../components/HelpTip";
import SchedulePicker from '../../components/SchedulePicker';

import mask from "../../utils/mask";

import api from "../../services/api";

const MonitoringTarget = ({ name, current, children }) => {
    if (name === current) return (
        <>
            {
                children
            }
        </>
    );
    else return (<></>);
}

export default function AbsenceNotificationCreate() {
    const classes = useStyles();

    const scheduleRef = useRef();

    const initialValues = {
        active: false,
        name: "",
        description: "",
        whatsapp: false,
        email: false,
        similarity: '',
        similarity_operation: null,
        monitoring_target: 'specific_people',
        self_notificate: false,
        notificate_dependant_of: false,
    };

    const [personTypeSearch, setPersonTypeSearch] = useState("");
    const [personTypeLimit, setPersonTypeLimit] = useState(10);
    const [personTypePage, setPersonTypePage] = useState(0);
    const [selectedPersonTypes, setSelectedPersonTypes] = useState([]);
    const [personTypes, setPersonTypes] = useState([]);
    const [personTypesTotal, setPersonTypesTotal] = useState(0);

    const [openUsersPopup, setOpenUsersPopup] = useState(false);
    const [openDevicesPopup, setOpenDevicesPopup] = useState(false);
    const [openCamerasPopup, setOpenCamerasPopup] = useState(false);
    const [openNotifyPopup, setOpenNotifyPopup] = useState(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const onEditorStateChange = (newState) => {
        setEditorState(newState);
    };

    const [customWhatsappMessageText, setCustomWhatsappMessageText] = useState('')

    const [usersSearch, setUsersSearch] = useState('');
    const [devicesSearch, setDevicesSearch] = useState('');
    const [camerasSearch, setCamerasSearch] = useState('');
    const [notifySearch, setNotifySearch] = useState('');
    const [orgsSearch, setOrgsSearch] = useState('');

    const [usersLimit, setUsersLimit] = useState(10);
    const [devicesLimit, setDevicesLimit] = useState(10);
    const [camerasLimit, setCamerasLimit] = useState(10);
    const [notifyLimit, setNotifyLimit] = useState(10);
    const [orgsLimit, setOrgsLimit] = useState(10);

    const [usersPage, setUsersPage] = useState(0);
    const [devicesPage, setDevicesPage] = useState(0);
    const [camerasPage, setCamerasPage] = useState(0);
    const [notifyPage, setNotifyPage] = useState(0);
    const [orgsPage, setOrgsPage] = useState(0);

    const [usersTotal, setUsersTotal] = useState(0);
    const [devicesTotal, setDevicesTotal] = useState(0);
    const [camerasTotal, setCamerasTotal] = useState(0);
    const [notifyTotal, setNotifyTotal] = useState(0);
    const [orgsTotal, setOrgsTotal] = useState(0);

    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [devices, setDevices] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);

    const [cameras, setCameras] = useState([]);
    const [selectedCameras, setSelectedCameras] = useState([]);

    const [toNotify, setToNotify] = useState([]);
    const [selectedToNotify, setSelectedToNotify] = useState([]);

    const [orgs, setOrgs] = useState([]);
    const [selectedOrgs, setSelectedOrgs] = useState([]);

    const [showTips, setShowTips] = useState(false); // reserved words

    const [onlySelectedUsers, setOnlySelectedUsers] = useState(false);
    const [onlySelectedToNotify, setOnlySelectedToNotify] = useState(false);
    const [onlySelectedPersonTypes, setOnlySelectedPersonTypes] = useState(false);
    const [onlySelectedOrgs, setOnlySelectedOrgs] = useState(false);

    const [onlySelectedDevices, setOnlySelectedDevices] = useState(false);
    const [onlySelectedCameras, setOnlySelectedCameras] = useState(false);

    const [schedule, setSchedule] = useState(null);

    const handleOnlySelectedUsers = () => {
        setUsersPage(0);
        setOnlySelectedUsers(!onlySelectedUsers);
    };

    const handleOnlySelectedToNotify = () => {
        setNotifyPage(0);
        setOnlySelectedToNotify(!onlySelectedToNotify);
    }

    const handleOnlySelectedDevices = () => {
        setDevicesPage(0);
        setOnlySelectedDevices(!onlySelectedDevices);
    };

    const handleOnlySelectedCameras = () => {
        setCamerasPage(0);
        setOnlySelectedCameras(!onlySelectedCameras);
    };

    const handleOnlySelectedPersonTypes = () => {
        setPersonTypePage(0);
        setOnlySelectedPersonTypes(!onlySelectedPersonTypes);
    };

    const handleOnlySelectedOrganizations = () => {
        setOrgsPage(0);
        setOnlySelectedOrgs(!onlySelectedOrgs);
    };

    const [message, setMessage] = useState({
        open: false,
        variant: "",
        text: "",
    });

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const result = await api.get('/absence_notification/index_users', {
                    params: {
                        search: usersSearch,
                        limit: usersLimit,
                        page: usersPage,
                        only_selected: onlySelectedUsers,
                        selected_users: selectedUsers,
                    },
                });

                const { count, users } = result.data;

                setUsers(users);
                setUsersTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadUsers();
    }, [usersPage, usersLimit, usersSearch, selectedUsers, onlySelectedUsers]);

    useEffect(() => {
        const loadPersonTypes = async () => {
            try {
                const result = await api.get('/absence_notification/index_person_types', {
                    params: {
                        search: personTypeSearch,
                        limit: personTypeLimit,
                        page: personTypePage,
                        only_selected: onlySelectedPersonTypes,
                        selected_person_types: selectedPersonTypes,
                    },
                });

                const { count, person_types } = result.data;

                setPersonTypes(person_types);
                setPersonTypesTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadPersonTypes();
    }, [onlySelectedPersonTypes, personTypeLimit, personTypePage, personTypeSearch, selectedPersonTypes]);

    useEffect(() => {
        const loadToNotify = async () => {
            try {
                const result = await api.get('/absence_notification/index_users', {
                    params: {
                        search: notifySearch,
                        limit: notifyLimit,
                        page: notifyPage,
                        only_selected: onlySelectedToNotify,
                        selected_users: selectedToNotify,
                    },
                });

                const { count, users } = result.data;

                setToNotify(users);
                setNotifyTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadToNotify();
    }, [notifyPage, notifyLimit, notifySearch, onlySelectedToNotify, selectedToNotify]);

    useEffect(() => {
        const loadDevices = async () => {
            try {
                const result = await api.get('/absence_notification/index_devices', {
                    params: {
                        search: devicesSearch,
                        limit: devicesLimit,
                        page: devicesPage,
                        only_selected: onlySelectedDevices,
                        selected_devices: selectedDevices,
                    },
                });

                const { count, devices } = result.data;

                let newDevices = [];
                devices.forEach(device => {
                    if (device.type === 'dhi') {
                        newDevices.push(device)
                    }
                })

                setDevices(newDevices);
                setDevicesTotal(count);
            } catch (err) {
                console.log(err);
            }
        };

        loadDevices();
    }, [devicesPage, devicesLimit, devicesSearch, selectedDevices, onlySelectedDevices]);

    useEffect(() => {
        const loadCameras = async () => {
            try {
                const result = await api.get('/absence_notification/index_cameras', {
                    params: {
                        search: camerasSearch,
                        limit: camerasLimit,
                        page: camerasPage,
                        selected_cameras: selectedCameras,
                        only_selected: onlySelectedCameras,
                    },
                });

                const { count, cameras } = result.data;

                setCameras(cameras);
                setCamerasTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadCameras();
    }, [camerasPage, camerasLimit, camerasSearch, onlySelectedCameras, selectedCameras]);

    useEffect(() => {
        const loadOrgs = async () => {
            try {
                const result = await api.get('/absence_notification/index_organizations', {
                    params: {
                        search: orgsSearch,
                        limit: orgsLimit,
                        page: orgsPage,
                        selected_orgs: selectedOrgs,
                        only_selected: onlySelectedOrgs,
                    },
                });

                const { count, orgs } = result.data;

                setOrgs(orgs);
                setOrgsTotal(count);
            } catch (err) {
                console.log(err);
            }
        };

        loadOrgs();
    }, [orgsSearch, orgsLimit, orgsPage, onlySelectedOrgs, selectedOrgs]);

    const saveNotification = async (values, formik) => {
        try {
            const notificationData = {
                ...values,
                users: selectedUsers,
                person_types: selectedPersonTypes,
                devices: selectedDevices,
                cameras: selectedCameras,
                notify_to: selectedToNotify,
                organizations: selectedOrgs,
                email_custom_message: `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`,
                whatsapp_custom_message: customWhatsappMessageText,
                email_raw_message: convertToRaw(editorState.getCurrentContent()),
                absence_periods: schedule,
            };

            console.log("Whatsapp message", customWhatsappMessageText);
            console.log("Email message", draftToHtml(convertToRaw(editorState.getCurrentContent())));

            const result = await api.post(
                "/absence_notification/create",
                notificationData
            );
            const { notification } = result.data;

            formik.setSubmitting(false);

            setSelectedUsers([]);
            setSelectedDevices([]);
            setSelectedCameras([]);
            setSelectedToNotify([]);
            setSelectedOrgs([]);
            setEditorState(EditorState.createEmpty())
            setCustomWhatsappMessageText("")
            setMessage({
                open: true,
                variant: "success",
                text: `Notificação "${notification.name}" cadastrada com sucesso!`,
            });

            console.log(values.name, values.description)

            resetForm(formik);
            scheduleRef.current.resetValues();
            console.log(values.name, values.description)
            console.log("Form resetado")

        } catch (err) {
            console.log(err)
            setMessage({
                open: true,
                variant: "error",
                text: err.error,
            });
        }
    };

    const resetForm = (formik) => {
        formik.resetForm();
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: "",
            text: "",
        });
    };

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'user':
                setUsersPage(newPage);
                break;
            case 'device':
                setDevicesPage(newPage);
                break;
            case 'camera':
                setCamerasPage(newPage);
                break;
            case 'notify':
                setNotifyPage(newPage);
                break;
            case 'person_type':
                setPersonTypePage(newPage);
                break;
            case 'organization':
                setOrgsPage(newPage);
                break;
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        const newLimit = parseInt(target.value, 10);
        switch (quantityOf) {
            case 'user':
                setUsersLimit(newLimit);
                setUsersPage(0);
                break;
            case 'device':
                setDevicesLimit(newLimit);
                setDevicesPage(0);
                break;
            case 'camera':
                setCamerasLimit(newLimit);
                setCamerasPage(0);
                break;
            case 'notify':
                setNotifyLimit(newLimit);
                setNotifyPage(0);
                break;
            case 'person_type':
                setPersonTypeLimit(newLimit);
                setPersonTypePage(0);
                break;
            case 'organization':
                setOrgsLimit(newLimit);
                setOrgsPage(0);
                break;
            default:
                console.log('Quantidade inválida');
        }
    };

    const manipulateCheckBox = (checkbox, isSelected, id) => {
        switch (checkbox) {
            case 'user':
                let newSelectedUsers = Object.assign([], selectedUsers);
                if (isSelected) {
                    newSelectedUsers.push(id);
                } else {
                    let index = selectedUsers.indexOf(id);
                    newSelectedUsers.splice(index, 1);
                }
                setSelectedUsers(newSelectedUsers);
                break;
            case 'notify':
                let newSelectedToNotify = Object.assign([], selectedToNotify);
                if (isSelected) {
                    newSelectedToNotify.push(id);
                } else {
                    let index = selectedToNotify.indexOf(id);
                    newSelectedToNotify.splice(index, 1);
                }
                setSelectedToNotify(newSelectedToNotify);
                break;
            case 'device':
                let newSelectedDevices = Object.assign([], selectedDevices);
                if (isSelected) {
                    newSelectedDevices.push(id);
                } else {
                    let index = selectedDevices.indexOf(id);
                    newSelectedDevices.splice(index, 1);
                }
                setSelectedDevices(newSelectedDevices);
                break;
            case 'camera':
                let newSelectedCameras = Object.assign([], selectedCameras);
                if (isSelected) {
                    newSelectedCameras.push(id);
                } else {
                    let index = selectedCameras.indexOf(id);
                    newSelectedCameras.splice(index, 1);
                }
                setSelectedCameras(newSelectedCameras);
                break;
            case 'person_type':
                let newSelectedPersonTypes = Object.assign([], selectedPersonTypes);
                if (isSelected) {
                    newSelectedPersonTypes.push(id);
                } else {
                    let index = selectedPersonTypes.indexOf(id);
                    newSelectedPersonTypes.splice(index, 1);
                }
                setSelectedPersonTypes(newSelectedPersonTypes);
                break;
            case 'organization':
                let newSelectedOrganizations = Object.assign([], selectedOrgs);
                if (isSelected) {
                    newSelectedOrganizations.push(id);
                } else {
                    let index = newSelectedOrganizations.indexOf(id);
                    newSelectedOrganizations.splice(index, 1);
                }
                setSelectedOrgs(newSelectedOrganizations);
                break;
            case 'all_users':
                let allUsers = [];
                if (isSelected) {
                    allUsers = Object.assign([], selectedUsers);
                    users.forEach(user => {
                        if (!allUsers.includes(user.id)) {
                            allUsers.push(user.id)
                        }
                    });
                } else {
                    let userIds = users.map(el => el.id);
                    allUsers = selectedUsers.filter(user => !userIds.includes(user));
                }
                setSelectedUsers(allUsers);
                break;
            case 'all_devices':
                let allDevices = [];
                if (isSelected) {
                    allDevices = Object.assign([], selectedDevices);
                    devices.forEach(device => {
                        if (!allDevices.includes(device.id)) {
                            allDevices.push(device.id);
                        }
                    });
                } else {
                    let deviceIds = devices.map(device => device.id);
                    allDevices = selectedDevices.filter(device => !deviceIds.includes(device));
                }
                setSelectedDevices(allDevices);
                break;
            case 'all_cameras':
                let allCameras = [];
                if (isSelected) {
                    allCameras = Object.assign([], selectedCameras);
                    cameras.forEach(camera => {
                        if (!allCameras.includes(camera.id)) {
                            allCameras.push(camera.id);
                        }
                    });
                } else {
                    let cameraIds = cameras.map(camera => camera.id);
                    allCameras = selectedCameras.filter(camera => !cameraIds.includes(camera));
                }
                setSelectedCameras(allCameras);
                break;
            case 'all_to_notify':
                let allToNotify = [];
                if (isSelected) {
                    allToNotify = Object.assign([], selectedToNotify);
                    toNotify.forEach(notify => {
                        if (!allToNotify.includes(notify.id)) {
                            allToNotify.push(notify.id);
                        }
                    });
                } else {
                    let toNotifyIds = toNotify.map(notify => notify.id);
                    allToNotify = selectedToNotify.filter(notify => !toNotifyIds.includes(notify));
                }
                setSelectedToNotify(allToNotify);
                break;
            case 'all_person_types':
                let allPersonTypes = [];
                if (isSelected) {
                    allPersonTypes = Object.assign([], selectedPersonTypes);
                    personTypes.forEach(personType => {
                        if (!allPersonTypes.includes(personType.id)) {
                            allPersonTypes.push(personType.id);
                        }
                    });
                } else {
                    let personTypesIDS = personTypes.map(personType => personType.id);
                    allPersonTypes = selectedToNotify.filter(personType => !personTypesIDS.includes(personType));
                }
                setSelectedPersonTypes(allPersonTypes);
                break;
            case 'all_organizations':
                let allOrganizations = [];
                if (isSelected) {
                    allOrganizations = Object.assign([], selectedOrgs);
                    orgs.forEach(org => {
                        if (!allOrganizations.includes(org.id)) {
                            allOrganizations.push(org.id);
                        }
                    });
                } else {
                    let orgsIds = orgs.map(org => org.id);
                    allOrganizations = selectedOrgs.filter(org => !orgsIds.includes(org));
                }
                setSelectedOrgs(allOrganizations);
                break;
            default:
                console.log('Tipo inválido');
        }
    };

    const handleChangeMonitoringTarget = (setFieldValue, value) => {
        setFieldValue('monitoring_target', value)
        if (value === 'specific_people') {
            setPersonTypeSearch("");
            setPersonTypeLimit(10);
            setPersonTypePage(0);
            setSelectedPersonTypes([]);

            setOrgsSearch("");
            setOrgsLimit(10);
            setOrgsPage(0);
            setSelectedOrgs([]);
        } else if (value === "organization") {
            setPersonTypeSearch("");
            setPersonTypeLimit(10);
            setPersonTypePage(0);
            setSelectedPersonTypes([]);

            setUsersSearch("");
            setUsersLimit(10);
            setUsersPage(0);
            setSelectedUsers([]);
        } else if (value === "person_type") {
            setUsersSearch("");
            setUsersLimit(10);
            setUsersPage(0);
            setSelectedUsers([]);

            setOrgsSearch("");
            setOrgsLimit(10);
            setOrgsPage(0);
            setSelectedOrgs([]);
        }
    }

    const validateInputNumber = (setFieldValue, key, number, validateSize, lastValue) => {
        const pattern = /^[0-9]*$/;
        console.log(number)
        if (!number) {
            setFieldValue(key, '')
            return;
        } else if (!number.match(pattern)) {
            setFieldValue(key, lastValue)
            return;
        }
        console.log('is number')

        if (number.match(pattern) && !validateSize) {
            console.log("matches");
            setFieldValue(key, number);
        } else if (number.match(pattern) && validateSize) {
            console.log('size');
            if (number >= 0 && number <= 100) {
                setFieldValue(key, number);
            } else if (number >= 0 && number > 100) {
                setFieldValue(key, 100);
            }
        }
    }

    const handleNullifiedValue = (operationValue, operationName, setFieldValue, keyModifier) => {
        console.log('trying to modify ', operationName, operationValue, keyModifier);
        if (operationValue === null) {
            setFieldValue(keyModifier, '');
        }

        setFieldValue(operationName, operationValue);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Criar Notificação de Ausência
                        </Typography>
                    </Grid>
                    <Formik initialValues={initialValues} onSubmit={saveNotification}>
                        {({
                            values,
                            setFieldValue,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form
                                            id="notification-create"
                                            onSubmit={handleSubmit}
                                            className={classes.form}
                                        >
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Informações Básicas
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Input
                                                                label="Nome"
                                                                textFieldProps={{
                                                                    name: "name",
                                                                    onChange: handleChange,
                                                                    value: values.name,
                                                                    fullWidth: true,
                                                                    required: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Input
                                                                label="Descrição"
                                                                textFieldProps={{
                                                                    id: "description",
                                                                    name: "description",
                                                                    onChange: handleChange,
                                                                    value: values.description,
                                                                    fullWidth: true,
                                                                    required: true,
                                                                }}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                                                            <label className={classes.statusLabel}>Status da Notificação</label>
                                                            <Grid container spacing={1} alignItems="center" justify="center">
                                                                <Typography>Desativada</Typography>
                                                                <Switch
                                                                    color="primary"
                                                                    checked={values.active}
                                                                    onChange={({ target }) => setFieldValue('active', target.checked)}
                                                                    inputProps={{
                                                                        'aria-label': 'notification-status'
                                                                    }}
                                                                />
                                                                <Typography>Ativada</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Tipos de Notificação
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Select
                                                                label="Whatsapp"
                                                                options={[
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "whatsapp",
                                                                    name: "whatsapp",
                                                                    value: values.whatsapp,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Select
                                                                label="Email"
                                                                options={[
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "email",
                                                                    name: "email",
                                                                    value: values.email,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Codições
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Operação de Reconhecimento"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Maior",
                                                                        value: "gt",
                                                                    },
                                                                    {
                                                                        label: "Maior ou igual",
                                                                        value: "gte",
                                                                    },
                                                                    {
                                                                        label: "Igual",
                                                                        value: "eq",
                                                                    },
                                                                    {
                                                                        label: "Menor",
                                                                        value: "lt",
                                                                    },
                                                                    {
                                                                        label: "Menor ou igual",
                                                                        value: "lte",
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "similarity_operation",
                                                                    name: "similarity_operation",
                                                                    value: values.similarity_operation,
                                                                    onChange:
                                                                        (e, value) => {
                                                                            handleNullifiedValue(value.props.value, 'similarity_operation', setFieldValue, 'similarity')
                                                                        },
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} style={{ display: values.similarity_operation ? 'block' : 'none' }}>
                                                            <Input
                                                                label="Nível de Reconhecimento"
                                                                numberProps={{
                                                                    min: 0,
                                                                    max: 100
                                                                }}
                                                                textFieldProps={{
                                                                    name: "similarity",
                                                                    id: "similarityl",
                                                                    icon: <PercentageIcon />,
                                                                    iconPosition: "start",
                                                                    onChange:
                                                                        ({ target }) => validateInputNumber(setFieldValue, 'similarity', target.value, true, values.similarity),
                                                                    value: values.similarity,
                                                                    fullWidth: true,
                                                                    required: (values.similarity_operation) ? true : false,
                                                                    disabled: (values.similarity_operation) ? false : true
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Periodos de Ausência
                                                    </Typography>
                                                    <Grid container justify="space-around" spacing={3}>

                                                        <Grid
                                                            item
                                                            xs={12}
                                                        >
                                                            <SchedulePicker setMessage={setMessage} setSchedule={setSchedule} ref={scheduleRef} multiPeriod={false} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Adições
                                                    </Typography>
                                                    <Grid container justify="space-around" spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenUsersPopup(true)}
                                                            >
                                                                <MonitoringTarget
                                                                    name="specific_people"
                                                                    current={values.monitoring_target}
                                                                >
                                                                    Pessoas monitoradas {`(${selectedUsers.length})`}
                                                                </MonitoringTarget>
                                                                <MonitoringTarget
                                                                    name="person_type"
                                                                    current={values.monitoring_target}
                                                                >
                                                                    Tipos de pessoa monitorados {`(${selectedPersonTypes.length})`}
                                                                </MonitoringTarget>
                                                                <MonitoringTarget
                                                                    name="organization"
                                                                    current={values.monitoring_target}
                                                                >
                                                                    Organizações monitoradas {`(${selectedOrgs.length})`}
                                                                </MonitoringTarget>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenNotifyPopup(true)}
                                                            >
                                                                Pessoas notificadas {`(${selectedToNotify.length})`}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Grid container justify="space-around" spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenDevicesPopup(true)}
                                                            >
                                                                Adcionar dispositivos {`(${selectedDevices.length})`}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenCamerasPopup(true)}
                                                            >
                                                                Adcionar câmeras {`(${selectedCameras.length})`}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="h6"
                                                            className={
                                                                classes.formSectionTitle
                                                            }
                                                        >
                                                            Mensagens Personalizadas
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Mostrar Palavras Reservadas"
                                                                options={[
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    value: showTips,
                                                                    onChange:
                                                                        ({ target: { value } }) => setShowTips(value),
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ display: showTips ? 'block' : 'none', marginBottom: '10px', marginTop: '10px' }}>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="h7"
                                                                className={
                                                                    classes.formSectionTitle
                                                                }
                                                            >
                                                                Palavras reservadas
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ borderRadius: '3px', padding: '5px' }}>
                                                            <TableContainer component={Paper}>
                                                                <Table className={classes.table} size="small" aria-label="notify list">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableHead}>Campo</TableCell>
                                                                            <TableCell className={classes.tableHead}>Descrição</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #id#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                ID da pessoa (Ex: 12)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #name#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Nome da pessoa (Ex.: José Ferreira)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #schedule#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Rotina do dia da pessoa (Ex.: 1º: 08:00 - 12:00)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #doc#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Tipo e número do documento da pessoa, se cadastrada
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #organization#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                organização da pessoa, se cadastrada
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #person_type#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Tipo da pessoa, se cadastrada (Ex.: Colaborador)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #absence_period#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Rotina de acesso prevista (Ex.: 08:00 - 09:20)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    variant="h7"
                                                                    className={
                                                                        classes.formSectionTitle
                                                                    }
                                                                >
                                                                    Email
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Paper alevation={3}>
                                                                    <Grid item xs={12} style={{ minHeight: '100%', paddingBottom: '50px' }}>
                                                                        <Editor
                                                                            editorState={editorState}
                                                                            editorClassName={classes.editor}
                                                                            toolbarClassName={classes.editorToolbar}
                                                                            wrapperClassName={classes.editorWrapper}
                                                                            onEditorStateChange={onEditorStateChange}
                                                                        />
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    variant="h7"
                                                                    className={
                                                                        classes.formSectionTitle
                                                                    }
                                                                >
                                                                    Whatsapp/Pop-up
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ height: '100%' }}>
                                                                <textarea
                                                                    placeholder="Escreva sua mensagem"
                                                                    multiline
                                                                    minRows={20}
                                                                    style={{ width: '100%', border: '1px solid #c6c6c6', height: '96.75%', resize: 'none', padding: '10px', fontSize: '1.2rem', color: grey[600], fontFamily: 'Arial, sans-serif' }}
                                                                    value={customWhatsappMessageText}
                                                                    onChange={({ target }) => { setCustomWhatsappMessageText(target.value) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Grid container justify="flex-end" spacing={3}>
                                                        <Grid item>
                                                            <Button
                                                                disabled={isSubmitting}
                                                                type="submit"
                                                                variant="contained"
                                                                form="notification-create"
                                                                color="primary"
                                                                endIcon={<SaveIcon />}
                                                            >
                                                                {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                                <Popup
                                    openPopup={openUsersPopup}
                                    setOpenPopup={setOpenUsersPopup}
                                    title={
                                        values.monitoring_target === "specific_people" ? (
                                            "Adcione pessoas á serem monitoradas"
                                        ) : (
                                            "Adcione tipos de pessoas á serem monitorados"
                                        )
                                    }
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid item xs={6}>
                                                <Select
                                                    label="Alvo de Monitoramento"
                                                    options={[
                                                        {
                                                            label: "Pessoas Especificas",
                                                            value: "specific_people",
                                                        },
                                                        {
                                                            label: "Tipos de Pessoa",
                                                            value: "person_type",
                                                        },
                                                        {
                                                            label: "Organizações",
                                                            value: "organization",
                                                        },
                                                    ]}
                                                    textFieldProps={{
                                                        id: "monitoring_target",
                                                        name: "monitoring_target",
                                                        value: values.monitoring_target,
                                                        onChange: ({ target: { value } }) => handleChangeMonitoringTarget(setFieldValue, value),
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                            <MonitoringTarget
                                                name="specific_people"
                                                current={values.monitoring_target}
                                            >
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Input
                                                            icon={<SearchIcon style={{ color: grey[400] }} />}
                                                            iconPosition="start"
                                                            textFieldProps={{
                                                                placeholder: 'Buscar…',
                                                                onChange: ({ target }) => setUsersSearch(target.value),
                                                                fullWidth: true,
                                                                value: usersSearch,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Paper
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                cursor: 'pointer',
                                                            }}
                                                            variant="outlined"
                                                            onClick={handleOnlySelectedUsers}
                                                        >
                                                            <Checkbox
                                                                name="only_selected_people"
                                                                checked={onlySelectedUsers}
                                                                color="primary"
                                                            />
                                                            <div>
                                                                <Typography variant="subtitle2">
                                                                    Mostrar apenas pessoas selecionadas
                                                                </Typography>
                                                            </div>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </MonitoringTarget>
                                            <MonitoringTarget
                                                name="person_type"
                                                current={values.monitoring_target}
                                            >
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Input
                                                            icon={<SearchIcon style={{ color: grey[400] }} />}
                                                            iconPosition="start"
                                                            textFieldProps={{
                                                                placeholder: 'Buscar…',
                                                                onChange: ({ target }) => setPersonTypeSearch(target.value),
                                                                fullWidth: true,
                                                                value: personTypeSearch,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Paper
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                cursor: 'pointer',
                                                            }}
                                                            variant="outlined"
                                                            onClick={handleOnlySelectedPersonTypes}
                                                        >
                                                            <Checkbox
                                                                name="only_selected_person_types"
                                                                checked={onlySelectedPersonTypes}
                                                                color="primary"
                                                            />
                                                            <div>
                                                                <Typography variant="subtitle2">
                                                                    Mostrar apenas tipos de pessoa selecionadas
                                                                </Typography>
                                                            </div>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </MonitoringTarget>
                                            <MonitoringTarget
                                                name="organization"
                                                current={values.monitoring_target}
                                            >
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Input
                                                            icon={<SearchIcon style={{ color: grey[400] }} />}
                                                            iconPosition="start"
                                                            textFieldProps={{
                                                                placeholder: 'Buscar…',
                                                                onChange: ({ target }) => setOrgsSearch(target.value),
                                                                fullWidth: true,
                                                                value: orgsSearch,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Paper
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                cursor: 'pointer',
                                                            }}
                                                            variant="outlined"
                                                            onClick={handleOnlySelectedOrganizations}
                                                        >
                                                            <Checkbox
                                                                name="only_selected_organizations"
                                                                checked={onlySelectedOrgs}
                                                                color="primary"
                                                            />
                                                            <div>
                                                                <Typography variant="subtitle2">
                                                                    Mostrar apenas organizações selecionadas
                                                                </Typography>
                                                            </div>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </MonitoringTarget>
                                        </Grid>
                                        <MonitoringTarget
                                            name="specific_people"
                                            current={values.monitoring_target}
                                        >
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table className={classes.table} size="small" aria-label="users list">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.tableHead}>ID</TableCell>
                                                                <TableCell className={classes.tableHead}>Nome</TableCell>
                                                                <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                                                <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                                                <TableCell className={classes.tableHead}>Tipo de Pessoa</TableCell>
                                                                <TableCell className={classes.tableHead}>Organização</TableCell>
                                                                <TableCell className={classes.tableHead}>
                                                                    <Checkbox
                                                                        name="addAllUsers"
                                                                        color="primary"
                                                                        checked={users.every(el => {
                                                                            return selectedUsers.includes(el.id);
                                                                        })}
                                                                        onChange={({ target }) => manipulateCheckBox('all_users', target.checked)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {!!users && users.map(user => (
                                                                <TableRow key={user.id}>
                                                                    <TableCell className={classes.tableBody} component="th" scope="row">
                                                                        {user.id}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {user.name}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {user.doc_type && user?.doc_type === 'ic' ? 'RG' :
                                                                            user?.doc_type === 'passport' ? 'Passaporte' :
                                                                                user?.doc_type === 'other' ? 'Outro' :
                                                                                    user?.doc_type === 'unknown' ? 'CPF' :
                                                                                        'Não identificado'}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {user.doc_number ? user.doc_number : "Não identificado"}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {user.PersonType ? user.PersonType.name : "-"}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {user.OrganizationalStructure ? user.OrganizationalStructure.name : "-"}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        <Checkbox
                                                                            name="addUser"
                                                                            color="primary"
                                                                            checked={selectedUsers.includes(user.id)}
                                                                            onChange={({ target }) => manipulateCheckBox('user', target.checked, user.id)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30]}
                                                    component="div"
                                                    count={usersTotal}
                                                    rowsPerPage={usersLimit}
                                                    page={usersPage}
                                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                    labelRowsPerPage="Registros por página"
                                                    onChangePage={(_, newPage) => changePage(_, newPage, 'user')}
                                                    onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'user')}
                                                />
                                            </Grid>
                                        </MonitoringTarget>
                                        <MonitoringTarget
                                            name="person_type"
                                            current={values.monitoring_target}
                                        >
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table className={classes.table} size="small" aria-label="person type list">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.tableHead}>ID</TableCell>
                                                                <TableCell className={classes.tableHead}>Nome</TableCell>
                                                                <TableCell className={classes.tableHead}>
                                                                    <Checkbox
                                                                        name="addAllPersonTypes"
                                                                        color="primary"
                                                                        checked={personTypes.every(el => {
                                                                            return selectedPersonTypes.includes(el.id);
                                                                        })}
                                                                        onChange={({ target }) => manipulateCheckBox('all_person_types', target.checked)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {!!personTypes && personTypes.map(personType => (
                                                                <TableRow key={personType.id}>
                                                                    <TableCell className={classes.tableBody} component="th" scope="row">
                                                                        {personType.id}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {personType.name}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        <Checkbox
                                                                            name="addPersonType"
                                                                            color="primary"
                                                                            checked={selectedPersonTypes.includes(personType.id)}
                                                                            onChange={({ target }) => manipulateCheckBox('person_type', target.checked, personType.id)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30]}
                                                    component="div"
                                                    count={personTypesTotal}
                                                    rowsPerPage={personTypeLimit}
                                                    page={personTypePage}
                                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                    labelRowsPerPage="Registros por página"
                                                    onChangePage={(_, newPage) => changePage(_, newPage, 'person_type')}
                                                    onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'person_type')}
                                                />
                                            </Grid>
                                        </MonitoringTarget>
                                        <MonitoringTarget
                                            name="organization"
                                            current={values.monitoring_target}
                                        >
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table className={classes.table} size="small" aria-label="organization list">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.tableHead}>ID</TableCell>
                                                                <TableCell className={classes.tableHead}>Nome</TableCell>
                                                                <TableCell className={classes.tableHead}>Parente</TableCell>
                                                                <TableCell className={classes.tableHead}>
                                                                    <Checkbox
                                                                        name="addAllOrgs"
                                                                        color="primary"
                                                                        checked={orgs.every(el => {
                                                                            return selectedOrgs.includes(el.id);
                                                                        })}
                                                                        onChange={({ target }) => manipulateCheckBox('all_organizations', target.checked)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {!!orgs && orgs.map(org => (
                                                                <TableRow key={org.id}>
                                                                    <TableCell className={classes.tableBody} component="th" scope="row">
                                                                        {org.id}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {org.name}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {org.father_node?.name ?? "-"}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        <Checkbox
                                                                            name="addOrg"
                                                                            color="primary"
                                                                            checked={selectedOrgs.includes(org.id)}
                                                                            onChange={({ target }) => manipulateCheckBox('organization', target.checked, org.id)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30]}
                                                    component="div"
                                                    count={orgsTotal}
                                                    rowsPerPage={orgsLimit}
                                                    page={orgsPage}
                                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                    labelRowsPerPage="Registros por página"
                                                    onChangePage={(_, newPage) => changePage(_, newPage, 'organization')}
                                                    onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'organization')}
                                                />
                                            </Grid>
                                        </MonitoringTarget>
                                    </Grid>
                                </Popup>
                                <Popup
                                    openPopup={openNotifyPopup}
                                    setOpenPopup={setOpenNotifyPopup}
                                    title="Adcione pessoas a receber essa notificação"
                                    width="80%"
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='center'>
                                                <Grid item xs={6}>
                                                    <Input
                                                        icon={<SearchIcon style={{ color: grey[400] }} />}
                                                        iconPosition="start"
                                                        textFieldProps={{
                                                            placeholder: 'Buscar…',
                                                            onChange: ({ target }) => setNotifySearch(target.value),
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Paper
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                        }}
                                                        variant="outlined"
                                                        onClick={handleOnlySelectedToNotify}
                                                    >
                                                        <Checkbox
                                                            name="only_selected_people_to_notify"
                                                            checked={onlySelectedToNotify}
                                                            color="primary"
                                                        />
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                Mostrar apenas pessoas selecionadas
                                                            </Typography>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Paper
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                        }}
                                                        variant="outlined"
                                                        onClick={() => setFieldValue('self_notificate', !values.self_notificate)}
                                                    >
                                                        <Checkbox
                                                            name="self_notificate"
                                                            checked={values.self_notificate}
                                                            color="primary"
                                                        />
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                Notificar pessoas monitoradas
                                                            </Typography>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Paper
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                        }}
                                                        variant="outlined"
                                                        onClick={() => setFieldValue('notificate_dependant_of', !values.notificate_dependant_of)}
                                                    >
                                                        <Checkbox
                                                            name="notificate_dependant_of"
                                                            checked={values.notificate_dependant_of}
                                                            color="primary"
                                                        />
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                Notificar vínculos de dependência
                                                                &nbsp;
                                                                <HelpTip
                                                                    tip={
                                                                        <Typography>
                                                                            Notificar as pessoas das quais as monitoradas tem vínculo de dependência
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </Typography>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small" aria-label="notify list">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHead}>ID</TableCell>
                                                            <TableCell className={classes.tableHead}>Nome</TableCell>
                                                            <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                                            <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                                            <TableCell className={classes.tableHead}>Telefone</TableCell>
                                                            <TableCell className={classes.tableHead}>Email</TableCell>
                                                            <TableCell className={classes.tableHead}>Tipo de Pessoa</TableCell>
                                                            <TableCell className={classes.tableHead}>Organização</TableCell>
                                                            <TableCell className={classes.tableHead}>
                                                                <Checkbox
                                                                    name="addAllToNotify"
                                                                    color="primary"
                                                                    // checked={selectedToNotify.length === toNotify.length}
                                                                    checked={toNotify.every(el => {
                                                                        return selectedToNotify.includes(el.id);
                                                                    })}
                                                                    onChange={({ target }) => manipulateCheckBox('all_to_notify', target.checked)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {!!toNotify && toNotify.map(notify => (
                                                            <TableRow key={notify.id}>
                                                                <TableCell className={classes.tableBody} component="th" scope="row">
                                                                    {notify.id}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.name}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.doc_type && notify?.doc_type === 'ic' ? 'RG' :
                                                                        notify?.doc_type === 'passport' ? 'Passaporte' :
                                                                            notify?.doc_type === 'other' ? 'Outro' :
                                                                                notify?.doc_type === 'unknown' ? 'CPF' :
                                                                                    'Não identificado'}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.doc_number ? notify.doc_number : "Não identificado"}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.phone !== null ? ((notify.phone.length === 10) ? (
                                                                        mask('(##) ####-####', notify.phone)
                                                                    ) : (
                                                                        mask('(##) #####-####', notify.phone)
                                                                    )) : 'Não informado'}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.email !== null ? (notify.email) : 'Não informado'}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.PersonType ? notify.PersonType.name : "-"}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.OrganizationalStructure ? notify.OrganizationalStructure.name : "-"}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    <Checkbox
                                                                        name="addToNotify"
                                                                        color="primary"
                                                                        checked={selectedToNotify.includes(notify.id)}
                                                                        onChange={({ target }) => manipulateCheckBox('notify', target.checked, notify.id)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 30]}
                                                component="div"
                                                count={notifyTotal}
                                                rowsPerPage={notifyLimit}
                                                page={notifyPage}
                                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                labelRowsPerPage="Registros por página"
                                                onChangePage={(_, newPage) => changePage(_, newPage, 'notify')}
                                                onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'notify')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Popup>
                                <Popup
                                    openPopup={openDevicesPopup}
                                    setOpenPopup={setOpenDevicesPopup}
                                    title="Adcione dispositivos á notificação"
                                >
                                    <Grid container spacing={3}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={6}>
                                                <Input
                                                    icon={<SearchIcon style={{ color: grey[400] }} />}
                                                    iconPosition="start"
                                                    textFieldProps={{
                                                        placeholder: 'Buscar…',
                                                        onChange: ({ target }) => setDevicesSearch(target.value),
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Paper
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    variant="outlined"
                                                    onClick={handleOnlySelectedDevices}
                                                >
                                                    <Checkbox
                                                        name="only_selected_devices"
                                                        checked={onlySelectedDevices}
                                                        color="primary"
                                                    />
                                                    <div>
                                                        <Typography variant="subtitle2">
                                                            Mostrar apenas dispositivos selecionados
                                                        </Typography>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small" aria-label="devices list">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHead}>ID</TableCell>
                                                            <TableCell className={classes.tableHead}>Nome</TableCell>
                                                            <TableCell className={classes.tableHead}>Descrição</TableCell>
                                                            <TableCell className={classes.tableHead}>
                                                                <Checkbox
                                                                    name="addAllDevices"
                                                                    color="primary"
                                                                    // checked={selectedDevices.length === devices.length}
                                                                    checked={devices.every(el => {
                                                                        return selectedDevices.includes(el.id);
                                                                    })}
                                                                    onChange={({ target }) => manipulateCheckBox('all_devices', target.checked)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {!!devices && devices.map(device => (
                                                            <TableRow key={device.id}>
                                                                <TableCell className={classes.tableBody} component="th" scope="row">
                                                                    {device.id}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {device.name}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {device.description}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    <Checkbox
                                                                        name="addDevice"
                                                                        color="primary"
                                                                        checked={selectedDevices.includes(device.id)}
                                                                        onChange={({ target }) => manipulateCheckBox('device', target.checked, device.id)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 30]}
                                                component="div"
                                                count={devicesTotal}
                                                rowsPerPage={devicesLimit}
                                                page={devicesPage}
                                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                labelRowsPerPage="Registros por página"
                                                onChangePage={(_, newPage) => changePage(_, newPage, 'device')}
                                                onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'device')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Popup>
                                <Popup
                                    openPopup={openCamerasPopup}
                                    setOpenPopup={setOpenCamerasPopup}
                                    title="Adcione câmeras á notificação"
                                >
                                    <Grid container spacing={3}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={6}>
                                                <Input
                                                    icon={<SearchIcon style={{ color: grey[400] }} />}
                                                    iconPosition="start"
                                                    textFieldProps={{
                                                        placeholder: 'Buscar…',
                                                        onChange: ({ target }) => setCamerasSearch(target.value),
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Paper
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    variant="outlined"
                                                    onClick={handleOnlySelectedCameras}
                                                >
                                                    <Checkbox
                                                        name="only_selected_cameras"
                                                        checked={onlySelectedCameras}
                                                        color="primary"
                                                    />
                                                    <div>
                                                        <Typography variant="subtitle2">
                                                            Mostrar apenas cameras selecionadas
                                                        </Typography>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small" aria-label="camera list">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHead}>ID</TableCell>
                                                            <TableCell className={classes.tableHead}>Nome</TableCell>
                                                            <TableCell className={classes.tableHead}>Tipo</TableCell>
                                                            <TableCell className={classes.tableHead}>
                                                                <Checkbox
                                                                    name="addAllCameras"
                                                                    color="primary"
                                                                    // checked={selectedCameras.length === cameras.length}
                                                                    checked={cameras.every(el => {
                                                                        return selectedCameras.includes(el.id);
                                                                    })}
                                                                    onChange={({ target }) => manipulateCheckBox('all_cameras', target.checked)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {!!cameras && cameras.map(camera => (
                                                            <TableRow key={camera.id}>
                                                                <TableCell className={classes.tableBody} component="th" scope="row">
                                                                    {camera.id}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {camera.name}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {(camera.type === 'entry') ? 'Entrada' : (camera.type === 'internal') ? 'Interna' : (camera.type === 'exit') ? 'Saída' : 'Desconhecido'}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    <Checkbox
                                                                        name="addCamera"
                                                                        color="primary"
                                                                        checked={selectedCameras.includes(camera.id)}
                                                                        onChange={({ target }) => manipulateCheckBox('camera', target.checked, camera.id)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 30]}
                                                component="div"
                                                count={camerasTotal}
                                                rowsPerPage={camerasLimit}
                                                page={camerasPage}
                                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                labelRowsPerPage="Registros por página"
                                                onChangePage={(_, newPage) => changePage(_, newPage, 'camera')}
                                                onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'camera')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Popup>
                            </>
                        )
                        }
                    </Formik >
                </Grid >
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main >
        </div >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: "bold",
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    tableRow: {
        transition: '0.2s',
        '&:hover': {
            backgroundColor: grey[300],
        }
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    statusLabel: {
        display: 'block',
        fontWeight: 'bold',
        color: grey[500],
        marginBottom: theme.spacing(1),
    },
    editor: {
        height: '540px',
        padding: '10px',
    },
    editorToolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid #cdcdcd'
    },
}));
