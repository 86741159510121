import React, { useState, useReducer, useEffect, forwardRef, useImperativeHandle } from "react";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    ButtonGroup,
    Button
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import InputStartEndTime from "./InputStartEndTime";
import { grey } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import RestartIconn from '@material-ui/icons/Replay';
import PasteIcon from '@mui/icons-material/ContentPaste';
import CopyIcon from '@mui/icons-material/ContentCopy';

const reduceSchedule = (state, action) => {
    let newState = {};
    switch (action.action) {
        case 'change_one':
            newState = {
                ...state,
                [action.day]: { ...state[action.day], [action.period]: { ...state[action.day][action.period], value: action.value } },
            };
            action.setSchedule(newState);
            return newState;
        case 'activate':
            newState = {
                ...state,
                [action.day]: { ...state[action.day], [action.period]: { ...state[action.day][action.period], active: action.value } },
            };
            action.setSchedule(newState);
            return newState
        case 'replicate':
            newState = {
                ...state,
                [action.day]: { ...action.content }
            };
            action.setSchedule(newState);
            return newState;
        case 'reset_day':
            newState = {
                ...state,
                [action.day]: {
                    first_period: {
                        active: true,
                        value: null
                    },
                    second_period: {
                        active: false,
                        value: null
                    },
                    third_period: {
                        active: false,
                        value: null
                    },
                    fourth_period: {
                        active: false,
                        value: null
                    }
                },
            };
            action.setSchedule(newState);
            return newState;
        case 'full_access':
            newState = fullAccessState;
            action.setSchedule(newState);
            console.log('full access')
            return newState;
        case 'load':
            newState = action.schedule;
            return newState;
        case 'reset':
            newState = initialState;
            action.setSchedule(newState);
            return newState;
        default: return state;
    };
}

const periods = {
    first_period: '1º Período',
    second_period: '2º Período',
    third_period: '3º Período',
    fourth_period: '4º Período',
};

const days = {
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sabado',
    sunday: 'Domingo'
};

const initialState = {
    sunday: {
        first_period: {
            active: true,
            value: null
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    monday: {
        first_period: {
            active: true,
            value: null
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    tuesday: {
        first_period: {
            active: true,
            value: null
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    wednesday: {
        first_period: {
            active: true,
            value: null
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    thursday: {
        first_period: {
            active: true,
            value: null
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    friday: {
        first_period: {
            active: true,
            value: null
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    saturday: {
        first_period: {
            active: true,
            value: null
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
};

const fullAccessState = {
    sunday: {
        first_period: {
            active: true,
            value: '00:00 - 23:59'
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    monday: {
        first_period: {
            active: true,
            value: '00:00 - 23:59'
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    tuesday: {
        first_period: {
            active: true,
            value: '00:00 - 23:59'
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    wednesday: {
        first_period: {
            active: true,
            value: '00:00 - 23:59'
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    thursday: {
        first_period: {
            active: true,
            value: '00:00 - 23:59'
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    friday: {
        first_period: {
            active: true,
            value: '00:00 - 23:59'
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
    saturday: {
        first_period: {
            active: true,
            value: '00:00 - 23:59'
        },
        second_period: {
            active: false,
            value: null
        },
        third_period: {
            active: false,
            value: null
        },
        fourth_period: {
            active: false,
            value: null
        },
    },
};

const SchedulePicker = forwardRef(({ setMessage, setSchedule, loadingSchedule, multiPeriod = true }, ref) => {

    const classes = useStyles();

    const [schedule, dispatchSchedule] = useReducer(reduceSchedule, initialState);

    const [copiedContent, setCopiedContent] = useState(null);

    useEffect(() => {
        // console.log('initiated')
        setSchedule(initialState);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (loadingSchedule) {
            // console.log({ loadingSchedule });
            dispatchSchedule({ action: 'load', schedule: loadingSchedule });
        }
    }, [loadingSchedule]);

    useImperativeHandle(ref, () => ({
        resetValues() {
            dispatchSchedule({ action: 'reset', setSchedule });
        },
        setFullAccess(checked) {
            if (checked) dispatchSchedule({ action: 'full_access', setSchedule });
            else dispatchSchedule({ action: 'reset', setSchedule });
        }
    }));

    const copyContent = (day) => {
        setCopiedContent(schedule[day]);
        setMessage({
            open: true,
            variant: 'success',
            text: `Conteúdo de ${days[day]} copiado com sucesso.`,
        });
    }

    const pasteContent = (day) => {
        if (!copiedContent) {
            setMessage({
                open: true,
                variant: 'error',
                text: 'Não há conteúdo copiado',
            });
        } else {
            dispatchSchedule({ action: 'replicate', day, content: copiedContent, setSchedule })
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="schedule-picker">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHead}>Dias</TableCell>
                                <TableCell className={classes.tableHead}>Horários</TableCell>
                                <TableCell className={classes.tableHead}>Resetar/Copiar/Colar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Object.keys(days).map((day, index) => {
                                    return (
                                        <TableRow key={`r-${index}`}>
                                            <TableCell className={[classes.tableHead, classes.borders]} component="th" scope="row">
                                                {days[day]}
                                            </TableCell>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                <Grid container justify='center'>
                                                    {
                                                        // eslint-disable-next-line
                                                        Object.keys(periods).map((period, i, allPeriods) => {
                                                            if (schedule[day][period].active) {
                                                                return (
                                                                    <>
                                                                        {
                                                                            (i !== 0) && (
                                                                                <div className={classes.divider} />
                                                                            )
                                                                        }
                                                                        <Grid
                                                                            item
                                                                            key={`i-${i}`}
                                                                            style={{
                                                                                marginRight: '10px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                            }}
                                                                        >
                                                                            <InputStartEndTime
                                                                                style={{
                                                                                    paddingTop: 8,
                                                                                    paddingBottom: 8,
                                                                                    backgroundColor: '#FFF',
                                                                                    border: 'none'
                                                                                }}
                                                                                onValidFormat={(value) => dispatchSchedule({ action: 'change_one', day, period, value: value, setSchedule })}
                                                                                onInvalidFormat={async (value) => {
                                                                                    dispatchSchedule({ action: 'change_one', day, period, value: value, setSchedule })
                                                                                    console.log('Invalid time format')
                                                                                    await setMessage({
                                                                                        open: true,
                                                                                        variant: 'error',
                                                                                        text: "Valor de tempo inválido",
                                                                                    })
                                                                                }
                                                                                }
                                                                                className={classes.input}
                                                                                textFieldProps={{
                                                                                    placeholder: '00:00 - 23:59',
                                                                                    value: schedule[day][period].value,
                                                                                    required: false,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        {
                                                                            (((i < 3) && (multiPeriod)) && (schedule[day][allPeriods[i + 1]].active === false)) && (
                                                                                <Grid item key={`b-${i}`}>
                                                                                    <Button
                                                                                        variant='contained'
                                                                                        color='primary'
                                                                                        onClick={() => dispatchSchedule({ action: 'activate', day, period: allPeriods[i + 1], value: true, setSchedule })}
                                                                                    >
                                                                                        <AddIcon />
                                                                                    </Button>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </Grid>
                                            </TableCell>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                <Grid container justify='space-around'>
                                                    <Grid item>
                                                        <ButtonGroup>
                                                            <Button
                                                                variant='contained'
                                                                color='primary'
                                                                onClick={() => dispatchSchedule({ action: 'reset_day', day, setSchedule })}
                                                            >
                                                                <RestartIconn />
                                                            </Button>
                                                            <Button
                                                                variant='contained'
                                                                color='primary'
                                                                onClick={() => copyContent(day)}
                                                            >
                                                                <CopyIcon />
                                                            </Button>
                                                            <Button
                                                                variant='contained'
                                                                color='primary'
                                                                onClick={() => pasteContent(day)}
                                                            >
                                                                <PasteIcon />
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid >
    );
});

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
        width: '100%',
    },
    input: {
        border: 'none',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
        background: '#f0f0f0'
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    borders: { borderBottom: '8px solid #f0f0f0', borderRight: '15px solid #f0f0f0' },
    divider: {
        borderLeft: '2px solid #cdcdcd',
        padding: '0 5px'
    }
}));

export default SchedulePicker;