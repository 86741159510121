import React, { useState, useEffect, useRef, useCallback, useReducer, useContext } from 'react';
import { usePlaces } from '../../hooks';

import {
    Toolbar,
    Paper,
    Button,
    Grid,
    Typography,
    Checkbox,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TablePagination,
    FormControlLabel,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import { grey, indigo } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import SaveIcon from '@material-ui/icons/Save';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import UploadIcon from '@mui/icons-material/Upload';
import ConvertIcon from '@material-ui/icons/Loop';

import Webcam from 'react-webcam';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';
import TimePicker from '../../components/TimePicker';
import DaysPicker from '../../components/DaysPicker';
import Popup from '../../components/Popup';
import AccessPicker from '../../components/AccessPicker';
import CredentialsManager from '../../components/CredentialsManager';
import Autocomplete from '../../components/Autocomplete';
import RedirectToUser from '../../components/RedirectToUser';

import api from '../../services/api';
import date from '../../utils/date';

import config from '../../configs/config';
import { PermissionsContext } from '../../providers/Permissions';
const { host, port } = config;

const validationInitialState = {
    start_time: new Date().setHours(0, 0, 0, 0),
    end_time: new Date().setHours(23, 59, 59, 0),
};

const handleValidationDate = (state, action) => {
    switch (action.type) {
        case 'change':
            return {
                ...state,
                [action.time]: action.value && action.value.format(),
            };
        case 'reset':
            return validationInitialState;
        case 'load':
            if (!action.value)
                return state;

            let visitingTime = new Date();
            visitingTime.setHours(action.value.split(':')[0]);
            visitingTime.setMinutes(parseInt(action.value.split(':')[1]), 0, 0);
            // console.log(visitingTime)
            return {
                ...state,
                [action.time]: date(visitingTime).format(),
            }
        default: return state;
    }
};

export default function GuestModify() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const [openGoBack, setOpenGoBack] = useState(false);

    const accessRef = useRef(null);
    const credentialsRef = useRef();

    const [guest, setGuest] = useState({
        name: '',
        birthday: null,
        sex: 'unknown',
        doc_type: '',
        doc_number: null,
        issuing_agency: null,
        email: null,
        phone: null,
        role: 'visitor',
        face_image: null,
        validation_date: null,
        constant_visiting: true,
        visiting_location: null,
        notes: '',
        user_custom_inputs: [],
        only_allowed_by_related_access: false,
    });

    const [placesSearch, setPlacesSearch] = useState("");
    const [selectedPlace, setSelectedPlace] = useState({});
    const places = usePlaces({
        search: placesSearch,
    });
    const [cameraOpened, setCameraOpened] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const [validationDate, dispatchValidationDate] = useReducer(handleValidationDate, validationInitialState);
    const [days, setDays] = useState(null);

    const webcamRef = useRef(null);
    const daysRef = useRef(null);

    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        const loadUser = async () => {
            try {
                const result = await api.get(`/guest/detail/${id}`);

                let {
                    user,
                    inputs,
                    allowedGroups,
                    allowedDevices,
                    allowedCameras,
                    dependantRelationships: dependant,
                } = result.data;

                accessRef?.current?.load({
                    loadingGroups: allowedGroups,
                    loadingDevices: allowedDevices,
                    loadingCameras: allowedCameras,
                });

                credentialsRef?.current?.load(user?.credentials);

                // console.log({ inputs })
                user.user_custom_inputs = inputs;

                setUserCustomInputs(inputs)

                if (user.face_image) {
                    const image = await dataUrlToFile(`http://${host}:${port}/midias/imagem/face/${user.face_image}`);

                    user.face_image = image;
                }

                setDays(user.visiting_days);
                setDependantRelationships(dependant);
                dispatchValidationDate({ type: 'load', time: 'start_time', value: user.visiting_start });
                dispatchValidationDate({ type: 'load', time: 'end_time', value: user.visiting_end });

                setGuest(user);

                if (user.OrganizationalStructure) {
                    setSelectedOrg(user.OrganizationalStructure);
                }

                if (user.place_to_visit) {
                    setSelectedPlace(user.place_to_visit);
                }
            }
            catch (err) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
                /* setTimeout(() => { */
                /*     history.goBack(); */
                /* }, 2000); */
            }
        };

        loadUser();
        // eslint-disable-next-line
    }, [id, history]);

    const [userCustomInputs, setUserCustomInputs] = useState([]);
    const [customInputs, setCustomInputs] = useState([]);
    const [loop, setLoop] = useState(0);

    useEffect(() => {
        const findInputs = async () => {
            try {
                const result = await api.get('/custom_input/list', {
                    params: {
                        target: ['all', 'guest'],
                        page: 0,
                        limit: 30,
                    }
                });

                const { custom_inputs } = result.data;

                setCustomInputs(custom_inputs);
            }
            catch (err) {
                console.log(err);
            }
        };

        findInputs();
    }, []);

    const dataUrlToFile = async dataUrl => {
        const image = await fetch(dataUrl)
            .then(res => res.arrayBuffer())
            .then(buffer => new File([buffer], 'face_image.jpeg', { type: 'image/jpg' }));

        return image;
    };

    const [loadedImage, setLoadedImage] = useState(null);

    const captureImage = useCallback(async setFieldValue => {
        const dataUrl = webcamRef.current.getScreenshot();

        if (dataUrl) {
            const image = await dataUrlToFile(dataUrl);

            setFieldValue('face_image', image);
            // console.log('caputured face image');
        }
    }, [webcamRef]);

    const uploadImage = (e, setFieldValue) => {
        // console.log(e)
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setFieldValue('face_image', e.target.files[0]);
                // console.log('uploaded face image')
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const ImagePreview = ({ image }) => {
        if (loadedImage === null) {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setLoadedImage(reader.result)
                }
            }
            reader.readAsDataURL(image);
            // console.log('image converted')
        }
        // console.log('rendering preview')

        return (
            <img
                src={loadedImage}
                alt="Imagem de identificação"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
        );
    };

    const saveUser = async (values, formik) => {
        try {
            //return;
            const data = new FormData();
            // console.log({
            //     ...values,
            //     device_id: values.device_id.filter(value => !!value),
            //     selected_users: selectedUsers,
            //     visiting_days: days,
            //     visiting_start: date(validationDate.start_time).format('HH:mm'),
            //     visiting_end: date(validationDate.end_time).format('HH:mm')
            // })
            data.append('face_image', values.face_image);

            values.org_id = selectedOrg.id;
            values.visiting_location = selectedPlace.id ?? null;

            const userData = {
                ...values,
                visiting_days: days,
                visiting_start: date(validationDate.start_time).format('HH:mm'),
                visiting_end: date(validationDate.end_time).format('HH:mm'),
                dependant_relationships: dependantRelationships,
                credentials: credentialsRef.current.getValues(),
                ...accessRef.current.getValues(),
            };

            userData.face_image = undefined;

            data.append('user_data', JSON.stringify(userData));
            // console.log("user data :|", userData)
            const result = await api.post(`/guest/modify/${id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const { user } = result.data;

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Visitante ${user.name} modificado com sucesso!`,
            });

            setTimeout(() => setOpenGoBack(true), 1500);
        }
        catch (err) {
            // console.log(err)
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const formatUserData = values => {
        if (values.phone)
            values.phone = values.phone.replace(/\D/g, '');
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const handleConstantVisiting = (setValue, { value: checked }) => {
        // console.log(checked)
        setValue('constant_visiting', checked);
        if (checked) {
            setValue('validation_date', null);
        } else {
            daysRef.current.resetValues();
            setValue('validation_date', date().format());
        }
    }

    const [dependantSearch, setDependantSearch] = useState('');
    const [dependantLimit, setDependantLimit] = useState(10);
    const [dependantPage, setDependantPage] = useState(0);
    const [dependantTotal, setDependantTotal] = useState(0);
    const [dependantResult, setDependantResult] = useState([]);
    const [fetchOnlySelectedDependant, setFetchOnlySelectedDependant] = useState(false);

    const [dependantRelationships, setDependantRelationships] = useState([]);
    const [openDependantModal, setOpenDependantModal] = useState(false);

    const handleOnlySelectedDependant = (checked) => {
        setDependantPage(0);
        setFetchOnlySelectedDependant(checked);
    }

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const result = await api.get('/user/list_by_relationship', {
                    params: {
                        search: dependantSearch,
                        limit: dependantLimit,
                        page: dependantPage,
                        user_id: id,
                        onlySelected: fetchOnlySelectedDependant,
                        selectedPeople: dependantRelationships,
                    },
                });

                const { count, users } = result.data;

                console.log({ users })

                setDependantResult(users);
                setDependantTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadUsers();

        //eslint-disable-next-line
    }, [dependantPage, dependantLimit, dependantSearch, fetchOnlySelectedDependant, id]);

    const [orgsSelect, setOrgsSelect] = useState([]);
    const [orgSearch, setOrgSearch] = useState("");
    const [selectedOrg, setSelectedOrg] = useState({});

    useEffect(() => {
        const findOrg = async () => {
            try {
                const result = await api.get('/organizational_structure/index', {
                    params: {
                        search: orgSearch,
                    }
                });

                const { orgs } = result.data;

                setOrgsSelect(orgs);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findOrg();
    }, [orgSearch]);

    const changePage = (_, newPage) => {
        setDependantPage(newPage);
    }

    const quantityPerPage = (target) => {
        setDependantLimit(parseInt(target.value, 10));
        setDependantPage(0);
    };

    const checkDependant = (type, isSelected, id) => {
        if (type === "single") {
            let newSelectedUsers = Object.assign([], dependantRelationships);
            if (isSelected) {
                newSelectedUsers.push(id);
            } else {
                let index = dependantRelationships.indexOf(id);
                newSelectedUsers.splice(index, 1);
            }
            setDependantRelationships(newSelectedUsers);
        } else if (type === 'all') {
            let allUsers = [];
            if (isSelected) {
                allUsers = Object.assign([], dependantRelationships);
                dependantResult.forEach(user => {
                    if (!allUsers.includes(user.id)) {
                        allUsers.push(user.id)
                    }
                });
            } else {
                let userIds = dependantResult.map(el => el.id);
                allUsers = dependantRelationships.filter(user => !userIds.includes(user));
            }
            setDependantRelationships(allUsers);
        }
    };

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const deleteGuest = async () => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/guest/delete/${id}`);

            const { success } = result.data;

            setIsDeleting(false);

            if (success) {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Visitante ${guest.name} deletado com sucesso!`,
                });
            }
            history.goBack();
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    const [personTypeSearch, setPersonTypeSearch] = useState();
    const [personTypes, setPersonTypes] = useState([]);
    const [selectedPersonType, setSelectedPersonType] = useState({});

    const [openGuestConversion, setOpenGuestConversion] = useState(false);

    const handleCloseGuestConversion = () => {
        setSelectedPersonType({});
        setSelectedOrg(null);
    };

    useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/person_type/list', {
                    params: {
                        search: personTypeSearch,
                        show_exceptions: false,
                        limit: 20,
                    }
                });

                const { personTypes } = result.data;

                setPersonTypes(personTypes);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, [personTypeSearch]);

    const [submittingConversion, setSubmittingConversion] = useState(false);

    const submitGuestConversion = async () => {
        setSubmittingConversion(true);
        try {

            if (!selectedPersonType?.id)
                throw new Error({ error: "O tipo de pessoa é obrigatório" });

            const result = await api.get("/guest/convert", {
                params: {
                    user_id: id,
                    new_person_type_id: selectedPersonType.id,
                }
            });
            console.log({ result })
            console.log({ status: result.status });

            if (result.status === 204) {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: "Sucesso ao tranferir, aguarde o redirecionamento...",
                });
                setTimeout(() => history.push(`/pessoa/modificar/${id}`), 2000);
            }

        } catch (err) {
            console.log(err)
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
        setSubmittingConversion(false);
    }

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid container xs={12}>
                        <Typography xs={6} variant="h5" className={classes.title}>
                            Editar Visitante
                        </Typography>
                    </Grid>
                    <Formik
                        enableReinitialize={true}
                        initialValues={guest}
                        validate={formatUserData}
                        onSubmit={saveUser}
                    >
                        {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="guest-modify" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3} className={classes.formSection} >
                                                    <div className={classes.faceImageContainer}
                                                        style={{
                                                            height: '300px',
                                                            width: '350px',
                                                        }}
                                                    >
                                                        <div
                                                            className={classes.faceImageContent}
                                                            style={{
                                                                width: '100%',
                                                                height: '200px',
                                                            }}
                                                        >
                                                            {!cameraOpened ? (
                                                                values.face_image ? (
                                                                    <ImagePreview image={values.face_image} />
                                                                ) : (
                                                                    <div className={classes.noImage}>
                                                                        <PhotoCameraIcon />
                                                                        <Typography variant="subtitle2">
                                                                            SEM IMAGEM
                                                                        </Typography>
                                                                    </div>
                                                                )
                                                            ) : (
                                                                <Webcam
                                                                    ref={webcamRef}
                                                                    audio={false}
                                                                    screenshotFormat="image/jpeg"
                                                                    screenshotQuality={1}
                                                                    forceScreenshotSourceSize={true}
                                                                    // width="100%"
                                                                    height="100%"
                                                                />
                                                            )}
                                                        </div>
                                                        {values.face_image && (
                                                            <Button
                                                                className={classes.removeImageButton}
                                                                size="small"
                                                                type="button"
                                                                variant="outlined"
                                                                color="secondary"
                                                                endIcon={<DeleteIcon />}
                                                                fullWidth
                                                                onClick={() => {
                                                                    setFieldValue('face_image', null);
                                                                    setLoadedImage(null);
                                                                }}
                                                            >
                                                                Remover
                                                            </Button>
                                                        )}
                                                        {
                                                            !values.face_image && (
                                                                <>
                                                                    <Button
                                                                        className={classes.faceImageButton}
                                                                        type="button"
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        endIcon={<PhotoCameraIcon />}
                                                                        fullWidth
                                                                        onClick={async () => {
                                                                            if (cameraOpened) await captureImage(setFieldValue);

                                                                            setCameraOpened(!cameraOpened);
                                                                        }}
                                                                    >
                                                                        {cameraOpened ? 'Tirar foto' : 'Abrir câmera'}
                                                                    </Button>
                                                                    {
                                                                        !cameraOpened && (
                                                                            <Button
                                                                                className={classes.faceImageButton}
                                                                                type="button"
                                                                                variant="outlined"
                                                                                color="primary"
                                                                                endIcon={<UploadIcon />}
                                                                                fullWidth
                                                                                component="label"
                                                                            >
                                                                                Adicionar Foto
                                                                                <input
                                                                                    name='map_image'
                                                                                    type="file"
                                                                                    accept="image/*"
                                                                                    onChange={(e) => uploadImage(e, setFieldValue)}
                                                                                    hidden
                                                                                    required={false}
                                                                                />
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                            DADOS PESSOAIS
                                                        </Typography>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={9}>
                                                                <Input
                                                                    label="Nome completo"
                                                                    textFieldProps={{
                                                                        name: 'name',
                                                                        placeholder: 'Nome completo',
                                                                        onChange: handleChange,
                                                                        value: values.name,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <DatePicker
                                                                    label="Data de nascimento"
                                                                    format="DD/MM/YYYY"
                                                                    id="date"
                                                                    value={values.birthday}
                                                                    placeholder="Data de nascimento"
                                                                    onChange={date => setFieldValue('birthday', date && date.format())}
                                                                    cancelLabel="cancelar"
                                                                    fullWidth={true}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={4}>
                                                                <Input
                                                                    label="Orgão Emissor"
                                                                    textFieldProps={{
                                                                        name: 'issuing_agency',
                                                                        placeholder: 'Orgão Emissor',
                                                                        onChange: handleChange,
                                                                        value: values.issuing_agency,
                                                                        fullWidth: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Select
                                                                    label="Tipo de Documento"
                                                                    options={[
                                                                        { label: '-- Selecione --', value: '' },
                                                                        { label: 'CPF', value: 'unknown' },
                                                                        { label: 'RG', value: 'ic' },
                                                                        { label: 'Passaporte', value: 'passport' },
                                                                        { label: 'Outro', value: 'other' },
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'doc_type',
                                                                        onChange: handleChange,
                                                                        fullWidth: true,
                                                                        value: values.doc_type,
                                                                        required: values.doc_number !== null && values.doc_number !== '',
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Input
                                                                    label="Número do Documento"
                                                                    textFieldProps={{
                                                                        name: 'doc_number',
                                                                        placeholder: 'Número do Documento',
                                                                        onChange: handleChange,
                                                                        value: values.doc_number,
                                                                        fullWidth: true,
                                                                        required: values.doc_type !== null && values.doc_type !== '',
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={3}>
                                                            <Grid item xs={4}>
                                                                <Select
                                                                    label="Sexo"
                                                                    options={[
                                                                        { label: '-- Selecione --', value: 'unknown', id: 'unknown' },
                                                                        { label: 'Masculino', value: 'male', id: 'male' },
                                                                        { label: 'Feminino', value: 'female', id: 'female' },
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'sex',
                                                                        onChange: handleChange,
                                                                        fullWidth: true,
                                                                        value: values.sex,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Input
                                                                    label="E-mail"
                                                                    textFieldProps={{
                                                                        name: 'email',
                                                                        placeholder: 'E-mail',
                                                                        onChange: handleChange,
                                                                        value: values.email,
                                                                        fullWidth: true,
                                                                        required: false,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Input
                                                                    label="Telefone"
                                                                    inputComponent={PhoneMask}
                                                                    textFieldProps={{
                                                                        name: 'phone',
                                                                        placeholder: 'Telefone',
                                                                        onChange: handleChange,
                                                                        value: values.phone,
                                                                        fullWidth: true,
                                                                        required: false,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    VALIDADE DO ACESSO
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    {
                                                        !values.constant_visiting && (
                                                            <Grid item xs={6} md={2}>
                                                                <DatePicker
                                                                    label="Dia da Visita"
                                                                    format="DD/MM/YYYY"
                                                                    id="date"
                                                                    value={values.validation_date}
                                                                    placeholder="Dia da visita"
                                                                    onChange={date => setFieldValue('validation_date', date && date.format())}
                                                                    cancelLabel="cancelar"
                                                                    fullWidth={true}
                                                                />
                                                            </Grid>
                                                        )
                                                    }
                                                    <Grid item xs={6} md={2}>
                                                        <TimePicker
                                                            label="Inicio"
                                                            format="HH:mm"
                                                            placeholder="Inicio da visita"
                                                            required={true}
                                                            fullWidth={true}
                                                            value={validationDate.start_time}
                                                            onChange={(value) => dispatchValidationDate({ type: 'change', time: 'start_time', value: value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} md={2}>
                                                        <TimePicker
                                                            label="Fim"
                                                            format="HH:mm"
                                                            placeholder="Fim da visita"
                                                            required={true}
                                                            fullWidth={true}
                                                            value={validationDate.end_time}
                                                            onChange={(value) => dispatchValidationDate({ type: 'change', time: 'end_time', value: value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} md={2}>
                                                        <Select
                                                            label="Visita Frequente"
                                                            options={[
                                                                {
                                                                    label: "Sim",
                                                                    value: true,
                                                                },
                                                                {
                                                                    label: "Não",
                                                                    value: false,
                                                                },
                                                            ]}
                                                            textFieldProps={{
                                                                name: "constant_visiting",
                                                                value: values.constant_visiting,
                                                                onChange:
                                                                    ({ target }) => handleConstantVisiting(setFieldValue, target),
                                                                fullWidth: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}
                                                style={{ display: (values.constant_visiting ? 'flex' : 'none') }}
                                            >
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    DIAS DE ACESSO
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    <DaysPicker setDays={setDays} ref={daysRef} loading={days} />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitleTwo}>
                                                    INFORMAÇÕES PERSONALIZADAS
                                                </Typography>
                                                <Grid container spacing={3}>
                                                    {userCustomInputs.map((input, index) => {
                                                        if (index === 0 && values.user_custom_inputs.length !== userCustomInputs.length && loop === 0) {
                                                            // console.log('0 loop')
                                                            setLoop(1)
                                                            values.user_custom_inputs = userCustomInputs

                                                        }


                                                        let label = '';
                                                        let is_required = '';
                                                        let ciType = '';
                                                        let ciOptions = [];
                                                        customInputs.forEach(ci => {
                                                            if (ci.id === input.custom_input_id) {
                                                                label = ci.name;
                                                                is_required = ci.is_required
                                                                ciType = ci?.type;
                                                                ciOptions = ci?.options;
                                                            }
                                                        });
                                                        // console.log({ values: values.user_custom_inputs })
                                                        return (
                                                            <Grid key={index} item xs={4}>
                                                                {
                                                                    ciType === "normal" ? (
                                                                        <Input
                                                                            label={label}
                                                                            textFieldProps={{
                                                                                name: `user_custom_inputs[${index}].information`,
                                                                                onChange: handleChange,
                                                                                value: values.user_custom_inputs[index]['information'] ? values.user_custom_inputs[index]['information'] : '',
                                                                                fullWidth: true,
                                                                                required: is_required,
                                                                            }}
                                                                        />
                                                                    ) : (

                                                                        <Autocomplete
                                                                            label={label}
                                                                            options={ciOptions?.length && ciOptions?.map((option) => ({ name: option, value: option, id: option }))}
                                                                            getOptionLabel={({ name }) => name || ''}
                                                                            onChange={(_, value) => setFieldValue(`user_custom_inputs[${index}].information`, value)}
                                                                            getOptionSelected={(option, value) => (option.id === value.id)}
                                                                            /* onInputChange={(_, value) => setPersonTypeSearch(value)} */
                                                                            value={values.user_custom_inputs[index]['information'] ? values.user_custom_inputs[index]['information'] : ''}
                                                                            required={is_required}
                                                                            fullWidth
                                                                        />
                                                                    )
                                                                }
                                                                {/* <Input */}
                                                                {/*     label={label} */}
                                                                {/*     textFieldProps={{ */}
                                                                {/*         name: `user_custom_inputs[${index}].information`, */}
                                                                {/*         onChange: handleChange, */}
                                                                {/*         value: values.user_custom_inputs[index]['information'] ? values.user_custom_inputs[index]['information'] : '', */}
                                                                {/*         fullWidth: true, */}
                                                                {/*         required: is_required, */}
                                                                {/*     }} */}
                                                                {/* /> */}
                                                            </Grid>
                                                        )

                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    OBSERVAÇÕES
                                                </Typography>
                                                <Grid item xs={12} style={{ height: '100%' }}>
                                                    <textarea
                                                        placeholder="Escreva o texto aqui"
                                                        multiline
                                                        minRows={20}
                                                        style={{ width: '100%', border: '1px solid #c6c6c6', minHeight: '200px', resize: 'vertical', padding: '10px', fontSize: '1.2rem', color: grey[600], fontFamily: 'Arial, sans-serif' }}
                                                        value={values.notes}
                                                        onChange={({ target }) => setFieldValue('notes', target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    VÍNCULOS
                                                </Typography>
                                                <Grid item xs={4}>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth={true}
                                                        onClick={() => setOpenDependantModal(true)}
                                                    >
                                                        Vínculos de dependência {`(${dependantRelationships.length})`}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    CREDENCIAIS
                                                </Typography>
                                                <Grid item xs={4}>
                                                    <CredentialsManager
                                                        ref={credentialsRef}
                                                        setMessage={setMessage}
                                                        userName={values.name}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    LOCAL DE ACESSO
                                                </Typography>
                                                <Grid item xs={12}>
                                                    <Grid item xs={4}>
                                                        <Autocomplete
                                                            id="visiting_location"
                                                            options={places}
                                                            getOptionLabel={({ name }) => name || ''}
                                                            onChange={(_, value) => setSelectedPlace(value)}
                                                            getOptionSelected={(option, value) => (option.id === value.id)}
                                                            onInputChange={(_, value) => setPlacesSearch(value)}
                                                            value={selectedPlace}
                                                            required={true}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    ORGANIZAÇÃO
                                                </Typography>
                                                <Grid item xs={12}>
                                                    <Grid item xs={4}>
                                                        <Autocomplete
                                                            label="Estrutura Organizacional"
                                                            placeholder="Estrutura Organizacional"
                                                            id="org_id"
                                                            options={orgsSelect}
                                                            getOptionLabel={({ name }) => name || ''}
                                                            onChange={(_, value) => setSelectedOrg(value)}
                                                            getOptionSelected={(option, value) => (option.id === value.id)}
                                                            onInputChange={(_, value) => setOrgSearch(value)}
                                                            value={selectedOrg}
                                                            required={true}
                                                            fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    PERMISSÕES
                                                </Typography>
                                                <Grid item xs={12}>
                                                    <Grid item xs={4}>
                                                        <Select
                                                            label="Apenas permitido por acesso do relacionado"
                                                            tip={(
                                                                <Typography>
                                                                    Essa pessoa so poderá acessar se um dos seus vínculos de depêndencia realizou uma entrada nos últimos 5 minutos.
                                                                </Typography>
                                                            )}
                                                            options={[
                                                                { label: "Não", value: false },
                                                                { label: "Sim", value: true },
                                                            ]}
                                                            textFieldProps={{
                                                                name: "only_allowed_by_related_access",
                                                                value: values.only_allowed_by_related_access,
                                                                onChange: handleChange,
                                                                fullWidth: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Typography
                                                    variant="subtitle1"
                                                    className={classes.formSectionTitle}
                                                    onClick={() => console.log(accessRef.current.getValues())}
                                                >
                                                    AUTORIZAÇÃO
                                                </Typography>
                                                <AccessPicker ref={accessRef} />
                                            </Grid>
                                            <Grid container justify="space-evenly">
                                                <PermissionWrapper route="/visitante/deletar/:id">
                                                    <Button
                                                        disabled={isDeleting}
                                                        variant="contained"
                                                        color="secondary"
                                                        endIcon={<DeleteIcon />}
                                                        onClick={() => setOpenDeletePopup(true)}

                                                    >
                                                        Deletar visitante
                                                    </Button>
                                                </PermissionWrapper>
                                                <PermissionWrapper route="/visitante/converter">
                                                    <Button
                                                        disabled={!!submittingConversion}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ background: '#ECB365' }}
                                                        onClick={() => setOpenGuestConversion(true)}
                                                        endIcon={<ConvertIcon />}
                                                    >
                                                        {
                                                            !!submittingConversion
                                                                ? "Transferindo..."
                                                                : "Tranferir para Regular"
                                                        }
                                                    </Button>
                                                </PermissionWrapper>
                                                <Button
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    form="guest-modify"
                                                    color="primary"
                                                    endIcon={<SaveIcon />}
                                                >
                                                    {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                </Button>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
            <Popup
                openPopup={openDeletePopup}
                setOpenPopup={setOpenDeletePopup}

            >
                <Grid container xs={12} style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 3% 2% 3%',

                }}>
                    <Typography variant='h5'
                        style={{
                            fontWeight: 'bold',
                            color: '#9e9e9e'
                        }}
                    >
                        Deseja mesmo deletar o visitante "{guest.name}"?
                    </Typography>
                    <Grid item style={{
                        marginTop: '50px'
                    }}>
                        <Button
                            disabled={isDeleting}
                            variant="contained"
                            onClick={() => deleteGuest()}
                            color="secondary"
                            endIcon={<DeleteIcon />}
                        >
                            {isDeleting ? 'Deletando...' : 'Sim, delete o usuário'}
                        </Button>
                    </Grid>

                </Grid>
            </Popup>
            <Popup
                openPopup={openDependantModal}
                setOpenPopup={setOpenDependantModal}
                title="Adcione pessoas das quais a atual depende"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Grid item xs={4}>
                            <Input
                                icon={<SearchIcon style={{ color: grey[400] }} />}
                                iconPosition="start"
                                textFieldProps={{
                                    placeholder: 'Buscar…',
                                    onChange: ({ target }) => setDependantSearch(target.value),
                                    values: dependantSearch,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                label="Mostrar apenas selecionados"
                                style={{
                                    userSelect: "none",
                                }}
                                checked={fetchOnlySelectedDependant}
                                onChange={({ target: { checked } }) => handleOnlySelectedDependant(checked)}
                                control={
                                    <Checkbox
                                        color='primary'

                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="users list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Pessoa</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllUsers"
                                                color="primary"
                                                // checked={selectedUsers.length === users.length}
                                                checked={dependantResult.every(el => {
                                                    return dependantRelationships.includes(el.id);
                                                })}
                                                onChange={({ target }) => checkDependant('all', target.checked)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!dependantResult && dependantResult.map(person => (
                                        <TableRow key={person.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {person.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <RedirectToUser pType={person.person_type_id} uId={person.id}>
                                                    {person.name}
                                                </RedirectToUser>
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.doc_type && person?.doc_type === 'ic' ? 'RG' :
                                                    person?.doc_type === 'passport' ? 'Passaporte' :
                                                        person?.doc_type === 'other' ? 'Outro' :
                                                            person?.doc_type === 'unknown' ? 'CPF' :
                                                                'Não identificado'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.doc_number ? person.doc_number : "Não identificado"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.PersonType?.name ? person.PersonType?.name : "Não identificado"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addUser"
                                                    color="primary"
                                                    checked={dependantRelationships.includes(person.id)}
                                                    onChange={({ target }) => checkDependant('single', target.checked, person.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={dependantTotal}
                            rowsPerPage={dependantLimit}
                            page={dependantPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage)}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target)}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openGoBack}
                setOpenPopup={setOpenGoBack}
                title="Voltar a listagem de pessoas?"
                width="30%"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 6,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "40%", fontWeight: 700 }}
                        size="large"
                        onClick={() => history.push("/pessoa")}
                    >
                        SIM
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ width: "40%", fontWeight: 700 }}
                        size="large"
                        onClick={() => setOpenGoBack(false)}
                    >
                        NÃO
                    </Button>
                </div>
            </Popup>
            <Popup
                openPopup={openGuestConversion}
                setOpenPopup={setOpenGuestConversion}
                title="Transferir Visitante para Regular"
                width="50%"
                onClose={handleCloseGuestConversion}
            >
                <Grid container alignItems="center" justify="center" spacing={3}>
                    <Grid item xs={7}>
                        <Autocomplete
                            label="Novo Tipo de Pessoa"
                            placeholder="Novo Tipo de pessoa"
                            id="type_of_person"
                            options={personTypes}
                            getOptionLabel={({ name }) => name || ''}
                            onChange={(_, value) => setSelectedPersonType(value)}
                            getOptionSelected={(option, value) => (option.id === value.id)}
                            onInputChange={(_, value) => setPersonTypeSearch(value)}
                            value={selectedPersonType}
                            required={true}
                            fullWidth />
                    </Grid>
                    <Grid item xs={7}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            onClick={submitGuestConversion}
                            disabled={!!submittingConversion}
                        >
                            {
                                !!submittingConversion
                                    ? "Tranferindo..."
                                    : "Transferir"
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            style={{
                                fontWeight: 700
                            }}
                        >
                            <i>OBS.:</i>
                            <br />
                            <i style={{ color: "rgba(115, 0, 30, 0.86)" }}>&#8226; Uma vez que a transferência é realizada não é possível reverter-la.</i>
                        </Typography>
                    </Grid>
                </Grid>
            </Popup>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    faceImageContainer: {
        width: '100%',
        height: '303px',
        border: `solid 1px ${indigo[200]}`,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    faceImageContent: {
        width: '100%',
        flex: 7,
        backgroundColor: grey[50],
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(.5),
    },
    faceImageButton: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        flex: 1,
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    noImage: {
        textAlign: 'center',
        color: grey[400],
    },
    cameraContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& h6 > span': {
            marginLeft: theme.spacing(.5),
        },
        '&:hover': {
            boxShadow: '0 2px 10px 1px rgba(150,150,150,.3)',
        },
    },
    tableContainer: {
        maxHeight: '95%',
        width: '100%',
    },
    table: {
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
}));

const PhoneMask = props => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'_'}
            showMask
        />
    );
};
