import React, { useState } from 'react';
import { Toolbar, Paper, Button, Grid, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Select from '../../components/Select';

import api from '../../services/api';

export default function FuncCreate() {
    const classes = useStyles();

    const initialValues = {
        name: '',
        router: '',
        url_accessible: false,
    };

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const saveFunc = async (values, formik) => {
        try {
            const funcData = {
                ...values
            };

            const result = await api.post('/functionality/create', funcData);
            const { func } = result.data;

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Funcionalidade ${func.name} cadastrado com sucesso!`,
            });
            resetForm(formik);
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const resetForm = formik => {
        formik.resetForm();
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Criar nova funcionalidade
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={saveFunc}
                    >
                        {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="func-create" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={9}>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                            Funcionalidades
                                                        </Typography>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={7}>
                                                                <Input
                                                                    label="Funcionalidade"
                                                                    textFieldProps={{
                                                                        name: 'name',
                                                                        placeholder: 'Criar Funcionalidade',
                                                                        onChange: handleChange,
                                                                        value: values.name,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Input
                                                                    label="Rota"
                                                                    textFieldProps={{
                                                                        name: 'router',
                                                                        placeholder: 'functionality/create',
                                                                        onChange: handleChange,
                                                                        value: values.router,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <Select
                                                                    label="Acessível por URL (atalhos)"
                                                                    options={[
                                                                        {
                                                                            label: "Sim",
                                                                            value: true,
                                                                        },
                                                                        {
                                                                            label: "Não",
                                                                            value: false,
                                                                        }
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'url_accessible',
                                                                        onChange: handleChange,
                                                                        fullWidth: true,
                                                                        value: values.url_accessible,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} container justify="flex-end">
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        form="func-create"
                                        color="primary"
                                        endIcon={<SaveIcon />}
                                    >
                                        {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    userRoles: {
        '& > button': {
            marginRight: theme.spacing(1),
        },
    },
}));

