import {
    useCallback,
} from "react";

import {
    Box,
    Grid,
    IconButton,

} from "@material-ui/core";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AllOutIcon from '@mui/icons-material/AllOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import api from "../services/api";

export default function PTZControls({
    selectedCamera = { id: 9, is_ptz: true },
}) {
    console.log({ selectedCamera })
    const requestMove = useCallback(async ({ x, y, zoom }) => {
        try {
            if (!selectedCamera)
                return;

            if (!selectedCamera?.is_ptz)
                return;

            const response = await api.get(`/external_cam/ptz/start/${selectedCamera.id}`, {
                params: {
                    x,
                    y,
                    zoom
                }
            });

            const result = await response.data;

            console.log({ result })
        } catch (error) {
            console.log(error)
        }
    }, [selectedCamera]);

    const requestStop = useCallback(async () => {
        try {
            if (!selectedCamera)
                return;

            if (!selectedCamera?.is_ptz)
                return;

            const response = await api.get(`/external_cam/ptz/stop/${selectedCamera.id}`);

            const result = await response.data;

            console.log({ result })
        } catch (error) {
            console.log(error)
        }
    }, [selectedCamera]);

    const moveLeft = useCallback(async () => {
        console.log("moving Left");

        await requestMove({ x: -0.5, y: 0, zoom: 0 });
    }, [requestMove]);

    const moveUpLeft = useCallback(async () => {
        console.log("moving Top Left");

        await requestMove({ x: -0.5, y: 0.5, zoom: 0 });
    }, [requestMove]);

    const moveRight = useCallback(async () => {
        console.log("moving Right");

        await requestMove({ x: 0.5, y: 0, zoom: 0 });
    }, [requestMove]);

    const moveUpRight = useCallback(async () => {
        console.log("moving Top Right");

        await requestMove({ x: 0.5, y: 0.5, zoom: 0 });
    }, [requestMove]);

    const moveUp = useCallback(async () => {
        console.log("moving Up");

        await requestMove({ x: 0, y: 0.5, zoom: 0 });
    }, [requestMove]);

    const moveDownLeft = useCallback(async () => {
        console.log("moving Down Left");

        await requestMove({ x: -0.5, y: -0.5, zoom: 0 });
    }, [requestMove]);

    const moveDownRight = useCallback(async () => {
        console.log("moving Down Right");

        await requestMove({ x: 0.5, y: -0.5, zoom: 0 });
    }, [requestMove]);

    const moveDown = useCallback(async () => {
        console.log("moving Down");

        await requestMove({ x: 0, y: -0.5, zoom: 0 });
    }, [requestMove]);

    const zoomIn = useCallback(async () => {
        console.log("zooming in");

        await requestMove({ x: 0, y: 0, zoom: 0.5 });
    }, [requestMove]);

    const zoomOut = useCallback(async () => {
        console.log("zooming out");

        await requestMove({ x: 0, y: 0, zoom: -0.5 });
    }, [requestMove]);

    return (
        <Grid
            container
            alignItems="center"
            justify="center"
            color="white"
            style={{
                width: "10rem",
            }}
        >
            <Grid item xs={12}>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                >
                    <Box>
                        <IconButton
                            style={{
                                color: "#fff",
                                backgroundColor: "rgba(176, 176, 186, 0.1)",
                                borderRadius: 15,
                            }}
                            disabled={false}
                            onMouseUp={requestStop}
                            onMouseDown={moveUpLeft}
                        >
                            <KeyboardArrowRightIcon
                                style={{
                                    transform: "rotate(225deg)",
                                }}
                            />
                        </IconButton>
                        <IconButton
                            style={{
                                color: "#fff",
                                backgroundColor: "rgba(176, 176, 186, 0.1)",
                                borderRadius: 15
                            }}
                            disabled={false}
                            onMouseUp={requestStop}
                            onMouseDown={moveUp}
                        >
                            <KeyboardArrowRightIcon
                                style={{
                                    transform: "rotate(270deg)",
                                }}
                            />
                        </IconButton>
                        <IconButton
                            style={{
                                color: "#fff",
                                backgroundColor: "rgba(176, 176, 186, 0.1)",
                                borderRadius: 15
                            }}
                            disabled={false}
                            onMouseUp={requestStop}
                            onMouseDown={moveUpRight}
                        >
                            <KeyboardArrowRightIcon
                                style={{
                                    transform: "rotate(315deg)",
                                }}
                            />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                >
                    <Box>
                        <IconButton
                            style={{
                                color: "#fff",
                                backgroundColor: "rgba(176, 176, 186, 0.1)",
                                borderRadius: 15
                            }}
                            disabled={false}
                            onMouseUp={requestStop}
                            onMouseDown={moveLeft}
                        >
                            <KeyboardArrowRightIcon
                                style={{
                                    transform: "rotate(180deg)",
                                }}
                            />
                        </IconButton>
                        <IconButton
                            style={{
                                color: "#fff",
                                backgroundColor: "rgba(176, 176, 186, 0.1)",
                                borderRadius: 15
                            }}
                        >
                            <AllOutIcon />
                        </IconButton>
                        <IconButton
                            style={{
                                color: "#fff",
                                backgroundColor: "rgba(176, 176, 186, 0.1)",
                                borderRadius: 15
                            }}
                            disabled={false}
                            onMouseUp={requestStop}
                            onMouseDown={moveRight}
                        >
                            <KeyboardArrowRightIcon
                                style={{
                                    transform: "rotate(0deg)",
                                }}
                            />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                >
                    <Box>
                        <IconButton
                            style={{
                                color: "#fff",
                                backgroundColor: "rgba(176, 176, 186, 0.1)",
                                borderRadius: 15
                            }}
                            disabled={false}
                            onMouseUp={requestStop}
                            onMouseDown={moveDownLeft}
                        >
                            <KeyboardArrowRightIcon
                                style={{
                                    transform: "rotate(135deg)",
                                }}
                            />
                        </IconButton>
                        <IconButton
                            style={{
                                color: "#fff",
                                backgroundColor: "rgba(176, 176, 186, 0.1)",
                                borderRadius: 15
                            }}
                            disabled={false}
                            onMouseUp={requestStop}
                            onMouseDown={moveDown}
                        >
                            <KeyboardArrowRightIcon
                                style={{
                                    transform: "rotate(90deg)",
                                }}
                            />
                        </IconButton>
                        <IconButton
                            style={{
                                color: "#fff",
                                backgroundColor: "rgba(176, 176, 186, 0.1)",
                                borderRadius: 15
                            }}
                            disabled={false}
                            onMouseUp={requestStop}
                            onMouseDown={moveDownRight}
                        >
                            <KeyboardArrowRightIcon
                                style={{
                                    transform: "rotate(45deg)",
                                }}
                            />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                >
                    <IconButton
                        style={{
                            color: "#fff",
                            backgroundColor: "rgba(176, 176, 186, 0.1)",
                            borderRadius: 15
                        }}
                        disabled={false}
                        onMouseUp={requestStop}
                        onMouseDown={zoomOut}
                    >
                        <ZoomOutIcon />
                    </IconButton>
                    <IconButton
                        style={{
                            color: "#fff",
                            backgroundColor: "rgba(176, 176, 186, 0.1)",
                            borderRadius: 15
                        }}
                        disabled={false}
                        onMouseUp={requestStop}
                        onMouseDown={zoomIn}
                    >
                        <ZoomInIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
}