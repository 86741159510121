import React from 'react';
import {
    Tooltip,
    IconButton,
} from '@material-ui/core';
import HelpIcon from "@material-ui/icons/HelpOutline";

export default function HelpTip({ tip }) {
    return (
        <Tooltip title={tip} arrow>
            <IconButton style={{ padding: 0 }}>
                <HelpIcon />
            </IconButton>
        </Tooltip>
    );
};