import React, { useState, useEffect, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
    Divider,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import {
    TreeView,
    TreeItem,
} from '@material-ui/lab';
import Popup from '../../components/Popup';
// import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Input from '../../components/Input';
import Alert from '../../components/Alert';
import InputSearch from '../../components/InputSearch';
import Checkbox from '@material-ui/core/Checkbox';

import api from '../../services/api';
import Select from '../../components/Select';
import { FormControlLabel } from '@mui/material';
import { PermissionsContext } from '../../providers/Permissions';

export default function UserList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    // Profile
    const [profiles, setProfiles] = useState([]);
    const [profile, setProfile] = useState(null);
    const [total, setTotal] = useState(0);
    const [newProfile, setNewProfile] = useState("");
    const [profileType, setProfileType] = useState("web");

    const [refresh, setRefresh] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletingProfile, setDeletingProfile] = useState({ id: null, name: null });

    // Functionality
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupCreater, setOpenPopupCreater] = useState(false);
    const [functionalityCheckeds, setFunctionalityCheckeds] = useState([]);

    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);

    const [endpoints, setEndpoints] = useState([]);
    const [selectedEndpoints, setSelectedEndpoints] = useState([]);
    const [endpointsPage, setEndpointsPage] = useState(0);
    const [endpointsLimit, setEndpointsLimit] = useState(10);
    const [endpointsTotal, setEndpointsTotal] = useState(0);

    const [requestingProfile, setRequestingProfile] = useState(false);

    const onCloseFunctionalitiesModal = () => {
        setFunctionalityCheckeds([]);
        setSelectedEndpoints([]);
        setExpanded([]);
        setSelected([]);
        setProfileType("web");
    }

    const changeEndpointsPage = (_, newPage) => {
        console.log({ newPage })
        setEndpointsPage(newPage);
    }

    const changeEndpointsQuantityPerPage = ({ target }) => {
        setEndpointsLimit(parseInt(target.value, 10));
        setEndpointsPage(0);
    }

    const [treeFuncs, setTreeFuncs] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get('/functionality/list-as-tree');

                setTreeFuncs(result.data);
            } catch (err) {
                console.log(err);
            }
        };

        loadData();
    }, []);

    function handleChangeFunc(func) {
        let newCheckedFuncs = Object.assign([], functionalityCheckeds)
        const funcIndex = newCheckedFuncs.indexOf(func.id);

        if (funcIndex < 0) {
            console.log("pushing ", func.id);
            newCheckedFuncs.push(func.id);

            const parent = func.parent_functionality;

            if (parent && !newCheckedFuncs.includes(parent.id)) {
                newCheckedFuncs.push(parent.id);
            }
        } else {
            console.log("pulling ", func.id);
            newCheckedFuncs.splice(funcIndex, 1);

            const children = func.children_functionalities;

            if (children) {
                const childrenIds = children.map((child) => child.id);
                newCheckedFuncs = newCheckedFuncs.filter((el) => !childrenIds.includes(el));
            }
        }

        setFunctionalityCheckeds(newCheckedFuncs);
    }

    function updateEndpointsRequest({ search }) {
        const loadData = async () => {
            try {
                const result = await api.get('/api_endpoint/list', {
                    params: {
                        search,
                        limit: endpointsLimit,
                        page: endpointsPage,
                    }
                });

                const { count, endpoints } = result.data;

                setEndpoints(endpoints);
                setEndpointsTotal(count);

            } catch (err) {
                console.log(err);
            }
        };

        loadData();
    }

    const selectAllEndpoints = ({ target }) => {
        let currentEndpoints = [...selectedEndpoints];

        if (target.checked) {
            for (const endpoint of endpoints) {
                if (!currentEndpoints.includes(endpoint.id)) {
                    currentEndpoints.push(endpoint.id);
                }
            }
        } else {
            const endpointsList = endpoints.map((endpoint) => endpoint.id);

            currentEndpoints = currentEndpoints.filter((endpoint) => !endpointsList.includes(endpoint));
        }

        setSelectedEndpoints(currentEndpoints);
    }

    const handleChangeEndpoint = (id) => {
        const aux = [...selectedEndpoints];
        let index = aux.indexOf(id);
        if (index !== -1) {
            aux.splice(index, 1);
        } else {
            aux.push(id);
        }
        setSelectedEndpoints(aux);
    };

    const sendFunctionalities = async () => {
        try {
            await api.post(`/profile/edit/${profile.id}`, {
                name: profile.name,
                type: profileType,
                functionalities_ids: functionalityCheckeds,
                endpoints_ids: selectedEndpoints,
            });

            setRefresh(!refresh);
            setMessage({
                open: true,
                variant: 'success',
                text: 'Modificações salvas com sucesso!'
            });
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error || `Desculpe, algo deu errado!`,
            });
        }
    }

    const createNewProfile = async () => {
        try {
            await api.post('/profile/add', {
                name: newProfile,
                type: profileType,
                functionalities_ids: functionalityCheckeds,
                endpoints_ids: selectedEndpoints,
            });

            setNewProfile("");
            setProfileType("web");
            setFunctionalityCheckeds([]);
            setSelectedEndpoints([]);
            setRefresh(!refresh);
            setMessage({
                open: true,
                variant: 'success',
                text: 'Perfil salvo com sucesso!'
            });
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error || `Desculpe, algo deu errado!`
            });
        }
    };

    function updateRequest({ search }) {
        const loadData = async () => {
            try {
                const result = await api.get('/profile/index', {
                    params: {
                        search,
                        limit,
                        page
                    }
                });

                const { count, profiles } = result.data;

                setProfiles(profiles);
                setTotal(count);

            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
    }

    const getProfileById = async (id) => {
        setRequestingProfile(true);
        try {
            const result = await api.get(`/profile/view/${id}`);
            const p = result.data;
            let innerFunctionalities = [];
            p.functionalities.forEach(functionality => {
                innerFunctionalities.push(functionality.id);
            });
            let innerEndpoints = [];
            p.api_endpoints.forEach(endpoint => {
                innerEndpoints.push(endpoint.id);
            });
            setProfile(p);
            setFunctionalityCheckeds(innerFunctionalities);
            setSelectedEndpoints(innerEndpoints);
            setRequestingProfile(false);
            setOpenPopup(true);
        } catch (err) {
            console.log(err);
            setRequestingProfile(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error || `Desculpe, algo deu errado!`,
            });
        }
    };

    const handleDelete = (id, name) => {
        setDeletingProfile({
            id: id,
            name: name
        });
        setOpenDeletePopup(true);
    }

    const deleteProfile = async () => {
        setIsDeleting(true);
        try {
            await api.post(`/profile/delete/${deletingProfile.id}`);
            setMessage({
                open: true,
                variant: 'success',
                text: 'Perfil deletado com sucesso!'
            });

            setDeletingProfile({ id: null, name: null });
            setRefresh(!refresh);
            setOpenDeletePopup(false);
        } catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error || `Desculpe, algo deu errado!`
            });
        }
        setIsDeleting(false);
    };

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
        console.log({ toggle: nodeIds });
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
        console.log({ selected: nodeIds })
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Perfis de Acesso
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={updateRequest}
                            pagination={[page, limit, refresh]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <PermissionOneOfWrapper
                                            routes={[
                                                "/perfil_de_acesso/modificar/:id",
                                                "/perfil_de_acesso/deletar/:id",
                                            ]}
                                        >
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!profiles && profiles.map(profile => (
                                        <TableRow key={profile.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {profile.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {profile.name}
                                            </TableCell>
                                            <PermissionOneOfWrapper
                                                routes={[
                                                    "/perfil_de_acesso/modificar/:id",
                                                    "/perfil_de_acesso/deletar/:id",
                                                ]}
                                            >
                                                <TableCell className={classes.tableBody}>
                                                    <>
                                                        <PermissionWrapper route="/perfil_de_acesso/modificar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                className={classes.actionButton}
                                                                onClick={async () => {
                                                                    await getProfileById(profile.id);
                                                                }}
                                                            >
                                                                <EditIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <PermissionWrapper route="/perfil_de_acesso/deletar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="secondary"
                                                                className={classes.actionButton}
                                                                onClick={() => handleDelete(profile.id, profile.name)}
                                                            >
                                                                <DeleteIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                    </>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                <PermissionWrapper route="/perfil_de_acesso/criar">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        onClick={() => setOpenPopupCreater(true)}
                    //href="/usuario/criar"
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                <Popup
                    openPopup={openPopupCreater}
                    setOpenPopup={setOpenPopupCreater}
                    title="Criando perfil"
                    onClose={onCloseFunctionalitiesModal}
                >
                    <Grid container>
                        <Grid container spacing={3}>
                            <Grid item xs={7}>
                                <Input
                                    label="Nome"
                                    icon={<EditIcon style={{ fontSize: 20, color: grey[400] }} />}
                                    iconPosition="start"
                                    id="name"
                                    textFieldProps={{
                                        placeholder: 'Nome do perfil',
                                        value: newProfile,
                                        onChange: ({ target }) => setNewProfile(target.value),
                                        fullWidth: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <Select
                                    label="Tipo"
                                    id="type"
                                    options={[
                                        { label: 'Web', value: 'web' },
                                        { label: 'Integração', value: 'integration' },
                                    ]}
                                    textFieldProps={{
                                        name: 'type',
                                        onChange: ({ target }) => setProfileType(target.value),
                                        fullWidth: true,
                                        value: profileType,
                                        required: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{
                            marginTop: "2rem",
                            marginBottom: "1.5rem",
                        }}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            {
                                profileType === "web" ? (
                                    <>
                                        <TreeView
                                            style={{
                                                flexGrow: 1,
                                                maxWidth: 400,
                                            }}
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                            expanded={expanded}
                                            selected={selected}
                                            onNodeToggle={handleToggle}
                                            onNodeSelect={handleSelect}
                                        >
                                            {
                                                !!treeFuncs.length && treeFuncs.map((func) => (
                                                    <TreeItem
                                                        nodeId={func.id}
                                                        label={
                                                            <FormControlLabel
                                                                label={(
                                                                    <Typography
                                                                        variant="body1"
                                                                        style={{
                                                                            fontWeight: 600,
                                                                            color: grey[800]
                                                                        }}
                                                                    >
                                                                        {func.name}
                                                                    </Typography>
                                                                )}
                                                                style={{
                                                                    marginLeft: ".5rem",
                                                                }}
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={functionalityCheckeds.includes(func.id)}
                                                                        onChange={() => handleChangeFunc(func)}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                    >
                                                        {
                                                            !!func.children_functionalities && func.children_functionalities.map((innerFunc) => (
                                                                <TreeItem nodeId={innerFunc.id} label={
                                                                    <FormControlLabel
                                                                        label={innerFunc.name}
                                                                        style={{
                                                                            marginLeft: ".5rem"
                                                                        }}
                                                                        control={
                                                                            <Checkbox
                                                                                color="primary"
                                                                                checked={functionalityCheckeds.includes(innerFunc.id)}
                                                                                onChange={() => handleChangeFunc(innerFunc)}
                                                                            />
                                                                        }
                                                                    />
                                                                } />
                                                            ))
                                                        }
                                                    </TreeItem>
                                                ))
                                            }
                                        </TreeView>
                                    </>
                                ) : (
                                    <>
                                        <Grid container spacing={3}>
                                            <Grid item xs={7}>
                                                <Typography variant="body2" className={classes.tableTitle}>
                                                    Rotas de API
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <InputSearch
                                                    textFieldProps={{
                                                        placeholder: 'Buscar…',
                                                        fullWidth: true,
                                                    }}
                                                    request={updateEndpointsRequest}
                                                    pagination={[endpointsPage, endpointsLimit, profileType]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} size="small" aria-label="endpoints list">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                                        <TableCell className={classes.tableHead}>Main Segment</TableCell>
                                                        <TableCell className={classes.tableHead}>Sub Segment</TableCell>
                                                        <TableCell className={classes.tableHead}>
                                                            <Checkbox
                                                                name="addAllEndpoints"
                                                                color="primary"
                                                                checked={endpoints.every(endpoint => {
                                                                    return selectedEndpoints.includes(endpoint.id);
                                                                })}
                                                                onChange={selectAllEndpoints}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {!!endpoints && endpoints.map(endpoint => (
                                                        <TableRow key={endpoint.id}>
                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                {endpoint.id}
                                                            </TableCell>
                                                            <TableCell className={classes.tableBody}>
                                                                {endpoint.main_segment}
                                                            </TableCell>
                                                            <TableCell className={classes.tableBody}>
                                                                {endpoint.sub_segment}
                                                            </TableCell>
                                                            <TableCell className={classes.tableBody}>
                                                                <Checkbox
                                                                    name="addEndpoint"
                                                                    color="primary"
                                                                    checked={selectedEndpoints.includes(endpoint.id)}
                                                                    onChange={() => handleChangeEndpoint(endpoint.id)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 20, 30]}
                                            component="div"
                                            count={endpointsTotal}
                                            rowsPerPage={endpointsLimit}
                                            page={endpointsPage}
                                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                            labelRowsPerPage="Registros por página"
                                            onChangePage={changeEndpointsPage}
                                            onChangeRowsPerPage={changeEndpointsQuantityPerPage}
                                        />
                                    </>
                                )
                            }
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button onClick={() => { createNewProfile() }}
                                variant="contained"
                                color="primary"
                                className={classes.buttonSave}
                                endIcon={<SaveIcon />}
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Popup>
                {!!profile && (
                    <Popup
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        title={`Editando o perfil ${profile.name}`}
                        onClose={onCloseFunctionalitiesModal}
                    >
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={7}>
                                    <Input
                                        label="Nome"
                                        icon={<EditIcon style={{ fontSize: 20, color: grey[400] }} />}
                                        iconPosition="start"
                                        id="name"
                                        textFieldProps={{
                                            placeholder: 'Nome do perfil',
                                            value: profile.name,
                                            onChange: ({ target }) => {
                                                const profileEdited = { ...profile };
                                                profileEdited.name = target.value;
                                                setProfile(profileEdited);
                                            },
                                            fullWidth: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <Select
                                        label="Tipo"
                                        id="type"
                                        options={[
                                            { label: 'Web', value: 'web' },
                                            { label: 'Integração', value: 'integration' },
                                        ]}
                                        textFieldProps={{
                                            name: 'type',
                                            onChange: ({ target }) => setProfileType(target.value),
                                            fullWidth: true,
                                            value: profileType,
                                            required: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{
                                marginTop: "2rem",
                                marginBottom: "1.5rem",
                            }}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    profileType === "web" ? (
                                        <>
                                            <TreeView
                                                style={{
                                                    flexGrow: 1,
                                                    maxWidth: 400,
                                                }}
                                                defaultCollapseIcon={<ExpandMoreIcon />}
                                                defaultExpandIcon={<ChevronRightIcon />}
                                                expanded={expanded}
                                                selected={selected}
                                                onNodeToggle={handleToggle}
                                                onNodeSelect={handleSelect}
                                            >
                                                {
                                                    !!treeFuncs.length && treeFuncs.map((func) => (
                                                        <TreeItem
                                                            nodeId={func.id}
                                                            label={
                                                                <FormControlLabel
                                                                    label={(
                                                                        <Typography
                                                                            variant="body1"
                                                                            style={{
                                                                                fontWeight: 600,
                                                                                color: grey[800]
                                                                            }}
                                                                        >
                                                                            {func.name}
                                                                        </Typography>
                                                                    )}
                                                                    style={{
                                                                        marginLeft: ".5rem",
                                                                    }}
                                                                    control={
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={functionalityCheckeds.includes(func.id)}
                                                                            onChange={() => handleChangeFunc(func)}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                        >
                                                            {
                                                                !!func.children_functionalities && func.children_functionalities.map((innerFunc) => (
                                                                    <TreeItem nodeId={innerFunc.id} label={
                                                                        <FormControlLabel
                                                                            label={innerFunc.name}
                                                                            style={{
                                                                                marginLeft: ".5rem"
                                                                            }}
                                                                            control={
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    checked={functionalityCheckeds.includes(innerFunc.id)}
                                                                                    onChange={() => handleChangeFunc(innerFunc)}
                                                                                />
                                                                            }
                                                                        />
                                                                    } />
                                                                ))
                                                            }
                                                        </TreeItem>
                                                    ))
                                                }
                                            </TreeView>
                                        </>
                                    ) : (
                                        <>
                                            <Grid container spacing={3}>
                                                <Grid item xs={7}>
                                                    <Typography variant="body2" className={classes.tableTitle}>
                                                        Rotas de API
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <InputSearch
                                                        textFieldProps={{
                                                            placeholder: 'Buscar…',
                                                            fullWidth: true,
                                                        }}
                                                        request={updateEndpointsRequest}
                                                        pagination={[endpointsPage, endpointsLimit, profileType]}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small" aria-label="endpoints list">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHead}>ID</TableCell>
                                                            <TableCell className={classes.tableHead}>Main Segment</TableCell>
                                                            <TableCell className={classes.tableHead}>Sub Segment</TableCell>
                                                            <TableCell className={classes.tableHead}>
                                                                <Checkbox
                                                                    name="addAllEndpoints"
                                                                    color="primary"
                                                                    checked={endpoints.every(endpoint => {
                                                                        return selectedEndpoints.includes(endpoint.id);
                                                                    })}
                                                                    onChange={selectAllEndpoints}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {!!endpoints && endpoints.map(endpoint => (
                                                            <TableRow key={endpoint.id}>
                                                                <TableCell className={classes.tableBody} component="th" scope="row">
                                                                    {endpoint.id}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {endpoint.main_segment}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {endpoint.sub_segment}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    <Checkbox
                                                                        name="addEndpoint"
                                                                        color="primary"
                                                                        checked={selectedEndpoints.includes(endpoint.id)}
                                                                        onChange={() => handleChangeEndpoint(endpoint.id)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 30]}
                                                component="div"
                                                count={endpointsTotal}
                                                rowsPerPage={endpointsLimit}
                                                page={endpointsPage}
                                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                labelRowsPerPage="Registros por página"
                                                onChangePage={changeEndpointsPage}
                                                onChangeRowsPerPage={changeEndpointsQuantityPerPage}
                                            />
                                        </>
                                    )
                                }
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button onClick={() => { sendFunctionalities() }}
                                    variant="contained"
                                    color="primary"
                                    className={classes.buttonSave}
                                    endIcon={<SaveIcon />}
                                >
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </Popup>
                )}
                <Popup
                    openPopup={openDeletePopup}
                    setOpenPopup={setOpenDeletePopup}
                    width='40%'
                >
                    <Grid container xs={12} style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 3% 2% 3%',

                    }}>
                        <Typography variant='h5'
                            style={{
                                fontWeight: 'bold',
                                color: '#9e9e9e'
                            }}
                        >
                            Deseja mesmo deletar "{deletingProfile.name}" ?
                        </Typography>
                        <Grid item style={{
                            marginTop: '50px'
                        }}>
                            <Button
                                disabled={isDeleting}
                                variant="contained"
                                onClick={() => deleteProfile()}
                                color="secondary"
                                endIcon={<DeleteIcon />}
                            >
                                {isDeleting ? 'Deletando...' : 'Sim'}
                            </Button>
                        </Grid>

                    </Grid>
                </Popup>
                <Backdrop className={classes.backdrop} open={requestingProfile}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    tableTitle: {
        color: grey[700],
        fontSize: 24,
    },
    table: {
        marginTop: theme.spacing(1),
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        padding: theme.spacing(.5, 0),
        minWidth: 30,
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    buttonPermissions: {
        marginLeft: theme.spacing(2)
    },
    buttonSave: {
        marginTop: theme.spacing(2)
    },
    input: {
        marginTop: theme.spacing(1)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
