import React, { useState, useEffect, useContext } from 'react';
import { Prompt } from "react-router-dom";
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import InputSearch from '../../components/InputSearch';
import Input from '../../components/Input';
import Popup from '../../components/Popup';
import Alert from '../../components/Alert';
import Select from '../../components/Select';
import Autocomplete from '../../components/Autocomplete';

import api from '../../services/api';

import { ShortcutsContext } from '../../providers/Shortcuts';
import { PermissionsContext } from '../../providers/Permissions';

export default function ShortcutList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const {
        useAssistant,
        inputAssistant,
        toggleAssistant,
        resetAssistant,
        removeShortcut,
        updateShortcut,
        createRedirectionshortcut,
    } = useContext(ShortcutsContext);

    const [shortcuts, setShortcuts] = useState([]);
    const [refresh, setRefresh] = useState(true)
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const [submitting, setSubmitting] = useState(false);

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [functionalities, setFunctionalities] = useState([]);
    const [functionalitySearch, setFunctionalitySearch] = useState("");
    const [selectedFunctionality, setSelectedFunctionality] = useState({ name: "", id: null })

    useEffect(() => {
        const loadFunctionalities = async () => {
            try {
                const result = await api.get("/functionality/list-by-url-accessible");

                setFunctionalities(result.data);
            } catch (err) {
                console.log(err);
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            }
        };

        loadFunctionalities();
    }, [functionalitySearch]);

    const [shortcutKeybinding, setShortcutKeybinding] = useState("");
    const [shortcutDescription, setShortcutDescription] = useState("");
    const [shortcutActive, setShortcutActive] = useState(false);
    const [openCreateShortcut, setOpenCreateShortcut] = useState(false);

    const handleOpenCreateShortcut = () => setOpenCreateShortcut(true);

    const resetValues = () => {
        setShortcutKeybinding("");
        setShortcutDescription("");
        setShortcutActive(false);
        setSelectedFunctionality({ name: "", id: null });
    }

    const handleCloseCreateShortcut = () => {
        setOpenCreateShortcut(false);
        resetValues();
        if (useAssistant) toggleAssistant();
    }

    const handleCreateKeybindingClick = () => {
        if (useAssistant) {
            setShortcutKeybinding(inputAssistant);
            resetAssistant();
            toggleAssistant();
        } else {
            resetAssistant();
            toggleAssistant();
        }
    }

    const handleCreateshortcut = async () => {
        setSubmitting(true);
        try {
            const data = {
                keybinding: shortcutKeybinding,
                description: shortcutDescription,
                functionality_id: selectedFunctionality?.id ?? undefined,
                type: "redirection",
                active: shortcutActive,
            }

            if (useAssistant) {
                data.keybinding = inputAssistant;
                handleCreateKeybindingClick();
            }

            const result = await api.post("/shortcut/create", data);

            if (result.status === 201) {
                setMessage({
                    open: true,
                    variant: "success",
                    text: `Atalho "${data.keybinding}" criado com sucesso`,
                });
                setRefresh(!refresh);
                if (shortcutActive) {
                    createRedirectionshortcut({
                        keybinding: shortcutKeybinding,
                        action: selectedFunctionality?.router,
                    });
                }
                handleCloseCreateShortcut();
            }

        } catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
        setSubmitting(false);
    }

    const [openEditShortcut, setOpenEditShortcut] = useState(false);
    const [selectedShortcut, setSelectedShortcut] = useState(null);

    const handleEdit = (shortcut) => {
        setOpenEditShortcut(true);
        setSelectedShortcut(shortcut);
        setShortcutKeybinding(shortcut?.keybinding);
        setShortcutDescription(shortcut?.description);
        setShortcutActive(shortcut?.active);
        for (const func of functionalities) {
            if (func.router === shortcut?.functionality?.router) {
                setSelectedFunctionality(func);
                break;
            }
        }
    }

    const handleCloseEditShortcut = () => {
        setSelectedShortcut(null);
        setOpenEditShortcut(false);
        resetValues();
        if (!!useAssistant) handleEditKeybindingClick();
    }

    const handleEditKeybindingClick = () => {
        if (useAssistant) {
            setShortcutKeybinding(inputAssistant);
            resetAssistant();
            toggleAssistant();
        } else {
            resetAssistant();
            toggleAssistant();
        }
    }

    const confirmEdit = async () => {
        setSubmitting(true);

        try {
            const result = await api.put(`/shortcut/modify/${selectedShortcut.id}`, {
                keybinding: shortcutKeybinding,
                description: shortcutDescription,
                type: selectedShortcut?.type,
                functionality_id: selectedShortcut.type === "redirection"
                    ? (selectedFunctionality?.id ?? undefined)
                    : undefined,
                active: shortcutActive,
            });

            if (result?.status === 200) {

                updateShortcut({
                    previousKeybinding: selectedShortcut.keybinding,
                    currentKeybinding: shortcutKeybinding,
                    type: selectedShortcut.type,
                    data: {
                        active: shortcutActive,
                        value: (
                            selectedShortcut.type === "system"
                                ? selectedShortcut.referrer
                                : selectedFunctionality.router
                        ),
                    }
                });

                handleCloseEditShortcut();
                setRefresh(!refresh);

                setMessage({
                    open: true,
                    variant: "success",
                    text: "Atalho atualizado com sucesso",
                });
            }

        } catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: "error",
                text: err.error,
            });
        }

        setSubmitting(false);
    }

    const [isDeleting, setIsDeleting] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const handleDelete = (shortcut) => {
        setSelectedShortcut(shortcut);
        setOpenDeletePopup(true);
    };

    const confirmDelete = async () => {
        setIsDeleting(true);
        try {

            const result = await api.delete(`/shortcut/delete/${selectedShortcut.id}`);

            if (result.status === 200) {
                removeShortcut(selectedShortcut.keybinding);
                handleCloseEditShortcut();
                setRefresh(!refresh);

                setMessage({
                    open: true,
                    variant: "success",
                    text: "Atalho apagado com sucesso",
                });
            }

        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
        setIsDeleting(false);
        setSelectedShortcut(null);
        setOpenDeletePopup(false);
    }

    function updateRequest({ search }) {
        const loadData = async () => {
            try {
                const result = await api.get('/shortcut/list', {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });

                const { count, shortcuts: loadedShortcuts } = result.data;

                setShortcuts(loadedShortcuts);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Atalhos
                        </Typography>
                    </Grid>
                    <Grid item spacing={5} xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={updateRequest}
                            pagination={[refresh, page, limit]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="shortcuts list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Atalho</TableCell>
                                        <TableCell className={classes.tableHead}>Descrição</TableCell>
                                        <TableCell className={classes.tableHead}>Ação</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Status</TableCell>
                                        <PermissionOneOfWrapper
                                            routes={[
                                                "/atalhos/modificar/:id",
                                                "/atalhos/deletar/:id",
                                            ]}
                                        >
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!shortcuts && shortcuts.map(current => (
                                        <TableRow
                                            key={current.id}
                                            className={classes.hoverRow}
                                        >
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {current.keybinding}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.description}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    current.functionality_id
                                                        ? current.functionality?.router
                                                        : "-"
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    current.type === "system" ? "SISTEMA"
                                                        : current.type === "redirection" ? "REDIRECIONAMENTO"
                                                            : "-"
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.active ? "ATIVADO" : "DESATIVADO"}
                                            </TableCell>
                                            <PermissionOneOfWrapper
                                                routes={[
                                                    "/atalhos/modificar/:id",
                                                    "/atalhos/deletar/:id",
                                                ]}
                                            >
                                                <TableCell className={classes.tableBody}>
                                                    <>
                                                        <PermissionWrapper route="/atalhos/modificar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                className={classes.actionButton}
                                                                onClick={() => handleEdit(current)}
                                                                id={`edit`}
                                                            >
                                                                <EditIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <PermissionWrapper route="/atalhos/deletar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="secondary"
                                                                className={classes.actionButton}
                                                                onClick={() => handleDelete(current)}
                                                                id={`delete`}
                                                            >
                                                                <DeleteIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                    </>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                <Popup
                    openPopup={openEditShortcut}
                    setOpenPopup={setOpenEditShortcut}
                    onClose={handleCloseEditShortcut}
                    title="Editar atalho"
                    width="40%"
                >
                    <Grid container style={{ gap: 8 }}>
                        <Grid item xs={12}>
                            <Input
                                label={
                                    <Typography
                                        className={classes.popupInputLabel}
                                    >
                                        Atalho
                                    </Typography>
                                }
                                style={{
                                    border: (
                                        useAssistant
                                            ? '2px solid #696969'
                                            : 'none'
                                    ),
                                    borderRadius: '4px',
                                    textAlign: "center",
                                }}
                                textFieldProps={{
                                    name: 'keybinding',
                                    value: (
                                        useAssistant
                                            ? inputAssistant
                                            : shortcutKeybinding
                                    ),
                                    fullWidth: true,
                                    disabled: true,
                                    onClick: () => handleEditKeybindingClick(),
                                    error: !!useAssistant,
                                    helperText: (
                                        useAssistant
                                            ? (
                                                <Typography
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    Enquanto a captura de teclas estiver ativa todos os atahos estaram desativados
                                                </Typography>
                                            )
                                            : ""
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label={
                                    <Typography
                                        className={classes.popupInputLabel}
                                    >
                                        Descrição
                                    </Typography>
                                }
                                style={{ textAlign: "center" }}
                                textFieldProps={{
                                    name: 'description',
                                    value: shortcutDescription,
                                    onChange: ({ target: { value } }) => setShortcutDescription(value),
                                    fullWidth: true,
                                }}
                            />
                        </Grid>
                        {
                            selectedShortcut?.type === "redirection" && (
                                <Grid item xs={12}>
                                    <Autocomplete
                                        label={
                                            <Typography
                                                className={classes.popupInputLabel}
                                            >
                                                Ação
                                            </Typography>
                                        }
                                        options={functionalities}
                                        getOptionLabel={({ name }) => name || ''}
                                        onChange={(_, value) => setSelectedFunctionality(value)}
                                        getOptionSelected={(option, value) => (option.name === value.name)}
                                        onInputChange={(_, value) => setFunctionalitySearch(value)}
                                        value={selectedFunctionality}
                                        fullWidth={true}
                                    />
                                </Grid>
                            )
                        }
                        <Grid item xs={12}>
                            <Input
                                label={
                                    <Typography
                                        className={classes.popupInputLabel}
                                    >
                                        Tipo
                                    </Typography>
                                }
                                style={{ textAlign: "center" }}
                                textFieldProps={{
                                    name: 'type',
                                    value: (
                                        selectedShortcut?.type === "system"
                                            ? "Sistema"
                                            : "Redirecionamento"
                                    ),
                                    fullWidth: true,
                                    disabled: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                label={
                                    <Typography
                                        className={classes.popupInputLabel}
                                    >
                                        Status
                                    </Typography>
                                }
                                options={[
                                    { label: "Ativado", value: true },
                                    { label: "Desativado", value: false },
                                ]}
                                style={{ textAlign: "center" }}
                                textFieldProps={{
                                    name: "active",
                                    value: shortcutActive,
                                    onChange: ({ target: { value } }) => setShortcutActive(value),
                                    fullWidth: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 8
                        }}>
                            {
                                selectedShortcut?.type === "redirection" && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        style={{ width: "200px" }}
                                        onClick={confirmDelete}
                                        disabled={submitting || isDeleting}
                                    >
                                        {
                                            !!isDeleting
                                                ? "Apagando..."
                                                : "Apagar"
                                        }
                                    </Button>
                                )
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ width: "200px" }}
                                onClick={confirmEdit}
                                disabled={submitting || isDeleting}
                            >
                                {
                                    !!submitting
                                        ? "Atualizando..."
                                        : "Atualizar"
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Popup>
                <Popup
                    openPopup={openCreateShortcut}
                    setOpenPopup={setOpenCreateShortcut}
                    onClose={handleCloseCreateShortcut}
                    title={"Criar novo atalho"}
                    width="40%"
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Input
                                label={
                                    <Typography
                                        className={classes.popupInputLabel}
                                    >
                                        Atalho
                                    </Typography>
                                }
                                style={{
                                    border: (
                                        useAssistant
                                            ? '2px solid #696969'
                                            : 'none'
                                    ),
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                }}
                                textFieldProps={{
                                    name: 'keybinding',
                                    value: (
                                        useAssistant
                                            ? inputAssistant
                                            : shortcutKeybinding
                                    ),
                                    fullWidth: true,
                                    disabled: true,
                                    onClick: handleCreateKeybindingClick,
                                    error: !!useAssistant,
                                    helperText: (
                                        useAssistant
                                            ? (
                                                <Typography
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    Enquanto a captura de teclas estiver ativa todos os atahos estaram desativados
                                                </Typography>
                                            )
                                            : ""
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label={
                                    <Typography
                                        className={classes.popupInputLabel}
                                    >
                                        Descrição
                                    </Typography>
                                }
                                style={{ textAlign: "center" }}
                                textFieldProps={{
                                    name: 'description',
                                    onChange: ({ target: { value } }) => setShortcutDescription(value),
                                    value: shortcutDescription,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                label={
                                    <Typography
                                        className={classes.popupInputLabel}
                                    >
                                        Ação
                                    </Typography>
                                }
                                options={functionalities}
                                getOptionLabel={({ name }) => name || ''}
                                onChange={(_, value) => setSelectedFunctionality(value)}
                                getOptionSelected={(option, value) => (option.name === value.name)}
                                onInputChange={(_, value) => setFunctionalitySearch(value)}
                                value={selectedFunctionality}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label={
                                    <Typography
                                        className={classes.popupInputLabel}
                                    >
                                        Tipo
                                    </Typography>
                                }
                                style={{ textAlign: "center" }}
                                textFieldProps={{
                                    name: 'type',
                                    value: "Redirecionamento",
                                    fullWidth: true,
                                    disabled: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                label={
                                    <Typography
                                        className={classes.popupInputLabel}
                                    >
                                        Status
                                    </Typography>
                                }
                                options={[
                                    { label: "Ativado", value: true },
                                    { label: "Desativado", value: false },
                                ]}
                                style={{ textAlign: "center" }}
                                textFieldProps={{
                                    name: "active",
                                    value: shortcutActive,
                                    onChange: ({ target: { value } }) => setShortcutActive(value),
                                    fullWidth: true,
                                }}
                            />
                        </Grid>
                        <Grid container justify="center" style={{ marginTop: 10 }}>
                            <Grid item xs={12} md={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleCreateshortcut}
                                    disabled={submitting}
                                >
                                    {
                                        !!submitting
                                            ? "Criando..."
                                            : "Criar"
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Popup>
                <Popup
                    openPopup={openDeletePopup}
                    setOpenPopup={setOpenDeletePopup}
                    title="Atenção"
                >
                    <Grid container xs={12} style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 3% 2% 3%',
                        gap: "20px"
                    }}>
                        <Typography variant='h5'
                            style={{
                                fontWeight: 'bold',
                                color: '#696969',
                                textAlign: 'justify',
                                fontSize: '1.25rem'
                            }}

                        >
                            Deseja mesmo remover esse atalho?
                        </Typography>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={12} md={6} style={{
                                marginTop: '10px'
                            }}>
                                <Button
                                    disabled={isDeleting}
                                    variant="contained"
                                    onClick={confirmDelete}
                                    color="secondary"
                                    id="confirm"
                                    fullWidth
                                >
                                    {isDeleting ? 'Deletando...' : 'SIM'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Popup>
                <PermissionWrapper route="/atalhos/criar">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        onClick={handleOpenCreateShortcut}
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
                <Prompt
                    when={useAssistant}
                    message={() => {
                        if (!!useAssistant) toggleAssistant();
                    }}
                />
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
        transition: "0.5s",

    },
    hoverRow: {
        cursor: 'pointer',
        '&:hover': {
            background: grey[300]
        }
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    popupInputLabel: {
        textAlign: "center",
        fontWeight: 700,
        marginTop: 4
    },
}));