import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Toolbar, Paper, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { Formik } from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Popup from '../../components/Popup';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';


export default function PlaceModify() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const [place, setPlace] = useState({
        name: '',
        description: '',
        org_id: null,
    });

    const { id } = useParams();
    const history = useHistory();

    const [openDeletePopup, setOpenDeletePopup] = useState(false);

    const [orgsSelect, setOrgsSelect] = useState([{ name: "Organizações", id: 0 }]);


    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };


    useEffect(() => {
        const LoadPlace = async () => {
            try {
                const result = await api.get(`/place/detail/${id}`);
                console.log('>>')
                console.log(result)

                const { place } = result.data;

                console.log('LOADED PLACE: ', place)

                setPlace(place)
            } catch (err) {
                // history.goBack();
                console.log("ERROR: ", { err })
                setMessage({
                    open: true,
                    variant: "error",
                    text: err.error,
                });
            }
        };
        const findOrg = async () => {
            try {
                const result = await api.get('/organizational_structure/index');

                const { orgs } = result.data;
                console.log("Orgs", orgs)

                setOrgsSelect(orgs);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findOrg();
        LoadPlace();
    }, [id, history]);

    const [isDeleting, setIsDeleting] = useState(false);
    const deletePlace = async () => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/place/delete/${id}`);

            const { success } = result.data;

            setIsDeleting(false);

            if (success) {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Lugar: ${place.name} deletado com sucesso!`,
                });
            }
            history.goBack();
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    const savePlace = async (values, formik) => {
        try {

            const data = { ...values };

            const result = await api.put(`/place/modify/${id}`, data);

            const { place } = result.data;

            console.log(result.data)

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Lugar: ${place.name} atualizado com sucesso!`,
            });
        }
        catch (err) {
            console.log("ERROR: ", { err })
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };


    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Editar lugar
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={place}
                        enableReinitialize={true}
                        onSubmit={savePlace}
                    >
                        {({ values, handleChange, handleSubmit, isSubmitting }) => (
                            <>
                                <Grid container spacing={5}>
                                    <Grid spacing={5} item xs={12}>
                                        <Paper alevation={3} style={{ padding: '1.5%' }}>
                                            <form id="device-create" onSubmit={handleSubmit} className={classes.form}>
                                                <Grid container xs={12} spacing={5} justify='space-evenly'>
                                                    <Grid xs={10} item>
                                                        <Grid spacing={1} container>
                                                            <Grid item xs={12}>
                                                                <Input
                                                                    label="Nome"
                                                                    textFieldProps={{
                                                                        name: 'name',
                                                                        placeholder: 'Nome do disposivo',
                                                                        onChange: handleChange,
                                                                        value: values.name,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Input
                                                                    label="Descrição"
                                                                    textFieldProps={{
                                                                        name: 'description',
                                                                        placeholder: 'Descrição do Local',
                                                                        onChange: handleChange,
                                                                        value: values.description,
                                                                        fullWidth: true,
                                                                        required: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Select
                                                                    label="Estrutura Organizacional"
                                                                    options={
                                                                        orgsSelect.map(org => ({ label: org.name, value: org.id }))
                                                                    }
                                                                    textFieldProps={{
                                                                        name: 'org_id',
                                                                        onChange: handleChange,
                                                                        fullWidth: true,
                                                                        value: values.org_id,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Popup
                                                        openPopup={openDeletePopup}
                                                        setOpenPopup={setOpenDeletePopup}

                                                    >
                                                        <Grid container xs={12} style={{
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            padding: '0 3% 2% 3%',

                                                        }}>
                                                            <Typography variant='h5'
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#9e9e9e'
                                                                }}
                                                            >
                                                                Deseja mesmo deletar o lugar: "{place.name}"?
                                                            </Typography>
                                                            <Grid item style={{
                                                                marginTop: '50px'
                                                            }}>
                                                                <Button
                                                                    disabled={isDeleting}
                                                                    variant="contained"
                                                                    onClick={() => deletePlace()}
                                                                    color="secondary"
                                                                    endIcon={<DeleteIcon />}
                                                                >
                                                                    {isDeleting ? 'Deletando...' : 'Sim, delete o lugar'}
                                                                </Button>
                                                            </Grid>

                                                        </Grid>
                                                    </Popup>
                                                    <Grid item xs={12} style={{ margin: '0 1%' }} container justify="space-evenly">
                                                        <PermissionWrapper route="/local/deletar/:id">
                                                            <Button
                                                                disabled={isDeleting}
                                                                variant="contained"
                                                                onClick={() => setOpenDeletePopup(true)}
                                                                color="secondary"
                                                                endIcon={<DeleteIcon />}
                                                            >
                                                                Deletar lugar
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            variant="contained"
                                                            form="device-create"
                                                            color="primary"
                                                            endIcon={<SaveIcon />}
                                                        >
                                                            {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    items_container: {
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    left_side: {
        borderBottom: 'solid 2px',
    },
    right_side: {

    },
}));
