import React, { useState, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import InputSearch from '../../components/InputSearch';
import Alert from '../../components/Alert';

import api from '../../services/api';
import date from '../../utils/date';
import { PermissionsContext } from '../../providers/Permissions';

export default function CaseList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [cases, setCases] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    function fetchCases({ search }) {
        const loadData = async () => {
            try {
                const result = await api.get('/case/list', {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });

                const { count, cases } = result.data;

                setCases(cases);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Casos
                        </Typography>
                    </Grid>
                    <Grid item spacing={5} xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={fetchCases}
                            pagination={[page, limit]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Caso</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Responsável</TableCell>
                                        <TableCell className={classes.tableHead}>Evidências</TableCell>
                                        <TableCell className={classes.tableHead}>Data de criação</TableCell>
                                        <PermissionOneOfWrapper
                                            routes={[
                                                "/caso/modificar/:id"
                                            ]}
                                        >
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!cases && cases.map(caseData => (
                                        <TableRow key={caseData.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {caseData.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {caseData.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {caseData.case_type?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {caseData.user.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {caseData.files?.length || 0}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {date(caseData.created_at).format('DD/MM/YYYY HH:mm')}
                                            </TableCell>
                                            <PermissionOneOfWrapper
                                                routes={[
                                                    "/caso/modificar/:id"
                                                ]}
                                            >
                                                <TableCell className={classes.tableBody}>
                                                    <>
                                                        <PermissionWrapper route="/caso/modificar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                className={classes.actionButton}
                                                                href={`/caso/modificar/${caseData.id}`}
                                                            >
                                                                <EditIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                    </>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                <PermissionWrapper route="/caso/criar">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href="/caso/criar"
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}

            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
}));
