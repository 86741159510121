import React, { useEffect, useCallback, useState, useRef } from 'react'
import {
    Dialog,
    DialogContent,
    makeStyles,
    Typography,
    Grid,
    Fab,
} from '@material-ui/core';
import Loader from "react-loader-spinner";
import RefreshIcon from '@material-ui/icons/Loop';

import Logo from "../assets/img/logo-horizontal.png";


export default function CredentialCard({
    openCard,
    setOpenCard,
    maxWidth = 'lg',
    onClose,
    userName
}) {

    const [scannedCredential, setScannedCredential] = useState('');
    const [scanning, setScanning] = useState(false);

    const classes = useStyles();

    const handleClose = () => {
        setOpenCard(false);

        if (onClose) {
            onClose(scannedCredential);
            setScannedCredential("");
            testRef.current = "";
        }
    }

    const testRef = useRef(null);

    const handleKeyPress = useCallback(async (event) => {
        // console.log(event.type, event.key, '*', openCard)

        if (openCard && event.type === "keydown" && event.key.toUpperCase() !== "ENTER" && !isNaN(event.key)) {
            if (scanning === false) {
                // console.log("enabling loader cuz", scanning);
                setScanning(true)
            };
            setTimeout(() => {
                testRef.current = ((!testRef.current ? event.key : `${testRef.current}${event.key}`))
                // console.log(`${scannedCredential}${event.key}`)
                // console.log({ scannedCredential })
            }, 500)
        } else if (openCard && event.type === "keydown" && event.key.toUpperCase() === "ENTER") {
            setTimeout(() => {
                // console.log("disabling loader");
                setScanning(false);
                // console.log({ test: testRef.current })
                setScannedCredential(testRef.current ? testRef.current : "")
                testRef.current = "";
            }, 500)
        }

    }, [openCard, scanning]);

    useEffect(() => {
        if (openCard) document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        }
    }, [handleKeyPress, openCard]);

    function resetCredential() {
        setScannedCredential("");
        testRef.current = "";
    }

    return (
        <Dialog open={openCard} onClose={handleClose} maxWidth={maxWidth} classes={{ paper: classes.dialogWrapper }}>
            <DialogContent className={classes.card}>
                <Grid
                    container
                    alignItems='space-between'
                    style={{
                        height: "100%",
                        overflow: "hidden"
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        style={{
                            textAlign: 'end'
                        }}
                    >
                        <Typography
                            variant='body1'
                            style={{
                                fontWeight: 700,
                                color: "#FFFFFF",
                                fontSize: "1.4rem",
                                userSelect: "none",
                                height: '2rem',
                            }}
                        >
                            {userName ? userName.toUpperCase() : "<Nome da Pessoa>"}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={Logo}
                            alt="logo"
                            style={{
                                width: "25rem",
                                userSelect: "none",
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            textAlign: 'start',
                            display: 'flex',
                            alignItems: 'end'
                        }}
                    >
                        <Typography
                            variant='body1'
                            style={{
                                fontWeight: 700,
                                color: "#FFFFFF",
                                fontSize: "1.4rem",
                                userSelect: "none",
                                height: '2rem',
                            }}
                        >
                            {
                                !!scanning ? (<Loader type="ThreeDots" color="#FFFFFF" height={40} width={40} />) :
                                    !scanning && scannedCredential ? scannedCredential :
                                        "<Passe o cartão no leitor>"
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Fab
                    color="primary"
                    aria-label="add"
                    className={classes.fabButton}
                    onClick={resetCredential}
                >
                    <RefreshIcon />
                </Fab>
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        position: 'absolute',
        margin: "0 auto",
        background: "rgba(0,0,0,0)",
        borderRadius: "16px",

    },
    card: {
        background: "rgba(6, 6, 68, 0.41)",
        borderRadius: "16px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(14px)",
        border: "1px solid rgba(6, 6, 68, 0.19)",
        width: "40rem",
        padding: "2rem",
        height: "20rem",
        overflowX: "hidden"
    },
    dialogTitle: {
        paddingRight: '0px',
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
        background: "rgba(6, 6, 68, 0.41)",
        transition: "0.5s",
        "&:hover": {
            background: "rgba(6, 6, 68, 0.20)",
        }
    },
}))
