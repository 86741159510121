import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Typography,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function Camera(props) {
    const {title, children, openPopup, setOpenPopup, cameraIndex} = props;
    const classes = useStyles();

    const handleClose = () => {
        if (setOpenPopup) setOpenPopup(false);
    }

    return (
        <Dialog open={openPopup} onClose={handleClose} maxWidth="xl" classes={{paper: classes.dialogWrapper}}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{flexGrow: 1}}>
                        {title}
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={() => setOpenPopup({name: cameraIndex, value: false})}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: '90%',
    },
    dialogTitle: {
        paddingRight: '0px',
    },
}))
