const storage = {
    setItem(identifier, item) {
        localStorage.setItem(identifier, JSON.stringify(item));
    },

    setMulti(items) {
        items.map(([identifier, item]) => this.setItem(identifier, item));
    },

    getItem(identifier) {
        return JSON.parse(localStorage.getItem(identifier));
    },

    remove(identifier) {
        localStorage.removeItem(identifier);
    },

    removeMulti(identifiers) {
        identifiers.map(identifier => this.remove(identifier));
    },
};

export default storage;
