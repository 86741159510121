import React, { useState, useEffect } from 'react';
import { Toolbar, Paper, Button, Grid, Typography } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import SaveIcon from '@material-ui/icons/Save';
import {Formik} from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Select from '../../components/Select';

import api from '../../services/api';


export default function PlaceCreate() {
    const classes = useStyles();

    const initialValues = {
        name: '',
        description: '',
        org_id: null,
    }

    const [orgsSelect, setOrgsSelect] = useState([{name: "Organizações", id: 0}]);


    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    useEffect(() => {
        const findOrg = async () => {
            try {
                const result = await api.get('/organizational_structure/index');

                const {orgs} = result.data;
                console.log("Orgs", orgs)

                setOrgsSelect(orgs);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findOrg();
    }, []);

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };


    const resetForm = formik => {
        formik.resetForm();
    };

    const savePlace = async (values, formik) => {
        try {
            
            const data = values;

            const result = await api.post('/place/create', data);

            const {place} = result.data;

            resetForm(formik);

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Lugar: ${place.name} cadastrado com sucesso!`,
            });
        }
        catch (err) {
            console.log("ERROR: ", {err})
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Cadastrar lugares
                        </Typography>
                    </Grid>
                        <Formik
                            initialValues={initialValues}
                            // validate={}
                            onSubmit={savePlace}
                        >
                            {({values, handleChange, handleSubmit, isSubmitting}) => (
                                <>
                                    <Grid container spacing={5}>
                                        <Grid spacing={5} item xs={12}>
                                            <Paper alevation={3} style={{padding: '1.5%'}}>
                                                <form id="device-create" onSubmit={handleSubmit} className={classes.form}>
                                                    <Grid container xs={12} spacing={5} justify='space-evenly'>
                                                            <Grid xs={12} item>
                                                                <Grid spacing={2} container>
                                                                    <Grid item xs={12}>
                                                                        <Input
                                                                            label="Nome"
                                                                            textFieldProps={{
                                                                                name: 'name',
                                                                                id: 'name',
                                                                                placeholder: 'Nome do lugar',
                                                                                onChange: handleChange,
                                                                                value: values.name,
                                                                                fullWidth: true,
                                                                                required: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                            <Input
                                                                                label="Descrição"
                                                                                id="description"
                                                                                textFieldProps={{
                                                                                    name: 'description',
                                                                                    id: 'description',
                                                                                    placeholder: 'Descrição do Local',
                                                                                    onChange: handleChange,
                                                                                    value: values.description,
                                                                                    fullWidth: true,
                                                                                    required: true
                                                                                }}
                                                                            />
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Select
                                                                            label="Estrutura Organizacional"
                                                                            options={
                                                                                orgsSelect.map(org => ({label: org.name, value: org.id, id: org.id}))
                                                                            }
                                                                            textFieldProps={{
                                                                                name: 'org_id',
                                                                                id: 'org_id',
                                                                                onChange: handleChange,
                                                                                fullWidth: true,
                                                                                value: values.org_id,
                                                                                required: true,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>                                                           
                                                        
                                                            
                                                        <Grid item xs={12} container justify="center">
                                                            <Button
                                                                disabled={isSubmitting}
                                                                type="submit"
                                                                variant="contained"
                                                                form="device-create"
                                                                color="primary"
                                                                endIcon={<SaveIcon />}
                                                            >
                                                                {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                                {isSubmitting ? console.log('Clicked') : console.log("wainting for click")}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    items_container: {
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    left_side: {
        borderBottom: 'solid 2px',
    },
    right_side: {

    },
}));
