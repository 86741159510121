import { useState, useEffect } from 'react';

import api from '../services/api';

const useDevices = ({
    search = '',
    page = 0,
    limit = 30,
    url = "list",
} = {}) => {

    const [devices, setDevices] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const findDevices = async () => {
            try {
                const result = await api.get(`/device/${url}`, {
                    params: {
                        search,
                        page,
                        limit,
                    }
                });

                const { devices, count } = result.data;

                setDevices(devices);
                setTotal(count);
                console.log({ devices, count });
            }
            catch (err) {
                console.log(err);
            }
        };

        findDevices();
    }, [search, page, limit, url]);

    return { devices, total };
};

export default useDevices;