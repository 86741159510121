import React from 'react';
import { Doughnut } from 'react-chartjs-2';

export default function DoughnutChart(props) {
	const { data = {
		labels: ['Null'],
		datasets: [
			{
				label: 'Null',
				data: [0],
				backgroundColor: [
					'#000D6B',
				]
			}
		]
	}, options } = props;
	return (
		<Doughnut 
			data={data}
			options={options}
		/>
	);
};