import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Toolbar,
    Paper,
    Button,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Checkbox,
    Switch,
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Autocomplete from '../../components/Autocomplete';
import Popup from '../../components/Popup';
import Select from '../../components/Select';
import PopUpReactive from '../../components/PopUpReactivate';
import SchedulePicker from '../../components/SchedulePicker';
import AccessPickerCamera from '../../components/AccessPickerCamera';


import api from '../../services/api';
import DeletePopUp from '../../components/DeletePopUp';
import { PermissionsContext } from '../../providers/Permissions';

export default function VehicleModify() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const ref = useRef(null);
    const accessRef = useRef(null);
    const scheduleRef = useRef();
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });
    const { id } = useParams();
    const history = useHistory();
    const [refresh, setRefresh] = useState(true)

    const [erroExistPlate, setErroExistPlate] = useState(false);
    const [schedule, setSchedule] = useState(null);
    const [fullAccess, setFullAccess] = useState(false);
    const [vehicleTypeSearch, setVehicleTypeSearch] = useState();
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [vehicleType, setVehicleType] = useState({});
    const [errorStay, setErrorStay] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [openUsersPopup, setOpenUsersPopup] = useState(false);
    const [usersSearch, setUsersSearch] = useState('');
    const [usersLimit, setUsersLimit] = useState(10);
    const [usersPage, setUsersPage] = useState(0);
    const [usersTotal, setUsersTotal] = useState(0);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [existsVehicle, setExistsVehicle] = useState(false);
    const [isActivating, setIsActivating] = useState(false);
    const [vehicleSearch, setVehicleSearch] = useState([]);
    const [vehicleId, setVehicleId] = useState();
    const [vehicleValue, setVehicleValue] = useState({
        car_brand: "",
        model: "",
        color: "",
        users: [],
    });

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const result = await api.get('/user/list', {
                    params: {
                        search: usersSearch,
                        limit: usersLimit,
                        page: usersPage,
                    },
                });

                const { count, users } = result.data;

                setUsers(users);
                setUsersTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadUsers();
    }, [usersPage, usersLimit, usersSearch]);

    useEffect(() => {
        const loadVehiclesValue = async () => {
            try {
                const result = await api.get(`/vehicle/list/${id}`);

                const { vehicles } = result.data;
                console.log(result.data)
                setVehicleValue(vehicles);
                setSchedule(vehicles.schedule)
                setVehicleType(vehicles.VehicleType)
                accessRef.current.load({ loadingCameras: vehicles.camera_id })
            } catch (err) {
                setMessage({
                    open: true,
                    variant: "error",
                    text: err.error,
                });
            }
        };
        loadVehiclesValue();
    }, [id, history, refresh]);

    const activeVehicle = async () => {
        try {
            setIsActivating(true);

            const result = await api.post(`/vehicle/active/${vehicleId}`);
            const { activedVehicle } = result.data;
            console.log(activedVehicle);

            setIsActivating(false);
            setExistsVehicle(false);

            if (!!existsVehicle) {
                setVehicleSearch(null);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `ativado com sucesso!`,
                });
                setTimeout(() => {
                    history.goBack();
                }, 2000);
            }
        } catch (err) {
            setIsActivating(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    useEffect(() => {
        const loadVehiclesLicensePlate = async () => {
            try {
                const result = await api.get('/vehicle/list/licensePlate', {
                    params: {
                        search: vehicleSearch,
                        active: false,
                    },
                });

                const { vehicles } = result.data;

                if (vehicles?.length > 0) {
                    setVehicleId(vehicles[0]?.id);
                    setExistsVehicle(true);
                    setErrorStay(true)
                }
            }
            catch (err) {
                console.log(err);
            }
        };
        loadVehiclesLicensePlate();
    }, [vehicleSearch]);

    useEffect(() => {
        const loadVehiclesTrue = async () => {
            try {
                const result = await api.get('/vehicle/list/licensePlate', {
                    params: {
                        search: vehicleSearch,
                        active: true,
                        vehicle_id: id,
                    },
                });

                const { vehicles } = result.data;

                if (vehicles?.length > 0) {
                    setErroExistPlate(true);
                } else {
                    setErroExistPlate(false);

                }
            }
            catch (err) {
                console.log(err);
            }
        };
        loadVehiclesTrue();
    }, [id, vehicleSearch]);


    useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/vehicle_type/list', {
                    params: {
                        search: vehicleTypeSearch,
                        show_exceptions: false,
                        limit: 20,
                    }
                });

                const { vehicleTypes } = result.data;

                setVehicleTypes(vehicleTypes);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, [vehicleTypeSearch]);

    const saveVehicle = async (values, formik) => {
        try {
            const vehicleData = {
                ...values,
                schedule,
                users: selectedUsers,
                vehicle_type_id: vehicleType?.id,
                ...accessRef.current.getValues(),
            };
            console.log('new values: ', vehicleData);
            const result = await api.post(`/vehicle/modify/${id}`, vehicleData);
            const { vehicle } = result.data;
            console.log(vehicle)
            formik.setSubmitting(false);
            setRefresh(!refresh)
            setMessage({
                open: true,
                variant: 'success',
                text: `O veículo foi modificado com sucesso!`,
            });
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const deleteVehicle = async () => {
        try {
            setIsDeleting(true);

            const result = await api.post(`/vehicle/delete/${id}`);

            const { vehicle } = result.data;

            setIsDeleting(false);
            setOpenDeletePopup(false);

            if (vehicle) {
                setOpenDeletePopup(false);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Veículo deletado com sucesso!`,
                });
                setTimeout(() => {
                    history.goBack();
                }, 2000);
            }
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'user':
                setUsersPage(newPage);
                break;
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        switch (quantityOf) {
            case 'user':
                setUsersLimit(parseInt(target.value, 10));
                setUsersPage(0);
                break;
            default:
                console.log('Quantidade inválida');
        }
    };

    const manipulateCheckBox = (checkbox, isSelected, id) => {
        switch (checkbox) {
            case 'user':
                let newSelectedUsers = Object.assign([], selectedUsers);
                if (isSelected) {
                    newSelectedUsers.push(id);
                } else {
                    let index = selectedUsers.indexOf(id);
                    newSelectedUsers.splice(index, 1);
                }
                setSelectedUsers(newSelectedUsers);
                break;
            case 'all_users':
                let allUsers = [];
                if (isSelected) {
                    allUsers = Object.assign([], selectedUsers);
                    users.forEach(user => {
                        if (!allUsers.includes(user.id)) {
                            allUsers.push(user.id)
                        }
                    });
                } else {
                    let userIds = users.map(el => el.id);
                    allUsers = selectedUsers.filter(user => !userIds.includes(user));
                }
                setSelectedUsers(allUsers);
                break;
            default:
                console.log('Tipo inválido');
        }
    };

    const optionsColor = [
        { label: 'Amarelo', value: 'Amarelo' },
        { label: 'Azul', value: 'Azul' },
        { label: 'Branco', value: 'Branco' },
        { label: 'Cinza', value: 'Cinza' },
        { label: 'Dourado', value: 'Dourado' },
        { label: 'Laranja', value: 'Laranja' },
        { label: 'Laranja escuro', value: 'Laranja escuro' },
        { label: 'Marrom', value: 'Marrom' },
        { label: 'Prata', value: 'Prata' },
        { label: 'Preto', value: 'Preto' },
        { label: 'Rosa', value: 'Rosa' },
        { label: 'Roxo', value: 'Roxo' },
        { label: 'Roxo escuro', value: 'Roxo escuro' },
        { label: 'Tomate', value: 'Tomate' },
        { label: 'Verde', value: 'Verde' },
        { label: 'Verde-oliva', value: 'Verde-oliva' },
        { label: 'Verde-oliva escuro', value: 'Verde-oliva' },
        { label: 'Vermelho', value: 'Vermelho' },
        { label: 'Outra', value: 'Outra' },
        { label: 'Desconhecida', value: 'Desconhecida' },
        // { label: 'Amarelo', value: ['Mistyrose', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Whitesmoke', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Dimgray', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Maroon', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Chartreuse', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Greenyellow', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Forestgreen', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Seagreen', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Deepskyblue', 'Amarelo'] },
    ];

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Editar Veículo
                        </Typography>
                    </Grid>
                    <Formik initialValues={vehicleValue} onSubmit={saveVehicle} enableReinitialize={true}>
                        {({
                            values,
                            setFieldValue,
                            handleChange,
                            handleSubmit,
                            isSubmitting,

                        }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form
                                            id="vehicle-modify"
                                            onSubmit={handleSubmit}
                                            className={classes.form}
                                        >
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Informações do Veículo:
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Input
                                                                label="Marca"
                                                                textFieldProps={{
                                                                    name: "car_brand",
                                                                    onChange: handleChange,
                                                                    value: values.car_brand,
                                                                    fullWidth: true,
                                                                    required: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Input
                                                                label="Modelo"
                                                                textFieldProps={{
                                                                    id: "model",
                                                                    name: "model",
                                                                    onChange: handleChange,
                                                                    value: values.model,
                                                                    fullWidth: true,
                                                                    required: true,
                                                                }}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Select
                                                                label="Cor"
                                                                options={optionsColor}
                                                                textFieldProps={{
                                                                    id: 'color',
                                                                    name: 'color',
                                                                    onChange: handleChange,
                                                                    value: values.color,
                                                                    fullWidth: true,
                                                                    required: true,

                                                                }}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Autocomplete
                                                                label="Tipo de Veículo"
                                                                placeholder="Tipo de veículo"
                                                                id="type_of_vehicle"
                                                                options={vehicleTypes}
                                                                getOptionLabel={({ name }) => name || ''}
                                                                onChange={(_, value) => setVehicleType(value)}
                                                                getOptionSelected={(option, value) => (option.id === value.id)}
                                                                onInputChange={(_, value) => setVehicleTypeSearch(value)}
                                                                value={vehicleType}
                                                                required={true}
                                                                fullWidth />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Input
                                                                ref={ref}
                                                                label="Placa"
                                                                error={(erroExistPlate || errorStay)}
                                                                helperText={erroExistPlate ? 'Veículo ja cadastrado' : (errorStay ? 'Veículo desativado' : null)}
                                                                textFieldProps={{
                                                                    id: "license_plate",
                                                                    name: "license_plate",
                                                                    onChange: (e) => {
                                                                        handleChange(e);
                                                                        if (!erroExistPlate || !erroExistPlate) {
                                                                            setVehicleSearch(null);
                                                                        }
                                                                        setTimeout((e) => {
                                                                            if (vehicleSearch === null) {
                                                                                setErroExistPlate(false);
                                                                                setErrorStay(false);
                                                                            }
                                                                        }, 500)
                                                                    },
                                                                    onBlur: () => {
                                                                        setVehicleSearch(values.license_plate);
                                                                        if (values.license_plate === '')
                                                                            setErrorStay(false);
                                                                        setErroExistPlate(false);
                                                                    },
                                                                    value: values.license_plate,
                                                                    fullWidth: true,
                                                                    required: false,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Adicionar Pessoas ao Veículos:
                                                    </Typography>
                                                    <Grid container justify="right" spacing={3}>
                                                        <Grid item xs={2}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenUsersPopup(true)}
                                                            >
                                                                Pessoas {`(${selectedUsers.length})`}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container className={classes.formSection}>
                                                    <Grid
                                                        container
                                                        style={{ marginBottom: '8px', marginTop: '8px' }}
                                                        justify='space-between'
                                                    >
                                                        <Grid item style={{ textAlign: 'center' }}>
                                                            <Typography variant="h6"
                                                                className={
                                                                    classes.formSectionTitle
                                                                }
                                                            >
                                                                Perído de Acesso:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item
                                                            style={{
                                                                display: 'flex',
                                                                marginLeft: '10px',
                                                            }}
                                                        >
                                                            <Grid container spacing={1} alignItems="center" justify="center">
                                                                <Typography>Acesso Regular</Typography>
                                                                <Switch
                                                                    color="primary"
                                                                    checked={fullAccess}
                                                                    onChange={({ target }) => {
                                                                        setFullAccess(target.checked);
                                                                        scheduleRef.current.setFullAccess(target.checked);
                                                                    }}
                                                                    inputProps={{
                                                                        'aria-label': 'notification-status'
                                                                    }}
                                                                />
                                                                <Typography>Acesso Total</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                        >
                                                            <SchedulePicker setMessage={setMessage} setSchedule={setSchedule} ref={scheduleRef} loadingSchedule={schedule} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} className={classes.formSection}>
                                                    <Typography
                                                        variant="h6"
                                                        className={
                                                            classes.formSectionTitle
                                                        }>
                                                        Autorização
                                                    </Typography>
                                                    <AccessPickerCamera ref={accessRef} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Grid container justify="space-evenly" spacing={3}>
                                                        <PermissionWrapper route="/veiculo/deletar/:id">
                                                            <Grid item style={{
                                                                margin: '0.5%'
                                                            }}>
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    endIcon={<DeleteIcon />}
                                                                    onClick={() => setOpenDeletePopup(true)}
                                                                >
                                                                    Apagar Veículo
                                                                </Button>
                                                            </Grid>
                                                        </PermissionWrapper>
                                                        <Grid item>
                                                            <Button
                                                                disabled={(isSubmitting || erroExistPlate || errorStay)}
                                                                type="submit"
                                                                variant="contained"
                                                                form="vehicle-modify"
                                                                color="primary"
                                                                endIcon={<SaveIcon />}
                                                            >
                                                                {isSubmitting ? 'Salvando...' : 'Salvar dados'}

                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                                <PopUpReactive
                                    open={existsVehicle}
                                    setOpen={setExistsVehicle}
                                    title={'veículo'}
                                    activateFunction={() => activeVehicle()}
                                    cancelFunction={() => setExistsVehicle(false) && setErrorStay(true)}
                                    isActivate={isActivating}
                                />
                                <Popup
                                    openPopup={openUsersPopup}
                                    setOpenPopup={setOpenUsersPopup}
                                    title="Adcione pessoas á serem monitorada"
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid item xs={4}>
                                                <Input
                                                    icon={<SearchIcon style={{ color: grey[400] }} />}
                                                    iconPosition="start"
                                                    textFieldProps={{
                                                        placeholder: 'Buscar…',
                                                        onChange: ({ target }) => setUsersSearch(target.value),
                                                        fullWidth: true
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small" aria-label="users list">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHead}>ID</TableCell>
                                                            <TableCell className={classes.tableHead}>Nome</TableCell>
                                                            <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                                            <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                                            <TableCell className={classes.tableHead}>
                                                                <Checkbox
                                                                    name="addAllUsers"
                                                                    color="primary"
                                                                    checked={users.every(el => {
                                                                        return selectedUsers.includes(el.id);
                                                                    })}
                                                                    onChange={({ target }) => manipulateCheckBox('all_users', target.checked)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {!!users && users.map(user => (
                                                            <TableRow key={user.id}>
                                                                <TableCell className={classes.tableBody} component="th" scope="row">
                                                                    {user.id}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {user.name}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {user.doc_type && user?.doc_type === 'ic' ? 'RG' :
                                                                        user?.doc_type === 'passport' ? 'Passaporte' :
                                                                            user?.doc_type === 'other' ? 'Outro' :
                                                                                user?.doc_type === 'unknown' ? 'CPF' :
                                                                                    'Não identificado'}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {user.doc_number ? user.doc_number : "Não identificado"}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    <Checkbox
                                                                        name="addUser"
                                                                        color="primary"
                                                                        checked={selectedUsers.includes(user.id)}
                                                                        onChange={({ target }) => manipulateCheckBox('user', target.checked, user.id)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 30]}
                                                component="div"
                                                count={usersTotal}
                                                rowsPerPage={usersLimit}
                                                page={usersPage}
                                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                labelRowsPerPage="Registros por página"
                                                onChangePage={(_, newPage) => changePage(_, newPage, 'user')}
                                                onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'user')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Popup>
                                <DeletePopUp
                                    open={openDeletePopup}
                                    setOpen={setOpenDeletePopup}
                                    isDelete={isDeleting}
                                    title={'Veículo'}
                                    deleteFunction={() => deleteVehicle()}
                                />
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    statusLabel: {
        display: 'block',
        fontWeight: 'bold',
        color: grey[500],
        marginBottom: theme.spacing(1),
    },
    editor: {
        height: '540px',
        padding: '10px',
    },
    editorToolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid #cdcdcd'
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    tableRow: {
        transition: '0.2s',
        '&:hover': {
            backgroundColor: grey[300],
        }
    },
}));
