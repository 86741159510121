import React, { useState, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ViewIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import Alert from '../../components/Alert';
import SideMenu from '../../components/SideMenu';
import InputSearch from '../../components/InputSearch';
import Popup from '../../components/Popup';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';

export default function OrganizationalList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [organizationals, setOrganizationals] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [refresh, setRefresh] = useState(true);
    const [deletingOrg, setDeletingOrg] = useState({ id: null, name: null });
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    function updateRequest({ search }) {

        const loadData = async () => {
            try {
                const result = await api.get('/organizational_structure/index', {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });

                const { count, orgs } = result.data;

                setOrganizationals(orgs);
                setTotal(count);
                setRefresh(false);
            }
            catch (err) {
                console.log(err);
            }
        };
        loadData();

    }


    const handleDelete = (id, name) => {
        setDeletingOrg({ id: id, name: name });
        setOpenDeletePopup(true);
    }
    async function deleteData() {
        try {
            setIsDeleting(true);
            await api.post(`/organizational_structure/delete/${deletingOrg.id}`);
            setMessage({
                open: true,
                variant: 'success',
                text: `Organização excluida com sucesso!`,
            });
            setIsDeleting(false);
            setOpenDeletePopup(false);
            setRefresh(!refresh);

        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Estrutura Organizacional
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={updateRequest}
                            pagination={[page, limit]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Organização Pai</TableCell>
                                        <PermissionOneOfWrapper
                                            routes={[
                                                "/organizational/modify/:id",
                                                "/organizational/deletar/:id",
                                                "/organizational/view/:id",
                                            ]}
                                        >
                                            <TableCell className={classes.tableHead}>AÇÕES</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!organizationals && organizationals.map(orgs => (
                                        <TableRow key={orgs.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {orgs.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {orgs.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {!!orgs.father_node ? orgs.father_node.name : '-'}
                                            </TableCell>
                                            <PermissionOneOfWrapper
                                                routes={[
                                                    "/organizational/modify/:id",
                                                    "/organizational/deletar/:id",
                                                    "/organizational/view/:id",
                                                ]}
                                            >
                                                <TableCell className={classes.tableBody}>
                                                    <>
                                                        <PermissionWrapper route="/organizational/modify/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                className={classes.actionButton}
                                                                href={`/organizational/modify/${orgs.id}`}
                                                            >
                                                                <EditIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <PermissionWrapper route="/organizational/deletar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="secondary"
                                                                className={classes.actionButton}
                                                                onClick={async () => handleDelete(orgs.id, orgs.name)}
                                                            >
                                                                <DeleteIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                    </>
                                                    <PermissionWrapper route="/organizational/view/:id">
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            style={{ background: '#ECB365' }}
                                                            className={classes.actionButton}
                                                            href={`/organizational/view/${orgs.id}`}

                                                        >
                                                            <ViewIcon style={{ fontSize: 20, color: '#fff' }} />
                                                        </Button>
                                                    </PermissionWrapper>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                <Popup
                    openPopup={openDeletePopup}
                    setOpenPopup={setOpenDeletePopup}
                    width='40%'
                >
                    <Grid container xs={12} style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 3% 2% 3%',

                    }}>
                        <Typography variant='h5'
                            style={{
                                fontWeight: 'bold',
                                color: '#9e9e9e'
                            }}
                        >
                            Deseja mesmo deletar "{deletingOrg.name}" ?
                        </Typography>
                        <Grid item style={{
                            marginTop: '50px'
                        }}>
                            <Button
                                disabled={isDeleting}
                                variant="contained"
                                onClick={() => deleteData()}
                                color="secondary"
                                endIcon={<DeleteIcon />}
                            >
                                {isDeleting ? 'Deletando...' : 'Sim'}
                            </Button>
                        </Grid>

                    </Grid>
                </Popup>
                <PermissionWrapper route="/organizational/modify/:id">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href="/organizational/criar"
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
}));
