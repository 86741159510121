import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Toolbar,
    Paper,
    Button,
    Grid,
    Typography,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';

import { grey, indigo } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import SaveIcon from '@material-ui/icons/Save';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

import { Formik } from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Popup from '../../components/Popup';
import Autocomplete from '../../components/Autocomplete';

import api from '../../services/api';
import config from '../../configs/config';
import { PermissionsContext } from '../../providers/Permissions';
const { host, port } = config;

const reduceDevices = (state, action) => {
    let newValues = [];
    console.log({ state })
    switch (action.type) {
        case 'single':
            if (action.checked && !state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                newValues.push(action.id);
            } else if (!action.checked && state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                let index = state[action.deviceArray].indexOf(action.id);
                newValues.splice(index, 1);
            } else {
                newValues = state[action.deviceArray];
            }
            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'all':
            if (action.checked) {
                newValues = state[action.deviceArray];
                for (let current of action.allDevices) {
                    if (!newValues.includes(current.id)) {
                        newValues.push(current.id);
                    }
                }
            } else {
                newValues = [];
            }
            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'load':
            newValues = action.loading;
            return {
                ...state,
                [action.deviceArray]: newValues.map(device => device.id),
            };
        case 'reset':
            return { device_id: [], camera_id: [] };
        default: return state;
    }
};

export default function WantedModify() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const { id } = useParams();
    const history = useHistory();

    let [wanted, setWanted] = useState({
        name: '',
        sex: 'unknown',
        role: 'visitor',
        face_image: null,
        notes: '',
        user_custom_inputs: [],
    });

    const [search, setSearch] = useState('');
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const [deviceIds, dispatchDeviceIds] = useReducer(reduceDevices, { device_id: [], camera_id: [] });

    const [image, setImage] = useState(null)

    const [loop, setLoop] = useState(0);
    const [newImage, setNewImage] = useState(false);
    const imageRef = useRef(null);

    function imageHandler(e, values) {
        console.log(e)
        setNewImage(true)
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result);
                values.face_image = e.target.files[0];
                console.log(e.target.files[0])
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        // console.log("imagem: " + image)
    }


    const ImagePreview = ({ image }) => {
        const src = newImage ? image : URL.createObjectURL(image);

        return (
            <img
                src={src}
                alt="Imagem de identificação"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
        );
    };

    const [cameras, setCameras] = useState([]);
    const [userCustomInputs, setUserCustomInputs] = useState([]);

    const dataUrlToFile = async dataUrl => {
        const image = await fetch(dataUrl)
            .then(res => res.arrayBuffer())
            .then(buffer => new File([buffer], 'face_image.jpeg', { type: 'image/jpg' }));

        return image;
    };

    useEffect(() => {
        const loadUser = async () => {
            try {
                const result = await api.get(`/wanted/detail/${id}`);

                let { user, inputs } = result.data;

                user.user_custom_inputs = inputs;

                setUserCustomInputs(inputs)

                const image = await dataUrlToFile(`http://${host}:${port}/midias/imagem/face/${user.face_image}`);

                user.face_image = image;
                setImage(image);

                dispatchDeviceIds({
                    type: 'load',
                    deviceArray: 'camera_id',
                    loading: user.cameras,
                });

                setWanted(user);

            }
            catch (err) {
                console.log({ err });
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
                setTimeout(() => {
                    history.goBack();
                }, 2000);
            }
        };

        loadUser();
        // eslint-disable-next-line
    }, [id, history]);

    useEffect(() => {
        const findCameras = async () => {
            try {
                const result = await api.get('/camera/list', {
                    params: {
                        search,
                        limit: 30,
                    }
                });

                const { cameras } = result.data;

                setCameras(cameras);
                console.log({ cameras })
            }
            catch (err) {
                console.log(err);
            }
        };

        findCameras();
        // es-li
    }, [search]);

    const saveUser = async (values, formik) => {
        try {

            const data = new FormData();

            data.append('face_image', values.face_image);

            if (!values.org_id) values.org_id = undefined;

            const userData = {
                ...values,
                camera_id: deviceIds.camera_id,
            };

            userData.face_image = undefined;

            data.append('user_data', JSON.stringify(userData));
            // console.log("user data :|", userData)
            const result = await api.post(`/wanted/modify/${id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const { user } = result.data;

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Procurado ${user.name} editado com sucesso!`,
            });
        }
        catch (err) {
            // console.log(err)
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };


    const [customInputs, setCustomInputs] = useState([]);

    useEffect(() => {
        const findInputs = async () => {
            try {
                const result = await api.get('/custom_input/list', {
                    params: {
                        target: ['wanted'],
                        page: 0,
                        limit: 30,
                    }
                });

                const { custom_inputs } = result.data;

                setCustomInputs(custom_inputs);
            }
            catch (err) {
                console.log(err);
            }
        };

        findInputs();
    }, []);

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const deleteWanted = async () => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/wanted/delete/${id}`);

            const { success } = result.data;

            setIsDeleting(false);

            if (success) {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Procurado deletado com sucesso!`,
                });
            }
            history.goBack();
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid container xs={12}>
                        <Typography xs={6} variant="h5" className={classes.title}>
                            Modificar Pessoa Procurada
                        </Typography>
                    </Grid>
                    <Formik
                        enableReinitialize={true}
                        initialValues={wanted}
                        onSubmit={saveUser}
                    >
                        {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="wanted-modify" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3} className={classes.formSection} >
                                                    <div className={classes.faceImageContainer}
                                                        style={{
                                                            height: '300px',
                                                            width: '350px',
                                                        }}>
                                                        <div
                                                            className={classes.faceImageContent}
                                                            style={{
                                                                width: "100%",
                                                                height: "200px"
                                                            }}
                                                        >
                                                            {image ? (
                                                                <ImagePreview image={image} />
                                                            ) : (
                                                                <div className={classes.noImage}>
                                                                    <PhotoCameraIcon />
                                                                    <Typography variant="subtitle2">
                                                                        SEM IMAGEM
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {image && (
                                                            <Button
                                                                className={classes.removeImageButton}
                                                                size="small"
                                                                type="button"
                                                                variant="outlined"
                                                                color="secondary"
                                                                endIcon={<DeleteIcon />}
                                                                fullWidth
                                                                onClick={() => {
                                                                    setFieldValue('face_image', null)
                                                                    setImage(null);
                                                                    imageRef.current.value = null;
                                                                }}
                                                            >
                                                                Remover
                                                            </Button>
                                                        )}
                                                        <Button
                                                            className={classes.faceImageButton}
                                                            type="button"
                                                            variant="outlined"
                                                            color="primary"
                                                            endIcon={<PhotoCameraIcon />}
                                                            fullWidth
                                                            id={'take_pic'}
                                                            component="label"
                                                        >
                                                            Adicionar Foto
                                                            <input
                                                                name='map_image'
                                                                type="file"
                                                                accept="image/*"
                                                                ref={imageRef}
                                                                onChange={(e) => imageHandler(e, values)}
                                                                hidden
                                                                required={false}
                                                            />
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={9} className={classes.formSection}>
                                                    <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                        DADOS PESSOAIS
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={9}>
                                                            <Input
                                                                label="Nome/Apelido"
                                                                textFieldProps={{
                                                                    name: 'name',
                                                                    placeholder: 'Nome/Apelido',
                                                                    onChange: handleChange,
                                                                    value: values.name,
                                                                    fullWidth: true,
                                                                    required: false,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Sexo"
                                                                options={[
                                                                    { label: '-- Selecione --', value: 'unknown', id: 'unknown' },
                                                                    { label: 'Masculino', value: 'male', id: 'male' },
                                                                    { label: 'Feminino', value: 'female', id: 'female' },
                                                                ]}
                                                                textFieldProps={{
                                                                    name: 'sex',
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                    value: values.sex,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.formSection} style={{ marginTop: '10px' }}>
                                                        <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                            OBSERVAÇÕES
                                                        </Typography>
                                                        <Grid item xs={12} style={{ height: '100%' }}>
                                                            <textarea
                                                                placeholder="Escreva o texto aqui"
                                                                multiline
                                                                minRows={25}
                                                                style={{ width: '100%', border: '1px solid #c6c6c6', minHeight: '200px', resize: 'none', padding: '10px', fontSize: '1.2rem', color: grey[600], fontFamily: 'Arial, sans-serif' }}
                                                                value={values.notes}
                                                                onChange={({ target }) => setFieldValue('notes', target.value)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitleTwo}>
                                                    INFORMAÇÕES PERSONALIZADAS
                                                </Typography>
                                                <Grid container spacing={3}>
                                                    {userCustomInputs.map((input, index) => {
                                                        if (index === 0 && values.user_custom_inputs.length !== userCustomInputs.length && loop === 0) {
                                                            // console.log('0 loop')
                                                            setLoop(1)
                                                            values.user_custom_inputs = userCustomInputs

                                                        }


                                                        let label = '';
                                                        let is_required = '';
                                                        let ciType = '';
                                                        let ciOptions = [];
                                                        customInputs.forEach(ci => {
                                                            if (ci.id === input.custom_input_id) {
                                                                label = ci.name;
                                                                is_required = ci.is_required
                                                                ciType = ci?.type;
                                                                ciOptions = ci?.options;
                                                            }
                                                        });
                                                        return (
                                                            <Grid key={index} item xs={4}>
                                                                {
                                                                    ciType === "normal" ? (
                                                                        <Input
                                                                            label={label}
                                                                            textFieldProps={{
                                                                                name: `user_custom_inputs[${index}].information`,
                                                                                onChange: handleChange,
                                                                                value: values.user_custom_inputs[index]['information'] ? values.user_custom_inputs[index]['information'] : '',
                                                                                fullWidth: true,
                                                                                required: is_required,
                                                                            }}
                                                                        />
                                                                    ) : (

                                                                        <Autocomplete
                                                                            label={label}
                                                                            id={`custom_inputs${index}.information`}
                                                                            options={ciOptions?.length && ciOptions?.map((option) => ({ name: option, value: option, id: option }))}
                                                                            getOptionLabel={({ name }) => name || ''}
                                                                            onChange={(_, value) => setFieldValue(`user_custom_inputs[${index}].information`, value)}
                                                                            getOptionSelected={(option, value) => (option.id === value.id)}
                                                                            /* onInputChange={(_, value) => setPersonTypeSearch(value)} */
                                                                            value={values.user_custom_inputs[index]['information'] ? values.user_custom_inputs[index]['information'] : ''}
                                                                            required={is_required}
                                                                            fullWidth
                                                                        />
                                                                    )
                                                                }
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    CÃMERAS
                                                </Typography>
                                                <Grid item xs={12}>
                                                    <Grid container style={{ padding: '0 0 3% 0', }}>
                                                        <Input
                                                            icon={<SearchIcon style={{ color: grey[400] }} />}
                                                            iconPosition="start"
                                                            textFieldProps={{
                                                                placeholder: 'Buscar…',
                                                                onChange: ({ target }) => setSearch(target.value),
                                                            }}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            style={{ marginLeft: '12px' }}
                                                            onClick={() => {
                                                                dispatchDeviceIds({
                                                                    type: 'all',
                                                                    checked: true,
                                                                    deviceArray: 'camera_id',
                                                                    allDevices: cameras,
                                                                });
                                                            }}
                                                        >
                                                            Selecionar todos
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            style={{ marginLeft: '12px' }}
                                                            onClick={() => {
                                                                dispatchDeviceIds({
                                                                    type: 'all',
                                                                    checked: false,
                                                                    deviceArray: 'camera_id',
                                                                });
                                                            }}
                                                        >
                                                            Desmarcar todos
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    {!!cameras && cameras.map((camera, index) => {
                                                        return (
                                                            <Grid item xs={4} key={camera.id}>
                                                                <FormControlLabel
                                                                    label={
                                                                        <Typography variant="subtitle2" style={{
                                                                            userSelect: 'none'
                                                                        }}>
                                                                            {camera.name}
                                                                            <Typography variant="caption">
                                                                                ({camera?.place_cameras?.name})
                                                                            </Typography>
                                                                        </Typography>
                                                                    }
                                                                    control={
                                                                        <Checkbox
                                                                            name={`camera_[${camera.id}]`}
                                                                            checked={deviceIds.camera_id.includes(camera.id)}
                                                                            color="primary"
                                                                            onChange={(e) => {
                                                                                dispatchDeviceIds({
                                                                                    type: 'single',
                                                                                    checked: e.target.checked,
                                                                                    deviceArray: 'camera_id',
                                                                                    id: camera.id
                                                                                });
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid container justify="space-evenly">
                                                <PermissionWrapper route="/procurado/deletar/:id">
                                                    <Button
                                                        disabled={isDeleting}
                                                        variant="contained"
                                                        color="secondary"
                                                        endIcon={<DeleteIcon />}
                                                        onClick={() => setOpenDeletePopup(true)}
                                                    >
                                                        Deletar procurado
                                                    </Button>
                                                </PermissionWrapper>
                                                <Button
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    form="wanted-modify"
                                                    color="primary"
                                                    endIcon={<SaveIcon />}
                                                >
                                                    {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                </Button>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
            <Popup
                openPopup={openDeletePopup}
                setOpenPopup={setOpenDeletePopup}

            >
                <Grid container xs={12} style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 3% 2% 3%',

                }}>
                    <Typography variant='h5'
                        style={{
                            fontWeight: 'bold',
                            color: '#9e9e9e'
                        }}
                    >
                        Deseja mesmo deletar essa pessoa procurado"?
                    </Typography>
                    <Grid item style={{
                        marginTop: '50px'
                    }}>
                        <Button
                            disabled={isDeleting}
                            variant="contained"
                            onClick={() => deleteWanted()}
                            color="secondary"
                            endIcon={<DeleteIcon />}
                        >
                            {isDeleting ? 'Deletando...' : 'Sim'}
                        </Button>
                    </Grid>

                </Grid>
            </Popup>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    faceImageContainer: {
        width: '100%',
        height: '303px',
        border: `solid 1px ${indigo[200]}`,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    faceImageContent: {
        width: '100%',
        flex: 7,
        backgroundColor: grey[50],
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(.5),
    },
    faceImageButton: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        flex: 1,
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    noImage: {
        textAlign: 'center',
        color: grey[400],
    },
}));
