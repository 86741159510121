import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import {
    Toolbar,
    Paper,
    Button,
    Grid,
    Typography,
    Checkbox,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TablePagination,
    Switch,
    FormControlLabel,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';
import MaskedInput from 'react-text-mask';
import { Formik } from 'formik';
import Webcam from 'react-webcam';
import { makeStyles } from '@material-ui/core/styles';
import { grey, indigo } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import UploadIcon from '@mui/icons-material/Upload';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';
import Popup from '../../components/Popup';
import Autocomplete from '../../components/Autocomplete';
import SchedulePicker from '../../components/SchedulePicker';
import AccessPicker from '../../components/AccessPicker';
import CredentialsManager from '../../components/CredentialsManager';
import RedirectToUser from '../../components/RedirectToUser';

import api from '../../services/api';
import config from '../../configs/config';
import { PermissionsContext } from '../../providers/Permissions';

const { host, port } = config;

export default function UserModify2() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const [fullAccess, setFullAccess] = useState(false);

    const scheduleRef = useRef();
    const accessRef = useRef(null);
    const credentialsRef = useRef();

    const [user, setUser] = useState({
        name: '',
        person_type_id: '',
        birthday: null,
        sex: 'unknown',
        doc_type: '',
        doc_number: null,
        issuing_agency: null,
        email: null,
        phone: null,
        occupation: '',
        role: 'employee',
        face_image: null,
        login: '',
        password: '',
        user_custom_inputs: [],
        antipassback: false,
        active_register: true,
        notes: '',
        only_allowed_by_related_access: false,
    });

    const [openGoBack, setOpenGoBack] = useState(false);
    const [enabledToLogin, setEnabledToLogin] = useState(false);
    const [cameraOpened, setCameraOpened] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });
    const [schedule, setSchedule] = useState(null);

    const webcamRef = useRef(null);

    const { id } = useParams();
    const history = useHistory();

    const [personTypes, setPersonTypes] = useState([{ name: 'test', id: 0 }]);
    const [personType, setPersonType] = useState({});
    const [personTypeSearch, setPersonTypeSearch] = useState("");

    useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/person_type/list', {
                    params: {
                        show_exceptions: false,
                        limit: 30,
                        search: personTypeSearch,
                    }
                });

                const { personTypes } = result.data;
                setPersonTypes(personTypes);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, [personTypeSearch]);

    const [relatedSearch, setRelatedSearch] = useState('');
    const [relatedLimit, setRelatedLimit] = useState(10);
    const [relatedPage, setRelatedPage] = useState(0);
    const [relatedTotal, setRelatedTotal] = useState(0);
    const [relatedResult, setRelatedResult] = useState([]);
    const [fetchOnlySelectedRelated, setFetchOnlySelectedRelated] = useState(false);

    const [dependantSearch, setDependantSearch] = useState('');
    const [dependantLimit, setDependantLimit] = useState(10);
    const [dependantPage, setDependantPage] = useState(0);
    const [dependantTotal, setDependantTotal] = useState(0);
    const [dependantResult, setDependantResult] = useState([]);
    const [fetchOnlySelectedDependant, setFetchOnlySelectedDependant] = useState(false);

    const [relatedRelationships, setRelatedRelationships] = useState([]);
    const [dependantRelationships, setDependantRelationships] = useState([]);
    const [openRelatedModal, setOpenRelatedModal] = useState(false);
    const [openDependantModal, setOpenDependantModal] = useState(false);

    const handleOnlySelectedRelated = (checked) => {
        setRelatedPage(0);
        setFetchOnlySelectedRelated(checked);
    };

    const handleOnlySelectedDependant = (checked) => {
        setDependantPage(0);
        setFetchOnlySelectedDependant(checked);
    }

    const [profiles, setProfiles] = useState([]);
    const [profilesSearch, setProfilesSearch] = useState("");
    const [profilesPage, setProfilesPage] = useState(0);
    const [profilesLimit, setProfilesLimit] = useState(10);
    const [profilesTotal, setProfilesTotal] = useState(0);
    const [fetchOnlySelectedProfiles, setFetchOnlySelectedProfiles] = useState(false);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [openProfilesModal, setOpenProfilesModal] = useState(false);

    const handleOnlySelectedProfiles = (checked) => {
        setProfilesPage(0);
        setFetchOnlySelectedProfiles(checked);
    };

    const toggleProfilesModal = () => setOpenProfilesModal((curr) => !curr);


    useEffect(() => {
        const loadUsers = async () => {
            try {
                const result = await api.get('/user/list_by_relationship', {
                    params: {
                        search: relatedSearch,
                        limit: relatedLimit,
                        page: relatedPage,
                        user_id: id,
                        onlySelected: fetchOnlySelectedRelated,
                        selectedPeople: relatedRelationships,
                    },
                });

                const { count, users } = result.data;

                console.log({ users })

                setRelatedResult(users);
                setRelatedTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadUsers();

        //eslint-disable-next-line
    }, [relatedPage, relatedLimit, relatedSearch, fetchOnlySelectedRelated, id]);

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const result = await api.get('/user/list_by_relationship', {
                    params: {
                        search: dependantSearch,
                        limit: dependantLimit,
                        page: dependantPage,
                        user_id: id,
                        onlySelected: fetchOnlySelectedDependant,
                        selectedPeople: dependantRelationships,
                    },
                });

                const { count, users } = result.data;

                console.log({ users })

                setDependantResult(users);
                setDependantTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadUsers();

        //eslint-disable-next-line
    }, [dependantPage, dependantLimit, dependantSearch, fetchOnlySelectedDependant, id]);

    useEffect(() => {
        const loadProfiles = async () => {
            try {
                const result = await api.get('/profile/index_to_bind', {
                    params: {
                        search: profilesSearch,
                        limit: profilesLimit,
                        page: profilesPage,
                        only_selected: fetchOnlySelectedProfiles,
                        selected_profiles: selectedProfiles,
                    },
                });

                const { count, profiles } = result.data;

                setProfiles(profiles);
                setProfilesTotal(count);
            } catch (err) {
                console.log(err);
            }
        };

        loadProfiles();
    }, [profilesSearch, profilesLimit, profilesPage, selectedProfiles, fetchOnlySelectedProfiles]);

    const changePage = (_, newPage, type) => {
        if (type === 'related') {
            setRelatedPage(newPage);
        } else if (type === 'dependant') {
            setDependantPage(newPage);
        } else if (type === 'profile') {
            setProfilesPage(newPage);
        }
    }

    const quantityPerPage = (target, type) => {
        if (type === 'related') {
            setRelatedLimit(parseInt(target.value, 10));
            setRelatedPage(0);
        } else if (type === 'dependant') {
            setDependantLimit(parseInt(target.value, 10));
            setDependantPage(0);
        } else if (type === 'profile') {
            setProfilesLimit(parseInt(target.value, 10));
            setProfilesPage(0);
        }
    };

    const checkRelated = (type, isSelected, id) => {
        if (type === "single") {
            let newRelated = Object.assign([], relatedRelationships);
            if (isSelected) {
                newRelated.push(id);
            } else {
                let index = relatedRelationships.indexOf(id);
                newRelated.splice(index, 1);
            }
            setRelatedRelationships(newRelated);
        } else if (type === 'all') {
            let allUsers = [];
            if (isSelected) {
                allUsers = Object.assign([], relatedRelationships);
                relatedResult.forEach(user => {
                    if (!allUsers.includes(user.id)) {
                        allUsers.push(user.id)
                    }
                });
            } else {
                let userIds = relatedResult.map(el => el.id);
                allUsers = relatedRelationships.filter(user => !userIds.includes(user));
            }
            setRelatedRelationships(allUsers);
        }
    };

    const checkDependant = (type, isSelected, id) => {
        if (type === "single") {
            let newDependant = Object.assign([], dependantRelationships);
            if (isSelected) {
                newDependant.push(id);
            } else {
                let index = dependantRelationships.indexOf(id);
                newDependant.splice(index, 1);
            }
            setDependantRelationships(newDependant);
        } else if (type === 'all') {
            let allUsers = [];
            if (isSelected) {
                allUsers = Object.assign([], dependantRelationships);
                dependantResult.forEach(user => {
                    if (!allUsers.includes(user.id)) {
                        allUsers.push(user.id)
                    }
                });
            } else {
                let userIds = dependantResult.map(el => el.id);
                allUsers = dependantRelationships.filter(user => !userIds.includes(user));
            }
            setDependantRelationships(allUsers);
        }
    };

    const checkProfile = (type, isSelected, id) => {
        if (type === "single") {
            let newProfiles = Object.assign([], selectedProfiles);
            if (isSelected) {
                newProfiles.push(id);
            } else {
                let index = selectedProfiles.indexOf(id);
                newProfiles.splice(index, 1);
            }
            setSelectedProfiles(newProfiles);
        } else if (type === 'all') {
            let allProfiles = [];
            if (isSelected) {
                allProfiles = Object.assign([], selectedProfiles);
                profiles.forEach(user => {
                    if (!allProfiles.includes(user.id)) {
                        allProfiles.push(user.id)
                    }
                });
            } else {
                let profileIds = profiles.map(el => el.id);
                allProfiles = selectedProfiles.filter(profile => !profileIds.includes(profile));
            }
            setSelectedProfiles(allProfiles);
        }
    };

    useEffect(() => {
        const loadUser = async () => {
            try {
                const result = await api.get(`/user/detail/${id}`);

                let {
                    user,
                    inputs,
                    allowedGroups,
                    allowedDevices,
                    allowedCameras,
                    relatedRelationships: related,
                    dependantRelationships: dependant,
                    profiles: userProfiles,
                } = result.data;

                accessRef?.current?.load({
                    loadingGroups: allowedGroups,
                    loadingDevices: allowedDevices,
                    loadingCameras: allowedCameras,
                });

                credentialsRef?.current?.load(user?.credentials);

                user.user_custom_inputs = inputs;

                setUserCustomInputs(inputs);

                if (user.face_image) {
                    const image = await dataUrlToFile(`http://${host}:${port}/midias/imagem/face/${user.face_image}`);

                    user.face_image = image;
                }

                if (user.login) {
                    setEnabledToLogin(true);
                }

                setSchedule(user.schedule)
                delete user.schedule;
                setUser(user);
                setDependantRelationships(dependant);
                setRelatedRelationships(related);
                setSelectedProfiles(userProfiles);

                if (user.PersonType) {
                    setPersonType(user.PersonType);
                }

                if (user.OrganizationalStructure) {
                    setSelectedOrg(user.OrganizationalStructure);
                }
            }
            catch (err) {
                console.log(err)
                history.goBack();
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
                // setTimeout(() => {
                //     history.goBack();
                // }, 2000);
            }
        };

        loadUser();
    }, [id, history]);

    const [orgsSelect, setOrgsSelect] = useState([]);
    const [orgSearch, setOrgSearch] = useState("");
    const [selectedOrg, setSelectedOrg] = useState({});

    useEffect(() => {
        const findOrg = async () => {
            try {
                const result = await api.get('/organizational_structure/index', {
                    params: {
                        search: orgSearch,
                    }
                });

                const { orgs } = result.data;

                setOrgsSelect(orgs);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findOrg();
    }, [orgSearch]);


    const dataUrlToFile = async dataUrl => {
        const image = await fetch(dataUrl)
            .then(res => res.arrayBuffer())
            .then(buffer => new File([buffer], 'face_image.jpeg', { type: 'image/jpg' }));

        return image;
    };

    const [loadedImage, setLoadedImage] = useState(null);

    const captureImage = useCallback(async setFieldValue => {
        const dataUrl = webcamRef.current.getScreenshot();

        if (dataUrl) {
            const image = await dataUrlToFile(dataUrl);

            setFieldValue('face_image', image);
            // console.log('caputured face image');
        }
    }, [webcamRef]);

    const uploadImage = (e, setFieldValue) => {
        // console.log(e)
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setFieldValue('face_image', e.target.files[0]);
                // console.log('uploaded face image')
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const ImagePreview = ({ image }) => {
        if (loadedImage === null) {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setLoadedImage(reader.result)
                }
            }
            reader.readAsDataURL(image);
            // console.log('image converted')
        }
        // console.log('rendering preview')

        return (
            <img
                src={loadedImage}
                alt="Imagem de identificação"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
        );
    };

    const saveUser = async (values, formik) => {
        try {
            const data = new FormData();

            data.append('face_image', values.face_image);

            values.person_type_id = personType.id;
            values.org_id = selectedOrg.id;

            if (values.login === null) {
                delete values.login;
            }

            const userData = {
                ...values,
                schedule,
                related_relationships: relatedRelationships,
                dependant_relationships: dependantRelationships,
                credentials: credentialsRef.current.getValues(),
                profiles: selectedProfiles,
                ...accessRef.current.getValues(),
            };

            userData.face_image = undefined;

            data.append('user_data', JSON.stringify(userData));
            // console.log('data', userData);

            const result = await api.post(`/user/modify/${id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const { user } = result.data;

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Pessoa ${user.name} modificada com sucesso!`,
            });

            setTimeout(() => setOpenGoBack(true), 1500);
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };



    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const deleteUser = async () => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/user/delete/${id}`);

            const { success } = result.data;

            setIsDeleting(false);

            if (success) {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Usuário ${user.name} deletado com sucesso!`,
                });
            }
            history.goBack();
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    const formatUserData = values => {
        if (values.phone)
            values.phone = values.phone.replace(/\D/g, '');
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [userCustomInputs, setUserCustomInputs] = useState([]);
    const [customInputs, setCustomInputs] = useState([]);
    const [loop, setLoop] = useState(0);

    useEffect(() => {
        const findInputs = async () => {
            try {
                const result = await api.get('/custom_input/list', {
                    params: {
                        target: ['all', 'regular'],
                        page: 0,
                        limit: 30,
                    }
                });

                const { custom_inputs } = result.data;

                setCustomInputs(custom_inputs);
            }
            catch (err) {
                console.log(err);
            }
        };

        findInputs();
    }, []);

    const isFullAccess = () => {
        let checked = false;
        if (schedule) {
            checked = Object.keys(schedule)?.every((day) => {
                if (schedule[day]?.first_period?.value === '00:00 - 23:59') return true
                else return false
            });
        }

        return checked;
    }

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Modificar pessoa
                        </Typography>
                    </Grid>
                    <Formik
                        enableReinitialize={true}
                        initialValues={user}
                        validate={formatUserData}
                        onSubmit={saveUser}
                    >
                        {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="user-create" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3} className={classes.formSection}>
                                                    <div className={classes.faceImageContainer}
                                                        style={{
                                                            height: '300px',
                                                            width: '350px',
                                                        }}
                                                    >
                                                        <div
                                                            className={classes.faceImageContent}
                                                            style={{
                                                                width: "100%",
                                                                height: "200px"
                                                            }}
                                                        >
                                                            {!cameraOpened ? (
                                                                values.face_image ? (
                                                                    <ImagePreview image={values.face_image} />
                                                                ) : (
                                                                    <div className={classes.noImage}>
                                                                        <PhotoCameraIcon />
                                                                        <Typography variant="subtitle2">
                                                                            SEM IMAGEM
                                                                        </Typography>
                                                                    </div>
                                                                )
                                                            ) : (
                                                                <Webcam
                                                                    ref={webcamRef}
                                                                    audio={false}
                                                                    screenshotFormat="image/jpeg"
                                                                    screenshotQuality={1}
                                                                    forceScreenshotSourceSize={true}
                                                                    // width="100%"
                                                                    height="100%"
                                                                />
                                                            )}
                                                        </div>
                                                        {values.face_image && (
                                                            <Button
                                                                className={classes.removeImageButton}
                                                                size="small"
                                                                type="button"
                                                                variant="outlined"
                                                                color="secondary"
                                                                endIcon={<DeleteIcon />}
                                                                fullWidth
                                                                onClick={() => {
                                                                    setFieldValue('face_image', null);
                                                                    setLoadedImage(null);
                                                                }}
                                                            >
                                                                Remover
                                                            </Button>
                                                        )}
                                                        {
                                                            !values.face_image && (
                                                                <>
                                                                    <Button
                                                                        className={classes.faceImageButton}
                                                                        type="button"
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        endIcon={<PhotoCameraIcon />}
                                                                        fullWidth
                                                                        onClick={async () => {
                                                                            if (cameraOpened) await captureImage(setFieldValue);

                                                                            setCameraOpened(!cameraOpened);
                                                                        }}
                                                                    >
                                                                        {cameraOpened ? 'Tirar foto' : 'Abrir câmera'}
                                                                    </Button>
                                                                    {
                                                                        !cameraOpened && (
                                                                            <Button
                                                                                className={classes.faceImageButton}
                                                                                type="button"
                                                                                variant="outlined"
                                                                                color="primary"
                                                                                endIcon={<UploadIcon />}
                                                                                fullWidth
                                                                                component="label"
                                                                            >
                                                                                Adicionar Foto
                                                                                <input
                                                                                    name='map_image'
                                                                                    type="file"
                                                                                    accept="image/*"
                                                                                    onChange={(e) => uploadImage(e, setFieldValue)}
                                                                                    hidden
                                                                                    required={false}
                                                                                />
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                            DADOS PESSOAIS
                                                        </Typography>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={8}>
                                                                <Input
                                                                    label="Nome completo"
                                                                    textFieldProps={{
                                                                        name: 'name',
                                                                        placeholder: 'Nome completo',
                                                                        onChange: handleChange,
                                                                        value: values.name,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Autocomplete
                                                                    label="Tipo de Pessoa"
                                                                    placeholder="Tipo de pessoa"
                                                                    options={personTypes}
                                                                    getOptionLabel={({ name }) => name || ''}
                                                                    onChange={(_, value) => setPersonType(value)}
                                                                    getOptionSelected={(option, value) => (option.id === value.id)}
                                                                    onInputChange={(_, value) => setPersonTypeSearch(value)}
                                                                    value={personType}
                                                                    required={true}
                                                                    fullWidth />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={3}>
                                                                <Input
                                                                    label="Orgão Emissor"
                                                                    textFieldProps={{
                                                                        name: 'issuing_agency',
                                                                        placeholder: 'Orgão Emissor',
                                                                        onChange: handleChange,
                                                                        value: values.issuing_agency,
                                                                        fullWidth: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Select
                                                                    label="Tipo de Documento"
                                                                    options={[
                                                                        { label: '-- Selecione --', value: '' },
                                                                        { label: 'CPF', value: 'unknown' },
                                                                        { label: 'RG', value: 'ic' },
                                                                        { label: 'Passaporte', value: 'passport' },
                                                                        { label: 'Outro', value: 'other' },
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'doc_type',
                                                                        onChange: handleChange,
                                                                        fullWidth: true,
                                                                        value: values.doc_type,
                                                                        required: values.doc_number !== null && values.doc_number !== '',
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Input
                                                                    label="Número do Documento"
                                                                    textFieldProps={{
                                                                        name: 'doc_number',
                                                                        placeholder: 'Número do Documento',
                                                                        onChange: handleChange,
                                                                        value: values.doc_number,
                                                                        fullWidth: true,
                                                                        required: values.doc_type !== null && values.doc_type !== '',
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <DatePicker
                                                                    label="Data de nascimento"
                                                                    format="DD/MM/YYYY"
                                                                    value={values.birthday}
                                                                    placeholder="Data de nascimento"
                                                                    onChange={date => setFieldValue('birthday', date && date.format())}
                                                                    cancelLabel="cancelar"
                                                                    fullWidth={true}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={3}>
                                                            <Grid item xs={4}>
                                                                <Select
                                                                    label="Sexo"
                                                                    options={[
                                                                        { label: '-- Selecione --', value: 'unknown', id: 'unknown' },
                                                                        { label: 'Masculino', value: 'male', id: 'male' },
                                                                        { label: 'Feminino', value: 'female', id: 'female' },
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'sex',
                                                                        onChange: handleChange,
                                                                        fullWidth: true,
                                                                        value: values.sex,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Input
                                                                    label="E-mail"
                                                                    textFieldProps={{
                                                                        name: 'email',
                                                                        placeholder: 'E-mail',
                                                                        onChange: handleChange,
                                                                        value: values.email,
                                                                        fullWidth: true,
                                                                        required: false,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Input
                                                                    label="Telefone"
                                                                    inputComponent={PhoneMask}
                                                                    textFieldProps={{
                                                                        name: 'phone',
                                                                        placeholder: 'Telefone',
                                                                        onChange: handleChange,
                                                                        value: values.phone,
                                                                        fullWidth: true,
                                                                        required: false,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Grid
                                                    container
                                                    style={{ marginBottom: '8px', marginTop: '8px' }}
                                                    justify='space-between'
                                                >
                                                    <Grid item style={{ textAlign: 'center' }}>
                                                        <Typography variant="subtitle1" className={classes.noMarginFormSectionTitle}>
                                                            PERÍODOS DE ACESSO
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item
                                                        style={{
                                                            display: 'flex',
                                                            marginLeft: '10px',
                                                        }}
                                                    >
                                                        <Grid container spacing={1} alignItems="center" justify="center">
                                                            <Typography>Acesso Regular</Typography>
                                                            <Switch
                                                                color="primary"
                                                                checked={fullAccess || isFullAccess()}
                                                                onChange={({ target }) => {
                                                                    setFullAccess(target.checked);
                                                                    scheduleRef.current.setFullAccess(target.checked);
                                                                }}
                                                                inputProps={{
                                                                    'aria-label': 'notification-status'
                                                                }}
                                                            />
                                                            <Typography>Acesso Total</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SchedulePicker ref={scheduleRef} setMessage={setMessage} setSchedule={setSchedule} loadingSchedule={schedule} />
                                                </Grid>
                                            </Grid>
                                            {(values.role === 'employee') && (
                                                <Grid container className={classes.formSection}>
                                                    <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                        ORGANIZAÇÃO
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={3}>
                                                            <Input
                                                                label="Ocupação"
                                                                textFieldProps={{
                                                                    name: 'occupation',
                                                                    placeholder: 'Ocupação',
                                                                    onChange: handleChange,
                                                                    value: values.occupation,
                                                                    fullWidth: true,
                                                                    required: false,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Autocomplete
                                                                label="Estrutura Organizacional"
                                                                placeholder="Estrutura Organizacional"
                                                                id="org_id"
                                                                options={orgsSelect}
                                                                getOptionLabel={({ name }) => name || ''}
                                                                onChange={(_, value) => setSelectedOrg(value)}
                                                                getOptionSelected={(option, value) => (option.id === value.id)}
                                                                onInputChange={(_, value) => setOrgSearch(value)}
                                                                value={selectedOrg}
                                                                required={true}
                                                                fullWidth />
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            )}
                                            <Grid container className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitleTwo}>
                                                    INFORMAÇÕES PERSONALIZADAS
                                                </Typography>
                                                <Grid container spacing={3}>
                                                    {userCustomInputs.map((input, index) => {
                                                        if (index === 0 && values.user_custom_inputs.length !== userCustomInputs.length && loop === 0) {
                                                            // console.log('0 loop')
                                                            setLoop(1)
                                                            values.user_custom_inputs = userCustomInputs

                                                        }


                                                        let label = '';
                                                        let is_required = '';
                                                        let ciType = '';
                                                        let ciOptions = [];
                                                        customInputs.forEach(ci => {
                                                            if (ci.id === input.custom_input_id) {
                                                                label = ci.name;
                                                                is_required = ci.is_required
                                                                ciType = ci?.type;
                                                                ciOptions = ci?.options;
                                                            }
                                                        });
                                                        // console.log({ values: values.user_custom_inputs })
                                                        return (
                                                            <Grid key={index} item xs={4}>
                                                                {
                                                                    ciType === "normal" ? (
                                                                        <Input
                                                                            label={label}
                                                                            textFieldProps={{
                                                                                name: `user_custom_inputs[${index}].information`,
                                                                                onChange: handleChange,
                                                                                value: values.user_custom_inputs[index]['information'] ? values.user_custom_inputs[index]['information'] : '',
                                                                                fullWidth: true,
                                                                                required: is_required,
                                                                            }}
                                                                        />
                                                                    ) : (

                                                                        <Autocomplete
                                                                            label={label}
                                                                            id={`custom_inputs${index}.information`}
                                                                            options={ciOptions?.length && ciOptions?.map((option) => ({ name: option, value: option, id: option }))}
                                                                            getOptionLabel={({ name }) => name || ''}
                                                                            onChange={(_, value) => setFieldValue(`user_custom_inputs[${index}].information`, value)}
                                                                            getOptionSelected={(option, value) => (option.id === value.id)}
                                                                            /* onInputChange={(_, value) => setPersonTypeSearch(value)} */
                                                                            value={values.user_custom_inputs[index]['information'] ? values.user_custom_inputs[index]['information'] : ''}
                                                                            required={is_required}
                                                                            fullWidth
                                                                        />
                                                                    )
                                                                }
                                                                {/* <Input */}
                                                                {/*     label={label} */}
                                                                {/*     textFieldProps={{ */}
                                                                {/*         name: `user_custom_inputs[${index}].information`, */}
                                                                {/*         onChange: handleChange, */}
                                                                {/*         value: values.user_custom_inputs[index]['information'] ? values.user_custom_inputs[index]['information'] : '', */}
                                                                {/*         fullWidth: true, */}
                                                                {/*         required: is_required, */}
                                                                {/*     }} */}
                                                                {/* /> */}
                                                            </Grid>
                                                        )

                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    OBSERVAÇÕES
                                                </Typography>
                                                <Grid item xs={12} style={{ height: '100%' }}>
                                                    <textarea
                                                        placeholder="Escreva o texto aqui"
                                                        multiline
                                                        minRows={20}
                                                        style={{ width: '100%', border: '1px solid #c6c6c6', minHeight: '200px', resize: 'vertical', padding: '10px', fontSize: '1.2rem', color: grey[600], fontFamily: 'Arial, sans-serif' }}
                                                        value={values.notes}
                                                        onChange={({ target }) => setFieldValue('notes', target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {(values.role === 'employee') && (
                                                <Grid container className={classes.formSection}>
                                                    <Typography xs={12} variant="subtitle1" className={classes.formSectionTitle}>
                                                        Permissões
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Paper
                                                                className={classes.loginContainer}
                                                                onClick={() => {
                                                                    if (enabledToLogin) {
                                                                        values.login = ''
                                                                        values.password = ''
                                                                    }
                                                                    setEnabledToLogin(!enabledToLogin)

                                                                }}
                                                                align='center'
                                                            >
                                                                <Checkbox
                                                                    name='enabledToLogin'
                                                                    checked={enabledToLogin}
                                                                    color="primary"
                                                                    placeholder='login'
                                                                />
                                                                <div className={classes.cameraName}>
                                                                    <Typography variant="subtitle2">
                                                                        Habilitar/Desabilitar Login
                                                                    </Typography>
                                                                </div>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Input
                                                                label="Login"
                                                                textFieldProps={{
                                                                    name: 'login',
                                                                    placeholder: 'Login do usuário',
                                                                    onChange: handleChange,
                                                                    value: values.login,
                                                                    fullWidth: true,
                                                                    disabled: !enabledToLogin,
                                                                    required: enabledToLogin,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Input
                                                                label="Senha"
                                                                textFieldProps={{
                                                                    name: 'password',
                                                                    type: 'password',
                                                                    placeholder: 'Senha do usuário',
                                                                    onChange: handleChange,
                                                                    value: values.password,
                                                                    fullWidth: true,
                                                                    disabled: !enabledToLogin,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3} style={{
                                                        marginTop: 2
                                                    }}>
                                                        <Grid item xs={4}>
                                                            <Select
                                                                label="Cadastro Ativo"
                                                                options={[
                                                                    { label: "Não", value: false },
                                                                    { label: "Sim", value: true },
                                                                ]}
                                                                textFieldProps={{
                                                                    name: "active_register",
                                                                    value: values.active_register,
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Select
                                                                label="Ignorar Passback"
                                                                tip={(
                                                                    <Typography>
                                                                        Ao ativar essa opção, as regras de passback não se aplicaram a essa pessoa.
                                                                        <br />
                                                                        <i>Usar com cautela.</i>
                                                                    </Typography>
                                                                )}
                                                                options={[
                                                                    { label: "Não", value: false },
                                                                    { label: "Sim", value: true },
                                                                ]}
                                                                textFieldProps={{
                                                                    name: "antipassback",
                                                                    value: values.antipassback,
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Select
                                                                label="Apenas permitido por acesso do relacionado"
                                                                tip={(
                                                                    <Typography>
                                                                        Essa pessoa so poderá acessar se um dos seus vínculos de depêndencia realizou uma entrada nos últimos 5 minutos.
                                                                    </Typography>
                                                                )}
                                                                options={[
                                                                    { label: "Não", value: false },
                                                                    { label: "Sim", value: true },
                                                                ]}
                                                                textFieldProps={{
                                                                    name: "only_allowed_by_related_access",
                                                                    value: values.only_allowed_by_related_access,
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} className={classes.formSection}>
                                                <Grid container spacing={2} justify='space-evenly'>
                                                    <Grid item xs={4}>
                                                        <CredentialsManager
                                                            userName={values.name}
                                                            setMessage={setMessage}
                                                            ref={credentialsRef}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            variant="contained"
                                                            onClick={toggleProfilesModal}
                                                            fullWidth={true}
                                                        >
                                                            GERENCIAR PERFIS
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formSection}>
                                                <Grid container spacing={2} justify='space-evenly'>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => setOpenRelatedModal(true)}
                                                            fullWidth={true}
                                                        >
                                                            Vínculos de relacionamento {`(${relatedRelationships.length})`}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            variant="contained"
                                                            fullWidth={true}
                                                            onClick={() => setOpenDependantModal(true)}
                                                        >
                                                            Vínculos de dependência {`(${dependantRelationships.length})`}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.formSection}>
                                                <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                    AUTORIZAÇÃO
                                                </Typography>
                                                <AccessPicker ref={accessRef} />
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>

                                <Popup
                                    openPopup={openDeletePopup}
                                    setOpenPopup={setOpenDeletePopup}

                                >
                                    <Grid container xs={12} style={{
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '0 3% 2% 3%',

                                    }}>
                                        <Typography variant='h5'
                                            style={{
                                                fontWeight: 'bold',
                                                color: '#9e9e9e'
                                            }}
                                        >
                                            Deseja mesmo deletar o usuário "{user.name}"?
                                        </Typography>
                                        <Grid item style={{
                                            marginTop: '50px'
                                        }}>
                                            <Button
                                                disabled={isDeleting}
                                                variant="contained"
                                                onClick={() => deleteUser()}
                                                color="secondary"
                                                endIcon={<DeleteIcon />}
                                            >
                                                {isDeleting ? 'Deletando...' : 'Sim, delete o usuário'}
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </Popup>
                                <Grid item xs={12} style={{ margin: '0 1%' }} container justify="space-evenly">
                                    <PermissionWrapper route="/pessoa/deletar/:id">
                                        <Button
                                            disabled={isDeleting}
                                            variant="contained"
                                            onClick={() => setOpenDeletePopup(true)}
                                            color="secondary"
                                            endIcon={<DeleteIcon />}
                                        >
                                            Deletar usuário
                                        </Button>
                                    </PermissionWrapper>
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        form="user-create"
                                        color="primary"
                                        endIcon={<SaveIcon />}
                                    >
                                        {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
            <Popup
                openPopup={openRelatedModal}
                setOpenPopup={setOpenRelatedModal}
                title="Adcione pessoas relacionadas a essa pessoa"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Grid item xs={4}>
                            <Input
                                icon={<SearchIcon style={{ color: grey[400] }} />}
                                iconPosition="start"
                                textFieldProps={{
                                    placeholder: 'Buscar…',
                                    onChange: ({ target }) => setRelatedSearch(target.value),
                                    values: relatedSearch,
                                    fullWidth: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                label="Mostrar apenas selecionados"
                                style={{
                                    userSelect: "none",
                                }}
                                checked={fetchOnlySelectedRelated}
                                onChange={({ target: { checked } }) => handleOnlySelectedRelated(checked)}
                                control={
                                    <Checkbox
                                        color='primary'

                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="users list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Pessoa</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllUsers"
                                                color="primary"
                                                // checked={selectedUsers.length === users.length}
                                                checked={relatedResult.every(el => {
                                                    return relatedRelationships.includes(el.id);
                                                })}
                                                onChange={({ target }) => checkRelated('all', target.checked)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!relatedResult && relatedResult.map(person => (
                                        <TableRow key={person.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {person.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <RedirectToUser pType={person.person_type_id} uId={person.id}>
                                                    {person.name}
                                                </RedirectToUser>
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.doc_type && person?.doc_type === 'ic' ? 'RG' :
                                                    person?.doc_type === 'passport' ? 'Passaporte' :
                                                        person?.doc_type === 'other' ? 'Outro' :
                                                            person?.doc_type === 'unknown' ? 'CPF' :
                                                                'Não identificado'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.doc_number ? person.doc_number : "Não identificado"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.PersonType?.name ? person.PersonType?.name : "Não identificado"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addUser"
                                                    color="primary"
                                                    checked={relatedRelationships.includes(person.id)}
                                                    onChange={({ target }) => checkRelated('single', target.checked, person.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={relatedTotal}
                            rowsPerPage={relatedLimit}
                            page={relatedPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'related')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'related')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openDependantModal}
                setOpenPopup={setOpenDependantModal}
                title="Adcione pessoas das quais a atual depende"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Grid item xs={4}>
                            <Input
                                icon={<SearchIcon style={{ color: grey[400] }} />}
                                iconPosition="start"
                                textFieldProps={{
                                    placeholder: 'Buscar…',
                                    onChange: ({ target }) => setDependantSearch(target.value),
                                    values: dependantSearch,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                label="Mostrar apenas selecionados"
                                style={{
                                    userSelect: "none",
                                }}
                                checked={fetchOnlySelectedDependant}
                                onChange={({ target: { checked } }) => handleOnlySelectedDependant(checked)}
                                control={
                                    <Checkbox
                                        color='primary'
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="users list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Pessoa</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllUsers"
                                                color="primary"
                                                // checked={selectedUsers.length === users.length}
                                                checked={dependantResult.every(el => {
                                                    return dependantRelationships.includes(el.id);
                                                })}
                                                onChange={({ target }) => checkDependant('all', target.checked)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!dependantResult && dependantResult.map(person => (
                                        <TableRow key={person.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {person.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <RedirectToUser pType={person.person_type_id} uId={person.id}>
                                                    {person.name}
                                                </RedirectToUser>
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.doc_type && person?.doc_type === 'ic' ? 'RG' :
                                                    person?.doc_type === 'passport' ? 'Passaporte' :
                                                        person?.doc_type === 'other' ? 'Outro' :
                                                            person?.doc_type === 'unknown' ? 'CPF' :
                                                                'Não identificado'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.doc_number ? person.doc_number : "Não identificado"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {person.PersonType?.name ? person.PersonType?.name : "Não identificado"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addUser"
                                                    color="primary"
                                                    checked={dependantRelationships.includes(person.id)}
                                                    onChange={({ target }) => checkDependant('single', target.checked, person.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={dependantTotal}
                            rowsPerPage={dependantLimit}
                            page={dependantPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'dependant')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'dependant')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openProfilesModal}
                setOpenPopup={setOpenProfilesModal}
                title="Perfis"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Grid item xs={4}>
                            <Input
                                icon={<SearchIcon style={{ color: grey[400] }} />}
                                iconPosition="start"
                                textFieldProps={{
                                    placeholder: 'Buscar…',
                                    onChange: ({ target }) => setProfilesSearch(target.value),
                                    values: profilesSearch,
                                    fullWidth: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                label="Mostrar apenas selecionados"
                                style={{
                                    userSelect: "none",
                                }}
                                checked={fetchOnlySelectedProfiles}
                                onChange={({ target: { checked } }) => handleOnlySelectedProfiles(checked)}
                                control={
                                    <Checkbox
                                        color='primary'
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="profiles list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllProfiles"
                                                color="primary"
                                                checked={profiles.every(el => {
                                                    return selectedProfiles.includes(el.id);
                                                })}
                                                onChange={({ target }) => checkProfile('all', target.checked)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!profiles && profiles.map(profile => (
                                        <TableRow key={profile.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {profile.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {profile.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addProfile"
                                                    color="primary"
                                                    checked={selectedProfiles.includes(profile.id)}
                                                    onChange={({ target }) => checkProfile('single', target.checked, profile.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={profilesTotal}
                            rowsPerPage={profilesLimit}
                            page={profilesPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'profile')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'profile')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openGoBack}
                setOpenPopup={setOpenGoBack}
                title="Voltar a listagem de pessoas?"
                width="30%"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 6,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "40%", fontWeight: 700 }}
                        size="large"
                        onClick={() => history.push("/pessoa")}
                    >
                        SIM
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ width: "40%", fontWeight: 700 }}
                        size="large"
                        onClick={() => setOpenGoBack(false)}
                    >
                        NÃO
                    </Button>
                </div>
            </Popup>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    formSectionTitleTwo: {
        fontWeight: 'bold',
        color: grey[700],
    },
    userRoles: {
        '& > button': {
            marginRight: theme.spacing(1),
        },
    },
    faceImageContainer: {
        width: '100%',
        height: '303px',
        border: `solid 1px ${indigo[200]}`,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    faceImageContent: {
        width: '100%',
        flex: 7,
        backgroundColor: grey[50],
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(.5),
    },
    faceImageButton: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        flex: 1,
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    noImage: {
        textAlign: 'center',
        color: grey[400],
    },
    cameraContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& h6 > span': {
            marginLeft: theme.spacing(.5),
        },
        '&:hover': {
            boxShadow: '0 2px 10px 1px rgba(150,150,150,.3)',
        },
    },
    daysPicker: {
        background: '#F0F0F0',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    dayButton: {
        padding: '10%',
    },
    loginContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& h6 > span': {
            marginLeft: theme.spacing(.5),
        },
        background: 'transparent',
        boxShadow: 'none',
        fontWeight: 'bold',
        color: '#9e9e9e'
    },
    enableAll: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        background: 'transparent',
        boxShadow: 'none',
        fontWeight: 'bold',
        color: '#9e9e9e',
        boxSizing: 'border-box',

    },
    customInput: {
        color: '#9e9e9e',
        fontWeight: 'bold',
        marginBottom: '8px',
        background: 'transparent',

    },
    leftSide: {
        width: '39%',
        boxShadow: '0 6px 8px #cdcdcd',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
        '& h6': {
            fontWeight: 'bold',
            color: '#616161',
        },
    },
    rightSide: {
        padding: theme.spacing(2),
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h5': {
            fontWeight: 'bold',
            color: '#616161',
        },
    },
    fingerprintImage: {
        height: '100px',
        width: '100px'
    },
    statusLabel: {
        display: 'block',
        fontWeight: 'bold',
        color: grey[500],
        marginBottom: theme.spacing(1),
    },
    noMarginFormSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
    },
    tableContainer: {
        maxHeight: '95%',
        width: '100%',
    },
    table: {
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
}));

const PhoneMask = props => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'_'}
            showMask
        />
    );
};
