import React, { useEffect, useReducer, useMemo, useContext } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { ShortcutsProvider } from './providers/Shortcuts';
import { WebNotificationProvider } from './providers/WebNotification';
// import Shortcuts from './components/Shortcuts';

import Login from './pages/Login/Login';

import Dashboard from './pages/Dashboard/Dashboard';
// User routes [OK]
import UserList from './pages/User/UserList';
import UserCreate from './pages/User/UserCreate';
import UserModify from './pages/User/UserModify';
import UserLogList from './pages/User/UserLogList'; //** */
// Device routes [OK]
import DeviceList from './pages/Device/DeviceList';
import DeviceCreate from './pages/Device/DeviceCreate';
import DeviceModify from './pages/Device/DeviceModify';

// Camera [OK]
import CameraLogList from './pages/Camera/CameraLogList';
import CameraView from './pages/Camera/CameraView';

import GateAccess from './pages/GateAccess/GateAccess';

// Case routes []
import CaseList from './pages/Case/CaseList';
import CaseCreate from './pages/Case/CaseCreate';
import CaseModify from './pages/Case/CaseModify';
import CaseTypeList from './pages/CaseType/CaseTypeList';

// CaseFile routes []
import CaseFileList from './pages/CaseFile/CaseFileList';

import Location from './pages/Location/Location';
// Organizational routes []
import OrganizationalList from './pages/Organizational/OrganizationalList';
import OrganizationalCreate from './pages/Organizational/OrganizationalCreate';
import OrganizationalModify from './pages/Organizational/OrganizationalModify';
import OrganizationalView from './pages/Organizational/OrganizationalView';
//Credential routes  [OK]
import CredentialList from './pages/Credential/CredentialList';
import CredentialView from './pages/Credential/CredentialView';
import CredentialCreate from './pages/Credential/CredentialCreate';
import CredentialModify from './pages/Credential/CredentialModify';
// Funcionality routes [OK]
import FunctionalityList from './pages/Functionality/FunctionalityList';
import FunctionalityCreate from './pages/Functionality/FunctionalityCreate';
import FunctionalityModify from './pages/Functionality/FunctionalityModify';
// Profile routes
import ProfileList from './pages/Profile/ProfileList';

// Place routes [Ok]
import PlaceList from './pages/Place/PlaceList';
import PlaceCreate from './pages/Place/PlaceCreate';
import PlaceModify from './pages/Place/PlaceModify';
// Person TYpe routes [OK]
import PersonTypeList from './pages/PersonType/PersonTypeList';
import PersonTypeCreate from './pages/PersonType/PersonTipeCreate';
import PersonTypeModify from './pages/PersonType/PersonTypeModify';

import VehicleTypeList from './pages/VehicleType/VehicleTypeList';
import VehicleTypeCreate from './pages/VehicleType/VehicleTypeCreate';
import VehicleTypeModify from './pages/VehicleType/VehicleTypeModify';
// UR routes
import UnifiedReport from './pages/Report/UnifiedReport';
import VehicleReport from './pages/Report/VehicleReport';
// Map routes [OK]
import MapCreate from './pages/Map/MapCreate';
import MapList from './pages/Map/MapList';
// Custom input routes [OK]
import CustomInputCreate from './pages/CustomInputs/CustomInputCreate';
import CustomInputList from './pages/CustomInputs/CustomInputList';
import CustomInputModify from './pages/CustomInputs/CustomInputModify';
// Notification routes [OK]
import NotificationList from './pages/Notification/NotificationList';
import NotificationCreate from './pages/Notification/NotificationCreate'
import NotificationModify from './pages/Notification/NotificationModify';
// Help routes [OK]
import HelpIndex from './pages/Help/HelpIndex';
import HelpCreate from './pages/Help/HelpCreate';
import HelpModify from './pages/Help/HelpModify';
import HelpView from './pages/Help/HelpView';

import VehicleList from './pages/Vehicle/VehicleList';
import VehicleCreate from './pages/Vehicle/VehicleCreate';
import VehicleModify from './pages/Vehicle/VehicleModify';

import GuestCreate from './pages/Guest/GuestCreate';
import GuestModify from './pages/Guest/GuestModify';

import WantedCreate from './pages/Wanted/WantedCreate';
import WantedModify from './pages/Wanted/WantedModify';

import ExportPeopleToDeviceList from './pages/ExportPeopleToDevice/ExportPeopleToDeviceList';
import ExportPeopleToDevice from './pages/ExportPeopleToDevice/ExportPeopleToDevice';
import ExportPeopleToDeviceView from './pages/ExportPeopleToDevice/ExportPeopleToDeviceView';

import DeviceRemoteControl from './pages/Device/DeviceRemoteControl';

import ShortcutList from './pages/Shortcuts/ShortcutList';

import DeviceGroupList from './pages/DeviceGroup/DeviceGroupList';
import DeviceGroupCreate from './pages/DeviceGroup/DeviceGroupCreate';
import DeviceGroupModify from './pages/DeviceGroup/DeviceGroupModify';

import CamerasList from './pages/DeviceCameras/DeviceList'

import SecurityPanelList from './pages/SecurityPanel/SecurityPanelList';
import SecurityPanelCreate from './pages/SecurityPanel/SecurityPanelCreate';
import SecurityPanelModify from './pages/SecurityPanel/SecurityPanelModify';
import SecurityPanelView from './pages/SecurityPanel/SecurityPanelView';

import AbsenceNotificationList from './pages/AbsenceNotification/AbsenceNotificationList';
import AbsenceNotificationCreate from './pages/AbsenceNotification/AbsenceNotificationCreate';
import AbsenceNotificationModify from './pages/AbsenceNotification/AbsenceNotificationModify';

import OperationLogList from './pages/OperationLog/OperationLogList';
import TimelineViewer from './pages/TimelineRecorder/TimelineViewer';

import { AuthProvider, AuthConsumer } from './providers/Auth';
import api from './services/api';
import storage from './utils/storage';
import Home from './pages/Home/Home';
import ApiEndpointList from './pages/ApiEndpoint/ApiEndpointList';
import { PermissionsContext } from './providers/Permissions';
import WebhookList from './pages/Webhook/WebhookList';

export default function MainRoutes() {
    const { allowedRoutes, insertPermissions } = useContext(PermissionsContext);

    const [state, dispatch] = useReducer(
        (prevState, action) => {
            switch (action.type) {
                case 'VERIFY_AUTH':
                    return {
                        ...prevState,
                        token: action.token,
                        role: action.role,
                        loading: false,
                    };
                case 'SIGN_IN':
                    return {
                        ...prevState,
                        token: action.token,
                        role: action.role,
                        signing_out: false,
                    };
                case 'SIGN_OUT':
                    return {
                        ...prevState,
                        token: null,
                        role: null,
                        signing_out: true,
                    };
                default:
                    return {
                        token: null,
                        role: null,
                        loading: true,
                        signing_out: false,
                    };
            }
        },
        {
            token: null,
            role: null,
            loading: true,
            routesAllowedToAccess: [],
            signing_out: false,
        }
    );

    // const [routesAllowedToAccess, setRoutesAllowedToAccess] = useState([]);

    const authContext = useMemo(() => ({
        isAuthorized: roles => (
            state.token != null && ((roles && roles.includes(state.role)) || !roles)
        ),
        signIn: async ({ login, password }, callback) => {
            let token;
            let role;
            let routesAllowedToAccess = [];

            try {
                const result = await api.post('/user/login', { login, password });

                const { user } = result.data;
                console.log({ user });
                token = result.data.token;
                role = user.role;

                if (user.profiles?.length) for (const profile of user.profiles) {
                    if (profile.functionalities) for (const functionality of profile.functionalities) {
                        if (!routesAllowedToAccess.includes(functionality.router)) {
                            routesAllowedToAccess.push(functionality.router);
                        }
                    }
                }

                console.log({ routesAllowedToAccess });

                // setRoutesAllowedToAccess(urlsAllowed);
                insertPermissions(routesAllowedToAccess);

                storage.setMulti([
                    ['@Identifik:user', user],
                    ['@Identifik:token', token],
                    ['@Identifik:allowed_routes', routesAllowedToAccess],
                ]);

                callback({ user, token, role }, null);
            }
            catch (err) {
                callback(null, err);
            }

            dispatch({ type: 'SIGN_IN', token, role });
        },
        signOut: () => {
            try {
                storage.removeMulti([
                    '@Identifik:user',
                    '@Identifik:token',
                    '@Identifik:allowed_routes',
                ]);
            }
            catch (err) {
                console.log('Logout fail: ', err);
            }

            dispatch({ type: 'SIGN_OUT' });
        },
    }), [state, insertPermissions]);

    useEffect(() => {
        const verifyAuthentication = () => {
            let token;
            let role;

            try {
                const user = storage.getItem('@Identifik:user');

                token = storage.getItem('@Identifik:token');
                role = user.role;
            }
            catch (err) {
                console.log('Token verify fail: ', err);
            }

            dispatch({ type: 'VERIFY_AUTH', token, role });
        };

        verifyAuthentication();
    }, []);

    if (state.loading) return <span></span>;

    return (
        <AuthProvider auth={authContext}>
            <Router>
                <ShortcutsProvider>
                    <WebNotificationProvider>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={() => state.token ? <Redirect to="/home" /> : <Login />}
                            />
                            <Route
                                exact
                                path="/home"
                                render={() => state.token ? <Home /> : <Login />}
                            />

                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                path="/dashboard"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <Dashboard />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/pessoa"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <UserList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                path="/pessoa/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <UserCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                path="/pessoa/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <UserModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/camera/acessos"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CameraLogList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/colaborador/acessos"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <UserLogList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/catracas/acessos"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <GateAccess />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/localizar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <Location />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/dispositivo"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <DeviceList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/dispositivo/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <DeviceCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/dispositivo/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <DeviceModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/organizational"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <OrganizationalList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/organizational/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <OrganizationalCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/organizational/modify/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <OrganizationalModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/organizational/view/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <OrganizationalView />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/credenciais"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CredentialList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/credenciais/detales/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CredentialView />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/credenciais/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CredentialCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/credenciais/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CredentialModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/funcionalidades"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <FunctionalityList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/funcionalidades/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <FunctionalityCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/funcionalidades/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <FunctionalityModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/perfil_de_acesso"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <ProfileList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/camera/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CameraView />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/local"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <PlaceList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/local/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <PlaceCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/local/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <PlaceModify />
                            </PrivateRoute>
                            {/* Realizar o ajuste manual e adicionar as rotas para o tipo de pessoa */}
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/tipo_de_pessoa"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <PersonTypeList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/tipo_de_veiculo"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <VehicleTypeList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/tipo_de_pessoa/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <PersonTypeCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/tipo_de_veiculo/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <VehicleTypeCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/tipo_de_pessoa/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <PersonTypeModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/tipo_de_veiculo/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <VehicleTypeModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/veiculos"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <VehicleList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/veiculo/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <VehicleCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/veiculo/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <VehicleModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/relatorio_unificado"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <UnifiedReport />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/relatorio_veiculos"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <VehicleReport />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/mapa/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <MapCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/mapa"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <MapList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/custom_input/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CustomInputCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/custom_input"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CustomInputList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/custom_input/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CustomInputModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/notificação"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <NotificationList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/notificação/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <NotificationCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/notificação/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <NotificationModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/ajuda"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <HelpIndex />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/ajuda/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <HelpCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/ajuda/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <HelpModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/ajuda/visualizar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <HelpView />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/visitante/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <GuestCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/visitante/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <GuestModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/procurado/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <WantedCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/procurado/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <WantedModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/exportar_pessoas"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <ExportPeopleToDeviceList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/exportar_pessoas/exportar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <ExportPeopleToDevice />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/exportar_pessoas/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <ExportPeopleToDeviceView />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/controle_remoto"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <DeviceRemoteControl />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/atalhos"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <ShortcutList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/grupos_de_dispositivos"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <DeviceGroupList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/grupos_de_dispositivos/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <DeviceGroupCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/grupos_de_dispositivos/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <DeviceGroupModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/paineis"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <SecurityPanelList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/paineis/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <SecurityPanelCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/paineis/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <SecurityPanelModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/paineis/visualizar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <SecurityPanelView />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/notificações_de_ausência"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <AbsenceNotificationList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/notificações_de_ausência/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <AbsenceNotificationCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/notificações_de_ausência/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <AbsenceNotificationModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/casos"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CaseList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/caso/criar"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CaseCreate />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/caso/modificar/:id"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CaseModify />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/caso/tipos"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CaseTypeList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/evidencias"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <CaseFileList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/rotas_de_api"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <ApiEndpointList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/listar_cameras"
                                authorizedRoles={['admin']}
                            >
                                <CamerasList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/operações"
                                authorizedRoles={['admin']}
                            >
                                <OperationLogList />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/timeline_recorder"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <TimelineViewer />
                            </PrivateRoute>
                            <PrivateRoute
                                allowedRoutes={allowedRoutes}
                                exact
                                path="/webhooks"
                                authorizedRoles={['admin', 'employee']}
                            >
                                <WebhookList />
                            </PrivateRoute>
                        </Switch>
                    </WebNotificationProvider>
                </ShortcutsProvider>
            </Router>
        </AuthProvider>
    );
};

const PrivateRoute = ({ authorizedRoles, allowedRoutes = [], children, ...rest }) => (
    <AuthConsumer>
        {({ isAuthorized }) => (
            <Route
                {...rest}
                render={({ location }) => isAuthorized(authorizedRoles) && allowedRoutes.includes(rest.path) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )}
            />
        )}
    </AuthConsumer>
);
