import React from 'react';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

export default function AlertNotification({ handleView, handleClose, notification_web }) {
    return (
        <Alert
            variant="filled"
            elevation={6}
            action={
                <>
                    <Button onClick={handleView} color="inherit"> ver mais </Button>
                    <Button onClick={handleClose} color="inherit"><CloseOutlinedIcon /></Button>
                </>
            }
            severity="error"
            style={{
                position: "fixed",
                zIndex: 10000,
                left: "50px",
                bottom: "10px",
                alignItems: "center",
            }}
        >
            <div>
                <strong>
                    Atenção!
                </strong>
                <br />
                {
                    notification_web?.web_notification?.notification?.name?.substring(0, 40)
                }
                {
                    notification_web?.web_notification?.notification?.name?.length > 40 && "..."
                }
            </div>
        </Alert>

    );
}
