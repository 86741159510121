import {createContext} from 'react';

const Context = createContext();

export const AuthContext = Context;

export const AuthProvider = ({children, auth, ...props}) => (
    <Context.Provider value={auth}>
        {(typeof children === 'function')
            ? children(props)
            : children
        }
    </Context.Provider>
);

export const AuthConsumer = ({children}) => (
    <Context.Consumer>
        {children}
    </Context.Consumer>
);
