import { useState, useEffect } from "react";

import api from "../services/api";

const usePersonTypes = (props = {}) => {
    let {
        search = null,
        limit = 10,
        page = 0,
        show_exceptions = false,
    } = props;

    const [personTypes, setPersonTypes] = useState([]);
    const [total, setTotal] = useState();

    useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/person_type/list', {
                    params: {
                        search,
                        show_exceptions,
                        limit,
                        page,
                    }
                });

                const { personTypes, count } = result.data;

                setPersonTypes(personTypes);
                setTotal(count);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, [search, limit, page, show_exceptions]);

    return { personTypes, total };
};

export default usePersonTypes;
