import React, { useState, useEffect, forwardRef, useImperativeHandle, useReducer } from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Checkbox,
    Button,
    Typography,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { grey } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';

import Input from './Input';
import Popup from './Popup';
import Select from './Select';

import api from '../services/api';

const reduceDevices = (state, action) => {
    let newValues = [];
    switch (action.type) {
        case 'single':
            if (action.checked && !state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                newValues.push(action.id);
            } else if (!action.checked && state[action.deviceArray].includes(action.id)) {
                newValues = state[action.deviceArray];
                let index = state[action.deviceArray].indexOf(action.id);
                newValues.splice(index, 1);
            } else {
                newValues = state[action.deviceArray];
            }
            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'all':
            if (action.checked) {
                newValues = state[action.deviceArray];
                for (let current of action.allDevices) {
                    if (!newValues.includes(current.id)) {
                        newValues.push(current.id);
                    }
                }
            } else {
                const allIds = action.allDevices.map(el => el.id)
                newValues = state[action.deviceArray].filter(el => !allIds.includes(el));
            }

            return {
                ...state,
                [action.deviceArray]: newValues,
            }
        case 'load':
            console.log("loading")
            newValues = action.loading;
            console.log({ loading: newValues, array: action.deviceArray })
            return {
                ...state,
                [action.deviceArray]: newValues,
            };
        case 'reset':
            return { device_id: [], camera_id: [] };
        default: return state;
    }
};

const AccessPicker = forwardRef(({ xs = 12, md = 4, children }, ref) => {
    const classes = useStyles();

    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [groupsSearch, setGroupsSearch] = useState('');
    const [groupsPage, setGroupsPage] = useState(0);
    const [groupsLimit, setGroupsLimit] = useState(10);
    const [groupsTotal, setGroupsTotal] = useState(0);
    const [openGroupsPopup, setOpenGroupsPopup] = useState(false);

    const [devices, setDevices] = useState([]);
    const [devicesSearch, setDevicesSearch] = useState('');
    const [devicesPage, setDevicesPage] = useState(0);
    const [devicesLimit, setDevicesLimit] = useState(10);
    const [devicesTotal, setDevicesTotal] = useState(0);
    const [openDevicesPopup, setOpenDevicesPopup] = useState(false);

    const [cameras, setCameras] = useState([]);
    const [camerasSearch, setCamerasSearch] = useState('');
    const [camerasPage, setCamerasPage] = useState(0);
    const [camerasLimit, setCamerasLimit] = useState(10);
    const [camerasTotal, setCamerasTotal] = useState(0);
    const [openCamerasPopup, setOpenCamerasPopup] = useState(false);

    const [dhiType, setDhiType] = useState("all");

    const [openDevicesInfoModal, setOpenDevicesInfoModal] = useState(false);
    const [openCamerasInfoModal, setOpenCamerasInfoModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});

    const handleOpenInfoModal = (type, group) => {
        setSelectedGroup(group);

        switch (type) {
            case 'devices':
                setOpenDevicesInfoModal(true);
                break;
            case 'cameras':
                setOpenCamerasInfoModal(true);
                break;
            default: console.log('Invalid type');
        }
    }

    const handleCloseDevicesInfo = () => {
        setOpenDevicesInfoModal(false);
        setSelectedGroup({});
    };
    const handleCloseCamerasInfo = () => {
        setOpenCamerasInfoModal(false);
        setSelectedGroup({});
    }

    const [deviceIds, dispatchDeviceIds] = useReducer(reduceDevices, { device_id: [], camera_id: [] });

    useImperativeHandle(ref, () => ({
        resetValues() {
            dispatchDeviceIds({ type: 'reset' });

            setSelectedGroups([]);
            setGroupsSearch('');
            setGroupsPage(0);
            setGroupsLimit(10);

            setDevicesSearch('');
            setDevicesPage(0);
            setDevicesLimit(10);

            setCamerasSearch('');
            setCamerasPage(0);
            setCamerasLimit(10);
        },
        getValues() {
            return {
                groups: selectedGroups,
                device_id: deviceIds.device_id,
                camera_id: deviceIds.camera_id,
            };
        },
        load({ loadingGroups, loadingDevices, loadingCameras }) {
            dispatchDeviceIds({ type: 'load', deviceArray: 'device_id', loading: loadingDevices });
            dispatchDeviceIds({ type: 'load', deviceArray: 'camera_id', loading: loadingCameras });
            setSelectedGroups(loadingGroups);
        }
    }));

    useEffect(() => {
        const findGroups = async () => {
            const result = await api.get('/device_group/list', {
                params: {
                    search: groupsSearch,
                    page: groupsPage,
                    limit: groupsLimit,
                }
            });

            const { DeviceGroups, count } = result.data;

            setGroups(DeviceGroups);
            setGroupsTotal(count);

            // console.log({ DeviceGroups });
        };

        findGroups();
    }, [groupsLimit, groupsPage, groupsSearch]);

    useEffect(() => {
        const findDevices = async () => {
            const result = await api.get('/device_group/get_unrelated_devices', {
                params: {
                    search: devicesSearch,
                    page: devicesPage,
                    limit: devicesLimit,
                    groups: selectedGroups,
                    type: dhiType === "all" ? "" : dhiType,
                }
            });

            const { devices, count } = result.data;

            setDevices(devices);
            setDevicesTotal(count);

            // console.log({ filteredDevices: devices });
        };

        findDevices();
    }, [devicesLimit, devicesPage, devicesSearch, selectedGroups, dhiType]);


    useEffect(() => {
        const findCameras = async () => {
            const result = await api.get('/device_group/get_unrelated_cameras', {
                params: {
                    search: camerasSearch,
                    page: camerasPage,
                    limit: camerasLimit,
                    groups: selectedGroups,
                }
            });

            const { cameras, count } = result.data;

            setCameras(cameras);
            setCamerasTotal(count);

            // console.log({ filteredCameras: cameras });
        };

        findCameras();
    }, [camerasLimit, camerasPage, camerasSearch, selectedGroups]);

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'device':
                setDevicesPage(newPage);
                break;
            case 'camera':
                setCamerasPage(newPage);
                break;
            case 'groups':
                setGroupsPage(newPage);
                break
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        switch (quantityOf) {
            case 'device':
                setDevicesLimit(parseInt(target.value, 10));
                setDevicesPage(0);
                break;
            case 'camera':
                setCamerasLimit(parseInt(target.value, 10));
                setCamerasPage(0);
                break;
            case 'group':
                setGroupsLimit(parseInt(target.value, 10));
                setGroupsPage(0);
                break;
            default:
                console.log('Quantidade inválida');
        }
    };

    const checkSelection = (arr, fetchedArray) => {
        let checked = false;
        if (arr === 'group') {
            let groupIds = groups.map(el => el.id);
            checked = groupIds.every(el => {
                return selectedGroups.includes(el)
            });
        } else {
            if (!deviceIds[arr].length) return checked;

            let checkingArray = fetchedArray.map(el => el.id);
            checked = checkingArray.every(el => {
                return deviceIds[arr].includes(el)
            });
        }

        return checked;
    }

    const removeInGroupIds = async (id) => {
        console.log('triggering for', id)
        for (const group of groups) {
            if (group.id === id) {
                console.log('found', id)
                const camIds = group.cameras?.map(cam => cam.id);
                const dhis = group.devices?.filter(dev => dev.type === 'dhi');
                const devIds = dhis?.map(dev => dev.id);
                console.log({ camIds, devIds })
                const filteredDevices = deviceIds.device_id?.filter(element => !devIds.includes(element));
                const filteredCameras = deviceIds.camera_id?.filter(element => !camIds.includes(element));
                dispatchDeviceIds({ type: 'load', deviceArray: 'device_id', loading: filteredDevices });
                dispatchDeviceIds({ type: 'load', deviceArray: 'camera_id', loading: filteredCameras });
                break;
            }
        }
    }

    const removeAllInGroupIds = (removingIds) => {
        const camIds = [];
        const devIds = [];
        console.log({ removingIds })
        for (const id of removingIds) {
            console.log({ id });
            for (const group of groups) {
                console.log({ group })
                if (group.id === id) {
                    console.log('found', id);

                    for (const cam of group.cameras) {
                        if (!camIds.includes(cam.id)) {
                            camIds.push(cam.id);
                        }
                    }

                    const dhis = group.devices?.filter(dev => dev.type === 'dhi');
                    for (const dev of dhis) {
                        if (!devIds.includes(dev.id)) {
                            devIds.push(dev.id);
                        }
                    }
                    break;
                }
            }
        }

        console.log({ camIds, devIds })
        const filteredDevices = deviceIds.device_id?.filter(element => !devIds.includes(element));
        const filteredCameras = deviceIds.camera_id?.filter(element => !camIds.includes(element));
        dispatchDeviceIds({ type: 'load', deviceArray: 'device_id', loading: filteredDevices });
        dispatchDeviceIds({ type: 'load', deviceArray: 'camera_id', loading: filteredCameras });
    }

    const checkOneGroup = ({ id, checked }) => {
        const currentSelected = Object.assign([], selectedGroups);

        if (checked) {
            if (!currentSelected.includes(id)) {
                currentSelected.push(id);
            }

            setSelectedGroups(currentSelected);
            removeInGroupIds(id);
        } else {
            if (currentSelected.includes(id)) {
                const removingIndex = currentSelected.indexOf(id);
                currentSelected.splice(removingIndex, 1);
            }

            setSelectedGroups(currentSelected);
        }
    }

    const checkAllGroups = async (checked) => {
        const checkingIds = groups.map(group => group.id);
        const toRemoveIds = [];
        if (checked) {
            const currentSelected = Object.assign([], selectedGroups);
            for (const id of checkingIds) {
                if (!currentSelected.includes(id)) {
                    currentSelected.push(id);
                    toRemoveIds.push(id);
                }
            }
            setSelectedGroups(currentSelected);
            removeAllInGroupIds(toRemoveIds);
        } else {
            const currentSelected = selectedGroups.filter(id => !checkingIds.includes(id));
            setSelectedGroups(currentSelected);
        }
    }

    return (
        <>
            <Grid container justify="space-around" spacing={3}>
                <Grid item xs={xs} md={md}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        onClick={() => setOpenGroupsPopup(true)}
                    >
                        Grupos de Dispositivos {`(${selectedGroups.length})`}
                    </Button>
                </Grid>
                <Grid item xs={xs} md={md}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        onClick={() => setOpenDevicesPopup(true)}
                    >
                        Dispositivos {`(${deviceIds.device_id.length})`}
                    </Button>
                </Grid>
                <Grid item xs={xs} md={md}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        onClick={() => setOpenCamerasPopup(true)}
                    >
                        Câmeras {`(${deviceIds.camera_id.length})`}
                    </Button>
                </Grid>
                {
                    children
                }
            </Grid>
            <Popup
                openPopup={openGroupsPopup}
                setOpenPopup={setOpenGroupsPopup}
                title="Grupos de Dispositivos"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setGroupsSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="group list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Descrição</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivos</TableCell>
                                        <TableCell className={classes.tableHead}>Câmeras</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllGroups"
                                                color="primary"
                                                checked={checkSelection('group', groups)}
                                                onChange={({ target: { checked } }) => checkAllGroups(checked)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!groups && groups.map(group => (
                                        <TableRow key={group.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {group.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {group.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {group.description ? group.description : '-'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    group.devices?.length
                                                        ? (
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => handleOpenInfoModal("devices", group)}
                                                            >
                                                                {group.devices.length}
                                                            </Button>
                                                        )
                                                        : (
                                                            <Button
                                                                variant="contained"
                                                            >
                                                                0
                                                            </Button>
                                                        )
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    group.cameras?.length
                                                        ? (
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => handleOpenInfoModal("cameras", group)}
                                                            >
                                                                {group.cameras.length}
                                                            </Button>
                                                        )
                                                        : (
                                                            <Button
                                                                variant="contained"
                                                            >
                                                                0
                                                            </Button>
                                                        )
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addGroup"
                                                    color="primary"
                                                    checked={selectedGroups.includes(group.id)}
                                                    onChange={({ target: { checked } }) => checkOneGroup({
                                                        id: group.id,
                                                        checked: checked,
                                                    })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={groupsTotal}
                            rowsPerPage={groupsLimit}
                            page={groupsPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'group')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'group')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openDevicesPopup}
                setOpenPopup={setOpenDevicesPopup}
                title="Dispositivos"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Buscar"
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                fullWidth: true,
                                onChange: ({ target }) => setDevicesSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            label="Tipo de Leitor Facial"
                            options={[
                                { label: "Todos", value: "all" },
                                { label: "Entrada", value: "entry" },
                                { label: "Saída", value: "exit" },
                                { label: "Entrada-Saída", value: "hybrid" },
                            ]}
                            textFieldProps={{
                                value: dhiType,
                                fullWidth: true,
                                onChange: ({ target: { value } }) => setDhiType(value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="devices list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Descrição</TableCell>
                                        <TableCell className={classes.tableHead}>Local</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllDevices"
                                                color="primary"
                                                // checked={selectedDevices.length === devices.length}
                                                checked={checkSelection('device_id', devices)}
                                                onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                    type: 'all',
                                                    deviceArray: 'device_id',
                                                    allDevices: devices,
                                                    checked,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!devices && devices.map(device => (
                                        <TableRow key={device.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {device.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.description}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {device.place_devices?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    device.dhi_type === "entry" ? "Entrada" :
                                                        device.dhi_type === "exit" ? "Saída" :
                                                            device.dhi_type === "hybrid" ? "Entrada-Saída" :
                                                                "-"
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addDevice"
                                                    color="primary"
                                                    checked={deviceIds.device_id.includes(device.id)}
                                                    onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                        type: 'single',
                                                        id: device.id,
                                                        deviceArray: 'device_id',
                                                        checked,
                                                    })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={devicesTotal}
                            rowsPerPage={devicesLimit}
                            page={devicesPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'device')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'device')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openCamerasPopup}
                setOpenPopup={setOpenCamerasPopup}
                title="Câmeras"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setCamerasSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="camera list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Local</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivo</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllCameras"
                                                color="primary"
                                                // checked={selectedCameras.length === cameras.length}
                                                checked={checkSelection('camera_id', cameras)}
                                                onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                    type: 'all',
                                                    deviceArray: 'camera_id',
                                                    allDevices: cameras,
                                                    checked,
                                                })}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!cameras && cameras.map(camera => (
                                        <TableRow key={camera.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {camera.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    (camera.type === 'entry') ? 'Entrada' :
                                                        (camera.type === 'internal') ? 'Interna' :
                                                            (camera.type === 'exit') ? 'Saída' :
                                                                'Desconhecido'
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.place_cameras?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {camera.device?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addCamera"
                                                    color="primary"
                                                    checked={deviceIds.camera_id.includes(camera.id)}
                                                    onChange={({ target: { checked } }) => dispatchDeviceIds({
                                                        type: 'single',
                                                        id: camera.id,
                                                        deviceArray: 'camera_id',
                                                        checked,
                                                    })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={camerasTotal}
                            rowsPerPage={camerasLimit}
                            page={camerasPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'camera')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'camera')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openDevicesInfoModal}
                setOpenPopup={setOpenDevicesInfoModal}
                title="Dispositivos"
                onClose={handleCloseDevicesInfo}
                width="45%"
            >
                <Grid container>
                    {
                        selectedGroup?.devices?.map(device => (
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        fontWeight: 700,
                                        color: "#696969"
                                    }}
                                >
                                    &bull; {device.name} - {
                                        device.dhi_type === "entry" ? "Entrada" :
                                            device.dhi_type === "exit" ? "Saída" :
                                                device.dhi_type === "hybrid" ? "Entrada-Saída" :
                                                    "-"
                                    }
                                </Typography>
                            </Grid>
                        ))
                    }
                </Grid>
            </Popup>
            <Popup
                openPopup={openCamerasInfoModal}
                setOpenPopup={setOpenDevicesInfoModal}
                title="Câmeras"
                onClose={handleCloseCamerasInfo}
                width="45%"
            >
                <Grid container>
                    {
                        selectedGroup?.cameras?.map(camera => (
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        fontWeight: 700,
                                        color: "#696969"
                                    }}
                                >
                                    &bull; {camera.name} ({camera.device?.name})
                                </Typography>
                            </Grid>
                        ))
                    }
                </Grid>
            </Popup>
        </>
    );
});

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    tableRow: {
        transition: '0.2s',
        '&:hover': {
            backgroundColor: grey[300],
        }
    },
}));

export default AccessPicker;