import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Typography,
    Button
} from '@material-ui/core';

export default function Warning(props) {
    const { title, children, openPopup, setOpenPopup } = props;
    const classes = useStyles();

    return (
        <Dialog open={openPopup} maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1%'}}>
                {children}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenPopup(false)}>
                    Entendi
                </Button>
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: '40%',
        border: '2px solid #DB003A',
        borderRadius: '10px'
    },
    dialogTitle: {
        paddingRight: '0px',
    },
}))
