import React from 'react';
import {
    Typography,
    Grid,
    Button
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import Popup from './Popup';


export default function DeletePopUp({ isDelete, title, deleteFunction, open, setOpen }) {

    return (
        <Popup
            openPopup={open}
            setOpenPopup={setOpen}
        >
            <Grid container xs={12} style={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 3% 2% 3%',
            }}>
                <Typography variant='h5'
                    style={{
                        fontWeight: 'bold',
                        color: '#9e9e9e'
                    }}

                >
                    Deseja mesmo deletar esse {title}?
                </Typography>
                <Grid item style={{
                    marginTop: '50px'
                }}>
                    <Button
                        disabled={isDelete}
                        variant="contained"
                        onClick={deleteFunction}
                        color="secondary"
                        endIcon={<DeleteIcon />}
                        id="confirm"
                    >
                        {isDelete ? 'Deletando...' : 'Sim'}
                    </Button>
                </Grid>

            </Grid>
        </Popup>
    );
}
