import React, {useState, useContext} from 'react';
import {AppBar, Toolbar, Avatar, IconButton, Link, Popover} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {makeStyles} from '@material-ui/core/styles';
import {blue, grey} from '@material-ui/core/colors';

import {AuthContext} from '../providers/Auth';

import storage from '../utils/storage';
import logo from '../assets/img/logo-horizontal.png';

export default function TopMenu() {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const auth = useContext(AuthContext);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const signOut = () => auth.signOut();

    const firstLetterName = () => {
        const {name} = storage.getItem('@Identifik:user');

        return name.charAt(0);
    };

    return (
        <AppBar position="fixed" color="inherit" className={classes.appBar}>
            <Toolbar>
                <div className={classes.logoContainer}>
                    <img src={logo} alt="logo" className={classes.logo} />
                </div>
                <div>
                    <IconButton
                        edge="end"
                        aria-label="user options"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleMenu}
                    >
                        <Avatar className={classes.avatar}>
                            {firstLetterName()}
                        </Avatar>
                    </IconButton>
                    <Popover
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={open}
                        onClose={handleClose}
                        keepMounted
                    >
                        <Link onClick={signOut} className={classes.popoverItem}>
                            Sair <ExitToAppIcon />
                        </Link>
                    </Popover>
                </div>
            </Toolbar>
        </AppBar>
    );
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        //zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#100c2f',
    },
    logoContainer: {
        flexGrow: 1,
    },
    logo: {
        width: 180,
        verticalAlign: 'middle',
    },
    avatar: {
        color: theme.palette.getContrastText(blue[700]),
        backgroundColor: blue[700],
    },
    popoverItem: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontWeight: 500,
        color: grey[700],
        padding: theme.spacing(1, 2),
        '& > *': {
            marginLeft: theme.spacing(1),
        },
        '&:hover': {
            backgroundColor: grey[100],
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
}));
