import React, { useState } from 'react';

import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import Alert from '../../components/Alert';
import SideMenu from '../../components/SideMenu';
import InputSearch from '../../components/InputSearch';

import api from '../../services/api';
import date from '../../utils/date';

export default function CredentialList() {
    const classes = useStyles();

    const [credentials, setCredentials] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    // const [refresh, setRefresh] = useState(true);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    function updateRequest({ search }) {

        const loadData = async () => {
            try {
                const result = await api.get('/credential/list', {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });

                const { count, credentials } = result.data;

                setCredentials(credentials);
                setTotal(count);
                // setRefresh(false);
            } catch (err) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error || 'Não foi possivel carregar as credenciais',
                });
            }
        };

        loadData();

    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };
    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Credencias
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={updateRequest}
                            pagination={[page, limit]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Número</TableCell>
                                        <TableCell className={classes.tableHead}>Status</TableCell>
                                        <TableCell className={classes.tableHead}>Inicio da Validade</TableCell>
                                        <TableCell className={classes.tableHead}>Fim da Validade</TableCell>
                                        <TableCell className={classes.tableHead}>Usuário Vinculado</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!credentials && credentials.map(credential => (
                                        <TableRow key={credential.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {credential.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {credential.number}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {credential.active ? "ATIVADA" : "DESATIVADA"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    credential.validity_begin
                                                        ? date(credential.validity_begin).toLocaleFormat()
                                                        : "-"
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    credential.validity_end
                                                        ? date(credential.validity_end).toLocaleFormat()
                                                        : "-"
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {credential.user.name ? credential.user.name : "-"}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    )

}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
}));
