import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Toolbar, Paper, Button, Grid, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Popup from '../../components/Popup';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';

export default function PersonTypeModify() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const { id } = useParams();
    const history = useHistory();

    const [refresh, setRefresh] = useState(true)
    const [personTypeValue, setPersonTypeValue] = useState({
        name: '',
    });
    useEffect(() => {
        const loadPersonType = async () => {
            try {
                const result = await api.get(`/person_type/detail/${id}`);

                const { personType } = result.data;

                setPersonTypeValue(personType);
            } catch (err) {
                setMessage({
                    open: true,
                    variant: "error",
                    text: err.error,
                });
            }
        };
        loadPersonType();
    }, [id, history, refresh]);

    const savePersonType = async (values, formik) => {
        try {

            const personTypeData = {
                ...values,
            };
            const result = await api.post(`/person_type/modify/${id}`, personTypeData);
            const { personType } = result.data;

            formik.setSubmitting(false);

            setRefresh(!refresh)
            setMessage({
                open: true,
                variant: 'success',
                text: `Tipo de pessoa "${personType.name}" modificado com sucesso!`,
            });
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const deletePersonType = async () => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/person_type/delete/${id}`);

            const { deletedPersonType } = result.data;

            setIsDeleting(false);

            if (deletedPersonType) {
                setOpenDeletePopup(false);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Tipo de pessoa "${deletedPersonType.name}"" deletado com sucesso!`,
                });
                history.goBack()
            }
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Criar Novo Tipo de Pessoa
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={personTypeValue}
                        enableReinitialize={true}
                        onSubmit={savePersonType}
                    >
                        {({ values, handleChange, handleSubmit, isSubmitting }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="person-type-modify" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Grid container xs={12} style={{
                                                            boxSizing: 'borde-box',
                                                            display: 'flex',
                                                            justifyContent: 'space-evenly',
                                                            alignItems: 'center',
                                                            flexDirection: 'column'
                                                        }}>
                                                            <Grid item
                                                                style={{
                                                                    marginBottom: '0.5%',
                                                                    width: '50%'
                                                                }}
                                                            >
                                                                <Input
                                                                    label="Nome"
                                                                    textFieldProps={{
                                                                        name: 'name',
                                                                        placeholder: 'Nome do tipo de pessoa',
                                                                        onChange: handleChange,
                                                                        value: values.name,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid container xs={8} style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-evenly',
                                                                alignItems: 'center',
                                                            }}>
                                                                <PermissionWrapper route="/tipo_de_pessoa/deletar/:id">
                                                                    <Grid item style={{
                                                                        margin: '0.5%'
                                                                    }}>
                                                                        <Button
                                                                            type="button"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            endIcon={<DeleteIcon />}
                                                                            onClick={() => setOpenDeletePopup(true)}
                                                                            id={`delete`}
                                                                        >
                                                                            Apagar tipo
                                                                        </Button>
                                                                    </Grid>
                                                                </PermissionWrapper>
                                                                <Grid item style={{
                                                                    margin: '0.5%'
                                                                }}>
                                                                    <Button
                                                                        disabled={isSubmitting}
                                                                        type="submit"
                                                                        variant="contained"
                                                                        form="person-type-modify"
                                                                        color="primary"
                                                                        endIcon={<SaveIcon />}
                                                                        id={`salvar`}
                                                                    >
                                                                        {isSubmitting ? 'Salvando...' : 'Salvar tipo'}
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                <Popup
                    openPopup={openDeletePopup}
                    setOpenPopup={setOpenDeletePopup}

                >
                    <Grid container xs={12} style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 3% 2% 3%',

                    }}>
                        <Typography variant='h5'
                            style={{
                                fontWeight: 'bold',
                                color: '#9e9e9e'
                            }}
                        >
                            Deseja mesmo deletar "{personTypeValue.name}"?
                        </Typography>
                        <Grid item style={{
                            marginTop: '50px'
                        }}>
                            <Button
                                disabled={isDeleting}
                                variant="contained"
                                onClick={() => deletePersonType()}
                                color="secondary"
                                endIcon={<DeleteIcon />}
                            >
                                {isDeleting ? 'Deletando...' : 'Sim'}
                            </Button>
                        </Grid>

                    </Grid>
                </Popup>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
}));

