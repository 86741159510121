import React, { useState, useReducer, useImperativeHandle, forwardRef } from 'react';
import {
    Grid,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Delete';
import { grey } from '@material-ui/core/colors';

import CredentialCard from './CredentialCard';
import DateTimePicker from './DateTimePicker';
import Popup from './Popup';
import Select from './Select';

const reduceCredentials = (state, action) => {
    const currentCredentials = Object.assign([], state);

    switch (action.type) {
        case 'insert':
            const currentCredentialsNumbers = state?.map(credential => credential.number);

            if (action.credentialNumber && !currentCredentialsNumbers.includes(action.credentialNumber)) {
                currentCredentials.push({
                    number: action.credentialNumber,
                    active: true,
                    validity_begin: null,
                    validity_end: null,
                });

                return currentCredentials;
            } else if (currentCredentialsNumbers.includes(action.credentialNumber)) {
                action.setMessage({
                    open: true,
                    variant: 'error',
                    text: 'Credencial ja cadastrada',
                });
            }
            return state;
        case 'remove':
            return state.filter(credential => credential.number !== action.credentialNumber);
        case 'change_validity':
            // console.log(action)
            if (action.index === null) return state;

            currentCredentials[action.index][action.validity_prop] = action.date ? action.date.format() : null;

            if (
                currentCredentials[action.index].validity_begin &&
                currentCredentials[action.index].validity_end &&
                new Date(currentCredentials[action.index].validity_begin) > new Date(currentCredentials[action.index].validity_end)
            ) {
                // console.log('INVALID DATE')
                // console.log(new Date(currentCredentials[action.index].validity_begin))
                // console.log(new Date(currentCredentials[action.index].validity_end))
                action.setMessage({
                    open: true,
                    variant: 'error',
                    text: 'Periodo de tempo inválido',
                });

                return state;
            }

            // console.log({ currentCredentials })

            return currentCredentials;
        case 'change_active':
            if (action.index === null) return state;
            else currentCredentials[action.index]['active'] = action.active;

            return currentCredentials;
        case 'load':
            return action.loading?.length ? action.loading : [];
        case 'reset':
            return [];
        default: return state;
    }
}

const CredentialsManager = forwardRef(({
    setMessage,
    userName,
}, ref) => {

    const classes = useStyles();

    const [openCredentialsManager, setOpenCredentialsManager] = useState(false);
    const [openCredentialCard, setOpenCredentialCard] = useState(false);

    const [credentials, dispatchCredentials] = useReducer(reduceCredentials, []);

    const handleOpenCredentialsManager = () => {
        setOpenCredentialsManager(!openCredentialsManager);
    };

    const handleOpenCredentialCard = () => {
        setOpenCredentialCard(!openCredentialCard);
    };

    const handleCloseCredentialCard = (credential) => {
        dispatchCredentials({
            type: 'insert',
            credentialNumber: credential,
            setMessage: setMessage,
        });
    };

    useImperativeHandle(ref, () => ({
        resetValues() {
            dispatchCredentials({ type: 'reset' });
        },
        getValues() {
            return credentials;
        },
        load(loadingCredentials) {
            dispatchCredentials({
                type: 'load',
                loading: loadingCredentials,
            });
        },
    }));

    return (
        <>
            <Button
                variant='contained'
                onClick={handleOpenCredentialsManager}
                fullWidth={true}
            >
                Gerenciar Credenciais
            </Button>
            <Popup
                title='Gerenciar Credenciais'
                openPopup={openCredentialsManager}
                setOpenPopup={setOpenCredentialsManager}
            >
                <Grid container>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="access history">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Númeração</TableCell>
                                    <TableCell className={classes.tableHead}>Ativa</TableCell>
                                    <TableCell className={classes.tableHead}>Inicio da Validade</TableCell>
                                    <TableCell className={classes.tableHead}>Fim da Validade</TableCell>
                                    <TableCell className={classes.tableHead}>Remover</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!credentials && credentials.map((credential, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.tableBody} component="th" scope="row">
                                            {credential.number}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            <Select
                                                options={[
                                                    { label: "SIM", value: true },
                                                    { label: "NÃO", value: false },
                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target: { value } }) => dispatchCredentials({
                                                        type: 'change_active',
                                                        index,
                                                        active: value,
                                                    }),
                                                    value: credential.active,
                                                    fullWidth: true,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            <DateTimePicker
                                                format="DD/MM/YYYY HH:mm"
                                                value={credential.validity_begin}
                                                placeholder="Inicio"
                                                onChange={date => dispatchCredentials({
                                                    type: 'change_validity',
                                                    validity_prop: 'validity_begin',
                                                    date,
                                                    index,
                                                    setMessage,
                                                })}
                                                cancelLabel="cancelar"
                                                disabled={false}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            <DateTimePicker
                                                format="DD/MM/YYYY HH:mm"
                                                value={credential.validity_end}
                                                placeholder="Fim"
                                                onChange={date => dispatchCredentials({
                                                    type: 'change_validity',
                                                    validity_prop: 'validity_end',
                                                    date,
                                                    index,
                                                    setMessage,
                                                })}
                                                cancelLabel="cancelar"
                                                disabled={false}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                onClick={() => dispatchCredentials({ type: 'remove', credentialNumber: credential.number })}
                                            >
                                                <RemoveIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {
                            credentials.length < 10 && (
                                <Grid item style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 6
                                }}>
                                    <Button
                                        variant='contained'
                                        onClick={handleOpenCredentialCard}
                                    >
                                        Adcionar nova credencial
                                    </Button>
                                </Grid>
                            )
                        }
                    </TableContainer>
                </Grid>
            </Popup>
            <CredentialCard
                openCard={openCredentialCard}
                setOpenCard={setOpenCredentialCard}
                userName={userName}
                onClose={handleCloseCredentialCard}
            />
        </>
    );
});

const useStyles = makeStyles(() => ({
    tableContainer: {
        maxHeight: '95%',
        width: '100%',
    },
    table: {
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
}));

export default CredentialsManager;