import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Bar, Scatter } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Toolbar, Grid, Typography, Avatar, Divider, Dialog, DialogTitle, DialogContent, Button,  Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CameraIcon from '@material-ui/icons/Camera';
import TimeIcon from '@material-ui/icons/Timeline';
import ExpandIcon from '@material-ui/icons/AspectRatio';
import FaceIcon from '@mui/icons-material/Face';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupIcon from '@material-ui/icons/Group';
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import IsoIcon from '@mui/icons-material/Iso';

import DevicesIcon from '@material-ui/icons/Devices';
import { indigo, grey } from '@material-ui/core/colors';

import date from '../../utils/date';
import api from '../../services/api';

import Select from '../../components/Select';
import DateTimePicker from '../../components/DateTimePicker';
import SideMenu from '../../components/SideMenu';
import BarChart from '../../components/BarChart';
import Doughunt from '../../components/DoughnutChart';

function getTime(time) {
    let currentISO = date(new Date()).toISOString();
    let currentDate = new Date(currentISO);

    switch (time) {
        case 'start':
            // currentDate.setHours(0, 0, 0, 0);
            currentDate.setDate(currentDate.getDate() - 15);
            return currentDate.toISOString();
        case 'end':
            return currentISO;
        default:
            return null
    };
};

function getColor(index) {
    let colors = [
        '#9D5C0D',
        '#89B5AF',
        '#BC8CF2',
        '#630000',
        '#2F86A6',
        '#105652',
        '#3F3351',
        '#CEE5D0',
        '#88E0EF',
        '#FF5151',
        '#49FF00',
        '#090910',
        '#F8DF8B',
        '#FF9292',
        '#9A9483',
        '#51050F',
        '#4B3869',
        '#345B63',
        '#4B6587',
        '#FACE7F',
        '#CC9B6D',
    ]
    return colors[index];
};
const reduceExpand = (state, action) => {
    switch (state[action.graphic]) {
        case true:
            setTimeout(() => action.ref.current.scrollIntoView({ behavior: 'smooth' }), 50);
            return {
                ...state,
                [action.graphic]: false,
            }
        case false:
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
            return {
                ...state,
                [action.graphic]: true,
            }
        default: return state;
    }
};

export default function Dashboard() {
    const [labelPlacePeople, setLabelPlacePeople] = useState(null);
    const [labelPlaceVehicle, setLabelPlaceVehicle] = useState(null);

    const handleChangePlacePeople = (event) => {
        setLabelPlacePeople(event.target.value);
    };

    const handleChangePlaceVehicle = (event) => {
        setLabelPlaceVehicle(event.target.value);
    };

    const classes = useStyles();

    const [expand, dispatchExpand] = useReducer(reduceExpand, {
        monitoringPeople: false,
        monitoringVehicle: false,
        timeMonitoring: false,
        timeMonitoringVehicle: false,
        peopleFrequency: false,
        vehicleFrequency: false,
        maskTemperature: false,
        gender: false,
        personType: false,
        vehicleType: false,
        accessType: false,
        averagePeoplePerHour: false,
        averageVehiclePerHour: false,
        faceImage: false,
        deviceCapacity: false,
    });

    const monitoringGraph = useRef(null);
    const monitoringVehicleGraph = useRef(null);
    const vehicleFrequencyGraph = useRef(null);
    const peopleFrequencyGraph = useRef(null);
    const maskTemperatureGraph = useRef(null);
    const deviceCapacityGraph = useRef(null);
    const genderGraph = useRef(null);
    const personTypeGraph = useRef(null);
    const vehicleTypeGraph = useRef(null);
    const accessTypeGraph = useRef(null);
    const vehicleAccessGraph = useRef(null);
    const faceImageGraph = useRef(null);
    const averagePeoplePerHourGraph = useRef(null);
    const averageVehiclePerHourGraph = useRef(null);

    const [openSettings, setOpenSettings] = useState(false);
    const [place, setPlace] = useState([])

    const [startTime, setStartTime] = useState(getTime('start'));
    const [endTime, setEndTime] = useState(getTime('end'));
    const [signedPeople, setSignedPeople] = useState({
        labels: ['Masculino', 'Feminino', 'Outro'],
        data: [0, 0, 0]
    });
    const [vehicleCount, setVehicleCountData] = useState('');
    const [accessControlData, setAccessControlData] = useState({
        labels: [
            'Autorizado',
            'Não autorizado'
        ],
        authorized: 0,
        unauthorized: 0,
        authorizedVehicle: 0,
        unauthorizedVehicle: 0,
    });
    const [extraAccessControlData, setExtraAccessControlData] = useState({
        labels: [
            'Identificados sem Mascara',
            'Identificados acima da temperatura',
            'Não identificados sem Mascara',
            'Não identificados acima da temperatura',
        ],
        identifiedUnmasked: 0,
        identifiedOverTemperature: 0,
        unidentifiedUnmasked: 0,
        unidentifiedOverTemperature: 0,
    });
    const [monitoringData, setMonitoringData] = useState({
        labels: [],
        data: []
    });
    const [monitoringVehicle, setMonitoringVehicle] = useState({
        labels: [],
        data: []
    });

    const [personTypeData, setPersonTypeData] = useState({ labels: [], data: [] });
    const [vehicleTypeData, setVehicleTypeData] = useState({ labels: [], data: [] });
    const [dateTimeAccessData, setDateTimeAccessData] = useState([]);
    const [dateTimeAccessVehicleData, setDateTimeAccessVehicleData] = useState([]);
    const [openFieldPopup, setOpenFieldPopup] = useState(false);

    const [timeMonitoringData, setTimeMonitoringData] = useState([{
        x: 0,
        y: 0
    }]);
    const [timeMonitoringVehicleData,setTimeMonitoringVehicleData] = useState([{x: 0, y:0}])

    const [faceImageDataGraph, setFaceImageDataGraph] = useState({
        labels: [
            'Cadastrada',
            'Não cadastrada'
        ],
        createdWithFaceImage: 0,
        createdWithoutFaceImage: 0
    })

    const handleDashboardFields = (prop) => {
        let copyDashboardFields = Object.assign({}, dashboardFields);
        copyDashboardFields[prop] = !copyDashboardFields[prop];
        setDashboardFields(copyDashboardFields);
    };

    const [dashboardFields, setDashboardFields] = useState({
        showPeopleFrequency: true,
        showPeopleType: true,
        showMonitoringPeople: true,
        showMonitoringVehicle: true,
        showAveragePeople: true,
        showAverageVehicle: true,
        showVehicleFrequency: true,
        showVehicleType: true,
        showGender: true,
        showMaskTemperature: true,
        showFacialImage: true,
        showAccessType: true,
        showVehicleAccess: true,
        showDeviceCapacity: true,
    });

    let chartRef = useRef();
    useEffect(() => {
        const loadStatistics = async () => {
            try {
                 const result = await api.get('/dashboard/statistics', {
                    params: {
                        start_time: startTime,
                        end_time: endTime,
                        place: labelPlacePeople,


                    }
                });

                const {
                    sexData,
                    vehicleCount,
                    monitoringData,
                    monitoringVehicleData,
                    accessControlData,
                    extraAccessControlData,
                    personTypeData,
                    vehicleTypeData,
                    dateData,
                    vehicleFrequency,
                    // logMonitoring,
                    // averageAccess
                    faceImageData,
                } = result.data;

                setSignedPeople(sexData);
                setVehicleCountData(vehicleCount)
                setAccessControlData(accessControlData);
                setExtraAccessControlData(extraAccessControlData);
                setMonitoringData(monitoringData);
                setMonitoringVehicle(monitoringVehicleData);
                setPersonTypeData(personTypeData);
                setVehicleTypeData(vehicleTypeData);
                setDateTimeAccessData(dateData);
                setDateTimeAccessVehicleData(vehicleFrequency);
                chartRef.current?.data.labels.splice(0, chartRef.current?.data.labels.length);

                const placeResult = await api.get('/place/list');
                let graohPlaces = [{ name: '|--- Selecione um valor ---|', id: null }];
                placeResult.data.places.forEach((el) => {
                    graohPlaces.push(el)
                })
                setPlace(graohPlaces)
                setFaceImageDataGraph(faceImageData);

                //console.log('resulting data: ', result.data);

            }
            catch (err) {
                console.log(err);
            }
        };

        loadStatistics();
        const load = setInterval(loadStatistics, 10000);

        return () => {
            clearInterval(load);
        };
        // eslint-disable-next-line
    }, [startTime, endTime]);

    useEffect(() => {
        const loadData = async () => {
            const response = await api.get('/dashboard/statistics', {
                params: {
                    start_time: startTime,
                    end_time: endTime,
                    place: labelPlacePeople
                }
            });
            const { averageAccess, vehicleAccess } = response.data;

            setTimeMonitoringData(averageAccess);
            setTimeMonitoringVehicleData(vehicleAccess);
        }
        loadData();

    }, [startTime, endTime, labelPlacePeople]);
    const setTime = (time) => {
        let currentISO = date(new Date()).toISOString();
        let currentDate = new Date(currentISO);
        let start = null;
        let end = currentISO;
        switch (time) {
            case '24h':
                currentDate.setHours(currentDate.getHours() - 24);
                start = currentDate.toISOString();
                break;
            case 'today':
                currentDate.setHours(0, 0, 0, 0);
                start = currentDate.toISOString();
                break;
            case '7d':
                currentDate.setDate(currentDate.getDate() - 7);
                start = currentDate.toISOString();
                break;
            default:
                end = null;
        }
        setStartTime(start);
        setEndTime(end);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3} className={classes.row}>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Grid xs={4} item>

                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h5" className={classes.logo}>
                                DADOS DE {date(startTime).toLocaleFormat()} ATÉ {date(endTime).toLocaleFormat()}
                            </Typography>
                        </Grid>
                        <Grid xs={4} item>

                        </Grid>
                        <Grid style={{ marginRight: '1%' }} item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenSettings(true)}
                            >
                                <SettingsIcon />
                            </Button>
                        </Grid>
                        <Grid item style={{ marginRight: '1%' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenFieldPopup(true)}
                            >
                                <IsoIcon/>
                            </Button>
                        </Grid>
                        <Dialog open={openSettings} classes={{ paper: classes.dialogWrapper }}>
                            <DialogTitle>
                                <Grid item xs={12} className={classes.dialogTitle}>
                                    <Grid item>
                                        <Typography variant='h6' className={classes.dialogTitleLabel}>
                                            Período de Tempo
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="secondary"
                                            onClick={() => setOpenSettings(false)}>
                                            <CloseIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container className={classes.dialogContent}>
                                    <Grid item className={classes.filterItem}>
                                        <Grid item xs={2}>
                                            <Typography variant="h6" className={classes.label}>
                                                Início:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <DateTimePicker
                                                format="DD/MM/YYYY HH:mm"
                                                value={startTime}
                                                onChange={value => setStartTime(value && value.format())}
                                                cancelLabel="cancelar"
                                                disabled={false}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.filterItem}>
                                        <Grid item xs={2}>
                                            <Typography variant="h6" className={classes.label}>
                                                Fim:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <DateTimePicker
                                                format="DD/MM/YYYY HH:mm"
                                                value={endTime}
                                                onChange={value => setEndTime(value && value.format())}
                                                cancelLabel="cancelar"
                                                disabled={false}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container align='space-between' justify='center'>
                                        <Grid item xs={10}>
                                            <Button
                                                variant='outlined'
                                                onClick={() => setTime('today')}
                                                fullWidth={true}
                                            >
                                                Hoje
                                            </Button>
                                        </Grid>
                                        <Grid item xs={10} style={{ margin: '10px' }}>
                                            <Button
                                                variant='outlined'
                                                onClick={() => setTime('24h')}
                                                fullWidth={true}
                                            >
                                                Últimas 24 horas
                                            </Button>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Button
                                                variant='outlined'
                                                onClick={() => setTime('7d')}
                                                fullWidth={true}
                                            >
                                                Últimos 7 dias
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>

                <Grid container justify='space-between'>

                    <Grid item xs={6} style={{ paddingRight: '15px' }}>
                        <Grid container>
                            {(dashboardFields.showMonitoringPeople)  && (<Grid item xs={12} ref={monitoringGraph} className={[(expand.monitoringPeople) && (classes.fullScreen), classes.card]}>
                                <div className={classes.bigCardHeader}>
                                    <Grid item className={classes.cardHeader}>
                                        <Avatar aria-label="recipe" className={classes.cardAvatar}>
                                            <CameraIcon style={{ fontSize: 30 }} />
                                        </Avatar>
                                        <Typography variant="h5" gutterBottom className={classes.cardLabel}>
                                            Monitoramento de Pessoas
                                        </Typography>
                                        <Divider orientation="vertical" flexItem style={{ paddingLeft: "2px", margin: "0 10px 0 10px" }} />
                                        <Typography variant="subtitle1" className={classes.subtitle}>
                                            TOTAL DE ACESSOS: {accessControlData.authorized + accessControlData.unauthorized}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => dispatchExpand({ graphic: 'monitoringPeople', ref: monitoringGraph })}
                                        >
                                            <ExpandIcon />
                                        </Button>
                                    </Grid>
                                </div>
                                <Divider />
                                <div className={classes.cardGraphic}>
                                    {/*<div className={classes.chartContainer}>*/}
                                    <BarChart data={{
                                        labels: monitoringData.labels,
                                        datasets: [
                                            {
                                                //label: extraAccessControlData.labels,
                                                data: monitoringData.data,
                                                backgroundColor: [
                                                    '#212121',
                                                    '#6D9886',
                                                    '#D9CAB3',
                                                    '#2F86A6'
                                                ],
                                                borderWidth: 2
                                            }
                                        ]
                                    }}
                                        options={{
                                            scales: {
                                                y: {
                                                    min: 0,
                                                    ticks: {
                                                        stepSize: 1,
                                                        color: '#191A19',
                                                        display: true,
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 16
                                                        }
                                                    },
                                                    title: {
                                                        color: '#191A19',
                                                        display: true,
                                                        text: 'Quantidade',
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 18
                                                        }
                                                    },
                                                },
                                                x: {
                                                    title: {
                                                        color: '#191A19',
                                                        display: true,
                                                        text: 'Tipo de Acesso',
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 18
                                                        }
                                                    },
                                                    ticks: {
                                                        color: '#191A19',
                                                        display: true,
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 16
                                                        }
                                                    }
                                                }
                                            },
                                            maintainAspectRatio: true,
                                            elements: {
                                                bar: {
                                                    borderWidth: 1,
                                                },
                                            },
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                            },
                                        }}
                                    />
                                    {/*</div>*/}
                                </div>
                                <Grid item xs={12} className={classes.linksContainer}>
                                    <Link
                                        className={classes.links}
                                        to={{
                                            pathname: '/relatorio_unificado',
                                            state: {
                                                routeStartTime: startTime,
                                                routeEndTime: endTime,
                                                routeCheckTime: true
                                            }
                                        }}
                                    >
                                        Ver detalhes
                                    </Link>
                                </Grid>
                            </Grid>)}
                            {(dashboardFields.showPeopleFrequency) &&( <Grid item xs={12} ref={peopleFrequencyGraph} style={{ marginTop: '10px' }} className={[classes.card, (expand.peopleFrequency) && (classes.fullScreen)]}>
                                <div className={classes.bigCardHeader}>
                                    <Grid item className={classes.cardHeader}>
                                        <Avatar aria-label="recipe" className={classes.cardAvatar}>
                                            <TimeIcon style={{ fontSize: 30 }} />
                                        </Avatar>
                                        <Typography variant="h5" gutterBottom className={classes.cardLabel}>
                                            Frequência de Acessos (Pessoas)
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => dispatchExpand({ graphic: 'peopleFrequency', ref: peopleFrequencyGraph })}
                                        >
                                            <ExpandIcon />
                                        </Button>
                                    </Grid>
                                </div>
                                <Divider />
                                <div className={classes.cardGraphic}>
                                    {/*<div className={classes.chartContainer}>*/}
                                    <Bar
                                        ref={chartRef}
                                        data={{
                                            datasets: dateTimeAccessData.map((element) => ({
                                                data: element.events,
                                                backgroundColor: getColor(8),
                                                fill: false
                                            }))
                                        }}
                                        options={{
                                            scales: {
                                                x: {
                                                    // type: 'time',
                                                    // time: {
                                                    //     displayFormats: {
                                                    //         quarter: 'DD/MM/YYYY'
                                                    //     }
                                                    // },
                                                    title: {
                                                        color: '#191A19',
                                                        display: true,
                                                        text: 'Dia',
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 18
                                                        }
                                                    },
                                                    ticks: {
                                                        color: '#191A19',
                                                        display: true,
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 16
                                                        }
                                                    }
                                                },
                                                y: {
                                                    title: {
                                                        color: '#191A19',
                                                        display: true,
                                                        text: 'Quantidade de Acessos',
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 18
                                                        }
                                                    },
                                                    ticks: {
                                                        stepSize: 1,
                                                        color: '#191A19',
                                                        display: true,
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 16
                                                        }
                                                    }
                                                }
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                    labels: {
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 18
                                                        }
                                                    }
                                                },
                                            },
                                        }}
                                    />
                                    {/*</div>*/}
                                </div>
                                <Grid item xs={12} className={classes.linksContainer}>
                                    <Link
                                        className={classes.links}
                                        to={{
                                            pathname: '/relatorio_unificado',
                                            state: {
                                                routeStartTime: startTime,
                                                routeEndTime: endTime,
                                                routeCheckTime: true
                                            }
                                        }}
                                    >
                                        Ver detalhes
                                    </Link>
                                </Grid>
                            </Grid>)}
                            {(dashboardFields.showAveragePeople) && (<Grid item xs={12} style={{ marginTop: '10px' }} ref={averagePeoplePerHourGraph} className={[(expand.averagePeoplePerHour) && (classes.fullScreen), classes.card]}>

                                <div className={classes.bigCardHeader}>
                                    <Grid item className={classes.cardHeader}>
                                        <Avatar aria-label="recipe" className={classes.cardAvatar}>
                                            <AccessTimeIcon style={{ fontSize: 30 }} />
                                        </Avatar>
                                        <Typography variant="h5" gutterBottom className={classes.cardLabel} style={{ marginTop: '10px' }}>
                                            Média de Acessos por Hora (Pessoas)
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => dispatchExpand({ graphic: 'averagePeoplePerHour', ref: averagePeoplePerHourGraph })}
                                        >
                                            <ExpandIcon />
                                        </Button>
                                    </Grid>
                                </div>
                                <Divider />
                                <div className={classes.cardGraphic}>
                                    <Scatter
                                        data={{
                                            datasets: [{
                                                data: timeMonitoringData,
                                                backgroundColor: getColor(0),
                                                borderColor: getColor(0),
                                                showLine: true
                                            }]

                                        }}
                                        options={{
                                            scales: {
                                                x: {
                                                    type: 'linear',
                                                    position: 'bottom',
                                                    title: {
                                                        color: '#191A19',
                                                        display: true,
                                                        text: 'Hora',
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 18
                                                        }
                                                    },
                                                    ticks: {
                                                        color: '#191A19',
                                                        display: true,
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 16
                                                        },
                                                        stepSize: 1,
                                                    }
                                                },
                                                y: {
                                                    title: {
                                                        color: '#191A19',
                                                        display: true,
                                                        text: 'Quantidade de Acessos',
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 18
                                                        }
                                                    },
                                                    ticks: {
                                                        color: '#191A19',
                                                        display: true,
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 16
                                                        }
                                                    }
                                                }
                                            },
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                    labels: {
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 18
                                                        }
                                                    }
                                                },
                                            },
                                        }}
                                    />

                                </div>
                                {
                                    !expand.averageVehiclePerHour && (
                                        <Grid item xs={12} style={{ display: "flex", alignItem: "center", justifyContent: "center" }}>
                                            <Typography variant="h7" gutterBottom className={classes.cardLabel} >
                                                Locais: &nbsp;&nbsp;
                                            </Typography>
                                            <Grid item xs={6}>
                                                <Select
                                                    label=""
                                                    options={
                                                        place.map((element) => ({ label: element.name, value: element.id }))
                                                    }
                                                    textFieldProps={{
                                                        name: 'place_id',
                                                        onChange: handleChangePlacePeople,
                                                        fullWidth: true,
                                                        value: labelPlacePeople,
                                                        required: false,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>)}
                            {(dashboardFields.showPeopleType) && (<Grid item xs={12} style={{ marginTop: '10px' }} ref={personTypeGraph} className={[(expand.personType) && (classes.fullScreen), classes.card]}>
                                <div className={classes.bigCardHeader}>
                                    <Grid item className={classes.cardHeader}>
                                        <Avatar aria-label="recipe" className={classes.cardAvatar}>
                                            <TimeIcon style={{ fontSize: 30 }} />
                                        </Avatar>
                                        <Typography variant="h5" gutterBottom className={classes.cardLabel}>
                                            Tipo de Pessoa
                                        </Typography>
                                        <Divider orientation="vertical" flexItem style={{ paddingLeft: "2px", margin: "0 10px 0 10px" }} />
                                        <Typography variant="h5" className={classes.subtitle}>
                                            TOTAL DE PESSOAS CADASTRADAS: {signedPeople.data[0] + signedPeople.data[1] + signedPeople.data[2]}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => dispatchExpand({ graphic: 'personType', ref: personTypeGraph })}
                                        >
                                            <ExpandIcon />
                                        </Button>
                                    </Grid>
                                </div>
                                <Divider />
                                <Grid item xs={12} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#fff',
                                    borderBottomLeftRadius: '10px',
                                    borderBottomRightRadius: '10px',
                                }}>
                                    {/* <Grid item xs={7} className={classes.chartContainer}> */}
                                    <Doughunt data={{
                                        labels: personTypeData.labels,
                                        datasets: [
                                            {
                                                label: 'Gênero das pessoas cadastradas',
                                                data: personTypeData.data,
                                                backgroundColor: [
                                                    '#413C69',
                                                    '#4A47A3',
                                                    '#AD62AA',
                                                    '#2D132C',
                                                    '#801336',
                                                    '#443737',
                                                    '#02383C',
                                                    '#007880',
                                                    '#B55400',
                                                    '#834C69',
                                                    '#ED6363',
                                                    '#4ECCA3',
                                                    '#C6DE41',
                                                    '#F1BBD5',
                                                    '#F70776',
                                                    '#AA96B7',
                                                    '#310A5D'
                                                ]
                                            }
                                        ]
                                    }}
                                        options={{
                                            maintainAspectRatio: false,
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    position: 'right',
                                                    labels: {
                                                        font: {
                                                            size: 18
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                    {/* </Grid> */}
                                </Grid>
                                <Grid item xs={12} className={classes.linksContainer}>
                                    <Link
                                        className={classes.links}
                                        to={{
                                            pathname: '/pessoa',
                                            state: {
                                                paramStartTime: startTime,
                                                paramEndTime: endTime,
                                            }
                                        }}
                                    >
                                        Ver detalhes
                                    </Link>
                                </Grid>
                            </Grid>)}
                        </Grid>
                    </Grid>

                    <Grid item xs={6} >
                        <Grid container>
                            {(dashboardFields.showAccessType) && ( <Grid item xs={12} ref={accessTypeGraph} className={[(expand.accessType) && (classes.fullScreen), classes.card]}>
                                <div className={classes.bigCardHeader}>
                                    <Grid item className={classes.cardHeader}>
                                        <Avatar aria-label="recipe" className={classes.cardAvatar}>
                                            <GroupIcon style={{ fontSize: 30 }} />
                                        </Avatar>
                                        <Typography variant="h5" gutterBottom className={classes.cardLabel}>
                                            Tipo de Acesso(Pessoas)
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => dispatchExpand({ graphic: 'accessType', ref: accessTypeGraph })}
                                        >
                                            <ExpandIcon />
                                        </Button>
                                    </Grid>
                                </div>
                                <Divider />
                                <Grid item xs={12} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#fff',
                                    borderBottomLeftRadius: '10px',
                                    borderBottomRightRadius: '10px',
                                }}>
                                    {/*<div>*/}
                                    <Doughunt data={{
                                        labels: accessControlData.labels,
                                        datasets: [
                                            {
                                                // label: extraAccessControlData.labels,
                                                data: [
                                                    accessControlData.authorized,
                                                    accessControlData.unauthorized,
                                                ],
                                                backgroundColor: [
                                                    '#2A0944',
                                                    '#FEC260',
                                                ],
                                            }
                                        ]
                                    }}
                                        options={{
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    position: 'right',
                                                    labels: {
                                                        font: {
                                                            size: 18
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                    {/*</div>*/}
                                </Grid>
                            </Grid>)}
                            {(dashboardFields.showMaskTemperature) && (<Grid item xs={12} style={{ marginTop: '10px' }} ref={maskTemperatureGraph} className={[classes.card, (expand.maskTemperature) && (classes.fullScreen)]}>

                                <div className={classes.bigCardHeader}>
                                    <Grid item className={classes.cardHeader}>
                                        <Avatar aria-label="recipe" className={classes.cardAvatar}>
                                            <GroupIcon style={{ fontSize: 30 }} />
                                        </Avatar>
                                        <Typography variant="h5" gutterBottom className={classes.cardLabel}>
                                            Uso de Máscara e Temperatura
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => dispatchExpand({ graphic: 'maskTemperature', ref: maskTemperatureGraph })}
                                        >
                                            <ExpandIcon />
                                        </Button>
                                    </Grid>
                                </div>
                                <Divider />
                                <div className={classes.cardGraphic}>
                                    {/*<div className={classes.chartContainer}>*/}
                                    <BarChart data={{
                                        labels: extraAccessControlData.labels,
                                        datasets: [
                                            {
                                                //label: extraAccessControlData.labels,
                                                data: [
                                                    extraAccessControlData.identifiedUnmasked,
                                                    extraAccessControlData.identifiedOverTemperature,
                                                    extraAccessControlData.unidentifiedUnmasked,
                                                    extraAccessControlData.unidentifiedOverTemperature
                                                ],
                                                backgroundColor: [
                                                    '#091353',
                                                    '#9D84B7',
                                                    '#D5D5D5',
                                                    '#B2F9FC'
                                                ],
                                                borderWidth: 2
                                            }
                                        ]
                                    }}
                                        options={{
                                            scales: {
                                                x: {
                                                    min: 0,
                                                    ticks: {
                                                        color: '#191A19',
                                                        display: true,
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 16
                                                        }
                                                    }
                                                },
                                                y: {
                                                    ticks: {
                                                        stepSize: 1,
                                                        color: '#191A19',
                                                        display: true,
                                                        font: {
                                                            family: 'Roboto',
                                                            size: 16
                                                        }
                                                    }
                                                }
                                            },
                                            maintainAspectRatio: true,
                                            elements: {
                                                bar: {
                                                    borderWidth: 1,
                                                },
                                            },
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                            },
                                        }}
                                    />
                                    {/*</div>*/}
                                </div>
                                <Grid item xs={12} className={classes.linksContainer}>
                                    <Link
                                        className={classes.links}
                                        to={{
                                            pathname: '/relatorio_unificado',
                                            state: {
                                                routeMask: 1,
                                                routeCheckMask: true,
                                                routeStartTime: startTime,
                                                routeEndTime: endTime,
                                                routeCheckTime: true
                                            }
                                        }}
                                    >
                                        Ver detalhes
                                    </Link>
                                </Grid>
                            </Grid>)}
                            {(dashboardFields.showGender) && (<Grid item xs={12} ref={genderGraph} style={{ marginTop: '10px' }} className={[classes.card, (expand.gender) && (classes.fullScreen)]}>
                                <div className={classes.bigCardHeader}>
                                    <Grid item className={classes.cardHeader}>
                                        <Avatar aria-label="recipe" className={classes.cardAvatar}>
                                            <CameraIcon style={{ fontSize: 30 }} />
                                        </Avatar>
                                        <Typography variant="h5" gutterBottom className={classes.cardLabel}>
                                            Gênero
                                        </Typography>
                                        <Divider orientation="vertical" flexItem style={{ paddingLeft: "2px", margin: "0 10px 0 10px" }} />
                                        <Typography variant="h5" className={classes.subtitle}>
                                            TOTAL DE PESSOAS CADASTRADAS: {signedPeople.data[0] + signedPeople.data[1] + signedPeople.data[2]}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => dispatchExpand({ graphic: 'gender', ref: genderGraph })}
                                        >
                                            <ExpandIcon />
                                        </Button>
                                    </Grid>
                                </div>
                                <Divider />
                                <Grid item xs={12}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: '#fff',
                                        borderBottomLeftRadius: '10px',
                                        borderBottomRightRadius: '10px',
                                    }}
                                >
                                    <Doughunt data={{
                                        labels: signedPeople.labels,
                                        datasets: [
                                            {
                                                label: 'Gênero das pessoas cadastradas',
                                                data: signedPeople.data,
                                                backgroundColor: [
                                                    '#005A8D',
                                                    '#FF96AD',
                                                    '#FF9B6A'
                                                ]
                                            }
                                        ]
                                    }}
                                        options={{
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    position: 'right',
                                                    labels: {
                                                        font: {
                                                            size: 18
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.linksContainer}>
                                    <Link
                                        className={classes.links}
                                        to={{
                                            pathname: '/pessoa',
                                            state: {
                                                paramStartTime: startTime,
                                                paramEndTime: endTime,
                                            }
                                        }}
                                    >
                                        Ver detalhes
                                    </Link>
                                </Grid>

                            </Grid>)}
                            {(dashboardFields.showFacialImage) && (<Grid item xs={12} style={{ marginTop: '10px' }} ref={faceImageGraph} className={[(expand.faceImage) && (classes.fullScreen), classes.card]}>

                                <div className={classes.bigCardHeader}>
                                    <Grid item className={classes.cardHeader}>
                                        <Avatar aria-label="recipe" className={classes.cardAvatar}>
                                            <FaceIcon style={{ fontSize: 30 }} />
                                        </Avatar>
                                        <Typography variant="h5" gutterBottom className={classes.cardLabel}>
                                            Imagem Fácial (Geral)
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => dispatchExpand({ graphic: 'faceImage', ref: faceImageGraph })}
                                        >
                                            <ExpandIcon />
                                        </Button>
                                    </Grid>
                                </div>
                                <Divider />
                                <Grid item xs={12} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#fff',
                                    borderBottomLeftRadius: '10px',
                                    borderBottomRightRadius: '10px',
                                }}>
                                    {/*<div>*/}
                                    <Doughunt data={{
                                        labels: faceImageDataGraph.labels,
                                        datasets: [
                                            {
                                                // label: extraAccessControlData.labels,
                                                data: [
                                                    faceImageDataGraph.createdWithFaceImage,
                                                    faceImageDataGraph.createdWithoutFaceImage
                                                ],
                                                backgroundColor: [
                                                    '#332FD0',
                                                    '#6EDCD9',
                                                ],
                                            }
                                        ]
                                    }}
                                        options={{
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    position: 'right',
                                                    labels: {
                                                        font: {
                                                            size: 18
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                    {/*</div>*/}
                                </Grid>
                            </Grid>)}
                            {(dashboardFields.showDeviceCapacity)&& (
                            <Grid item xs={12} style={{ marginTop: '10px' }} ref={deviceCapacityGraph} className={[(expand.deviceCapacity) && (classes.fullScreen), classes.card]}>
                                 <div className={classes.bigCardHeader}>
                                    <Grid item className={classes.cardHeader}>
                                        <Avatar aria-label="recipe" className={classes.cardAvatar}>
                                            <DevicesIcon style={{ fontSize: 30 }} />
                                        </Avatar>
                                        <Typography variant="h5" gutterBottom className={classes.cardLabel}>
                                            Capacidade do Dispositivo:
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => dispatchExpand({ graphic: 'deviceCapacity', ref: deviceCapacityGraph })}
                                        >
                                            <ExpandIcon />
                                        </Button>
                                    </Grid>
                                </div>
                                <Divider />
                                <Grid item xs={12} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#fff',
                                    borderBottomLeftRadius: '10px',
                                    borderBottomRightRadius: '10px',
                                }}>
                                 <Doughunt data={{
                                        labels: faceImageDataGraph.labels,
                                        datasets: [
                                            {
                                                // label: extraAccessControlData.labels,
                                                data: [
                                                    faceImageDataGraph.createdWithFaceImage,
                                                    faceImageDataGraph.createdWithoutFaceImage
                                                ],
                                                backgroundColor: [
                                                    '#332FD0',
                                                    '#6EDCD9',
                                                ],
                                            }
                                        ]
                                    }}
                                        options={{
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    position: 'right',
                                                    labels: {
                                                        font: {
                                                            size: 18
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                    {/*<div>*/}
{/*
                                 {
                                    !expand.deviceCapacity && (
                                        <Grid item xs={10} style={{ display: "flex", alignItem: "center", justifyContent: "center" }}>
                                            <Typography variant="h7" gutterBottom className={classes.cardLabel} >
                                                Dispositivos: &nbsp;&nbsp;
                                            </Typography>
                                            <Grid item xs={6}>
                                                <Select
                                                    label=""
                                                    options={
                                                         place.map((element) => ({ label: element.name, value: element.id }))
                                                    }
                                                    textFieldProps={{
                                                        name: 'Device',
                                                        onChange: handleChangePlacePeople,
                                                        fullWidth: true,
                                                        value: labelPlacePeople,
                                                        required: false,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                } */}
                                </Grid>
                            </Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
                 <Dialog open={openFieldPopup} onClose={() => setOpenFieldPopup(false)} maxWidth="lg" classes={{ paper: classes.dialogWrapperPopUp }}>
                    <DialogTitle className={classes.dialogTitlePopUp}>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="h6" component="div" style={{ flexGrow: 1 }} className={classes.labelPopUp}>
                                Campos do Dashboard
                            </Typography>
                            <Button
                                color="secondary"
                                onClick={() => setOpenFieldPopup(false)}>
                                <CloseIcon />
                            </Button>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid xs={12} container>
                            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={dashboardFields.showMonitoringPeople}
                                            onChange={() => handleDashboardFields('showMonitoringPeople')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Monitoramento de Pessoas
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={dashboardFields.showDeviceCapacity}
                                            onChange={() => handleDashboardFields('showDeviceCapacity')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                           Mostrar Capacidade
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={dashboardFields.showGender}
                                            onChange={() => handleDashboardFields('showGender')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Gênero
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={dashboardFields.showPeopleType}
                                            onChange={() => handleDashboardFields('showPeopleType')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Tipo de pessoas
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={dashboardFields.showMaskTemperature}
                                            onChange={() => handleDashboardFields('showMaskTemperature')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Uso de Máscara/Temperatura
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* right side */}
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={dashboardFields.showPeopleFrequency}
                                            onChange={() => handleDashboardFields('showPeopleFrequency')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                           Frequência de Acessos(Pessoas)
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={dashboardFields.showAveragePeople}
                                            onChange={() => handleDashboardFields('showAveragePeople')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Média de Acessos por Hora (Pessoas)
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={dashboardFields.showAccessType}
                                            onChange={() => handleDashboardFields('showAccessType')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                           Tipo de Acesso (Pessoa)
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={dashboardFields.showFacialImage}
                                            onChange={() => handleDashboardFields('showFacialImage')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Imagem Facial
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </main>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: '#EEEEEE'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    row: {
        marginBottom: theme.spacing(3),
    },
    logo: {
        fontWeight: 'bold',
        fontFamily: 'Oxygen',
        fontSize: '1.25rem',
        borderRadius: '10px',
        color: '#696969',
        textAlign: 'center',
        '&:nth-child(1)': {
            margin: '0 10px'
        }
    },

    subtitle: {
        fontWeight: 'bold',
        fontFamily: 'Oxygen',
        marginBottom: '5px',
        fontSize: '1rem',
        borderRadius: '10px',
        color: '#696969',
        textAlign: 'center',
        '&:nth-child(1)': {
            margin: '0 10px'
        }
    },
    title: {
        color: grey[700],
        fontSize: 25,
    },
    card: {
        height: '552px',
        //marginRight: '70px',
        padding: theme.spacing(2, 1),
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        borderRadius: '5px',
        boxShadow: '0 0 5px #cdcdcd'
    },
    marginToRight: {
        marginRight: '10px'
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    bigCardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    cardAvatar: {
        backgroundColor: indigo[500],
        width: theme.spacing(6),
        height: theme.spacing(6),
        marginRight: '10px'
    },
    cardData: {
        color: grey[800],
        textAlign: 'right',
        fontWeight: 'bold',
    },
    cardLabel: {
        color: grey[600],
        fontWeight: 'bold',
        // textAlign: 'right',
    },
    cardGraphic: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#fff',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
    },
    cardGraphicTitle: {
        color: grey[600],
        fontWeight: 'bold'
    },
    chartContainer: {
        maxWidth: '100vw',
        maxHeight: '100vh'
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    linksContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        maxHeight: '50px'
    },
    links: {
        color: '#000000',
        fontFamily: 'Oxygen',
        textDecoration: 'none',
        '&:hover': {
            color: '#1F1D36'
        },
        '&:after': {
            content: '||||'
        }
    },
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: '30%',
    },
    dialogWrapperPopUp: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: '60%',
    },
    dialogTitle: {
        paddingRight: '0px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    dialogTitlePopUp: {
        paddingRight: '0px',
    },
    dialogTitleLabel: {
        color: '#343F56',
        fontWeight: 'bold'
    },
    label: {
        color: '#3C415C',
        fontWeight: 'bold'
    },
    labelPopUp: {
        color: '#9e9e9e',
        fontWeight: 'bold'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '2%'
    },
    filterItem: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '10px',
    },
    fullScreen: {
        position: 'absolute',
        width: '100%',
        height: '110%',
        zIndex: '10000000',
        display: 'block',
        top: '0',
        left: '0',
    }
}));
