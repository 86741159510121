import React, { useState, useEffect } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Chip,
    Grid,
    Typography,
    Button,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CachedIcon from '@material-ui/icons/Cached';
import { makeStyles } from '@material-ui/core/styles';
import { grey, green, orange, red } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import DateTimePicker from '../../components/DateTimePicker';
import FullScreenDialog from '../../components/FullScreenDialog';
import Alert from '../../components/Alert';

import api from '../../services/api';
import config from '../../configs/config';

import date from '../../utils/date';

import { metrics } from '../../assets/styles';

const { host, port } = config;

export default function AccessLogList() {
    const classes = useStyles();

    const [accessLogs, setAccessLogs] = useState([]);
    const [total, setTotal] = useState(0);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [accessLog, setAccessLog] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get('/access_log/list', {
                    params: {
                        type: 'camera_access',
                        startTime,
                        endTime,
                        limit,
                        page,
                    },
                });

                const { count, access_log } = result.data;

                if (total > count) setPage(0);

                setAccessLogs(access_log);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();

        const load = setInterval(loadData, 10000);

        return () => {
            clearInterval(load);
        };
    }, [page, limit, startTime, endTime, total]);

    const watchVideo = async accessLog => {
        setLoadingVideo(true);
        setAccessLog(accessLog);

        try {
            const result = await api.get(`/access_log/video/${accessLog.id}`);

            const { video } = result.data;

            const videoPath = `http://${host}:${port}/midias/video/acesso/${video}`;

            setVideoUrl(videoPath);
            setLoadingVideo(false);
        }
        catch (err) {
            clearVideo();

            setMessage({
                open: true,
                variant: 'error',
                text: 'Não foi possível baixar o vídeo',
            });
        }
    };

    const clearVideo = () => {
        setAccessLog(null);
        setVideoUrl('');
        setLoadingVideo(false);
    };

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const accessStatus = ({ type, allowed }) => {
        switch (type) {
            case 'entry':
                return `Entrada ${allowed ? 'permitida' : 'negada'}`;
            case 'exit':
                return `Saída ${allowed ? 'permitida' : 'negada'}`;
            default:
                return `Acesso interno ${allowed ? 'permitido' : 'negado'}`;
        }
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Acessos
                            <Typography variant="overline" className={classes.smallTitle}>
                                (câmeras)
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} className={classes.filter}>
                        <DateTimePicker
                            className={classes.dateTimeInput}
                            format="DD/MM/YYYY HH:mm"
                            value={startTime}
                            placeholder="Período inicial"
                            onChange={date => setStartTime(date && date.format())}
                            cancelLabel="cancelar"
                        />
                        <DateTimePicker
                            className={classes.dateTimeInput}
                            format="DD/MM/YYYY HH:mm"
                            value={endTime}
                            placeholder="Período final"
                            onChange={date => setEndTime(date && date.format())}
                            cancelLabel="cancelar"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table className={classes.table} stickyHeader size="small" aria-label="access history">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Código</TableCell>
                                    <TableCell className={classes.tableHead}>Nome</TableCell>
                                    <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                    <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                    <TableCell className={classes.tableHead}>Máscara</TableCell>
                                    <TableCell className={classes.tableHead}>Sentimento</TableCell>
                                    <TableCell className={classes.tableHead}>Câmera</TableCell>
                                    <TableCell className={classes.tableHead}>Identificação</TableCell>
                                    <TableCell className={classes.tableHead}>Percentual</TableCell>
                                    <TableCell className={classes.tableHead}>Horário</TableCell>
                                    <TableCell className={classes.tableHead}>Vídeo</TableCell>
                                    <TableCell className={classes.tableHead}>Acesso</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!accessLogs && accessLogs.map(access => (
                                    <TableRow key={access.id}>
                                        <TableCell className={classes.tableBody} component="th" scope="row">
                                            {access.id}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access?.user
                                                ? access.user.name
                                                : <b>Não identificado</b>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.user.doc_type && access.user?.doc_type === 'ic' ? 'RG' :
                                                access.user?.doc_type === 'passport' ? 'Passaporte' :
                                                    access.user?.doc_type === 'other' ? 'Outro' :
                                                        access.user?.doc_type === 'unknown' ? 'CPF' :
                                                            'Não identificado'}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.user.doc_number ? access.user.doc_number : "Não identificado"}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.attribute_mask === 2 ? 'Sim' : 'Não'}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.attribute_emotion}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.camera.name}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {(access.user && access.type === 'FaceRecognitionRecord') ? (
                                                access.allowed_access ? (
                                                    <Typography className={classes.registeredInSector}>
                                                        Cadastrado no setor
                                                    </Typography>
                                                ) : (
                                                    <Typography className={classes.unregisteredInSector}>
                                                        Não cadastrado no setor
                                                    </Typography>
                                                )
                                            ) : (
                                                <Typography className={classes.notRegistered}>
                                                    Não autorizado
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.similarity ? `${access.similarity}%` : '-'}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {date(access.event_time).toLocaleFormat()}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {(accessLog && accessLog.id === access.id && loadingVideo) ? (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    disabled
                                                    size="small"
                                                    className={classes.videoButton}
                                                    startIcon={<CachedIcon fontSize="small" />}
                                                >
                                                    Carregando...
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    className={classes.videoButton}
                                                    startIcon={<PlayArrowIcon fontSize="small" />}
                                                    onClick={async () => await watchVideo(access)}
                                                >
                                                    Visualizar
                                                </Button>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.allowed_access ? (
                                                <Chip
                                                    icon={<CheckIcon className={classes.authorizationIcon} />}
                                                    label={accessStatus({
                                                        type: access.action_type,
                                                        allowed: access.allowed_access,
                                                    })}
                                                    size="small"
                                                    className={classes.authorizedAccess}
                                                />
                                            ) : (
                                                <Chip
                                                    icon={<CloseIcon className={classes.authorizationIcon} />}
                                                    label={accessStatus({
                                                        type: access.action_type,
                                                        allowed: access.allowed_access,
                                                    })}
                                                    size="small"
                                                    className={classes.unauthorizedAccess}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={total}
                        rowsPerPage={limit}
                        page={page}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                        labelRowsPerPage="Registros por página"
                        onChangePage={changePage}
                        onChangeRowsPerPage={quantityPerPage}
                    />
                </Grid>
                <FullScreenDialog
                    open={!!(videoUrl && !loadingVideo)}
                    title={accessLog && (
                        `${accessLog.camera.name}
                         (${accessLog.camera.device.name})`
                    )}
                    onClose={clearVideo}
                >
                    <video controls className={classes.video}>
                        <source src={videoUrl} />
                    </video>
                </FullScreenDialog>
            </main>
            {!!message.open && (
                <Alert
                    open={message.open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    severity={message.variant}
                    onClose={closeMessage}
                >
                    {message.text}
                </Alert>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: `calc(100% - ${metrics.drawerWidth}px)`,
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    smallTitle: {
        color: grey[600],
        marginLeft: theme.spacing(1),
        fontSize: 16,
    },
    filter: {
        margin: theme.spacing(0, 0, 3, 0),
    },
    dateTimeInput: {
        marginRight: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: 430,
        width: '100%',
    },
    table: {
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    registeredInSector: {
        color: green[700],
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: 11,
    },
    unregisteredInSector: {
        color: orange[700],
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: 11,
    },
    notRegistered: {
        color: red[700],
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: 11,
    },
    authorizedAccess: {
        backgroundColor: green[700],
        color: '#FFF',
    },
    unauthorizedAccess: {
        backgroundColor: red[700],
        color: '#FFF',
    },
    authorizationIcon: {
        color: '#FFF',
    },
    videoButton: {
        lineHeight: 1,
        '& span > svg': {
            marginRight: 2,
        },
    },
    video: {
        maxHeight: '100%',
        height: '100%',
        maxWidth: '100%',
        display: 'block',
        margin: 'auto',
    },
}));
