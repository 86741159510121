import { Box, Grid, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const PanelDetails = ({
    panel = {
        name: "",
        type: "",
        cameras: [],
        devices: [],
    }
}) => {
    return (
        <Grid
            container
            spacing={2}
        >
            <Grid item xs={12}>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: 700,
                            color: grey[600],
                        }}
                    >
                        Nome
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        style={{
                            fontWeight: 700,
                            color: grey[500],
                            marginLeft: 8
                        }}
                    >
                        {panel.name}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: 700,
                            color: grey[600],
                        }}
                    >
                        Tipo
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        style={{
                            fontWeight: 700,
                            color: grey[500],
                            marginLeft: 8
                        }}
                    >
                        {
                            panel.type === "access" ? "Acesso" :
                                panel.type === "monitoring" ? "Monitoramento" :
                                    ""
                        }
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    {
                        (!!panel?.devices?.length && panel.type === "access") && (
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 700,
                                        color: grey[600],
                                    }}
                                >
                                    Dispositivos
                                </Typography>
                                <Grid container spacing={1}>
                                    {
                                        panel?.devices?.map(device => (
                                            <Grid item xs={4} key={`${device.id}`}>
                                                <Typography
                                                    variant="subtitle1"
                                                    style={{
                                                        fontWeight: 700,
                                                        color: grey[500],
                                                        marginLeft: 8
                                                    }}
                                                >
                                                    - {device.name}
                                                </Typography>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        (!!panel?.cameras?.length && panel.type === "monitoring") && (
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 700,
                                        color: grey[600],
                                    }}
                                >
                                    Câmeras
                                </Typography>
                                <Grid container spacing={1}>
                                    {
                                        panel?.cameras?.map(camera => (
                                            <Grid item xs={4} key={`${camera.id}`}>
                                                <Typography
                                                    variant="subtitle1"
                                                    style={{
                                                        fontWeight: 700,
                                                        color: grey[500],
                                                        marginLeft: 8
                                                    }}
                                                >
                                                    - {camera.name}
                                                </Typography>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PanelDetails;