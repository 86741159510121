import React from 'react';
import { Dialog, DialogActions, Button, DialogContent, DialogContentText, DialogTitle } from '@mui/material';



export default function Modal({ open, handleView, notification_web }) {

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll='paper'
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">
                    {notification_web?.web_notification?.notification?.name}
                </DialogTitle>
                <DialogContent dividers='paper' >
                    <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                       <strong>Descrição:</strong>
                        {notification_web?.web_notification?.description}
                    </DialogContentText>
                    <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                        <strong>Mensagem:</strong> {notification_web?.web_notification?.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleView} autoFocus>Visualizado</Button>
                </DialogActions>
            </Dialog>
        </div>

    );
}
