import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    // Fab,
    Typography,
    Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import InputSearch from '../../components/InputSearch';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';

export default function FuncList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const history = useHistory();

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [funcs, setFuncs] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    function updateRequest({ search }) {
        if (refresh)
            loadData();

        async function loadData() {
            try {
                const result = await api.get(`/functionality/list`, {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });
                const { count, functionalities } = result.data;
                setFuncs(functionalities);
                setTotal(count);
                setRefresh(false);
            } catch (err) {
                console.log(err);
            }
        }
    }

    async function deleteData(id) {
        try {
            await api.delete(`/functionality/delete/${id}`);

            setMessage({
                open: true,
                variant: 'success',
                text: 'Funcionalidade deletada com sucesso!',
            });
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
        setRefresh(true);
    }

    const changePage = (_, newPage) => {
        setRefresh(true);
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setRefresh(true);
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };
    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Funcionalidades
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={updateRequest}
                            pagination={[refresh, limit, page]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Rotas</TableCell>
                                        <PermissionOneOfWrapper
                                            routes={[
                                                "/funcionalidades/modificar/:id",
                                                "/funcionalidades/deletar/:id",
                                            ]}
                                        >
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!funcs && funcs.map(func => (
                                        <TableRow key={func.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {func.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {func.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {func.router}
                                            </TableCell>
                                            <PermissionOneOfWrapper
                                                routes={[
                                                    "/funcionalidades/modificar/:id",
                                                    "/funcionalidades/deletar/:id",
                                                ]}
                                            >
                                                <TableCell className={classes.tableBody}>
                                                    <PermissionWrapper
                                                        route="/funcionalidades/modificar/:id"
                                                    >
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            className={classes.actionButton}
                                                            onClick={() => history.push(`/funcionalidades/modificar/${func.id}`)}
                                                        >
                                                            <EditIcon style={{ fontSize: 20 }} />
                                                        </Button>
                                                    </PermissionWrapper>
                                                    <PermissionWrapper route="/funcionalidades/deletar/:id">
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            color="secondary"
                                                            className={classes.actionButton}
                                                            onClick={() => deleteData(func.id)}
                                                        >
                                                            <DeleteIcon style={{ fontSize: 20 }} />
                                                        </Button>
                                                    </PermissionWrapper>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
                {/* <PermissionWrapper
                    route="/funcionalidades/criar"
                >
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href="/funcionalidades/criar"
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper> */}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        padding: theme.spacing(.5, 0),
        minWidth: 30,
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
}));
