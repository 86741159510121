import React, { useState, useContext } from 'react';
import {
    Toolbar,
    Paper,
    Grid,
    Typography,
    Fab,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from '@material-ui/core';
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { indigo, grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
// import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import KeyIcon from '@material-ui/icons/ShortText';
import TableIcon from '@material-ui/icons/Loop';
import Alert from '../../components/Alert';
// import Input from '../../components/Input';
import Popup from '../../components/Popup';
import InputSearch from '../../components/InputSearch';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';

export default function HelpIndex() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [message, setMessage] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [feed, setFeed] = useState([]);
    const [total, setTotal] = useState(0);
    const [pagination, setPagination] = useState();
    const [tableView, setTableView] = useState(true);

    const [refresh, setRefresh] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletingPost, setDeletingPost] = useState({ id: null, title: null });

    const [keywords, setKeywords] = useState([]);
    const [openKeywordsPopup, setOpenKeywordsPopup] = useState(false);

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    function updateRequest({ search }) {
        const loadPost = async () => {
            try {
                const result = await api.get('/help_feed/list', {
                    params: {
                        search,
                        page,
                        limit
                    }
                });
                const { count, helpFeed } = result.data;
                setFeed(helpFeed);
                setTotal(count);
                setPagination(Math.ceil(count / limit));
            } catch (err) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            }
        };

        loadPost();
    }

    const handleKeywords = (value) => {
        setKeywords(value);
        setOpenKeywordsPopup(true);
    }

    const handleDelete = (id, title) => {
        setDeletingPost({ id: id, title: title });
        setOpenDeletePopup(true);
    }
    const deletePost = async () => {
        try {
            setIsDeleting(true);
            await api.delete(`/help_feed/delete/${deletingPost.id}`);
            setMessage({
                open: true,
                variant: 'success',
                text: `Postagem deletada com sucesso!`,
            });
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
        setIsDeleting(false);
        setOpenDeletePopup(false);
        setRefresh(!refresh);
        setDeletingPost({ id: null, title: null });
    }

    const changePage = async (newPage) => {
        setPage(newPage);
    };

    const changeTbPage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const changeView = () => {
        setTableView(!tableView);
    }

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3} className={classes.row}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Artigos de Ajuda
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {(tableView) && (
                            <>
                                <Grid item style={{ marginBottom: '30px' }} xs={12}>
                                    <InputSearch
                                        textFieldProps={{
                                            placeholder: 'Buscar…',
                                        }}
                                        request={updateRequest}
                                        pagination={[page, limit, refresh]}
                                    />
                                </Grid>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="person type list">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableHead}>ID</TableCell>
                                                <TableCell className={classes.tableHead}>Titulo</TableCell>
                                                <TableCell className={classes.tableHead}>Palavras-chave</TableCell>
                                                <PermissionOneOfWrapper
                                                    routes={[
                                                        "/ajuda/modificar/:id",
                                                        "/ajuda/deletar/:id",
                                                        "/ajuda/visualizar/:id",
                                                    ]}
                                                >
                                                    <TableCell className={classes.tableHead}>Ações</TableCell>
                                                </PermissionOneOfWrapper>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!!feed && feed.map(current => (
                                                <TableRow key={current.id}>
                                                    <TableCell className={classes.tableBody} component="th" scope="row">
                                                        {current.id}
                                                    </TableCell>
                                                    <TableCell className={classes.tableBody}>
                                                        {current.title}
                                                    </TableCell>
                                                    <TableCell className={classes.tableBody}>
                                                        <Button
                                                            onClick={() => handleKeywords(current.keywords)}
                                                            variant='contained'
                                                            color='primary'
                                                        >
                                                            <KeyIcon style={{ fontSize: 20 }} />
                                                        </Button>
                                                    </TableCell>
                                                    <PermissionOneOfWrapper
                                                        routes={[
                                                            "/ajuda/modificar/:id",
                                                            "/ajuda/deletar/:id",
                                                            "/ajuda/visualizar/:id",
                                                        ]}
                                                    >
                                                        <TableCell className={classes.tableBody}>
                                                            <>
                                                                <PermissionWrapper route="/ajuda/modificar/:id">
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        size="small"
                                                                        color="primary"
                                                                        className={classes.actionButton}
                                                                        href={`/ajuda/modificar/${current.id}`}
                                                                    >
                                                                        <EditIcon style={{ fontSize: 20 }} />
                                                                    </Button>
                                                                </PermissionWrapper>
                                                                <PermissionWrapper route="/ajuda/deletar/:id">
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        size="small"
                                                                        color="secondary"
                                                                        className={classes.actionButton}
                                                                        onClick={() => handleDelete(current.id, current.title)}
                                                                    >
                                                                        <DeleteIcon style={{ fontSize: 20 }} />
                                                                    </Button>
                                                                </PermissionWrapper>
                                                                <PermissionWrapper route="/ajuda/visualizar/:id">
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        size="small"
                                                                        style={{ background: '#ECB365' }}
                                                                        className={classes.actionButton}
                                                                        href={`/ajuda/visualizar/${current.id}`}
                                                                    >
                                                                        <ViewIcon style={{ fontSize: 20, color: '#fff' }} />
                                                                    </Button>
                                                                </PermissionWrapper>
                                                            </>
                                                        </TableCell>
                                                    </PermissionOneOfWrapper>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15]}
                                    component="div"
                                    count={total}
                                    rowsPerPage={limit}
                                    page={page}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                    labelRowsPerPage="Registros por página"
                                    onChangePage={changeTbPage}
                                    onChangeRowsPerPage={quantityPerPage}
                                />
                            </>
                        )}
                        {(!tableView) && (
                            <Paper alevation={3} style={{ height: '80vh' }}>
                                <Grid item className={classes.form}>
                                    <Grid item style={{ marginBottom: '30px' }} xs={12}>
                                        {/* <Input
                                            icon={<SearchIcon style={{ color: grey[400] }} />}
                                            iconPosition="start"
                                            textFieldProps={{
                                                placeholder: 'Buscar…',
                                                onChange: ({ target }) => setSearch(target.value),
                                            }}
                                        /> */}
                                        <InputSearch
                                            textFieldProps={{
                                                placeholder: 'Buscar…',
                                            }}
                                            request={updateRequest}
                                        />
                                    </Grid>
                                    <Grid container style={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'space-between', justifyContent: 'center' }} spacing={3}>
                                        <Grid item style={{ height: '400px' }} xs={12}>
                                            {
                                                !!feed && feed.map((article) => {
                                                    return (
                                                        <Paper key={article.id} className={classes.article} component={Link} to={`/ajuda/visualizar/${article.id}`}>
                                                            <Typography variant='subtitle' className={classes.articleTitle}>
                                                                {article.title}
                                                            </Typography>
                                                        </Paper>
                                                    );
                                                })
                                            }
                                        </Grid>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px', width: '100%', maxHeight: '70px' }}>
                                            <Button className={[classes.paginationButton]} onClick={() => changePage(0)}>{'<<'}</Button>
                                            {!!feed && [0].map(_ => {
                                                let pages = []
                                                for (let i = 0; i < pagination; i++) {
                                                    pages.push(
                                                        <Button className={[classes.paginationButton, (page === i) && (classes.currentPage)]} onClick={() => changePage(i)}>{i + 1}</Button>
                                                    )
                                                }
                                                return pages;
                                            })}
                                            <Button className={[classes.paginationButton]} onClick={() => changePage(pagination - 1)}>{'>>'}</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}

                    </Grid>
                </Grid>
                <Popup
                    openPopup={openDeletePopup}
                    setOpenPopup={setOpenDeletePopup}
                    width='40%'
                >
                    <Grid container xs={12} style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 3% 2% 3%',

                    }}>
                        <Grid item xs={12}>
                            <Typography variant='h5'
                                style={{
                                    fontWeight: 'bold',
                                    color: '#9e9e9e'
                                }}
                            >
                                Deseja mesmo deletar a postagem
                            </Typography>
                        </Grid>
                        <Grid variant='h5'
                            style={{
                                fontWeight: 'bold',
                                color: '#9e9e9e'
                            }}
                        >
                            <Typography>
                                "{deletingPost.title}" ?
                            </Typography>
                        </Grid>
                        <Grid item style={{
                            marginTop: '50px'
                        }}>
                            <Button
                                disabled={isDeleting}
                                variant="contained"
                                onClick={() => deletePost()}
                                color="secondary"
                                endIcon={<DeleteIcon />}
                            >
                                {isDeleting ? 'Deletando...' : 'Sim'}
                            </Button>
                        </Grid>

                    </Grid>
                </Popup>
                <Popup
                    title='Palavras-chave'
                    openPopup={openKeywordsPopup}
                    setOpenPopup={setOpenKeywordsPopup}
                    width='35%'
                >
                    <Grid container direction='row' style={{ width: '100%' }}>
                        <Grid container xs={12} className={classes.keywordsContainer}>
                            {
                                keywords.map((word, index) => {
                                    return (
                                        <Grid key={index} item className={classes.keyword}>
                                            <Typography className={classes.keywordTitle}>{word}</Typography>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                </Popup>
                <PermissionWrapper route="/ajuda/criar">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href="/ajuda/criar"
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                <Fab
                    color={tableView && "primary"}
                    aria-label="add"
                    className={classes.tableButton}
                    onClick={() => changeView()}
                >
                    <TableIcon />
                </Fab>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    row: {
        marginBottom: theme.spacing(3),
    },
    form: {
        padding: theme.spacing(6, 6),
        height: '100%'
    },
    title: {
        color: grey[700],
        fontSize: 25,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    card: {
        margin: theme.spacing(1, 2),
        padding: theme.spacing(3, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    cardAvatar: {
        backgroundColor: indigo[500],
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    cardData: {
        color: grey[800],
        textAlign: 'right',
    },
    cardLabel: {
        color: grey[500],
        textAlign: 'right',
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    tableButton: {
        position: 'fixed',
        bottom: 70,
        right: 0,
        margin: theme.spacing(3),
    },
    article: {
        padding: '18px 16px',
        borderRadius: '8px',
        boxShadow: '0 0 2px #cdcdcd',
        marginBottom: '20px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxHeight: '85px',
        textDecoration: 'none',
        '&:hover': {
            boxShadow: '0 0 10px #cdcdcd',
        }
    },
    articleTitle: {
        color: grey[600],
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    paginationButton: {
        padding: '0',
        margin: '0.25%'
    },
    currentPage: {
        textDecoration: 'underline'
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    keywordsContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '15px 0',
    },
    keyword: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#cdcdcd',
        borderRadius: '5px',
        padding: '0 20px',
        boxSizing: 'border-box',
        margin: '0 0 5px 5px'
    },
    keywordTitle: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        color: grey[700],
        textTransform: 'uppercase'
    },
}));
