
import React from 'react';
import {
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import Popup from './Popup'


export default function PopUpReactive({ open, setOpen, title, isActivate, activateFunction, cancelFunction }) {


    return (
        <Popup
            openPopup={open}
            setOpenPopup={setOpen}
        >
            <Grid container xs={12} style={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 3% 2% 3%',

            }}>
                <Typography variant='h5'
                    style={{
                        fontWeight: 'bold',
                        color: '#9e9e9e'
                    }}

                >
                    Esse {title} já existe deseja reativar?
                </Typography>
                <Grid item style={{
                    marginTop: '50px',
                }}>
                    <Grid
                        container
                        direction="row-reverse"
                        alignItems="center"
                        spacing={4}
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Grid item>
                            <Button
                                disabled={isActivate}
                                variant="contained"
                                onClick={activateFunction}
                                color="secondary"
                                id="confirm"
                            >
                                {isActivate ? 'Ativando...' : 'Ativar'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={cancelFunction}
                                color="primary"
                                id="confirm"
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Popup>
    );
}





