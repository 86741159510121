import React from  'react';
import { Link } from 'react-router-dom';

const RedirectToUser = ({ pType, uId, children }) => {
    return (
        <Link
            to={`/${pType === 4 ? 'visitante' : 'pessoa'}/modificar/${uId}`}
            target="_blank"
            style={{
                textDecoration: "none",
                color: "inherit"
            }}
        >
            {children}
        </Link>
    )
};

export default RedirectToUser;