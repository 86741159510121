import moment from 'moment';
import 'moment/locale/pt-br';

moment.fn.getTimezone = function() {
    return this.format('Z');
};

moment.fn.toDateFormat = function() {
    return this.format('YYYY-MM-DD');
};

moment.fn.toTimeFormat = function() {
    return this.format('HH:mm:ss');
};

moment.fn.toLocaleDateFormat = function() {
    return this.format('L');
};

moment.fn.toLocaleTimeFormat = function() {
    return this.format('LT');
};

moment.fn.toLocaleFormat = function() {
    return this.format('L LT');
};

moment.fn.toTimestamp = function() {
    return this.unix();
};

moment.fn.toISOFormat = function() {
    return this.toISOString(true);
};

moment.fn.isValidDate = function() {
    return this.isValid();
};

moment.fn.isValidTime = function() {
    const pattern = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
    const time = this.format('HH:mm:ss');

    return !!(this.isValid() && time.match(pattern));
};

moment.fn.incrementMinutes = function(minutes) {
    return this.add(minutes, 'minutes');
};

moment.fn.decrementMinutes = function(minutes) {
    return this.subtract(minutes, 'minutes');
};

moment.fn.getDayOfWeek = function() {
    return this.day();
};

moment.fn.getDayOfWeekName = function(day) {
    return this.day(day).format('ddd');
};

moment.fn.minutesToTime = function(minutes) {
    return this.incrementMinutes(minutes).format(this._f);
};

export default moment;
