import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';

import Routes from './routes';
import { PermissionsContextProvider } from './providers/Permissions';

function App() {
    return (
        <PermissionsContextProvider>
            <Router>
                <Routes />
            </Router>
        </PermissionsContextProvider>
    );
}

export default App;
