import React, {useState, useEffect} from 'react';
import {Toolbar, Paper, Button, Grid, Typography, Checkbox} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {Formik} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {useParams, useHistory} from 'react-router-dom';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';
import InputTime from '../../components/InputTime';
import Autocomplete from '../../components/Autocomplete';
import Select from '../../components/Select';

import api from '../../services/api';

export default function CredentialModify() {
    const classes = useStyles();

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const [users, setUsers] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [user, setUser] = useState(null);
    const [credential, setCredential] = useState({
        id_user: 0,
        number: '',
        credential_type: 0,
        technology_type: 0,
        duration_type: 0,
        credential_status: 0,
        master_type: 0, 
        blocking_reason: '',
        time_collect_in_safe: '',
        organizational_structure: 0,
        access_profile: 0,
        is_credential_public: false,
        is_equipament_supervisor: false,
        validity_begin: null,
        validity_end: null
    });

    const {id} = useParams();
    const history = useHistory();

    const [orgsSelect, setOrgsSelect] = useState([{ name: "Organizações", id: 0 }]);

    useEffect(() => {
        const findOrg = async () => {
            try {
                const result = await api.get('/organizational_structure/index');

                const { orgs } = result.data;

                setOrgsSelect(orgs);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findOrg();
    }, []);

    useEffect(() => {
        const findCredential = async () => {
            try{
                const result = await api.get(`/credential/detail/${id}`);

                const {credential} = result.data
                setCredential(credential);
                setUser(credential.user);
            }catch(err){
                history.goBack();
            }
        };
        findCredential();
    }, [id, history])

    const saveCredential = async (values, formik) => {
        try {
            const credentialData = {
                ...values,
                id_user: user.id,
            };
            const result = await api.patch(`/credential/modify/${id}`, credentialData);
            const {credential} = result.data;

            setCredential(credential);
            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Credencial ${credential.number} modificada com sucesso!`,
            });
            resetForm(formik);
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    useEffect(() => {
        const findUser = async () => {
            try {
                const result = await api.get('/user/list', {
                    params: {search: userSearch},
                });

                const {users} = result.data;

                setUsers(users);
            }
            catch (err) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            }
        };

        findUser();
    }, [userSearch]);

    const resetForm = formik => {
        formik.resetForm();
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Editar credencial
                        </Typography>
                    </Grid>
                    <Formik
                        enableReinitialize={true}
                        initialValues={credential}
                        onSubmit={saveCredential}
                    >
                        {({values, setFieldValue, handleChange, handleSubmit, isSubmitting}) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="credential-modify" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                <Input
                                                                    label="Número"
                                                                    textFieldProps={{
                                                                        name: 'number',
                                                                        placeholder: 'Número da credencial',
                                                                        onChange: handleChange,
                                                                        value: values.number,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                            <Input
                                                                    label="Tipo de credencial"
                                                                    textFieldProps={{
                                                                        name: 'credential_type',
                                                                        placeholder: 'Tipo de credencial',
                                                                        onChange: handleChange,
                                                                        value: values.credential_type,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                        type: 'number',
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    label="Tipo de Tecnologia"
                                                                    options={[
                                                                        { label: 'Código de Barras', value: 1 },
                                                                        { label: 'Cartão Magnético', value: 2 },
                                                                        { label: 'Proximidade', value: 3 },
                                                                        { label: 'Smart Card', value: 4 },
                                                                        { label: 'RFID', value: 5 }
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'technology_type',
                                                                        onChange: handleChange,
                                                                        value: values.technology_type,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    label="Tipo de Duração"
                                                                    options={[
                                                                        { label: 'Temporária', value: 0 },
                                                                        { label: 'Permanente', value: 1 },
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'duration_type',
                                                                        onChange: handleChange,
                                                                        value: values.duration_type,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    label="Status da Credencial"
                                                                    options={[
                                                                        { label: 'Válida', value: 0 },
                                                                        { label: 'Bloqueada', value: 1 },
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'credential_status',
                                                                        onChange: handleChange,
                                                                        value: values.credential_status,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    label="Master Type"
                                                                    options={[
                                                                        { label: 'Não Mestre', value: 0 },
                                                                        { label: 'Mestre', value: 1 },
                                                                        { label: 'Mestre no perfil', value: 2 },
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'master_type',
                                                                        onChange: handleChange,
                                                                        value: values.master_type,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                <Input
                                                                    label="Razão de Bloqueio"
                                                                    textFieldProps={{
                                                                        name: 'blocking_reason',
                                                                        placeholder: '...',
                                                                        onChange: handleChange,
                                                                        value: values.blocking_reason,
                                                                        fullWidth: true,
                                                                        required: false,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    label="Estrutura Organizacional"
                                                                    options={
                                                                        orgsSelect.map(org => ({ label: org.name, value: org.id }))
                                                                    }
                                                                    textFieldProps={{
                                                                        name: 'organizational_structure',
                                                                        onChange: handleChange,
                                                                        fullWidth: true,
                                                                        value: values.organizational_structure,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={4}>
                                                                <DatePicker
                                                                    label="Inicio da Validação"
                                                                    format="DD/MM/YYYY"
                                                                    value={values.validity_begin}
                                                                    placeholder="DD/MM/YYYY"
                                                                    onChange={date => setFieldValue('validity_begin', date && date.format())}
                                                                    cancelLabel="cancelar"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <DatePicker
                                                                    label="Fim da Validação"
                                                                    format="DD/MM/YYYY"
                                                                    value={values.validity_end}
                                                                    placeholder="DD/MM/YYYY"
                                                                    onChange={date => setFieldValue('validity_end', date && date.format())}
                                                                    cancelLabel="cancelar"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <InputTime
                                                                    className={classes.inputTime}
                                                                    textFieldProps={{
                                                                        
                                                                        name: 'time_collect_in_safe',
                                                                        value: values.time_collect_in_safe,
                                                                        placeholder: 'Horas',
                                                                    }}
                                                                    onValidFormat={value => (
                                                                        setFieldValue(`time_collect_in_safe`, value)
                                                                    )}
                                                                    onInvalidFormat={() => (
                                                                        setFieldValue(`time_collect_in_safe`, '')
                                                                    )}
                                                                    label = 'Hora coletada de segurança'
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                <Input
                                                                    label="Acesso ao perfil:"
                                                                    textFieldProps={{
                                                                        name: 'access_profile',
                                                                        placeholder: '...',
                                                                        onChange: handleChange,
                                                                        value: values.access_profile,
                                                                        fullWidth: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                            <Autocomplete
                                                                label="Usuário Vinculado"
                                                                placeholder="Usuário"
                                                                options={users}
                                                                getOptionLabel={({ name }) => name || ''}
                                                                onChange={(_, value) => setUser(value)}
                                                                getOptionSelected={(option, value) => (option.name === value.name)}
                                                                onInputChange={(_, value) => setUserSearch(value)}
                                                                value={user}
                                                                fullWidth
                                                                required={(values.role === 'employee')} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                <Paper
                                                                    className={classes.checkboxContainer}
                                                                    variant="outlined"
                                                                    onClick={() => {
                                                                        if (!values.is_credential_public) {
                                                                            setFieldValue('is_credential_public', true);
                                                                        }
                                                                        else {
                                                                            setFieldValue('is_credential_public', false);
                                                                        }
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        name='is_credential_public'
                                                                        checked= {values.is_credential_public}
                                                                        color="primary"
                                                                        onChange = {handleChange}
                                                                    />
                                                                    <div className={classes.checkboxName}>
                                                                        <Typography variant="subtitle2">
                                                                            Credencial Publica
                                                                        </Typography>
                                                                    </div>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Paper
                                                                    className={classes.checkboxContainer}
                                                                    variant="outlined"
                                                                    onClick={() => {
                                                                        if (!values.is_equipament_supervisor) {
                                                                            setFieldValue('is_equipament_supervisor', true);
                                                                        }
                                                                        else {
                                                                            setFieldValue('is_equipament_supervisor', false);
                                                                        }
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        name='is_equipament_supervisor'
                                                                        checked= {values.is_equipament_supervisor}
                                                                        color="primary"
                                                                        onChange = {handleChange}
                                                                    />
                                                                    <div className={classes.checkboxName}>
                                                                        <Typography variant="subtitle2">
                                                                            Equipamento do Supervisor
                                                                        </Typography>
                                                                    </div>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} container justify="flex-end">
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        form="credential-modify"
                                        color="primary"
                                        endIcon={<SaveIcon />}
                                    >
                                        {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    userRoles: {
        '& > button': {
            marginRight: theme.spacing(1),
        },
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& h6 > span': {
            marginLeft: theme.spacing(.5),
        },
        '&:hover': {
            boxShadow: '0 2px 10px 1px rgba(150,150,150,.3)',
        },
    },
}));

