import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid } from '@mui/material';
import { Button } from '@material-ui/core';
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from '@mui/icons-material/Close';

export default function PopUpDetails(props) {
    const { title, children, openPopup, setOpenPopup, onClose, saveButton = false } = props;
    const [save] = useState(saveButton);

    const handleClose = () => {
        setOpenPopup(false);

        if (onClose) {
            onClose()
        }
    };

    return (
        <Dialog
            open={openPopup}
            onClose={handleClose}
            maxWidth={'md'}
            fullWidth={true}>
            <DialogTitle>
                <Typography variant='h5' align='center' style={{
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    color: 'black'
                }}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container xs={12} style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 3% 2% 3%',

                }}>
                    <Typography variant='h6'
                        style={{
                            fontWeight: 'normal',
                            color: 'black'
                        }}>
                        {children}
                    </Typography>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }} >
                {save ?
                    (
                    <>
                    <Button
                        color="primary"
                        endIcon={<SaveIcon />}
                        onClick={handleClose}
                        type="submit"
                        variant="contained"
                    >
                        Salvar
                    </Button>
                        <Button
                        color="secondary"
                        endIcon={<CloseIcon />}
                        onClick={handleClose}
                        variant="contained"
                    >
                        Fechar
                    </Button>
                    </>
                    )
                    :
                    (<Button
                        color="secondary"
                        endIcon={<CloseIcon />}
                        onClick={handleClose}
                        variant="contained"
                        >
                        Fechar
                    </Button>)
                }


            </DialogActions>
        </Dialog >
    );
}
