import React, { useState, useEffect } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Chip,
    Grid,
    Typography,

} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { grey, green, red } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import DateTimePicker from '../../components/DateTimePicker';
import Alert from '../../components/Alert';
import Input from '../../components/Input';

import api from '../../services/api';

import date from '../../utils/date';

import { metrics } from '../../assets/styles';


export default function AccessLogList() {
    const classes = useStyles();


    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [gateAccessLogs, setGateAccessLogs] = useState([]);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get('/gates/list', {
                    params: {
                        search,
                        startTime,
                        endTime,
                        limit,
                        page,
                    },
                });

                const { count, devices } = result.data;

                if (total > count) setPage(0);

                setGateAccessLogs(devices);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();

        const load = setInterval(loadData, 10000);

        return () => {
            clearInterval(load);
        };
    }, [search, page, limit, startTime, endTime, total]);

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };



    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const getAccessStatus = (status) => {
        let new_status;

        // https://demo.mdacesso.com.br/itk/swagger/ui/index#!/Access32Log/AccessLog_Get
        switch (status) {
            case null:
                new_status = null;
                break;
            case 1:
                new_status = 'Permitido'; // AccessGranted
                break;
            case 8:
                new_status = 'Acesso não completado'; // AccessNotCompleted
                break;
            case 9:
                new_status = 'A credencial do usuário não existe'; // CredentialNotExists
                break;
            case 10:
                new_status = "Acesso completado"; // AccessCompleted
                break;
            case 13:
                new_status = 'Credencial expirada'; // CredentialExpired
                break;
            case 22:
                new_status = 'Horário não registrado'; // ShiftNotRegistered
                break;
            case 23:
                new_status = 'Autorizado por administrador'; // AccessCompletedByAuthorization
                break;
            case 29:
                new_status = 'A credencial não era associada com o usuário'; // CredentialNotAssociatedToPerson
                break;
            case 39:
                new_status = 'Digital não registrada'; // FingerprintNotRegistered
                break;
            case 40:
                new_status = 'Digital não reconhecida'; // FingerprintDoesntMatch
                break;
            case 60:
                new_status = 'Senha Invalida'; // InvalidPassword
                break;
            default:
                new_status = 'Código ' + status;
        }

        return new_status;
    };

    const getAccessColor = (status) => {
        const gotSuccess = [1, 10, 23]
        const gotError = [9, 22, 29, 39, 40, 60]

        if (gotSuccess.includes(status)) {
            return '#388E3C'; // greeen
        } else if (gotError.includes(status)) {
            return '#D32F2F'; // red
        } else {
            return '#FBC02D'; // yellow
        }

    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de acessos
                            <Typography variant="overline" className={classes.smallTitle}>
                                (catracas)
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} className={classes.filter}>
                        <Input
                            className={classes.dateTimeInput} // just for now :p
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setSearch(target.value),
                            }}
                        />
                        <DateTimePicker
                            className={classes.dateTimeInput}
                            format="DD/MM/YYYY HH:mm"
                            value={startTime}
                            placeholder="Período inicial"
                            onChange={date => setStartTime(date && date.format())}
                            cancelLabel="cancelar"
                        />
                        <DateTimePicker
                            className={classes.dateTimeInput}
                            format="DD/MM/YYYY HH:mm"
                            value={endTime}
                            placeholder="Período final"
                            onChange={date => setEndTime(date && date.format())}
                            cancelLabel="cancelar"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table className={classes.table} stickyHeader size="small" aria-label="access history">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Credencial</TableCell>
                                    <TableCell className={classes.tableHead}>Nome</TableCell>
                                    <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                    <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                    {/* <TableCell className={classes.tableHead}>Número do Registro</TableCell> */}
                                    <TableCell className={classes.tableHead}>Status</TableCell>
                                    <TableCell className={classes.tableHead}>Dia/Hora</TableCell>
                                    <TableCell className={classes.tableHead}>Nome do Dispositivo</TableCell>
                                    {/* <TableCell className={classes.tableHead}>ID Organizacional</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!gateAccessLogs && gateAccessLogs.map(access => (
                                    <TableRow key={access.id}>
                                        <TableCell className={classes.tableBody} component="th" scope="row">
                                            {access.credential_number}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access?.person_name
                                                ? access.person_name
                                                : access.user != null && access.user.name ? access.user.name
                                                    : <b>Não identificado</b>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.user.doc_type && access.user?.doc_type === 'ic' ? 'RG' :
                                                access.user?.doc_type === 'passport' ? 'Passaporte' :
                                                    access.user?.doc_type === 'other' ? 'Outro' :
                                                        access.user?.doc_type === 'unknown' ? 'CPF' :
                                                            'Não identificado'}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {access.user.doc_number ? access.user.doc_number : "Não identificado"}
                                        </TableCell>
                                        {/* <TableCell className={classes.tableBody}>
                                            {access?.person_registration_number && (
                                                access.person_registration_number
                                            )}
                                        </TableCell> */}
                                        <TableCell className={classes.tableBody}>
                                            {access?.access_validation_status
                                                ? (
                                                    <Chip
                                                        style={{
                                                            background: getAccessColor(access.access_validation_status),
                                                            color: '#fff',
                                                            fontWeight: 'bold'
                                                        }}
                                                        label={getAccessStatus(access.access_validation_status)}
                                                    />
                                                )
                                                : <b>Não identificado</b>
                                            }
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {date(access.access_datetime).toLocaleFormat()}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {/* {access?.equipment_number
                                                ? access.equipment_number
                                                : <b>Não identificado</b>
                                            } */}
                                            Catraca MC2 New
                                        </TableCell>
                                        {/* <TableCell className={classes.tableBody}>
                                            {access?.organizational_structure_id
                                                ? access.organizational_structure_id
                                                : <b>Não identificado</b>
                                            }
                                        </TableCell> */}
                                        {/* <TableCell className={classes.tableBody}>
                                            {access.allowed_access ? (
                                                <Chip
                                                    icon={<CheckIcon className={classes.authorizationIcon} />}
                                                    label={accessStatus({
                                                        type: access.action_type,
                                                        allowed: access.allowed_access,
                                                    })}
                                                    size="small"
                                                    className={classes.authorizedAccess}
                                                />
                                            ) : (
                                                <Chip
                                                    icon={<CloseIcon className={classes.authorizationIcon} />}
                                                    label={accessStatus({
                                                        type: access.action_type,
                                                        allowed: access.allowed_access,
                                                    })}
                                                    size="small"
                                                    className={classes.unauthorizedAccess}
                                                />
                                            )}
                                        </TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={total}
                        rowsPerPage={limit}
                        page={page}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                        labelRowsPerPage="Registros por página"
                        onChangePage={changePage}
                        onChangeRowsPerPage={quantityPerPage}
                    />
                </Grid>
            </main>
            {!!message.open && (
                <Alert
                    open={message.open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    severity={message.variant}
                    onClose={closeMessage}
                >
                    {message.text}
                </Alert>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: `calc(100% - ${metrics.drawerWidth}px)`,
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    smallTitle: {
        color: grey[600],
        marginLeft: theme.spacing(1),
        fontSize: 16,
    },
    filter: {
        margin: theme.spacing(0, 0, 3, 0),
    },
    dateTimeInput: {
        marginRight: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: 430,
        width: '100%',
    },
    table: {
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    authorizedAccess: {
        backgroundColor: green[700],
        color: '#FFF',
    },
    unauthorizedAccess: {
        backgroundColor: red[700],
        color: '#FFF',
    },
    authorizationIcon: {
        color: '#FFF',
    },
}));
