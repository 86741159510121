import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Typography,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function Popup(props) {
    const {title, children, openPopup, setOpenPopup, width = '60%', height = 'auto', maxWidth = 'lg', onClose} = props;
    const classes = useStyles({ width, height });

    const handleClose = () => {
        if (setOpenPopup) setOpenPopup(false);
        if (onClose) onClose();
    }

    return (
        <Dialog open={openPopup} onClose={handleClose} maxWidth={maxWidth} classes={{paper: classes.dialogWrapper}}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{flexGrow: 1}}>
                        {title}
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: ({ width }) => width,
        height: ({ height }) => height,
    },
    dialogTitle: {
        paddingRight: '0px',
    },
}))
