import React, { useState, useEffect } from 'react';
import {
    Toolbar,
    Grid,
    Fab,
    Typography,
    Paper,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import date from '../../utils/date';
import SideMenu from '../../components/SideMenu';

import api from '../../services/api';

export default function CredentialView() {
    const classes = useStyles();

    const [user, setUser] = useState([]);
    const [credential, setCredential] = useState([]);

    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get(`/credential/detail/${id}`);

                const { credential } = result.data;

                setCredential(credential);
                setUser(credential.user);

            } catch (err) {
                history.goBack();
            }
        };

        loadData();
    }, [history, id]);

    function getTechnology(tech) {
        let techName = null;
        switch (tech) {
            case 1:
                techName = 'Código de Barras';
                break;
            case 2:
                techName = 'Cartão Magnético';
                break;
            case 3:
                techName = 'Proximidade';
                break;
            case 4:
                techName = 'Smart Card';
                break;
            case 5:
                techName = 'RFID';
                break;
            default:
                techName = `${tech} (desconhecido)`;
        }

        return techName;
    }

    function getDuration(type) {
        let durationType = null;

        switch (type) {
            case 0:
                durationType = 'Temporária';
                break;
            case 1:
                durationType = 'Permanente';
                break;
            default:
                durationType = `${type} (desconhecido)`;
        }

        return durationType;
    }

    function getCredentialStatus(status) {
        let credStatus = null;

        switch (status) {
            case 0:
                credStatus = 'Válida';
                break;
            case 1:
                credStatus = 'Bloqueada';
                break;
            default:
                credStatus = `${status} (desconhecido)`;
        }

        return credStatus;
    }

    function getMasterType(type) {
        let masterType = null;

        switch (type) {
            case 0:
                masterType = 'Não mestre';
                break;
            case 1:
                masterType = 'Mestre';
                break;
            case 2:
                masterType = 'Mestre apenas no perfil';
                break;
            default:
                masterType = `${type} (desconhecido)`;
        }

        return masterType;
    }

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Credencial: {credential.Number}
                        </Typography>
                    </Grid>
                    <Paper alevation={2} className={classes.block}>
                        <Grid item xs={12} >
                            <Grid container spacing={1}>
                                <Grid container item xs={12} spacing={2} style={{ padding: '15px 10px' }}>
                                    <Grid container>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Nome do Usuário:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(user?.name !== null) ? user.name : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Tipo de Credencial:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.credential_type !== null) ? credential.credential_type : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Tipo de Tecnologia:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.technology_type !== null) ? getTechnology(credential.technology_type) : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Tipo de Duração:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.duration_type !== null) ? getDuration(credential.duration_type) : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Status da Credencial:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.credential_status !== null) ? getCredentialStatus(credential.credential_status) : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Master Type:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.master_type !== null) ? getMasterType(credential.master_type) : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Número:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.number !== null) ? credential.number : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Perfil de Acesso:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.access_profile !== null) ? credential.access_profile : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Razão de bloqueio:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.blocking_reason) ? credential.blocking_reason : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Inicio da validação:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.validity_begin !== null) ? date(credential.validity_begin).format('DD/MM/YYYY').toUpperCase() : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Fim da Validação:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.validity_end !== null) ? date(credential.validity_end).format('DD/MM/YYYY').toUpperCase() : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Tempo Coletado de Segurança:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.time_collect_in_safe !== null) ? credential.time_collect_in_safe : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Credencial Publica:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential.is_credential_public) ? 'Sim' : 'Não'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Estrutura Organizacional:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential?.organizational_structure !== null) ? credential.organizational_structure : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={2} className={classes.credContainer}>
                                            <Typography gutterBottom className={classes.credLabel} variant="body1">
                                                Equipamento do Supervisor:
                                            </Typography>
                                            <Typography variant="body2" className={classes.subtitle1Edit}>
                                                {(credential.is_equipament_supervisor) ? 'Sim' : 'Não'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Fab
                    color="primary"
                    aria-label="add"
                    className={classes.fabButton}
                    href={`/credenciais/modificar/${credential.id}`}
                >
                    <EditIcon />
                </Fab>
            </main>
        </div>
    )

}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    block: {
        width: '100%',
    },
    components: {
        margin: theme.spacing(1, .5),
        border: '1px solid #dedede',
    },
    subtitle1Edit: {
        width: '100%',
        padding: '0.5%',
        fontWeight: '600',
        fontSize: '1.05rem',
        color: grey[800]
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    image: {
        width: 128,
        height: 128,
        backgroundColor: grey[500],
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    credContainer: {
        padding: '10px 15px',
        borderRadius: '8px',
    },
    credLabel: {
        fontWeight: '700',
        color: grey[500],
        fontSize: '1.2rem'
    }
}));
