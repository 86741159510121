import React from 'react';
import {
    TextField,
    MenuItem,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import HelpIcon from "@material-ui/icons/HelpOutline";
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

export default function Select({
    label,
    options,
    className,
    textFieldProps,
    id,
    tip,
    style,
}) {
    const classes = useStyles();

    return (
        <div className={className}>
            {!!label && (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <label className={classes.label}>
                        {label} {!!textFieldProps?.required && '*'}
                        {!!tip && (
                            <Tooltip title={tip} arrow>
                                <IconButton style={{ padding: 0 }}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </label>
                </div>
            )}
            <TextField
                {...textFieldProps}
                select
                variant="outlined"
                size="small"
                id={id}
                style={style}
                SelectProps={{
                    SelectDisplayProps: {
                        style: {
                            paddingTop: 8,
                            paddingBottom: 8,
                            backgroundColor: '#FFF',
                            borderRadius: 5,
                        },
                    },
                }}
            >
                {!!options && options.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    label: {
        display: 'block',
        fontWeight: 'bold',
        color: grey[500],
        marginBottom: theme.spacing(1),
        width: "100%"
    },
}));
