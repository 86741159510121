// Cores utilizadas no sistema.
const colors = {
    primary: '#100C2F',
    secondary: '#6C757D',
    success: '#43A047',
    danger: '#E53935',
    warning: '#FB8C00',
    info: '#17A2B8',

    dark: '#343A40',
    light: '#F8F9FA',

    white: '#FFF',
    black: '#000',

    regular: '#555',
    lightGray: '#ADB4BB',
    transparent: 'rgba(0, 0, 0, 0)',
};

export default colors;
