const number = {
    toFloat(value, digits) {
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 1,
            maximumFractionDigits: digits,
        });

        return formatter.format(value);
    },

    toPercentage(reference, total, digits) {
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: digits,
        });

        return (total !== 0) ? formatter.format(reference / total) : '0%';
    },
};

export default number;
