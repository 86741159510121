const config = {
    host: '199.231.166.66',
    port: 3001,
    relayHost: '199.231.166.66',
    relayPort: 3002,
    relayUrl: "",
    useSsl: false,
};

if (config.useSsl) {
    config.relayUrl = `wss://${config.relayHost}`;
} else {
    config.relayUrl = `ws://${config.relayHost}:${config.relayPort}`;
}

export default config;
