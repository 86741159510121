import { createContext, useEffect, useState } from "react";
import api from "../services/api";
import AlertNotification from "../components/AlertNotification";
import Modal from "../components/Modal";

export const WebNotificationContext = createContext();

export const WebNotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState({});
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const handleOpen = (notification) => {
        setOpen(!open);
        setSelectedNotification(notification);
    }

    const onShowed = async (notification) => {
        try {
            const { data } = await api.put(`/web_notification/showed/${notification.id}`);
            setRefresh(!refresh);
            setSelectedNotification({});
            setOpen(false);
            console.log(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                const { data } = await api.get('/web_notification/list');
                console.log(data)
                setNotifications(data?.UserWebNotifications ?? []);

            } catch (err) {
                console.log(err);
            }
        }
        loadData();
        return setInterval(loadData, 30000);

    }, [refresh])

    return (
        <WebNotificationContext.Provider value={console.log()} >
            {children}
            {!open ? (notifications?.length > 0 && notifications?.map(notification => (
                <AlertNotification key={notification.id} notification_web={notification} handleClose={() => onShowed(notification)} handleView={() => handleOpen(notification)} />
            ))) : (<Modal open={open} handleView={() => onShowed(selectedNotification)} notification_web={selectedNotification} />)}

        </WebNotificationContext.Provider>
    );
}





