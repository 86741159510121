import { useEffect, useRef, useContext, useState } from "react";

import {
    Search as SearchIcon
} from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";

import Input from "./Input";

import { ShortcutsContext } from "../providers/Shortcuts";
import { useDebounce } from "../hooks/useDebounce";

const InputSearch = ({
    label,
    className,
    inputComponent,
    textFieldProps,
    style,
    id,
    request,
    onChangeSearch, // used when the father component needs the debounced search
    pagination=[]
}) => {

    const { storeSystemShortcut, invalidateSystemShortcut } = useContext(ShortcutsContext);

    const inputRef = useRef(null);

    const [search, setSearch] = useState();
    const debouncedSearch = useDebounce(search, 850);

    useEffect(() => {
        request({ search })
        if (onChangeSearch) onChangeSearch(search);
        // eslint-disable-next-line
    }, [debouncedSearch, ...pagination])

    const toggleFocus = () => inputRef.current.focus();
    useEffect(() => {
        storeSystemShortcut({
            referrer: "INPUT-FOCUS",
            callback: () => {
                toggleFocus();
            }
        });

        return () => invalidateSystemShortcut("INPUT-FOCUS");
    }, [invalidateSystemShortcut, storeSystemShortcut]);

    return (
        <Input
            icon={<SearchIcon style={{ color: grey[400] }} />}
            iconPosition="start"
            ref={inputRef}
            label={label}
            className={className}
            inputComponent={inputComponent}
            textFieldProps={{
                ...textFieldProps,
                onChange: ({ target }) => setSearch(target.value)
            }}
            style={style}
            id={id}

        />
    );
};

export default InputSearch;