import React, { useState } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Typography,
} from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import InputSearch from '../../components/InputSearch';

import api from '../../services/api';

export default function ApiEndpointList() {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [endpoints, setEndpoints] = useState([]);
    const [total, setTotal] = useState(0);

    function updateRequest({ search }) {
        const loadData = async () => {
            try {
                console.log({ search, limit, page })
                const result = await api.get('/api_endpoint/list', {
                    params: {
                        search,
                        limit,
                        page
                    }
                });

                const { count, endpoints } = result.data;

                setEndpoints(endpoints);
                setTotal(count);

            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
    }

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Rotas de API
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <InputSearch
                            textFieldProps={{
                                placeholder: 'Buscar…',
                            }}
                            request={updateRequest}
                            pagination={[page, limit]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="endpoints list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Main Segment</TableCell>
                                        <TableCell className={classes.tableHead}>Sub Segment</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!endpoints && endpoints.map(endpoint => (
                                        <TableRow key={endpoint.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {endpoint.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {endpoint.main_segment}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {endpoint.sub_segment}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        marginTop: theme.spacing(1),
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        padding: theme.spacing(.5, 0),
        minWidth: 30,
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    buttonPermissions: {
        marginLeft: theme.spacing(2)
    },
    buttonSave: {
        marginTop: theme.spacing(2)
    },
    input: {
        marginTop: theme.spacing(1)
    }
}));
