import React from 'react';
import MaskedInput from 'react-text-mask';

import Input from './Input';

export default function InputTime({
    label,
    className,
    icon,
    iconPosition,
    textFieldProps,
    onValidFormat,
    onInvalidFormat,
}) {
    return (
        <Input
            label={label}
            className={className}
            icon={icon}
            iconPosition={iconPosition}
            inputComponent={TimeMask}
            textFieldProps={{
                ...textFieldProps,
                onChange: ({target}) => {
                    const pattern = /^(?:[0-1][0-9]|2[0-3]):([0-5][0-9])$/;
                    const value = target.value;

                    // if (value.match(pattern)) onValidFormat(value);
                    if (value === '') {
                        onValidFormat(value)
                    } else if (value.match(pattern)) {
                        onValidFormat(value)
                    };
                },
                onBlur: ({target}) => {
                    const pattern = /^(?:[0-1][0-9]|2[0-3]):([0-5][0-9])$/;

                    if (!target.value.match(pattern)) onInvalidFormat();
                },
            }}
        />
    );
};

const TimeMask = props => {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => inputRef(ref ? ref.inputElement : null)}
            mask={[/\d/, /\d/, ':', /\d/, /\d/]}
        />
    );
};
