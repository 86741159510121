import { createContext, useMemo, useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import api from '../services/api';

export const ShortcutsContext = createContext();

export const ShortcutsProvider = ({ children }) => {

    const history = useHistory();

    // Used to store the callbacks from the components used on system shortcuts
    const storedCallbacks = useRef({});

    // Used to store the keys registered on keydown event until the keyup event succeeds
    const [combo, setCombo] = useState([]);

    // Used to block the usage of shortcuts
    const [locked, setLocked] = useState(false);

    const toggleLockShortcuts = () => setLocked(!locked);

    const [useAssistant, setUseAssistant] = useState(false);
    const [inputAssistant, setInputAssistant] = useState("");

    /**
     * Toggles the Input Assistant used on Shortcut's page
     */
    const toggleAssistant = () => {
        setUseAssistant(!useAssistant);
        setLocked(!locked);
    };

    const resetAssistant = () => setInputAssistant("");

    const shortcuts = useMemo(() => ({}), []);

    /**
     * Creates a Redirection Shortcut
     * @param {Object} RedirectionShortcutData - Data associated with the shortcut
     * @param {string} RedirectionShortcutData.keybinding - The shortcut's keybinding
     * @param {string} RedirectionShortcutData.action - The action URL which the user will be redirected to
     */
    const createRedirectionshortcut = useCallback(({ keybinding, action }) => {
        shortcuts[keybinding] = {
            callback() {
                history.push(action);
            }
        };
    }, [history, shortcuts]);

    /**
     * Creates a System Shortcut
     * @param {Object} SystemShortcutData - Data associated with the shortcut
     * @param {string} SystemShortcutData.keybinding - The shortcut's keybinding
     * @param {string} SystemShortcutData.referrer - The shortcut's referrer. Must be the same used on the component.
     */
    const createSystemShortcut = useCallback(({ keybinding, referrer, }) => {
        const newBinding = {
            referrer: referrer,
            callback: storedCallbacks.current[referrer],
        };

        shortcuts[keybinding] = newBinding;
    }, [shortcuts]);

    /**
     * Stores the system shortcut data to be retrieved when creating a new one
     * @param {Object} SystemShortcutData Data for the Systems' shortcut
     * @param {string} SystemShortcutData.referrer The shortcut referrer
     * @param {callback} SystemShortcutData.callback The callback provided by the component
     */
    const storeSystemShortcut = useCallback(({ referrer, callback }) => {
        const alreadyCreated = storedCallbacks.current[referrer] ? true : false;

        if (!alreadyCreated) {
            for (const shortcut in shortcuts) {
                if (shortcuts[shortcut]?.referrer === referrer) {
                    shortcuts[shortcut] = {
                        ...shortcuts[shortcut],
                        callback: callback,
                    };
                    break;
                }
            }
        }

        storedCallbacks.current = { ...storedCallbacks?.current, [referrer]: callback }
    }, [shortcuts]);

    /**
     * Invalidates the component reference when the useEffect returns. (when the page has changed)
     * @param {string} referrer The referrer used on the database must be the same as the one stored in the component.
     */
    const invalidateSystemShortcut = (referrer) => {
        storedCallbacks.current = { ...storedCallbacks?.current, [referrer]: undefined };
        for (const shortcut in shortcuts) {
            if (shortcuts[shortcut]?.referrer === referrer) {
                shortcuts[shortcut] = {
                    ...shortcuts[shortcut],
                    callback: undefined,
                };
                break;
            }
        }
    }

    /**
     * Removes the shortcut from the global object
     * @param {string} keybinding - The shortcut's keybinding
     */
    const removeShortcut = (keybinding) => {
        let shortcutKeys = Object.keys(shortcuts);

        for (const shortcut of shortcutKeys) {
            if (shortcut === keybinding) {
                delete shortcuts[shortcut];
                break;
            }
        }
    };

    /**
     * Create System Shortcut
     * @param {Object} ShortcutData - Data associated with the shortcut
     * @param {string} ShortcutData.previousKeybinding - The shortcut's previous keybinding
     * @param {string} ShortcutData.currentKeybinding - The shortcut's current keybinding
     * @param {string} ShortcutData.type - The Shortcut's type (system or redirection)
     * @param {Object} ShortcutData.data - Data used to update the shortcut
     * @param {boolean} ShortcutData.data.active - Whether the shortcut is active or not
     * @param {string} ShortcutData.data.value - The value that will the define the redirection. Action URL for redirection, referrer for system.
     */
    const updateShortcut = ({ previousKeybinding, currentKeybinding, type, data }) => {
        switch (type) {
            case "system":
                removeShortcut(previousKeybinding);
                if (data.active) {
                    createSystemShortcut({
                        keybinding: currentKeybinding,
                        referrer: data.value,
                    });
                }
                break;
            case "redirection":
                removeShortcut(previousKeybinding);
                if (data.active) {
                    createRedirectionshortcut({
                        keybinding: currentKeybinding,
                        action: data.value,
                    });
                }
                break;
            default: return;
        }
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get('/shortcut/list-all');

                const databaseShortcuts = result?.data;

                for (const shortcut of databaseShortcuts) {
                    if (shortcut.type === "redirection") {
                        createRedirectionshortcut({
                            keybinding: shortcut.keybinding,
                            action: shortcut.functionality?.router,
                        });
                    } else if (shortcut.type === "system") {
                        createSystemShortcut(shortcut);
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
    }, [createSystemShortcut, createRedirectionshortcut]);

    
    /**
     * @param {Object} event - The DOM Event Object
     */
    const handleKeyPress = useCallback((event) => {

        if (!locked) {

            if (event.type === "keydown") {
                setCombo((prev) => {
                    let newCombo = Object.assign([], prev);
                    newCombo.push(event.key);
                    return newCombo;
                });
            }

            if (event.type === "keyup") {
                const comboKey = combo.map(key => key?.toUpperCase())?.join("+");
                setCombo([]);
                if (comboKey) shortcuts[comboKey]?.callback && shortcuts[comboKey]?.callback();
            }

        } else if (locked && useAssistant) {
            if (event.type === "keydown") {
                let newCombo = Object.assign([], combo);
                if (!newCombo.length || !newCombo.includes(event.key)) {
                    newCombo.push(event.key);
                    setCombo(newCombo);
                    setInputAssistant(newCombo.join("+").toUpperCase())
                };
            }

            if (event.type === "keyup") {
                setCombo([]);
            }
        }

    }, [locked, useAssistant, combo, shortcuts]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        document.addEventListener("keyup", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
            document.removeEventListener("keyup", handleKeyPress);
        }
    }, [handleKeyPress]);

    return (
        <ShortcutsContext.Provider
            value={{
                storeSystemShortcut,
                invalidateSystemShortcut,
                removeShortcut,
                toggleLockShortcuts,
                useAssistant,
                inputAssistant,
                toggleAssistant,
                resetAssistant,
                updateShortcut,
                createRedirectionshortcut,
            }}
        >
            {
                children
            }
        </ShortcutsContext.Provider>
    );
}