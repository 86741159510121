import React, { useState, useEffect, useContext } from 'react';
import { useVehicleTypes } from '../../hooks';
import fileDownloader from 'js-file-download';
import Loader from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Chip,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
} from '@material-ui/core';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CachedIcon from '@material-ui/icons/Cached';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@material-ui/core/styles';
import { grey, green, orange, red } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import DateTimePicker from '../../components/DateTimePicker';
import FullScreenDialog from '../../components/FullScreenDialog';
import Alert from '../../components/Alert';
import Select from '../../components/Select';
import Autocomplete from '../../components/Autocomplete';
import PopUpDetailsVehicleTable from '../../components/PopUpDetailsVehicleTable';
import PopUpCreateVehicle from '../../components/PopUpCreateVehicle';

import api from '../../services/api';
import date from '../../utils/date';
import config from '../../configs/config';

import { metrics } from '../../assets/styles';
import Input from '../../components/Input';
import { PermissionsContext } from '../../providers/Permissions';

const { host, port } = config;

export default function VehicleReport() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const location = useLocation();
    const { routeStartTime, routeEndTime, routeCheckTime } = (location && location.state) || {};

    const [total, setTotal] = useState(0);
    const [vehicleLogs, setVehicleLogs] = useState([]);
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [vehicleLog, setVehicleLog] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [openViewPopup, setOpenViewPopup] = useState(false);
    const [selectLog, setSelectLog] = useState({})
    const [openPopupCreate, setOpenPopupCreate] = useState(false);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });
    const [openFilterPopup, setOpenFilterPopup] = useState(false);
    const [openTableEditPopup, setOpenTableEditPopup] = useState(false);

    const [type, setType] = useState();

    const [vehicleModel, setVehicleModel] = useState(null);
    const [vehicleBrand, setVehicleBrand] = useState(null);
    const [vehicleColor, setVehicleColor] = useState(null);
    const [vehiclePlate, setVehiclePlate] = useState(null);

    const [userDetails, setUserDetails] = useState([]);

    const [openExportPopup, setOpenExportPopup] = useState(false);


    const [place, setPlace] = useState(null);
    const [places, setPlaces] = useState([]);
    const [placeSearch, setPlaceSearch] = useState('');

    const [startTime, setStartTime] = useState((routeStartTime) ? (routeStartTime) : null);
    const [endTime, setEndTime] = useState((routeEndTime) ? (routeEndTime) : null);
    const [selection, setSelection] = useState();
    const [rejectionStatus, setRejectionStatus] = useState(null);
    const [search, setSearch] = useState(false);

    const [exporting, setExporting] = useState(false);

    const [searchVehicleTypes, setSearchVehicleTypes] = useState('');
    const [selectedVehicleType, setSelectedVehicleType] = useState({});
    const vehicleTypes = useVehicleTypes({ search: searchVehicleTypes, show_exceptions: true });

    const [checkedFilters, setCheckedFilters] = useState({
        checkType: false,
        checkModel: false,
        checkColor: false,
        checkPlate: false,
        checkBrand: false,
        checkPlace: false,
        checkTime: (routeCheckTime) ? true : false,
        checkSelection: false,
        checkRejectionStatus: false,
        checkVehicleType: false,
    });

    const optionsColors = [
        { label: 'Amarelo', value: ['Yellow', 'Amarelo'] },
        { label: 'Azul', value: ['Blue', 'Azul'] },
        { label: 'Branco', value: ['White', 'Branco'] },
        { label: 'Cinza', value: ['Gray', 'Cinza'] },
        { label: 'Dourado', value: ['Gold', 'Dourado'] },
        { label: 'Laranja', value: ['Orange', 'Laranja'] },
        { label: 'Laranja escuro', value: ['Darkorange', 'Laranja escuro'] },
        { label: 'Marrom', value: ['Brown', 'Marrom'] },
        { label: 'Prata', value: ['Silver', 'Prata'] },
        { label: 'Preto', value: ['Black', 'Preto'] },
        { label: 'Rosa', value: ['Pink', 'Rosa'] },
        { label: 'Roxo', value: ['Purple', 'Roxo'] },
        { label: 'Roxo escuro', value: ['Darkviolet', 'Roxo escuro'] },
        { label: 'Tomate', value: ['Tomato', 'Tomate'] },
        { label: 'Verde', value: ['Green', 'Verde'] },
        { label: 'Verde-oliva', value: ['Olive', 'Verde-oliva'] },
        { label: 'Verde-oliva escuro', value: ['Darkolivegreen', 'Verde-oliva'] },
        { label: 'Vermelho', value: ['Red', 'Vermelho'] },
        { label: 'Outra', value: ['Other', 'Outra'] },
        { label: 'Desconhecida', value: ['Unknown', 'Desconhecida'] },
        // { label: 'Amarelo', value: ['Mistyrose', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Whitesmoke', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Dimgray', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Maroon', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Chartreuse', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Greenyellow', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Forestgreen', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Seagreen', 'Amarelo'] },
        // { label: 'Amarelo', value: ['Deepskyblue', 'Amarelo'] },
    ];

    const [tableFields, setTableFields] = useState({
        showCode: false,
        showDeviceName: true,
        showLocation: true,
        showAction: true,
        showDateTime: true,
        showVideo: false,
        showAccess: true,
        showRejectionStatus: true,
        showVehicleType: true,
        showPlateNumber: true,
        showPlateColor: false,
        showPlateType: false,
        showCategory: false,
        showVehicleSign: true,
        showVehicleColor: true,
        showVehicleSize: false,
        showCountry: false,
        showInfo: true,
    });

    const handleCheck = (prop) => {
        let copyCheckedFilters = Object.assign({}, checkedFilters);
        copyCheckedFilters[prop] = !copyCheckedFilters[prop];
        setCheckedFilters(copyCheckedFilters);
    };

    const handleTableFields = (prop) => {
        let copyTableFields = Object.assign({}, tableFields);
        copyTableFields[prop] = !copyTableFields[prop];
        setTableFields(copyTableFields);
    };

    const handleOpenModel = (log) => {
        setSelectLog(log);
        setOpenPopupCreate(true);
    };

    const exportToCSV = async () => {
        try {
            if (vehicleLogs.length === 0) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: 'Sem dados para exportação',
                });
                return;
            }

            let datetime = Date.now();
            let data = {
                params: {
                    type: (checkedFilters?.checkType) ? type : null,
                    model: (checkedFilters.checkModel) ? vehicleModel : null,
                    color: (checkedFilters?.checkColor) ? vehicleColor : null,
                    plate_number: (checkedFilters?.checkPlate) ? vehiclePlate : null,
                    place: (checkedFilters?.checkPlace) ? place.name : null,
                    start_time: (checkedFilters?.checkTime) ? startTime : null,
                    end_time: (checkedFilters?.checkTime) ? endTime : null,
                    selection: (checkedFilters?.checkSelection) ? selection : null,
                    rejection_status: (checkedFilters?.checkRejectionStatus) ? rejectionStatus : null,
                    vehicle_type: (checkedFilters?.checkVehicleType) ? selectedVehicleType?.id : null,
                    limit: limit,
                    page: page,
                },
                datetime: datetime
            };
            console.log('startTime: ', startTime);

            setExporting(true);
            await api.post('/vehicle_report/export', data).then(async (res) => {
                await fileDownloader(res.data, `report_${datetime}.csv`);
            });
            setExporting(false);
            setOpenExportPopup(false);
        } catch (err) {
            console.log(err)
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
            setExporting(false);
            setOpenExportPopup(false);
        }
    };
    const timeSet = (time) => {
        let currentISO = date().toISOString();
        let currentDate = new Date(currentISO);
        let start = null;
        let end = currentISO;
        switch (time) {
            case '24h':
                currentDate.setHours(currentDate.getHours() - 24);
                start = currentDate.toISOString();
                break;
            case 'today':
                currentDate.setHours(0, 0, 0, 0);
                start = currentDate.toISOString();
                break;
            case '7d':
                currentDate.setDate(currentDate.getDate() - 7);
                start = currentDate.toISOString();
                break;
            default:
                end = null;
        }
        setStartTime(start);
        setEndTime(end);
    }

    const viewVehicle = async (id) => {
        try {

            const result = await api.get(`/vehicle/list/${id}`);
            const { vehicles: requestedVehicle } = result.data;
            setUserDetails(requestedVehicle);

        } catch (err) {
            console.log(err)
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    useEffect(() => {
        const loadData = async () => {
            try {

                const result = await api.get('/vehicle_report/list', {
                    params: {
                        type: (checkedFilters?.checkType) ? type : null,
                        model: (checkedFilters.checkModel) ? vehicleModel : null,
                        brand: (checkedFilters.checkBrand) ? vehicleBrand : null,
                        color: (checkedFilters?.checkColor) ? vehicleColor : null,
                        plate_number: (checkedFilters?.checkPlate) ? vehiclePlate : null,
                        place: (checkedFilters?.checkPlace) ? place.name : null,
                        start_time: (checkedFilters?.checkTime) ? startTime : null,
                        end_time: (checkedFilters?.checkTime) ? endTime : null,
                        selection: (checkedFilters?.checkSelection) ? selection : null,
                        rejection_status: (checkedFilters?.checkRejectionStatus) ? rejectionStatus : null,
                        vehicle_type: (checkedFilters?.checkVehicleType) ? selectedVehicleType?.id : null,
                        limit: limit,
                        page: page,
                    },
                });

                const { count, access_log } = result.data;
                if (total > count) setPage(0);
                console.log()

                setVehicleLogs(access_log);
                setTotal(count);

            }
            catch (err) {
                console.log(err);
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            }
        };

        loadData();

        const load = setInterval(loadData, 10000);

        return () => {
            clearInterval(load);
        };
        // eslint-disable-next-line
    }, [page, limit, search, total]);


    useEffect(() => {
        const findPlace = async () => {
            try {
                const result = await api.get('/vehicle_report/filter', {
                    params: {
                        search_type: 'place',
                        place_name: placeSearch,
                    },
                });

                const { results } = result.data;

                setPlaces(results);
            }
            catch (err) {
                console.log(err)
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            }
        };

        findPlace();
    }, [placeSearch]);

    const handleView = (id) => {
        setOpenViewPopup(true);
        viewVehicle(id);
    };

    const watchVideo = async vehicleLog => {
        setLoadingVideo(true);
        setVehicleLog(vehicleLog);

        try {
            const result = await api.get(`/vehicle_report/video/${vehicleLog.id}`);

            const { video } = result.data;

            const videoPath = `http://${host}:${port}/midias/video/acesso/${video}`;

            setVideoUrl(videoPath);
            setLoadingVideo(false);
        }
        catch (err) {
            console.log(err)
            clearVideo();

            setMessage({
                open: true,
                variant: 'error',
                text: 'Não foi possível baixar o vídeo',
            });
        }
    };

    const clearVideo = () => {
        setVehicleLog(null);
        setVideoUrl('');
        setLoadingVideo(false);
    };

    const getColor = (color) => {
        let returning;
        switch (color) {
            case 'Unknown':
                returning = 'Desconhecida';
                break;
            case 'Pink':
                returning = 'Rosa';
                break;
            case 'Silver':
                returning = 'Prata';
                break;
            case 'Black':
                returning = 'Preto';
                break;
            case 'White':
                returning = 'Branco';
                break;
            case 'Orange':
                returning = 'Laranja';
                break;
            case 'Brown':
                returning = 'Marrom';
                break;
            case 'Red':
                returning = 'Vermelho';
                break;
            case 'Yellow':
                returning = 'Amarelo';
                break;
            case 'Gray':
                returning = 'Cinza';
                break;
            case 'Blue':
                returning = 'Azul';
                break;
            case 'Green':
                returning = 'Verde';
                break;
            default:
                returning = 'Desconhecida';
        }

        return returning;
    };

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const accessStatus = ({ type, allowed }) => {
        switch (type) {
            case 'entry':
                return `Entrada ${allowed ? 'permitida' : 'negada'}`;
            case 'exit':
                return `Saída ${allowed ? 'permitida' : 'negada'}`;
            default:
                return `Acesso interno ${allowed ? 'permitido' : 'negado'}`;
        }
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const vehicleTypeName = (id) => {
        let result = ''
        if (!id)
            return "Não informado"

        vehicleTypes.forEach(current => {
            if (current.id === id) {
                result = current.name;
            }
        });

        return result;
    };


    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Relatório Veículos (Acesso e Monitoramento)
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} className={classes.filter}>
                        <Grid item style={{ marginRight: '1%' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenFilterPopup(true)}
                            >
                                Adcionar/Editar Filtros
                            </Button>
                        </Grid>
                        <PermissionWrapper route="/relatorio_veiculos/exportar">
                            <Grid item style={{ marginRight: '1%' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setOpenExportPopup(true);
                                        exportToCSV();
                                    }}
                                >
                                    Gerar CSV
                                </Button>
                            </Grid>
                        </PermissionWrapper>
                        <Grid item style={{ marginRight: '1%' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenTableEditPopup(true)}
                            >
                                Editar campos da tabela
                            </Button>
                        </Grid>
                        <Dialog open={openFilterPopup} onClose={() => setOpenFilterPopup(false)} maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
                            <DialogTitle className={classes.dialogTitle}>
                                <div style={{ display: 'flex' }}>
                                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }} className={classes.label}>
                                        Escolha o que deseja filtrar
                                    </Typography>
                                    <Button
                                        color="secondary"
                                        onClick={() => setOpenFilterPopup(false)}>
                                        <CloseIcon />
                                    </Button>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={1} style={{ display: 'flex', flexDirection: 'column' }}>

                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <Grid item>
                                                <Checkbox
                                                    checked={checkedFilters.checkPlace}
                                                    onChange={() => handleCheck('checkPlace')}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Localização
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Autocomplete
                                                placeholder="Localização"
                                                options={places}
                                                getOptionLabel={({ name }) => name || ''}
                                                onChange={(_, value) => setPlace(value)}
                                                getOptionSelected={(option, value) => (option.name === value.name)}
                                                onInputChange={(_, value) => setPlaceSearch(value)}
                                                value={place}
                                                fullWidth

                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <Grid item>
                                                <Checkbox
                                                    checked={checkedFilters.checkType}
                                                    onChange={() => handleCheck('checkType')}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Ação
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                    { label: 'Entrou', value: 'entry' },
                                                    { label: 'Presente', value: 'internal' },
                                                    { label: 'Saiu', value: 'exit' },
                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target }) => setType(target.value),
                                                    value: type,
                                                    fullWidth: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <Grid item>
                                                <Checkbox
                                                    checked={checkedFilters.checkModel}
                                                    onChange={() => handleCheck('checkModel')}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Modelo
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Input
                                                textFieldProps={{
                                                    placeholder: 'Modelo',
                                                    onChange: ({ target }) => setVehicleModel(target.value),
                                                    value: vehicleModel,
                                                    fullWidth: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <Grid item>
                                                <Checkbox
                                                    checked={checkedFilters.checkBrand}
                                                    onChange={() => handleCheck('checkBrand')}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Marca
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Input
                                                textFieldProps={{
                                                    placeholder: 'Marca',
                                                    onChange: ({ target }) => setVehicleBrand(target.value),
                                                    value: vehicleBrand,
                                                    fullWidth: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <Grid item>
                                                <Checkbox
                                                    checked={checkedFilters.checkColor}
                                                    onChange={() => handleCheck('checkColor')}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Cor
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Autocomplete
                                                options={optionsColors}
                                                getOptionLabel={({ label }) => label || ''}
                                                getOptionSelected={(option, value) => (option.label === value.label)}
                                                placeholder='Cor'
                                                fullWidth
                                                onChange={(_, value) => setVehicleColor(value?.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <Grid item>
                                                <Checkbox
                                                    checked={checkedFilters.checkPlate}
                                                    onChange={() => handleCheck('checkPlate')}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Placa
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Input
                                                textFieldProps={{
                                                    placeholder: 'Placa',
                                                    onChange: ({ target }) => setVehiclePlate(target.value),
                                                    value: vehiclePlate,
                                                    fullWidth: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <Grid item>
                                                <Checkbox
                                                    checked={checkedFilters.checkVehicleType}
                                                    onChange={() => handleCheck('checkVehicleType')}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Tipo de Veículo
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Autocomplete
                                                placeholder="Tipo de veículo" style={{ background: '#ECB365' }}
                                                options={vehicleTypes}
                                                getOptionLabel={({ name }) => name || ''}
                                                getOptionSelected={(option, value) => (option.name === value.name)}
                                                onInputChange={(_, value) => setSearchVehicleTypes(value)}
                                                fullWidth
                                                textFieldProps={{
                                                    onChange: (_, value) => setSelectedVehicleType(value),
                                                    value: selectedVehicleType,
                                                    fullWidth: true,
                                                    disabled: !checkedFilters.checkVehicleType
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <Grid item>
                                                <Checkbox
                                                    checked={checkedFilters.checkSelection}
                                                    onChange={() => handleCheck('checkSelection')}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Registros
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                    { label: 'Todos', value: 'all' },
                                                    { label: 'Primeiro e Último', value: 'first_last' },

                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target }) => setSelection(target.value),
                                                    value: selection,
                                                    fullWidth: true,

                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <Grid item>
                                                <Checkbox
                                                    checked={checkedFilters.checkRejectionStatus}
                                                    onChange={() => handleCheck('checkRejectionStatus')}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Detalhes
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                options={[
                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                    { label: 'Dia não autorizado', value: 'unauthorized_day' },
                                                    { label: 'Horário não autorizado', value: 'unauthorized_time' },
                                                    { label: 'Local não autorizado', value: 'unauthorized_location' },
                                                    { label: 'Veículo desconhecido', value: 'unknown_vehicle' },
                                                    { label: 'Entrada Repetida', value: 're_entry' },
                                                ]}
                                                textFieldProps={{
                                                    onChange: ({ target }) => setRejectionStatus(target.value),
                                                    value: rejectionStatus,
                                                    fullWidth: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} item className={classes.filterItem}>
                                        <Grid item xs={3} className={classes.checkboxContainer}>
                                            <Grid item>
                                                <Checkbox
                                                    checked={checkedFilters.checkTime}
                                                    onChange={() => handleCheck('checkTime')}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Período
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Grid item>
                                                <DateTimePicker
                                                    className={classes.dateTimeInput}
                                                    format="DD/MM/YYYY HH:mm"
                                                    value={startTime}
                                                    placeholder="Início"
                                                    onChange={date => setStartTime(date && date.format())}
                                                    cancelLabel="cancelar"

                                                    fullWidth={true}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <DateTimePicker
                                                    className={classes.dateTimeInput}
                                                    format="DD/MM/YYYY HH:mm"
                                                    value={endTime}
                                                    placeholder="Fim"
                                                    onChange={date => setEndTime(date && date.format())}
                                                    cancelLabel="cancelar"

                                                    fullWidth={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button variant='outlined' onClick={() => timeSet('today')}>
                                            Hoje
                                        </Button>
                                        <Button variant='outlined' onClick={() => timeSet('24h')}>
                                            Últimas 24 horas
                                        </Button>
                                        <Button variant='outlined' onClick={() => timeSet('7d')} >
                                            Últimos 7 dias
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='p' className={classes.label}>
                                            <i>
                                                Habilite os filtros que deseja usar. <br />
                                                Desabilite os que não mais desejar.
                                            </i>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setSearch(!search);
                                                setOpenFilterPopup(false);
                                            }}
                                        >
                                            Aplicar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table className={classes.table} stickyHeader size="small" aria-label="access history">
                            <TableHead>
                                <TableRow>
                                    {(tableFields.showCode) && (
                                        <TableCell className={classes.tableHead}>Código</TableCell>
                                    )}
                                    {(tableFields.showPlateNumber) && (
                                        <TableCell className={classes.tableHead}>Nº da Placa</TableCell>
                                    )}
                                    {(tableFields.showPlateColor) && (
                                        <TableCell className={classes.tableHead}>Cor da Placa</TableCell>
                                    )}
                                    {(tableFields.showPlateType) && (
                                        <TableCell className={classes.tableHead}>Tipo da Placa</TableCell>
                                    )}
                                    {(tableFields.showCategory) && (
                                        <TableCell className={classes.tableHead}>Tipo de Veículo</TableCell>
                                    )}
                                    {(tableFields.showVehicleSign) && (
                                        <TableCell className={classes.tableHead}>Modelo</TableCell>
                                    )}
                                    {(tableFields.showVehicleColor) && (
                                        <TableCell className={classes.tableHead}>Cor do Veículo</TableCell>
                                    )}
                                    {(tableFields.showVehicleSize) && (
                                        <TableCell className={classes.tableHead}>Tamanho do Veículo</TableCell>
                                    )}
                                    {(tableFields.showCountry) && (
                                        <TableCell className={classes.tableHead}>Pais</TableCell>
                                    )}

                                    {(tableFields.showDeviceName) && (
                                        <TableCell className={classes.tableHead}>Nome da Camera</TableCell>
                                    )}

                                    {(tableFields.showLocation) && (
                                        <TableCell className={classes.tableHead}>Localização</TableCell>
                                    )}
                                    {(tableFields.showAction) && (
                                        <TableCell className={classes.tableHead}>Ação</TableCell>
                                    )}

                                    {(tableFields.showDateTime) && (
                                        <TableCell className={classes.tableHead}>Horário</TableCell>
                                    )}
                                    {(tableFields.showAccess) && (
                                        <TableCell className={classes.tableHead}>Acesso</TableCell>
                                    )}
                                    <PermissionWrapper route="/relatorio_veiculos/video/:id">
                                        {(tableFields.showVideo) && (
                                            <TableCell className={classes.tableHead}>Vídeo</TableCell>
                                        )}
                                    </PermissionWrapper>
                                    {(tableFields.showRejectionStatus) && (
                                        <TableCell className={classes.tableHead}>Status</TableCell>
                                    )}
                                    {(tableFields.showInfo) && (
                                        <TableCell className={classes.tableHead}>Detalhes</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!vehicleLogs && vehicleLogs.map(access => (
                                    <TableRow key={access.id}>
                                        {(tableFields.showCode) && (
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {access ? access.id : '-'}
                                            </TableCell>
                                        )}

                                        {(tableFields.showPlateNumber) && (
                                            <TableCell className={classes.tableBody}>
                                                {access?.vehicle?.license_plate
                                                    ? access?.vehicle?.license_plate
                                                    : (access.plate_number ? access.plate_number
                                                        : <b>Não identificado</b>)
                                                }
                                            </TableCell>
                                        )}

                                        {(tableFields.showPlateColor) && (
                                            <TableCell className={classes.tableBody}>
                                                {access?.plate_color
                                                    ? access.plate_color
                                                    : <b>-</b>
                                                }

                                            </TableCell>
                                        )}

                                        {(tableFields.showPlateType) && (
                                            <TableCell className={classes.tableBody}>
                                                {access?.plate_type
                                                    ? access.plate_type
                                                    : <b>-</b>
                                                }
                                            </TableCell>
                                        )}

                                        {(tableFields.showCategory) && (
                                            <TableCell className={classes.tableBody}>
                                                {access?.vehicle?.vehicle_type_id
                                                    ? (vehicleTypeName(access?.vehicle?.vehicle_type_id))
                                                    : <b>Não identificado</b>
                                                }
                                            </TableCell>
                                        )}

                                        {(tableFields.showVehicleSign) && (
                                            <TableCell className={classes.tableBody}>
                                                {access?.vehicle?.model
                                                    ? access?.vehicle?.model
                                                    : (access.category ? access.category
                                                        : <b>Não identificado</b>)
                                                }
                                            </TableCell>
                                        )}

                                        {(tableFields.showVehicleColor) && (
                                            <TableCell className={classes.tableBody}>
                                                {access?.vehicle?.color
                                                    ? access?.vehicle?.color
                                                    : (access.vehicle_color ? (getColor(access.vehicle_color))
                                                        : <b>Não identificado</b>)
                                                }
                                            </TableCell>
                                        )}

                                        {(tableFields.showVehicleSize) && (
                                            <TableCell className={classes.tableBody}>
                                                {access?.vehicle_size
                                                    ? access.vehicle_size
                                                    : <b>-</b>
                                                }
                                            </TableCell>
                                        )}

                                        {(tableFields.showCountry) && (
                                            <TableCell className={classes.tableBody}>
                                                {access?.country
                                                    ? access?.country
                                                    : <b>-</b>
                                                }
                                            </TableCell>
                                        )}

                                        {(tableFields.showDeviceName) && (
                                            <TableCell className={classes.tableBody}>
                                                {(access.camera) ? (access.camera.name) : (access.Device) ? (access.Device.name) : ("-")}
                                            </TableCell>
                                        )}

                                        {(tableFields.showLocation) && (
                                            <TableCell className={classes.tableBody}>
                                                {(access.camera) ? (access.camera.place_cameras?.name) : (access.Device) ? (access.Device.place_devices?.name) : ("-")}
                                            </TableCell>
                                        )}

                                        {(tableFields.showAction) && (
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    access.action_type ? ((access.action_type === 'entry') ? ('Entrou') :
                                                        (access.action_type === 'internal') ? ('Presente') :
                                                            (access.action_type === 'exit') ? ('Saiu') :
                                                                ("-")) : '-'
                                                }
                                            </TableCell>
                                        )}

                                        {(tableFields.showDateTime) && (
                                            <TableCell className={classes.tableBody}>
                                                {access.event_time ? date(access.event_time).toLocaleFormat() : '-'}
                                            </TableCell>
                                        )}

                                        {(tableFields.showAccess) && (
                                            <TableCell className={classes.tableBody}>
                                                {access.allowed_access ? (
                                                    <Chip
                                                        icon={<CheckIcon className={classes.authorizationIcon} />}
                                                        label={accessStatus({
                                                            type: access.action_type,
                                                            allowed: access.allowed_access,
                                                        })}
                                                        size="small"
                                                        className={classes.authorizedAccess}
                                                    />
                                                ) : (
                                                    <Chip
                                                        icon={<CloseIcon className={classes.authorizationIcon} />}
                                                        label={accessStatus({
                                                            type: access.action_type,
                                                            allowed: access.allowed_access,
                                                        })}
                                                        size="small"
                                                        className={classes.unauthorizedAccess}
                                                    />
                                                )}
                                            </TableCell>
                                        )}

                                        <PermissionWrapper route="/relatorio_veiculos/video/:id">
                                            {(tableFields.showVideo) && (
                                                <TableCell className={classes.tableBody}>
                                                    {(access.camera !== null) ? ((vehicleLog && vehicleLog.id === access.id && loadingVideo) ? (
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            disabled
                                                            size="small"
                                                            className={classes.videoButton}
                                                            startIcon={<CachedIcon fontSize="small" />}
                                                        >
                                                            Carregando...
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            className={classes.videoButton}
                                                            startIcon={<PlayArrowIcon fontSize="small" />}
                                                            onClick={async () => await watchVideo(access)}
                                                        >
                                                            Visualizar
                                                        </Button>
                                                    )) : ('-')}
                                                </TableCell>
                                            )}
                                        </PermissionWrapper>

                                        {(tableFields.showRejectionStatus) && (
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    access.rejection_status ? (
                                                        access.rejection_status === 'unauthorized_day' ? 'DIA NÃO AUTORIZADO' :
                                                            access.rejection_status === 'unauthorized_time' ? 'HORA NÃO AUTORIZADA' :
                                                                access.rejection_status === 'unauthorized_location' ? 'LOCAL NÃO AUTORIZADO' :
                                                                    access.rejection_status === 'unknown_vehicle' ? 'VEÍCULO DESCONHECIDO' :
                                                                        access.rejection_status === 'wanted_vehicle' ? 'VEICULO PROCURADO' :
                                                                            access.rejection_status === 're_entry' ? 'ENTRADA REPETIDA' :
                                                                                '-'
                                                    ) : '-'
                                                }
                                            </TableCell>
                                        )}
                                        {(tableFields.showInfo) && (
                                            <TableCell className={classes.tableBody}>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    className={classes.actionButton}
                                                    onClick={() => (!access.vehicle_id) ? handleOpenModel(access) : handleView(access.vehicle_id)}
                                                    id={`view`}
                                                >
                                                    {console.log(access.vehicle_id)}
                                                    {(access.vehicle_id) ? <DirectionsCarIcon style={{ fontSize: 20 }} /> : <AddIcon style={{ fontSize: 20 }} />}
                                                </Button>
                                            </TableCell>
                                        )}


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={total}
                        rowsPerPage={limit}
                        page={page}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                        labelRowsPerPage="Registros por página"
                        onChangePage={changePage}
                        onChangeRowsPerPage={quantityPerPage}
                    />
                </Grid>
                <Dialog open={openExportPopup} maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
                    <DialogTitle className={classes.dialogTitle}>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="h6" component="div" style={{ flexGrow: 1 }} className={classes.label}>
                                Gerando Relatório
                            </Typography>
                            <Button
                                color="secondary"
                                onClick={() => setOpenExportPopup(false)}>
                                <CloseIcon />
                            </Button>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            {(openExportPopup === true && exporting === true) && (
                                <Grid item>
                                    <Loader type="Oval" color="#0078D7" height={80} width={80} />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                </Dialog>

                <Dialog open={openTableEditPopup} onClose={() => setOpenTableEditPopup(false)} maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
                    <DialogTitle className={classes.dialogTitle}>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="h6" component="div" style={{ flexGrow: 1 }} className={classes.label}>
                                Campos da tabela
                            </Typography>
                            <Button
                                color="secondary"
                                onClick={() => setOpenTableEditPopup(false)}>
                                <CloseIcon />
                            </Button>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid xs={12} container>
                            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showCode}
                                            onChange={() => handleTableFields('showCode')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Codigo
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showPlateNumber}
                                            onChange={() => handleTableFields('showPlateNumber')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Número da Placa
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showPlateColor}
                                            onChange={() => handleTableFields('showPlateColor')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Cor da Placa
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showPlateType}
                                            onChange={() => handleTableFields('showPlateType')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Tipo da placa
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showCategory}
                                            onChange={() => handleTableFields('showCategory')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Tipo do Veículo
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showVehicleSign}
                                            onChange={() => handleTableFields('showVehicleSign')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Modelo
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showVehicleColor}
                                            onChange={() => handleTableFields('showVehicleColor')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Cor do Veículo
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* right side */}

                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showVehicleSize}
                                            onChange={() => handleTableFields('showVehicleSize')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Tamanho do Veículo
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showCountry}
                                            onChange={() => handleTableFields('showCountry')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Pais
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showDeviceName}
                                            onChange={() => handleTableFields('showDeviceName')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Nome do dispositivo
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showLocation}
                                            onChange={() => handleTableFields('showLocation')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Localização
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showAction}
                                            onChange={() => handleTableFields('showAction')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Ação
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showDateTime}
                                            onChange={() => handleTableFields('showDateTime')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Horário
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showAccess}
                                            onChange={() => handleTableFields('showAccess')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Acesso
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showRejectionStatus}
                                            onChange={() => handleTableFields('showRejectionStatus')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Status
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <Grid item>
                                    <Checkbox
                                        checked={tableFields.showVideo}
                                        onChange={() => handleTableFields('showVideo')}
                                        color="primary"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Vídeo
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item xs={12} className={classes.checkboxContainer}>
                                    <Grid item>
                                        <Checkbox
                                            checked={tableFields.showInfo}
                                            onChange={() => handleTableFields('showInfo')}
                                            color="primary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Usuario
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <PopUpDetailsVehicleTable
                    user={userDetails}
                    open={openViewPopup}
                    setOpen={setOpenViewPopup}
                    title={'veículo do(s) usuario(s)'}
                />
                <FullScreenDialog
                    open={!!(videoUrl && !loadingVideo)}
                    title={vehicleLog && (
                        `${vehicleLog.camera.name}
                         (${vehicleLog.camera.device.name})`
                    )}
                    onClose={clearVideo}
                >
                    <video controls className={classes.video}>
                        <source src={videoUrl} />
                    </video>
                </FullScreenDialog>
                <PopUpCreateVehicle
                    access={selectLog}
                    open={openPopupCreate}
                    setOpen={setOpenPopupCreate}
                    title={'Veículo não cadastrado'}
                />

            </main>
            {!!message.open && (
                <Alert
                    open={message.open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    severity={message.variant}
                    onClose={closeMessage}
                >
                    {message.text}
                </Alert>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: `calc(100% - ${metrics.drawerWidth}px)`,
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    smallTitle: {
        color: grey[600],
        marginLeft: theme.spacing(1),
        fontSize: 16,
    },
    filter: {
        margin: theme.spacing(0, 0, 3, 0),
    },
    dateTimeInput: {
        marginRight: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: '95%',
        width: '100%',
    },
    table: {
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    registeredInSector: {
        color: green[700],
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: 11,
    },
    unregisteredInSector: {
        color: orange[700],
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: 11,
    },
    notRegistered: {
        color: red[700],
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: 11,
    },
    authorizedAccess: {
        backgroundColor: green[700],
        color: '#FFF',
    },
    unauthorizedAccess: {
        backgroundColor: red[700],
        color: '#FFF',
    },
    authorizationIcon: {
        color: '#FFF',
    },
    videoButton: {
        lineHeight: 1,
        '& span > svg': {
            marginRight: 2,
        },
    },
    video: {
        maxHeight: '100%',
        height: '100%',
        maxWidth: '100%',
        display: 'block',
        margin: 'auto',
    },
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: '60%',
    },
    dialogTitle: {
        paddingRight: '0px',
    },
    label: {
        color: '#9e9e9e',
        fontWeight: 'bold'
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    filterItem: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    }
}));
