import React, { useState, useEffect, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import SideMenu from '../../components/SideMenu';
import Input from '../../components/Input';
import Popup from '../../components/Popup';
import Alert from '../../components/Alert';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';

export default function SecurityPanelList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [securityPanels, setSecurityPanels] = useState([]);
    const [search, setSearch] = useState('');
    const [refresh, setRefresh] = useState(true)
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const [openDevicesInfoModal, setOpenDevicesInfoModal] = useState(false);
    const [openCamerasInfoModal, setOpenCamerasInfoModal] = useState(false);
    const [selectedSecurityPanel, setSelectedSecurityPanel] = useState({});

    const handleOpenInfoModal = (type, group) => {
        setSelectedSecurityPanel(group);

        switch (type) {
            case 'devices':
                setOpenDevicesInfoModal(true);
                break;
            case 'cameras':
                setOpenCamerasInfoModal(true);
                break;
            default: console.log('Invalid type');
        }
    }

    const handleCloseDevicesInfo = () => {
        setOpenDevicesInfoModal(false);
        setSelectedSecurityPanel({});
    };
    const handleCloseCamerasInfo = () => {
        setOpenCamerasInfoModal(false);
        setSelectedSecurityPanel({});
    }

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingPanelId, setDeletingPanelId] = useState();
    const handleDelete = (id) => {
        setDeletingPanelId(id);
        setOpenDeletePopup(true);
    };
    const deletePanel = async () => {
        setIsDeleting(true);
        try {
            const securityPanel = securityPanels.find((element) => element.id === deletingPanelId);

            const result = await api.delete(`/security_panel/delete/${deletingPanelId}`);
            const { status } = result;

            if (status === 200) {
                setDeletingPanelId(null);
                setOpenDeletePopup(false);
                setRefresh(!refresh);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Painel de Segurança "${securityPanel.name}" deletado com sucesso!`,
                });
            }
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
        setIsDeleting(false);
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get('/security_panel/list', {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });

                const { count, panels } = result.data;

                setSecurityPanels(panels);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error,
                });
            }
        };

        loadData();
    }, [search, refresh, page, limit]);

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Paineis de Segurança
                        </Typography>
                    </Grid>
                    <Grid item spacing={5} xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="security panels list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Criador</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivos</TableCell>
                                        <TableCell className={classes.tableHead}>Câmeras</TableCell>
                                        <PermissionOneOfWrapper
                                            routes={[
                                                "/paineis/modificar/:id",
                                                "/paineis/visualizar/:id",
                                                "/paineis/deletar/:id",
                                            ]}
                                        >
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {console.log({securityPanels})}
                                    {!!securityPanels && securityPanels.map(current => (
                                        <TableRow key={current.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {current.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    current.type === "access"
                                                        ? "Acesso"
                                                        : "Monitoramento"
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.owner?.name ?? "-"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    current.devices?.length && current.type === "access"
                                                        ? (
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => handleOpenInfoModal("devices", current)}
                                                            >
                                                                {current.devices.length ?? 0}
                                                            </Button>
                                                        )
                                                        : (
                                                            <Button
                                                                variant="contained"
                                                            >
                                                                -
                                                            </Button>
                                                        )
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    (current.cameras?.length || current.externalcams?.length) && current.type === "monitoring"
                                                        ? (
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => handleOpenInfoModal("cameras", current)}
                                                            >
                                                                { current.cameras.length + current.externalcams.length ?? 0}
                                                            </Button>
                                                        )
                                                        : (
                                                            <Button
                                                                variant="contained"
                                                            >
                                                                -
                                                            </Button>
                                                        )
                                                }
                                            </TableCell>
                                            <PermissionOneOfWrapper
                                                routes={[
                                                    "/paineis/modificar/:id",
                                                    "/paineis/visualizar/:id",
                                                    "/paineis/deletar/:id",
                                                ]}
                                            >
                                                <TableCell className={classes.tableBody}>
                                                    <>
                                                        <PermissionWrapper route="/paineis/modificar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                className={classes.actionButton}
                                                                href={`/paineis/modificar/${current.id}`}
                                                                id={`edit`}
                                                            >
                                                                <EditIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <PermissionWrapper route="/paineis/visualizar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                className={classes.actionButton}
                                                                href={`/paineis/visualizar/${current.id}`}
                                                                id={`edit`}
                                                            >
                                                                <AdminPanelSettingsIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <PermissionWrapper route="/paineis/deletar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="secondary"
                                                                className={classes.actionButton}
                                                                onClick={() => handleDelete(current.id)}
                                                                id={`delete`}
                                                            >
                                                                <DeleteIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                    </>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                <Popup
                    openPopup={openDeletePopup}
                    setOpenPopup={setOpenDeletePopup}
                    title="Atenção"
                >
                    <Grid container xs={12} style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 3% 2% 3%',
                        gap: "20px"
                    }}>
                        <Typography variant='h5'
                            style={{
                                fontWeight: 'bold',
                                color: '#696969',
                                textAlign: 'justify',
                                fontSize: '1.25rem'
                            }}

                        >
                            Deseja mesmo remover esse Painel de Segurança?
                        </Typography>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={12} md={6} style={{
                                marginTop: '10px'
                            }}>
                                <Button
                                    disabled={isDeleting}
                                    variant="contained"
                                    onClick={() => deletePanel()}
                                    color="secondary"
                                    id="confirm"
                                    fullWidth
                                >
                                    {isDeleting ? 'Deletando...' : 'SIM'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Popup>
                <Popup
                    openPopup={openDevicesInfoModal}
                    setOpenPopup={setOpenDevicesInfoModal}
                    title="Dispositivos"
                    onClose={handleCloseDevicesInfo}
                    width="45%"
                >
                    <Grid container>
                        {
                            selectedSecurityPanel?.devices?.map(device => (
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        style={{
                                            fontWeight: 700,
                                            color: "#696969"
                                        }}
                                    >
                                        &bull; {device.name} - {
                                            device.dhi_type === "entry" ? "Entrada" :
                                                device.dhi_type === "exit" ? "Saída" :
                                                    device.dhi_type === "hybrid" ? "Hibrido" :
                                                        "-"
                                        }
                                    </Typography>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Popup>
                <Popup
                    openPopup={openCamerasInfoModal}
                    setOpenPopup={setOpenDevicesInfoModal}
                    title="Câmeras"
                    onClose={handleCloseCamerasInfo}
                    width="45%"
                >
                <Grid spacing={4} container>
                    { !!selectedSecurityPanel?.cameras?.length && (
                  <Grid item xs={12}>
                      <Typography 
                       variant="h7" 
                       style={{
                          fontWeight: 700,
                       }}> 
                      Câmeras Embarcadas
                     </Typography>
                         <Grid container>
                             {
                                 selectedSecurityPanel?.cameras?.map(camera => (
                                     <Grid item xs={6}>
                                         <Typography
                                             variant="subtitle1"
                                             style={{
                                                 fontWeight: 700,
                                                 color: "#696969"
                                             }}
                                             >
                                             &bull; {camera.name} ({camera.device?.name})
                                         </Typography>
                                     </Grid>
                                 ))
                             }
                         </Grid>
                    </Grid>
                    )}
                
                  { !! selectedSecurityPanel?.externalcams?.length && (
                    <Grid item xs={12}>
                        <Typography
                        variant="h7"
                        style={{
                            fontWeight: 700,
                        }}>
                            Câmeras Externas
                        </Typography>
                            <Grid container>
                                {
                                selectedSecurityPanel?.externalcams?.map(camera => (
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                fontWeight: 700,
                                                color: "#696969"
                                            }}
                                        >
                                            &bull; {camera.name} ({camera.device_type})
                                        </Typography>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    )}
                </Grid>
                </Popup>
                <PermissionWrapper route="/paineis/criar">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href="/paineis/criar"
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
}));
