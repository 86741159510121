import React from 'react';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

export default function Alert(props) {
    return (
        <Snackbar {...props}>
            <CustomAlert {...props}>
                {props.children}
            </CustomAlert>
        </Snackbar>
    );
};

const CustomAlert = props => <MuiAlert elevation={6} variant="filled" {...props} />;
