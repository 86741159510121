import React, { useReducer, useState } from 'react';
import {
    Typography,
    Button,
    Grid,
    ButtonGroup,
} from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { grey, red } from '@material-ui/core/colors';

import Popup from './Popup';
import Select from './Select';
import Input from './Input';

const submitInitialState = {
    running: false,
    file: null,
};

const reduceExportModalSubmit = (state, action) => {
    switch (action.type) {
        case 'init':
            return {
                running: true,
                file: action.file,
            }
        case 'clear':
            return submitInitialState;
        default: return state;
    }
}

const ExportModal = ({ open = false, onClose, handleExport }) => {

    const [submit, dispatchSubmit] = useReducer(reduceExportModalSubmit, submitInitialState);
    const [exportDestination, setExportDestination] = useState("website");

    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");

    const onClickCSV = async () => {
        dispatchSubmit({
            type: 'init',
            file: 'csv',
        });

        await handleExport('csv', exportDestination, email);

        dispatchSubmit({
            type: 'clear',
        });
        onClose();
    };
    const onClickXLSX = async () => {
        dispatchSubmit({
            type: 'init',
            file: 'xlsx',
        });

        await handleExport('xlsx', exportDestination, email);

        dispatchSubmit({
            type: 'clear',
        });
        onClose();
    }
    const onClickPDF = async () => {
        dispatchSubmit({
            type: 'init',
            file: 'pdf',
        });

        await handleExport('pdf', exportDestination, email);

        dispatchSubmit({
            type: 'clear',
        });
        onClose();
    }

    const handleExportDestination = ({ target }) => {
        setExportDestination(target.value);
        setEmail("");
        setConfirmEmail("");
    }

    return (
        <Popup
            openPopup={open}
            onClose={onClose}
        >
            <Grid container justify='center' alignItems='center' style={{ gap: 16 }}>
                <Grid item xs={12}>
                    <Typography
                        variant='h6'
                        style={{ textAlign: 'center', color: grey[700] }}
                    >
                        Onde deseja receber a exportação?
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Select
                        options={[
                            { label: 'Via Aplicação (pagina atual)', value: 'website' },
                            { label: 'Via Email', value: 'email' },
                        ]}
                        style={{
                            fontSize: 24,
                            fontWeight: 'bold'
                        }}
                        textFieldProps={{
                            onChange: handleExportDestination,
                            value: exportDestination,
                            fullWidth: true,
                        }}
                    />
                </Grid>
                {
                    exportDestination === "email" && (
                        <>
                            <Grid item xs={8}>
                                <Input
                                    label="Email"
                                    textFieldProps={{
                                        placeholder: 'Email a receber o arquivo',
                                        value: email,
                                        onChange: ({ target }) => setEmail(target.value),
                                        fullWidth: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Input
                                    label="Confirmar email"
                                    textFieldProps={{
                                        placeholder: 'Confirmar o email a receber o arquivo',
                                        value: confirmEmail,
                                        onChange: ({ target }) => setConfirmEmail(target.value),
                                        fullWidth: true,
                                    }}
                                />
                            </Grid>
                            {
                                exportDestination === "email" && (
                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                        <Typography variant="subtitle1" style={{ color: red[900] }}>
                                            {
                                                email === "" && confirmEmail === "" ? (
                                                    "Insira e confirme o email a receber o arquivo"
                                                ) : email !== confirmEmail ? (
                                                    "Os emails não coincidem"
                                                ) : ""
                                            }
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </>
                    )
                }
                <Grid item xs={12}>
                    <Typography
                        variant='h6'
                        style={{ textAlign: 'center', color: grey[700] }}
                    >
                        Em que formato deseja a exportação?
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <ButtonGroup
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        disabled={
                            (
                                (exportDestination === "email" && email === "" && confirmEmail ==="") ||
                                (exportDestination === "email" && email !== confirmEmail)
                            ) ? true : false
                        }
                    >
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth={true}
                            style={{ padding: 8, width: "40%" }}
                            onClick={onClickCSV}
                            disabled={submit.running && submit.file === 'csv'}
                        >
                            <Typography
                                variant='h6'
                                style={{ textAlign: 'center' }}
                            >
                                CSV
                            </Typography>
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth={true}
                            style={{ padding: 8, width: "40%" }}
                            onClick={onClickXLSX}
                            disabled={submit.running && submit.file === 'xlsx'}
                        >
                            <Typography
                                variant='h6'
                                style={{ textAlign: 'center' }}
                            >
                                XLSX
                            </Typography>
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth={true}
                            style={{ padding: 8, width: "40%" }}
                            onClick={onClickPDF}
                            disabled={submit.running && submit.file === 'pdf'}
                        >
                            <Typography
                                variant='h6'
                                style={{ textAlign: 'center' }}
                            >
                                PDF
                            </Typography>
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Backdrop
                open={submit.running}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Typography
                    variant='h6'
                    style={{
                        textAlign: 'center',
                        userSelect: 'none',
                    }}
                >
                    <CircularProgress color='inherit' />
                    <br />
                    GERANDO A PLANILHA...
                </Typography>
            </Backdrop>
        </Popup>
    );
}

export default ExportModal;