import { Chip, Divider, Grid, Typography } from "@material-ui/core";
import Popup from "../../../components/Popup";

import { useEffect, useState } from "react";
import { Formik } from "formik";
import {
    Button,
    FormControl,
    Box,
} from "@material-ui/core";
import Select from "../../../components/Select";
import Input from "../../../components/Input";
import api from '../../../services/api';
import Autocomplete from "../../../components/Autocomplete";
import { grey } from "@material-ui/core/colors";

export default function OnvifExtraData({
    cameraId = null,
    openModal = false,
    onCloseModal = () => {},
    setMessage = () => {},
}) {

    const [camera, setCamera] = useState({
        name: "",
        main_stream_resolution: "",
        main_stream_encoding: "",
        sub_stream_resolution: "",
        sub_stream_encoding: "",
        ptz_support: false,
        capabilities: [],
        manufacturer: "",
        model: "",
        serial_number: "",
    });
    const [fetchingCamera, setFetchingCamera] = useState(false);

    useEffect(() => {
        (async () => {
            if (!cameraId || !openModal)
                return;
            setFetchingCamera(true);
            try {
                const response = await api.get(`/external_cam/view/${cameraId}`);

                setCamera(response.data);

            } catch (err) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: err.error ?? "Não foi possível visualizar a câmera",
                });
            }
            setFetchingCamera(false);
        })();

    }, [cameraId, openModal, setMessage]);

    return (
        <Popup
            openPopup={openModal}
            onClose={onCloseModal}
            title={"Detalhes da Câmera" + (camera.name ? ` "${camera.name}"`: "")}
        >
            {
                (fetchingCamera || cameraId === null || !openModal) ? (
                    "..."
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Input
                                label="Main Stream Resolution"
                                textFieldProps={{
                                    value: camera.main_stream_resolution,
                                    disabled: true,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <Input
                                label="Main Stream Encoding"
                                textFieldProps={{
                                    value: camera.main_stream_encoding,
                                    disabled: true,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <Input
                                label="Sub Stream Resolution"
                                textFieldProps={{
                                    value: camera.sub_stream_resolution,
                                    disabled: true,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <Input
                                label="Sub Stream Encoding"
                                textFieldProps={{
                                    value: camera.sub_stream_encoding,
                                    disabled: true,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Input
                                label="Manufacturer"
                                textFieldProps={{
                                    value: camera.manufacturer,
                                    disabled: true,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Input
                                label="Model"
                                textFieldProps={{
                                    value: camera.model,
                                    disabled: true,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Input
                                label="Serial Number"
                                textFieldProps={{
                                    value: camera.serial_number,
                                    disabled: true,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Input
                                label="PTZ"
                                textFieldProps={{
                                    value: camera.ptz_support ? "SIM" : "NÃO",
                                    disabled: true,
                                    fullWidth: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {
                                    (camera?.capabilities ?? []).map((capability) => (
                                        <Grid item>
                                            <Chip
                                                size="small"
                                                label={(
                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: grey[800],
                                                        }}
                                                    >
                                                        {capability}
                                                    </Typography>
                                                )}
                                                style={{
                                                    boxShadow: "0px 0px 10px #cdcdcd"
                                                }}
                                            />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Popup >
    );
}
