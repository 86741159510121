import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    TextField,
} from '@material-ui/core';
import PopUpDetails from './PopUpDetails'
import api from '../services/api';

export default function PopUpDetailsTable({ user, open, setOpen, title }) {
const [vehicleTypes,setVehicleTypes] = useState([]);

     useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/vehicle_type/list', {
                    params: {
                        show_exceptions: true,
                        limit: 30,
                    }
                });

                const { vehicleTypes } = result.data;

                setVehicleTypes(vehicleTypes);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, []);

    const vehicleTypeName = (id) => {
        let result = ''
        if (!id)
            return "Não informado"

        vehicleTypes.forEach(current => {
            if (current.id === id) {
                result = current.name;
            }
        });

        return result;
    };
    return (
        <PopUpDetails
            openPopup={open}
            setOpenPopup={setOpen}
            title={`Detalhes do ${title} :`}>
            <Grid container justifyContent="center"
                alignItems="center" spacing={3}>
                <Grid item xs={6} md={4}>
                    <TextField
                        colorSecondary
                        label="ID"
                        value={`${user?.id}`}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        colorSecondary
                        label="Marca"
                        value={`${user?.car_brand}`}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        colorSecondary
                        label="Modelo"
                        value={`${user?.model}`}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        colorSecondary
                            label="Tipo"
                            value={`${vehicleTypeName(user?.vehicle_type_id)}`}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant='outlined'
                        />
                    </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        colorSecondary
                        label="Placa"
                        value={`${user?.license_plate}`}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <TextField
                        colorSecondary
                        label="Cor"
                        value={`${user?.color}`}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant='outlined'
                    />
                </Grid>
            </Grid>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell >ID</TableCell>
                        <TableCell >Usuários</TableCell>
                        <TableCell >Número</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {user?.users?.length && user?.users?.map(item => (
                        <TableRow key={item?.id}>
                            <TableCell>
                                {item?.id}
                            </TableCell>
                            <TableCell>
                                {item?.name}
                            </TableCell>
                            <TableCell>
                                {item?.phone}
                            </TableCell>
                        </TableRow>))}
                </TableBody>

            </Table>
        </PopUpDetails >
    );
}
