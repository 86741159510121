import DateFnsUtils from '@date-io/moment';
import {MuiPickersUtilsProvider, KeyboardTimePicker} from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';

export default function DateTime({
    label,
    placeholder,
    required,
    format,
    value,
    onChange,
    className,
    okLabel,
    cancelLabel,
    fullWidth = false
}) {
    const classes = useStyles();

    return (
        <div className={className}>
            {!!label && (
                <label className={classes.label}>
                    {label} {!!required && '*'}
                </label>
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    ampm={false}
                    variant="dialog"
                    inputVariant="outlined"
                    format={format}
                    required={required}
                    value={value}
                    onChange={onChange}
                    fullWidth={fullWidth}
                    inputProps={{
                        placeholder,
                        style: {
                            paddingTop: 8,
                            paddingBottom: 8,
                            backgroundColor: '#FFF',
                            borderRadius: 5,
                        },
                    }}
                    keyboardIcon={<AccessTimeIcon />}
                    InputAdornmentProps={{
                        position: 'start',
                    }}
                    InputProps={{
                        style: {
                            backgroundColor: '#FFF',
                        },
                    }}
                    KeyboardButtonProps={{
                        style: {
                            padding: 0,
                        },
                    }}
                    okLabel={okLabel}
                    cancelLabel={cancelLabel}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    label: {
        display: 'block',
        fontWeight: 'bold',
        color: grey[500],
        marginBottom: theme.spacing(1),
    },
}));
