import { useState, useEffect } from 'react';

import api from '../services/api';

const usePlaces = (props = {}) => {

    const [places, setPlaces] = useState([]);
    let { search = null } = props;

    useEffect(() => {
        const findPlace = async () => {
            try {
                const result = await api.get('/place/list', { params: { search } });
                const { places } = result.data;
                setPlaces(places);
                // console.log('places: ', places);
            } catch (err) {
                console.log(err.message);
            }
        }
        findPlace();
    }, [search]);

    return places;
}

export default usePlaces;