import { Chip, Divider, Grid, Typography } from "@material-ui/core";
import Popup from "../../../components/Popup";

import { useEffect, useState } from "react";
import { Formik } from "formik";
import {
    Button,
    FormControl,
    Box,
} from "@material-ui/core";
import Select from "../../../components/Select";
import Input from "../../../components/Input";
import api from '../../../services/api';
import Autocomplete from "../../../components/Autocomplete";
import { grey } from "@material-ui/core/colors";

export default function DeviceEditModal({
    selectedDevice,
    openModalEdit,
    setModalEdit,
    refresh,
    setRefresh,
    setMessage,
}) {
    const [connectionType, setConnectionType] = useState(selectedDevice.device_type);
    const [orgs, setOrgs] = useState([]);
    const [orgSearch, setOrgSearch] = useState('');
    const [org, setOrg] = useState(selectedDevice.org);

    useEffect(() => {
        const findOrg = async () => {
            try {
                const result = await api.get('/organizational_structure/index', {
                    params: { search: orgSearch },
                });

                const { orgs } = result.data;

                setOrgs(orgs);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findOrg();
    }, [orgSearch]);

    const initialValues = () => {
        switch (connectionType) {
            case "Eagle-Eye":
                return {
                    deviceName: selectedDevice.name,
                    zone: "",
                    enabled: selectedDevice.enabled,
                    mac_address: selectedDevice.mac_address,
                };
            case "Onvif":
                return {
                    deviceName: selectedDevice.name,
                    zone: "",
                    enabled: selectedDevice.enabled,
                    username: selectedDevice.username,
                    password: selectedDevice.password,
                    ip_address: selectedDevice.ip_address,
                    port: selectedDevice.port,
                    onvif_recording_stream: selectedDevice?.onvif_recording_stream ?? "main",
                    onvif_recording_condition: selectedDevice?.onvif_recording_condition ?? "every_moment",
                };
            case "RTSP":
                return {
                    deviceName: selectedDevice.name,
                    zone: "",
                    enabled: selectedDevice.enabled,
                    username: selectedDevice.username,
                    password: selectedDevice.password,
                    ip_address: selectedDevice.ip_address,
                    port: selectedDevice.port,
                };
        }
    }


    console.log(initialValues())

    const saveCustomInput = async (values) => {
        const { ...rest } = values

        try {
            const nonEmptyData = Object.entries(rest).reduce((acc, [key, value]) => {
                if (value !== null && value !== undefined) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            const { deviceName, enabled, zone, mac_address, port, password, ip_address, username } = nonEmptyData;

            const data = {
                name: deviceName,
                enabled: enabled,
                ip_address: ip_address,
                port: port,
                username: username,
                password: password,
                device_type: connectionType,
                mac_address: mac_address,
                onvif_recording_stream: values?.onvif_recording_stream && connectionType === "Onvif" ? values?.onvif_recording_stream : "main",
                onvif_recording_condition: values?.onvif_recording_condition && connectionType === "Onvif" ? values?.onvif_recording_condition : "every_moment",
            };

            await api.put(`/external_cam/update/${selectedDevice.id}`, data);
            setRefresh(!refresh);
            setMessage({
                open: true,
                variant: 'success',
                text: `Dispositivo externo de ID ${selectedDevice.id} editado com sucesso!`,
            });
            setModalEdit(false)
        }
        catch (err) {
            setRefresh(!refresh);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
            setModalEdit(false)
        }
    };

    const handleClose = () => {
        setModalEdit(false);
    };

    const handleConnectionTypeChange = (event, resetForm) => {
        setConnectionType(event.target.value);
        resetForm();
    };

    console.log({ org })

    return (
        <Popup
            openPopup={openModalEdit}
            setOpenPopup={setModalEdit}
            title="Edição de Câmeras"
        >
            <Box
                pb="4"
                display="flex"
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Formik
                    initialValues={initialValues()}
                    onSubmit={saveCustomInput}
                >
                    {({
                        values,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        resetForm,
                    }) => (
                        <>
                            <form id="deviceForm" onSubmit={handleSubmit}>
                                <FormControl>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Select
                                                label={"Selecione um tipo de conexão"}
                                                options={[
                                                    {
                                                        label: "Eagle-Eye",
                                                        value: "Eagle-Eye",
                                                    },
                                                    {
                                                        label: "Onvif",
                                                        value: "Onvif",
                                                    },
                                                    {
                                                        label: "RTSP",
                                                        value: "RTSP",
                                                    },
                                                ]}
                                                textFieldProps={{
                                                    fullWidth: true,
                                                    name: "deviceType",
                                                    value: connectionType,
                                                    onChange: (event) => {
                                                        handleConnectionTypeChange(
                                                            event,
                                                            resetForm
                                                        );
                                                    },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Input
                                                label="Nome do dispositivo:"
                                                textFieldProps={{
                                                    required: true,
                                                    name: "deviceName",
                                                    value: values.deviceName,
                                                    onChange:
                                                        handleChange,
                                                    fullWidth: true,
                                                }}
                                                placeholder="Nome do dispositivo"
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Select
                                                label={"Status"}
                                                options={[
                                                    {
                                                        label: "Ativado",
                                                        value: true,
                                                    },
                                                    {
                                                        label: "Desativado",
                                                        value: false,
                                                    },
                                                ]}
                                                textFieldProps={{
                                                    required: true,
                                                    fullWidth: true,
                                                    name: "enabled",
                                                    value: values.enabled,
                                                    onChange:
                                                        handleChange,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Autocomplete
                                                label="Organização Parente"
                                                placeholder="Administração"
                                                id="parent_organization"
                                                options={orgs}
                                                getOptionLabel={({ name }) => name || ''}
                                                onChange={(_, value) => setOrg(value)}
                                                getOptionSelected={(option, value) => (option.name === value.name)}
                                                onInputChange={(_, value) => setOrgSearch(value)}
                                                value={org}
                                                fullWidth
                                                required={(values.role === 'employee')}
                                            />
                                        </Grid>

                                        {
                                            (connectionType === "Eagle-Eye") && (
                                                <Grid item xs={4}>
                                                    <Input
                                                        label="Endereço MAC:"
                                                        textFieldProps={{
                                                            required: true,
                                                            name: "mac_address",
                                                            value: values.mac_address,
                                                            onChange:
                                                                handleChange,
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        }

                                        {
                                            (connectionType === "Onvif" || connectionType === "RTSP") && (
                                                <>
                                                    <Grid item xs={4}>
                                                        <Input
                                                            label="Nome do usuário:"
                                                            textFieldProps={{
                                                                required: true,
                                                                name: "username",
                                                                value: values.username,
                                                                onChange:
                                                                    handleChange,
                                                                fullWidth: true,
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}>
                                                        <Input
                                                            label="Senha:"
                                                            textFieldProps={{
                                                                required: true,
                                                                name: "password",
                                                                type: "password",
                                                                value: values.password,
                                                                onChange:
                                                                    handleChange,
                                                                fullWidth: true,
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}>
                                                        <Input
                                                            label="Endereço IP:"
                                                            textFieldProps={{
                                                                required: true,
                                                                name: "ip_address",
                                                                value: values.ip_address,
                                                                onChange:
                                                                    handleChange,
                                                                fullWidth: true,
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}>
                                                        <Input
                                                            label="Porta:"
                                                            textFieldProps={{
                                                                required: true,
                                                                name: "port",
                                                                value: values.port,
                                                                onChange:
                                                                    handleChange,
                                                                fullWidth: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        }

                                        {
                                            (connectionType === "Onvif") && (
                                                <>
                                                    <Grid item xs={4}>
                                                        <Select
                                                            label={"Stream de Gravação"}
                                                            options={[
                                                                {
                                                                    label: "Principal",
                                                                    value: "main",
                                                                },
                                                                {
                                                                    label: "Secundária",
                                                                    value: "sub",
                                                                },
                                                            ]}
                                                            textFieldProps={{
                                                                fullWidth: true,
                                                                name: "onvif_recording_stream",
                                                                value: values.onvif_recording_stream,
                                                                onChange:
                                                                    handleChange,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Select
                                                            label={"Condição de Gravação"}
                                                            options={[
                                                                {
                                                                    label: "Integral",
                                                                    value: "every_moment",
                                                                },
                                                                {
                                                                    label: "Detecção de Movimento",
                                                                    value: "motion_detection",
                                                                },
                                                            ]}
                                                            textFieldProps={{
                                                                fullWidth: true,
                                                                name: "onvif_recording_condition",
                                                                value: values.onvif_recording_condition,
                                                                onChange:
                                                                    handleChange,
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        }
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                                                <Grid item xs={4}>
                                                    <Button
                                                        disabled={connectionType === ""}
                                                        type="submit"
                                                        form="deviceForm"
                                                        color="primary"
                                                        variant="contained"
                                                        fullWidth={true}
                                                    >
                                                        Editar Dispositivo
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        onClick={handleClose}
                                                        variant="contained"
                                                        color="default"
                                                        fullWidth={true}
                                                    >
                                                        Cancelar
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </form>
                        </>
                    )}
                </Formik>
            </Box>
        </Popup>
    );
}
