import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button,
} from '@material-ui/core';
import MapPopup from '../../components/MultiPopup';
import Canvas from '../../components/Canvas';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Input from '../../components/Input';
import Alert from '../../components/Alert';
import Popup from '../../components/Popup';

import api from '../../services/api';

import pin from '../../assets/img/pin.png';
import { PermissionsContext } from '../../providers/Permissions';

export default function MapList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const [maps, setMaps] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [refresh, setRefresh] = useState(true)
    const [limit, setLimit] = useState(10);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openMapPopup, setOpenMapPopup] = useState({ index: null, value: false });

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingMapId, setDeletingMapId] = useState();
    const handleDelete = (id) => {
        setDeletingMapId(id);
        setOpenDeletePopup(true);
    }

    const deleteMap = async (id) => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/map/delete/${deletingMapId}`);

            const { success } = result.data;

            console.log(success);

            setIsDeleting(false);

            if (success) {
                setDeletingMapId(null);
                setOpenDeletePopup(false);
                setRefresh(!refresh);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Mapa deletado com sucesso!`,
                });
            }
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get('/map/list', {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });

                console.log(search, limit, page)
                //console.log(result.data)

                const { count, maps } = result.data;

                console.log(maps);

                setMaps(maps);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
    }, [page, limit, search, refresh]);

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    const [savedCams, setSavedCams] = useState([]);
    useEffect(() => {
        const findCam = async () => {
            try {
                const result = await api.get('/camera/list');

                const { cameras } = result.data;
                console.log("Cameras", cameras)

                setSavedCams(cameras);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findCam();
    }, []);


    const canvas = useRef(null)

    //   useLayoutEffect(()=>{
    //     if(canvas.current){
    //         canvas.current = null;
    //         return
    //     }
    //   })


    useLayoutEffect(() => {
        if (canvas && canvas !== null) {
            console.log('2312')
            // canvas.current.getContext('2d')
            // ctx.drawImage(image, 100, 313, 10, 10)
        }
    }, [canvas])


    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Lista de Mapas
                        </Typography>
                    </Grid>
                    <Grid item spacing={5} xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="access history">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            Ação
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!maps && maps.map((map, index) => (
                                        <TableRow key={map.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {map.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {map.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <>
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        size="small"
                                                        color="primary"
                                                        style={{ background: '#ECB365' }}
                                                        className={classes.actionButton}
                                                        onClick={() => setOpenMapPopup({ index: index, value: !openMapPopup['value'] })}
                                                    >
                                                        <VisibilityIcon style={{ fontSize: 20 }} />
                                                    </Button>
                                                    <PermissionWrapper route="/mapa/deletar/:id">
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            color="secondary"
                                                            className={classes.actionButton}
                                                            onClick={() => handleDelete(map.id)}
                                                        >
                                                            <DeleteIcon style={{ fontSize: 20 }} />
                                                        </Button>
                                                    </PermissionWrapper>
                                                </>
                                            </TableCell>
                                            <MapPopup
                                                openPopup={(openMapPopup['index'] === index) ? openMapPopup['value'] : false}
                                                setOpenPopup={setOpenMapPopup}
                                                cameraIndex={index}
                                                title={`${map.name}`}
                                            >
                                                <Grid container style={{ overflowX: 'hidden' }}>
                                                    <Grid xs={6} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Typography style={{ fontSize: '60px' }}>{map.name}</Typography>
                                                    </Grid>
                                                    <Grid xs={6} item style={{ paddingLeft: '70px' }}>
                                                        <div style={{ position: 'relative' }}>
                                                            <Canvas image={`${map.map_image}`} getCursorPos={() => null} />
                                                            {savedCams.map((cam, index) => {
                                                                if (cam.map_id === map.id) {
                                                                    console.log(cam.x_axis, cam.y_axis)
                                                                    return (
                                                                        <div key={index} className="pin" style={{ top: `${cam.y_axis}px`, left: `${cam.x_axis}px`, position: 'absolute', zIndex: 0 }} >
                                                                            <img src={pin} alt='' style={{ width: '30px' }} />
                                                                            <p style={{ top: `${35}px`, left: `${-10}px`, position: 'absolute', fontWeight: 'bold', textAlign: 'center' }}>{cam.name}</p>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </MapPopup>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                    <Popup
                        openPopup={openDeletePopup}
                        setOpenPopup={setOpenDeletePopup}

                    >
                        <Grid container xs={12} style={{
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0 3% 2% 3%',

                        }}>
                            <Typography variant='h5'
                                style={{
                                    fontWeight: 'bold',
                                    color: '#9e9e9e'
                                }}
                            >
                                Deseja mesmo deletar esse mapa ?
                            </Typography>
                            <Grid item style={{
                                marginTop: '50px'
                            }}>
                                <Button
                                    disabled={isDeleting}
                                    variant="contained"
                                    onClick={() => deleteMap()}
                                    color="secondary"
                                    endIcon={<DeleteIcon />}
                                >
                                    {isDeleting ? 'Deletando...' : 'Sim, delete o mapa'}
                                </Button>
                            </Grid>

                        </Grid>
                    </Popup>
                </Grid>
                <PermissionWrapper route="/mapa/criar">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href="/mapa/criar"
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}

            </main>
        </div>
    );


}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
}));
