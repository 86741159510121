import React, {useState, useEffect} from 'react';
import {Toolbar, Paper, Button, Grid, Typography} from '@material-ui/core';
import {useParams, useHistory} from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import {Formik} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Autocomplete from '../../components/Autocomplete';
import Input from '../../components/Input';

import api from '../../services/api';

export default function OrganModify() {
    const classes = useStyles();

    const [orgEdit, setOrgEdit] = useState({
        id: 0,
        name: '',
        parent_id: 0,
    });
    
    
    const [orgs, setOrgs] = useState([]);
    const [orgSearch, setOrgSearch] = useState('');
    const [org, setOrg] = useState(null);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const {id} = useParams();
    const history = useHistory();

    useEffect(() => {
        const loadOrg = async () => {
            try{
                const result = await api.get(`/organizational_structure/view/${id}`);

                const {org} = result.data;
                setOrgEdit(org);
                setOrg(org.father_node);
                console.log(org)

            }catch(err){
                console.log(err);
                history.goBack();
            }
        }

        loadOrg();
    },[id, history]);

    useEffect(() => {
        const findOrg = async () => {
            try {
                const result = await api.get('/organizational_structure/index', {
                    params: {search: orgSearch},
                });

                const {orgs} = result.data;
                
                setOrgs(orgs);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findOrg();
    }, [orgSearch])

    const saveOrgan = async (values, formik) => {
        try {
            if ((values.parent_id !== null) && (org === null)) {
                setMessage({
                    open: true,
                    variant: 'error',
                    text: 'A Organização Parente não pode ser nula'
                });
                formik.setSubmitting(false);
                return;
            }
            const orgData = {
                name:  values.name,
                parent_id: org?.id,
            };
            await api.post(`/organizational_structure/edit/${values.id}`, orgData);

            resetForm(formik);

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Organização atualizada com sucesso!`,
            });
        }
        catch (err) {
            console.log(err);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };


    const resetForm = formik => {
        formik.resetForm();
    };

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Editar organização
                        </Typography>
                    </Grid>
                    <Formik
                        enableReinitialize={true}
                        initialValues={orgEdit}
                        onSubmit={saveOrgan}
                    >
                        {({values, handleChange, handleSubmit, isSubmitting}) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form id="org-create" onSubmit={handleSubmit} className={classes.form}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={9}>
                                                    <Grid item xs={12} className={classes.formSection}>
                                                        <Typography variant="subtitle1" className={classes.formSectionTitle}>
                                                            DADOS ORGANIZACIONAIS
                                                        </Typography>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={8}>
                                                                <Input
                                                                    label="Nome"
                                                                    textFieldProps={{
                                                                        name: 'name',
                                                                        placeholder: 'Nome',
                                                                        onChange: handleChange,
                                                                        value: values.name,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Autocomplete
                                                                    label="Organização Parente"
                                                                    placeholder="Administração"
                                                                    options={orgs}
                                                                    getOptionLabel={({name}) => name || ''}
                                                                    onChange={(_, value) => setOrg(value)}
                                                                    getOptionSelected={(option, value) => (option.name === value.name)}
                                                                    onInputChange={(_, value) => setOrgSearch(value)}
                                                                    value={org}
                                                                    fullWidth
                                                                    required={(values.role === 'employee')}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} container justify="flex-end">
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        form="org-create"
                                        color="primary"
                                        endIcon={<SaveIcon />}
                                    >
                                        {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    faceImageContainer: {
        width: '100%',
        height: '264px',
        border: `solid 1px ${theme.palette.primary.dark}`,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    faceImageContent: {
        width: '100%',
        flex: 7,
        backgroundColor: grey[50],
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(.5),
    },
    faceImageButton: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderColor: 'transparent',
        borderTopColor: theme.palette.primary.dark,
        flex: 1,
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    noImage: {
        textAlign: 'center',
        color: grey[400],
    },
    userRoles: {
        '& > button': {
            marginRight: theme.spacing(1),
        },
    },
}));

