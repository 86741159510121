import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Toolbar, Paper, Button, Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { Formik } from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Popup from '../../components/Popup';
import Select from '../../components/Select';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';

export default function CustomInputModify() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const [customInput, setCustomInput] = useState({
        name: '',
        description: '',
        is_required: false,
        type: 'normal',
        target: 'all',
        options: [],
    });

    const [newOption, setNewOption] = useState('');
    const [openOptionsModal, setOpenOptionsModal] = useState(false);

    const { id } = useParams();
    const history = useHistory();

    const [openDeletePopup, setOpenDeletePopup] = useState(false);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    useEffect(() => {
        const LoadCustomInput = async () => {
            try {
                const result = await api.get(`/custom_input/detail/${id}`);

                const { custom_input } = result.data;

                setCustomInput(custom_input)
            } catch (err) {
                // history.goBack();
                console.log("ERROR: ", { err })
                setMessage({
                    open: true,
                    variant: "error",
                    text: err.error,
                });
            }
        };
        LoadCustomInput();
    }, [id, history]);

    const [isDeleting, setIsDeleting] = useState(false);
    const deleteCustomInput = async () => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/custom_input/delete/${id}`);

            const { success } = result.data;

            setIsDeleting(false);

            if (success) {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Campo: ${customInput.name} deletado com sucesso!`,
                });
            }
            history.goBack();
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    const saveCustomInput = async (values, formik) => {
        try {

            const data = { ...values };

            const result = await api.put(`/custom_input/modify/${id}`, data);

            const { custom_input } = result.data;
            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Campo: ${custom_input.name} atualizado com sucesso!`,
            });
        }
        catch (err) {
            console.log("ERROR: ", { err })
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const handleInsertNewOption = (word, insertedOptions, setFieldValue) => {
        const currentOptions = Object.assign([], insertedOptions);
        if (currentOptions.includes(word)) {
            setMessage({
                open: true,
                variant: 'error',
                text: "Essa opção ja existe",
            });
            return;
        }
        currentOptions.push(word);
        setFieldValue('options', currentOptions);
        setNewOption("")
    };

    const removeOption = (index, insertedOptions, setFieldValue) => {
        const currentOptions = Object.assign([], insertedOptions);
        currentOptions.splice(index, 1);
        setFieldValue('options', currentOptions);
    };

    const changeTarget = (value, setFieldValue) => {
        if (value === 'normal') {
            setFieldValue('options', []);
        }

        setFieldValue('type', value);
    }

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Editar campo
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={customInput}
                        enableReinitialize={true}
                        onSubmit={saveCustomInput}
                    >
                        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                            <>
                                <Grid container spacing={5}>
                                    <Grid spacing={5} item xs={12}>
                                        <Paper alevation={3} style={{ padding: '1.5%' }}>
                                            <form id="custom-input-modify" onSubmit={handleSubmit} className={classes.form}>
                                                <Grid container xs={12} spacing={5} justify='space-evenly'>
                                                    <Grid xs={12} item>
                                                        <Grid spacing={2} container>
                                                            <Grid item xs={12} md={4}>
                                                                <Input
                                                                    label="Nome"
                                                                    textFieldProps={{
                                                                        name: 'name',
                                                                        id: 'name',
                                                                        placeholder: 'Nome do campo',
                                                                        onChange: handleChange,
                                                                        value: values.name,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Input
                                                                    label="Descrição"
                                                                    textFieldProps={{
                                                                        name: 'description',
                                                                        id: 'description',
                                                                        placeholder: 'Descrição do campo',
                                                                        onChange: handleChange,
                                                                        value: values.description,
                                                                        fullWidth: true,
                                                                        required: false
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6} md={4}>
                                                                        <Select
                                                                            label='Formulário'
                                                                            options={[
                                                                                { label: 'Regular e Visitante', value: 'all' },
                                                                                { label: 'Regular', value: 'regular' },
                                                                                { label: 'Visitante', value: 'guest' },
                                                                                { label: 'Procurado', value: 'wanted', }
                                                                            ]}
                                                                            textFieldProps={{
                                                                                name: 'target',
                                                                                id: 'target',
                                                                                required: true,
                                                                                fullWidth: true,
                                                                                value: values.target,
                                                                                onChange: handleChange,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} md={8} style={{
                                                                        display: 'flex',
                                                                        alignItems: 'flex-end'
                                                                    }}>
                                                                        <FormControlLabel
                                                                            label='Obrigatoriedade'
                                                                            control={
                                                                                <Checkbox
                                                                                    name='enabledToRequired'
                                                                                    checked={values.is_required}
                                                                                    onChange={() => setFieldValue('is_required', !values.is_required)}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6} md={4}>
                                                                        <Select
                                                                            label='Tipo'
                                                                            options={[
                                                                                { label: 'Texto', value: 'normal' },
                                                                                { label: 'Seleção', value: 'select' },
                                                                            ]}
                                                                            textFieldProps={{
                                                                                name: 'type',
                                                                                id: 'type',
                                                                                required: true,
                                                                                fullWidth: true,
                                                                                value: values.type,
                                                                                onChange: ({ target }) => changeTarget(target.value, setFieldValue),
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                values.type === "select" && (
                                                                    <Grid item xs={12}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={6} md={4}>
                                                                                <Button
                                                                                    onClick={() => setOpenOptionsModal(true)}
                                                                                    variant='contained'
                                                                                    color='primary'
                                                                                    fullWidth={true}
                                                                                >
                                                                                    Adicionar Opções
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Popup
                                                        openPopup={openDeletePopup}
                                                        setOpenPopup={setOpenDeletePopup}

                                                    >
                                                        <Grid container xs={12} style={{
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            padding: '0 3% 2% 3%',

                                                        }}>
                                                            <Typography variant='h5'
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#9e9e9e'
                                                                }}
                                                            >
                                                                Deseja mesmo deletar o lugar: "{customInput.name}"?
                                                            </Typography>
                                                            <Grid item style={{
                                                                marginTop: '50px'
                                                            }}>
                                                                <Button
                                                                    disabled={isDeleting}
                                                                    variant="contained"
                                                                    onClick={() => deleteCustomInput()}
                                                                    color="secondary"
                                                                    endIcon={<DeleteIcon />}
                                                                >
                                                                    {isDeleting ? 'Deletando...' : 'Sim, delete esse campo'}
                                                                </Button>
                                                            </Grid>

                                                        </Grid>
                                                    </Popup>
                                                    <Grid item xs={12} style={{ margin: '0 1%' }} container justify="space-evenly">
                                                        <PermissionWrapper route="/custom_input/deletar/:id">
                                                            <Button
                                                                disabled={isDeleting}
                                                                variant="contained"
                                                                onClick={() => setOpenDeletePopup(true)}
                                                                color="secondary"
                                                                endIcon={<DeleteIcon />}
                                                            >
                                                                Deletar campo
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            variant="contained"
                                                            form="custom-input-modify"
                                                            color="primary"
                                                            endIcon={<SaveIcon />}
                                                        >
                                                            {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Popup
                                    title='Opções'
                                    openPopup={openOptionsModal}
                                    setOpenPopup={setOpenOptionsModal}
                                    width='35%'
                                >
                                    <Grid container direction='row' style={{ width: '100%' }}>
                                        <Grid item xs={12} style={{ display: 'flex' }}>
                                            <Grid item xs={6} style={{ marginRight: '5px' }}>
                                                <Input
                                                    textFieldProps={{
                                                        placeholder: 'Nova Opção',
                                                        onChange: ({ target }) => setNewOption(target.value),
                                                        value: newOption,
                                                        fullWidth: true,
                                                        required: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Button
                                                onClick={() => handleInsertNewOption(newOption, values.options, setFieldValue)}
                                                variant='contained'
                                                color='primary'
                                            >
                                                <AddIcon />
                                            </Button>
                                        </Grid>
                                        <Grid container xs={12} className={classes.keywordsContainer}>
                                            {
                                                values.options?.map((currentOption, index) => {
                                                    return (
                                                        <Grid key={index} item className={classes.keyword}>
                                                            <Typography className={classes.keywordTitle}>{currentOption}</Typography>
                                                            <button className={classes.keywordButton} onClick={() => removeOption(index, values.options, setFieldValue)}>
                                                                <CloseIcon />
                                                            </button>
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Popup>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    items_container: {
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    left_side: {
        borderBottom: 'solid 2px',
    },
    right_side: {

    },
    keywordsContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '15px 0',
    },
    keyword: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#cdcdcd',
        borderRadius: '5px',
        padding: '0 5px',
        boxSizing: 'border-box',
        margin: '0 0 5px 5px'
    },
    keywordTitle: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        color: grey[700],
        textTransform: 'uppercase'
    },
    keywordButton: {
        border: 'none',
        background: 'transparent',
        padding: '0',
        margin: '0',
        color: '#fff',
        cursor: 'pointer'
    }
}));
