import React from 'react';
import {TextField} from '@material-ui/core';
import {Autocomplete as MaterialAutocomplete} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';

export default function Autocomplete({
    label = "",
    placeholder = "",
    options,
    className,
    required = false,
    value,
    fullWidth,
    onChange,
    getOptionLabel,
    getOptionSelected,
    onInputChange, 
    disabled = false,
    id,
}) {
    const classes = useStyles();

    return (
        <div className={className}>
            {!!label && (
                <label className={classes.label}>
                    {label} {!!required && '*'}
                </label>
            )}
            <MaterialAutocomplete
                variant="outlined"
                size="small"
                value={value}
                onChange={onChange}
                options={options}
                getOptionLabel={getOptionLabel}
                onInputChange={onInputChange}
                id={id}
                getOptionSelected={getOptionSelected}
                disabled={disabled}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            style: {
                                backgroundColor: '#FFF',
                            }
                        }}
                        placeholder={placeholder}
                        fullWidth={fullWidth}
                        required={required}
                        inputProps={{
                            ...params.inputProps,
                            style: {
                                paddingTop: 2,
                                paddingBottom: 2,
                                backgroundColor: '#FFF',
                                borderRadius: 5,
                            },
                        }}
                        disabled={disabled}
                    />
                )}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    label: {
        display: 'block',
        fontWeight: 'bold',
        color: grey[500],
        marginBottom: theme.spacing(1),
    },
}));
