import React, { useState} from 'react';
import { Toolbar, Paper, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import SaveIcon from '@material-ui/icons/Save';
import { Formik } from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';

import api from '../../services/api';



export default function MapCreate


    () {
    const classes = useStyles();

    const initialValues = {
        name: '',
        map_image: null,
    }


    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const [image, setImage] = useState(null)



    function imageHandler(e, values) {
        console.log(e)
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result);
                values.map_image = e.target.files[0];
                console.log(e.target.files[0])
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        console.log("imagem: " + image)
    }

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };


    const resetForm = formik => {
        formik.resetForm();
    };

    const saveMap = async (values, formik) => {
        try {

            const data = new FormData();


            data.append('map_image', values.map_image);

            const mapData = values;

            console.log('>>>>>>> Map save: ', mapData);

            mapData.map_image = undefined;

            console.log("MAPDATAAAA", mapData)

            data.append('map_data', JSON.stringify(mapData));
            console.log('>>>> Data: ', data)

            const result = await api.post('/map/create', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('>>>>> result: ', result)

            const { map } = result.data;

            resetForm(formik);

            formik.setSubmitting(false);

            setImage(null);
            
            setMessage({
                open: true,
                variant: 'success',
                text: `Mapa ${map.name} cadastrado com sucesso!`,
            });
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };



    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Cadastrar Mapas
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={initialValues}
                        // validate={}
                        onSubmit={saveMap}
                    >
                        {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                            <>
                                <Grid container spacing={5}>
                                    <Grid spacing={5} item xs={12}>
                                        <Paper alevation={3} style={{ padding: '1.5%' }}>
                                            <form id="device-create" onSubmit={handleSubmit} className={classes.form}>
                                                <Grid container xs={12} spacing={5} justify='space-evenly'>
                                                    <Grid xs={12} item>
                                                        <Grid spacing={2} container>
                                                            <Grid item xs={12}>
                                                                <Input
                                                                    label="Nome"
                                                                    textFieldProps={{
                                                                        name: 'name',
                                                                        placeholder: 'Nome do mapa  ',
                                                                        onChange: handleChange,
                                                                        value: values.name,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid xs={12} item style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Grid spacing={0} xs={12} container justify='center'>
                                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Button
                                                                    variant="contained"
                                                                    component="label"
                                                                >
                                                                    Upload File
                                                                    <input
                                                                        name='map_image'
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={(e) => imageHandler(e, values)}
                                                                        hidden
                                                                        required={false}
                                                                    />
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {image !== null ? (
                                                        <img src={image} style={{ width: "800px" }} alt="" />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    <Grid item xs={12} container justify="center">
                                                        <Button
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            variant="contained"
                                                            form="device-create"
                                                            color="primary"
                                                            endIcon={<SaveIcon />}
                                                        >
                                                            {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                            {isSubmitting ? console.log('Clicked') : console.log("wainting for click")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    items_container: {
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    left_side: {
        borderBottom: 'solid 2px',
    },
    right_side: {

    },
}));
