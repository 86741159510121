import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Toolbar, Paper, Button, Grid, Typography, Tabs, Tab, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import SaveIcon from '@material-ui/icons/Save';
// import ConfigIcon from '@material-ui/icons/Settings';
import CameraIcon from '@material-ui/icons/Camera';
import DeleteIcon from '@material-ui/icons/Delete';
import { Formik } from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Popup from '../../components/Popup';
import CameraPopup from '../../components/CameraPopup';
import Warning from '../../components/WarningPopup';
import Canvas from '../../components/Canvas'
import MacAddresses from './components/MacAddresses';
import Autocomplete from '../../components/Autocomplete';

import pin from '../../assets/img/pin.png';

import api from '../../services/api';
import { useDebounce } from '../../hooks/useDebounce';
import { PermissionsContext } from '../../providers/Permissions';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function DeviceCreate() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const [device, setDevice] = useState({
        name: '',
        host: '',
        port: '',
        username: '',
        password: '',
        type: '',
        face_group: '',
        description: '',
        remote_validation: false,
        allow_unauthorized_day: false,
        allow_unauthorized_time: false,
        allow_without_mask: false,
        allow_over_temperature: false,
        dhi_type: null,
        allowed_access_audio: false,
        allow_reentry: true,
        reentry_time: 0,
        position: null,
        cards_compatible: false,
        input_01: {
            description: '',
            input_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        input_02: {
            description: '',
            input_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        input_03: {
            description: '',
            input_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        input_04: {
            description: '',
            input_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        output_01: {
            description: '',
            output_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        output_02: {
            description: '',
            output_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        output_03: {
            description: '',
            output_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        output_04: {
            description: '',
            output_behaviour: '',
            wave_type: '',
            wave_ignition: '',
            wave_reset: '',
            wave_duration: '',
        },
        mac_addresses: [""],
        events_server_enabled: false,
        events_server_ip_address: null,
        events_server_port: null,
        events_server_path: null,
        alcolizer_enabled: false,
        alcolizer_ip: '',
        alcolizer_port: '',
        enabled: true,
    });

    const [openMacAddresses, setOpenMacAddresses] = useState(false);
    const toggleMacAddresses = () => setOpenMacAddresses(!openMacAddresses);

    const { id } = useParams();
    const history = useHistory();

    const [openPopup, setOpenPopup] = useState(false);
    const [openWarningPopup, setOpenWarningPopup] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [value, setValue] = React.useState(0);
    const [updateToServerTime, setUpdateToServerTime] = useState(false);

    const [cameraAmount, setCameraAmount] = useState();
    const [cameraValues, setCameraValues] = useState([]);
    const cameraModel = {
        name: null,
        channel: null,
        recognition_similarity: null,
        type: null,
        usage: null,
        place_id: null,
        map_id: null,
        x_axis: null,
        y_axis: null
    };
    const [openCameraPopup, setOpenCameraPopup] = useState({ name: null, value: false });
    const [toDeleteCameras, setToDeleteCameras] = useState([]);

    function getCursorPosition(e, index, canvas) {



        var rect = canvas.current.getBoundingClientRect();

        let values = cameraValues.map(current => {
            let newObject = Object.assign({}, current);
            return newObject;
        })

        values[index]["x_axis"] = ((e.clientX - rect.left) - 15);
        values[index]["y_axis"] = ((e.clientY - rect.top));
        setCameraValues(values);
        console.log(cameraValues);

    }


    function handleCameraAmount(type, values) {
        console.log("Type: ", type);
        console.log("Values: ", values)

        values.type = type;

        if (type !== 'dhi')
            values.dhi_type = null;
        if (type === 'dhi')
            values.face_group = '';

        let cameras = (type === 'nvr-8') ? 8 : (type === 'nvr-16') ? 16 : (type === 'nvr-24') ? 24 : (type === 'nvr-32') ? 32 : (type === 'nvr-40') ? 40 : (type === 'nvr-48') ? 48 : (type === 'nvr-56') ? 56 : (type === 'nvr-64') ? 64 : null

        setCameraAmount(cameras);
        setOpenCameraPopup({ name: null, value: false })
        let c_values = [];
        for (let i = 0; i < cameras; i++) {
            c_values.push(cameraModel);
        }

        // Looking for the filled values
        let copyCamerasValues = [];
        cameraValues.forEach((current, index) => {
            if (current.name !== null && current.channel !== null && current.recognition_similarity !== null && current.type !== null && current.usage !== null && current.place_id !== null) {
                copyCamerasValues.push(current);
            }
        });
        console.log('copy of cameras values: ', copyCamerasValues);

        if (copyCamerasValues.length > 0) {
            copyCamerasValues.forEach((current, index) => {
                console.log('Camera: ', current);
                c_values[index] = current;
            });
        }
        setCameraValues(c_values);
        console.log(cameraValues);
    }

    const handleEditCameraAmount = useCallback(async (type, deviceCameras) => {
        // this function will be used to set the number of cameras when the page is first rendered
        // console.log('device type: ', type);
        let cameras = (type === 'nvr-8') ? 8 : (type === 'nvr-16') ? 16 : (type === 'nvr-24') ? 24 : (type === 'nvr-32') ? 32 : (type === 'nvr-40') ? 40 : (type === 'nvr-48') ? 48 : (type === 'nvr-56') ? 56 : (type === 'nvr-64') ? 64 : null
        console.log('cameras amount: ', cameras);
        setCameraAmount(cameras);
        setOpenCameraPopup({ name: null, value: false });
        let c_values = [];
        for (let i = 0; i < cameras; i++) {
            c_values.push({
                name: null,
                channel: null,
                recognition_similarity: null,
                type: null,
                usage: null,
                place_id: null,
                map_id: null,
                x_axis: null,
                y_axis: null,
            });
        }

        // console.log('current values: ', c_values)
        deviceCameras.forEach((current, index) => {
            console.log('Camera: ', current);
            c_values[index] = {
                ...current,
                place_id: current?.place_cameras ?? {},
            };
        });

        setCameraValues(c_values);
        // console.log('to edit values: ', cameraValues);
    }, []);

    function checkForRepeatedValues(toCheckValue, prop, toCheckIndex) {
        cameraValues.forEach((current, index) => {
            if (index !== toCheckIndex) {
                if ((current[prop] === toCheckValue) && (toCheckValue !== '') && (toCheckValue !== null)) {
                    setMessage({
                        open: true,
                        variant: 'error',
                        text: `Valores iguais não seram aceitos para a propriedade ${(prop === 'name') ? '"nome"' : (prop === 'channel') ? '"canal"' : null}`,
                    });
                }
            }
        });
    }

    function handleSetCameraValue(index, prop, input) {
        let values = cameraValues.map(current => {
            let newObject = Object.assign({}, current);
            return newObject;
        });

        // Calls a function to look for repeated values for the specified properties
        if (prop === 'name')
            checkForRepeatedValues(input, prop, index);

        if (input === '')
            input = null
        values[index][prop] = input;
        console.log('values: ', values);
        setCameraValues(values);
        console.log(cameraValues);
    }

    function getCameraButtonVariant(index) {
        let button = cameraValues[index];
        let variant = (
            (button.name === null || button.name === '') &&
            (button.channel === null || button.channel === '') &&
            (button.recognition_similarity === null || button.recognition_similarity === '') &&
            (button.type === null) &&
            (button.usage === null) &&
            (button.x_axis === null || button.map_id === null) &&
            (button.y_axis === null || button.map_id === null) &&
            (button.map_id === null) &&
            (button.place_id === null)

        ) ? 'outlined' : 'contained';

        // if (button.name === '' && button.channel === '' && button.recognition_similarity === '' && button.type === null && button.usage === null && button.place_id === null && variant === 'contained')
        //     variant = 'outlined';

        return variant;
    }

    function getCameraButtonColor(index) {
        let button = cameraValues[index];
        let variantCheck = (getCameraButtonVariant(index) === 'contained') ? true : false;
        let color = (
            (
                (button.name === null) ||
                (button.channel === null) ||
                (button.recognition_similarity === null) ||
                (button.type === null) ||
                (button.usage === null) ||
                (button.x_axis === null) ||
                (button.y_axis === null) ||
                (button.map_id === null) ||
                (button.place_id === null)
            ) && (
                variantCheck
            )
        ) ? 'secondary' : 'primary';

        return color;
    }

    function handleSetDeletingCameras(index) {
        let deletingCamera = cameraValues[index];
        let values = cameraValues.map(current => {
            let newObject = Object.assign({}, current);
            return newObject;
        });
        let deletingCameras = toDeleteCameras;
        values[index] = cameraModel;
        deletingCameras.push(deletingCamera.id);
        setOpenCameraPopup({ name: index, value: !openCameraPopup['value'] });
        setCameraValues(values);
        setToDeleteCameras(deletingCameras);
    }

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [orgsSelect, setOrgsSelect] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState({});
    const [orgSearch, setOrgSearch] = useState("");
    const debouncedOrgsSearch = useDebounce(orgSearch, 500);

    useEffect(() => {
        const findOrg = async () => {
            try {
                const result = await api.get('/organizational_structure/index', {
                    params: {
                        limig: 10,
                        page: 0,
                        search: debouncedOrgsSearch,
                    }
                });

                const { orgs } = result.data;

                setOrgsSelect(orgs);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findOrg();
    }, [debouncedOrgsSearch]);

    useEffect(() => {
        const loadDevice = async () => {
            try {
                const result = await api.get(`/device/detail/${id}`);
                console.log('>>')
                console.log(result)

                const { device } = result.data;

                setDevice(device);

                if (device.type !== 'dhi') {
                    const { cameras } = result.data;

                    handleEditCameraAmount(device.type, cameras);
                }

                if (device.OrganizationalStructure) {
                    setSelectedOrg(device.OrganizationalStructure);
                }

                if (device.place_devices) {
                    setSelectedPlace(device.place_devices);
                }

                console.log({ device })

            } catch (err) {
                // history.goBack();
                console.log("ERROR: ", { err })
                setMessage({
                    open: true,
                    variant: "error",
                    text: err.error,
                });
            }
        };
        loadDevice();
    }, [id, history, handleEditCameraAmount]);

    const [placesSelect, setPlacesSelect] = useState([]);
    const [placesSearch, setPlacesSearch] = useState("");
    const [selectedPlace, setSelectedPlace] = useState({});
    const debouncedPlacesSearch = useDebounce(placesSearch, 500);

    useEffect(() => {
        console.log("refetching places")
        const findPlace = async () => {
            try {
                const result = await api.get('/place/list', {
                    params: {
                        search: debouncedPlacesSearch,
                        limit: 10,
                        page: 0,
                    }
                });

                const { places: resultPlaces } = result.data;
                console.log("Places", resultPlaces)

                setPlacesSelect(resultPlaces);
            }
            catch (err) {
                console.log(err.message);
            }
        };

        findPlace();
    }, [debouncedPlacesSearch]);

    const [mapSelect, setMapSelect] = useState([{ name: 'Selecione um mapa', id: null, map_image: null }]);
    useEffect(() => {
        const findMap = async () => {
            try {
                const result = await api.get('/map/list');
                console.log('map result: ', result)
                const { maps } = result.data;
                console.log('maps: ', maps);

                if (maps)
                    setMapSelect([{ name: '|---- Selecione um local ----|', id: null }, ...maps]);
            } catch (err) {
                console.log(err.message);
            }
        }
        findMap();
    }, [])

    const [isDeleting, setIsDeleting] = useState(false);
    const deleteDevice = async () => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/device/delete/${id}`);

            const { success } = result.data;

            setIsDeleting(false);

            if (success) {
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Dispositivo ${device.name} deletado com sucesso!`,
                });
            }
            history.goBack();
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    const saveDevice = async (values, formik) => {
        try {
            let stopSubmission = false;
            cameraValues.forEach((current, index) => {
                if (current.name !== null && current.channel !== null && current.recognition_similarity !== null && current.type !== null && current.place_id && current.usage) {
                    // just pass
                } else if (current.name !== null || current.channel !== null || current.recognition_similarity !== null || current.type !== null || current.place_id || current.usage) {
                    stopSubmission = true;
                }
            });

            if (stopSubmission) {
                formik.setSubmitting(false);
                setOpenWarningPopup(true);
                return false;
            }

            const data = {
                ...values,
                org_id: selectedOrg.id,
                place_id: selectedPlace.id,
                update_to_server_time: updateToServerTime,
            };

            const result = await api.post(`/device/modify/${id}`, data);

            const { device } = result.data;

            formik.setSubmitting(false);

            if (values.type !== 'dhi') {
                let mapedValues = [];
                let device_id = device.id;
                cameraValues.forEach((current, index) => {
                    if (current.name !== null && current.channel !== null && current.recognition_similarity !== null && current.type !== null) {
                        mapedValues.push({
                            ...current,
                            device_id: device_id,
                            place_id: current?.place_id?.id ?? null,
                        });
                    }
                });

                console.log('requesting: ', mapedValues);
                const result_2 = await api.post(`/camera/modify/${id}`, mapedValues);
                const { cameras } = result_2.data;
                console.log('Updated values: ', cameras);

                if (toDeleteCameras?.length) {
                    const result_3 = await api.post('/camera/delete', {
                        ids: toDeleteCameras
                    });
                    console.log(result_3);
                }
            }

            setMessage({
                open: true,
                variant: 'success',
                text: `Dispositivo ${device.name} atualizado com sucesso!`,
            });
        }
        catch (err) {
            console.log("ERROR: ", { err })
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    // const changeRemoteValidation = (checked, setFieldValue) => {
    //     setFieldValue('remote_validation', checked);
    //     if (!checked) {
    //         setFieldValue('allow_unauthorized_day', false);
    //         setFieldValue('allow_unauthorized_time', false);
    //         setFieldValue('allow_without_mask', false);
    //         setFieldValue('allow_over_temperature', false);
    //     }
    // };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Editar dispositivo
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={device}
                        enableReinitialize={true}
                        onSubmit={saveDevice}
                    >
                        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                            <>
                                <Grid container spacing={5}>
                                    <Grid spacing={5} item xs={12}>
                                        <Paper alevation={3} style={{ padding: '1.5%' }}>
                                            <form id="device-modify" onSubmit={handleSubmit} className={classes.form}>
                                                <Grid container xs={12} spacing={3} justify='space-evenly'>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5" className={classes.sectionTitle}>
                                                            Informações Gerais
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={4} item>
                                                        <Grid spacing={1} container>
                                                            <Grid item xs={10}>
                                                                <Input
                                                                    label="Nome"
                                                                    textFieldProps={{
                                                                        name: 'name',
                                                                        placeholder: 'Nome do disposivo',
                                                                        onChange: handleChange,
                                                                        value: values.name,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Input
                                                                    label="Host"
                                                                    textFieldProps={{
                                                                        name: 'host',
                                                                        placeholder: 'Host',
                                                                        onChange: handleChange,
                                                                        value: values.host,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Input
                                                                    label="Port"
                                                                    textFieldProps={{
                                                                        name: 'port',
                                                                        placeholder: 'Port',
                                                                        onChange: handleChange,
                                                                        value: values.port,
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid xs={4} item>
                                                        <Grid container spacing={1} direction='column'>
                                                            <Grid item xs={12}>
                                                                <Input
                                                                    label="Usuário"
                                                                    textFieldProps={{
                                                                        name: 'username',
                                                                        placeholder: 'Username do administrador',
                                                                        onChange: handleChange,
                                                                        value: values.username,
                                                                        fullWidth: true,
                                                                        required: false,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Input
                                                                    label="Senha"
                                                                    textFieldProps={{
                                                                        name: 'password',
                                                                        type: 'password',
                                                                        placeholder: 'Senha do administrador',
                                                                        onChange: handleChange,
                                                                        value: values.password,
                                                                        fullWidth: true,
                                                                        required: false,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid xs={4} item>
                                                        <Grid container spacing={1} direction='column'>
                                                            <Grid item xs={12}>
                                                                <Select
                                                                    label="Tipo"
                                                                    options={[
                                                                        { label: 'Leitor Facial', value: 'dhi' },
                                                                        { label: 'Alcolizer', value: 'alcolizer' },
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'type',
                                                                        onChange: ({ target }) => handleCameraAmount(target.value, values),
                                                                        fullWidth: true,
                                                                        value: values.type,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Input
                                                                    label="Grupo"
                                                                    textFieldProps={{
                                                                        name: 'face_group',
                                                                        placeholder: 'Grupo do dispositivo',
                                                                        onChange: handleChange,
                                                                        value: values.face_group,
                                                                        fullWidth: true,
                                                                        disabled: ['dhi', 'alcolizer'].includes(values.type),
                                                                        required: !['dhi', 'alcolizer'].includes(values.type),

                                                                    }}
                                                                />
                                                            </Grid>
                                                            {/* <Grid item>
                                                                <Grid container style={{ marginTop: '0.5%', }} direction="column">
                                                                    <Typography style={{ fontWeight: 'bold', color: '#9e9e9e' }}>
                                                                        Configurações
                                                                    </Typography>
                                                                    <Button
                                                                        style={{ marginTop: '0.5%', }}
                                                                        label='config'
                                                                        variant="contained"
                                                                        form="device-modify"
                                                                        color="secondary"
                                                                        onClick={() => setOpenPopup(true)}
                                                                        endIcon={<ConfigIcon />}
                                                                    >
                                                                        Adcionar
                                                                    </Button>
                                                                </Grid>
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid spacing={3} container>
                                                            <Grid xs={8} item>
                                                                <Input
                                                                    label="Descrição"
                                                                    textFieldProps={{
                                                                        name: 'description',
                                                                        placeholder: 'Descrição da localização',
                                                                        onChange: handleChange,
                                                                        value: values.description,
                                                                        fullWidth: true,
                                                                        required: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Autocomplete
                                                                    label="Localidade"
                                                                    placeholder="Localidade"
                                                                    id="place_id"
                                                                    options={placesSelect}
                                                                    getOptionLabel={({ name }) => name || ''}
                                                                    onChange={(_, value) => setSelectedPlace(value)}
                                                                    getOptionSelected={(option, value) => (option.id === value.id)}
                                                                    onInputChange={(_, value) => setPlacesSearch(value)}
                                                                    value={selectedPlace}
                                                                    required={true}
                                                                    fullWidth />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Select
                                                                    label="Habilitado"
                                                                    options={[
                                                                        { label: "Sim", value: true },
                                                                        { label: "Não", value: false },
                                                                    ]}
                                                                    textFieldProps={{
                                                                        name: 'enabled',
                                                                        onChange: handleChange,
                                                                        fullWidth: true,
                                                                        value: values.enabled,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Autocomplete
                                                                    label="Estrutura Organizacional"
                                                                    placeholder="Estrutura Organizacional"
                                                                    id="org_id"
                                                                    options={orgsSelect}
                                                                    getOptionLabel={({ name }) => name || ''}
                                                                    onChange={(_, value) => setSelectedOrg(value)}
                                                                    getOptionSelected={(option, value) => (option.id === value.id)}
                                                                    onInputChange={(_, value) => setOrgSearch(value)}
                                                                    value={selectedOrg}
                                                                    required={true}
                                                                    fullWidth />
                                                            </Grid>
                                                            {values.type === "dhi" && (
                                                                <>
                                                                    <Grid item xs={6} md={4}>
                                                                        <Select
                                                                            label="Controlar Remotamente"
                                                                            options={[
                                                                                {
                                                                                    label: "Não",
                                                                                    value: false,
                                                                                },
                                                                                {
                                                                                    label: "Sim",
                                                                                    value: true,
                                                                                }
                                                                            ]}
                                                                            textFieldProps={{
                                                                                name: 'remote_validation',
                                                                                onChange: handleChange,
                                                                                fullWidth: true,
                                                                                value: values.remote_validation
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} md={4}>
                                                                        <Select
                                                                            label="Tipo do DHI"
                                                                            options={[
                                                                                {
                                                                                    label: "Entrada",
                                                                                    value: 'entry',
                                                                                },
                                                                                {
                                                                                    label: "Saída",
                                                                                    value: 'exit',
                                                                                },
                                                                                {
                                                                                    label: "Entrada-Saída",
                                                                                    value: 'hybrid',
                                                                                },
                                                                            ]}
                                                                            textFieldProps={{
                                                                                name: 'dhi_type',
                                                                                onChange: handleChange,
                                                                                fullWidth: true,
                                                                                value: values.dhi_type,
                                                                                required: values.type === 'dhi',
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} md={4}>
                                                                        <Select
                                                                            label="Posição do DHI"
                                                                            options={[
                                                                                {
                                                                                    label: "Selecione uma posição",
                                                                                    value: null,
                                                                                },
                                                                                {
                                                                                    label: "Interno",
                                                                                    value: "internal",
                                                                                },
                                                                                {
                                                                                    label: "Externo",
                                                                                    value: "external",
                                                                                }
                                                                            ]}
                                                                            textFieldProps={{
                                                                                name: 'position',
                                                                                onChange: handleChange,
                                                                                fullWidth: true,
                                                                                value: values.position
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    {(values.remote_validation) && (
                                                                        <Grid item xs={6} md={4}>
                                                                            <Select
                                                                                label="Reproduzir áudio de acesso permitido"
                                                                                options={[
                                                                                    {
                                                                                        label: "Não",
                                                                                        value: false,
                                                                                    },
                                                                                    {
                                                                                        label: "Sim",
                                                                                        value: true,
                                                                                    }
                                                                                ]}
                                                                                textFieldProps={{
                                                                                    name: 'allowed_access_audio',
                                                                                    onChange: handleChange,
                                                                                    fullWidth: true,
                                                                                    value: values.allowed_access_audio
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item xs={6} md={4}>
                                                                        <Select
                                                                            label="Compatível com Cartões"
                                                                            options={[
                                                                                {
                                                                                    label: "Sim",
                                                                                    value: true,
                                                                                },
                                                                                {
                                                                                    label: "Não",
                                                                                    value: false,
                                                                                },
                                                                            ]}
                                                                            textFieldProps={{
                                                                                name: 'cards_compatible',
                                                                                onChange: handleChange,
                                                                                fullWidth: true,
                                                                                value: values.cards_compatible,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            )}
                                                            <Grid item xs={6} md={4}>
                                                                <Select
                                                                    label="Sincroniza com horário do sistema"
                                                                    options={[
                                                                        {
                                                                            label: "Sim",
                                                                            value: true,
                                                                        },
                                                                        {
                                                                            label: "Não",
                                                                            value: false,
                                                                        },
                                                                    ]}
                                                                    textFieldProps={{
                                                                        onChange: ({ target: { value } }) => setUpdateToServerTime(value),
                                                                        fullWidth: true,
                                                                        value: updateToServerTime,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} style={{ display: "flex", alignItems: "flex-end" }}>
                                                                <Button
                                                                    variant="text"
                                                                    color="default"
                                                                    onClick={toggleMacAddresses}
                                                                    fullWidth={true}
                                                                    style={{
                                                                        border: "1px solid #cdcdcd",
                                                                        color: grey[600],
                                                                        fontWeight: 700,
                                                                    }}
                                                                >
                                                                    Endereços Mac ({
                                                                        ((values.mac_addresses ?? []).filter(mac => mac !== "")).length
                                                                    })
                                                                </Button>
                                                            </Grid>
                                                            <Popup
                                                                openPopup={openMacAddresses}
                                                                setOpenPopup={setOpenMacAddresses}
                                                                title="Endereços MAC"
                                                                width="40%"
                                                            >
                                                                <MacAddresses
                                                                    value={values.mac_addresses}
                                                                    onChange={(value) => setFieldValue("mac_addresses", value)}
                                                                />
                                                            </Popup>
                                                        </Grid>
                                                    </Grid>
                                                    {values.type === 'dhi' && (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Grid spacing={3} container>
                                                                    <Grid item xs={12} style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}>
                                                                        <Typography variant="h5" className={classes.sectionTitle}>
                                                                            Servidor de Eventos
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid xs={6} item>
                                                                        <Select
                                                                            label="Ativo"
                                                                            id="es-enabled"
                                                                            options={[
                                                                                {
                                                                                    label: "Não",
                                                                                    value: false,
                                                                                },
                                                                                {
                                                                                    label: "Sim",
                                                                                    value: true,
                                                                                },

                                                                            ]}
                                                                            textFieldProps={{
                                                                                name: "events_server_enabled",
                                                                                onChange: handleChange,
                                                                                fullWidth: true,
                                                                                value: values.events_server_enabled,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid xs={6} item>
                                                                        <Input
                                                                            label="Endereço IP"
                                                                            id="es-ip-address"
                                                                            textFieldProps={{
                                                                                name: 'events_server_ip_address',
                                                                                placeholder: 'Enderço IP do servidor',
                                                                                onChange: handleChange,
                                                                                value: values.events_server_ip_address,
                                                                                fullWidth: true,
                                                                                required: !!values.events_server_enabled,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid xs={6} item>
                                                                        <Input
                                                                            label="Porta"
                                                                            id="es-port"
                                                                            textFieldProps={{
                                                                                name: 'events_server_port',
                                                                                placeholder: 'Porta do servidor',
                                                                                onChange: handleChange,
                                                                                value: values.events_server_port,
                                                                                fullWidth: true,
                                                                                type: "number",
                                                                                required: !!values.events_server_enabled,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid xs={6} item>
                                                                        <Input
                                                                            label="Caminho"
                                                                            id="es-path"
                                                                            textFieldProps={{
                                                                                name: 'events_server_path',
                                                                                placeholder: 'Caminho de envio no IP',
                                                                                onChange: handleChange,
                                                                                value: values.events_server_path,
                                                                                fullWidth: true,
                                                                                required: !!values.events_server_enabled,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid spacing={3} container>
                                                                    <Grid item xs={12} style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}>
                                                                        <Typography variant="h5" className={classes.sectionTitle}>
                                                                            Integração Alcolizer
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid xs={4} item>
                                                                        <Select
                                                                            label="Ativo"
                                                                            id="ia-enabled"
                                                                            options={[
                                                                                {
                                                                                    label: "Não",
                                                                                    value: false,
                                                                                },
                                                                                {
                                                                                    label: "Sim",
                                                                                    value: true,
                                                                                },

                                                                            ]}
                                                                            textFieldProps={{
                                                                                name: "alcolizer_enabled",
                                                                                onChange: handleChange,
                                                                                fullWidth: true,
                                                                                value: values.alcolizer_enabled,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid xs={4} item>
                                                                        <Input
                                                                            label="Endereço IP"
                                                                            id="ia-ip-address"
                                                                            textFieldProps={{
                                                                                name: 'alcolizer_ip',
                                                                                placeholder: 'Enderço IP do servidor',
                                                                                onChange: handleChange,
                                                                                value: values.alcolizer_ip,
                                                                                fullWidth: true,
                                                                                required: !!values.alcolizer_enabled,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid xs={4} item>
                                                                        <Input
                                                                            label="Porta"
                                                                            id="ia-port"
                                                                            textFieldProps={{
                                                                                name: 'alcolizer_port',
                                                                                placeholder: 'Porta do servidor',
                                                                                onChange: handleChange,
                                                                                value: values.alcolizer_port,
                                                                                fullWidth: true,
                                                                                type: "number",
                                                                                required: !!values.alcolizer_enabled,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )}

                                                    {values.type !== 'alcolizer' && (
                                                        <Grid item xs={12}>
                                                            <Grid spacing={3} container>
                                                                <Grid item xs={12} style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}>
                                                                    <Typography variant="h5" className={classes.sectionTitle}>
                                                                        Controle de Acesso Customizado
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={6} item>
                                                                    <Select
                                                                        label="Permitir acesso em dias não autorizados (DHI / NVR)"
                                                                        id="unauthorized-days"
                                                                        tip={
                                                                            <>
                                                                                <Typography variant='subtitle1'>
                                                                                    Ao habilitar essa função, as pessoas terão o acesso permitido em dias não autorizados.
                                                                                </Typography>
                                                                                <Typography variant="subtitle2"><i>OBS: Para que está função funcione nos leitores faciais é necessario que o controle remoto do equipamento esteja habilitado.</i></Typography>
                                                                            </>
                                                                        }
                                                                        options={[
                                                                            {
                                                                                label: "Não",
                                                                                value: false,
                                                                            },
                                                                            {
                                                                                label: "Sim",
                                                                                value: true,
                                                                            },

                                                                        ]}
                                                                        textFieldProps={{
                                                                            name: "allow_unauthorized_day",
                                                                            onChange: handleChange,
                                                                            fullWidth: true,
                                                                            value: values.allow_unauthorized_day,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={6} item>
                                                                    <Select
                                                                        label="Permitir acesso em horários não autorizados (DHI / NVR)"
                                                                        id="unauthorized-time"
                                                                        tip={
                                                                            <>
                                                                                <Typography variant='subtitle1'>
                                                                                    Ao habilitar essa função, as pessoas teão acesso permitido em horários não autorizados.
                                                                                </Typography>
                                                                                <Typography variant="subtitle2"><i>OBS: Para que está função funcione nos leitores faciais é necessario que o controle remoto do equipamento esteja habilitado.</i></Typography>
                                                                            </>
                                                                        }
                                                                        options={[
                                                                            {
                                                                                label: "Não",
                                                                                value: false,
                                                                            },
                                                                            {
                                                                                label: "Sim",
                                                                                value: true,
                                                                            },

                                                                        ]}
                                                                        textFieldProps={{
                                                                            name: "allow_unauthorized_time",
                                                                            onChange: handleChange,
                                                                            fullWidth: true,
                                                                            value: values.allow_unauthorized_time,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                {values.type === 'dhi' && (
                                                                    <>
                                                                        <Grid xs={6} item>
                                                                            <Select
                                                                                label="Permitir acessos a cima da temperatura (DHI)"
                                                                                id="over-temperature"
                                                                                tip={
                                                                                    <>
                                                                                        <Typography variant='subtitle1'>
                                                                                            Ao habilitar essa função, pessoas com temperatura acima de 37.3 serão autorizadas.
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2"><i>OBS: Apenas para o Leitor Facial (DHI).</i></Typography>
                                                                                    </>
                                                                                }
                                                                                options={[
                                                                                    {
                                                                                        label: "Não",
                                                                                        value: false,
                                                                                    },
                                                                                    {
                                                                                        label: "Sim",
                                                                                        value: true,
                                                                                    },

                                                                                ]}
                                                                                textFieldProps={{
                                                                                    name: "allow_over_temperature",
                                                                                    onChange: handleChange,
                                                                                    fullWidth: true,
                                                                                    value: values.allow_over_temperature,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={6} item>
                                                                            <Select
                                                                                label="Permitir acessos sem máscara (DHI)"
                                                                                id="without-mask"
                                                                                tip={
                                                                                    <>
                                                                                        <Typography variant='subtitle1'>
                                                                                            Ao habilitar essa função, pessoas sem máscara serão autorizadas.
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2"><i>OBS: Apenas para o Leitor Facial (DHI).</i></Typography>
                                                                                    </>
                                                                                }
                                                                                options={[
                                                                                    {
                                                                                        label: "Não",
                                                                                        value: false,
                                                                                    },
                                                                                    {
                                                                                        label: "Sim",
                                                                                        value: true,
                                                                                    },

                                                                                ]}
                                                                                textFieldProps={{
                                                                                    name: "allow_without_mask",
                                                                                    onChange: handleChange,
                                                                                    fullWidth: true,
                                                                                    value: values.allow_without_mask,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={6} item>
                                                                            <Select
                                                                                label="Permitir entradas repetidas (DHI)"
                                                                                id="allow-re-entry"
                                                                                tip={
                                                                                    <>
                                                                                        <Typography variant='subtitle1'>
                                                                                            Ao habilitar essa função, uma pessoa poderá por exemplo, passar pelo dispositivo e
                                                                                            depois usar seu rosto/cartão para habilitar a passagem de outra pessoa
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2"><i>OBS: Apenas para o Leitor Facial (DHI).</i></Typography>
                                                                                    </>
                                                                                }
                                                                                options={[
                                                                                    {
                                                                                        label: "Não",
                                                                                        value: false,
                                                                                    },
                                                                                    {
                                                                                        label: "Sim",
                                                                                        value: true,
                                                                                    },
                                                                                ]}
                                                                                textFieldProps={{
                                                                                    name: "allow_reentry",
                                                                                    onChange: (e) => {
                                                                                        setFieldValue('reentry_time', 0);
                                                                                        handleChange(e);
                                                                                    },
                                                                                    fullWidth: true,
                                                                                    value: values.allow_reentry,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        {
                                                                            !values.allow_reentry && (
                                                                                <Grid xs={6} item>
                                                                                    <Input
                                                                                        label="Tempo entre entradas repetidas (segundos)"
                                                                                        id="re-entry-time"
                                                                                        textFieldProps={{
                                                                                            name: "reentry_time",
                                                                                            type: "number",
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.reentry_time,
                                                                                        }}
                                                                                        numberProps={{
                                                                                            min: 0,
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    <Grid xs={12} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                        {(cameraAmount != null) && (
                                                            <Typography className={classes.cameraTitle}>
                                                                Câmeras
                                                            </Typography>
                                                        )}
                                                        <Grid xs={9} container style={{ display: 'flex', justifyContent: 'center', width: '60%' }}>
                                                            {(cameraAmount !== 0) ? cameraValues.map((current, index) => (
                                                                <Grid xs={1.8} key={index} item style={{ margin: '0.6%', width: '9.95%' }}>
                                                                    <Button
                                                                        variant={getCameraButtonVariant(index)}
                                                                        color={getCameraButtonColor(index)}
                                                                        onClick={() => setOpenCameraPopup({ name: index, value: !openCameraPopup['value'] })}
                                                                        endIcon={<CameraIcon />}
                                                                    >
                                                                        C{(index < 9) ? ('0' + (index + 1)) : (index + 1)}
                                                                    </Button>
                                                                    <CameraPopup
                                                                        openPopup={(openCameraPopup['name'] === index) ? openCameraPopup['value'] : false}
                                                                        setOpenPopup={setOpenCameraPopup}
                                                                        cameraIndex={index}
                                                                        title={`Configurar C${(index < 9) ? ('0' + (index + 1)) : (index + 1)}`}
                                                                    >
                                                                        <Grid container>
                                                                            <Grid xs={6} item>
                                                                                <Input
                                                                                    label={'Nome'}
                                                                                    textFieldProps={{
                                                                                        onChange: ({ target }) => handleSetCameraValue(index, 'name', target.value),
                                                                                        value: cameraValues[index]['name'],
                                                                                        fullWidth: true
                                                                                    }}
                                                                                />
                                                                                <Input
                                                                                    label={'Canal'}
                                                                                    textFieldProps={{
                                                                                        onChange: ({ target }) => handleSetCameraValue(index, 'channel', target.value),
                                                                                        value: cameraValues[index]['channel'],
                                                                                        fullWidth: true
                                                                                    }}
                                                                                />
                                                                                <Input
                                                                                    label={'Grau de reconhecimento'}
                                                                                    textFieldProps={{
                                                                                        onChange: ({ target }) => handleSetCameraValue(index, 'recognition_similarity', target.value),
                                                                                        value: cameraValues[index]['recognition_similarity'],
                                                                                        fullWidth: true
                                                                                    }}
                                                                                />
                                                                                <Select
                                                                                    label="Tipo"
                                                                                    options={[
                                                                                        { label: '|---- Selecione um valor ----|', value: null },
                                                                                        { label: 'Entrada', value: 'entry' },
                                                                                        { label: 'Saída', value: 'exit' },
                                                                                        { label: 'Interna', value: 'internal' }
                                                                                    ]}
                                                                                    textFieldProps={{
                                                                                        onChange: ({ target }) => handleSetCameraValue(index, 'type', target.value),
                                                                                        value: cameraValues[index]['type'],
                                                                                        fullWidth: true
                                                                                    }}
                                                                                />
                                                                                <Select
                                                                                    label="Uso da câmera"
                                                                                    options={[
                                                                                        { label: '|---- Selecione um valor ----|', value: null },
                                                                                        { label: 'Controle de acesso', value: 'access_controll' },
                                                                                        { label: 'Monitoramento', value: 'monitoring' },
                                                                                        { label: 'Segurança Orgânica', value: 'security' }
                                                                                    ]}
                                                                                    textFieldProps={{
                                                                                        onChange: ({ target }) => handleSetCameraValue(index, 'usage', target.value),
                                                                                        value: cameraValues[index]['usage'],
                                                                                        fullWidth: true
                                                                                    }}
                                                                                />
                                                                                <Autocomplete
                                                                                    label="Localização da câmera"
                                                                                    id="c_loc"
                                                                                    options={placesSelect}
                                                                                    getOptionLabel={({ name }) => name || ''}
                                                                                    onChange={(_, value) => {
                                                                                        handleSetCameraValue(index, 'place_id', value)
                                                                                        setPlacesSearch("");
                                                                                    }}
                                                                                    getOptionSelected={(option, value) => (option.id === value.id)}
                                                                                    onInputChange={(_, value) => setPlacesSearch(value)}
                                                                                    value={cameraValues[index]['place_id']}
                                                                                    required={true}
                                                                                    fullWidth />
                                                                                <Select
                                                                                    label="Mapa da câmera"
                                                                                    options={
                                                                                        mapSelect.map(map => ({ label: map.name, value: map.id }))
                                                                                    }
                                                                                    textFieldProps={{
                                                                                        id: 'c_loc',
                                                                                        onChange: ({ target }) => handleSetCameraValue(index, 'map_id', target.value),
                                                                                        value: cameraValues[index]['map_id'],
                                                                                        fullWidth: true
                                                                                    }}
                                                                                />
                                                                                {!!cameraValues[index]['id'] && (
                                                                                    <Grid container style={{ padding: '2% 0 0.5% 0' }} justify='center'>
                                                                                        <Button
                                                                                            disabled={isDeleting}
                                                                                            variant="contained"
                                                                                            onClick={() => handleSetDeletingCameras(index)}
                                                                                            color="secondary"
                                                                                            endIcon={<DeleteIcon />}
                                                                                        >
                                                                                            Deletar câmera
                                                                                        </Button>
                                                                                    </Grid>
                                                                                )}
                                                                            </Grid>
                                                                            <Grid xs={6} item style={{ paddingLeft: '70px', paddingTop: '20px' }}>
                                                                                {mapSelect.map(map => {
                                                                                    if (map.id === cameraValues[index].map_id) {
                                                                                        console.log(map.map_image)
                                                                                        return (((map.map_image !== null) && (map.map_image !== undefined)) && (
                                                                                            <div style={{ position: 'relative' }}>
                                                                                                <Canvas image={`${map.map_image}`} getCursorPos={getCursorPosition} index={index} />
                                                                                                {cameraValues[index]['y_axis'] !== null && cameraValues[index]['x_axis'] !== null && (
                                                                                                    <div className="pin" style={{ top: `${cameraValues[index]['y_axis']}px`, left: `${cameraValues[index]['x_axis']}px`, position: 'absolute', zIndex: 0 }}>
                                                                                                        <img src={pin} alt='' style={{ width: '30px' }}></img>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                    return null;
                                                                                })}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </CameraPopup>
                                                                </Grid>
                                                            )) : null}
                                                        </Grid>
                                                    </Grid>
                                                    <Popup
                                                        openPopup={openPopup}
                                                        setOpenPopup={setOpenPopup}
                                                        title='Configurações do Dispositivo'
                                                    >
                                                        <Grid container direction='column' spacing={1} xs={12}>
                                                            <Grid item xs>
                                                                <Grid justify='center' container xs={12}>
                                                                    <Tabs
                                                                        xs={12}
                                                                        indicatorColor="primary"
                                                                        textColor="primary"
                                                                        centered
                                                                        onChange={handleChangeTabs}
                                                                        value={value}
                                                                        aria-label="tabs"
                                                                    >
                                                                        <Tab label="Entrada" index={0} />
                                                                        <Tab label="Saída" index={1} />
                                                                    </Tabs>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs>
                                                                <Grid container xs>
                                                                    <TabPanel value={value} index={0}>
                                                                        <Grid container justify='space-between' styles={{ margin: '0.5%' }}>
                                                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                                                <Grid container>
                                                                                    <Typography variant='h5'>
                                                                                        Entrada 01
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container style={{
                                                                                    margin: '2% 0'
                                                                                }}>
                                                                                    <Input
                                                                                        label="Descrição"
                                                                                        textFieldProps={{
                                                                                            name: 'input_01.description',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_01.description,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Comportamento de entrada"
                                                                                        textFieldProps={{
                                                                                            name: 'input_01.input_behaviour',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_01.input_behaviour,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Tipo de pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_01.wave_type',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_01.wave_type,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Transição que inicia o pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_01.wave_ignition',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_01.wave_ignition,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Reset da duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_01.wave_reset',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_01.wave_reset,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_01.wave_duration',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_01.wave_duration,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                                                <Grid container>
                                                                                    <Typography variant='h5'>
                                                                                        Entrada 02
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container style={{
                                                                                    margin: '2% 0'
                                                                                }}>
                                                                                    <Input
                                                                                        label="Descrição"
                                                                                        textFieldProps={{
                                                                                            name: 'input_02.description',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_02.description,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Comportamento de entrada"
                                                                                        textFieldProps={{
                                                                                            name: 'input_02.input_behaviour',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_02.input_behaviour,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Tipo de pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_02.wave_type',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_02.wave_type,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Transição que inicia o pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_02.wave_ignition',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_02.wave_ignition,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Reset da duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_02.wave_reset',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_02.wave_reset,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_02.wave_duration',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_02.wave_duration,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                                                <Grid container>
                                                                                    <Typography variant='h5'>
                                                                                        Entrada 03
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container style={{
                                                                                    margin: '2% 0'
                                                                                }}>
                                                                                    <Input
                                                                                        label="Descrição"
                                                                                        textFieldProps={{
                                                                                            name: 'input_03.description',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_03.description,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Comportamento de entrada"
                                                                                        textFieldProps={{
                                                                                            name: 'input_03.input_behaviour',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_03.input_behaviour,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Tipo de pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_03.wave_type',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_03.wave_type,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Transição que inicia o pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_03.wave_ignition',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_03.wave_ignition,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Reset da duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_03.wave_reset',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_03.wave_reset,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_03.wave_duration',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_03.wave_duration,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                                                <Grid container>
                                                                                    <Typography variant='h5'>
                                                                                        Entrada 04
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container style={{
                                                                                    margin: '2% 0'
                                                                                }}>
                                                                                    <Input
                                                                                        label="Descrição"
                                                                                        textFieldProps={{
                                                                                            name: 'input_04.description',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_04.description,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Comportamento de entrada"
                                                                                        textFieldProps={{
                                                                                            name: 'input_04.input_behaviour',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_04.input_behaviour,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Tipo de pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_04.wave_type',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_04.wave_type,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Transição que inicia o pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_04.wave_ignition',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_04.wave_ignition,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Reset da duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_04.wave_reset',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_04.wave_reset,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'input_04.wave_duration',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.input_04.wave_duration,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TabPanel>
                                                                    <TabPanel value={value} index={1}>
                                                                        <Grid container justify='space-between' styles={{ margin: '0.5%' }}>
                                                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                                                <Grid container>
                                                                                    <Typography variant='h5'>
                                                                                        Saída 01
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container style={{
                                                                                    margin: '2% 0'
                                                                                }}>
                                                                                    <Input
                                                                                        label="Descrição"
                                                                                        textFieldProps={{
                                                                                            name: 'output_01.description',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_01.description,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Comportamento de saída"
                                                                                        textFieldProps={{
                                                                                            name: 'output_01.output_behaviour',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_01.output_behaviour,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Tipo de pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_01.wave_type',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_01.wave_type,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Transição que inicia o pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_01.wave_ignition',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_01.wave_ignition,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Reset da duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_01.wave_reset',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_01.wave_reset,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_01.wave_duration',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_01.wave_duration,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                                                <Grid container>
                                                                                    <Typography variant='h5'>
                                                                                        Saída 02
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container style={{
                                                                                    margin: '2% 0'
                                                                                }}>
                                                                                    <Input
                                                                                        label="Descrição"
                                                                                        textFieldProps={{
                                                                                            name: 'output_02.description',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_02.description,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Comportamento de saída"
                                                                                        textFieldProps={{
                                                                                            name: 'output_02.output_behaviour',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_02.output_behaviour,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Tipo de pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_02.wave_type',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_02.wave_type,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Transição que inicia o pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_02.wave_ignition',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_02.wave_ignition,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Reset da duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_02.wave_reset',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_02.wave_reset,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_02.wave_duration',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_02.wave_duration,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                                                <Grid container>
                                                                                    <Typography variant='h5'>
                                                                                        Saída 03
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container style={{
                                                                                    margin: '2% 0'
                                                                                }}>
                                                                                    <Input
                                                                                        label="Descrição"
                                                                                        textFieldProps={{
                                                                                            name: 'output_03.description',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_03.description,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Comportamento de saída"
                                                                                        textFieldProps={{
                                                                                            name: 'output_03.output_behaviour',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_03.output_behaviour,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Tipo de pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_03.wave_type',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_03.wave_type,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Transição que inicia o pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_03.wave_ignition',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_03.wave_ignition,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Reset da duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_03.wave_reset',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_03.wave_reset,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_03.wave_duration',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_03.wave_duration,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container style={{ flexDirection: 'column', padding: '1%' }} xs={5}>
                                                                                <Grid container>
                                                                                    <Typography variant='h5'>
                                                                                        Saída 04
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid container style={{
                                                                                    margin: '2% 0'
                                                                                }}>
                                                                                    <Input
                                                                                        label="Descrição"
                                                                                        textFieldProps={{
                                                                                            name: 'output_04.description',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_04.description,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Comportamento de saída"
                                                                                        textFieldProps={{
                                                                                            name: 'output_04.output_behaviour',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_04.output_behaviour,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Tipo de pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_04.wave_type',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_04.wave_type,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Transição que inicia o pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_04.wave_ignition',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_04.wave_ignition,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Reset da duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_04.wave_reset',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_04.wave_reset,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    <Input
                                                                                        label="Duração do pulso"
                                                                                        textFieldProps={{
                                                                                            name: 'output_04.wave_duration',
                                                                                            onChange: handleChange,
                                                                                            fullWidth: true,
                                                                                            value: values.output_04.wave_duration,
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TabPanel>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Popup>
                                                    <Popup
                                                        openPopup={openDeletePopup}
                                                        setOpenPopup={setOpenDeletePopup}

                                                    >
                                                        <Grid container xs={12} style={{
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            padding: '0 3% 2% 3%',

                                                        }}>
                                                            <Typography variant='h5'
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#9e9e9e'
                                                                }}
                                                            >
                                                                Deseja mesmo deletar o dispositivo "{device.name}"?
                                                            </Typography>
                                                            <Grid item style={{
                                                                marginTop: '50px'
                                                            }}>
                                                                <Button
                                                                    disabled={isDeleting}
                                                                    variant="contained"
                                                                    onClick={() => deleteDevice()}
                                                                    color="secondary"
                                                                    endIcon={<DeleteIcon />}
                                                                >
                                                                    {isDeleting ? 'Deletando...' : 'Sim, delete o dispositivo'}
                                                                </Button>
                                                            </Grid>

                                                        </Grid>
                                                    </Popup>
                                                    <Warning
                                                        openPopup={openWarningPopup}
                                                        setOpenPopup={setOpenWarningPopup}
                                                        title='Erro ao editar'
                                                    >
                                                        <Grid item style={{ padding: '1%', margin: '1%' }}>
                                                            <Typography variant='subtitle1' style={{
                                                                fontWeight: 'bold',
                                                                color: '#757575',
                                                                fontSize: '1.5rem'
                                                            }}>
                                                                Não deixe espaços vazios para as câmeras
                                                            </Typography>
                                                            <Typography variant='subtitle2' style={{
                                                                fontWeight: 'bold',
                                                                color: '#757575',
                                                                fontSize: '1.2rem'
                                                            }}>
                                                                Apague as Informações ou termine de preencher-las
                                                            </Typography>
                                                        </Grid>
                                                    </Warning>
                                                    <Grid item xs={12} style={{ margin: '0 1%' }} container justify="space-evenly">
                                                        <PermissionWrapper route="/dispositivo/deletar/:id">
                                                            <Button
                                                                disabled={isDeleting}
                                                                variant="contained"
                                                                onClick={() => setOpenDeletePopup(true)}
                                                                color="secondary"
                                                                endIcon={<DeleteIcon />}
                                                            >
                                                                Deletar dispositivo
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            variant="contained"
                                                            form="device-modify"
                                                            color="primary"
                                                            endIcon={<SaveIcon />}
                                                        >
                                                            {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    items_container: {
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    left_side: {
        borderBottom: 'solid 2px',
    },
    right_side: {

    },
}));
