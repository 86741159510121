import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Toolbar,
    Paper,
    Button,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Checkbox,
    Switch
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import PercentageIcon from '../../assets/img/percentage.svg';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import InputTime from "../../components/InputTime";
import Select from "../../components/Select";
import Popup from '../../components/Popup';

import { usePersonTypes } from "../../hooks";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import mask from "../../utils/mask";

import api from '../../services/api';
import HelpTip from '../../components/HelpTip';
import { PermissionsContext } from '../../providers/Permissions';

const MonitoringTarget = ({ name, current, children }) => {
    if (name === current) return (
        <>
            {
                children
            }
        </>
    );
    else return (<></>);
}

export default function NotificationModify() {
    const classes = useStyles();

    const {
        PermissionWrapper,
    } = useContext(PermissionsContext);

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const { id } = useParams();
    const history = useHistory();

    const [refresh, setRefresh] = useState(true)
    const [notificationValues, setNotificationValues] = useState({
        active: false,
        name: null,
        description: null,
        whatsapp: false,
        email: false,
        pop_up: false,
        sex: null,
        emotion: null,
        glasses: null,
        mask: null,
        beard: null,
        mouth: null,
        eye: null,
        similarity: '',
        similarity_operation: null,
        current_temperature: '',
        temperature_operation: null,
        is_over_temperature: null,
        allowed_access: null,
        start_time: null,
        end_time: null,
        user_timeout: 3,
        unknown_users: false,
        monitoring_target: 'specific_people',
        self_notificate: false,
        action_type: null,
        notificate_dependant_of: false,
        starting_age_range: '',
        ending_age_range: '',
        standard_metadata: true,
        access_method: null,
        rejection_status: null,
    });
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();

    const [personTypeSearch, setPersonTypeSearch] = useState("");
    const [personTypeLimit, setPersonTypeLimit] = useState(10);
    const [personTypePage, setPersonTypePage] = useState(0);
    const [selectedPersonTypes, setSelectedPersonTypes] = useState([]);
    const { personTypes, total: personTypesTotal } = usePersonTypes({
        search: personTypeSearch,
        show_exceptions: true,
        limit: personTypeLimit,
        page: personTypePage
    });

    const [openUsersPopup, setOpenUsersPopup] = useState(false);
    const [openDevicesPopup, setOpenDevicesPopup] = useState(false);
    const [openCamerasPopup, setOpenCamerasPopup] = useState(false);
    const [openNotifyPopup, setOpenNotifyPopup] = useState(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const onEditorStateChange = (newState) => {
        setEditorState(newState);
    };

    const [customWhatsappMessageText, setCustomWhatsappMessageText] = useState('')

    const [usersSearch, setUsersSearch] = useState('');
    const [devicesSearch, setDevicesSearch] = useState('');
    const [camerasSearch, setCamerasSearch] = useState('');
    const [notifySearch, setNotifySearch] = useState('');
    const [orgsSearch, setOrgsSearch] = useState('');

    const [usersLimit, setUsersLimit] = useState(10);
    const [devicesLimit, setDevicesLimit] = useState(10);
    const [camerasLimit, setCamerasLimit] = useState(10);
    const [notifyLimit, setNotifyLimit] = useState(10);
    const [orgsLimit, setOrgsLimit] = useState(10);

    const [usersPage, setUsersPage] = useState(0);
    const [devicesPage, setDevicesPage] = useState(0);
    const [camerasPage, setCamerasPage] = useState(0);
    const [notifyPage, setNotifyPage] = useState(0);
    const [orgsPage, setOrgsPage] = useState(0);

    const [usersTotal, setUsersTotal] = useState(0);
    const [devicesTotal, setDevicesTotal] = useState(0);
    const [camerasTotal, setCamerasTotal] = useState(0);
    const [notifyTotal, setNotifyTotal] = useState(0);
    const [orgsTotal, setOrgsTotal] = useState(0);

    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [devices, setDevices] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);

    const [cameras, setCameras] = useState([]);
    const [selectedCameras, setSelectedCameras] = useState([]);

    const [toNotify, setToNotify] = useState([]);
    const [selectedToNotify, setSelectedToNotify] = useState([]);

    const [orgs, setOrgs] = useState([]);
    const [selectedOrgs, setSelectedOrgs] = useState([]);

    const [showTips, setShowTips] = useState(false); // reserved words

    const [onlySelectedUsers, setOnlySelectedUsers] = useState(false);
    const [onlySelectedToNotify, setOnlySelectedToNotify] = useState(false);

    const handleOnlySelectedUsers = () => {
        setUsersPage(0);
        setOnlySelectedUsers(!onlySelectedUsers);
    };

    const handleOnlySelectedToNotify = () => {
        setNotifyPage(0);
        setOnlySelectedToNotify(!onlySelectedToNotify);
    }

    useEffect(() => {
        const loadNotification = async () => {
            try {
                const result = await api.get(`/notification/view/${id}`);

                const { notification } = result.data;
                setSelectedUsers(notification.users);
                setSelectedDevices(notification.devices);
                setSelectedCameras(notification.cameras);
                setSelectedToNotify(notification.notify);
                setSelectedPersonTypes(notification.person_types)
                setSelectedOrgs(notification.organizations);
                setStartTime(notification.start_time);
                setEndTime(notification.end_time);
                setCustomWhatsappMessageText(notification.whatsapp_custom_message);
                setEditorState(EditorState.createWithContent(convertFromRaw(notification.email_raw_message)));
                delete notification.users;
                delete notification.devices;
                delete notification.cameras;
                delete notification.notify;
                delete notification.organizations;
                delete notification.start_time;
                delete notification.end_time;
                delete notification.id;
                delete notification.whatsapp_custom_message;
                delete notification.email_raw_message;
                delete notification.email_custom_message;
                console.log(notification)
                setNotificationValues(notification);
                console.log('setted notification', notificationValues);
                console.log('setted users: ', selectedUsers)
            } catch (err) {
                setMessage({
                    open: true,
                    variant: "error",
                    text: err.error,
                });
            }
        };
        loadNotification();
        // eslint-disable-next-line
    }, [id, history, refresh]);

    const saveNotification = async (values, formik) => {
        try {

            values.start_time = (startTime) ? startTime.toString() : null;
            values.end_time = (endTime) ? endTime.toString() : null;


            const notificationData = {
                ...values,
                users: selectedUsers,
                person_types: selectedPersonTypes,
                devices: selectedDevices,
                cameras: selectedCameras,
                notify: selectedToNotify,
                organizations: selectedOrgs,
                email_custom_message: `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`,
                whatsapp_custom_message: customWhatsappMessageText,
                email_raw_message: convertToRaw(editorState.getCurrentContent()),
            };
            console.log('new values: ', notificationData);
            const result = await api.post(`/notification/edit/${id}`, notificationData);
            const { notification } = result.data;

            formik.setSubmitting(false);
            console.log('modified')
            setRefresh(!refresh)
            setMessage({
                open: true,
                variant: 'success',
                text: `Notificação "${notification.name}" modificada com sucesso!`,
            });
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const result = await api.get('/user/list_by_relationship', {
                    params: {
                        search: usersSearch,
                        limit: usersLimit,
                        page: usersPage,
                        onlySelected: onlySelectedUsers,
                        selectedPeople: selectedUsers,
                    },
                });

                const { count, users } = result.data;

                setUsers(users);
                setUsersTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadUsers();
    }, [usersPage, usersLimit, usersSearch, selectedUsers, onlySelectedUsers]);

    useEffect(() => {
        const loadToNotify = async () => {
            try {
                const result = await api.get('/user/list_by_relationship', {
                    params: {
                        search: notifySearch,
                        limit: notifyLimit,
                        page: notifyPage,
                        onlySelected: onlySelectedToNotify,
                        selectedPeople: selectedToNotify,
                    },
                });

                const { count, users } = result.data;

                setToNotify(users);
                setNotifyTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadToNotify();
    }, [notifyPage, notifyLimit, notifySearch, onlySelectedToNotify, selectedToNotify]);

    useEffect(() => {
        const loadDevices = async () => {
            try {
                const result = await api.get('/device/list', {
                    params: {
                        search: devicesSearch,
                        limit: devicesLimit,
                        page: devicesPage,
                    },
                });

                const { count, devices } = result.data;

                let newDevices = [];
                devices.forEach(device => {
                    if (device.type === 'dhi') {
                        newDevices.push(device)
                    }
                })

                setDevices(newDevices);
                setDevicesTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadDevices();
    }, [devicesPage, devicesLimit, devicesSearch]);

    useEffect(() => {
        const loadCameras = async () => {
            try {
                const result = await api.get('/camera/list', {
                    params: {
                        search: camerasSearch,
                        limit: camerasLimit,
                        page: camerasPage,
                    },
                });

                const { count, cameras } = result.data;

                setCameras(cameras);
                setCamerasTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadCameras();
    }, [camerasPage, camerasLimit, camerasSearch]);

    useEffect(() => {
        const loadOrgs = async () => {
            try {
                const result = await api.get('/organizational_structure/index', {
                    params: {
                        search: orgsSearch,
                        limit: orgsLimit,
                        page: orgsPage,
                    },
                });

                const { count, orgs } = result.data;

                setOrgs(orgs);
                setOrgsTotal(count);
            } catch (err) {
                console.log(err);
            }
        };

        loadOrgs();
    }, [orgsSearch, orgsLimit, orgsPage]);

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const deleteNotification = async () => {
        try {
            setIsDeleting(true);

            const result = await api.delete(`/notification/delete/${id}`);

            const { notification } = result.data;

            setIsDeleting(false);
            setOpenDeletePopup(false);

            if (notification) {
                setOpenDeletePopup(false);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Notificação "${notification.name}"" deletada com sucesso!`,
                });
                setTimeout(() => {
                    history.goBack();
                }, 2000);
            }
        } catch (err) {
            setIsDeleting(false);
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    }

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const validateInputNumber = (setFieldValue, key, number, validateSize, lastValue) => {
        const pattern = /^[0-9]*$/;
        console.log(number)
        if (!number) {
            setFieldValue(key, '')
            return;
        } else if (!number.match(pattern)) {
            setFieldValue(key, lastValue)
            return;
        }
        console.log('is number')

        if (number.match(pattern) && !validateSize) {
            console.log("matches");
            setFieldValue(key, number);
        } else if (number.match(pattern) && validateSize) {
            console.log('size');
            if (number >= 0 && number <= 100) {
                setFieldValue(key, number);
            } else if (number >= 0 && number > 100) {
                setFieldValue(key, 100);
            }
        }
    }

    const handleNullifiedValue = (operationValue, operationName, setFieldValue, keyModifier) => {
        console.log('trying to modify ', operationName, operationValue, keyModifier);
        if (operationValue === null) {
            setFieldValue(keyModifier, '');
        }

        setFieldValue(operationName, operationValue);
    };

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'user':
                setUsersPage(newPage);
                break;
            case 'device':
                setDevicesPage(newPage);
                break;
            case 'camera':
                setCamerasPage(newPage);
                break;
            case 'notify':
                setNotifyPage(newPage);
                break;
            case 'person_type':
                setPersonTypePage(newPage);
                break;
            case 'organization':
                setOrgsPage(newPage);
                break;
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        const newLimit = parseInt(target.value, 10);
        switch (quantityOf) {
            case 'user':
                setUsersLimit(newLimit);
                setUsersPage(0);
                break;
            case 'device':
                setDevicesLimit(newLimit);
                setDevicesPage(0);
                break;
            case 'camera':
                setCamerasLimit(newLimit);
                setCamerasPage(0);
                break;
            case 'notify':
                setNotifyLimit(newLimit);
                setNotifyPage(0);
                break;
            case 'person_type':
                setPersonTypeLimit(newLimit);
                setPersonTypePage(0);
                break;
            case 'organization':
                setOrgsLimit(newLimit);
                setOrgsPage(0);
                break;
            default:
                console.log('Quantidade inválida');
        }
    };

    const manipulateCheckBox = (checkbox, isSelected, id) => {
        switch (checkbox) {
            case 'user':
                let newSelectedUsers = Object.assign([], selectedUsers);
                if (isSelected) {
                    newSelectedUsers.push(id);
                } else {
                    let index = selectedUsers.indexOf(id);
                    newSelectedUsers.splice(index, 1);
                }
                setSelectedUsers(newSelectedUsers);
                break;
            case 'notify':
                let newSelectedToNotify = Object.assign([], selectedToNotify);
                if (isSelected) {
                    newSelectedToNotify.push(id);
                } else {
                    let index = selectedToNotify.indexOf(id);
                    newSelectedToNotify.splice(index, 1);
                }
                setSelectedToNotify(newSelectedToNotify);
                break;
            case 'device':
                let newSelectedDevices = Object.assign([], selectedDevices);
                if (isSelected) {
                    newSelectedDevices.push(id);
                } else {
                    let index = selectedDevices.indexOf(id);
                    newSelectedDevices.splice(index, 1);
                }
                setSelectedDevices(newSelectedDevices);
                break;
            case 'camera':
                let newSelectedCameras = Object.assign([], selectedCameras);
                if (isSelected) {
                    newSelectedCameras.push(id);
                } else {
                    let index = selectedCameras.indexOf(id);
                    newSelectedCameras.splice(index, 1);
                }
                setSelectedCameras(newSelectedCameras);
                break;
            case 'person_type':
                let newSelectedPersonTypes = Object.assign([], selectedPersonTypes);
                if (isSelected) {
                    newSelectedPersonTypes.push(id);
                } else {
                    let index = selectedPersonTypes.indexOf(id);
                    newSelectedPersonTypes.splice(index, 1);
                }
                setSelectedPersonTypes(newSelectedPersonTypes);
                break;
            case 'organization':
                let newSelectedOrganizations = Object.assign([], selectedOrgs);
                if (isSelected) {
                    newSelectedOrganizations.push(id);
                } else {
                    let index = newSelectedOrganizations.indexOf(id);
                    newSelectedOrganizations.splice(index, 1);
                }
                setSelectedOrgs(newSelectedOrganizations);
                break;
            case 'all_users':
                let allUsers = [];
                if (isSelected) {
                    allUsers = Object.assign([], selectedUsers);
                    users.forEach(user => {
                        if (!allUsers.includes(user.id)) {
                            allUsers.push(user.id)
                        }
                    });
                } else {
                    let userIds = users.map(el => el.id);
                    allUsers = selectedUsers.filter(user => !userIds.includes(user));
                }
                setSelectedUsers(allUsers);
                break;
            case 'all_devices':
                let allDevices = [];
                if (isSelected) {
                    allDevices = Object.assign([], selectedDevices);
                    devices.forEach(device => {
                        if (!allDevices.includes(device.id)) {
                            allDevices.push(device.id);
                        }
                    });
                } else {
                    let deviceIds = devices.map(device => device.id);
                    allDevices = selectedDevices.filter(device => !deviceIds.includes(device));
                }
                setSelectedDevices(allDevices);
                break;
            case 'all_cameras':
                let allCameras = [];
                if (isSelected) {
                    allCameras = Object.assign([], selectedCameras);
                    cameras.forEach(camera => {
                        if (!allCameras.includes(camera.id)) {
                            allCameras.push(camera.id);
                        }
                    });
                } else {
                    let cameraIds = cameras.map(camera => camera.id);
                    allCameras = selectedCameras.filter(camera => !cameraIds.includes(camera));
                }
                setSelectedCameras(allCameras);
                break;
            case 'all_to_notify':
                let allToNotify = [];
                if (isSelected) {
                    allToNotify = Object.assign([], selectedToNotify);
                    toNotify.forEach(notify => {
                        if (!allToNotify.includes(notify.id)) {
                            allToNotify.push(notify.id);
                        }
                    });
                } else {
                    let toNotifyIds = toNotify.map(notify => notify.id);
                    allToNotify = selectedToNotify.filter(notify => !toNotifyIds.includes(notify));
                }
                setSelectedToNotify(allToNotify);
                break;
            case 'all_person_types':
                let allPersonTypes = [];
                if (isSelected) {
                    allPersonTypes = Object.assign([], selectedPersonTypes);
                    personTypes.forEach(personType => {
                        if (!allPersonTypes.includes(personType.id)) {
                            allPersonTypes.push(personType.id);
                        }
                    });
                } else {
                    let personTypesIDS = personTypes.map(personType => personType.id);
                    allPersonTypes = selectedToNotify.filter(personType => !personTypesIDS.includes(personType));
                }
                setSelectedPersonTypes(allPersonTypes);
                break;
            case 'all_organizations':
                let allOrganizations = [];
                if (isSelected) {
                    allOrganizations = Object.assign([], selectedOrgs);
                    orgs.forEach(org => {
                        if (!allOrganizations.includes(org.id)) {
                            allOrganizations.push(org.id);
                        }
                    });
                } else {
                    let orgsIds = orgs.map(org => org.id);
                    allOrganizations = selectedOrgs.filter(org => !orgsIds.includes(org));
                }
                setSelectedOrgs(allOrganizations);
                break;
            default:
                console.log('Tipo inválido');
        }
    };

    const handleChangeMonitoringTarget = (setFieldValue, value) => {
        setFieldValue('monitoring_target', value)
        if (value === 'specific_people') {
            setPersonTypeSearch("");
            setPersonTypeLimit(10);
            setPersonTypePage(0);
            setSelectedPersonTypes([]);

            setOrgsSearch("");
            setOrgsLimit(10);
            setOrgsPage(0);
            setSelectedOrgs([]);
        } else if (value === "person_type") {
            setUsersSearch("");
            setUsersLimit(10);
            setUsersPage(0);
            setSelectedUsers([]);
            setFieldValue('unknown_users', false);

            setOrgsSearch("");
            setOrgsLimit(10);
            setOrgsPage(0);
            setSelectedOrgs([]);
        } else if (value === "organization") {
            setPersonTypeSearch("");
            setPersonTypeLimit(10);
            setPersonTypePage(0);
            setSelectedPersonTypes([]);

            setUsersSearch("");
            setUsersLimit(10);
            setUsersPage(0);
            setSelectedUsers([]);
            setFieldValue('unknown_users', false);
        } else if (value === 'everyone') {
            setPersonTypeSearch("");
            setPersonTypeLimit(10);
            setPersonTypePage(0);
            setSelectedPersonTypes([]);
            setUsersSearch("");
            setUsersLimit(10);
            setUsersPage(0);
            setSelectedUsers([]);
            setFieldValue('unknown_users', false);
        }
    }

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Editar Notificação de Acesso
                        </Typography>
                    </Grid>
                    <Formik initialValues={notificationValues} onSubmit={saveNotification} enableReinitialize={true}>
                        {({
                            values,
                            setFieldValue,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <>
                                <Grid item xs={12}>
                                    <Paper alevation={3}>
                                        <form
                                            id="notification-modify"
                                            onSubmit={handleSubmit}
                                            className={classes.form}
                                        >
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Informações Básicas
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Input
                                                                label="Nome"
                                                                textFieldProps={{
                                                                    name: "name",
                                                                    onChange:
                                                                        handleChange,
                                                                    value: values.name,
                                                                    fullWidth: true,
                                                                    required: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Input
                                                                label="Descrição"
                                                                textFieldProps={{
                                                                    name: "description",
                                                                    onChange:
                                                                        handleChange,
                                                                    value: values.description,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                                                            <label className={classes.statusLabel}>Status da Notificação</label>
                                                            <Grid container spacing={1} alignItems="center" justify="center">
                                                                <Typography>Desativada</Typography>
                                                                <Switch
                                                                    color="primary"
                                                                    checked={values.active}
                                                                    onChange={({ target }) => setFieldValue('active', target.checked)}
                                                                    inputProps={{
                                                                        'aria-label': 'notification-status'
                                                                    }}
                                                                />
                                                                <Typography>Ativada</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Tipos de Notificação
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Select
                                                                label="Whatsapp"
                                                                options={[
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "whatsapp",
                                                                    name: "whatsapp",
                                                                    value: values.whatsapp,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Select
                                                                label="Email"
                                                                options={[
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "email",
                                                                    name: "email",
                                                                    value: values.email,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Select
                                                                label="Pop-up"
                                                                options={[
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "pop_up",
                                                                    name: "pop_up",
                                                                    value: values.pop_up,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Condições
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Período de tempo
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={3}>
                                                            <InputTime
                                                                label="De"
                                                                onValidFormat={(value) => setStartTime(value)}
                                                                onInvalidFormat={(value) =>
                                                                    setMessage({
                                                                        open: true,
                                                                        variant: 'error',
                                                                        text: "Valor de tempo inválido",
                                                                    })
                                                                }

                                                                textFieldProps={{
                                                                    name: 'start_time',
                                                                    placeholder: '00:00',
                                                                    onChange: (e, value) => setStartTime(value),
                                                                    value: startTime,
                                                                    fullWidth: true,
                                                                    required: false,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <InputTime
                                                                label="Até"
                                                                onValidFormat={(value) => setEndTime(value)}
                                                                onInvalidFormat={(value) => {
                                                                    if (value !== undefined) {
                                                                        setMessage({
                                                                            open: true,
                                                                            variant: 'error',
                                                                            text: `Valor de tempo inválido`,
                                                                        });
                                                                    } else {
                                                                        setEndTime(null);
                                                                    }
                                                                }
                                                                }

                                                                textFieldProps={{
                                                                    name: 'end_time',
                                                                    placeholder: '00:00',
                                                                    onChange: (e, value) => setEndTime(value),
                                                                    value: endTime,
                                                                    fullWidth: true,
                                                                    required: false,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Input
                                                                label="Tempo entre notificações (segundos)"
                                                                numberProps={{
                                                                    min: 0,
                                                                }}
                                                                textFieldProps={{
                                                                    name: "user_timeout",
                                                                    id: "user_timeout",
                                                                    onChange:
                                                                        ({ target }) => validateInputNumber(setFieldValue, 'user_timeout', target.value, false, values.user_timeout),
                                                                    value: values.user_timeout,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="h6"
                                                                className={
                                                                    classes.formSectionTitle
                                                                }
                                                            >
                                                                Sobre a notificação
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Status do Acesso"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Permitido",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Negado",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "allowed_access",
                                                                    name: "allowed_access",
                                                                    value: values.allowed_access,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Detalhes do Acesso"
                                                                options={[
                                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                                    { label: 'Dia não autorizado', value: 'unauthorized_day' },
                                                                    { label: 'Horário não autorizado', value: 'unauthorized_time' },
                                                                    { label: 'Local não autorizado', value: 'unauthorized_location' },
                                                                    { label: 'Acima da temperatura', value: 'over_temperature' },
                                                                    { label: 'Sem máscara', value: 'without_mask' },
                                                                    { label: 'Pessoa desconhecida', value: 'unknown_person' },
                                                                    { label: 'Entrada Repetida', value: 're_entry' },
                                                                    { label: 'Credencial Desconhecida', value: 'unknown_card' },
                                                                    { label: 'Credencial Inválida', value: 'invalid_card' },
                                                                    { label: 'A pessoa relacionada ja está sendo acompanhada', value: 'related_already_with_a_dependant' },
                                                                    { label: 'A pessoa relacionada ainda não acessou', value: 'related_did_not_access' },
                                                                    { label: 'Fora do horário de acompanhamento', value: 'companion_out_of_time' },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "rejection_status",
                                                                    name: "rejection_status",
                                                                    value: values.rejection_status,
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Método de Acesso"
                                                                options={[
                                                                    { label: '|---- Selecione um valor ----|', value: null },
                                                                    { label: 'Facial', value: 'facial' },
                                                                    { label: 'Cartão', value: 'card' },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "access_method",
                                                                    name: "access_method",
                                                                    value: values.access_method,
                                                                    onChange: handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Tipo de Ação"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Entrada",
                                                                        value: "entry",
                                                                    },
                                                                    {
                                                                        label: "Saída",
                                                                        value: "exit",
                                                                    },
                                                                    {
                                                                        label: "Acesso Interno",
                                                                        value: "internal"
                                                                    }
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "action_type",
                                                                    name: "action_type",
                                                                    value: values.action_type,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Operação de Reconhecimento"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Maior",
                                                                        value: "gt",
                                                                    },
                                                                    {
                                                                        label: "Maior ou igual",
                                                                        value: "gte",
                                                                    },
                                                                    {
                                                                        label: "Igual",
                                                                        value: "eq",
                                                                    },
                                                                    {
                                                                        label: "Menor",
                                                                        value: "lt",
                                                                    },
                                                                    {
                                                                        label: "Menor ou igual",
                                                                        value: "lte",
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "similarity_operation",
                                                                    name: "similarity_operation",
                                                                    value: values.similarity_operation,
                                                                    onChange:
                                                                        (e, value) => {
                                                                            handleNullifiedValue(value.props.value, 'similarity_operation', setFieldValue, 'similarity')
                                                                        },
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} style={{ display: values.similarity_operation ? 'block' : 'none' }}>
                                                            <Input
                                                                label="Nível de Reconhecimento"
                                                                numberProps={{
                                                                    min: 0,
                                                                    max: 100
                                                                }}
                                                                textFieldProps={{
                                                                    name: "similarity",
                                                                    id: "similarityl",
                                                                    icon: <PercentageIcon />,
                                                                    iconPosition: "start",
                                                                    onChange:
                                                                        ({ target }) => validateInputNumber(setFieldValue, 'similarity', target.value, true, values.similarity),
                                                                    value: values.similarity,
                                                                    fullWidth: true,
                                                                    required: (values.similarity_operation) ? true : false,
                                                                    disabled: (values.similarity_operation) ? false : true
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Temperatura
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Temperatura"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Maior",
                                                                        value: "gt",
                                                                    },
                                                                    {
                                                                        label: "Maior ou igual",
                                                                        value: "gte",
                                                                    },
                                                                    {
                                                                        label: "Igual",
                                                                        value: "eq",
                                                                    },
                                                                    {
                                                                        label: "Menor",
                                                                        value: "lt",
                                                                    },
                                                                    {
                                                                        label: "Menor ou igual",
                                                                        value: "lte",
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "temperature_operation",
                                                                    name: "temperature_operation",
                                                                    value: values.temperature_operation,
                                                                    onChange:
                                                                        (e, value) => {
                                                                            handleNullifiedValue(value.props.value, 'temperature_operation', setFieldValue, 'current_temperature')
                                                                        },
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} style={{ display: values.temperature_operation ? 'block' : 'none' }}>
                                                            <Input
                                                                label="Valor da Temperatura"
                                                                numberProps={{
                                                                    min: 0,
                                                                    max: 100
                                                                }}
                                                                textFieldProps={{
                                                                    name: "current_temperature",
                                                                    id: "current_temperature",
                                                                    onChange:
                                                                        ({ target }) => { validateInputNumber(setFieldValue, 'current_temperature', target.value, false, values.current_temperature) },
                                                                    value: values.current_temperature,
                                                                    fullWidth: true,
                                                                    required: (values.temperature_operation) ? true : false,
                                                                    disabled: (values.temperature_operation) ? false : true
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Acima da Temperatura"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "is_over_temperature",
                                                                    name: "is_over_temperature",
                                                                    value: values.is_over_temperature,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Características
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Expressão Facial"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Sorriso",
                                                                        value: "Smile",
                                                                    },
                                                                    {
                                                                        label: "Raiva",
                                                                        value: "Anger",
                                                                    },
                                                                    {
                                                                        label: "Tristeza",
                                                                        value: "Sadness",
                                                                    },
                                                                    {
                                                                        label: "Desgosto",
                                                                        value: "Disgust",
                                                                    },
                                                                    {
                                                                        label: "Medo",
                                                                        value: "Fear",
                                                                    },
                                                                    {
                                                                        label: "Surpresa",
                                                                        value: "Surprise",
                                                                    },
                                                                    {
                                                                        label: "Neutra",
                                                                        value: "Neutral",
                                                                    },
                                                                    {
                                                                        label: "Rindo",
                                                                        value: "Laugh",
                                                                    },
                                                                    {
                                                                        label: "Feliz",
                                                                        value: "Happy",
                                                                    },
                                                                    {
                                                                        label: "Confusa",
                                                                        value: "Confused",
                                                                    },
                                                                    {
                                                                        label: "Gritando",
                                                                        value: "Scream",
                                                                    },
                                                                    {
                                                                        label: "De lado",
                                                                        value: "Lookaside",
                                                                    },
                                                                    {
                                                                        label: "Desconhecida",
                                                                        value: "Unknown",
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "emotion",
                                                                    name: "emotion",
                                                                    value: values.emotion,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Sexo"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Feminino",
                                                                        value: "woman",
                                                                    },
                                                                    {
                                                                        label: "Masculino",
                                                                        value: "man",
                                                                    },
                                                                    {
                                                                        label: "Desconhecido",
                                                                        value: "unknown",
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "sex",
                                                                    name: "sex",
                                                                    value: values.sex,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Óculos"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Não detectado",
                                                                        value: 0,
                                                                    },
                                                                    {
                                                                        label: "Sem",
                                                                        value: 1,
                                                                    },
                                                                    {
                                                                        label: "Óculos normal",
                                                                        value: 2,
                                                                    },
                                                                    {
                                                                        label: "Óculos de sol",
                                                                        value: 3,
                                                                    },
                                                                    {
                                                                        label: "Armação preta",
                                                                        value: 4,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "glasses",
                                                                    name: "glasses",
                                                                    value: values.glasses,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Grid container style={{ display: 'flex', alignItems: 'flex-end' }} spacing={3}>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Barba"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Não detectada",
                                                                        value: 0,
                                                                    },
                                                                    {
                                                                        label: "Sem barba",
                                                                        value: 1,
                                                                    },
                                                                    {
                                                                        label: "Com barba",
                                                                        value: 2,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "beard",
                                                                    name: "beard",
                                                                    value: values.beard,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Boca"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Não detectada",
                                                                        value: 0,
                                                                    },
                                                                    {
                                                                        label: "Fechada",
                                                                        value: 1,
                                                                    },
                                                                    {
                                                                        label: "Aberta",
                                                                        value: 2,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "mouth",
                                                                    name: "mouth",
                                                                    value: values.mouth,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Olho"
                                                                options={[
                                                                    {
                                                                        label: "|--- Selecione um valor ---|",
                                                                        value: null,
                                                                    },
                                                                    {
                                                                        label: "Não detectado",
                                                                        value: 0,
                                                                    },
                                                                    {
                                                                        label: "Fechado",
                                                                        value: 1,
                                                                    },
                                                                    {
                                                                        label: "Aberta",
                                                                        value: 2,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "eye",
                                                                    name: "eye",
                                                                    value: values.eye,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Grid container style={{ display: 'flex', alignItems: 'flex-end' }} spacing={3}>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Máscara"
                                                                options={[
                                                                    { label: '|--- Selecione um valor ---|', value: null },
                                                                    { label: 'Sim', value: 2 },
                                                                    { label: 'Não', value: 1 },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "mask",
                                                                    name: "mask",
                                                                    value: values.mask,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Input
                                                                label="Inicio de faixa etária"
                                                                numberProps={{
                                                                    min: 0,
                                                                    max: 100
                                                                }}
                                                                textFieldProps={{
                                                                    name: "starting_age_range",
                                                                    id: "starting_age_range",
                                                                    onChange:
                                                                        ({ target }) => { validateInputNumber(setFieldValue, 'starting_age_range', target.value, false, values.starting_age_range) },
                                                                    value: values.starting_age_range,
                                                                    fullWidth: true,
                                                                    required: false,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Input
                                                                label="Fim de faixa etária"
                                                                numberProps={{
                                                                    min: 0,
                                                                    max: 100
                                                                }}
                                                                textFieldProps={{
                                                                    name: "ending_age_range",
                                                                    id: "ending_age_range",
                                                                    onChange:
                                                                        ({ target }) => { validateInputNumber(setFieldValue, 'ending_age_range', target.value, false, values.ending_age_range) },
                                                                    value: values.ending_age_range,
                                                                    fullWidth: true,
                                                                    required: false,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        className={
                                                            classes.formSectionTitle
                                                        }
                                                    >
                                                        Adições
                                                    </Typography>
                                                    <Grid container justify="space-around" spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenUsersPopup(true)}
                                                            >
                                                                <MonitoringTarget
                                                                    name="specific_people"
                                                                    current={values.monitoring_target}
                                                                >
                                                                    Pessoas monitoradas {`(${selectedUsers.length})`}
                                                                </MonitoringTarget>
                                                                <MonitoringTarget
                                                                    name="person_type"
                                                                    current={values.monitoring_target}
                                                                >
                                                                    Tipos de pessoa monitorados {`(${selectedPersonTypes.length})`}
                                                                </MonitoringTarget>
                                                                <MonitoringTarget
                                                                    name="everyone"
                                                                    current={values.monitoring_target}
                                                                >
                                                                    Pessoas monitoradas {`(${usersTotal})`}
                                                                </MonitoringTarget>
                                                                <MonitoringTarget
                                                                    name="organization"
                                                                    current={values.monitoring_target}
                                                                >
                                                                    Organizações monitoradas {`(${selectedOrgs.length})`}
                                                                </MonitoringTarget>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenNotifyPopup(true)}
                                                            >
                                                                Pessoas notificadas {`(${selectedToNotify.length})`}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Grid container justify="space-around" spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenDevicesPopup(true)}
                                                            >
                                                                Adcionar dispositivos {`(${selectedDevices.length})`}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth={true}
                                                                onClick={() => setOpenCamerasPopup(true)}
                                                            >
                                                                Adcionar câmeras {`(${selectedCameras.length})`}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="h6"
                                                            className={
                                                                classes.formSectionTitle
                                                            }
                                                        >
                                                            Mensagens Personalizadas
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Metadados do Acesso"
                                                                tip={(
                                                                    <Typography>
                                                                        Os metadados são informações como: nome da notificação, dispositivo de acesso, horário, nome da pessoa e etc.
                                                                    </Typography>
                                                                )}
                                                                options={[
                                                                    {
                                                                        label: "Ativado",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Desativado",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    id: "standard_metadata",
                                                                    name: "standard_metadata",
                                                                    value: values.standard_metadata,
                                                                    onChange:
                                                                        handleChange,
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Select
                                                                label="Mostrar Palavras Reservadas"
                                                                options={[
                                                                    {
                                                                        label: "Sim",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Não",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                textFieldProps={{
                                                                    value: showTips,
                                                                    onChange:
                                                                        ({ target: { value } }) => setShowTips(value),
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ display: showTips ? 'block' : 'none', marginBottom: '10px', marginTop: '10px' }}>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="h7"
                                                                className={
                                                                    classes.formSectionTitle
                                                                }
                                                            >
                                                                Palavras reservadas
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ borderRadius: '3px', padding: '5px' }}>
                                                            <TableContainer component={Paper}>
                                                                <Table className={classes.table} size="small" aria-label="notify list">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableHead}>Campo</TableCell>
                                                                            <TableCell className={classes.tableHead}>Tipo</TableCell>
                                                                            <TableCell className={classes.tableHead}>Descrição</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #id#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                ID da pessoa (Ex: 12)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #name#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Nome da pessoa (Ex.: José Ferreira)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #place#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Local em que a pessoa foi vista (Ex.: Corredor A)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #schedule#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Rotina do dia da pessoa (Ex.: 1º : 08:00 - 09:20 A)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #device_data#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Dados do dispositivo (Validação remota, autorizações e etc)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #date#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Dia em que pessoa foi vista (Ex.: 12/01/2022)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #time#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Hora em que pessoa foi vista (Ex.: 12:30)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #datetime#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Dia e hora em que a pessoa foi vista (Ex.: 12/01/2022 12:30)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #device#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Dispositivo em que a pessoa foi vista (Ex.: Camera 01)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #status#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Status do acesso (Permitido ou Negado)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #doc#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Tipo e número do documento da pessoa, se cadastrada
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #organization#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                organização da pessoa, se cadastrada
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #person_type#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Tipo da pessoa, se cadastrada (Ex.: Colaborador)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #age#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Idade da pessoa, se cadastrada (Ex.: 21)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #sex#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Sexo da pessoa, se cadastrada (Feminino / Masculino / Desconhecido)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #action#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Tipo de ação (Ex.: Entrada, Saída, Interna)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #detail#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Detalhes do acesso (Dia/Local Não Autorizado, Entrada Repetida e etc)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #method#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera/DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Método de Acesso (Facial ou Cartão)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #emotion#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Emoção apresentada na detecção (Ex.: Surpesa)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #glasses#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Se a pessoa está usando óculos e o tipo (Ex.: Óculos de sol)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #beard#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Se a pessoa tem barba (Ex.: Sem barba)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #mouth#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Se a pessoa está com a boca aberta ou não (Ex.: Boca aberta)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #eye#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Camera
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Se a pessoa está com os olhos abertos ou não (Ex.: Olhos fechados)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #temperature#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Temperatura detectada (Ex.: 37°C / Não definida)
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                                                #credential#
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                DHI
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableBody}>
                                                                                Número da credencial usada no acesso
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    variant="h7"
                                                                    className={
                                                                        classes.formSectionTitle
                                                                    }
                                                                >
                                                                    Email
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Paper alevation={3}>
                                                                    <Grid item xs={12} style={{ minHeight: '100%', paddingBottom: '50px' }}>
                                                                        <Editor
                                                                            editorState={editorState}
                                                                            editorClassName={classes.editor}
                                                                            toolbarClassName={classes.editorToolbar}
                                                                            wrapperClassName={classes.editorWrapper}
                                                                            onEditorStateChange={onEditorStateChange}
                                                                        />
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    variant="h7"
                                                                    className={
                                                                        classes.formSectionTitle
                                                                    }
                                                                >
                                                                    Whatsapp/Pop-up
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ height: '100%' }}>
                                                                <textarea
                                                                    placeholder="Escreva sua mensagem"
                                                                    multiline
                                                                    minRows={20}
                                                                    style={{ width: '100%', border: '1px solid #c6c6c6', height: '96.75%', resize: 'none', padding: '10px', fontSize: '1.2rem', color: grey[600], fontFamily: 'Arial, sans-serif' }}
                                                                    value={customWhatsappMessageText}
                                                                    onChange={({ target }) => { setCustomWhatsappMessageText(target.value) }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.formSection
                                                    }
                                                >
                                                    <Grid container justify="space-evenly" spacing={3}>
                                                        <PermissionWrapper route="/notificação/deletar/:id">
                                                            <Grid item>
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    endIcon={<DeleteIcon />}
                                                                    onClick={() => setOpenDeletePopup(true)}
                                                                >
                                                                    Apagar Notificação
                                                                </Button>
                                                            </Grid>
                                                        </PermissionWrapper>
                                                        <Grid item>
                                                            <Button
                                                                disabled={isSubmitting}
                                                                type="submit"
                                                                variant="contained"
                                                                form="notification-modify"
                                                                color="primary"
                                                                endIcon={<SaveIcon />}
                                                            >
                                                                {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                                <Popup
                                    openPopup={openUsersPopup}
                                    setOpenPopup={setOpenUsersPopup}
                                    title={
                                        values.monitoring_target === "specific_people" ? (
                                            "Adcione pessoas á serem monitoradas"
                                        ) : (
                                            "Adcione tipos de pessoas á serem monitorados"
                                        )
                                    }
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid item xs={6}>
                                                <Select
                                                    label="Alvo de Monitoramento"
                                                    options={[
                                                        {
                                                            label: "Pessoas Especificas",
                                                            value: "specific_people",
                                                        },
                                                        {
                                                            label: "Tipos de Pessoa",
                                                            value: "person_type",
                                                        },
                                                        {
                                                            label: "Organizações",
                                                            value: "organization",
                                                        },
                                                        {
                                                            label: "Todas as pessoas cadastradas",
                                                            value: "everyone",
                                                        },
                                                    ]}
                                                    textFieldProps={{
                                                        id: "monitoring_target",
                                                        name: "monitoring_target",
                                                        value: values.monitoring_target,
                                                        onChange: ({ target: { value } }) => handleChangeMonitoringTarget(setFieldValue, value),
                                                        fullWidth: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                            <MonitoringTarget
                                                name="specific_people"
                                                current={values.monitoring_target}
                                            >
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Input
                                                            icon={<SearchIcon style={{ color: grey[400] }} />}
                                                            iconPosition="start"
                                                            textFieldProps={{
                                                                placeholder: 'Buscar…',
                                                                onChange: ({ target }) => setUsersSearch(target.value),
                                                                fullWidth: true,
                                                                value: usersSearch,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Paper
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                cursor: 'pointer',
                                                            }}
                                                            variant="outlined"
                                                            onClick={handleOnlySelectedUsers}
                                                        >
                                                            <Checkbox
                                                                name="only_selected_people"
                                                                checked={onlySelectedUsers}
                                                                color="primary"
                                                            />
                                                            <div>
                                                                <Typography variant="subtitle2">
                                                                    Mostrar apenas pessoas selecionadas
                                                                </Typography>
                                                            </div>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Paper
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                cursor: 'pointer',
                                                            }}
                                                            variant="outlined"
                                                            onClick={() => setFieldValue('unknown_users', !values.unknown_users)}
                                                        >
                                                            <Checkbox
                                                                name="unknown_users"
                                                                checked={values.unknown_users}
                                                                color="primary"
                                                            />
                                                            <div>
                                                                <Typography variant="subtitle2">
                                                                    Notificar sobre pessoas desconhecidas
                                                                </Typography>
                                                            </div>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </MonitoringTarget>
                                            <MonitoringTarget
                                                name="person_type"
                                                current={values.monitoring_target}
                                            >
                                                <Grid item xs={6}>
                                                    <Input
                                                        icon={<SearchIcon style={{ color: grey[400] }} />}
                                                        iconPosition="start"
                                                        textFieldProps={{
                                                            placeholder: 'Buscar…',
                                                            onChange: ({ target }) => setPersonTypeSearch(target.value),
                                                            fullWidth: true,
                                                            value: personTypeSearch,
                                                        }}
                                                    />
                                                </Grid>
                                            </MonitoringTarget>
                                            <MonitoringTarget
                                                name="organization"
                                                current={values.monitoring_target}
                                            >
                                                <Grid item xs={6}>
                                                    <Input
                                                        icon={<SearchIcon style={{ color: grey[400] }} />}
                                                        iconPosition="start"
                                                        textFieldProps={{
                                                            placeholder: 'Buscar…',
                                                            onChange: ({ target }) => setOrgsSearch(target.value),
                                                            fullWidth: true,
                                                            value: orgsSearch,
                                                        }}
                                                    />
                                                </Grid>
                                            </MonitoringTarget>
                                            <MonitoringTarget
                                                name="everyone"
                                                current={values.monitoring_target}
                                            >
                                                <Grid item xs={4}>
                                                    <Paper
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                        }}
                                                        variant="outlined"
                                                        onClick={() => setFieldValue('unknown_users', !values.unknown_users)}
                                                    >
                                                        <Checkbox
                                                            name="unknown_users"
                                                            checked={values.unknown_users}
                                                            color="primary"
                                                        />
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                Notificar sobre pessoas desconhecidas
                                                            </Typography>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                            </MonitoringTarget>
                                        </Grid>
                                        <MonitoringTarget
                                            name="specific_people"
                                            current={values.monitoring_target}
                                        >
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table className={classes.table} size="small" aria-label="users list">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.tableHead}>ID</TableCell>
                                                                <TableCell className={classes.tableHead}>Nome</TableCell>
                                                                <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                                                <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                                                <TableCell className={classes.tableHead}>
                                                                    <Checkbox
                                                                        name="addAllUsers"
                                                                        color="primary"
                                                                        checked={users.every(el => {
                                                                            return selectedUsers.includes(el.id);
                                                                        })}
                                                                        onChange={({ target }) => manipulateCheckBox('all_users', target.checked)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {!!users && users.map((user) => (
                                                                <TableRow key={user.id}>
                                                                    <TableCell className={classes.tableBody} component="th" scope="row">
                                                                        {user.id}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {user.name}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {user.doc_type && user?.doc_type === 'ic' ? 'RG' :
                                                                            user?.doc_type === 'passport' ? 'Passaporte' :
                                                                                user?.doc_type === 'other' ? 'Outro' :
                                                                                    user?.doc_type === 'unknown' ? 'CPF' :
                                                                                        'Não identificado'}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {user.doc_number ? user.doc_number : "Não identificado"}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        <Checkbox
                                                                            name="addUser"
                                                                            color="primary"
                                                                            checked={selectedUsers.includes(user.id)}
                                                                            onChange={({ target }) => manipulateCheckBox('user', target.checked, user.id)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30]}
                                                    component="div"
                                                    count={usersTotal}
                                                    rowsPerPage={usersLimit}
                                                    page={usersPage}
                                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                    labelRowsPerPage="Registros por página"
                                                    onChangePage={(_, newPage) => changePage(_, newPage, 'user')}
                                                    onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'user')}
                                                />
                                            </Grid>
                                        </MonitoringTarget>
                                        <MonitoringTarget
                                            name="person_type"
                                            current={values.monitoring_target}
                                        >
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table className={classes.table} size="small" aria-label="person type list">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.tableHead}>ID</TableCell>
                                                                <TableCell className={classes.tableHead}>Nome</TableCell>
                                                                <TableCell className={classes.tableHead}>
                                                                    <Checkbox
                                                                        name="addAllPersonTypes"
                                                                        color="primary"
                                                                        checked={personTypes.every(el => {
                                                                            return selectedPersonTypes.includes(el.id);
                                                                        })}
                                                                        onChange={({ target }) => manipulateCheckBox('all_person_types', target.checked)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {!!personTypes && personTypes.map(personType => (
                                                                <TableRow key={personType.id}>
                                                                    <TableCell className={classes.tableBody} component="th" scope="row">
                                                                        {personType.id}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {personType.name}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        <Checkbox
                                                                            name="addPersonType"
                                                                            color="primary"
                                                                            checked={selectedPersonTypes.includes(personType.id)}
                                                                            onChange={({ target }) => manipulateCheckBox('person_type', target.checked, personType.id)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30]}
                                                    component="div"
                                                    count={personTypesTotal}
                                                    rowsPerPage={personTypeLimit}
                                                    page={personTypePage}
                                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                    labelRowsPerPage="Registros por página"
                                                    onChangePage={(_, newPage) => changePage(_, newPage, 'person_type')}
                                                    onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'person_type')}
                                                />
                                            </Grid>
                                        </MonitoringTarget>
                                        <MonitoringTarget
                                            name="organization"
                                            current={values.monitoring_target}
                                        >
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table className={classes.table} size="small" aria-label="organization list">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.tableHead}>ID</TableCell>
                                                                <TableCell className={classes.tableHead}>Nome</TableCell>
                                                                <TableCell className={classes.tableHead}>Parente</TableCell>
                                                                <TableCell className={classes.tableHead}>
                                                                    <Checkbox
                                                                        name="addAllOrgs"
                                                                        color="primary"
                                                                        checked={orgs.every(el => {
                                                                            return selectedOrgs.includes(el.id);
                                                                        })}
                                                                        onChange={({ target }) => manipulateCheckBox('all_organizations', target.checked)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {!!orgs && orgs.map(org => (
                                                                <TableRow key={org.id}>
                                                                    <TableCell className={classes.tableBody} component="th" scope="row">
                                                                        {org.id}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {org.name}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        {org.father_node?.name ?? "-"}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tableBody}>
                                                                        <Checkbox
                                                                            name="addOrg"
                                                                            color="primary"
                                                                            checked={selectedOrgs.includes(org.id)}
                                                                            onChange={({ target }) => manipulateCheckBox('organization', target.checked, org.id)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30]}
                                                    component="div"
                                                    count={orgsTotal}
                                                    rowsPerPage={orgsLimit}
                                                    page={orgsPage}
                                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                    labelRowsPerPage="Registros por página"
                                                    onChangePage={(_, newPage) => changePage(_, newPage, 'organization')}
                                                    onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'organization')}
                                                />
                                            </Grid>
                                        </MonitoringTarget>
                                        <MonitoringTarget
                                            name="everyone"
                                            current={values.monitoring_target}
                                        >
                                        </MonitoringTarget>
                                    </Grid>
                                </Popup>
                                <Popup
                                    openPopup={openNotifyPopup}
                                    setOpenPopup={setOpenNotifyPopup}
                                    title="Adcione pessoas a receber essa notificação"
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item xs={6}>
                                                    <Input
                                                        icon={<SearchIcon style={{ color: grey[400] }} />}
                                                        iconPosition="start"
                                                        textFieldProps={{
                                                            placeholder: 'Buscar…',
                                                            onChange: ({ target }) => setNotifySearch(target.value),
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Paper
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                        }}
                                                        variant="outlined"
                                                        onClick={handleOnlySelectedToNotify}
                                                    >
                                                        <Checkbox
                                                            name="only_selected_people_to_notify"
                                                            checked={onlySelectedToNotify}
                                                            color="primary"
                                                        />
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                Mostrar apenas pessoas selecionadas
                                                            </Typography>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Paper
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                        }}
                                                        variant="outlined"
                                                        onClick={() => setFieldValue('self_notificate', !values.self_notificate)}
                                                    >
                                                        <Checkbox
                                                            name="self_notificate"
                                                            checked={values.self_notificate}
                                                            color="primary"
                                                        />
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                Notificar pessoas monitoradas
                                                            </Typography>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Paper
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                        }}
                                                        variant="outlined"
                                                        onClick={() => setFieldValue('notificate_dependant_of', !values.notificate_dependant_of)}
                                                    >
                                                        <Checkbox
                                                            name="notificate_dependant_of"
                                                            checked={values.notificate_dependant_of}
                                                            color="primary"
                                                        />
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                Notificar vínculos de dependência
                                                                &nbsp;
                                                                <HelpTip
                                                                    tip={
                                                                        <Typography>
                                                                            Notificar as pessoas das quais as monitoradas tem vínculo de dependência
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </Typography>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small" aria-label="notify list">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHead}>ID</TableCell>
                                                            <TableCell className={classes.tableHead}>Nome</TableCell>
                                                            <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                                            <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                                            <TableCell className={classes.tableHead}>Telefone</TableCell>
                                                            <TableCell className={classes.tableHead}>Email</TableCell>
                                                            <TableCell className={classes.tableHead}>
                                                                <Checkbox
                                                                    name="addAllToNotify"
                                                                    color="primary"
                                                                    // checked={selectedToNotify.length === toNotify.length}
                                                                    checked={toNotify.every(el => {
                                                                        return selectedToNotify.includes(el.id);
                                                                    })}
                                                                    onChange={({ target }) => manipulateCheckBox('all_to_notify', target.checked)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {!!toNotify && toNotify.map(notify => (
                                                            <TableRow key={notify.id}>
                                                                <TableCell className={classes.tableBody} component="th" scope="row">
                                                                    {notify.id}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.name}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.doc_type && notify?.doc_type === 'ic' ? 'RG' :
                                                                        notify?.doc_type === 'passport' ? 'Passaporte' :
                                                                            notify?.doc_type === 'other' ? 'Outro' :
                                                                                notify?.doc_type === 'unknown' ? 'CPF' :
                                                                                    'Não identificado'}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.doc_number ? notify.doc_number : "Não identificado"}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.phone !== null ? ((notify.phone.length === 10) ? (
                                                                        mask('(##) ####-####', notify.phone)
                                                                    ) : (
                                                                        mask('(##) #####-####', notify.phone)
                                                                    )) : 'Não informado'}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {notify.email !== null ? (notify.email) : 'Não informado'}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    <Checkbox
                                                                        name="addToNotify"
                                                                        color="primary"
                                                                        checked={selectedToNotify.includes(notify.id)}
                                                                        onChange={({ target }) => manipulateCheckBox('notify', target.checked, notify.id)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 30]}
                                                component="div"
                                                count={notifyTotal}
                                                rowsPerPage={notifyLimit}
                                                page={notifyPage}
                                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                labelRowsPerPage="Registros por página"
                                                onChangePage={(_, newPage) => changePage(_, newPage, 'notify')}
                                                onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'notify')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Popup>
                                <Popup
                                    openPopup={openDevicesPopup}
                                    setOpenPopup={setOpenDevicesPopup}
                                    title="Adcione dispositivos á notificação"
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Input
                                                icon={<SearchIcon style={{ color: grey[400] }} />}
                                                iconPosition="start"
                                                textFieldProps={{
                                                    placeholder: 'Buscar…',
                                                    onChange: ({ target }) => setDevicesSearch(target.value),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small" aria-label="devices list">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHead}>ID</TableCell>
                                                            <TableCell className={classes.tableHead}>Nome</TableCell>
                                                            <TableCell className={classes.tableHead}>Descrição</TableCell>
                                                            <TableCell className={classes.tableHead}>
                                                                <Checkbox
                                                                    name="addAllDevices"
                                                                    color="primary"
                                                                    // checked={selectedDevices.length === devices.length}
                                                                    checked={devices.every(el => {
                                                                        return selectedDevices.includes(el.id);
                                                                    })}
                                                                    onChange={({ target }) => manipulateCheckBox('all_devices', target.checked)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {!!devices && devices.map(device => (
                                                            <TableRow key={device.id}>
                                                                <TableCell className={classes.tableBody} component="th" scope="row">
                                                                    {device.id}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {device.name}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {device.description}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    <Checkbox
                                                                        name="addDevice"
                                                                        color="primary"
                                                                        checked={selectedDevices.includes(device.id)}
                                                                        onChange={({ target }) => manipulateCheckBox('device', target.checked, device.id)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 30]}
                                                component="div"
                                                count={devicesTotal}
                                                rowsPerPage={devicesLimit}
                                                page={devicesPage}
                                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                labelRowsPerPage="Registros por página"
                                                onChangePage={(_, newPage) => changePage(_, newPage, 'device')}
                                                onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'device')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Popup>
                                <Popup
                                    openPopup={openCamerasPopup}
                                    setOpenPopup={setOpenCamerasPopup}
                                    title="Adcione câmeras á notificação"
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Input
                                                icon={<SearchIcon style={{ color: grey[400] }} />}
                                                iconPosition="start"
                                                textFieldProps={{
                                                    placeholder: 'Buscar…',
                                                    onChange: ({ target }) => setCamerasSearch(target.value),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small" aria-label="camera list">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHead}>ID</TableCell>
                                                            <TableCell className={classes.tableHead}>Nome</TableCell>
                                                            <TableCell className={classes.tableHead}>Tipo</TableCell>
                                                            <TableCell className={classes.tableHead}>
                                                                <Checkbox
                                                                    name="addAllCameras"
                                                                    color="primary"
                                                                    // checked={selectedCameras.length === cameras.length}
                                                                    checked={cameras.every(el => {
                                                                        return selectedCameras.includes(el.id);
                                                                    })}
                                                                    onChange={({ target }) => manipulateCheckBox('all_cameras', target.checked)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {!!cameras && cameras.map(camera => (
                                                            <TableRow key={camera.id}>
                                                                <TableCell className={classes.tableBody} component="th" scope="row">
                                                                    {camera.id}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {camera.name}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    {(camera.type === 'entry') ? 'Entrada' : (camera.type === 'internal') ? 'Interna' : (camera.type === 'exit') ? 'Saída' : 'Desconhecido'}
                                                                </TableCell>
                                                                <TableCell className={classes.tableBody}>
                                                                    <Checkbox
                                                                        name="addCamera"
                                                                        color="primary"
                                                                        checked={selectedCameras.includes(camera.id)}
                                                                        onChange={({ target }) => manipulateCheckBox('camera', target.checked, camera.id)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 20, 30]}
                                                component="div"
                                                count={camerasTotal}
                                                rowsPerPage={camerasLimit}
                                                page={camerasPage}
                                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                                labelRowsPerPage="Registros por página"
                                                onChangePage={(_, newPage) => changePage(_, newPage, 'camera')}
                                                onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'camera')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Popup>
                                <Popup
                                    openPopup={openDeletePopup}
                                    setOpenPopup={setOpenDeletePopup}

                                >
                                    <Grid container xs={12} style={{
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '0 3% 2% 3%',

                                    }}>
                                        <Typography variant='h5'
                                            style={{
                                                fontWeight: 'bold',
                                                color: '#9e9e9e'
                                            }}
                                        >
                                            Deseja mesmo deletar "{notificationValues.name}"?
                                        </Typography>
                                        <Grid item style={{
                                            marginTop: '50px'
                                        }}>
                                            <Button
                                                disabled={isDeleting}
                                                variant="contained"
                                                onClick={() => deleteNotification()}
                                                color="secondary"
                                                endIcon={<DeleteIcon />}
                                            >
                                                {isDeleting ? 'Deletando...' : 'Sim'}
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </Popup>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        padding: theme.spacing(3, 3),
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    formSectionTitle: {
        fontWeight: 'bold',
        color: grey[700],
        marginBottom: theme.spacing(1),
    },
    removeImageButton: {
        borderRadius: 0,
        marginBottom: theme.spacing(1),
    },
    statusLabel: {
        display: 'block',
        fontWeight: 'bold',
        color: grey[500],
        marginBottom: theme.spacing(1),
    },
    editor: {
        height: '540px',
        padding: '10px',
    },
    editorToolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid #cdcdcd'
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    tableRow: {
        transition: '0.2s',
        '&:hover': {
            backgroundColor: grey[300],
        }
    },
}));

