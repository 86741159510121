import React, { useState, useEffect, useContext } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Fab,
    Typography,
    Button
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import SideMenu from '../../components/SideMenu';
import Input from '../../components/Input';
import Popup from '../../components/Popup';
import Alert from '../../components/Alert';

import api from '../../services/api';
import { PermissionsContext } from '../../providers/Permissions';

export default function DeviceGroupList() {
    const classes = useStyles();

    const {
        PermissionWrapper,
        PermissionOneOfWrapper,
    } = useContext(PermissionsContext);

    const [deviceGroups, setDeviceGroups] = useState([]);
    const [search, setSearch] = useState('');
    const [refresh, setRefresh] = useState(true)
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const [openDevicesInfoModal, setOpenDevicesInfoModal] = useState(false);
    const [openCamerasInfoModal, setOpenCamerasInfoModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});

    const handleOpenInfoModal = (type, group) => {
        setSelectedGroup(group);

        switch (type) {
            case 'devices':
                setOpenDevicesInfoModal(true);
                break;
            case 'cameras':
                setOpenCamerasInfoModal(true);
                break;
            default: console.log('Invalid type');
        }
    }

    const handleCloseDevicesInfo = () => {
        setOpenDevicesInfoModal(false);
        setSelectedGroup({});
    };
    const handleCloseCamerasInfo = () => {
        setOpenCamerasInfoModal(false);
        setSelectedGroup({});
    }

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletingGroupId, setDeletingGroupId] = useState();
    const handleDelete = (id) => {
        setDeletingGroupId(id);
        setOpenDeletePopup(true);
    };
    const deleteGroup = async (colateralRemove) => {
        setIsDeleting(true);
        try {
            let group;
            deviceGroups.forEach((element) => {
                if (element.id === deletingGroupId) {
                    group = element
                }
            })

            const result = await api.delete(`/device_group/delete/${deletingGroupId}`, {
                params: {
                    colateral: colateralRemove,
                }
            });
            const { status } = result;

            if (status === 200) {
                setDeletingGroupId(null);
                setOpenDeletePopup(false);
                setRefresh(!refresh);
                setMessage({
                    open: true,
                    variant: 'success',
                    text: `Grupo de dispositivos "${group.name}" deletado com sucesso!`,
                });
            }
        } catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
        setIsDeleting(false);
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await api.get('/device_group/list', {
                    params: {
                        search,
                        limit,
                        page,
                    },
                });

                const { count, DeviceGroups } = result.data;

                setDeviceGroups(DeviceGroups);
                setTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadData();
    }, [search, refresh, page, limit]);

    const changePage = (_, newPage) => {
        setPage(newPage);
    };

    const quantityPerPage = ({ target }) => {
        setLimit(parseInt(target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Grupos de Dispositivos
                        </Typography>
                    </Grid>
                    <Grid item spacing={5} xs={12}>
                        <Input
                            icon={<SearchIcon style={{ color: grey[400] }} />}
                            iconPosition="start"
                            textFieldProps={{
                                placeholder: 'Buscar…',
                                onChange: ({ target }) => setSearch(target.value),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="device group list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Descrição</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivos</TableCell>
                                        <TableCell className={classes.tableHead}>Câmeras</TableCell>
                                        <PermissionOneOfWrapper
                                            routes={[
                                                "/grupos_de_dispositivos/modificar/:id",
                                                "/grupos_de_dispositivos/deletar/:id",
                                            ]}
                                        >
                                            <TableCell className={classes.tableHead}>Ações</TableCell>
                                        </PermissionOneOfWrapper>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!deviceGroups && deviceGroups.map(current => (
                                        <TableRow key={current.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {current.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {current.description ? current.description : '-'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    current.devices?.length
                                                        ? (
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => handleOpenInfoModal("devices", current)}
                                                            >
                                                                {current.devices.length}
                                                            </Button>
                                                        )
                                                        : (
                                                            <Button
                                                                variant="contained"
                                                            >
                                                                0
                                                            </Button>
                                                        )
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {
                                                    current.cameras?.length
                                                        ? (
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => handleOpenInfoModal("cameras", current)}
                                                            >
                                                                {current.cameras.length}
                                                            </Button>
                                                        )
                                                        : (
                                                            <Button
                                                                variant="contained"
                                                            >
                                                                0
                                                            </Button>
                                                        )
                                                }
                                            </TableCell>
                                            <PermissionOneOfWrapper
                                                routes={[
                                                    "/grupos_de_dispositivos/modificar/:id",
                                                    "/grupos_de_dispositivos/deletar/:id",
                                                ]}
                                            >
                                                <TableCell className={classes.tableBody}>
                                                    <>
                                                        <PermissionWrapper route="/grupos_de_dispositivos/modificar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                className={classes.actionButton}
                                                                href={`/grupos_de_dispositivos/modificar/${current.id}`}
                                                                id={`edit`}
                                                            >
                                                                <EditIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                        <PermissionWrapper route="/grupos_de_dispositivos/deletar/:id">
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                size="small"
                                                                color="secondary"
                                                                className={classes.actionButton}
                                                                onClick={() => handleDelete(current.id)}
                                                                id={`delete`}
                                                            >
                                                                <DeleteIcon style={{ fontSize: 20 }} />
                                                            </Button>
                                                        </PermissionWrapper>
                                                    </>
                                                </TableCell>
                                            </PermissionOneOfWrapper>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={total}
                            rowsPerPage={limit}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={changePage}
                            onChangeRowsPerPage={quantityPerPage}
                        />
                    </Grid>
                </Grid>
                <Popup
                    openPopup={openDeletePopup}
                    setOpenPopup={setOpenDeletePopup}
                    title="Atenção"
                >
                    <Grid container xs={12} style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 3% 2% 3%',

                    }}>
                        <Typography variant='h5'
                            style={{
                                fontWeight: 'bold',
                                color: '#696969',
                                textAlign: 'justify',
                                textIndent: '20px',
                            }}

                        >
                            Deseja também remover os registros de pessoas nos respectivos dispositivos
                            que foram vinculados através desse grupo? Essa operação pode demorar um pouco
                            a ser completada e não pode ser cancelada.
                            <br /><br />
                            <i>Para não realizar nenhuma das operações, apenas feche essa janela.</i>
                        </Typography>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={12} md={9} style={{
                                marginTop: '50px'
                            }}>
                                <Button
                                    disabled={isDeleting}
                                    variant="contained"
                                    onClick={() => deleteGroup(true)}
                                    color="primary"
                                    id="confirm"
                                    fullWidth
                                >
                                    {isDeleting ? 'Deletando...' : 'Sim, remova o grupo e os registros nos dispositivos'}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={9} style={{
                                marginTop: '10px'
                            }}>
                                <Button
                                    disabled={isDeleting}
                                    variant="contained"
                                    onClick={() => deleteGroup(false)}
                                    color="secondary"
                                    id="confirm"
                                    fullWidth
                                >
                                    {isDeleting ? 'Deletando...' : 'Não, remova apenas o grupo'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Popup>
                <Popup
                    openPopup={openDevicesInfoModal}
                    setOpenPopup={setOpenDevicesInfoModal}
                    title="Dispositivos"
                    onClose={handleCloseDevicesInfo}
                    width="45%"
                >
                    <Grid container>
                        {
                            selectedGroup?.devices?.map(device => (
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        style={{
                                            fontWeight: 700,
                                            color: "#696969"
                                        }}
                                    >
                                        &bull; {device.name} - {
                                            device.dhi_type === "entry" ? "Entrada" :
                                                device.dhi_type === "exit" ? "Saída" :
                                                    "-"
                                        }
                                    </Typography>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Popup>
                <Popup
                    openPopup={openCamerasInfoModal}
                    setOpenPopup={setOpenDevicesInfoModal}
                    title="Câmeras"
                    onClose={handleCloseCamerasInfo}
                    width="45%"
                >
                    <Grid container>
                        {
                            selectedGroup?.cameras?.map(camera => (
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        style={{
                                            fontWeight: 700,
                                            color: "#696969"
                                        }}
                                    >
                                        &bull; {camera.name} ({camera.device?.name})
                                    </Typography>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Popup>
                <PermissionWrapper route="/grupos_de_dispositivos/criar">
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.fabButton}
                        href="/grupos_de_dispositivos/criar"
                    >
                        <AddIcon />
                    </Fab>
                </PermissionWrapper>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    table: {
        minWidth: 500,
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
    },
    actionButton: {
        margin: theme.spacing(0, .5),
        padding: theme.spacing(.5, 0),
        minWidth: 30,
    },
}));