import axios from 'axios';

import config from '../configs/config';
import storage from '../utils/storage';

const { host, port, useSsl } = config;

const api = axios.create({
    baseURL: `${useSsl ? "https" : "http"}://${host}:${port}/api`,
});

api.interceptors.request.use(async config => {
    const token = storage.getItem('@Identifik:token');

    if (token)
        config.headers.Authorization = `Bearer ${token}`;

    return config;
});

api.interceptors.response.use(
    response => response,
    error => {
        if (!error.response)
            return Promise.reject({ error: 'Não foi possível concluir a operação, tente novamente' });

        return Promise.reject(error.response.data);
    },
);

export default api;
