import React, { useState, useEffect, useRef } from 'react';
import {
    Toolbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Grid,
    Typography,
    Button,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
    Tooltip,
    IconButton,
    FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { metrics } from '../../assets/styles';
import { grey, green, red } from '@material-ui/core/colors';

import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import SideMenu from '../../components/SideMenu';
import Popup from '../../components/Popup';
import Input from '../../components/Input';
import Alert from '../../components/Alert';
import Autocomplete from '../../components/Autocomplete';
import AccessPicker from '../../components/AccessPicker';

import api from '../../services/api';

const StatusTip = ({ success, type, content }) => {

    function getTipIcon() {
        switch (type) {
            case 'check':
                return <CheckIcon style={{ color: getTipColor() }} />;
            case 'error':
                return <CloseIcon style={{ color: getTipColor() }} />;
            case 'image':
                return <AccountCircleIcon style={{ color: getTipColor() }} />;
            default:
                throw new Error("Invalid tip type");
        }
    }

    function getTipColor() {
        if (success) {
            return green[700];
        } else {
            return red[700];
        }
    }

    return (
        <Tooltip title={
            <Typography variant='subtitle1' style={{
                fontSize: '1rem',
            }}>
                {content}
            </Typography>
        } arrow>
            <IconButton style={{ padding: 0 }}>
                {getTipIcon()}
            </IconButton>
        </Tooltip>
    );
};

export default function ExportPeopleToDevice() {
    const classes = useStyles();

    const accessRef = useRef(null);

    const [openUsersPopup, setOpenUsersPopup] = useState(false);
    const [usersSearch, setUsersSearch] = useState('');
    const [usersLimit, setUsersLimit] = useState(10);
    const [usersPage, setUsersPage] = useState(0);
    const [usersTotal, setUsersTotal] = useState(0);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [submitting, setSubmitting] = useState(false);

    const [message, setMessage] = useState({
        open: false,
        variant: "",
        text: "",
    });

    const [selectedUser, setSelectedUser] = useState({});

    const [openReportPopup, setOpenReportPopup] = useState(false);

    function manageReport(user) {
        setSelectedUser(user);
        setOpenReportPopup(true);
    }

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: "",
            text: "",
        });
    };

    const [personTypeSearch, setPersonSearch] = useState('');
    const [selectedPersonType, setSelectedPersonType] = useState();
    const [personTypes, setPersonTypes] = useState([{ name: '', id: 0 }]);
    useEffect(() => {
        const findTypes = async () => {
            try {
                const result = await api.get('/person_type/list', {
                    params: {
                        show_exceptions: true,
                        limit: 30,
                    }
                });

                const { personTypes } = result.data;
                setPersonTypes(personTypes);
            } catch (err) {
                console.log(err)
            }
        }

        findTypes();
    }, [personTypeSearch]);

    useEffect(() => {
        setSelectedUsers([]);
    }, [selectedPersonType]);

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const result = await api.get('/user/list', {
                    params: {
                        search: usersSearch,
                        limit: usersLimit,
                        page: usersPage,
                        filters: {
                            face_image: {
                                active: true,
                                value: true
                            },
                            person_type: {
                                active: selectedPersonType?.id ? true : false,
                                value: selectedPersonType?.id
                            }
                        }
                    },
                });

                const { count, users } = result.data;

                setUsers(users);
                setUsersTotal(count);
            }
            catch (err) {
                console.log(err);
            }
        };

        loadUsers();
    }, [usersPage, usersLimit, usersSearch, selectedPersonType]);

    const exportPeople = async () => {
        try {
            setSubmitting(true);

            const { camera_id, device_id, groups } = accessRef.current.getValues(); 

            const result = await api.post(
                "/export_people/export",
                {
                    users: selectedUsers,
                    cameras: camera_id,
                    devices: device_id,
                    groups: groups,
                    selectingEveryone,
                    person_type: selectedPersonType?.id
                }
            );

            if (result.status) {
                setMessage({
                    open: true,
                    variant: "success",
                    text: "Pessoas exportadas com sucesso"
                })
            }

            setSubmitting(false);

            setUsersSearch('');
            setUsersLimit(10);
            setUsersPage(0);

            setSelectedUsers([]);
            setSelectingEveryone(false);

            accessRef.current.resetValues();

        } catch (err) {
            setSubmitting(false);
            console.log(err)
            setMessage({
                open: true,
                variant: "error",
                text: err.error,
            });
        }
    };

    const changePage = (_, newPage, pageOf) => {
        switch (pageOf) {
            case 'user':
                setUsersPage(newPage);
                break;
            default:
                console.log('Página invalida');
        }
    };

    const quantityPerPage = (target, quantityOf) => {
        switch (quantityOf) {
            case 'user':
                setUsersLimit(parseInt(target.value, 10));
                setUsersPage(0);
                break;
            default:
                console.log('Quantidade inválida');
        }
    };

    const manipulateCheckBox = (checkbox, isSelected, id) => {
        switch (checkbox) {
            case 'user':
                let newSelectedUsers = Object.assign([], selectedUsers);

                let newIsSelectedUsers = selectingEveryone ? !isSelected : isSelected;
                if (newIsSelectedUsers) {
                    newSelectedUsers.push(id);
                } else {
                    let index = selectedUsers.indexOf(id);
                    newSelectedUsers.splice(index, 1);
                }
                setSelectedUsers(newSelectedUsers);
                break;
            case 'all_users':
                let allUsers = [];
                let newIsSelectedAllUsers = selectingEveryone ? !isSelected : isSelected;
                if (newIsSelectedAllUsers) {
                    allUsers = Object.assign([], selectedUsers);
                    users.forEach(user => {
                        if (!allUsers.includes(user.id)) {
                            allUsers.push(user.id)
                        }
                    });
                } else {
                    let userIds = users.map(el => el.id);
                    allUsers = selectedUsers.filter(user => !userIds.includes(user));
                }
                setSelectedUsers(allUsers);
                break;
            default:
                console.log('Tipo inválido');
        }
    };

    const getDeviceStatusTip = (relation) => {

        if (relation?.uid && relation?.error_type === null) {
            return <StatusTip success={true} type='check' content='Cadastrado(a) com sucesso' />;
        } else if (relation?.uid && relation?.error_type === "invalid_face_image") {
            return <StatusTip success={false} type='image' content='Cadastrado(a) com imagem inválida' />;
        } else if (!relation?.uid) {
            return <StatusTip success={false} type='error' content='Não cadastrado(a)' />
        } else {
            return;
        }

    }

    const [selectingEveryone, setSelectingEveryone] = useState(false);

    function isEveryoneSelected() {
        let selected = users.every(el => {
            if (selectingEveryone) return !selectedUsers.includes(el.id);
            else return selectedUsers.includes(el.id);
        });

        return selected;
    }

    function isSelectedUser(id) {
        if (selectingEveryone) return !selectedUsers.includes(id);
        else return selectedUsers.includes(id);
    }

    function toggleSelectingEveryone() {
        setSelectingEveryone(!selectingEveryone);
        setSelectedUsers([]);
    }

    function amountOfExportingUsers() {
        if (selectingEveryone) return usersTotal - selectedUsers.length;
        else return selectedUsers.length;
    }

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Exportar Pessoas para Dispositivos e Câmeras
                        </Typography>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid spacing={5} item xs={12}>
                            <Paper alevation={3} style={{ padding: '1.5%' }}>
                                <Grid container spacing={3}>
                                    <Grid
                                        container
                                        spacing={2}
                                        justify="space-evenly"
                                    >
                                        <AccessPicker xs={12} md={6} ref={accessRef}>
                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth={true}
                                                    onClick={() => setOpenUsersPopup(true)}
                                                >
                                                    Selecionar Pessoas ({`${amountOfExportingUsers()}`})
                                                </Button>
                                            </Grid>
                                        </AccessPicker>
                                    </Grid>
                                    <Grid container justify="center" style={{ marginTop: "20px" }}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={!!submitting}
                                                onClick={async () => await exportPeople()}
                                            >
                                                {
                                                    !submitting ? "Exportar" : "Exportando..."
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
            <Popup
                openPopup={openUsersPopup}
                setOpenPopup={setOpenUsersPopup}
                title="Pessoas a serem exportadas"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            style={{
                                fontWeight: 400,
                                color: "#696969"
                            }}
                        >
                            OBS.: Apenas pessoas com face cadastrada estarão presentes nessa listagem
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <Grid item xs={4}>
                            <Input
                                icon={<SearchIcon style={{ color: grey[400] }} />}
                                iconPosition="start"
                                textFieldProps={{
                                    placeholder: 'Buscar…',
                                    onChange: ({ target }) => setUsersSearch(target.value),
                                    fullWidth: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                placeholder="Tipo de pessoa"
                                options={personTypes}
                                getOptionLabel={({ name }) => name || ''}
                                onChange={(_, value) => setSelectedPersonType(value)}
                                getOptionSelected={(option, value) => (option.name === value.name)}
                                onInputChange={(_, value) => setPersonSearch(value)}
                                value={selectedPersonType}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                label="Selecionar todas as pessoas"
                                control={
                                    <Checkbox
                                        name="selectEveryone"
                                        color="primary"
                                        checked={selectingEveryone}
                                        onChange={toggleSelectingEveryone}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="users list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>ID</TableCell>
                                        <TableCell className={classes.tableHead}>Nome</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo de Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Nº do Documento</TableCell>
                                        <TableCell className={classes.tableHead}>Tipo</TableCell>
                                        <TableCell className={classes.tableHead}>Dispositivos</TableCell>
                                        <TableCell className={classes.tableHead}>Relatório</TableCell>
                                        <TableCell className={classes.tableHead}>
                                            <Checkbox
                                                name="addAllUsers"
                                                color="primary"
                                                // checked={selectedUsers.length === users.length}
                                                checked={isEveryoneSelected()}
                                                onChange={({ target }) => manipulateCheckBox('all_users', target.checked)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!users && users.map(user => (
                                        <TableRow key={user.id}>
                                            <TableCell className={classes.tableBody} component="th" scope="row">
                                                {user.id}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.name}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.doc_type && user?.doc_type === 'ic' ? 'RG' :
                                                    user?.doc_type === 'passport' ? 'Passaporte' :
                                                        user?.doc_type === 'other' ? 'Outro' :
                                                            user?.doc_type === 'unknown' ? 'CPF' :
                                                                '-'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.doc_number ? user.doc_number : "-"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.PersonType ? user.PersonType.name : "-"}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                {user.devices?.length ? (
                                                    user.devices.every(device => {
                                                        // console.log(user)
                                                        if (device.relation[0]?.uid) return true;
                                                        else return false;
                                                    }) ? (
                                                        <Chip
                                                            label={<CheckIcon className={classes.authorizationIcon} />}
                                                            size="medium"
                                                            className={classes.authorizedAccess}
                                                        />
                                                    ) : (
                                                        <Chip
                                                            label={<CloseIcon className={classes.authorizationIcon} />}
                                                            size="medium"
                                                            className={classes.unauthorizedAccess}
                                                        />
                                                    )
                                                ) : '-'}
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Grid justify='space-around' container>
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        size="small"
                                                        color="primary"
                                                        style={{ background: '#5b8262' }}
                                                        className={classes.actionButton}
                                                        onClick={() => manageReport(user)}
                                                    >
                                                        <AssignmentIcon style={{ fontSize: 20 }} />
                                                    </Button>
                                                </Grid>
                                            </TableCell>
                                            <TableCell className={classes.tableBody}>
                                                <Checkbox
                                                    name="addUser"
                                                    color="primary"
                                                    checked={isSelectedUser(user.id)}
                                                    onChange={({ target }) => manipulateCheckBox('user', target.checked, user.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={usersTotal}
                            rowsPerPage={usersLimit}
                            page={usersPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                            labelRowsPerPage="Registros por página"
                            onChangePage={(_, newPage) => changePage(_, newPage, 'user')}
                            onChangeRowsPerPage={({ target }) => quantityPerPage(target, 'user')}
                        />
                    </Grid>
                </Grid>
            </Popup>
            <Popup
                openPopup={openReportPopup}
                setOpenPopup={setOpenReportPopup}
                title={`Dispositivos e Câmeras de acesso para ${selectedUser?.name}`}
                width='50%'
            >
                <Grid container spacing={4}>
                    {
                        (
                            !selectedUser?.devices?.filter(device => device.type === 'dhi').length &&
                            !selectedUser?.devices?.filter(device => device.type !== 'dhi').length
                        ) && (
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        fontWeight: 900
                                    }}
                                >
                                    Essa pessoa não tem vínculo com nenhum dispositivo
                                </Typography>
                            </Grid>
                        )
                    }
                    {
                        !!selectedUser?.devices?.filter(device => device.type === 'dhi').length && (
                            <Grid item xs={12}>
                                <Typography variant='h5'>
                                    Leitores Faciais
                                </Typography>
                                <Grid container spacing={1}>
                                    {
                                        selectedUser?.devices?.filter(device => device.type === 'dhi')?.map(device => (
                                            <Grid item xs={4} key={`${device.id}`}>
                                                <Typography variant='subtitle1'>
                                                    - {device.name} {getDeviceStatusTip(device.relation[0])}
                                                </Typography>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        !!selectedUser?.devices?.filter(device => device.type !== 'dhi').length && (
                            <Grid item xs={12}>
                                <Typography variant='h5'>
                                    NVRs & Câmeras
                                </Typography>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        paddingTop: '10px'
                                    }}
                                >
                                    {
                                        selectedUser?.devices?.filter(device => device.type !== 'dhi')?.map(device => (
                                            <Grid item xs={12}>
                                                <Accordion style={{ boxShaddow: 'none' }}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`${device.name}-content`}
                                                        id={`${device.name}-header`}
                                                    >
                                                        <Typography className={classes.heading}>
                                                            {device.name} {getDeviceStatusTip(device.relation[0])}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container>
                                                            {
                                                                selectedUser?.cameras?.filter(camera => camera.device_id === device.id).map(camera => (
                                                                    <Grid key={`${camera.id}`} item xs={12} md={4}>
                                                                        <Typography>
                                                                            - {camera.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <Grid container>
                            <Typography variant='h5' style={{ color: grey[700] }}>
                                Referência
                            </Typography>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <StatusTip success={true} type='check' content='Cadastrado(a) com sucesso' />
                                <Typography variant='subtitle1' style={{ color: grey[700] }}>
                                    Cadastrado(a) com sucesso
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <StatusTip success={false} type='image' content='Cadastrado(a) com imagem inválida' />
                                <Typography variant='subtitle1' style={{ color: grey[700] }}>
                                    Cadastrado(a) com imagem inválida
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <StatusTip success={false} type='error' content='Não cadastrado(a)' />
                                <Typography variant='subtitle1' style={{ color: grey[700] }}>
                                    Não cadastrado(a)
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popup>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: `calc(100% - ${metrics.drawerWidth}px)`,
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    smallTitle: {
        color: grey[600],
        marginLeft: theme.spacing(1),
        fontSize: 16,
    },
    filter: {
        margin: theme.spacing(0, 0, 3, 0),
    },
    dateTimeInput: {
        marginRight: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: '95%',
        width: '100%',
    },
    table: {
        width: '100%',
    },
    tableHead: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: grey[500],
    },
    tableBody: {
        color: grey[800],
        textAlign: 'center',
    },
    authorizedAccess: {
        backgroundColor: green[700],
        color: '#FFF',
    },
    unauthorizedAccess: {
        backgroundColor: red[700],
        color: '#FFF',
    },
    authorizationIcon: {
        color: '#FFF',
    },
}));
