import React, { useState, useEffect } from 'react';
import { Toolbar, Paper, Button, Grid, Typography, Checkbox } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import TextArea from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import SaveIcon from '@material-ui/icons/Save';
import { Formik } from 'formik';

import SideMenu from '../../components/SideMenu';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import Select from '../../components/Select';

import api from '../../services/api';
import config from '../../configs/config';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

export default function CaseModify() {
    const { id } = useParams();
    const classes = useStyles();

    const [caseData, setCaseData] = useState({
        name: '',
        description: '',
        case_type_id: null,
        files: [],
    });
    const [casesTypes, setCasesTypes] = useState([]);
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    useEffect(() => {
        fetchCase();
        fetchCaseTypes();
        fetchCaseFiles();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

    }, [right]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        }
        else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        }
        else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    function resetList() {
        setLeft([]);
        setRight([]);
        setChecked([]);
    }

    const checkType = (file, type) => file.type.includes(type);

    const customList = (title, items) => (
        <Card elevation={3}>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selecionado(s)`}
            />
            <Divider />
            <List
                sx={{
                    //width: '50%',
                    //flex: 1,
                    height: 500,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <>
                                {checkType(value, 'image') && (
                                    <img
                                        src={`http://${config.host}:${config.port}/midias/evidencia/${value.name}`}
                                        width="320"
                                        alt={value?.name ?? "..."}
                                    />
                                )}
                                {checkType(value, 'video') && (
                                    <video width="320" height="240" controls>
                                        <source
                                            src={`http://${config.host}:${config.port}/midias/evidencia/${value.name}`}
                                            type={value.type}
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                )}
                                {checkType(value, 'audio') && (
                                    <audio controls>
                                        <source
                                            src={`http://${config.host}:${config.port}/midias/evidencia/${value.name}`}
                                            type={value.type}
                                        />
                                        Your browser does not support the audio element.
                                    </audio>
                                )}
                            </>
                            <ListItemText id={labelId} primary={value.name} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };

    const resetForm = formik => {
        formik.resetForm();
    };

    async function fetchCase() {
        try {
            const result = await api.get(`/case/view/${id}`);

            const {name, description, files, case_type} = result.data;

            setCaseData({
                name,
                description,
                files,
                case_type_id: case_type?.id,
            });
            setLeft([...files]);
        }
        catch (err) {
            console.log(err);
        }
    }

    async function fetchCaseFiles() {
        try {
            const result = await api.get('/case_file/list', {
                params: {
                    // search,
                    // limit,
                    // page,
                    without_case: 1,
                },
            });

            const {cases_files} = result.data;

            setRight(cases_files);
        }
        catch (err) {
            console.log(err);
        }
    }

    async function fetchCaseTypes() {
        try {
            const result = await api.get('/case_type/list', {
                params: {
                    // search,
                    // limit,
                    // page,
                },
            });

            const {cases_types} = result.data;

            setCasesTypes(cases_types);
        }
        catch (err) {
            console.log(err);
        }
    }

    const saveCase = async (values, formik) => {
        try {
            const data = {
                ...values,
                files: [],
            };

            if (left)
                left.map(file => data.files.push(file.id));

            const result = await api.post(`/case/modify/${id}`, data);

            const {name} = result.data;

            resetForm(formik);
            resetList();

            await fetchCaseFiles();
            await fetchCase();

            formik.setSubmitting(false);

            setMessage({
                open: true,
                variant: 'success',
                text: `Caso: ${name} modificado com sucesso!`,
            });
        }
        catch (err) {
            setMessage({
                open: true,
                variant: 'error',
                text: err.error,
            });
        }
    };

    return (
        <div className={classes.root}>
            <SideMenu />
            <main className={classes.content}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.title}>
                            Cadastrar Caso
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={caseData}
                        enableReinitialize={true}
                        onSubmit={saveCase}
                    >
                        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                            <>
                                <Grid container spacing={5}>
                                    <Grid spacing={5} item xs={12}>
                                        <Paper alevation={3} style={{ padding: '1.5%' }}>
                                            <form id="custom-input-create" onSubmit={handleSubmit} className={classes.form}>
                                                <Grid container xs={12} md={12} spacing={5}>
                                                    <Grid container item xs={12} md={12}>
                                                        <Grid item xs={12} md={4}>
                                                            <Input
                                                                label="Nome"
                                                                textFieldProps={{
                                                                    name: 'name',
                                                                    id: 'name',
                                                                    placeholder: 'Nome do campo',
                                                                    onChange: handleChange,
                                                                    value: values.name,
                                                                    fullWidth: true,
                                                                    required: true,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Box style={{marginLeft: 15}}>
                                                                <Select
                                                                    label="Tipo"
                                                                    options={casesTypes.map(caseType => ({
                                                                        label: caseType.name,
                                                                        value: caseType.id,
                                                                    }))}
                                                                    textFieldProps={{
                                                                        name: 'case-type-id',
                                                                        id: 'case_type_id',
                                                                        placeholder: 'Tipo',
                                                                        onChange: ({target}) => setFieldValue('case_type_id', target.value),
                                                                        value: values.case_type_id || '',
                                                                        fullWidth: true,
                                                                        required: true,
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} md={12}>
                                                        <TextArea
                                                            name="description"
                                                            id="description"
                                                            placeholder="Digite suas anotações"
                                                            onChange={handleChange}
                                                            value={values.description}
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows={10}
                                                        />
                                                    </Grid>
                                                    <Grid item container xs={12} spacing={2} justifyContent="center" alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" style={{color: '#9e9e9e', fontSize: '1rem', fontWeight: 'bold'}}>
                                                                Lista de Evidências
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item style={{flex: 1}}>{customList('Evidências vinculadas', left)}</Grid>
                                                        <Grid item>
                                                            <Grid container direction="column" alignItems="center">
                                                            <Button
                                                                sx={{ my: 0.5 }}
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleCheckedRight}
                                                                disabled={leftChecked.length === 0}
                                                                aria-label="move selected right"
                                                            >
                                                                &gt;
                                                            </Button>
                                                            <Button
                                                                sx={{ my: 0.5 }}
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleCheckedLeft}
                                                                disabled={rightChecked.length === 0}
                                                                aria-label="move selected left"
                                                            >
                                                                &lt;
                                                            </Button>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item style={{flex: 1}}>{customList('Evidências não vinculadas', right)}</Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            variant="contained"
                                                            form="custom-input-create"
                                                            color="primary"
                                                            endIcon={<SaveIcon />}
                                                        >
                                                            {isSubmitting ? 'Salvando...' : 'Salvar dados'}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Formik>
                </Grid>
                {!!message.open && (
                    <Alert
                        open={message.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        severity={message.variant}
                        onClose={closeMessage}
                    >
                        {message.text}
                    </Alert>
                )}
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        color: grey[700],
        fontSize: 28,
    },
    form: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    keywordsContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '15px 0',
    },
    keyword: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#cdcdcd',
        borderRadius: '5px',
        padding: '0 5px',
        boxSizing: 'border-box',
        margin: '0 0 5px 5px'
    },
    keywordTitle: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        color: grey[700],
        textTransform: 'uppercase'
    },
    keywordButton: {
        border: 'none',
        background: 'transparent',
        padding: '0',
        margin: '0',
        color: '#fff',
        cursor: 'pointer'
    }
}));
