import React, { useReducer } from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    Checkbox,
    Button,
    Typography,
    DialogContent,
    FormControlLabel
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from "@material-ui/core/styles";

const reduceTableColumns = (state, action) => {
    switch (action.type) {
        case 'change':
            return {
                ...state,
                [action.column]: !state[action.column]
            }
        default:
            return state;
    }
}

export const useTableColumnsToShow = () => {
    const [tableColumns, dispatchTableColumns] = useReducer(reduceTableColumns, {
        showCode: false,
        showUserName: true,
        showUserOrg: false,
        showDocumentType: false,
        showDocumentNumber: false,
        showSex: false,
        showMask: false,
        showEmotion: false,
        showTemperature: false,
        showDeviceType: true,
        showDeviceName: true,
        showLocation: true,
        showAction: true,
        showSimilarity: false,
        showDateTime: true,
        showMedia: true,
        showAccess: true,
        showRejectionStatus: true,
        showPersonType: true,
        showAccessMethod: true,
        showInformedAge: false,
        showGlasses: false,
        showIdentifiedAge: false,
        showUserId: false,
        showExternalAPICalled: false,
    });

    return { tableColumns, dispatchTableColumns };
}

export const ColumnsManagementModal = ({ open, setOpen, tableColumns, dispatchTableColumns }) => {
    const classes = useStyles();
    
    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
                <DialogTitle className={classes.dialogTitle}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }} className={classes.label}>
                            Campos da tabela
                        </Typography>
                        <Button
                            color="secondary"
                            onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid xs={12} container>
                        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* left side */}

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Codigo
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showCode}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showCode' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            ID (Pessoa)
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showUserId}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showUserId' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Nome da pessoa
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showUserName}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showUserName' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Tipo do Documento
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showDocumentType}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showDocumentType' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Nº do Documento
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showDocumentNumber}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showDocumentNumber' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Organização (Pessoa)
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showUserOrg}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showUserOrg' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Sexo
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showSex}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showSex' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Mascara
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showMask}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showMask' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Sentimento
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showEmotion}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showEmotion' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Óculos
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showGlasses}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showGlasses' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Temperatura
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showTemperature}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showTemperature' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Tipo do dispositivo
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showDeviceType}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showDeviceType' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Método de Acesso
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showAccessMethod}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showAccessMethod' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* right side */}

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Nome do dispositivo
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showDeviceName}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showDeviceName' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Localização
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showLocation}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showLocation' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Ação
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showAction}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showAction' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Horário
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showDateTime}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showDateTime' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Mídia
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showMedia}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showMedia' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Acesso
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showAccess}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showAccess' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Percentual de Semelhança
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showSimilarity}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showSimilarity' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Detalhes
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showRejectionStatus}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showRejectionStatus' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Tipo de Pessoa
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showPersonType}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showPersonType' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Idade Identificada
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showIdentifiedAge}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showIdentifiedAge' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Idade Informada
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showInformedAge}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showInformedAge' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.checkboxContainer}>
                                <FormControlLabel
                                    label={
                                        <Typography variant="subtitle1" className={classes.label}>
                                            Marcação de Acesso
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={tableColumns.showExternalAPICalled}
                                            onChange={() => dispatchTableColumns({ type: 'change', column: 'showExternalAPICalled' })}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        width: '60%',
    },
    dialogTitle: {
        paddingRight: '0px',
    },
    label: {
        color: '#9e9e9e',
        fontWeight: 'bold',
        userSelect: 'none',
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center'
    },
}));