import {
    useEffect,
    useReducer,
    useState,
    useRef,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import clsx from 'clsx';

import {
    AppBar as MuiAppBar,
    Box,
    Toolbar,
    Grid,
    Typography,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Button,
    Paper,
    ListItemIcon,
    Tooltip,
    Zoom,

} from "@material-ui/core";

import InfoIcon from '@mui/icons-material/Info';

import { styled } from '@mui/material/styles';

import { grey } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import logo from '../../assets/img/logo-horizontal.png';

import Alert from '../../components/Alert';
import Popup from "../../components/Popup";

import MenuIcon from '@mui/icons-material/Menu';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EjectIcon from '@mui/icons-material/Eject';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import CameraIcon from '@mui/icons-material/Camera';

import api from '../../services/api';
import socket from '../../services/socket';
import config from '../../configs/config';

import { loadPlayer } from 'rtsp-relay/browser';
import Input from "../../components/Input";
import Select from "../../components/Select";
import FullLogCard from "./components/FullLogCard";
import PastLog from "./components/PastLog";
import PanelDetails from "./components/PanelDetails";
import PTZControls from "../../components/PTZControls";
import Draggable from 'react-draggable';

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FullScreenDialog from "../../components/FullScreenDialog";

import storage from "../../utils/storage";

const { host, port, relayUrl } = config;


const TransmissionProtocol = (type, element, socketUrl, canvas) => {
    let elementHost = "";
    let elementUsername = "";
    let elementPassword = "";
    let elementChannel = "1";
    let streamSubtype = "1";

    if (type === "access") {
        elementHost = element?.host;
        elementChannel = "1";
        elementUsername = element?.username;
        elementPassword = element?.password;
    } else if (type === "monitoring") {
        elementHost = element?.device?.host;
        elementChannel = element?.channel;
        elementUsername = element?.device?.username;
        elementPassword = element?.device?.password;
    } else {
        return;
    }

    if (element.origin === "external") {
        switch (element.device_type) {
            case "Onvif":
                const getUrl = async () => {
                    try {
                        const result = await api.post(`/device_connection_control/onvif`, {
                            deviceIp: element?.ip_address,
                            username: element?.username,
                            password: element?.password,
                            port: element?.port,
                        });

                        let url = result.data;
                        url = url.replace('rtsp://', `rtsp://${element?.username}:${element?.password}@`);
                        url = url.replace('&subtype=0', `&subtype=${streamSubtype}`);

                        socketUrl = `${relayUrl}/api/stream/?external=true&urlStream=${url}`;

                        if (socketUrl === null) return;

                        loadPlayer({
                            url: socketUrl,
                            canvas: canvas.current,
                        });
                    } catch (err) {
                        console.log({ err });
                    }
                };
                getUrl();
                break;

            case "RTSP":
                let url = `rtsp://${element?.username}:${element?.password}@${element?.ip_address}:${element?.port}/cam/realmonitor?channel=1&subtype=${streamSubtype}`;
                socketUrl = `${relayUrl}/api/stream/?external=true&urlStream=${url}`;

                if (socketUrl === null) return;

                loadPlayer({
                    url: socketUrl,
                    canvas: canvas.current,
                });
                break;
            default:
                //
        }
    } else {
        socketUrl = `${relayUrl}/api/stream/?nvr=${elementHost}&cam=${elementChannel}&username=${elementUsername}&password=${elementPassword}&subtype=${streamSubtype}`;

        console.log({ socketUrl });

        if (socketUrl === null) return;

        loadPlayer({
            url: socketUrl,
            canvas: canvas.current,
        });
    }
}


const RtspPlayer = ({
    type,
    element,
}) => {
    const canvas = useRef(null);
    const [fullScreen, setFullScreen] = useState(false);


    useEffect(() => {
        console.log({ canvas })
        if (!canvas?.current) {
            console.log("no canvas")
            // return;
            setTimeout(() => {
                console.log("calling player")
                let socketUrl = null;
                TransmissionProtocol(type, element, socketUrl, canvas)
            }, [1500]);
        };



        console.log("calling player")

        let socketUrl = null;

        TransmissionProtocol(type, element, socketUrl, canvas)


    }, [element, type, fullScreen]);

    const toggleFullScreen = () => setFullScreen((curr) => !curr);

    const [activeDrags, setActiveDrags] = useState(0);

    const onStart = () => {
        setActiveDrags((curr) => ++curr);
    };

    const onStop = () => {
        setActiveDrags((curr) => --curr);
    };

    return (
        <>
            {
                fullScreen ? (
                    <FullScreenDialog
                        open={fullScreen}
                        title={element?.name ?? "-"}
                        onClose={toggleFullScreen}
                    >
                        <div style={{
                            backgroundmaxHeight: '100%',
                            height: '100%',
                            maxWidth: '100%',
                            margin: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <canvas
                                ref={canvas}
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    position: "absolute",
                                    borderRadius: 4,
                                }}
                            />
                            {
                                (fullScreen && (element.capabilities.includes("PTZ"))) && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            zIndex: 9999,
                                        }}
                                    >
                                        <Draggable
                                            onStart={onStart}
                                            onStop={onStop}
                                        >
                                            <div
                                                style={{
                                                    padding: "0.8rem",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#171923",
                                                    cursor: "move",
                                                }}
                                            >
                                                <PTZControls
                                                    selectedCamera={{
                                                        id: element.id,
                                                        is_ptz: true,
                                                    }}
                                                />
                                            </div>
                                        </Draggable>
                                    </div >
                                )
                            }
                        </div>
                    </FullScreenDialog>
                ) : (
                    <canvas
                        ref={canvas}
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            cursor: "pointer",
                            borderRadius: 4,
                        }}
                        onDoubleClick={toggleFullScreen}
                    />
                )
            }
        </>

    );
};

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const BlueGrayTooltip = withStyles({
    tooltip: {
        backgroundColor: "#3C415C",
    },
    arrow: {
        color: "#3C415C",
    },
})(Tooltip);

const reducePastLogs = (state, action) => {
    switch (action.type) {
        case "add":
            state.unshift(action.value);
            if (state.length > 20)
                state.pop();
            return state;
        case "load":
            const loadedValues = [];

            if (action?.value?.length) for (const toLoadValue of action.value) {
                const parsedValue = JSON.parse(toLoadValue);

                const imagePath = `http://${host}:${port}/midias/imagem/acesso/${parsedValue.mediaName}`
                parsedValue.accessMedia = imagePath;

                const accessImagePath = (
                    parsedValue?.user?.face_image
                        ? `http://${host}:${port}/midias/imagem/face/${parsedValue?.user?.face_image}`
                        : null
                );
                parsedValue.userFace = accessImagePath;

                loadedValues.push(parsedValue);
            }

            return loadedValues;
        default:
            return state;
    }
};

const ElementContainer = ({
    cameraColumns,
    onDropElement = () => { },
    index = 0,
    element,
    elementType,
}) => {
    // eslint-disable-next-line
    const [{ isOver }, drop] = useDrop(() => ({
        accept: "image",
        drop: (item) => onDropElement(item, index),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <Grid
            item
            xs={12 / cameraColumns}
            style={{
                background: "#213555",
                content: "",
                display: "flex",
                padding: 10,
            }}
        >
            <BlueGrayTooltip
                title={
                    <Typography
                        style={{
                            fontSize: "1rem",
                            color: "#CDCDCD",
                        }}
                    >
                        {element?.name ?? "..."}
                    </Typography>
                }
                TransitionComponent={Zoom}
                arrow
                PopperProps={{ style: { zIndex: 0 } }}
            >
                <div
                    ref={drop}
                    style={{
                        width: "100%",
                        background: "#212A3E",
                        paddingBottom: "56.25%", /* 16:9 */
                        boxSizing: "border-box",
                        position: "relative",
                        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                        borderRadius: 4,
                    }}
                >
                    {
                        element ? (
                            <RtspPlayer
                                element={element}
                                type={elementType}
                            />
                        ) : (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    width: "100%",
                                    zIndex: 999,
                                    position: "absolute",
                                }}
                            >
                                <CameraIcon
                                    style={{
                                        color: "#9BA4B5",
                                        fontSize: `${(6 / (cameraColumns * 0.75)).toFixed(1)}rem`,
                                    }}
                                />
                            </Box>
                        )
                    }
                </div>
            </BlueGrayTooltip>
        </Grid>
    );
}

const ElementItem = ({ element, onGrid, removeFromSlot }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "image",
        item: { ...element },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <ListItem
            ref={drag}
            button
            key={element.id}
            component={Paper}
            style={{
                borderRadius: 8,
                background: "#212A3E",
                marginBottom: 16,
            }}
        >
            <ListItemText
                primary={element.name}
                style={{
                    color: isDragging ? "#FFF" : "#CDCDCD",
                }}
            />
            {
                onGrid && (
                    <ListItemIcon
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end"
                        }}
                        onClick={() => removeFromSlot(element)}
                    >
                        <BlueGrayTooltip
                            title={
                                <Typography
                                    style={{
                                        fontSize: "1rem",
                                        color: "#CDCDCD",
                                    }}
                                >
                                    Remover
                                </Typography>
                            }
                        >
                            <RemoveCircleOutlineIcon
                                style={{
                                    color: "#9BABB8",
                                }}
                            />
                        </BlueGrayTooltip>
                    </ListItemIcon>
                )
            }
            <ListItemIcon
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}
            >
                <DragIndicatorIcon
                    style={{
                        color: isDragging ? "#FFF" : "#CDCDCD",
                    }}
                />
            </ListItemIcon>
        </ListItem>
    );
}

const reduceSlots = (state, action) => {
    const newState = Object.assign([], state);
    console.log({ action });
    switch (action.type) {
        case "manageSlots":
            if (newState.length > action.value) {
                while (newState.length > action.value) {
                    newState.pop();
                }
            } else {
                while (newState.length < action.value) {
                    newState.push(null);
                }
            }

            return newState;
        case "add":
            return newState.map((el, index) => {
                if (el?.id === action.value.id && el?.origin === action.value.origin) {
                    return null;
                }

                if (index === action?.index) {
                    return action.value;
                }

                return el;
            });
        case "remove":
            return newState.map((el) => {
                if (el?.id === action.value.id && el?.origin === action.value.origin) {
                    return null;
                }
                return el;
            });
        default:
            return state;
    }
}

export default function SecurityPanelView() {
    const classes = useStyles();

    const { id } = useParams();
    const history = useHistory();

    const goToMainSite = () => history.push("/paineis");

    const [accessLog, setAccessLog] = useState(null);
    const [securityPanel, setSecurityPanel] = useState(null);
    const [pastLogs, dispatchPastLogs] = useReducer(reducePastLogs, []);

    const [showSelectedLog, setShowSelectedLog] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);
    const [gridViewMode, setGridViewMode] = useState(false);

    const onChangeGridForDHI = (e) => {
        setGridViewMode(e.target?.value || false);
    }

    const [openLogs, setOpenLogs] = useState(false);
    const toggleOpenLogs = () => setOpenLogs((curr) => !curr);

    const [openDevicesList, setOpenDevicesList] = useState(false);
    const toggleOpenDevicesList = () => setOpenDevicesList((curr) => !curr);

    const [cameraRows, setCameraRows] = useState(2);
    const [cameraColumns, setCameraColumns] = useState(2);

    const showLog = (log) => {
        setSelectedLog(log);
        setShowSelectedLog(true);
    };

    const onCloseSelecedLog = () => {
        setShowSelectedLog(false);
    };

    const [showPanelDetails, setShowPanelDetails] = useState(false);

    const togglePanelDetails = () => setShowPanelDetails((prev) => !prev);

    const loadPastLogs = (loadingLogs) => dispatchPastLogs({
        type: "load",
        value: loadingLogs,
    });

    const [message, setMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });

    const closeMessage = () => {
        setMessage({
            open: false,
            variant: '',
            text: '',
        });
    };


    const [managedColumns, setManagedColumns] = useState(2);
    const [elementSlots, dispatchElementSlots] = useReducer(
        reduceSlots,
        Array.from({ length: managedColumns * cameraRows }, () => null),
    );

    const manageSlots = () => {
        dispatchElementSlots({
            type: "manageSlots",
            value: cameraColumns * cameraRows,
        });
        setManagedColumns(cameraColumns);
    }

    const onDropElement = (value, index) => {
        dispatchElementSlots({
            type: "add",
            value: value,
            index: index,
        });
    };

    const onRemoveElement = (value, index) => {
        dispatchElementSlots({
            type: "remove",
            value: value,
            index: index,
        });
    }

    useEffect(() => {
        (async () => {
            try {
                const result = await api.get(`/security_panel/view/${id}`);

                const securityPanelData = result.data;
                console.log({ securityPanelData })
                setSecurityPanel(securityPanelData);

                if (securityPanelData?.type === "monitoring")
                    setGridViewMode(true)
            } catch (err) {
                console.log(err)
                setMessage({
                    open: true,
                    variant: "error",
                    text: err.error,
                });
                setTimeout(() => history.goBack(), 1500);
            }
        })();
        const user = storage.getItem("@Identifik:user");
        if (!socket.connected) {
            console.log("connecting...");
            socket.connect();
            socket.once("connect", () => {
                console.log("connected: ", socket.connected);
            });

            socket.emit("client-join", `${id}`, loadPastLogs);

            if (user)
                socket.emit("room-presence", {
                    room: id,
                    user: user.id,
                    viewing: []
                });

            socket.on(`receive-message`, (message) => {
                const log = message;

                dispatchPastLogs({
                    type: "add",
                    value: message,
                });

                const imagePath = `http://${host}:${port}/midias/imagem/acesso/${log.mediaName}`
                log.accessMedia = imagePath;

                if (log.videoName) {
                    const videoPath = `http://${host}:${port}/midias/video/acesso/${log.videoName}`
                    log.videoMedia = videoPath;
                }

                const accessImagePath = (
                    log?.user?.face_image
                        ? `http://${host}:${port}/midias/imagem/face/${log?.user?.face_image}`
                        : null
                );
                log.userFace = accessImagePath;

                setAccessLog(log);
            });
        }
        return () => {
            console.log("disconnecting")
            socket.off();
            socket.disconnect();
        };
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        const interval = setInterval(() => {
            const user = storage.getItem("@Identifik:user");

            if (!id)
                return;

            if (!socket.connected)
                return;

            if (!user)
                return;

            socket.emit("room-presence", {
                room: id,
                user: user.id,
                viewing: elementSlots
                    .filter((el) => el !== null)
                    .map((el) => el.id),
            });
        }, [300000]);

        return () => {
            clearInterval(interval);
        };
    }, [id, elementSlots]);

    useEffect(() => {
        const checkIfIsStillAllowed = async () => {
            try {
                await api.get(`/security_panel/is_allowed_to_access/${id}`);

                console.log("still allowed to access");
            } catch (err) {
                console.log(err)
                setMessage({
                    open: true,
                    variant: "error",
                    text: err.error,
                });
                setTimeout(() => history.goBack(), 1500);
            }
        };

        const interval = setInterval(checkIfIsStillAllowed, 10000);

        return () => clearInterval(interval);
    }, [id, history])

    const handleCameraColumnsChange = ({ target: { value } }) => {
        let parsedValue = value ? parseInt(value) : 0;

        if (parsedValue === 5) {
            parsedValue = cameraColumns === 6 ? 4 : 6;
        } else if (parsedValue > 6 && parsedValue < 12) {
            parsedValue = cameraColumns <= 6 ? 12 : 6;
        }

        setCameraColumns(parsedValue)
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={classes.root}>
                <Box style={{ display: 'flex' }}>
                    <AppBar>
                        <Toolbar style={{ background: '#100c2f', display: "flex", justifyContent: "flex-start" }} >
                            <IconButton
                                edge="end"
                                aria-label="user options"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={toggleOpenDevicesList}
                                style={{
                                    marginRight: 10
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <div className={classes.logoContainer}>
                                <img src={logo} alt="logo" className={classes.logo} />
                            </div>
                            <Box
                                style={{
                                    display: "flex",
                                    flexGrow: 1,
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: 700
                                        }}
                                    >
                                        Painel: {securityPanel?.name}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        aria-label="user options"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        color="inherit"
                                        onClick={togglePanelDetails}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: "normal",
                                            marginLeft: "3rem",
                                            marginRight: "1rem",
                                        }}
                                    >
                                        Modo:
                                    </Typography>
                                    <Select
                                        className={classes.gridModeWidth}
                                        options={[
                                            { label: "GRID", value: true },
                                            { label: "LOG ÚNICO", value: false },
                                        ]}
                                        textFieldProps={{
                                            value: gridViewMode,
                                            onChange: onChangeGridForDHI,
                                            fullWidth: true,
                                        }}
                                    />
                                    {
                                        gridViewMode && (
                                            <>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        fontWeight: "normal",
                                                        marginLeft: "1rem",
                                                        marginRight: "1rem",
                                                    }}
                                                >
                                                    Linhas:
                                                </Typography>
                                                <Input
                                                    className={classes.dimensionWidth}
                                                    textFieldProps={{
                                                        value: cameraRows,
                                                        onChange: ({ target: { value } }) => setCameraRows(value ? parseInt(value) : 0),
                                                        type: "number",
                                                    }}
                                                    numberProps={{
                                                        min: 1,
                                                    }}
                                                />
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        fontWeight: "normal",
                                                        marginLeft: "1rem",
                                                        marginRight: "1rem",
                                                    }}
                                                >
                                                    Colunas:
                                                </Typography>
                                                <Input
                                                    className={classes.dimensionWidth}
                                                    textFieldProps={{
                                                        value: cameraColumns,
                                                        onChange: handleCameraColumnsChange,
                                                        type: "number",
                                                    }}
                                                    numberProps={{
                                                        min: 1,
                                                        max: 12
                                                    }}
                                                />
                                                <Button
                                                    variant="primary"
                                                    onClick={manageSlots}
                                                    style={{
                                                        color: "#cdcdcd",
                                                        background: "#212A3E",
                                                        marginLeft: "1rem",
                                                    }}
                                                >
                                                    APLICAR
                                                </Button>
                                            </>
                                        )
                                    }
                                </Box>
                                <IconButton
                                    edge="end"
                                    aria-label="user options"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={toggleOpenLogs}
                                >
                                    <FolderSharedIcon />
                                </IconButton>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Toolbar />
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={openDevicesList}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar />
                    <Box
                        style={{
                            width: "100%",
                            maxHeight: "100%",
                            background: "#27374D",
                            flex: 1,
                            padding: 12,
                        }}
                        className={classes.customScroolbar}
                    >
                        <Grid
                            container
                            alignItems="center"
                            justify="center"
                            style={{
                                maxHeight: "calc(100vh - 100px)",
                                gap: 12,
                                overflowY: "auto",
                            }}
                        >
                            {
                                securityPanel?.type === "access" ? (
                                    <List component="nav" aria-label="secondary mailbox folders" style={{
                                        width: "100%",
                                    }}>
                                        <ListItem
                                            button
                                            component={Paper}
                                            style={{
                                                borderRadius: 8,
                                                background: "#6B778D",
                                                marginBottom: 16,
                                            }}
                                            onClick={goToMainSite}
                                        >
                                            <ListItemIcon
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start"
                                                }}
                                            >
                                                <EjectIcon
                                                    style={{
                                                        color: "#CDCDCD",
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={(
                                                    <Typography
                                                        style={{
                                                            fontWeight: "bolder",
                                                            color: "#CDCDCD",
                                                        }}
                                                    >
                                                        VOLTAR
                                                    </Typography>
                                                )}
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            />
                                            <ListItemIcon
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end"
                                                }}
                                            >
                                                <EjectIcon
                                                    style={{
                                                        color: "#CDCDCD",
                                                    }}
                                                />
                                            </ListItemIcon>
                                        </ListItem>
                                        {
                                            !!securityPanel?.devices && securityPanel.devices.map((device) => (
                                                <ElementItem
                                                    key={device.id}
                                                    element={device}
                                                    onGrid={elementSlots.map(el => el?.id).includes(device.id)}
                                                    removeFromSlot={onRemoveElement}
                                                />
                                            ))
                                        }
                                    </List>
                                ) : securityPanel?.type === "monitoring" ? (
                                    <>
                                        <List component="nav" aria-label="secondary mailbox folders" style={{
                                            width: "100%",
                                        }}>
                                            <ListItem
                                                button
                                                component={Paper}
                                                style={{
                                                    borderRadius: 8,
                                                    background: "#6B778D",
                                                    marginBottom: 16,
                                                }}
                                                onClick={goToMainSite}
                                            >
                                                <ListItemIcon
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "flex-start"
                                                    }}
                                                >
                                                    <EjectIcon
                                                        style={{
                                                            color: "#CDCDCD",
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={(
                                                        <Typography
                                                            style={{
                                                                fontWeight: "bolder",
                                                                color: "#CDCDCD",
                                                            }}
                                                        >
                                                            VOLTAR
                                                        </Typography>
                                                    )}
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                />
                                                <ListItemIcon
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "flex-end"
                                                    }}
                                                >
                                                    <EjectIcon
                                                        style={{
                                                            color: "#CDCDCD",
                                                        }}
                                                    />
                                                </ListItemIcon>
                                            </ListItem>
                                            {
                                                !!securityPanel?.cameras && securityPanel.cameras.map((camera) => (
                                                    <ElementItem
                                                        key={camera.id}
                                                        element={{
                                                            ...camera,
                                                            origin: "nvr",
                                                        }}
                                                        onGrid={elementSlots.find(el => el?.id === camera.id && el.origin === "nvr") !== undefined}
                                                        removeFromSlot={onRemoveElement}
                                                    />
                                                ))
                                            }
                                            {

                                                !!securityPanel?.externalcams && securityPanel?.externalcams?.map((external) => (
                                                    <ElementItem
                                                        key={external.id + '_external'}
                                                        element={{
                                                            ...external,
                                                            origin: "external",
                                                        }}
                                                        onGrid={elementSlots.find(el => el?.id === external.id && el.origin === "external") !== undefined}
                                                        removeFromSlot={onRemoveElement}
                                                    />
                                                ))
                                            }
                                        </List>
                                    </>
                                ) : ""
                            }
                        </Grid>
                    </Box>
                </Drawer>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: openLogs || openDevicesList,
                        [classes.shiftRight]: openLogs,
                        [classes.shiftLeft]: openDevicesList,
                    })}
                >
                    <Grid
                        container
                        style={{
                            height: "100%",
                        }}
                    >
                        <Box
                            style={{
                                height: "100%",
                                background: "#CDCDCD",
                                flex: 1,
                                display: "flex",
                            }}
                        >
                            <Grid
                                container
                                style={{
                                    background: "#213555",
                                    boxSizing: "border-box"

                                }}
                                justify="center"
                                alignItems="center"
                            >
                                {
                                    !gridViewMode ? (
                                        <>
                                            {
                                                !!accessLog ? (
                                                    <FullLogCard accessLog={accessLog} />
                                                ) : (
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontWeight: 700,
                                                            color: grey[600],
                                                        }}
                                                    >
                                                        Aguardando Logs
                                                    </Typography>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {
                                                elementSlots.map((el, index) => (
                                                    <ElementContainer
                                                        cameraColumns={managedColumns}
                                                        onDropElement={onDropElement}
                                                        index={index}
                                                        element={el}
                                                        elementType={securityPanel?.type}
                                                    />
                                                ))
                                            }
                                        </>
                                    )
                                }
                            </Grid>
                        </Box>
                    </Grid>
                    <Popup
                        openPopup={showPanelDetails}
                        setOpenPopup={setShowPanelDetails}
                        title="Detalhes do Painel"
                    >
                        <PanelDetails panel={securityPanel} />
                    </Popup>
                    <Popup
                        title="Detalhes do Acesso"
                        openPopup={showSelectedLog}
                        onClose={onCloseSelecedLog}
                        width="100%"
                    >
                        <FullLogCard accessLog={selectedLog} />
                    </Popup>
                    {!!message.open && (
                        <Alert
                            open={message.open}
                            autoHideDuration={6000}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            severity={message.variant}
                            onClose={closeMessage}
                        >
                            {message.text}
                        </Alert>
                    )}
                </main>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={openLogs}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar />
                    <Box
                        style={{
                            width: "100%",
                            maxHeight: "100%",
                            background: "#27374D",
                            flex: 1,
                            padding: 12,
                        }}
                        className={classes.customScroolbar}
                    >
                        <Grid
                            container
                            alignItems="center"
                            justify="center"
                            style={{
                                maxHeight: "calc(100vh - 100px)",
                                gap: 12,
                                overflowY: "auto",

                            }}
                        >
                            {
                                !!pastLogs.length && pastLogs.map((pastLog) => (
                                    <PastLog key={pastLog.id} accessLog={pastLog} onClick={showLog} />
                                ))
                            }
                        </Grid>
                    </Box>
                </Drawer>
            </div>
        </DndProvider>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: "100vh",
        maxHeight: "100vh",
        minHeight: "100vh",
        flexDirection: "column",
        background: "#213555"
    },
    drawer: {
        width: 320,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 320,
        background: "#27374D",
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 0,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    shiftRight: {
        marginRight: 320,

    },
    shiftLeft: {
        marginLeft: 320,
    },
    logoContainer: {
        marginRight: "1rem"
    },
    logo: {
        width: 180,
        verticalAlign: 'middle',
    },
    divider: {
        marginTop: 6,
        marginBottom: 6,
    },
    accessImageAlt: {
        fontWeight: 700,
        color: grey[900],
    },
    detailsSideTitle: {
        fontWeight: 700,
        color: grey[700],
    },
    video: {
        maxHeight: '100%',
        height: '100%',
        maxWidth: '100%',
        display: 'block',
        margin: 'auto',
    },
    customScroolbar: {
        "-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            backgroundColor: "#F5F5F5",
        },
        "-webkit-scrollbar": {
            width: "12px",
            backgroundColor: "#F5F5F5",
        },
        "-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
            backgroundColor: "#555",
        }, line: '1px solid slategrey'
    },
    gridModeWidth: {
        width: "10rem",
    },
    dimensionWidth: {
        width: "4rem",
    },
}));
